import React, { useState, useEffect, useCallback } from 'react';
import API_STATES from '@/constants/StateConstants';
import EmptyTable from '@/components/General/EmptyTable';
import DataTable from '@/components/General/DataTable';
import styles from '../HomepageNotification.module.scss';
import * as MessageService from '@/services/api/subscriptionBasedNotification.service';
import generalStyles from '@/components/General/Styles.module.scss';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import moment from 'moment';
import expandIcon from '@images/icons/expandIcon.svg';
import deleteIcon from '@images/icons/deleteIcon.svg';
import editIcon from '@images/icons/EditIcon.svg';
import SubscriptionBasedNotificationView from './SubscriptionBasedNotificationView';
import ByzzerCSRModal from '@/components/ByzzerCSRModal';
import SubscriptionBasedNotificationForm from './SubscriptionBasedNotificationForm';
import * as Constant from '../Constants';
import { customizeDate } from '../Utils';

function SubscriptionBasedNotificationHistory({ refreshHistory, refresh }) {
    const [notificationApiStatus, setNotificationApiStatus] = useState(API_STATES.none);
    const [notificationApiError, setNotificationApiError] = useState('');
    const [notificationList, setNotificationList] = useState([]);
    const [notificationDeleteStatus, setNotificationDeleteStatus] = useState(API_STATES.none);
    const [notificationDeleteError, setNotificationDeleteError] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState({});
    const [detailedView, setDetailedView] = useState(false);
    const [edit, setEdit] = useState(false);

    const COLUMNS = [
        {
            Header: 'Notification ID',
            accessor: 'notificationId',
            className: generalStyles.tableCell,
        },
        {
            Header: 'Flagged Subscription Types',
            accessor: 'subscriptionTypes',
            className: generalStyles.tableCell,
        },
        {
            Header: 'Expiration date',
            accessor: 'endDate',
            className: generalStyles.tableCell,
        },
        {
            Header: 'Type of Notification',
            accessor: 'notificationType',
            className: generalStyles.tableCell,
        },
        {
            Header: 'Notification Content',
            accessor: 'content',
            className: generalStyles.tableCell,
        },
        {
            Header: 'Link',
            accessor: 'hyperLink',
            className: generalStyles.tableCell,
        },
        {
            Header: 'Action',
            accessor: (row) => row,
            id: 'actions',
            disableFilters: true,
            disableSortBy: true,
            className: generalStyles.tableCell,
            Cell: ({ value: row }) => {
                const isExpired = moment(row.expiration).isAfter(moment(new Date()));
                return (
                    <div className={styles.actionContainer}>
                        <div
                            className={styles.expandIconContainer}
                            onClick={() => {
                                expandClicked(row);
                            }}
                        >
                            <img src={expandIcon} alt="expand" className={styles.btnWidth} />
                        </div>

                        <div
                            className={styles.expandIconContainer}
                            onClick={
                                isExpired
                                    ? () => {
                                          deleteClicked(row);
                                      }
                                    : {}
                            }
                        >
                            <img
                                src={deleteIcon}
                                alt="delete"
                                className={isExpired ? styles.btnWidth : styles.disabledIcon}
                            />
                        </div>

                        <div
                            className={styles.expandIconContainer}
                            onClick={() => {
                                editClicked(row);
                            }}
                        >
                            <img src={editIcon} alt="edit" className={styles.btnWidth} />
                        </div>
                    </div>
                );
            },
        },
    ];

    const getNotificationHistory = useCallback(async () => {
        refresh(false);
        setNotificationApiStatus(API_STATES.loading);

        let response = await MessageService.getSubscriptionHistory('subscriptionBased');
        if (response.status === API_STATES.success) {
            const result = response.data;
            setNotificationApiStatus(API_STATES.success);
            setNotificationList(result.data);
        } else {
            setNotificationApiError(response.error);
            setNotificationList([]);
            setNotificationApiStatus(API_STATES.error);
        }
    }, [refresh]);

    useEffect(() => {
        if (refreshHistory) {
            getNotificationHistory();
        }
    }, [refreshHistory, getNotificationHistory]);

    const transformTabelColumns = (list) => {
        const historyData = [];
        let type = {};

        for (const data of list) {
            type = Constant.NOTIFICATION_TYPE.filter((item) => {
                return item.value === data.messageType;
            });
            let dataObj = {
                notificationId: data.notificationId,
                endDate: customizeDate(data.endDate),
                notificationType: data.messageType,
                content: data.message.header + ': ' + data.message.message,
                hyperLink: data.message.hyperLink,
                message: data.message.message,
                header: data.message.header,
                type: type,
                expiration: data.endDate,
                subscriptionTypes: data.criteria?.subscriptionTypes?.join(', ') ?? '',
                runType: data.criteria.subscriptionTriggerType ?? '',
                subscriptionList: data.criteria?.subscriptionTypes,
                csrNotificationId: data.csrConfigId,
                subscriptionNotificationType: data.criteria?.notificationType,
            };
            historyData.push(dataObj);
        }
        return historyData;
    };

    function deleteClicked(rowData) {
        setSelectedRowData(rowData);
        setOpenModal(true);
    }

    function expandClicked(rowData) {
        setSelectedRowData(rowData);
        setDetailedView(true);
    }

    function editClicked(rowData) {
        setSelectedRowData(rowData);
        setEdit(true);
    }

    function closeEdit() {
        setEdit(false);
        setSelectedRowData(null);
    }

    function closeExpand() {
        setDetailedView(false);
        setSelectedRowData(null);
        getNotificationHistory();
    }

    async function terminateNotification() {
        try {
            setOpenModal(false);
            if (selectedRowData) {
                setNotificationDeleteStatus(API_STATES.loading);
                let response = await MessageService.terminateMessage({
                    id: selectedRowData.notificationId,
                    endDate: moment(new Date()).startOf('day').format('YYYY/MM/DD HH:mm:ss'),
                });
                if (response.status === API_STATES.success) {
                    setNotificationDeleteStatus(API_STATES.success);
                    refresh(true);
                } else {
                    setNotificationDeleteError(response.error);
                    setNotificationDeleteStatus(API_STATES.error);
                    return false;
                }
            } else {
                return false;
            }
        } catch (err) {
            setNotificationDeleteError(err);
            setNotificationDeleteStatus(API_STATES.error);
        }
    }

    return (
        <>
            <h3>Notification History</h3>
            <br />
            <div>
                {notificationDeleteStatus === API_STATES.error && (
                    <p className={generalStyles.errorMessage}>Error: {notificationDeleteError}</p>
                )}
                {notificationDeleteStatus === API_STATES.loading && (
                    <p className={generalStyles.errorMessage}>Terminating...</p>
                )}
                {notificationApiStatus === API_STATES.error && (
                    <p className={generalStyles.errorMessage}>Error: {notificationApiError}</p>
                )}
                {notificationApiStatus === API_STATES.none && <p className={generalStyles.statusMessage}>Loading...</p>}
                {notificationApiStatus === API_STATES.loading && (
                    <p className={generalStyles.statusMessage}>Loading...</p>
                )}
                {notificationApiStatus === API_STATES.success && (
                    <>
                        {notificationList.length > 0 ? (
                            <DataTable data={transformTabelColumns(notificationList)} columns={COLUMNS} withDownload downloadFileName={"SubscriptionBasedNotificationsHistory"}/>
                        ) : (
                            <div>
                                <EmptyTable
                                    noValueDescription="No Notification History found."
                                    columns={[
                                        'Notification ID',
                                        'Flagged Subscription Types',
                                        'Expiration date',
                                        'Type of Notification',
                                        'Notification Content',
                                        'Link',
                                        'Action',
                                    ]}
                                />
                            </div>
                        )}
                    </>
                )}
            </div>

            {openModal && (
                <Popup
                    open={openModal}
                    onClose={() => {
                        setOpenModal(false);
                    }}
                    modal
                >
                    {() => (
                        <div className={styles.warningDialog}>
                            <h2>Warning!</h2>
                            <div>
                                <div>
                                    {' '}
                                    <b>Are you sure you want to terminate the Notification for all notified users?</b>
                                </div>
                                <br></br>
                                <button
                                    className={`${generalStyles.confirmOperationButton} ${styles.btnWidth}`}
                                    onClick={() => {
                                        terminateNotification();
                                    }}
                                >
                                    Terminate for all users
                                </button>
                                <button
                                    className={`${generalStyles.cancelOperationButton} ${styles.btnWidth}`}
                                    onClick={() => {
                                        setOpenModal(false);
                                    }}
                                >
                                    Cancel
                                </button>{' '}
                            </div>
                        </div>
                    )}
                </Popup>
            )}

            {detailedView && (
                <ByzzerCSRModal
                    show={true}
                    onClose={() => closeExpand()}
                    size={'extra-large'}
                    type={'info'}
                    headerType={'none'}
                    children={<SubscriptionBasedNotificationView selectedNotification={selectedRowData} />}
                />
            )}

            {edit && (
                <ByzzerCSRModal
                    show={true}
                    onClose={() => setEdit(false)}
                    size={'extra-large'}
                    type={'info'}
                    headerType={'none'}
                    children={
                        <SubscriptionBasedNotificationForm
                            selectedNotification={selectedRowData}
                            edit={edit}
                            refresh={(flag) => refresh(flag)}
                            closeEdit={() => closeEdit()}
                        />
                    }
                />
            )}
        </>
    );
}
export default SubscriptionBasedNotificationHistory;
