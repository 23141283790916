import { getCategoryLicenseReport } from '@/services/api/report.service';
import {
    getCategories,
    getDepartments,
    getSuperCategories,
    getSubscriptionTypes,
} from '@/services/api/product.service';
import { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import generalStyles from '@/components/General/Styles.module.scss';
import styles from '@/components/CategoryLicenseReport/CategoryLicenseReport.module.scss';
import API_STATES from '@/constants/StateConstants';
import { handleApiError } from '@/components/General/HandleApiError';
import EmptyTable from '@/components/General/EmptyTable';
import ByzzerSwitch from '../General/ByzzerSwitch';
import Select from 'react-select';
import {toOption } from '../utils/utils';
import DataTable from '@/components/General/DataTable';
import { COLUMNS } from '@/components/CategoryLicenseReport/CategoryLicenseReportColumns'

const FILTER_TYPES = [
    { label: 'Categories', value: 'Category' },
    { label: 'Super Categories', value: 'Super Category' },
    { label: 'Departments', value: 'Department' },
];
function CategoryLicenseReport(props) {
    const [categoriesApiState, setCategoriesApiState] = useState(API_STATES.none);
    const [categoriesApiError, setCategoriesApiError] = useState('');
    const [allCategories, setAllCategories] = useState(null);

    const [subscriptionTypeApiState, setSubscriptionTypeApiState] = useState(API_STATES.none);
    const [subscriptionTypeApiError, setSubscriptionTypeApiError] = useState('');
    const [subscriptionTypes, setSubscriptionTypes] = useState([]);

    const [selectedSubscriptionTypes, setSelectedSubscriptionTypes] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);

    const [reportApiStatus, setReportApiStatus] = useState(API_STATES.none);
    const [reportApiError, setReportApiError] = useState('');

    const [reportResult, setReportResult] = useState([]);
    const [includeTestAccounts, setIncludeTestAccounts] = useState(false);
    const [showActiveContracts, setShowActiveContracts] = useState(false);

    const [selectedFilterType, setSelectedFilterType] = useState(FILTER_TYPES[0]);

    const handleIncludeTestAccountsChange = ({ target }) => {
        setIncludeTestAccounts(target.checked);
    };

    const handleShowActiveContracts = ({ target }) => {
        setShowActiveContracts(target.checked);
    };

    const handleSelectedFilterType = (filterType) => {
        setSelectedItems([]);
        setSelectedFilterType(filterType);
    };

    const handleItemSelect = (selectedItem) => {
        setSelectedItems(selectedItem);
    };

    const handleSubscriptionTypeSelect = (selectedSubscriptionType) => {
        setSelectedSubscriptionTypes(selectedSubscriptionType);
    };

    async function getCategoryLicenseDetails() {
        setReportApiStatus(API_STATES.loading);
        const result = await getCategoryLicenseReport(
            selectedFilterType.value,
            selectedItems.map((item) => item.value),
            selectedSubscriptionTypes?.map((type) => type.value),
            showActiveContracts,
            includeTestAccounts
        );
        switch (result.status) {
            case API_STATES.success:
                setReportApiStatus(API_STATES.success);
                setReportResult(result.data);
                break;
            case API_STATES.error:
                const error = result.error;
                handleApiError(error, setReportApiStatus, setReportApiError, 'Category License report not found');
                break;
            default:
                setReportApiStatus(API_STATES.none);
                setReportApiError('');
        }
    }

    async function loadCategories(selectedFilterType) {
        setCategoriesApiState(API_STATES.loading);
        let response = null;
        if (selectedFilterType.value === 'Category') {
            response = await getCategories();
        } else if (selectedFilterType.value === 'Super Category') {
            response = await getSuperCategories();
        } else if (selectedFilterType.value === 'Department') {
            response = await getDepartments();
        } else {
            return;
        }
        if (response !== null) {
            if (response.status === API_STATES.success) {
                if (selectedFilterType.value === 'Super Category') {
                    const selectItems = response.data.map((item) => toOption(item.superCategoryName));
                    setAllCategories(selectItems);
                } else if (selectedFilterType.value === 'Department') {
                    const selectItems = response.data.map((item) => toOption(item.departmentName));
                    setAllCategories(selectItems);
                } else {
                    const selectItems = response.data.map((category) => toOption(category));
                    setAllCategories(selectItems);
                }
                setCategoriesApiState(API_STATES.success);
            } else {
                setAllCategories([]);
                handleApiError(response.error, setCategoriesApiState, setCategoriesApiError);
            }
        }
    }

    async function loadSubscriptionTypes() {
        setSubscriptionTypeApiState(API_STATES.loading);
        const response = await getSubscriptionTypes();
        if (response !== null) {
            if (response.status === API_STATES.success) {
                response.data = response.data ?? [];
                const subscriptionTypes = response.data.map((subscriptionType) => toOption(subscriptionType));
                setSubscriptionTypes(subscriptionTypes);
                setSubscriptionTypeApiState(API_STATES.success);
            } else {
                setSubscriptionTypes([]);
                handleApiError(response.error, setSubscriptionTypeApiState, setSubscriptionTypeApiError);
            }
        }
    }

    function isGenerateReportsEnabled() {
        return selectedItems?.length > 0;
    }

    useEffect(() => {
        loadCategories(selectedFilterType);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFilterType]);

    useEffect(() => {
        loadSubscriptionTypes();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <h1>Category Licensed Report</h1>
            <hr />
            <div className={generalStyles['input-wrapper']}>
                <label> Select a filter type: </label>
                <span className={generalStyles.formField}>
                    <Select
                        id="selectBox"
                        classNamePrefix={'react-select'}
                        onChange={handleSelectedFilterType}
                        options={FILTER_TYPES}
                        defaultValue={FILTER_TYPES[0]}
                    />
                </span>
            </div>

            <b>{selectedFilterType.label}: </b>
            <div className={styles.multiSelectInput}>
                {categoriesApiState === API_STATES.loading ? (
                    <span className={generalStyles.statusMessage}>Loading categories...</span>
                ) : categoriesApiState === API_STATES.error ? (
                    <span className={generalStyles.statusMessage}>{categoriesApiError}</span>
                ) : (
                    <Select
                        classNamePrefix={'react-select'}
                        options={allCategories}
                        closeMenuOnSelect={false}
                        isMulti={true}
                        onChange={handleItemSelect}
                        value={selectedItems}
                        placeholder="Select from the list"
                    />
                )}
            </div>

            <b>Subscription Types:</b>
            <div className={styles.multiSelectInput}>
                {subscriptionTypeApiState === API_STATES.loading ? (
                    <span className={generalStyles.statusMessage}>Loading subscription types...</span>
                ) : subscriptionTypeApiState === API_STATES.error ? (
                    <span className={generalStyles.statusMessage}>{subscriptionTypeApiError}</span>
                ) : (
                    <Select
                        classNamePrefix={'react-select'}
                        options={subscriptionTypes}
                        closeMenuOnSelect={false}
                        isMulti={true}
                        onChange={handleSubscriptionTypeSelect}
                        value={selectedSubscriptionTypes}
                        placeholder="Please select Subscription Types"
                    />
                )}
            </div>

            <div className={generalStyles['input-wrapper']}>
                <label className={generalStyles.switchLabel}>Include Test Accounts: </label>
                <ByzzerSwitch name="includeTestAccounts" onChange={handleIncludeTestAccountsChange}></ByzzerSwitch>
            </div>

            <div className={generalStyles['input-wrapper']}>
                <label className={generalStyles.switchLabel}>Show Only Active Contracts: </label>
                <ByzzerSwitch name="showActiveContracts" onChange={handleShowActiveContracts}></ByzzerSwitch>
            </div>
            <br />

            <button
                className={generalStyles.submitButton}
                onClick={getCategoryLicenseDetails}
                disabled={!isGenerateReportsEnabled()}
            >
                Generate Report
            </button>
            {reportApiStatus === API_STATES.error ? (
                <span className={generalStyles.errorMessage}>Error: {reportApiError}</span>
            ) : reportApiStatus === API_STATES.loading ? (
                <span className={generalStyles.statusMessage}>Loading...</span>
            ) : (
                reportApiStatus === API_STATES.success && (
                    <>
                        {reportResult?.length > 0 ? (
                            <DataTable data={reportResult} columns={COLUMNS} withDownload downloadFileName={'CategoryLicensedReport'}/>
                        ) : (
                            <EmptyTable
                                noValueDescription="No client users matching the selected categories."
                                columns={[
                                    'Company Id',
                                    'Company Name',
                                    'Customer Email',
                                    'First Name',
                                    'Last Name',
                                    'Subscription Type',
                                    'Category Config',
                                    'Contract Start Date',
                                    'Contract End Date',
                                ]}
                            />
                        )}
                    </>
                )
            )}
        </div>
    );
}

export default CategoryLicenseReport;
