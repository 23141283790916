import React, { useState, useEffect } from 'react';
import generalStyles from '@/components/General/Styles.module.scss';
import 'reactjs-popup/dist/index.css';
import styles from '../MarketMaps.module.scss';
import API_STATES from '@/constants/StateConstants';
import { CreateFolder } from '../CreateFolder';
import { handleApiError } from '@/components/General/HandleApiError';
import FolderList from '../FolderList';
import * as API from '@/services/api/product.service';

// import styles from '../../HomepageNotification/HomepageDataTable.module.scss';

function SyndicateMarketMaps(props) {
    const [folderListApiStatus, setfolderListApiStatus] = useState(API_STATES.none);
    const [folderListApiError, setfolderListApiError] = useState('');
    const [folderList, setFolderList] = useState([]);
    const [folderListUpdated, setFolderListUpdated] = useState(false);
    const marketMapType = 'syndicated';

    const [addFolderSelected, setAddFolderSelected] = useState(false);

    async function getFolderList() {
        setfolderListApiStatus(API_STATES.loading);
        const result = await API.getFoldersForMarketMaps(marketMapType);
        switch (result.status) {
            case API_STATES.success:
                setfolderListApiStatus(API_STATES.success);
                setFolderList(result.data);
                setFolderListUpdated(false);
                break;
            case API_STATES.error:
                let error = result.error;
                handleApiError(error, setfolderListApiStatus, setfolderListApiError);
                break;
            default:
                setfolderListApiError('');
                setfolderListApiStatus(API_STATES.none);
        }
    }

    useEffect(() => {
        getFolderList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (folderListUpdated) {
            getFolderList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [folderListUpdated]);

    return (
        <div>
            <h1>Syndicate Market Maps</h1>
            <hr />
            <div>
                <button className={generalStyles.submitButton} onClick={() => setAddFolderSelected(true)}>
                    Add Folder
                </button>
            </div>
            <h3>Folders List</h3>
            <div className={styles.folderList}>
                {folderListApiStatus === API_STATES.error ? (
                    <p className={generalStyles.errorMessage}>Error: {folderListApiError}</p>
                ) : folderListApiStatus === API_STATES.none ? (
                    <p className={generalStyles.statusMessage}>Folder List</p>
                ) : folderListApiStatus === API_STATES.loading ? (
                    <p className={generalStyles.statusMessage}>Loading...</p>
                ) : folderListApiStatus === API_STATES.success ? (
                    <>
                        <FolderList
                            folders={folderList}
                            marketMapType={marketMapType}
                            onUpdate={() => setFolderListUpdated(true)}
                        />
                    </>
                ) : null}
            </div>

            {addFolderSelected && (
                <CreateFolder
                    marketMapType={marketMapType}
                    isaddFolderSelected={addFolderSelected}
                    onClose={() => setAddFolderSelected(false)}
                    onFolderUpdate={() => setFolderListUpdated(true)}
                />
            )}
        </div>
    );
}
export default SyndicateMarketMaps;
