import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import API_STATES from '@/constants/StateConstants';
import generalStyles from '@/components/General/Styles.module.scss';
import DataTable, { FILTER_TYPES } from '../General/DataTable';
import { MultiSelectColumnFilter } from '../General/MultiSelectColumnFilter';
import { DateRangeColumnFilter } from '../General/DateRangeColumnFilter';
import editIcon from '@images/icons/EditIcon.svg';
import styles from './ByzzerAccessEndDateReport.module.scss'
import Popup from 'reactjs-popup';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import calendarIcon from '@images/icons/calendar.svg';
import moment from 'moment';
import { isoStringToDate } from '../utils/utils';
import { handleApiError } from '../General/HandleApiError';
import { getByzzerAccessEndDatesReport } from '@/services/api/report.service';
import { updateByzzerAccessEndDate } from '@/services/api/company.service';
import store from '../../store';

const ByzzerAccessEndDateReport = () => {
    const [editingAccessEndDateRecord, setEditingAccessEndDateRecord] = useState(null)
    const [byzzerAccessEndDateInput, setByzzerAccessEndDateInput] = useState(moment())

    const { isSuperUser } = store?.getState()?.account;
    const isEditable = isSuperUser

    const [reportApiStatus, setReportApiStatus] = useState(API_STATES.none);
    const [reportApiError, setReportApiError] = useState('')
    const [reportData, setReportData] = useState([])

    function onEditAccessEndDate(rowData) {
        setEditingAccessEndDateRecord(rowData)
        setByzzerAccessEndDateInput(moment(rowData.byzzerAccessEndDate ?? new Date()))
    }

    const [updateAccessEndDateApiError, setUpdateAccessEndDateApiError] = useState('');
    const [updateAccessEndDateApiStatus, setUpdateAccessEndDateApiStatus] = useState(API_STATES.none);

    async function submitUpdateAccessEndDateRequest(nsCompanyId, byzzerAccessEndDate) {
        setUpdateAccessEndDateApiStatus(API_STATES.loading);
        let result = await updateByzzerAccessEndDate(nsCompanyId, byzzerAccessEndDate);
        if (result.status === API_STATES.success) {
            setUpdateAccessEndDateApiStatus(API_STATES.success);
            loadByzzerAccessEndDateReport()
            setTimeout(() => {
                cancelEdit();
                setUpdateAccessEndDateApiStatus(API_STATES.none)
            }, 3000)
        } else {
            handleApiError(
                result.error,
                setUpdateAccessEndDateApiStatus,
                setUpdateAccessEndDateApiError
            );
            setTimeout(() => setUpdateAccessEndDateApiStatus(API_STATES.none), 6000)
        }
    }

    const COLUMNS = useMemo(() => {
        const columns = [
            {
                Header: 'Company Name',
                accessor: 'companyName',
                className: generalStyles.tableCell,
                Cell: ({ row, value }) => (
                    <Link className={generalStyles.link} to={`/newcompanypage/${row.original.nsCompanyId}`}>
                        {value}
                    </Link>
                ),
                Filter: MultiSelectColumnFilter,
                filter: FILTER_TYPES.multiple,
            },
            {
                Header: 'Subscription',
                accessor: 'subscriptionName',
                className: generalStyles.tableCell,
                Filter: MultiSelectColumnFilter,
                filter: FILTER_TYPES.multiple,
            },
            {
                Header: 'Summary',
                accessor: 'summary',
                className: generalStyles.tableCell,
                Filter: MultiSelectColumnFilter,
                filter: FILTER_TYPES.multiple,
            },
            {
                Header: 'Byzzer Access End Date',
                accessor: (row) => row.byzzerAccessEndDate?.toLocaleDateString() ?? null,
                id: 'byzzerAccessEndDate',
                className: generalStyles.tableCell,
                Filter: DateRangeColumnFilter,
                filter: FILTER_TYPES.dateRange,
            },
            {
                Header: 'Contract Expiry Date',
                accessor: (row) => row.contractExpiryDate?.toLocaleDateString() ?? null,
                id: 'contractExpiryDate',
                className: generalStyles.tableCell,
                Filter: DateRangeColumnFilter,
                filter: FILTER_TYPES.dateRange
            },
            {
                Header: "MYA",
                accessor: (row) => row.mya ? "Y" : "N",
                id: "mya",
                className: generalStyles.tableCell,
                Filter: MultiSelectColumnFilter,
                filter: FILTER_TYPES.multiple

            },
            {
                Header: 'True Contract End Date',
                accessor: (row) => row.trueContractEndDate?.toLocaleDateString() ?? null,
                id: 'trueContractEndDate',
                className: generalStyles.tableCell
            }
        ]
        const actionsColumn = {
            Header: "Action",
            accessor: (row) => row,
            id: 'actions',
            disableFilters: true,
            disableSortBy: true,
            className: generalStyles.tableCell,
            Cell: ({ value: row }) => (
                <div
                    className={styles.editIconContainer}
                    onClick={() => onEditAccessEndDate(row)}
                >
                    <img src={editIcon} alt="edit" className={styles.editIcon} />
                </div>
            )
        }
        if (isEditable) {
            columns.push(actionsColumn)
        }
        return columns
    }, [isEditable])

    useEffect(() => {
        loadByzzerAccessEndDateReport()
    }, []);

    async function loadByzzerAccessEndDateReport() {
        setReportApiStatus(API_STATES.loading)
        const result = await getByzzerAccessEndDatesReport();
        switch (result.status) {
            case API_STATES.success:
                setReportApiStatus(API_STATES.success);
                const data = result.data.map((item) => {
                    const contractExpiryDate = isoStringToDate(item.contractExpiryDate)
                    const trueContractEndDate = isoStringToDate(item.trueContractEndDate)
                    const byzzerAccessEndDate = isoStringToDate(item.accessEndDate)
                    const summary = !item.hasSubscription ? (
                        'No subscription'
                    ) : !byzzerAccessEndDate ? (
                        'Access end date is null'
                    ) : item.hasMultiYearAgreement ? 'Has mya, ' + (
                        !trueContractEndDate ? (
                            'true contract end date is null'
                        ) : byzzerAccessEndDate < trueContractEndDate ? (
                            'access is less than true contract end date'
                        ) : byzzerAccessEndDate > trueContractEndDate ? (
                            'access is greater than true contract end date'
                        ) : (
                            'access is same as true contract end date'
                        )
                    ) : 'No mya, ' + (
                        !contractExpiryDate ? (
                            'has subscription but contract expiry date is null'
                        ) : byzzerAccessEndDate < contractExpiryDate ? (
                            'access is less than contract expiry date'
                        ) : byzzerAccessEndDate > contractExpiryDate ? (
                            'access is greater than contract expiry date'
                        ) : (
                            'access is same as contract expiry date'
                        )
                    ) 
                    return {
                        nsCompanyId: item.nsCompanyId,
                        companyName: item.companyName,
                        subscriptionName: item.subscriptionName,
                        mya: item.hasMultiYearAgreement,
                        contractExpiryDate,
                        trueContractEndDate,
                        byzzerAccessEndDate,
                        summary
                    }
                });
                setReportData(data)
                setEditingAccessEndDateRecord(null)
                break;
            case API_STATES.error:
                handleApiError(result.error, setReportApiStatus, setReportApiError);
                break;
            default:
                setReportApiStatus(API_STATES.none);
                setReportApiError('');
        }
    }

    function cancelEdit() {
        if (
            updateAccessEndDateApiStatus !== API_STATES.loading
            && updateAccessEndDateApiStatus !== API_STATES.success
        ) {
            setEditingAccessEndDateRecord(null)
        }
    }


    return (
        <>
            <h1>Byzzer Access End Date Report</h1>
            <hr />
            {reportApiStatus === API_STATES.error ? (
                <span className={generalStyles.errorMessage}>Error: {reportApiError}</span>
            ) : reportApiStatus === API_STATES.loading ? (<span className={generalStyles.statusMessage}>Loading...</span>
            ) : (
                <DataTable columns={COLUMNS} data={reportData} withDownload noDataDescription='companies not found' />
            )}
            {isEditable && editingAccessEndDateRecord &&
                <Popup
                    open={editingAccessEndDateRecord !== null}
                    onClose={cancelEdit}
                    modal
                >
                    <div className={styles.editFormHeaderContainer}>
                        <h3>Edit Byzzer Access End Date</h3>
                        <span className={styles.closeButton} onClick={cancelEdit}>X</span>
                    </div>
                    <div className={styles.editFormContent}>
                        <div className={generalStyles['input-wrapper']}>
                            <label className={generalStyles.longLabel}>Company Name:</label>
                            <span>
                                {editingAccessEndDateRecord.companyName}
                            </span>
                        </div>
                        <div className={generalStyles['input-wrapper']}>
                            <label className={generalStyles.longLabel}>Subscription:</label>
                            <span>
                                {editingAccessEndDateRecord.subscriptionName || 'N/A'}
                            </span>
                        </div>
                        <div className={generalStyles['input-wrapper']}>
                            <label className={generalStyles.longLabel}>Multi Year Agreement:</label>
                            {editingAccessEndDateRecord.mya ?
                                <span className={generalStyles.successMessage}>Yes</span>
                                : <span className={generalStyles.errorMessage}>No</span>
                            }
                        </div>
                        <div className={generalStyles['input-wrapper']}>
                            <label className={generalStyles.longLabel}>True Contract End Date:</label>
                            <span>
                                {editingAccessEndDateRecord.trueContractEndDate?.toLocaleDateString() || 'N/A'}
                            </span>
                        </div>
                        <div className={generalStyles['input-wrapper']}>
                            <label className={generalStyles.longLabel}>Contract Expiry Date:</label>
                            <span>
                                {editingAccessEndDateRecord.contractExpiryDate?.toLocaleDateString() || 'N/A'}
                            </span>
                        </div>
                        <div className={generalStyles['input-wrapper']}>
                            <label>Access End Date:</label>
                            <div className={styles.dateContainer}>
                                <DatePicker
                                    name="trueContractEndDate"
                                    placeholderText="mm/dd/yyyy"
                                    shouldCloseOnSelect={true}
                                    fixedHeight
                                    closeOnScroll={true}
                                    dateFormat="MMM d, y"
                                    selected={byzzerAccessEndDateInput.toDate()}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    tabIndex={1}
                                    disabled={updateAccessEndDateApiStatus === API_STATES.loading || updateAccessEndDateApiStatus === API_STATES.success}
                                    onChange={(date) => setByzzerAccessEndDateInput(moment(date))}
                                    preventOpenOnFocus={true}
                                />
                                <img src={calendarIcon} alt="calendar icon" autoFocus={true} />
                            </div>
                        </div>
                        {byzzerAccessEndDateInput < editingAccessEndDateRecord.byzzerAccessEndDate &&
                            <b>
                                You are setting the access end date to be earlier than the current end date.
                                Please make sure that you want to reduce the number of days of access for this company.
                            </b>
                        }
                        <div className={styles.buttonContainer}>
                            <button
                                onClick={() => submitUpdateAccessEndDateRequest(
                                    editingAccessEndDateRecord.nsCompanyId,
                                    byzzerAccessEndDateInput
                                )}
                                className={
                                    byzzerAccessEndDateInput < editingAccessEndDateRecord.byzzerAccessEndDate ?
                                        generalStyles.confirmOperationButton
                                        : generalStyles.submitButton
                                }
                                disabled={
                                    updateAccessEndDateApiStatus === API_STATES.loading ||
                                    updateAccessEndDateApiStatus === API_STATES.success
                                }
                            >
                                Save
                            </button>                
                            {
                                updateAccessEndDateApiStatus === API_STATES.loading ? (
                                    <span className={generalStyles.statusMessage}>
                                        Updating access end date...
                                    </span>
                                ) : updateAccessEndDateApiStatus === API_STATES.error ? (
                                    <span className={generalStyles.errorMessage}>
                                        {updateAccessEndDateApiError}
                                    </span>
                                ) : updateAccessEndDateApiStatus === API_STATES.success ? (
                                    <span className={generalStyles.successMessage}>
                                        Successfully updated access end date
                                    </span>
                                ) : <button
                                    className={generalStyles.submitButton}
                                    onClick={cancelEdit}
                                    disabled={updateAccessEndDateApiStatus === API_STATES.loading}
                                >
                                    Cancel
                                </button>
                            }
                        </div>
                    </div>
                </Popup>
            }
        </>
    )
}

export default ByzzerAccessEndDateReport