import React from 'react';
import PropTypes from 'prop-types';
import generalStyles from '@/components/General/Styles.module.scss';

function EmptyTable(props) {
    return (
        <table>
            <thead>
                <tr className={generalStyles.tableRow}>
                    {props.columns.map((col) => {
                        return (
                            <th className={generalStyles.nonStickyTableHeader} key={col}>
                                {col}
                            </th>
                        );
                    })}
                </tr>
            </thead>
            <tbody>
                <tr className={generalStyles.tableRow}>
                    <td colSpan={1000} className={generalStyles.tableCell}>
                        <b>{props.noValueDescription}</b>
                    </td>
                </tr>
            </tbody>
        </table>
    );
}

EmptyTable.propTypes = {
    noValueDescription: PropTypes.string.isRequired,
    columns: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default EmptyTable;
