import React, { useState, useEffect } from 'react';
import API_STATES from '@/constants/StateConstants';
import * as API from '@/services/api/company.service';
import generalStyles from '@/components/General/Styles.module.scss';
import { handleApiError } from '@/components/General/HandleApiError';
import TrialProductsList from '@/components/ExtendTrial/TrialProductsList';

function ExtendTrial({ nsCompanyId, isEditable = true }) {

    const [trialProductsApiStatus, setTrialProductsApiStatus] = useState(API_STATES.none);
    const [trialProductsApiError, setTrialProductsApiError] = useState('');

    const [trialProducts, setTrialProducts] = useState([]);
    useEffect(() => {
        if (nsCompanyId !== 0) {
            getTrialProducts(nsCompanyId);
        }
    }, [nsCompanyId]);

    async function getTrialProducts(companyid) {
        setTrialProductsApiStatus(API_STATES.loading);
        const result = await API.getTrialAddonsByCompany(companyid);
        switch (result.status) {
            case API_STATES.success:
                setTrialProductsApiStatus(API_STATES.success);
                const trialData = result.data.map((row) => {
                    return {
                        ns_catalog_item_id: row?.ns_catalog_item_id,
                        ns_catalog_item_title: row?.ns_catalog_item_title,
                        ns_contract_item_id: row?.ns_contract_item_id,
                        original_expiry: row?.expiry_date,
                        product_type: row.product_type
                    };
                });
                const subscriptionArr = trialData.filter((entry) => entry.product_type === 'access.subscription');
                const bundleArr = trialData.filter((entry) => entry.product_type === 'access.bundle');
                const mergedArr = subscriptionArr.concat(bundleArr);
                setTrialProducts(mergedArr);
                break;
            case API_STATES.error:
                let error = result.error;
                handleApiError(error, setTrialProductsApiStatus, setTrialProductsApiError);
                break;
            default:
                setTrialProductsApiError('');
                setTrialProductsApiStatus(API_STATES.none);
        }
    }

    return (
        <>
            <div>
                {trialProductsApiStatus === API_STATES.error && (
                    <span className={generalStyles.errorMessage}>{trialProductsApiError}</span>
                )}
                {trialProductsApiStatus === API_STATES.success && (
                    <>
                        {trialProducts?.length > 0 ? (
                            <TrialProductsList trialProducts={trialProducts} nsCompanyId={nsCompanyId} isEditable={isEditable}/>
                        ) : (
                            <p className={generalStyles.statusMessage}>No trial add-on(s) for this user's company!</p>
                        )}
                    </>
                )}
            </div>
        </>
    );
}
export default ExtendTrial;
