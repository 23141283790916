import React, { useState, useEffect } from 'react';
import styles from './SetupNewCompany.module.scss';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import generalStyles from '@/components/General/Styles.module.scss';
import calendarIcon from '@images/icons/calendar.svg';
import moment from 'moment';
import BuySubscription from '@/components/AccountSetup/BuySubscription';
import Cart from '@/components/AccountSetup/Cart';
import BuyBundles from '@/components/AccountSetup/BuyBundles';
import BuyReports from '@/components/AccountSetup/BuyReports';
import Select from 'react-select';
import TextInput from '@/components/General/TextInput';
import { getPaymentTermOptions } from '@/services/api/company.service';
import API_STATES from '@/constants/StateConstants';
import { billingFrequencyOptions, evergreenOptions } from '@/constants/globalVars'

function PlaceOrder(props) {
    const nsCompanyId = props.nsCompanyId;
    // const [showItems, setShowItems] = useState(true);
    const [contractStartDate, setContractStartDate] = useState(moment());
    const [contractEndDate, setContractEndDate] = useState(
        moment()
            .add(1, 'y')
            .subtract(1, 'd')
    );
    const [trueContractEndDate, setTrueContractEndDate] = useState(
        moment()
            .add(1, 'y')
            .subtract(1, 'd')
    );
    const [cartItems, setCartItems] = useState([]);
    const [editableItem, setEditableItem] = useState({});
    const [selectedEvergreenOption, setSelectedEvergreenOption] = useState({ value: false, label: 'No' });
    const [selectedBillingFrequencyOption, setSelectedBillingFrequencyOption] = useState({});
    const [companyHasPurchase, setCompanyHasPurchase] = useState(props.companyHasPurchase);
    const [dynamicsDealNum, setDynamicsDealNum] = useState('');
    const [isMultiYearAgreement, setMultiYearAgreement] = useState(false);
    const [totalBill, setTotalBill] = useState(0);

    const [paymentTermOptionsApiState, setPaymentTermOptionsApiState] = useState(API_STATES.none);
    const [paymentTermOptionsApiError, setPaymentTermOptionsApiError] = useState('');
    const [selectedPaymentTermsOption, setSelectedPaymentTermsOption] = useState({label: 'Net 30', value: 2})
    const [paymentTermOptions, setPaymentTermOptions] = useState([])
    
    useEffect(() => {
        const total = cartItems.reduce((accumulator, object) => {
            return accumulator + object.sectionTotal;
        }, 0);
        setTotalBill(parseFloat(total).toFixed(2));
    }, [cartItems]);

    useEffect(()=>{
        (async()=>{
            const response  = await getPaymentTermOptions()
            if (response.status === API_STATES.success) {
                setPaymentTermOptionsApiState(API_STATES.success)
                const paymentTermOptions = Object.entries(response.data).map(([label, id])=>({ label: label, value: id}))
                setPaymentTermOptions([...paymentTermOptions])
                setTimeout(() => setPaymentTermOptionsApiState(API_STATES.none), 1000);
            } else {
                handleApiError(response.error, setPaymentTermOptionsApiState, setPaymentTermOptionsApiError);
                setTimeout(() => setPaymentTermOptionsApiState(API_STATES.none), 6000)
            }
        })()
    }, [])

    useEffect(() => {
        if (props.companyInfo?.nsCompanyId) {
            setDynamicsDealNum('');
            setCartItems([]);
            if (props.companyInfo?.contractStart !== null && props.companyInfo?.contractEnd !== null) {
                //setShowItems(true);
                setContractStartDate(moment(props.companyInfo?.contractStart));
                setContractEndDate(moment(props.companyInfo?.contractEnd));
                setTrueContractEndDate(moment(props.companyInfo?.setTrueContractEndDate));
            } else {
                //setShowItems(false);
                setContractStartDate(moment());
                setContractEndDate(
                    moment()
                        .add(1, 'y')
                        .subtract(1, 'd')
                );
                setTrueContractEndDate(
                    moment()
                        .add(1, 'y')
                        .subtract(1, 'd'));
            }
        }
    }, [props.companyInfo]);

    useEffect(() => {
        setCompanyHasPurchase(props.companyHasPurchase);
    }, [props.companyHasPurchase]);

    function onMultiYearAgreementCheckboxClick(isChecked) {
        setMultiYearAgreement(isChecked);
    };

    return (
        <>
            {/* {showItems && ( */}
                <>
                    <div className={styles.expiryDateTimeForm}>
                        <span>Contract Start Date: </span>
                        <div className={styles.date}>
                            <DatePicker
                                name="contractStartDate"
                                placeholderText="mm/dd/yyyy"
                                shouldCloseOnSelect={true}
                                fixedHeight
                                closeOnScroll={true}
                                maxDate={new Date(2025, 11, 31, 23, 59, 59)}
                                minDate={new Date(2020, 0, 1, 0, 0, 0)}
                                dateFormat="MMM d, y"
                                selected={contractStartDate.toDate()}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                tabIndex={1}
                                onChange={(date) => {
                                    setContractStartDate(moment(date));
                                }}
                            />

                            <img src={calendarIcon} alt="calendar icon" />
                        </div>
                        <span>Contract End Date: </span>
                        <div className={styles.date}>
                            <DatePicker
                                name="contractEndDate"
                                placeholderText="mm/dd/yyyy"
                                shouldCloseOnSelect={true}
                                fixedHeight
                                closeOnScroll={true}
                                maxDate={new Date(2030, 11, 31, 23, 59, 59)}
                                minDate={new Date()}
                                dateFormat="MMM d, y"
                                selected={contractEndDate.toDate()}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                tabIndex={1}
                                onChange={(date) => {
                                    setContractEndDate(moment(date));
                                }}
                            />

                            <img src={calendarIcon} alt="calendar icon" />
                        </div>
                    </div>
                    <br />
                    <div className={styles.formItem}>
                        <TextInput
                            type="text"
                            label="Dynamics Deal Number:"
                            value={dynamicsDealNum}
                            onInputChange={(event) => setDynamicsDealNum(event.target.value)}
                            required={false}
                        />
                    </div>
                    <div className={generalStyles['input-wrapper']}>
                        <label>Evergreen:</label>
                        <span className={styles.formField}>
                            <Select
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 4,
                                    colors: {
                                        ...theme.colors,
                                        text: 'black',
                                        primary25: '#cfd1d2',
                                        primary: 'hsl(120, 100%, 47%)',
                                        neutral0: 'white'
                                    }
                                })}
                                onChange={(option)=>setSelectedEvergreenOption(option)}
                                options={evergreenOptions}
                                value={selectedEvergreenOption}
                                isDisabled={false}
                            />
                        </span>
                        <br />
                    </div>
                    <div className={generalStyles['input-wrapper']}>
                        <label>Billing Frequency:</label>
                        <span className={styles.formField}>
                            <Select
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 4,
                                    colors: {
                                        ...theme.colors,
                                        text: 'black',
                                        primary25: '#cfd1d2',
                                        primary: 'hsl(120, 100%, 47%)',
                                        neutral0: 'white'
                                    }
                                })}
                                onChange={(option)=>setSelectedBillingFrequencyOption(option)}
                                options={billingFrequencyOptions}
                                value={selectedBillingFrequencyOption}
                                isDisabled={false}
                            />
                        </span>
                    </div>
                    {paymentTermOptionsApiState === API_STATES.loading ? (
                        <p className={generalStyles.statusMessage}>loading payment terms options...</p> 
                    ) : ( paymentTermOptionsApiState === API_STATES.error ? (
                        <p className={generalStyles.errorMessage}>{paymentTermOptionsApiError}</p>
                        ) : (
                            <div className={generalStyles['input-wrapper']}>
                                <label>Payment Terms:</label>
                                <span className={generalStyles.formField}>
                                    <Select
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 4,
                                            colors: {
                                                ...theme.colors,
                                                text: 'black',
                                                primary25: '#cfd1d2',
                                                primary: 'hsl(120, 100%, 47%)',
                                                neutral0: 'white'
                                            }
                                        })}
                                        onChange={(option)=>setSelectedPaymentTermsOption(option)}
                                        options={paymentTermOptions}
                                        value={selectedPaymentTermsOption}
                                        isDisabled={false}
                                    />
                                </span>
                            </div>
                        )
                    )}
                    <div className={generalStyles['input-wrapper']}>
                        <label>Multi Year Agreement: </label>
                        <input
                            type="checkbox"
                            checked={isMultiYearAgreement}
                            onChange={(e) => onMultiYearAgreementCheckboxClick(e.target.checked)}
                        />
                    </div>
                    <br/>
                    <div className={styles.expiryDateTimeForm}>    
                        <span>True Contract End Date: </span>
                        <div className={styles.date}>
                            <DatePicker
                                name="trueContractEndDate"
                                placeholderText="mm/dd/yyyy"
                                shouldCloseOnSelect={true}
                                fixedHeight
                                closeOnScroll={true}
                                minDate={new Date()}
                                dateFormat="MMM d, y"
                                selected={trueContractEndDate.toDate()}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                tabIndex={1}
                                disabled={isMultiYearAgreement === false}
                                onChange={(date) => {
                                    setTrueContractEndDate(moment(date));
                                }}
                            />
                            <img src={calendarIcon} alt="calendar icon" />
                        </div>


                    </div>

                    
                </>
            {/* )} */}
            <div className={styles.row}>
                <div>
                    <h4>Purchase Items</h4>
                    <div className={styles.purchaseItems}>
                        <BuySubscription
                            addCartItem={(val) => setCartItems([...cartItems, val])}
                            items={cartItems}
                            contractStart={moment(
                                `${contractStartDate.format('YYYY-MM-DD')}`,
                                'YYYY-MM-DD hh:mm a'
                            ).toDate()}
                            contractEnd={moment(
                                `${contractEndDate.format('YYYY-MM-DD')}`,
                                'YYYY-MM-DD hh:mm a'
                            ).toDate()}
                            editableItem={editableItem?.type === 'Subscription' ? editableItem : null}
                        />
                        <br />
                        <hr />
                        <BuyBundles
                            addCartItem={(val) => setCartItems([...cartItems, val])}
                            items={cartItems}
                            contractStart={moment(
                                `${contractStartDate.format('YYYY-MM-DD')}`,
                                'YYYY-MM-DD hh:mm a'
                            ).toDate()}
                            contractEnd={moment(
                                `${contractEndDate.format('YYYY-MM-DD')}`,
                                'YYYY-MM-DD hh:mm a'
                            ).toDate()}
                            editableItem={editableItem?.type === 'Bundle' ? editableItem : null}
                        />
                        <br />
                        <hr />
                        <BuyReports
                            addCartItem={(val) => setCartItems([...cartItems, val])}
                            contractStart={moment(
                                `${contractStartDate.format('YYYY-MM-DD')}`,
                                'YYYY-MM-DD hh:mm a'
                            ).toDate()}
                            contractEnd={moment(
                                `${contractEndDate.format('YYYY-MM-DD')}`,
                                'YYYY-MM-DD hh:mm a'
                            ).toDate()}
                            editableItem={editableItem?.type === 'Report' ? editableItem : null}
                        />
                    </div>
                </div>
                <div>
                    <div>
                        <div className={styles.billHeading}>
                            <h4>Cart</h4>
                            <h4>Total Bill: ${totalBill}</h4>
                        </div>

                        <div className={styles.purchaseItems}>
                            <Cart
                                setEditableItem={(item) => setEditableItem(item)}
                                items={cartItems}
                                nsCompanyId={nsCompanyId}
                                resetCreateOrder={props.resetCreateOrder}
                                updatedPurchases={props.updatedPurchases}
                                contractStart={moment(
                                    `${contractStartDate.format('YYYY-MM-DD')}`,
                                    'YYYY-MM-DD hh:mm a'
                                ).toDate()}
                                contractEnd={moment(
                                    `${contractEndDate.format('YYYY-MM-DD')}`,
                                    'YYYY-MM-DD hh:mm a'
                                ).toDate()}
                                isEvergreen={selectedEvergreenOption.value}
                                billingFrequency={selectedBillingFrequencyOption.value}
                                netsuitePaymentTerm={selectedPaymentTermsOption.value }
                                dynamicsDealNum={dynamicsDealNum}
                                isMultiYearAgreement={isMultiYearAgreement}
                                trueContractEndDate={moment(
                                    `${trueContractEndDate.format('YYYY-MM-DD')}`,
                                    'YYYY-MM-DD hh:mm a'
                                ).toDate()}
                                removeCartItem={(sku) => {
                                    setCartItems(cartItems.filter((item) => item.sku !== sku));
                                }}
                                companyHasPurchase={companyHasPurchase}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default PlaceOrder;
