import React, { forwardRef, useEffect, useMemo } from 'react';
import classnames from 'classnames';
import { NavLink } from 'react-router-dom';
import { Tooltip } from 'antd';
import './HeaderNav.scss';
import InlineSVG from 'react-inlinesvg/esm';
import store from '@/store';

export const HeaderNav = forwardRef(
    (
        {
            onToggleClick, // onclick of menuitem with subitems
            expanded,
            disabled = false,
            iconSrc,
            children,
            content = children,
            active,
            url, // to navigate
            type, // used as a suffix for class name and as argument to onClick, onToggleClick
            onClick, // onclick event handler
            subItems,
            className,
            title, // text to be displayed in tooltip
            isHeader = false,
            toggleParentMenu,
            topHeaderItem,
            hasHr = false,
            accessibleBy = null, // null indicates accessible by all roles
            ...props
        },
        ref
    ) => {
        const baseClassName = 'header-nav-item';
        const storeState = store?.getState();

        const separatorLine = <hr className={baseClassName + '--separator-line'} />;

        useEffect(() => {
            if (subItems) {
                // alert('subitems');
                // remove any url empty urls and append * to the end if necessary to match sub-routes
                // const urls = subItems.filter(({url}) => url).map(({url}) => url.endsWith('*') ? url : `${url}/*`);
                // setExpanded(expanded => expanded || urls.some(url => Boolean(matchPath(url, location.pathname))))
            }
        }, [subItems]);

        const isAccessible = useMemo(() => {
            if (accessibleBy) {
                const roles = storeState?.account?.user?.csrUserRoles;
                const matchingRole = accessibleBy.find((requiredRole) => roles?.includes(requiredRole));
                return matchingRole !== undefined;
            } else return true;
        }, [accessibleBy, storeState]);

        useEffect(() => {
            if (!expanded) {
                toggleParentMenu && toggleParentMenu();
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [expanded]);

        if (!isAccessible) {
            return <></>;
        } else if (disabled) {
            return (
                <Tooltip placement="right" overlayClassName={`${baseClassName}--tooltip`} title={title}>
                    <div
                        className={classnames(baseClassName, `${baseClassName}--${type}`, `${baseClassName}--disabled`)}
                        ref={ref}
                    >
                        {iconSrc && <InlineSVG src={iconSrc} className={`${baseClassName}__icon`} />}
                        <span className={`${baseClassName}__text`}>{content}</span>
                    </div>
                    {hasHr && separatorLine}
                </Tooltip>
            );
        } else if (onClick) {
            return (
                <Tooltip placement="right" overlayClassName={`${baseClassName}--tooltip`} title={title}>
                    <div
                        className={classnames(baseClassName, className, `${baseClassName}--${type}`)}
                        onClick={() => onClick(type)}
                        ref={ref}
                        {...props}
                    >
                        {iconSrc && <InlineSVG src={iconSrc} className={`${baseClassName}__icon`} />}
                        {content && (
                            <span
                                className={classnames(`${baseClassName}__text`, 'header-nav__top-nav-sub-items-text')}
                            >
                                {content}
                            </span>
                        )}
                    </div>
                    {hasHr && separatorLine}
                </Tooltip>
            );
        } else if (subItems) {
            return (
                <>
                    <div
                        className={classnames(
                            baseClassName,
                            `${baseClassName}--${type}`,
                            `${expanded && 'header-nav__dropdown-expanded'} `,
                            `${baseClassName}--toggle}`,
                            {
                                // [`${baseClassName}--expanded`]: expanded,
                            }
                        )}
                        onClick={() => onToggleClick(type)}
                        {...props}
                        ref={ref}
                    >
                        {iconSrc && <InlineSVG src={iconSrc} className={`${baseClassName}__icon`} />}
                        {content && <span className={`${baseClassName}__text`}>{content}</span>}
                    </div>

                    {expanded && (
                        <div
                            className={classnames('header-nav__sub-items', {
                                [`header-nav__sub-items--expanded`]: expanded,
                            })}
                        >
                            {subItems.map((props) => (
                                <HeaderNav key={props.content} {...props} />
                            ))}
                        </div>
                    )}
                    {hasHr && separatorLine}
                </>
            );
        } else if (url) {
            return (
                <Tooltip placement="right" overlayClassName={`${baseClassName}--tooltip`} title={title}>
                    <NavLink
                        className={({ isActive }) =>
                            classnames(baseClassName, className, `${baseClassName}--${type}`, {
                                [`${baseClassName}--active ${
                                    content === 'My Items' && ` header-nav__border-subitems`
                                }`]: isActive,
                            })
                        }
                        to={url}
                        {...props}
                        ref={ref}
                    >
                        {/* {!topHeaderItem &&  */}
                        {iconSrc && <InlineSVG src={iconSrc} className={`${baseClassName}__icon`} />}
                        <span className={`${baseClassName}__text`}>{content}</span>
                        {/* } {topHeaderItem && <span className="user-setting-sub-items">{content}</span>} */}
                    </NavLink>
                    {hasHr && separatorLine}
                </Tooltip>
            );
        } else if (content) {
            return (
                <Tooltip placement="right" overlayClassName={`${baseClassName}--tooltip`} title={title}>
                    <div
                        className={classnames(baseClassName, className, `${baseClassName}--${type}`)}
                        ref={ref}
                        {...props}
                    >
                        <span className={classnames(`${baseClassName}__text`, 'header-nav__top-nav-sub-items-text')}>
                            {iconSrc && <InlineSVG src={iconSrc} className={`${baseClassName}__icon`} />}
                            {content}
                        </span>
                    </div>
                    {hasHr && separatorLine}
                </Tooltip>
            );
        } else return <></>;
    }
);
