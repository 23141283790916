import React, { useMemo, useState } from 'react';
import Select from 'react-select';
import styles from './CompanyConfig.module.scss';
import generalStyles from '@/components/General/Styles.module.scss';
import API_STATES from '@/constants/StateConstants';
import TextInput from '../General/TextInput';
import { editOmniPriorApprovedMarket } from '@/services/api/company.service';
import { handleApiError } from '../General/HandleApiError';
import ByzzerSwitch from '../General/ByzzerSwitch';

function EditOmniPriorApprovedMarketForm(props) {
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [confirmationUrl, setConfirmationUrl] = useState('');
    const [isPartial, setIsPartial] = useState(false);

    const [editMarketApiState, setEditMarketApiState] = useState(API_STATES.none);
    const [editMarketApiError, setEditMarketApiError] = useState('');

    async function editSelectedPriorApprovalMarket() {
        setEditMarketApiState(API_STATES.loading);
        const marketId = selectedMarket.value;
        const categoryKeys = selectedCategories.map((category) => category.value);
        const editMarketResponse = await editOmniPriorApprovedMarket(
            props.nsCompanyId,
            marketId,
            confirmationUrl,
            isPartial,
            categoryKeys
        );
        if (editMarketResponse.status === API_STATES.success) {
            setEditMarketApiState(API_STATES.success);
            props.onSuccess();
        } else {
            handleApiError(editMarketResponse.error, setEditMarketApiState, setEditMarketApiError)
        }
        setTimeout(() => setEditMarketApiState(API_STATES.none), 2000);
    }

    const transformCategories = (categories) => {
        setSelectedCategories([]);
        return (
            categories?.map((category) => {
                return { label: category.category, value: category.categoryKey };
            }) ?? []
        );
    };
    const categories = useMemo(() => transformCategories(props.categories), [props.categories]);
    const selectedMarket = useMemo(() => {
        setSelectedCategories(transformCategories(props.editMarket.categories));
        setConfirmationUrl(props.editMarket.confirmationUrl || '');
        setIsPartial(props.editMarket.isPartial);
        return { label: props.editMarket.parentRetailer, value: props.editMarket.id };
    }, [props.editMarket]);

    return (
        <>
            {/* Parent Retailer */}
            <div className={generalStyles['input-wrapper']}>
                <label>Parent Retailer:</label>
                <b className={generalStyles.formField}>{selectedMarket?.label || ''}</b>
            </div>
            {/* Is Partial */}
            <div className={generalStyles['input-wrapper']}>
                <label className={generalStyles.switchLabel}>Approve all categories: </label>
                <ByzzerSwitch 
                    name="includeTestAccounts" 
                    onChange={({ target }) => setIsPartial(!target.checked)}
                    value={!isPartial}
                ></ByzzerSwitch>
            </div>
            {/* Categories */}
            <div className={generalStyles['input-wrapper']}>
                <label>Omni Categories:</label>
                <span className={generalStyles.formField}>
                    <Select
                        classNamePrefix={'react-select'}
                        options={categories}
                        closeMenuOnSelect={false}
                        isMulti
                        onChange={(selectedCategories) => setSelectedCategories(selectedCategories)}
                        value={isPartial? selectedCategories : []}
                        placeholder={isPartial ? "Select categories" : "ALL CATEGORIES"}
                        isDisabled={!isPartial}
                    />
                </span>
            </div>
            {
                (isPartial && !selectedCategories?.length) &&
                <div className={generalStyles.errorMessage}>
                    Please select categories
                </div>
            }
            {/* Confirmation Link */}
            <TextInput
                type="text"
                label="Confirmation Link:"
                placeholder="Confirmation Link"
                value={confirmationUrl}
                onInputChange={(event) => setConfirmationUrl(event.target.value)}
            />
            <div className={styles.columnar}>
                <button
                    className={generalStyles.submitButton}
                    onClick={editSelectedPriorApprovalMarket}
                    disabled={!selectedMarket || !confirmationUrl || editMarketApiState !== API_STATES.none || (isPartial && !selectedCategories.length)}
                    data-test="approve-selected-market-button"
                >
                    Save Changes
                </button>
                {editMarketApiState === API_STATES.loading ? (
                    <span className={generalStyles.statusMessage}>Processing...</span>
                ) : editMarketApiState === API_STATES.error ? (
                    <span className={generalStyles.errorMessage}>Error: {editMarketApiError}</span>
                ) : (
                    editMarketApiState === API_STATES.success && (
                        <>
                            <span className={generalStyles.successMessage}> Changes Saved </span>
                        </>
                    )
                )}
                {editMarketApiState !== API_STATES.loading && editMarketApiState !== API_STATES.success && (
                    <button className={generalStyles.submitButton} onClick={props.onClose}>
                        View Approved Omni Markets
                    </button>
                )}
            </div>
        </>
    );
}
export default EditOmniPriorApprovedMarketForm;
