export const evergreenOptions = [{ value: true, label: 'Yes' }, { value: false, label: 'No' }];
export const billingFrequencyOptions = [{ value: 'monthly', label: 'Monthly' }, { value: 'upfront', label: 'Upfront' }, {value: 'quarterly' , label: 'Quarterly'}];








