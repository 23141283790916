import { Tooltip } from 'antd';
import classNames from 'classnames';
import { useMemo, useRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { forwardRef } from 'react';
import InlineSVG from 'react-inlinesvg/esm';
import { NavLink, useLocation } from 'react-router-dom';
import { useClickOutsideHandler } from '../utils/utils';
import store from '@/store';
import React from 'react';

export const DashboardNavItem = forwardRef(
    (
        {
            disabled = false,
            iconSrc,
            children,
            content = children,
            url,
            otherUrls = [],
            type,
            onClick,
            subItems,
            className,
            title,
            collapsed,
            toggleFromParent = false,
            toggleParentMenu,
            accessibleBy = null, // null indicates accessible by all roles
            ...props
        },
        ref
    ) => {
        const baseClassName = 'dashboard-nav-item';
        const [expanded, setExpanded] = useState(false);
        const [active, setActive] = useState(false);
        const { pathname } = useLocation();
        const paths = useMemo(() => [url, ...otherUrls], [url, otherUrls]);
        const compRef = useRef();
        const storeState = store?.getState();
    
        const isAccessible = useMemo(() => {
            if (accessibleBy) {
                const roles = storeState?.account?.user?.csrUserRoles;
                const matchingRole = accessibleBy.find((requiredRole) => roles?.includes(requiredRole));
                return matchingRole !== undefined;
            } else return true;
        }, [accessibleBy, storeState]);

        useEffect(() => {
            if (!pathname) return;
            const pathnameMatches = paths?.filter((pathToCheck) =>new RegExp(pathToCheck+"$").test(pathname))?.length > 0;
            setActive(pathnameMatches);
        }, [pathname, paths]);

        useEffect(() => {
            onToggleOfExpand(toggleFromParent);
        }, [toggleFromParent]);

        function onToggleOfExpand(expand) {
            setExpanded(expand);
        }
        function onToggleClick() {
            setExpanded((expanded) => !expanded);
            if (!expanded) {
                toggleParentMenu?.();
            }
        }

        useClickOutsideHandler(compRef, () => {
            setExpanded(false);
        });

        const tooltipText = () => {
            return collapsed ? title : '';
        };

        if (!isAccessible) {
            return <></>;
        } else if (disabled) {
            return (
                <Tooltip placement="right" overlayClassName={`${baseClassName}--tooltip`} title={tooltipText()}>
                    <div
                        className={classNames(
                            baseClassName,
                            className,
                            `${baseClassName}--${type}`,
                            `${baseClassName}--disabled`
                        )}
                        ref={ref}
                    >
                        {iconSrc && <InlineSVG src={iconSrc} className={`${baseClassName}__icon`} />}
                        {!collapsed && <span className={`${baseClassName}__text`}>{content}</span>}
                    </div>
                </Tooltip>
            );
        } else if (onClick) {
            return (
                <Tooltip placement="right" overlayClassName={`${baseClassName}--tooltip`} title={tooltipText()}>
                    <div
                        className={classNames(`${baseClassName}__wrapper`, {
                            [`${baseClassName}__wrapper--active`]: active,
                        })}
                    >
                        <div
                            className={classNames(baseClassName, className, `${baseClassName}--${type}`, {
                                [`${baseClassName}--active`]: active,
                            })}
                            onClick={(e) => onClick(e)}
                            ref={ref}
                            {...props}
                        >
                            {iconSrc && <InlineSVG src={iconSrc} className={`${baseClassName}__icon`} />}
                            {!collapsed && <span className={`${baseClassName}__text`}>{content}</span>}
                        </div>
                    </div>
                </Tooltip>
            );
        } else if (subItems) {
            const item = (
                <div
                    className={classNames(`${baseClassName}__wrapper`, {
                        [`${baseClassName}__wrapper--active`]: active,
                    })}
                >
                    <div
                        className={classNames(baseClassName, className, `${baseClassName}--${type}`)}
                        onClick={() => onToggleClick()}
                        {...props}
                        ref={ref}
                    >
                        {iconSrc && <InlineSVG src={iconSrc} className={`${baseClassName}__icon`} />}
                        {!collapsed && <span className={`${baseClassName}__text`}>{content}</span>}
                        <div
                            className={classNames('dashboard-nav__sub-items', {
                                [`dashboard-nav__sub-items--expanded`]: expanded,
                            })}
                            ref={compRef}
                        >
                            {subItems.map((props) => (
                                <DashboardNavItem key={props.content} disabled={disabled} {...props} />
                            ))}
                        </div>
                    </div>
                </div>
            );
            if (!expanded)
                return (
                    <Tooltip placement="right" overlayClassName={`${baseClassName}--tooltip`} title={tooltipText()}>
                        {item}
                    </Tooltip>
                );
            else return item;
        } else if (url) {
            return (
                <Tooltip placement="right" overlayClassName={`${baseClassName}--tooltip`} title={tooltipText()}>
                    <div
                        className={classNames(`${baseClassName}__wrapper`, {
                            [`${baseClassName}__wrapper--active`]: active,
                        })}
                    >
                        <NavLink
                            className={classNames(baseClassName, className, `${baseClassName}--${type}`, {
                                [`${baseClassName}--active`]: active,
                            })}
                            to={url}
                            {...props}
                            ref={ref}
                        >
                            {iconSrc && <InlineSVG src={iconSrc} className={`${baseClassName}__icon`} />}
                            {!collapsed && <span className={`${baseClassName}__text`}>{content}</span>}
                        </NavLink>
                    </div>
                </Tooltip>
            );
        } else {
            return <></>;
        }
    }
);
