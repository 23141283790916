import React, { useState, useEffect, useRef, useCallback } from 'react';
import generalStyles from '@/components/General/Styles.module.scss';
import styles from '@/components/ExtendTrial/ExtendTrial.module.scss';
import API_STATES from '@/constants/StateConstants';
import * as API from '@/services/api/product.service';
import { handleApiError } from '@/components/General/HandleApiError';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import calendarIcon from '@images/icons/calendar.svg';
import TimeKeeper from 'react-timekeeper';
import moment from 'moment';
import clockIcon from '@images/icons/clock.svg';

function TrialProductListItem(props) {
    const trialProduct = props.trialProduct;

    const [saveExpirationApiStatus, setSaveExpirationApiStatus] = useState(API_STATES.none);
    const [saveExpirationApiError, setSaveExpirationApiError] = useState('');

    const [expiryDate, setExpiryDate] = useState(moment());
    const [expiryTime, setExpiryTime] = useState();
    const [initialExpiryDate, setInitialExpiryDate] = useState(moment());

    const [showTimePicker, setShowTimePicker] = useState(false);
    const [dateRangeError, setDateRangeError] = useState('');
    const [hasDateRangeError, setHasDateRangeError] = useState(false);

    async function saveNewExpiry() {
        setSaveExpirationApiStatus(API_STATES.loading);
        const result = await API.saveExpiryDate(
            trialProduct.ns_contract_item_id,
            moment(`${expiryDate.format('YYYY-MM-DD')} ${expiryTime}`, 'YYYY-MM-DD hh:mm a').toDate(),
            props.nsCompanyId
        );
        switch (result.status) {
            case API_STATES.success:
                setSaveExpirationApiStatus(API_STATES.success);
                setTimeout(() => {
                    setSaveExpirationApiStatus(API_STATES.none);
                }, 3000);
                break;
            case API_STATES.error:
                let error = result.error;
                handleApiError(error, setSaveExpirationApiStatus, setSaveExpirationApiError);
                break;
            default:
                setSaveExpirationApiError('');
                setSaveExpirationApiStatus(API_STATES.none);
        }
    }

    function validateDate(newDate) {
        const maxDate = moment('2025-12-31 23:59:59', 'YYYY-MM-DD HH:mm:ss');
        if (newDate < moment()) {
            setDateRangeError('Invalid Date Selected!');
            setHasDateRangeError(true);
            setExpiryDate(expiryDate);
        } else if (newDate > maxDate) {
            setExpiryDate(expiryDate);
            setDateRangeError('Date Range Overflow!');
            setHasDateRangeError(true);
        } else {
            setExpiryDate(moment(newDate));
            setDateRangeError('');
            setHasDateRangeError(false);
        }
    }

    function calculateExpiryDate(days) {
        let newDate = new moment(expiryDate);
        newDate.add(days, 'days');
        validateDate(newDate);
    }

    useEffect(() => {
        if (props.expiryDate !== null && props.expiryDate !== undefined) {
            setExpiryDate(props.expiryDate);
            setInitialExpiryDate(props.expiryDate);
            if (props.expiryDate < moment()) {
                setHasDateRangeError(true);
            }
        }
    }, [props.expiryDate]);

    useEffect(() => {
        if (props.expiryTime !== null && props.expiryTime !== undefined) {
            setExpiryTime(props.expiryTime);
        }
    }, [props.expiryTime]);

    const timePickerRef = useRef(null); // needed to detect outer click
    const handleClickOutsideTimePicker = useCallback(
        (event) => {
            if (timePickerRef.current && !timePickerRef.current.contains(event.target)) {
                setShowTimePicker(false);
            }
        },
        [timePickerRef]
    );

    useEffect(() => {
        // Bind the event listener for click on outside time picker
        if (showTimePicker) {
            document.addEventListener('mousedown', handleClickOutsideTimePicker);
        } else {
            document.removeEventListener('mousedown', handleClickOutsideTimePicker);
        }
    }, [handleClickOutsideTimePicker, showTimePicker]);

    return (
        <>
            <div className={styles.dateTimePickerArea}>
                <div className={styles.expiryDateTimeForm}>
                    <span>Expiry Date: </span>
                    <div className={styles.date}>
                        <DatePicker
                            name="expiryDate"
                            placeholderText="mm/dd/yyyy"
                            shouldCloseOnSelect={true}
                            fixedHeight
                            closeOnScroll={true}
                            maxDate={new Date(2025, 11, 31, 23, 59, 59)}
                            minDate={initialExpiryDate.toDate()}
                            dateFormat="MMM d, y"
                            selected={expiryDate.toDate()}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            tabIndex={1}
                            onChange={(date) => {
                                validateDate(moment(date));
                            }}
                            disabled={!props.isEditable}
                        />

                        <img src={calendarIcon} alt="calendar icon" />
                    </div>
                    <div className={styles.time}>
                        <div className={styles.timePicker} ref={timePickerRef}>
                            <div
                                className={styles.timeDisplay}
                                onClick={() => setShowTimePicker(props.isEditable && !showTimePicker)}
                            >
                                <input value={expiryTime} disabled={props.isEditable} />
                                {props.isEditable && <img src={clockIcon} alt="clock icon" />}
                            </div>
                            {props.isEditable && showTimePicker && (
                                <div className={styles.timePickerElementWrapper}>
                                    <TimeKeeper
                                        time={expiryTime}
                                        onChange={(data) => setExpiryTime(data.formatted12)}
                                        onDoneClick={() => setShowTimePicker(false)}
                                        switchToMinuteOnHourSelect
                                        closeOnMinuteSelect
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {props.isEditable && (
                    <>
                        <button
                            className={styles.timePeriodButton}
                            onClick={() => calculateExpiryDate(1)}
                            name={'next1day'}
                        >
                            Next 1 Day
                        </button>
                        <button
                            className={styles.timePeriodButton}
                            onClick={() => calculateExpiryDate(7)}
                            name={'next1week'}
                        >
                            Next 1 Week
                        </button>
                        <button
                            className={styles.timePeriodButton}
                            onClick={() => calculateExpiryDate(14)}
                            name={'next2weeks'}
                        >
                            Next 2 Weeks
                        </button>
                        <button
                            className={styles.timePeriodButton}
                            onClick={() => calculateExpiryDate(28)}
                            name={'next4weeks'}
                        >
                            Next 4 Weeks
                        </button>
                        <div className={styles.savebtn}>
                            <button
                                className={generalStyles.submitButton}
                                onClick={saveNewExpiry}
                                disabled={hasDateRangeError}
                            >
                                Save
                            </button>
                            <label>
                                {saveExpirationApiStatus === API_STATES.loading ? (
                                    <span className={generalStyles.statusMessage}>Processing...</span>
                                ) : saveExpirationApiStatus === API_STATES.error ? (
                                    <span className={generalStyles.errorMessage}>Error: {saveExpirationApiError}</span>
                                ) : (
                                    saveExpirationApiStatus === API_STATES.success && (
                                        <span className={generalStyles.successMessage}>Saved!</span>
                                    )
                                )}
                            </label>
                            {hasDateRangeError && <span className={generalStyles.errorMessage}>{dateRangeError}</span>}
                        </div>
                    </>
                )}
            </div>
        </>
    );
}

export default TrialProductListItem;
