import React, { useState } from 'react';
import { useAsyncDebounce } from 'react-table';
import styles from './Styles.module.scss';
import SearchBar from './SearchBar';

export const ColumnFilter = ({ column }) => {
    const { filterValue, setFilter } = column;
    const [value, setValue] = useState(filterValue);

    const onChange = useAsyncDebounce((value) => {
        setFilter(value || undefined);
    }, 1000);

    return (
        <div className={styles.filterRow}>
            <SearchBar
                data-test="filter-input"
                className={styles.searchBox}
                value={value || ''}
                onChange={(e) => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
            />
        </div>
    );
};
