import API_STATES from '@/constants/StateConstants';
import generalStyles from '@/components/General/Styles.module.scss';
import { useState } from 'react';
import USER_ROLES from '@/constants/UserRoles';
import { handleApiError } from '../General/HandleApiError';
import { useEffect } from 'react';
import styles from '@/components/UserConfig/UserConfig.module.scss';
import Select from 'react-select';
import { getCompanyUserDetails, getTeamsByCompanyId, updateCompanyUserDetails } from '@/services/api/company.service';
import { findSelectedOption } from '../utils/utils';
import { useMemo } from 'react';
import TextInput from '../General/TextInput';
import { ApproveUser } from './ApproveUser';

export function UserDetails({ nsUserId, nsCompanyId, isEditable = true }) {
    const [userDetailsApiState, setUserDetailsApiState] = useState(API_STATES.none);
    const [userDetailsApiError, setUserDetailsApiError] = useState('');

    const [teamsApiStatus, setTeamsApiStatus] = useState(API_STATES.none);
    const [teamsApiError, setTeamsApiError] = useState('');
    const [teams, setTeams] = useState([]);

    const [userDetails, setUserDetails] = useState({});

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [selectedRole, setSelectedRole] = useState(null);
    const [selectedTeam, setSelectedTeam] = useState(null);

    const [updateDetailsApiState, setUpdateDetailsApiState] = useState(API_STATES.none);
    const [updateDetailsApiError, setUpdateDetailsApiError] = useState('');

    async function loadUserDetails(nsUserId) {
        setUserDetailsApiState((prevVal) =>
            prevVal === API_STATES.success ? API_STATES.refreshing : API_STATES.loading
        );
        const response = await getCompanyUserDetails(nsCompanyId, nsUserId);
        if (response.status === API_STATES.success) {
            console.log('user details:', response.data);
            setUserDetails(response.data);
            resetForm(response.data);
            setUserDetailsApiState(API_STATES.success);
        } else {
            handleApiError(response.error, setUserDetailsApiState, setUserDetailsApiError);
        }
    }

    function resetForm(userDetails) {
        setFirstName(userDetails?.firstName);
        setLastName(userDetails?.lastName);
        const userRole = findSelectedOption(userDetails?.role, USER_ROLES);
        setSelectedRole(userRole);
        setTeams((teams) => {
            const team = findSelectedOption(userDetails?.teamId, teams);
            setSelectedTeam(team);
            return teams;
        });
    }

    async function loadTeams(nsCompanyId) {
        setTeamsApiStatus((prevVal) => (prevVal === API_STATES.success ? API_STATES.refreshing : API_STATES.loading));
        const result = await getTeamsByCompanyId(nsCompanyId);
        switch (result.status) {
            case API_STATES.success:
                setTeamsApiStatus(API_STATES.success);
                const teams = result.data.map(({ id, title }) => ({
                    value: id,
                    label: title,
                }));
                setTeams(teams);
                setUserDetails((userDetails) => {
                    const team = findSelectedOption(userDetails?.teamId, teams);
                    setSelectedTeam(team);
                    return userDetails;
                });
                break;
            case API_STATES.error:
                let error = result.error;
                handleApiError(error, setTeamsApiStatus, setTeamsApiError);
                break;
            default:
                setTeamsApiStatus(API_STATES.none);
                setTeamsApiError('');
        }
    }

    async function saveUserDetails() {
        setUpdateDetailsApiState(API_STATES.loading);
        const details = {
            firstName,
            lastName,
            role: selectedRole.value,
            teamId: selectedTeam.value,
        };
        const response = await updateCompanyUserDetails(nsCompanyId, nsUserId, details);
        if (response.status === API_STATES.success) {
            setUpdateDetailsApiState(API_STATES.success);
            await loadUserDetails(nsUserId);
            setUpdateDetailsApiState(API_STATES.none);
        } else {
            handleApiError(response.error, setUpdateDetailsApiState, setUpdateDetailsApiError);
            setTimeout(() => {
                setUpdateDetailsApiState(API_STATES.none);
            }, 3000);
        }
    }

    const areDetailsEdited = useMemo(() => {
        // if the user is consultant, the firstName and lastName cannot be edited
        return (
            (!userDetails?.isConsultant && firstName && firstName !== userDetails?.firstName) ||
            (!userDetails?.isConsultant && lastName && lastName !== userDetails?.lastName) ||
            (selectedRole && selectedRole?.value !== userDetails?.role) ||
            (selectedTeam && selectedTeam?.value !== userDetails?.teamId)
        );
    }, [firstName, lastName, selectedRole, selectedTeam, userDetails]);

    useEffect(() => {
        if (nsUserId) {
            loadUserDetails(nsUserId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nsUserId]);

    useEffect(() => {
        if (nsCompanyId) {
            loadTeams(nsCompanyId);
        }
    }, [nsCompanyId]);

    return (
        <>
            <h3>User Details</h3>
            {userDetailsApiState === API_STATES.loading ? (
                <div className={generalStyles.statusMessage}>Loading user details....</div>
            ) : userDetailsApiState === API_STATES.error ? (
                <div className={generalStyles.errorMessage}>{userDetailsApiError}</div>
            ) : userDetailsApiState === API_STATES.none ? (
                <></>
            ) : (
                <>
                    <ApproveUser
                        userDetails={userDetails}
                        nsCompanyId={nsCompanyId}
                        teams={teams}
                        onUserApproved={async () => await loadUserDetails(nsUserId)}
                        isEditable={isEditable}
                    />
                    <TextInput
                        type="text"
                        label="First Name:"
                        placeholder="First Name"
                        value={firstName}
                        onInputChange={(event) => setFirstName(event.target.value)}
                        disabled={!isEditable || userDetails.isConsultant || updateDetailsApiState !== API_STATES.none}
                    />
                    <TextInput
                        type="text"
                        label="Last Name:"
                        placeholder="Last Name"
                        value={lastName}
                        onInputChange={(event) => setLastName(event.target.value)}
                        disabled={!isEditable || userDetails.isConsultant || updateDetailsApiState !== API_STATES.none}
                    />
                    <div className={generalStyles['input-wrapper']}>
                        <label>Member Type:</label>
                        <span className={generalStyles.formField}>
                            <Select
                                classNamePrefix={'react-select'}
                                options={USER_ROLES}
                                onChange={(selectedRole) => setSelectedRole(selectedRole)}
                                value={selectedRole}
                                isDisabled={!isEditable || updateDetailsApiState !== API_STATES.none}
                            />
                        </span>
                    </div>

                    <div className={generalStyles['input-wrapper']}>
                        <label>Team:</label>
                        <span className={generalStyles.formField}>
                            {teamsApiStatus === API_STATES.error ? (
                                <p className={generalStyles.errorMessage}>Error: {teamsApiError}</p>
                            ) : teamsApiStatus === API_STATES.loading ? (
                                <p className={generalStyles.statusMessage}>Loading...</p>
                            ) : (
                                <Select
                                    classNamePrefix={'react-select'}
                                    options={teams}
                                    value={selectedTeam}
                                    onChange={setSelectedTeam}
                                    isDisabled={!isEditable || updateDetailsApiState !== API_STATES.none}
                                />
                            )}
                        </span>
                    </div>

                    <div>
                        {isEditable && areDetailsEdited && (
                            <span className={styles.row}>
                                <button
                                    className={generalStyles.submitButton}
                                    onClick={() => resetForm(userDetails)}
                                    disabled={updateDetailsApiState === API_STATES.loading}
                                    data-test="cancel-user-details-button"
                                >
                                    Cancel
                                </button>
                                <button
                                    className={generalStyles.submitButton}
                                    onClick={saveUserDetails}
                                    disabled={!isEditable || !selectedRole || updateDetailsApiState !== API_STATES.none}
                                    data-test="update-user-details-button"
                                >
                                    Save
                                </button>
                                {updateDetailsApiState === API_STATES.loading ? (
                                    <span className={generalStyles.statusMessage}>saving...</span>
                                ) : updateDetailsApiState === API_STATES.error ? (
                                    <span className={generalStyles.errorMessage}>{updateDetailsApiError}</span>
                                ) : (
                                    updateDetailsApiState === API_STATES.success && (
                                        <span className={generalStyles.successMessage}>Saved!</span>
                                    )
                                )}
                            </span>
                        )}
                    </div>
                </>
            )}
        </>
    );
}
