import Popup from 'reactjs-popup';
import styles from './MarketMaps.module.scss';
import generalStyles from '@/components/General/Styles.module.scss';
import TextInput from '@/components/General/TextInput';
import API_STATES from '@/constants/StateConstants';
import React, { useState } from 'react';
import * as API from '@/services/api/product.service';
import { handleApiError } from '@/components/General/HandleApiError';

export function CreateFolder(props) {
    const [folderName, setFolderName] = useState('');
    const [addFolderTitleApiStatus, setAddFolderTitleApiStatus] = useState(API_STATES.none);
    const [addFolderTitleApiError, setAddFolderTitleApiError] = useState('');
    const marketMapType = props.marketMapType;

    const onTitleChange = (value) => {
        setFolderName(value);
    };
    async function CreateFolder() {
        setAddFolderTitleApiStatus(API_STATES.loading);
        const result = await API.addMarketMapsFolder(folderName, marketMapType);
        switch (result.status) {
            case API_STATES.success:
                setAddFolderTitleApiStatus(API_STATES.success);
                setTimeout(() => setAddFolderTitleApiStatus(API_STATES.none), 1000);
                setTimeout(() => props.onFolderUpdate(), 2000);
                setTimeout(() => props.onClose(), 2000);

                break;
            case API_STATES.error:
                let error = result.error;
                handleApiError(error, setAddFolderTitleApiStatus, setAddFolderTitleApiError);
                break;
            default:
                setAddFolderTitleApiError('');
                setAddFolderTitleApiStatus(API_STATES.none);
        }
    }

    return (
        <Popup
            open={props.isaddFolderSelected}
            onClose={() => {
                props.onClose();
            }}
            modal
        >
            {() => (
                <div className={styles.dialogModal}>
                    <h4 className={styles.dialogModalHeader}>Add Folder</h4>
                    <div className={styles.dialogContainer}>
                        <div className={generalStyles['input-wrapper']}>
                            <label>Folder Name</label>
                            <div className={generalStyles.TextInput}>
                                <TextInput
                                    className={styles.dialogInput}
                                    placeholder="Folder Name"
                                    value={folderName}
                                    onInputChange={(event) => onTitleChange(event.target.value)}
                                    disabled={setAddFolderTitleApiStatus === API_STATES.loading}
                                />
                            </div>
                        </div>
                        <div>
                            <button
                                className={`${generalStyles.cancelOperationButton} ${styles.btnWidth}`}
                                disabled={addFolderTitleApiStatus === API_STATES.loading}
                                onClick={() => {
                                    CreateFolder();
                                }}
                            >
                                Save
                            </button>
                            <label data-test="create-product-status">
                                {addFolderTitleApiStatus === API_STATES.loading ? (
                                    <span className={generalStyles.statusMessage}>Creating...</span>
                                ) : addFolderTitleApiStatus === API_STATES.error ? (
                                    <span className={generalStyles.errorMessage}>Error: {addFolderTitleApiError}</span>
                                ) : (
                                    addFolderTitleApiStatus === API_STATES.success && (
                                        <span className={generalStyles.successMessage}>Successfully Created!</span>
                                    )
                                )}
                            </label>
                        </div>
                    </div>
                </div>
            )}
        </Popup>
    );
}
