import React from 'react';
import generalStyles from '@/components/General/Styles.module.scss';

import { logout } from '@/services/account.service';
import { useHistory } from 'react-router-dom';

export function Logout(props) {
    const history = useHistory();

    async function doLogout() {
        await logout();
        history.replace(`/signin`);
    }

    return (
        <button className={generalStyles.submitButton} onClick={doLogout}>
            Logout
        </button>
    );
}
