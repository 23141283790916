import React from 'react';
import generalStyles from '@/components/General/Styles.module.scss';
import PropTypes from 'prop-types';

export default function CheckBoxList(props) {
    const checkBoxList = props.checkBoxList;
    return (
        <>
            <div className={generalStyles.checkboxlist_wrapper}>
                {checkBoxList.map((listItem) => (
                    <div key={listItem.id}>
                        <input
                            type="checkbox"
                            id={listItem.id}
                            value={listItem.label}
                            checked={listItem.isChecked}
                            onChange={(e) => props.checkBoxOnChange(listItem, e.target.checked)}
                            data-test="check-box-input"
                        />
                        <label>{listItem.label}</label>
                    </div>
                ))}
            </div>
        </>
    );
}

CheckBoxList.propTypes = {
    checkBoxOnChange: PropTypes.func.isRequired,
    checkBoxList: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            label: PropTypes.string.isRequired,
            isChecked: PropTypes.bool.isRequired
        })
    ).isRequired
};
