import React, { useState } from 'react';
import * as API from '@/services/api/product.service';
import generalStyles from '@/components/General/Styles.module.scss';
import API_STATES from '@/constants/StateConstants';
import PropTypes from 'prop-types';
import { handleApiError } from '@/components/General/HandleApiError';

function BundleInfoTableInstance(props) {
    const bundleSku = props.bundleSku;
    const childProduct = props.childProduct;

    const [deleteChildSkuApiStatus, setDeleteChildSkuApiStatus] = useState(API_STATES.none);
    const [deleteChildSkuApiError, setDeleteChildSkuApiError] = useState('');

    async function deleteChildSkuData() {
        setDeleteChildSkuApiStatus(API_STATES.loading);
        const result = await API.deleteChildSku(bundleSku, childProduct.childSku);
        switch (result.status) {
            case API_STATES.success:
                setDeleteChildSkuApiStatus(API_STATES.success);
                setTimeout(() => setDeleteChildSkuApiStatus(API_STATES.none), 1000);
                setTimeout(() => props.onBundleUpdate(), 2000);
                break;
            case API_STATES.error:
                let error = result.error;
                handleApiError(error, setDeleteChildSkuApiStatus, setDeleteChildSkuApiError);
                break;
            default:
                setDeleteChildSkuApiError('');
                setDeleteChildSkuApiStatus(API_STATES.none);
        }
    }

    return (
        <>
            <tr>
                <td className={generalStyles.numeric}>{childProduct?.childSku}</td>
                <td className={generalStyles.cap}>{childProduct?.name}</td>
                <td className={generalStyles.cap}>{childProduct?.type}</td>
                <td>
                    <button
                        className={generalStyles.smallButton}
                        disabled={deleteChildSkuApiStatus === API_STATES.loading}
                        onClick={deleteChildSkuData}
                    >
                        Delete
                    </button>
                    <label data-test="delete-product-status">
                        {deleteChildSkuApiStatus === API_STATES.loading ? (
                            <span className={generalStyles.statusMessage}>Processing...</span>
                        ) : deleteChildSkuApiStatus === API_STATES.error ? (
                            <span className={generalStyles.errorMessage}>Error: {deleteChildSkuApiError}</span>
                        ) : (
                            deleteChildSkuApiStatus === API_STATES.success && (
                                <span className={generalStyles.successMessage}>Successfully deleted!</span>
                            )
                        )}
                    </label>
                </td>
            </tr>
        </>
    );
}

BundleInfoTableInstance.propTypes = {
    bundleSku: PropTypes.string.isRequired,
    childProduct: PropTypes.shape({
        childSku: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
    }).isRequired,
    onBundleUpdate: PropTypes.func.isRequired,
};

export default BundleInfoTableInstance;
