import './DashboardContent.scss';
import classnames from "classnames";
import React from "react";

const baseClassName = 'dashboard-content-body';

export default function DashboardContent({
    // title,
    // subTitle,
    // links,
    className,
    // extras,
    // exact,
    // replace,
    children,
    // loading,
    staticContentBody = false,
    ...props
}) {

    // const [requiresHeader, setRequiresHeader] = useState(true);

    // useEffect(() => {
    //     setRequiresHeader(title || subTitle || links?.length || extras)
    // }, [title, subTitle, links, extras])

    // useEffect(() => {

    // }, [requiresHeader])

    // function renderLinks() {
    //     if (links?.length) {
    //         return <div className={`${baseClassName}__links`}>
    //             {links.map(({ url, text, disabled }) => {
    //                 if (disabled) {
    //                     return <div key={url}
    //                         className={classnames(`${baseClassName}__link`, `${baseClassName}__link--disabled`)}>
    //                         <span className={`${baseClassName}__link-text`}>{text}</span>
    //                     </div>

    //                 } else {
    //                     return <NavLink
    //                         className={({ isActive }) => classnames(
    //                             `${baseClassName}__link`,
    //                             {
    //                                 [`${baseClassName}__link--active`]: isActive,
    //                                 [`${baseClassName}__link--disabled`]: disabled
    //                             }
    //                         )}
    //                         replace={replace}
    //                         end={exact}
    //                         to={url}
    //                         key={url}
    //                     >
    //                         <span className={`${baseClassName}__link-text`}>{text}</span>
    //                     </NavLink>
    //                 }
    //             })}
    //         </div>
    //     }
    // }

    return (
        <div className={"dashboard-content"}>
            <div
                className={`${classnames(baseClassName)} ${staticContentBody && 'dashboard-content-body-static'}`} {...props}>
                {/*requiresHeader && (
                    <header className={`${baseClassName}__heading`}>
                        <div className={`${baseClassName}__titles`}>
                            <div className={`${baseClassName}__title`}>{title}</div>
                            {subTitle && <div className={`${baseClassName}__subtitle`}>{subTitle}</div>}
                        </div>
                        {renderLinks()}
                        {extras}
                    </header>
                )*/}
                <div className={classnames(`${baseClassName}__view`, className)}>
                    {/* <ByzzerMask show={loading} loading={loading} /> */}
                    {children}
                </div>
            </div>
        </div>
    )
}
