import React, { useState, useEffect, } from 'react';
import styles from '@/components/AccountSetup/SetupNewCompany.module.scss';
import 'react-datepicker/dist/react-datepicker.css';
import API_STATES from '@/constants/StateConstants';
import { getChildSkuDetails } from '@/services/api/product.service';
import { DebounceInput } from 'react-debounce-input';
import generalStyles from '@/components/General/Styles.module.scss';
import { handleApiError } from '@/components/General/HandleApiError';
// import RadioButton from '@/components/General/RadioButton';
import { calculateTermInMonths } from '../utils/utils.jsx'


function BuySubscriptionAddOns(props) {

    const [productSkuArray, setProductSkuArray] = useState([]);
    const [newChildSku, setNewChildSku] = useState(0);
    const [childSkuData, setChildSkuData] = useState({});
    const [childSkuApiStatus, setChildSkuApiStatus] = useState(API_STATES.none);
    const [childSkuApiError, setChildSkuApiError] = useState('');
    const [count, setCount] = useState(0);
    const [addonMonthlyPrice, setAddonMonthlyPrice] = useState(0);
    const [addonYearlyPrice, setAddonYearlyPrice] = useState(0);

    const [termValue, setTermValue] = useState(0);

    useEffect(() => {
        const contractTerm = calculateTermInMonths(
            new Date(props.contractStart), new Date(props.contractEnd));
        setTermValue(contractTerm);
    }, [props.contractStart, props.contractEnd]);

    useEffect(() => {
        setAddonYearlyPrice(Number(parseFloat(count * addonMonthlyPrice * termValue).toFixed(2)));
    }, [addonMonthlyPrice, count, termValue]);

    useEffect(() => {
        if (props.editableItem?.edit === true) {
            setProductSkuArray(
                props.editableItem?.addons.map((item) => {
                    return {
                        label: item?.label,
                        sku: item?.sku,
                        quantity: item?.quantity,
                        ns_catalog_item_id: item?.ns_catalog_item_id,
                        monthlyPrice: item?.monthlyPrice,
                        annualPrice: item?.annualPrice
                        //billingFrequency: item?.billingFrequency
                    };
                })
            );
        }
    }, [props.editableItem]);

    const onDebounceInputChange = (value) => {
        setChildSkuApiStatus(API_STATES.none);
        setChildSkuApiError('');
        setChildSkuData({});
        setNewChildSku(value);
        if (value !== null && value !== 0 && value !== '') {
            if (productSkuArray.some((product) => product.sku === value)) {
                setChildSkuApiStatus(API_STATES.error);
                setChildSkuApiError('Duplicate sku not allowed in selection');
            } else {
                getChildSkuData(value);
            }
        }
    };

    async function getChildSkuData(childSku) {
        setChildSkuApiStatus(API_STATES.loading);
        const result = await getChildSkuDetails(childSku);
        switch (result.status) {
            case API_STATES.success:
                if (result.data) {
                    if(!result.data?.type) {
                        const newData = {
                            ...result.data,
                            type: 'unknown'
                        }
                        setChildSkuApiStatus(API_STATES.success);
                        setChildSkuData(newData);
                    } else if (!result.data?.type.includes('addon') && !result.data?.type.includes('scorecard')) {
                        setChildSkuApiStatus(API_STATES.error);
                        setChildSkuApiError('Not an addon');
                    } else {
                        setChildSkuApiStatus(API_STATES.success);
                        setChildSkuData(result.data);
                    }
                } else {
                    setChildSkuApiStatus(API_STATES.error);
                    setChildSkuApiError('Invalid sku entry');
                }
                break;
            case API_STATES.error:
                let error = result.error;
                handleApiError(error, setChildSkuApiStatus, setChildSkuApiError);
                break;
            default:
                setChildSkuApiError('');
                setChildSkuApiStatus(API_STATES.none);
        }
    }

    const onAddSubscription = () => {
        /*const date = new Date();
        const currentYear = date.getFullYear();
        const currentMonth = date.getMonth() + 1;

        const fullMonths = monthDiff(date, props.contractEnd);

        const daysInCurrentMonth = getDaysInMonth(currentYear, currentMonth);

        const daysForProata = (daysInCurrentMonth - date.getDate() + 1) + (props.contractEnd.getDate());

        const totalCost = count * (addonMonthlyPrice * fullMonths + (daysForProata * addonMonthlyPrice/30));*/
        const totalCost = count * termValue * addonMonthlyPrice;

        props.addCartItem({
            nsCatalogItemId: childSkuData.id,
            sku: childSkuData.sku,
            quantity: count,
            termValue: termValue,
            label: childSkuData.title,
            //type: item.type,
            monthlyPrice: addonMonthlyPrice,
            sectionTotal: Number(parseFloat(totalCost).toFixed(2)),
            annualPrice: addonYearlyPrice
            //billingFrequency: parentBillingFrequency
        });
        setProductSkuArray([]);
        setNewChildSku(0);
        setChildSkuData({});
        setChildSkuApiStatus(API_STATES.none);
        setChildSkuApiError('');
        setCount(0);
        setAddonMonthlyPrice(0);
        setAddonYearlyPrice(0);
    };
    const removeProductSku = (sku) => {
        setProductSkuArray(productSkuArray.filter((productItem) => productItem !== sku));
    };



    function getDaysInMonth(year, month) {
        return new Date(year, month, 0).getDate();
    }

    function monthDiff(dateFrom, dateTo) {
        //skip the current month and last month of contract, those will be calculated on pro-rata basis
        return dateTo.getMonth() - 1 - dateFrom.getMonth()+ 
          (12 * (dateTo.getFullYear() - dateFrom.getFullYear()))
    }

    return (
        <>
        <div className={styles.billHeading}>
                <h4>Add ons</h4>
        </div>
        {   
            productSkuArray.length !== 0 ? (
                    <>
                        <p>Child Sku:</p>
                        <ul className={styles.tileList}>
                            {productSkuArray.map((sku) => (
                                <li data-test="product-sku-list-item" key={sku}>
                                    <strong
                                        className={styles.removeButton}
                                        onClick={() => removeProductSku(sku)}
                                    >
                                        X
                                    </strong>
                                    [{sku.sku}] {sku.label} - {sku.quantity} - $
                                    {parseFloat(sku.monthlyPrice).toFixed(2)}/mon
                                </li>
                            ))}
                        </ul>
                    </>
                ) : (
                    <></>
                )}

                <table className={styles.creditTable}>
                    <thead>
                        <tr>
                            <th>Sku</th>
                            <th>Product Name</th>
                            <th>Product Type</th>
                            <th>Quantity</th>
                            <th>Monthly Price($)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className={styles.tableInputContainer}>
                                <DebounceInput
                                    placeholder="child sku"
                                    className={styles.tableInput}
                                    value={newChildSku === 0 ? '' : newChildSku}
                                    minLength={1}
                                    debounceTimeout={1000}
                                    onChange={(event) => onDebounceInputChange(event.target.value)}
                                />
                            </td>
                            <td className={generalStyles.cap}>
                                {childSkuApiStatus === API_STATES.success
                                    ? childSkuData?.title || 'Unknown Title'
                                    : ''}
                                <label>
                                    {childSkuApiStatus === API_STATES.loading ? (
                                        <span className={generalStyles.statusMessage}>Fetching data...</span>
                                    ) : childSkuApiStatus === API_STATES.error ? (
                                        <span className={generalStyles.errorMessage}>
                                            Error: {childSkuApiError}
                                        </span>
                                    ) : (
                                        childSkuApiStatus === API_STATES.success && (
                                            <span className={generalStyles.successMessage}></span>
                                        )
                                    )}
                                </label>
                            </td>
                            <td className={generalStyles.cap}>
                                {childSkuApiStatus === API_STATES.success
                                    ? childSkuData?.type || 'Unknown Type'
                                    : ''}
                            </td>
                            <td>
                                <input
                                    className={generalStyles.numeric}
                                    type={'number'}
                                    value={count}
                                    min={0}
                                    onChange={(e) => {
                                        const newCount = +e.target.value;
                                        setCount(newCount);
                                    }}
                                />
                            </td>
                            <td>
                                <input
                                    className={generalStyles.numeric}
                                    value={addonMonthlyPrice}
                                    onChange={(e) => {
                                        const newCount = e.target.value;
                                        setAddonMonthlyPrice(newCount);
                                    }}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            <br/>
            <button
                className={generalStyles.smallButton}
                disabled={
                    newChildSku === 0 ||
                    newChildSku === '' ||
                    childSkuApiStatus === API_STATES.error ||
                    childSkuApiStatus === API_STATES.loading ||
                    count === 0 ||
                    addonMonthlyPrice === 0 ||
                    addonMonthlyPrice?.toString().trim().length === 0
                }
                onClick={onAddSubscription}
            >
            
                Add to Cart
            </button>
            </>
        )}
export default BuySubscriptionAddOns;
