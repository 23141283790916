import React, { useState, useEffect } from 'react';
import styles from './BundleManager.module.scss';
import * as API from '@/services/api/product.service';
import generalStyles from '@/components/General/Styles.module.scss';
import API_STATES from '@/constants/StateConstants';
import BundleInfoTableInstance from '@/components/BundleManager/BundleInfoTableInstance';
import { DebounceInput } from 'react-debounce-input';
import PropTypes from 'prop-types';
import { handleApiError } from '@/components/General/HandleApiError';

function BundleInfoTable(props) {
    const [childSkuApiStatus, setChildSkuApiStatus] = useState(API_STATES.none);
    const [childSkuApiError, setChildSkuApiError] = useState('');

    const [addChildSkuApiStatus, setAddChildSkuApiStatus] = useState(API_STATES.none);
    const [addChildSkuApiError, setAddChildSkuApiError] = useState('');

    const [newChildSku, setNewChildSku] = useState(0);
    const [childSkuData, setChildSkuData] = useState({});

    const childProducts = props.childProducts;
    const bundleSku = props.bundleSku;

    async function addChildSkuData() {
        setAddChildSkuApiStatus(API_STATES.loading);
        const result = await API.addChildSkuDetails(newChildSku, bundleSku);
        switch (result.status) {
            case API_STATES.success:
                setAddChildSkuApiStatus(API_STATES.success);
                setNewChildSku(0);
                setChildSkuData({});
                setChildSkuApiStatus(API_STATES.none);
                setTimeout(() => setAddChildSkuApiStatus(API_STATES.none), 1000);
                setTimeout(() => props.onBundleUpdate(), 2000);
                break;
            case API_STATES.error:
                let error = result.error;
                handleApiError(error, setAddChildSkuApiStatus, setAddChildSkuApiError, `bundle ${bundleSku} not found`);
                break;
            default:
                setAddChildSkuApiError('');
                setAddChildSkuApiStatus(API_STATES.none);
        }
    }

    async function getChildSkuData() {
        setChildSkuApiStatus(API_STATES.loading);
        const result = await API.getChildSkuDetails(newChildSku);
        switch (result.status) {
            case API_STATES.success:
                if (result.data) {
                    setChildSkuApiStatus(API_STATES.success);
                    setChildSkuData(result.data);
                } else {
                    setChildSkuApiStatus(API_STATES.error);
                    setChildSkuApiError('Invalid sku entry');
                }
                break;
            case API_STATES.error:
                let error = result.error;
                setChildSkuApiStatus(API_STATES.error);
                switch (error) {
                    case 401:
                        setChildSkuApiError('CSR is not logged in.');
                        break;
                    case 403:
                        setChildSkuApiError('Unauthorised to perform this action');
                        break;
                    case 404:
                        setChildSkuApiError(`Child sku ${newChildSku} not found`);
                        break;
                    case 422:
                        setChildSkuApiError(`A bundle or subscription SKU can't be added to the bundle`);
                        break;
                    default:
                        setChildSkuApiError('Unknown error in fetching child sku data');
                        break;
                }
                break;
            default:
                setChildSkuApiError('');
                setChildSkuApiStatus(API_STATES.none);
        }
    }

    const onInputChange = (value) => {
        setChildSkuApiStatus(API_STATES.none);
        setChildSkuApiError('');
        setChildSkuData({});
        setNewChildSku(value);
    };

    useEffect(() => {
        (async () => {
            if (newChildSku !== null && newChildSku !== 0 && newChildSku !== '') {
                if (childProducts.some((product) => product.childSku === newChildSku)) {
                    setChildSkuApiStatus(API_STATES.error);
                    setChildSkuApiError('Duplicate sku not allowed in a bundle');
                } else {
                    getChildSkuData();
                }
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newChildSku]);

    return (
        <table className={styles.bundleTable}>
            <thead>
                <tr>
                    <th>Sku</th>
                    <th>Product Name</th>
                    <th>Product Type</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {childProducts.map((childProduct) => {
                    return (
                        <BundleInfoTableInstance
                            bundleSku={props.bundleSku}
                            key={childProduct.childSku}
                            childProduct={childProduct}
                            onBundleUpdate={props.onBundleUpdate}
                        />
                    );
                })}

                <tr>
                    <td className={styles.tableInputContainer}>
                        <DebounceInput
                            data-test="debounce-input"
                            placeholder="new sku"
                            className={styles.tableInput}
                            value={newChildSku === 0 ? '' : newChildSku}
                            minLength={1}
                            debounceTimeout={1000}
                            onChange={(event) => onInputChange(event.target.value)}
                        />
                    </td>
                    <td className={generalStyles.cap}>
                        {childSkuApiStatus === API_STATES.success ? childSkuData?.title || 'Unknown Title' : ''}
                        <label data-test="get-child-sku-result">
                            {childSkuApiStatus === API_STATES.loading ? (
                                <span className={generalStyles.statusMessage}>Fetching data...</span>
                            ) : childSkuApiStatus === API_STATES.error ? (
                                <span className={generalStyles.errorMessage}>Error: {childSkuApiError}</span>
                            ) : (
                                childSkuApiStatus === API_STATES.success && (
                                    <span className={generalStyles.successMessage}></span>
                                )
                            )}
                        </label>
                    </td>
                    <td className={generalStyles.cap}>
                        {childSkuApiStatus === API_STATES.success ? childSkuData?.type || 'Unknown Type' : ''}
                    </td>
                    <td>
                        <button
                            data-test="add-childsku-btn"
                            className={generalStyles.smallButton}
                            disabled={
                                addChildSkuApiStatus === API_STATES.loading ||
                                newChildSku === 0 ||
                                newChildSku === '' ||
                                childSkuApiStatus === API_STATES.error ||
                                childSkuApiStatus === API_STATES.loading
                            }
                            onClick={addChildSkuData}
                        >
                            Add
                        </button>
                        <label data-test="add-child-sku-result">
                            {addChildSkuApiStatus === API_STATES.loading ? (
                                <span className={generalStyles.statusMessage}>Processing...</span>
                            ) : addChildSkuApiStatus === API_STATES.error ? (
                                <span className={generalStyles.errorMessage}>Error: {addChildSkuApiError}</span>
                            ) : (
                                addChildSkuApiStatus === API_STATES.success && (
                                    <span className={generalStyles.successMessage}>Successfully added</span>
                                )
                            )}
                        </label>
                    </td>
                </tr>
            </tbody>
        </table>
    );
}

BundleInfoTable.propTypes = {
    bundleSku: PropTypes.string.isRequired,
    childProducts: PropTypes.arrayOf(
        PropTypes.shape({
            childSku: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        })
    ).isRequired,
    onBundleUpdate: PropTypes.func.isRequired,
};

export default BundleInfoTable;
