import styles from '@/components/General/Styles.module.scss';

export default function TextInput(props) {
    return (
        <div className={styles['input-wrapper']}>
            {props.label ? (
                props.required ? (
                    <label className={styles.required}>{props.label}</label>
                ) : (
                    <label>{props.label}</label>
                )
            ) : null}
            <div className={styles.input}>
                <input
                    type={props.type}
                    name={props.name}
                    onChange={props.onInputChange}
                    placeholder={props.placeholder}
                    value={props.value}
                    disabled={props.disabled}
                    required={props.required}
                />
            </div>
        </div>
    );
}
