import React, { useState, useCallback } from 'react';
import CategoryBasedNotificationForm from './CategoryBasedNotificationForm';
import CategoryNotificationHistory from './CategoryNotificationHistory';

function CategoryBasedNotification() {
    const [refreshHistory, setRefreshHistory] = useState(true);

    const refreshNotificationList = useCallback(async (flag) => {
        setRefreshHistory(flag);
    }, []);

    return (
        <>
            <CategoryBasedNotificationForm refresh={(flag) => refreshNotificationList(flag)} />
            <br />
            <CategoryNotificationHistory
                refreshHistory={refreshHistory}
                refresh={(flag) => refreshNotificationList(flag)}
            />
        </>
    );
}

export default CategoryBasedNotification;
