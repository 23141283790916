import React, { useState } from 'react';
import { getCompanySearchList } from '@/services/api/company.service';
import CompanySearchTable from '@/components/CompanySearch/CompanySearchTable';
import styles from './CompanySearch.module.scss';
import API_STATES from '@/constants/StateConstants';
import SearchBar from '@/components/General/SearchBar';
import generalStyles from '@/components/General/Styles.module.scss';
import { handleApiError } from '@/components/General/HandleApiError';

function CompanySearch(props) {
    const [companyName, setCompanyName] = useState('');
    //emailInput - to manage email state when input changes

    const [companySearchApiStatus, setCompanySearchApiStatus] = useState(API_STATES.none);
    const [companySearchApiError, setCompanySearchApiError] = useState('');

    const [companyList, setCompanyList] = useState([]);

    //get company search list api call
    async function getCompanyList() {
        setCompanySearchApiStatus(API_STATES.loading);
        const result = await getCompanySearchList(companyName);
        switch (result.status) {
            case API_STATES.success:
                setCompanySearchApiStatus(API_STATES.success);
                setCompanyList(result.data.companies);
                break;
            case API_STATES.error:
                let error = result.error;
                handleApiError(error, setCompanySearchApiStatus, setCompanySearchApiError);
                break;
            default:
                setCompanySearchApiStatus(API_STATES.none);
                setCompanySearchApiError('');
        }
    }

    const updateCompanyName = (e) => {
        setCompanyName(e.target.value);
    };

    function onSubmitHandler() {
        if (companyName !== null && companyName !== '') {
            getCompanyList();
        }
    }

    return (
        <>
            <h1>Company Search</h1>
            <hr />
            <div className={styles.flexcontainer}>
                <div>
                    <SearchBar
                        value={companyName}
                        onChange={updateCompanyName}
                        placeholder="Company Name"
                        disabled={companySearchApiStatus === API_STATES.loading}
                        withButton={true}
                        buttonText="Search"
                        onSubmit={() => onSubmitHandler()}
                    />
                </div>
                <div data-test="company-search-status">
                    {//Error Status in Api execution
                    companySearchApiStatus === API_STATES.error ? (
                        <p className={generalStyles.errorMessage}>Error: {companySearchApiError}</p>
                    ) : //Initial Status before Api execution
                    companySearchApiStatus === API_STATES.none ? (
                        <p className={generalStyles.statusMessage}>Please enter the company name to search</p>
                    ) : //Api in loading state
                    companySearchApiStatus === API_STATES.loading ? (
                        <p className={generalStyles.statusMessage}>Loading...</p>
                    ) : //Success Status in Api execution
                    companySearchApiStatus === API_STATES.success ? (
                        <p className={generalStyles.statusMessage}>
                            Showing top results only. Please narrow your search for better results.
                        </p>
                    ) : null}
                </div>
            </div>
            {companySearchApiStatus === API_STATES.success && (
                <>
                    <CompanySearchTable dtos={companyList} />
                </>
            )}
        </>
    );
}
export default CompanySearch;
