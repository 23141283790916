import { useEffect, useState } from 'react';
import generalStyles from '@/components/General/Styles.module.scss';
import { changeMyOwnPassword } from '@/services/api/csrdata.service';
import { validatePassword } from '@/services/PasswordValidation.service';
import store from '@/store';
import levenshtein from 'js-levenshtein';
import PasswordInput from '@/components/General/PasswordInput';
import { validateCurrentPassword, reauthenticateUser } from '@/services/account.service';
import API_STATES from '@/constants/StateConstants';
import { handleApiError } from '@/components/General/HandleApiError';

export default function ChangePassword(props) {
    const [passwordValue, setPasswordValue] = useState({
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
    });

    const onPasswordValueChange = async (passwordName, value) => {
        setChangePasswordApiState(API_STATES.none);
        setPasswordValue({
            ...passwordValue,
            [passwordName]: value,
        });
        setPasswordErrorList({
            ...passwordErrorList,
            [passwordName]: [],
        });
    };

    const [passwordErrorList, setPasswordErrorList] = useState({
        currentPassword: [],
        newPassword: [],
        confirmPassword: [],
    });

    const resetPasswordFlag = store?.getState()?.account?.user?.resetPassword;
    const [changePasswordApiState, setChangePasswordApiState] = useState(API_STATES.none);
    const [changePasswordApiError, setChangePasswordApiError] = useState('');

    const findPasswordErrors = () => {
        const { currentPassword, newPassword, confirmPassword } = passwordValue;
        let newPasswordErrorList = [];
        if (newPassword !== '') {
            newPasswordErrorList = validatePassword(newPassword, store.getState().account.user.email);
            const similarity = levenshtein(newPassword, currentPassword);
            if (newPassword === currentPassword || similarity < 3) {
                newPasswordErrorList.push('Must not be the similar to your current password.');
            }
        }
        let confirmPasswordErrorList = [];
        if (newPassword !== confirmPassword) {
            confirmPasswordErrorList.push('Must be the same as your new password.');
        }
        setPasswordErrorList({
            ...passwordErrorList,
            newPassword: newPasswordErrorList,
            confirmPassword: confirmPasswordErrorList,
        });
    };

    async function checkCurrentPassword() {
        let currentPasswordErrorList = [];
        setChangePasswordApiState(API_STATES.loading);
        const result = await validateCurrentPassword(passwordValue.currentPassword);
        switch (result.status) {
            case API_STATES.success:
                changePassword();
                break;
            case API_STATES.error:
                let errorStatus = result.error.code;
                switch (errorStatus) {
                    case 'auth/wrong-password':
                        currentPasswordErrorList.push('Your current password is incorrect');
                        break;
                    default:
                        currentPasswordErrorList.push(
                            'This is an unusual case, and can usually be fixed by logging out, then logging back in.'
                        );
                        break;
                }
                setPasswordErrorList({
                    ...passwordErrorList,
                    currentPassword: currentPasswordErrorList,
                });
                setPasswordValue({
                    ...passwordValue,
                    currentPassword: '',
                });
                setChangePasswordApiState(API_STATES.error);
                break;
            default:
                setChangePasswordApiState(API_STATES.none);
        }
    }

    async function changePassword() {
        setChangePasswordApiState(API_STATES.loading);
        const apiResult = await changeMyOwnPassword(passwordValue.currentPassword, passwordValue.newPassword);
        switch (apiResult.status) {
            case API_STATES.success:
                const reauthResult = await reauthenticateUser(passwordValue.newPassword);
                switch (reauthResult.status) {
                    case API_STATES.success:
                        setChangePasswordApiState(API_STATES.success);
                        setPasswordValue({
                            currentPassword: '',
                            newPassword: '',
                            confirmPassword: '',
                        });
                        break;
                    case API_STATES.error:
                        setChangePasswordApiState(API_STATES.error);
                        setChangePasswordApiError(
                            'This is an unusual case, and can usually be fixed by logging out, then logging back in.'
                        );
                        break;
                    default:
                        setChangePasswordApiState(API_STATES.none);
                }
                break;
            case API_STATES.error:
                let errorStatus = apiResult.error;
                handleApiError(
                    errorStatus,
                    setChangePasswordApiState,
                    setChangePasswordApiError,
                    'User not found'
                );
                break;
            default:
                setChangePasswordApiError('');
                setChangePasswordApiState(API_STATES.none);
        }
    }

    const isSubmitEnabled = () => {
        return (
            passwordValue.currentPassword !== '' &&
            passwordErrorList.currentPassword.length === 0 &&
            passwordValue.newPassword !== '' &&
            passwordErrorList.newPassword.length === 0 &&
            passwordValue.confirmPassword === passwordValue.newPassword &&
            changePasswordApiState !== API_STATES.loading
        );
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => findPasswordErrors(), [passwordValue]);

    return (
        <>
            <h1>Change Password</h1>
            <hr />
            {resetPasswordFlag && (
                <p className={generalStyles.statusMessage}>
                    Your password has been marked as needing to be changed. Please create a new password in order to use
                    the system.
                </p>
            )}

            <PasswordInput
                label="Current Password:"
                placeholder="Current Password"
                value={passwordValue.currentPassword}
                onPasswordChange={(event) => onPasswordValueChange('currentPassword', event.target.value)}
                errorList={passwordErrorList.currentPassword}
                disabled={changePasswordApiState === API_STATES.loading}
            />
            <PasswordInput
                label="New Password:"
                placeholder="New Password"
                value={passwordValue.newPassword}
                onPasswordChange={(event) => onPasswordValueChange('newPassword', event.target.value)}
                errorList={passwordErrorList.newPassword}
                disabled={changePasswordApiState === API_STATES.loading}
            />
            <PasswordInput
                label="Confirm Password:"
                placeholder="Confirm Password"
                value={passwordValue.confirmPassword}
                onPasswordChange={(event) => onPasswordValueChange('confirmPassword', event.target.value)}
                errorList={passwordErrorList.confirmPassword}
                disabled={changePasswordApiState === API_STATES.loading}
            />
            <button
                data-test="change-password-submit-btn"
                className={generalStyles.submitButton}
                disabled={!isSubmitEnabled()}
                onClick={checkCurrentPassword}
            >
                Submit
            </button>
            <span data-test="change-pwd-result">
                {changePasswordApiState === API_STATES.success && (
                    <label className={generalStyles.successMessage}>successfully updated</label>
                )}
                {changePasswordApiState === API_STATES.loading && (
                    <label className={generalStyles.statusMessage}>Processing....</label>
                )}
                {changePasswordApiState === API_STATES.error && (
                    <label className={generalStyles.errorMessage}>{changePasswordApiError}</label>
                )}
            </span>
            <h2>Password Rules</h2>
            <hr />
            <ul className={generalStyles.infoList}>
                <li>New password must be at least 8 characters.</li>
                <li>New password must be no longer than 127 characters.</li>
                <li>New password must not use a commonly used password.</li>
                <li>New password must not use 4 or more sequential characters.</li>
                <li>New password must not use 4 or more repeated characters.</li>
                <li>New password must not be similar to your current password.</li>
                <li>New password must not be similar to your user name</li>
                <li>New password must not be similar to email domain</li>
                <li>Confirm password must be the same as your new password</li>
            </ul>
        </>
    );
}
