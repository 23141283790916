import React, { useState, useEffect } from 'react';
import styles from './SetupNewCompany.module.scss';
import 'react-datepicker/dist/react-datepicker.css';
import API_STATES from '@/constants/StateConstants';
import { getAllReports, getChildSkuDetails } from '@/services/api/product.service';
import Select from 'react-select';
import { DebounceInput } from 'react-debounce-input';
import generalStyles from '@/components/General/Styles.module.scss';
import { handleApiError } from '@/components/General/HandleApiError';
//import RadioButton from '@/components/General/RadioButton';

function BuyReports(props) {
    const [subscriptionApiState, setSubscriptionApiState] = useState(API_STATES.none);
    const [subscriptionApiError, setSubscriptionApiError] = useState('');
    const [subscription, setSubscription] = useState({});
    const [subscriptionList, setSubscriptionList] = useState([]);

    const [productSkuArray, setProductSkuArray] = useState([]);
    const [newChildSku, setNewChildSku] = useState(0);
    const [childSkuData, setChildSkuData] = useState({});
    const [childSkuApiStatus, setChildSkuApiStatus] = useState(API_STATES.none);
    const [childSkuApiError, setChildSkuApiError] = useState('');
    const [count, setCount] = useState(0);
    const [parentCount, setParentCount] = useState(0);
    const [addonYearlyPrice, setAddonYearlyPrice] = useState(0);
    const [parentYearlyPrice, setParentYearlyPrice] = useState(0);

    const [sectionTotal, setSectionTotal] = useState(0);

    // const [parentBillingFrequency, setParentBillingFrequency] = useState('monthly');
    // const handleParentMonthlyChange = () => {
    //     setParentBillingFrequency('monthly');
    // };

    // const handleParentUpfrontChange = () => {
    //     setParentBillingFrequency('upfront');
    // };
    // //child
    // const [addonBillingFrequency, setAddonBillingFrequency] = useState('monthly');
    // const handleAddonMonthlyChange = () => {
    //     setAddonBillingFrequency('monthly');
    // };

    // const handleAddonUpfrontChange = () => {
    //     setAddonBillingFrequency('upfront');
    // };

    useEffect(() => {
        const addonTotal = productSkuArray.reduce((accumulator, object) => {
            return accumulator + object.quantity * object.annualPrice;
        }, 0);
        const annualTotal =
            Number(parseFloat(parentYearlyPrice * parentCount).toFixed(2)) + Number(parseFloat(addonTotal).toFixed(2));
        setSectionTotal(Number(parseFloat(annualTotal).toFixed(2)));
    }, [parentYearlyPrice, productSkuArray, parentCount]);

    useEffect(() => {
        if (props.editableItem?.edit === true) {
            setSubscription({
                label: props.editableItem.label,
                value: props.editableItem.sku,
                ns_catalog_item_id: props.editableItem.nsCatalogItemId,
                count: props.editableItem.quantity,
                type: 'Report'
            });
            setProductSkuArray(
                props.editableItem?.addons?.map((item) => {
                    return {
                        label: item?.label,
                        sku: item?.sku,
                        quantity: item?.quantity,
                        ns_catalog_item_id: item?.ns_catalog_item_id,
                        monthlyPrice: item?.monthlyPrice,
                        annualPrice: item?.annualPrice,
                        billingFrequency: item?.billingFrequency
                    };
                })
            );
            setParentYearlyPrice(props.editableItem.annualPrice);
            //setParentBillingFrequency(props.editableItem.billingFrequency);
            setParentCount(props.editableItem.quantity);
        }
    }, [props.editableItem]);

    function onParentCountChange(e) {
        const newCount = +e.target.value;
        setParentCount(newCount);
        setSubscription({
            ...subscription,
            count: newCount
        });
    }

    const onDebounceInputChange = (value) => {
        setChildSkuApiStatus(API_STATES.none);
        setChildSkuApiError('');
        setChildSkuData({});
        setNewChildSku(value);
        if (value !== null && value !== 0 && value !== '') {
            if (productSkuArray.some((product) => product.sku === value)) {
                setChildSkuApiStatus(API_STATES.error);
                setChildSkuApiError('Duplicate sku not allowed in selection');
            } else {
                getChildSkuData(value);
            }
        }
    };

    async function getChildSkuData(childSku) {
        setChildSkuApiStatus(API_STATES.loading);
        const result = await getChildSkuDetails(childSku);
        switch (result.status) {
            case API_STATES.success:
                if (result.data) {
                    if (!result.data?.type.includes('addon')) {
                        setChildSkuApiStatus(API_STATES.error);
                        setChildSkuApiError('Not an addon');
                    } else {
                        setChildSkuApiStatus(API_STATES.success);
                        setChildSkuData(result.data);
                    }
                } else {
                    setChildSkuApiStatus(API_STATES.error);
                    setChildSkuApiError('Invalid sku entry');
                }
                break;
            case API_STATES.error:
                let error = result.error;
                handleApiError(error, setChildSkuApiStatus, setChildSkuApiError);
                break;
            default:
                setChildSkuApiError('');
                setChildSkuApiStatus(API_STATES.none);
        }
    }

    const onAddSubscription = () => {
        props.addCartItem({
            nsCatalogItemId: subscription.ns_catalog_item_id,
            sku: subscription.value,
            quantity: subscription.count,
            label: subscription.label,
            type: subscription.type,
            addons: productSkuArray,
            annualPrice: parentYearlyPrice,
            sectionTotal: sectionTotal
            //billingFrequency: parentBillingFrequency
        });
        setSubscription({});
        setProductSkuArray([]);
        setNewChildSku(0);
        setChildSkuData({});
        setChildSkuApiStatus(API_STATES.none);
        setChildSkuApiError('');
        setCount(0);
        setParentCount(0);
        setAddonYearlyPrice(0);
        setParentYearlyPrice(0);
        // setParentBillingFrequency('monthly');
        // setAddonBillingFrequency('monthly');
    };

    const addProductSku = () => {
        setProductSkuArray([
            ...productSkuArray,
            {
                label: childSkuData?.title,
                sku: childSkuData?.sku,
                quantity: count,
                ns_catalog_item_id: childSkuData?.id,
                annualPrice: addonYearlyPrice
                //billingFrequency: addonBillingFrequency
            }
        ]);
        setChildSkuApiStatus(API_STATES.none);
        setNewChildSku(0);
        setCount(0);
        setChildSkuData({});
        setAddonYearlyPrice(0);
        //setAddonBillingFrequency('monthly');
    };
    const removeProductSku = (sku) => {
        setProductSkuArray(productSkuArray.filter((productItem) => productItem !== sku));
    };

    const onSubscriptionChange = (type) => {
        setSubscription(type);
        setProductSkuArray([]);
        setNewChildSku(0);
        setChildSkuData({});
        setChildSkuApiStatus(API_STATES.none);
        setChildSkuApiError('');
        setCount(0);
        setParentCount(0);
        setAddonYearlyPrice(0);
        setParentYearlyPrice(0);
        //setParentBillingFrequency('monthly');
    };

    const isSubmitEnabled = () => {
        return (
            subscription.value &&
            parentCount !== 0 &&
            parentYearlyPrice !== 0 &&
            parentYearlyPrice?.toString().trim().length !== 0
        );
    };

    async function getSubscriptionList() {
        setSubscriptionApiState(API_STATES.loading);
        const response = await getAllReports();
        switch (response.status) {
            case API_STATES.success:
                setSubscriptionApiState(API_STATES.success);
                const data = response.data.map((row) => {
                    return {
                        product_id: row.id,
                        label: row.title,
                        value: row.sku,
                        ns_catalog_item_id: row.ns_catalog_item_id,
                        type: 'Report'
                    };
                });
                setSubscriptionList(data);
                break;
            case API_STATES.error:
                let error = response.error;
                handleApiError(error, setSubscriptionApiState, setSubscriptionApiError);
                break;
            default:
                setSubscriptionApiError('');
                setSubscriptionApiState(API_STATES.none);
        }
    }

    useEffect(() => {
        getSubscriptionList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className={styles.billHeading}>
                <h4>Reports</h4>
                <h5>Item Total: $ {parseFloat(sectionTotal).toFixed(2)}</h5>
            </div>
            {subscriptionApiState === API_STATES.loading && (
                <label className={generalStyles.statusMessage}>Loading reports...</label>
            )}
            {subscriptionApiState === API_STATES.error && (
                <label className={generalStyles.errorMessage}>{subscriptionApiError}</label>
            )}
            {subscriptionApiState === API_STATES.success && (
                <>
                    <div className={generalStyles['input-wrapper']}>
                        <label>Select Report(s):</label>
                        <span className={styles.formField}>
                            <Select
                                classNamePrefix={'react-select'}
                                onChange={onSubscriptionChange}
                                options={subscriptionList}
                                value={subscription}
                            />
                        </span>
                    </div>
                    <div>
                        <p>Parent Sku: {subscription.value}</p>
                        <br />
                        <p>
                            Quantity:{' '}
                            <input
                                className={styles.tableInputContainer}
                                type={'number'}
                                value={parentCount}
                                min={0}
                                onChange={onParentCountChange}
                            />
                        </p>
                        <br />
                        <p>
                            Report Annual Price($):{' '}
                            <input
                                className={styles.tableInputContainer}
                                value={parentYearlyPrice}
                                onChange={(e) => {
                                    const newCount = e.target.value;
                                    setParentYearlyPrice(newCount);
                                }}
                            />
                        </p>
                        {/* <br />
                        <label>Parent Billing Frequency:</label>
                        <RadioButton
                            label="Monthly"
                            value={parentBillingFrequency === 'monthly'}
                            onChange={handleParentMonthlyChange}
                        />
                        <RadioButton
                            label="Upfront"
                            value={parentBillingFrequency === 'upfront'}
                            onChange={handleParentUpfrontChange}
                        />
                        <br /> */}
                        <br />
                        <b>Report Add-Ons</b>
                        {productSkuArray.length === 0 ? (
                            <p className={generalStyles.statusMessage}>No report skus selected</p>
                        ) : (
                            <>
                                <p>Child Sku:</p>
                                <ul className={styles.tileList}>
                                    {productSkuArray.map((sku) => (
                                        <li data-test="product-sku-list-item" key={sku}>
                                            <strong
                                                className={styles.removeButton}
                                                onClick={() => removeProductSku(sku)}
                                            >
                                                X
                                            </strong>
                                            [{sku.sku}] {sku.label} - {sku.quantity} - $
                                            {parseFloat(sku.annualPrice).toFixed(2)}/year
                                        </li>
                                    ))}
                                </ul>
                            </>
                        )}

                        <table className={styles.creditTable}>
                            <thead>
                                <tr>
                                    <th>Sku</th>
                                    <th>Product Name</th>
                                    <th>Product Type</th>
                                    <th>Quantity</th>
                                    <th>Annual Price($)</th>
                                    {/* <th>Billing Frequency</th> */}
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className={styles.tableInputContainer}>
                                        <DebounceInput
                                            placeholder="child sku"
                                            className={styles.tableInput}
                                            value={newChildSku === 0 ? '' : newChildSku}
                                            minLength={1}
                                            debounceTimeout={1000}
                                            onChange={(event) => onDebounceInputChange(event.target.value)}
                                        />
                                    </td>
                                    <td className={generalStyles.cap}>
                                        {childSkuApiStatus === API_STATES.success
                                            ? childSkuData?.title || 'Unknown Title'
                                            : ''}
                                        <label>
                                            {childSkuApiStatus === API_STATES.loading ? (
                                                <span className={generalStyles.statusMessage}>Fetching data...</span>
                                            ) : childSkuApiStatus === API_STATES.error ? (
                                                <span className={generalStyles.errorMessage}>
                                                    Error: {childSkuApiError}
                                                </span>
                                            ) : (
                                                childSkuApiStatus === API_STATES.success && (
                                                    <span className={generalStyles.successMessage}></span>
                                                )
                                            )}
                                        </label>
                                    </td>
                                    <td className={generalStyles.cap}>
                                        {childSkuApiStatus === API_STATES.success
                                            ? childSkuData?.type || 'Unknown Type'
                                            : ''}
                                    </td>
                                    <td>
                                        <input
                                            className={generalStyles.numeric}
                                            type={'number'}
                                            value={count}
                                            min={0}
                                            onChange={(e) => {
                                                const newCount = +e.target.value;
                                                setCount(newCount);
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            className={generalStyles.numeric}
                                            value={addonYearlyPrice}
                                            onChange={(e) => {
                                                const newCount = e.target.value;
                                                setAddonYearlyPrice(newCount);
                                            }}
                                        />
                                    </td>
                                    {/* <td>
                                        <RadioButton
                                            label="Monthly"
                                            value={addonBillingFrequency === 'monthly'}
                                            onChange={handleAddonMonthlyChange}
                                        />

                                        <RadioButton
                                            label="Upfront"
                                            value={addonBillingFrequency === 'upfront'}
                                            onChange={handleAddonUpfrontChange}
                                        />
                                    </td> */}
                                    <td>
                                        <button
                                            className={generalStyles.smallButton}
                                            disabled={
                                                newChildSku === 0 ||
                                                newChildSku === '' ||
                                                childSkuApiStatus === API_STATES.error ||
                                                childSkuApiStatus === API_STATES.loading ||
                                                count === 0 ||
                                                addonYearlyPrice === 0 ||
                                                addonYearlyPrice?.toString().trim().length === 0
                                            }
                                            onClick={addProductSku}
                                        >
                                            Add
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <button
                        className={generalStyles.submitButton}
                        disabled={!isSubmitEnabled()}
                        onClick={onAddSubscription}
                    >
                        Add to Cart
                    </button>
                </>
            )}
        </>
    );
}
export default BuyReports;
