import styles from './OnDemandSingleAlert.module.scss';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { useState, useEffect } from 'react';
import * as Constants from './Constants';
import generalStyles from '@/components/General/Styles.module.scss';
import TextInput from '../General/TextInput';
import API_STATES from '@/constants/StateConstants';
import { getCategoriesForBrand } from '@/services/api/product.service';
import { getMatchingBrands } from '@/services/api/product.service';
import { Link } from 'react-router-dom';
import store from '@/store';
import * as SalesAPI from '../../services/api/sales.service';
import isEmail from 'is-email';
import debounce from 'lodash.debounce';

export function OnDemandSingleAlert(props) {
    const [selectedEmailType, setSelectedEmailType] = useState({});

    const [toName, setToName] = useState('');
    const [toEmail, setToEmail] = useState('');

    const [fromName, setFromName] = useState('');
    const [fromEmail, setFromEmail] = useState('');

    /**
     * Handles the selection of the alert/email type from the selection/dropdown.
     */
    const handleSelectedEmail = (email) => {
        setSelectedEmailType(email);
    };

    const [categoriesApiState, setCategoriesApiState] = useState(API_STATES);
    const [categoriesApiError, setCategoriesApiError] = useState('');
    const [categoriesForBrand, setCategoriesForBrand] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState({});

    async function loadCategoriesForBrand(brandName) {
        setCategoriesApiState(API_STATES.loading);
        const response = await getCategoriesForBrand(brandName);
        if (response.status === API_STATES.success) {
            const categories = response.data;
            if (categories.length === 1 && categories[0] !== selectedCategory.value) {
                setSelectedCategory({ label: categories[0], value: categories[0] });
            } else if (selectedCategory.value && !categories.includes(selectedCategory.value)) {
                setSelectedCategory({});
            }
            setCategoriesForBrand(
                response.data.map((category) => {
                    return { label: category, value: category };
                })
            );
            setCategoriesApiState(API_STATES.success);
        } else {
            setCategoriesApiError(response.error);
            setCategoriesForBrand([]);
            setSelectedCategory({});
            setCategoriesApiState(API_STATES.error);
        }
    }

    const handleSelectedCategory = (category) => {
        setSelectedCategory(category);
    };

    const [brandsApiState, setBrandsApiState] = useState(API_STATES.none);
    const [brandsApiError, setBrandsApiError] = useState('');
    const [selectedBrand, setSelectedBrand] = useState({});

    const [sendAlertApiState, setSendAlertApiState] = useState(API_STATES.none);
    const [alertResponse, setAlertResponse] = useState(null);
    const [sendAlertApiError, setSendAlertApiError] = useState('');

    const loadBrands = async (brandSearchTerm, callback) => {
        if (brandSearchTerm === '') {
            setBrandsApiState(API_STATES.none);
            callback([]);
            return;
        }
        setBrandsApiState(API_STATES.loading);
        const response = await getMatchingBrands(brandSearchTerm);
        if (response.status === API_STATES.success) {
            const brandsData = response.data;
            callback(
                brandsData.brands.sort().map((brand) => {
                    return { label: brand, value: brand };
                })
            );
            setBrandsApiState(API_STATES.success);
        } else {
            setBrandsApiError(response.error);
            setBrandsApiState(API_STATES.error);
            callback([]);
        }
    };
    const loadBrandsDebounced = debounce(loadBrands, 1000);

    const handleSelectedBrand = (brand) => {
        setSelectedBrand(brand);
    };

    const isSubmitDisabled = () => {
        return (
            !fromName ||
            !toName ||
            !fromEmail ||
            !isEmail(fromEmail) ||
            !isEmail(toEmail) ||
            !toEmail ||
            !selectedBrand.value ||
            !selectedCategory.value
        );
    };

    const sendAlert = async () => {
        setSendAlertApiState(API_STATES.loading);
        setAlertResponse(null);
        const initiator = store.getState().account.user.email;
        const postBodyData = [
            {
                initiator,
                email: toEmail,
                brand: selectedBrand.value,
                category: selectedCategory.value,
                alertType: selectedEmailType.value,
                replyToEmail: fromEmail,
                fromName: fromName,
                firstName: toName,
            },
        ];

        const result = await SalesAPI.submitOnDemandSalesAlerts(postBodyData);
        // We track actual statuses of the lines of data in the status table,
        // not here.  So this is just to see if the submission worked or not.
        // If it did, indicate this in a state, so we can check that later
        // and continue to show the "send email" button, or change that to a
        // link to the status table.
        switch (result.status) {
            case API_STATES.success:
                setSendAlertApiState(API_STATES.success);
                setAlertResponse(result.data);
                resetForm();
                break;
            default:
                const errorStatus = result.error || 'unknown error';
                setSendAlertApiError(`error submitting mails: ${errorStatus}`);
                setSendAlertApiState(API_STATES.error);
                break;
        }
    };

    const resetForm = () => {
        setToName('');
        setToEmail('');
        setFromName('');
        setFromEmail('');
        setSelectedEmailType({});
        setSelectedCategory({});
    };

    useEffect(() => {
        if (selectedBrand.value) {
            loadCategoriesForBrand(selectedBrand.value);
        } else {
            setSelectedCategory({});
            setCategoriesForBrand([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedBrand]);

    return (
        <>
            <h1>Single On Demand Sales Alert</h1>
            <hr />

            {/* alertType */}
            <div className={generalStyles['input-wrapper']}>
                <label>Alert Type:</label>
                <span className={styles.formField}>
                    <Select
                        classNamePrefix={'react-select'}
                        options={Constants.emailTypes}
                        onChange={handleSelectedEmail}
                        value={selectedEmailType}
                    />
                </span>
            </div>

            {/* to Name (first name) */}
            <TextInput
                type="text"
                label="First Name:"
                placeholder="First Name"
                value={toName}
                onInputChange={(event) => setToName(event.target.value)}
            />

            {/* to email */}
            <div className={styles.formItem}>
                <TextInput
                    type="email"
                    label="Email:"
                    placeholder="Email"
                    value={toEmail}
                    onInputChange={(event) => setToEmail(event.target.value)}
                />
                {toEmail && !isEmail(toEmail) && (
                    <span className={generalStyles.errorMessage}>invalid email format</span>
                )}
            </div>

            {/* brand */}
            <div className={generalStyles['input-wrapper']}>
                <label>Brand:</label>
                <span className={styles.formField}>
                    <AsyncSelect
                        cacheOptions
                        backspaceRemovesValue
                        loadOptions={loadBrandsDebounced}
                        onChange={handleSelectedBrand}
                        escapeClearsValue={false}
                        noOptionsMessage={() =>
                            brandsApiState === API_STATES.success
                                ? 'No brands match the search'
                                : 'Please enter brand name to search'
                        }
                    />
                </span>
                {brandsApiState === API_STATES.error && (
                    <span className={generalStyles.errorMessage}>{brandsApiError}</span>
                )}
            </div>

            {/* category */}
            <div className={generalStyles['input-wrapper']}>
                <label>Category:</label>
                <span className={styles.formField}>
                    <Select
                        classNamePrefix={'react-select'}
                        onChange={handleSelectedCategory}
                        options={categoriesForBrand}
                        value={selectedCategory}
                        isDisabled={categoriesApiState !== API_STATES.success || !selectedBrand.value}
                    />
                </span>
                {!selectedBrand.value ? (
                    <span className={generalStyles.statusMessage}>Please select brand</span>
                ) : (
                    categoriesApiState === API_STATES.loading && (
                        <span className={generalStyles.statusMessage}>Loading categories for brand....</span>
                    )
                )}
                {categoriesApiState === API_STATES.error && (
                    <span className={generalStyles.errorMessage}>{categoriesApiError}</span>
                )}
            </div>

            {/* from name */}
            <TextInput
                type="text"
                label="From Name:"
                placeholder="From Name"
                value={fromName}
                onInputChange={(event) => setFromName(event.target.value)}
            />

            {/* from email */}
            <div className={styles.formItem}>
                <TextInput
                    type="email"
                    label="Reply to Email:"
                    placeholder="Reply to Email"
                    value={fromEmail}
                    onInputChange={(event) => setFromEmail(event.target.value)}
                />
                {fromEmail && !isEmail(fromEmail) && (
                    <span className={generalStyles.errorMessage}>invalid email format</span>
                )}
            </div>
            <div>
                <button className={generalStyles.submitButton} onClick={sendAlert} disabled={isSubmitDisabled()}>
                    Send Alert
                </button>
                {sendAlertApiState === API_STATES.loading ? (
                    <span className={generalStyles.statusMessage}>Processing...</span>
                ) : sendAlertApiState === API_STATES.error ? (
                    <span className={generalStyles.errorMessage}>Error: {sendAlertApiError}</span>
                ) : (
                    sendAlertApiState === API_STATES.success && (
                        <>
                            <span className={generalStyles.statusMessage}> Alert Sent </span>
                            {alertResponse?.correlationId ? (
                                <Link
                                    className={generalStyles.link}
                                    to={`/ondemandalertdetails/${alertResponse.correlationId}`}
                                >
                                    View Submitted Alert Details
                                </Link>
                            ) : (
                                <Link className={generalStyles.link} to={`/ondemandalertsstatus`}>
                                    Go To Alerts Status
                                </Link>
                            )}
                        </>
                    )
                )}
            </div>
        </>
    );
}
