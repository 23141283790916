import React, { useEffect, useState } from 'react';
import API_STATES from '@/constants/StateConstants';
import generalStyles from '@/components/General/Styles.module.scss';
import styles from '@/components/NewCompanyPage/NewCompanyPage.module.scss';
import { handleApiError } from '@/components/General/HandleApiError';
import Popup from 'reactjs-popup';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import calendarIcon from '@images/icons/calendar.svg';
import moment from 'moment';
import { updateByzzerAccessEndDate, getByzzerAccessEndDate } from '@/services/api/company.service';


function ByzzerAccessEndDate({ nsCompanyId, isEditable = true, refreshTimeStamp }) {

    const [confirmingAccessEndDateInput, setConfirmingAccessEndDateInput] = useState(null)
    const [updateAccessEndDateApiError, setUpdateAccessEndDateApiError] = useState('');
    const [updateAccessEndDateApiStatus, setUpdateAccessEndDateApiStatus] = useState(API_STATES.none);

    const [byzzerAccessEndDate, setByzzerAccessEndDate] = useState(null);
    const [byzzerAccessEndDateInput, setByzzerAccessEndDateInput] = useState(null)

    const [getByzzerAccessEndDateApiError, setGetByzzerAccessEndDateApiError] = useState('')
    const [getByzzerAccessEndDateApiStatus, setGetByzzerAccessEndDateApiStatus] = useState(API_STATES.none)

    const [isInEditMode, setIsInEditMode] = useState(false)

    function onEditByzzerAccessEndDate() {
        const byzzerAccessEndDateInput = isNaN(byzzerAccessEndDate) ? moment() : byzzerAccessEndDate
        setByzzerAccessEndDateInput(byzzerAccessEndDateInput)
        setIsInEditMode(true)
    }

    function onByzzerAccessEndDateChange(date) {
        const byzzerAccessEndDateInput = moment(date)
        if (byzzerAccessEndDate !== null && byzzerAccessEndDate > byzzerAccessEndDateInput) {
            setConfirmingAccessEndDateInput(byzzerAccessEndDateInput)
            return 
        }
        setByzzerAccessEndDateInput(byzzerAccessEndDateInput)
    }

    function onConfirmByzzerAccessEndDateInput(byzzerAccessEndDateInput) {
        setByzzerAccessEndDateInput(byzzerAccessEndDateInput)
        setConfirmingAccessEndDateInput(null)
    }
    
    function onCancelByzzerAccessEndDateInput() {
        setConfirmingAccessEndDateInput(null)
    }

    useEffect(() => {
        fetchByzzerAccessEndDate(nsCompanyId)
    }, [nsCompanyId, refreshTimeStamp])

    async function fetchByzzerAccessEndDate(nsCompanyId) {
        setGetByzzerAccessEndDateApiStatus(API_STATES.loading)
        let result = await getByzzerAccessEndDate(nsCompanyId);

        if (result.status === API_STATES.success) {
            const formattedDate = new Date(
                new Date(result.data[0].access_end_dtm).getTime() + new Date().getTimezoneOffset() * 60000
            );
            setByzzerAccessEndDate(result.data[0].access_end_dtm ? moment(formattedDate) : null);
            setGetByzzerAccessEndDateApiStatus(API_STATES.success);
        } else {
            const error = result.error
            handleApiError(
                error,
                setGetByzzerAccessEndDateApiStatus,
                setGetByzzerAccessEndDateApiError,
            );
        }

    }

    async function onSaveByzzerAccessEndDate(nsCompanyId, byzzerAccessEndDateInput) {
        setUpdateAccessEndDateApiStatus(API_STATES.loading);
        //to convert byzzer access end date from locale date string to UTC format
        const formattedAccessEndDate = new Date(
            byzzerAccessEndDateInput.toDate().getTime() - byzzerAccessEndDateInput.toDate().getTimezoneOffset() * 60000
        ).toISOString();
        let result = await updateByzzerAccessEndDate(nsCompanyId, formattedAccessEndDate);
        if (result.status === API_STATES.success) {
            setUpdateAccessEndDateApiStatus(API_STATES.success);
            setTimeout(() => {
                fetchByzzerAccessEndDate(nsCompanyId)
                setUpdateAccessEndDateApiStatus(API_STATES.none)
                setIsInEditMode(false)
            }, 2000)
        } else {
            handleApiError(result.error, setUpdateAccessEndDateApiStatus, setUpdateAccessEndDateApiError);
            setTimeout(() => setUpdateAccessEndDateApiStatus(API_STATES.none), 6000)
        }
    }

    return (
        <>
            {getByzzerAccessEndDateApiStatus === API_STATES.loading ? (
                <span className={generalStyles.statusMessage}>Fetching byzzer access end date...</span>
            ) : getByzzerAccessEndDateApiStatus === API_STATES.error ? (
                <span className={generalStyles.errorMessage}>{getByzzerAccessEndDateApiError}</span>
            ) : (
                <span data-test="byzzer-access-end-date">
                    {isEditable && isInEditMode ? (
                        <>
                            <div className={generalStyles['input-wrapper']}>
                                <label>Access End Date:</label>
                                <span className={generalStyles.formField}>
                                    <div className={styles.dateContainer}>
                                        <DatePicker
                                            name="byzzerAccessEndDate"
                                            placeholderText="mm/dd/yyyy"
                                            shouldCloseOnSelect={true}
                                            fixedHeight
                                            closeOnScroll={true}
                                            dateFormat="MMM d, y"
                                            selected={byzzerAccessEndDateInput ? byzzerAccessEndDateInput.toDate() : moment().toDate()}
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            tabIndex={1}
                                            onChange={(date) => {
                                                onByzzerAccessEndDateChange(date)
                                            }}
                                        />
                                        <img src={calendarIcon} alt="calendar icon" />
                                    </div>
                                </span>
                            </div>
                            <button
                                onClick={() => onSaveByzzerAccessEndDate(nsCompanyId, byzzerAccessEndDateInput)}
                                className={generalStyles.submitButton}
                                disabled={
                                    !isEditable ||
                                    updateAccessEndDateApiStatus === API_STATES.loading ||
                                    updateAccessEndDateApiStatus === API_STATES.success
                                }>
                                {byzzerAccessEndDate === null ? 'Set Access End Date' : 'Save'}
                            </button>
                            {
                                updateAccessEndDateApiStatus === API_STATES.loading ? (
                                    <span className={generalStyles.statusMessage}>Saving...</span>
                                ) : updateAccessEndDateApiStatus === API_STATES.error ? (
                                    <span className={generalStyles.errorMessage}>{updateAccessEndDateApiError}</span>
                                ) : updateAccessEndDateApiStatus === API_STATES.success ? (
                                    <span className={generalStyles.successMessage}>Saved!</span>
                                ) : (<button
                                    className={generalStyles.submitButton}
                                    onClick={() => setIsInEditMode(false)}
                                >
                                    Cancel
                                </button>)
                            }
                        </>
                    ) : (
                        <>
                            <div className={generalStyles['input-wrapper']}>
                                <label>Access End Date:</label>
                                <span className={generalStyles.formField}>{byzzerAccessEndDate ? byzzerAccessEndDate.format('ddd MMM DD YYYY') : 'N/A'}</span>
                            </div>
                            {isEditable && !isInEditMode &&
                                <button className={generalStyles.submitButton} onClick={onEditByzzerAccessEndDate}>Edit</button>
                            }
                        </>
                    )}

                </span>
            )
            }
            {confirmingAccessEndDateInput &&
                <>
                    <Popup
                        open={confirmingAccessEndDateInput != null}
                        onClose={onCancelByzzerAccessEndDateInput}
                        modal
                    >
                        {() => (
                            <div>
                                <b>You are setting the access end date to be earlier than the current end date. Please make sure that you want to reduce the number of days of access for this company.</b>
                                <br />
                                <div className={styles.buttonContainer}>
                                    <button className={generalStyles.confirmOperationButton} onClick={() => onConfirmByzzerAccessEndDateInput(confirmingAccessEndDateInput)}>Submit</button>
                                    <button className={generalStyles.cancelOperationButton} onClick={onCancelByzzerAccessEndDateInput}>Cancel</button>
                                </div>
                            </div>)
                        }
                    </Popup>
                </>
            }
        </>
    )
}

export default ByzzerAccessEndDate;