const API_STATES = {
    loading: 'loading',
    // refreshing indicates that we already have data from a successful API call and we are doing a refresh
    refreshing: 'refreshing',
    error: 'error',
    success: 'success',
    none: '',
};

export default API_STATES;
