import levenshtein from 'js-levenshtein';

const commonPasswords = [
    '!@#$%^&*',
    '0.0.0.000',
    '0000000000',
    '0123456789',
    '0192837465',
    '0987654321',
    '1029384756',
    '111111111',
    '111222333',
    '112233',
    '1123581321',
    '121212',
    '123123',
    '123321123',
    '123654789',
    '123698745',
    '123789456',
    '123qwe123',
    '123qweasd',
    '123qwerty',
    '131313',
    '134679852',
    '135792468',
    '135798642',
    '147258369',
    '147852369',
    '147896325',
    '18atcskd2w',
    '192837465',
    '1q2w3e4r5',
    '1qa2ws3ed',
    '1qaz2wsx3edc',
    '1qazxsw23edc',
    '232323',
    '321321321',
    '321654987',
    '369258147',
    '3rjs1la7qe',
    '456456456',
    '45M2DO5BS',
    '4815162342',
    '609609609',
    '696969',
    '741852963',
    '789456123',
    '789654123',
    '789789789',
    '7uGd5HIp2J',
    '8675309',
    '9293709b13',
    '963852741',
    '987456321',
    '987654321',
    'Fktrcfylh',
    'Good123654',
    'Mailcreated5240',
    'Password1',
    'PolniyPizdec0211',
    'Qwertyuiop',
    'Sojdlg123aljg',
    'Soso123aljg',
    'Translator',
    'Usuckballz1',
    'VQsaBLPzLa',
    'abc123',
    'abgrtyu',
    'abrakadabra',
    'absolutely',
    'access',
    'access14',
    'action',
    'adobe123[a]',
    'adrenalin',
    'albert',
    'alejandro',
    'aleksandr',
    'alessandro',
    'alexand',
    'alexis',
    'amanda',
    'amateur',
    'amsterdam',
    'anastasia',
    'anastasiya',
    'andrea',
    'andrew',
    'andromeda',
    'angel',
    'animal',
    'anthony',
    'apollo',
    'apple',
    'argentina',
    'armagedon',
    'arsenal',
    'arthur',
    'asdasdasd',
    'asdfghjkl',
    'asdqwe123',
    'ashley',
    'asshole',
    'august',
    'austin',
    'australia',
    'azertyuiop',
    'badboy',
    'bailey',
    'banana',
    'barcelona',
    'barney',
    'barselona',
    'baseball',
    'baseball1',
    'basketball',
    'batman',
    'beach',
    'beautiful',
    'beaver',
    'beavis',
    'benessere',
    'bigcock',
    'bigdaddy',
    'bigdick',
    'bigdog',
    'bigtits',
    'billy',
    'birdie',
    'bismillah',
    'bitch',
    'bitches',
    'biteme',
    'blablabla',
    'black',
    'blackjack',
    'blazer',
    'blonde',
    'blowjob',
    'blowme',
    'bobmarley',
    'bond007',
    'bonnie',
    'booboo',
    'boobs',
    'booger',
    'boomer',
    'booty',
    'boston',
    'brandon',
    'brandy',
    'braves',
    'brazil',
    'brian',
    'bronco',
    'bubba',
    'buddy',
    'budweiser',
    'bulldog',
    'buster',
    'butter',
    'buttercup',
    'butterfly',
    'butthead',
    'california',
    'calvin',
    'camaro',
    'cameron',
    'canada',
    'capricorn',
    'captain',
    'cardinals',
    'carlos',
    'carter',
    'cashmoney',
    'casper',
    'cassandra',
    'catherine',
    'celebrity',
    'charles',
    'charlie',
    'charlotte',
    'cheerleaers',
    'cheese',
    'chelsea',
    'chester',
    'chevrolet',
    'chevy',
    'chicago',
    'chicken',
    'chocolate',
    'chris',
    'christian',
    'christina',
    'christine',
    'christmas',
    'christopher',
    'cleopatra',
    'cnfybckfd',
    'cocacola',
    'cocksucker',
    'coffee',
    'college',
    'compaq',
    'computer',
    'cookie',
    'cooper',
    'corvette',
    'cowboy',
    'cowboys',
    'cream',
    'crystal',
    'cumming',
    'cumshot',
    'dakota',
    'dallas',
    'daniel',
    'danielle',
    'darkangel',
    'david',
    'debbie',
    'deepthroat',
    'dennis',
    'dfktynbyf',
    'diablo',
    'diamond',
    'dirty',
    'dkflbckfd',
    'doctor',
    'doggie',
    'dolphin',
    'dolphins',
    'donald',
    'dragon',
    'dragonball',
    'dreams',
    'driver',
    'eagle',
    'earthlink',
    'edward',
    'einstein',
    'ekaterina',
    'elizabeth',
    'elizaveta',
    'enjoy',
    'enter',
    'enterprise',
    'erotic',
    'evangelion',
    'evolution',
    'excalibur',
    'experienced',
    'extreme',
    'falcon',
    'favorite6',
    'fender',
    'ferrari',
    'firebird',
    'fishing',
    'fktrcfylh',
    'florida',
    'flower',
    'flyers',
    'football',
    'forever',
    'francesco',
    'frank',
    'freddy',
    'freedom',
    'friendster',
    'fuck',
    'fyfcnfcbz',
    'fyutkjxtr',
    'gandalf',
    'gateway',
    'gators',
    'gemini',
    'george',
    'gfhjkm123',
    'gfhjkmgfhjkm',
    'ghbdtn123',
    'ghbywtccf',
    'ghhh47hj7649',
    'ghjcnbnenrf',
    'ghjcnjgfhjkm',
    'ghjcnjnfr',
    'ghjuhfvvf',
    'ghostrider',
    'giants',
    'ginger',
    'girls',
    'gladiator',
    'godfather',
    'golden',
    'golfer',
    'gordon',
    'great',
    'green',
    'gregory',
    'guitar',
    'gunner',
    'hammer',
    'hannah',
    'happiness',
    'happy',
    'hardcore',
    'harley',
    'harrypotter',
    'heather',
    'hello',
    'hellokitty',
    'helpme',
    'hentai',
    'highlander',
    'hockey',
    'hollywood',
    'hooters',
    'horney',
    'horny',
    'hotdog',
    'house',
    'htubcnhfwbz',
    'hunter',
    'hunting',
    'hurricane',
    'iceman',
    'idontknow',
    'iloveyou',
    'iloveyou1',
    'iloveyou2',
    'intercourse',
    'internet',
    'ironmaiden',
    'iwantu',
    'jackie',
    'jackson',
    'jaguar',
    'james',
    'jamesbond',
    'japan',
    'jasmine',
    'jason',
    'jasper',
    'jellybean',
    'jennifer',
    'jeremy',
    'jessica',
    'johnny',
    'johnson',
    'jordan',
    'joseph',
    'joshua',
    'juice',
    'junior',
    'justin',
    'kamasutra',
    'katherine',
    'kelly',
    'kevin',
    'killer',
    'killer123',
    'kissmyass',
    'kitty',
    'kjrjvjnbd',
    'kleopatra',
    'knight',
    'konstantin',
    'ladies',
    'lakers',
    'lauren',
    'leather',
    'leavemealone',
    'legend',
    'letmein',
    'lightning',
    'little',
    'liverpool',
    'lkjhgfdsa',
    'lokomotiv',
    'london',
    'lover',
    'lucky',
    'maddog',
    'madison',
    'maggie',
    'magic',
    'magnum',
    'manchester',
    'margarita',
    'marijuana',
    'marine',
    'marlboro',
    'marseille',
    'martin',
    'marvin',
    'master',
    'masterbate',
    'masterbating',
    'matrix',
    'matthew',
    'maverick',
    'maxwell',
    'melissa',
    'member',
    'mercedes',
    'merlin',
    'metallica',
    'michael',
    'michelle',
    'mickey',
    'microphone',
    'microsoft',
    'midnight',
    'millenium',
    'miller',
    'minecraft',
    'mississippi',
    'mistress',
    'money',
    'monica',
    'monkey',
    'monster',
    'montgom240',
    'moonlight',
    'morgan',
    'morrowind',
    'mother',
    'motherfucker',
    'motherlode',
    'mountain',
    'movie',
    'muffin',
    'multiplelo',
    'murphy',
    'music',
    'mustang',
    'mypassword',
    'naked',
    'nascar',
    'nathan',
    'naughty',
    'navigator',
    'ncc1701',
    'nevermind',
    'nevermore',
    'newcastle',
    'newyork',
    'nicholas',
    'nicole',
    'nightmare',
    'nightwish',
    'nipple',
    'nokia6233',
    'nokia6300',
    'noname123',
    'nthvbyfnjh',
    'nuttertools',
    'oliver',
    'orange',
    'ou812',
    'packers',
    'paintball',
    'panasonic',
    'panther',
    'panties',
    'paris',
    'parker',
    'password',
    'patrick',
    'peaches',
    'peanut',
    'penetration',
    'penis',
    'pepper',
    'peter',
    'phantom',
    'phoenix',
    'photoshop',
    'pineapple',
    'pinkfloyd',
    'player',
    'playstation',
    'please',
    'poiuytrewq',
    'pookie',
    'porno',
    'porsche',
    'power',
    'president',
    'prince',
    'princess',
    'private',
    'pufunga7782',
    'purple',
    'pussies',
    'pussy',
    'q1w2e3r4t5',
    'qazwsx',
    'qazxswedc',
    'qwe123qwe',
    'qweasd123',
    'qweasdzxc',
    'qweqweqwe',
    'qwerasdfzxcv',
    'qwert',
    'rabbit',
    'rachel',
    'racing',
    'radiohead',
    'raiders',
    'rainbow',
    'rammstein',
    'ranger',
    'rangers',
    'realmadrid',
    'rebecca',
    'redskins',
    'redsox',
    'redwings',
    'rfvfcenhf',
    'richard',
    'rjirfrgbde',
    'rjycnfynby',
    'roadrunner',
    'robert',
    'rocket',
    'rocknroll',
    'rosebud',
    'runescape',
    'runner',
    'rush2112',
    'russia',
    'salamander',
    'samantha',
    'sammy',
    'samson',
    'sandra',
    'sasha_007',
    'saturn',
    'scooby',
    'scoobydoo',
    'scooter',
    'scorpio',
    'scorpion',
    'scott',
    'sebastian',
    'secret',
    'september',
    'sersolution',
    'sexsex',
    'sexsexsex',
    'shadow',
    'shannon',
    'shaved',
    'sierra',
    'silver',
    'skateboard',
    'skippy',
    'skywalker',
    'slayer',
    'slimshady',
    'smackdown',
    'smith',
    'smokey',
    'snoopy',
    'snowboard',
    'soccer',
    'something',
    'sonyericsson',
    'sophie',
    'southpark',
    'spanky',
    'sparky',
    'spider',
    'spiderman',
    'spongebob',
    'squirt',
    'srinivas',
    'stanislav',
    'starcraft',
    'startrek',
    'starwars',
    'steelers',
    'stephanie',
    'steve',
    'steven',
    'sticky',
    'stonecold',
    'strawberry',
    'streaming',
    'stupid',
    'success',
    'suckit',
    'suckmydick',
    'summer',
    'sunflower',
    'sunshine',
    'super',
    'superman',
    'superman1',
    'supernova',
    'superstar',
    'surfer',
    'sweetness',
    'swimming',
    'swordfish',
    'sydney',
    'syncmaster',
    'taekwondo',
    'taylor',
    'teddybear',
    'teens',
    'telephone',
    'tennis',
    'teresa',
    'terminator',
    'tester',
    'testing',
    'theman',
    'thomas',
    'thunder',
    'thx1138',
    'tiffany',
    'tiger',
    'tigger',
    'tinkerbell',
    'tkbpfdtnf',
    'tokiohotel',
    'tomcat',
    'topgun',
    'tottenham',
    'toyota',
    'travis',
    'trfnthbyf',
    'trouble',
    'trustno1',
    'tucker',
    'turtle',
    'underground',
    'undertaker',
    'united',
    'universal',
    'university',
    'vSjasnel12',
    'vagina',
    'valentina',
    'valentine',
    'vfhufhbnf',
    'vfntvfnbrf',
    'victor',
    'victoria',
    'video',
    'viewsonic',
    'viking',
    'viktoriya',
    'viper',
    'vjqgfhjkm',
    'vkontakte',
    'vladislav',
    'voodoo',
    'voyager',
    'walter',
    'warhammer',
    'warrior',
    'washington',
    'webhompas',
    'webmaster',
    'welcome',
    'whatever',
    'white',
    'william',
    'willie',
    'wilson',
    'winner',
    'winston',
    'winter',
    'wizard',
    'wolverine',
    'women',
    'wonderboy',
    'wonderful',
    'wrestling',
    'xavier',
    'xxxxx',
    'yamaha',
    'yankee',
    'yellow',
    'yfcntymrf',
    'yjdsqgfhjkm',
    'young',
    'zaqwsxcde',
    'zaqxswcde',
    'zxcasdqwe',
    'zxcvbn',
    'zxcvbnm',
    'zxcvbnm123',
    'zzzzzz',
];
const repeatedCharacters = [
    '0000',
    '1111',
    '2222',
    '3333',
    '4444',
    '5555',
    '6666',
    '7777',
    '8888',
    'AAAA',
    'BBBB',
    'CCCC',
    'DDDD',
    'EEEE',
    'FFFF',
    'GGGG',
    'HHHH',
    'IIII',
    'JJJJ',
    'KKKK',
    'LLLL',
    'MMMM',
    'NNNN',
    'OOOO',
    'PPPP',
    'QQQQ',
    'RRRR',
    'SSSS',
    'TTTT',
    'UUUU',
    'VVVV',
    'WWWW',
    'XXXX',
    'YYYY',
    'ZZZZ',
    'aaaa',
    'bbbb',
    'cccc',
    'dddd',
    'eeee',
    'ffff',
    'gggg',
    'hhhh',
    'iiii',
    'jjjj',
    'kkkk',
    'llll',
    'mmmm',
    'nnnn',
    'oooo',
    'pppp',
    'qqqq',
    'rrrr',
    'ssss',
    'tttt',
    'uuuu',
    'vvvv',
    'wwww',
    'xxxx',
    'yyyy',
    'zzzz'
];
const sequences = [
    '0123',
    '0987',
    '1221',
    '1234',
    '2112',
    '2121',
    '2211',
    '2323',
    '2345',
    '2424',
    '2468',
    '2525',
    '2626',
    '2727',
    '2828',
    '3030',
    '3131',
    '3232',
    '3434',
    '3456',
    '3636',
    '4040',
    '4242',
    '4321',
    '4545',
    '4567',
    '5050',
    '5151',
    '5252',
    '5353',
    '5432',
    '5454',
    '5656',
    '5678',
    '6969',
    '6996',
    '6789',
    '6543',
    '7007',
    '7654',
    '7890',
    '8989',
    '8765',
    '9876',
    '1qaz',
    'qwer',
    'asdf',
    'poiu',
    'lkjh',
    'mnbv',
    'zxcv',
    'abcd',
    'bcde',
    'cdef',
    'efgh',
    'fghi',
    'ghij',
    'hijk',
    'ijkl',
    'jklm',
    'klmn',
    'lmno',
    'mnop',
    'nopq',
    'opqr',
    'pqrs',
    'qrst',
    'rstu',
    'stuv',
    'tuvw',
    'uvwx',
    'vwxy',
    'wxyz'
];

function getUserAndDomain(email) {
    let emailParts = email.toLowerCase().split('@');
    const userName = emailParts[0]?.toLowerCase();
    emailParts[1] = emailParts[1] || '';
    const lastDotIndex = emailParts[1].lastIndexOf('.');
    const emailDomain = emailParts[1].substr(0, lastDotIndex)?.toLowerCase();
    return { userName, emailDomain };
}

export function validatePassword(password, email) {
    const maxPasswordLength = 127;
    const minPasswordLength = 8;
    const { userName, emailDomain } = getUserAndDomain(email);

    let errorList = [];
    if (password.length < minPasswordLength) {
        errorList.push('Must be at least 8 characters.');
    }
    if (password.length > maxPasswordLength) {
        errorList.push('Must be no longer than 127 characters.');
    }
    const repeatedCharactersInPassword = repeatedCharacters.find((repeatedCharactersWord) =>
        password.includes(repeatedCharactersWord),
    );
    if (repeatedCharactersInPassword) {
        errorList.push('Must not use 4 or more repeated characters.');
    }
    const passwordLower = password.toLowerCase();
    const commonWordInPassword = commonPasswords.find((forbiddenWord) => passwordLower.includes(forbiddenWord.toLowerCase()));
    if (commonWordInPassword) {
        errorList.push('Must not use a commonly used password.');
    }
    const sequencesInPassword = sequences.find((sequence) => passwordLower.includes(sequence.toLowerCase()));
    if (sequencesInPassword) {
        errorList.push('Must not use 4 or more sequential characters.');
    }
    let similarity = levenshtein(passwordLower, userName);
    if (passwordLower.includes(userName) || similarity < 3) {
        errorList.push('Must not be similar to your user name');
    }
    similarity = levenshtein(passwordLower, emailDomain);
    if (passwordLower.includes(emailDomain) || similarity < 3) {
        errorList.push('Must not be similar to your email domain');
    }
    return errorList;
}
