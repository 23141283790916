import React, { useState, useEffect, useCallback,useMemo } from 'react';
import styles from './AccountSetup.module.scss';
import TextInput from '@/components/General/TextInput';
import generalStyles from '@/components/General/Styles.module.scss';
import { createNewCompany, updateCompany, getAllCompanyTypes } from '@/services/api/company.service';
import API_STATES from '@/constants/StateConstants';
import Select from 'react-select';
import { handleApiError } from '@/components/General/HandleApiError';
import isEmail from 'is-email';
import * as AccessLevels from '@/components/General/AccessLevels';
import AdminCredentials from './AdminCredentials';
import PlaceOrder from '@/components/AccountSetup/PlaceOrder';
import CompanyPurchasesReport from '@/components/CompanyPurchasesReport/CompanyPurchasesReport';
import ConfigureProducts from '@/components/AccountSetup/ConfigureProducts';
import AddUsers from '@/components/AccountSetup/AddUsers';
import store from '../../store';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';

function CompanyDetails(props) {
    const [companyName, setCompanyName] = useState('');
    const [companyDomain, setCompanyDomain] = useState('');
    const [alternateDomain1, setAlternateDomain1] = useState('');
    const [alternateDomain2, setAlternateDomain2] = useState('');
    const [isCompanyDomainValid, setIsCompanyDomainValid] = useState(false);
    const [isAlternateDomain1Valid, setIsAlternateDomain1Valid] = useState(false);
    const [isAlternateDomain2Valid, setIsAlternateDomain2Valid] = useState(false);
    const [companyTypeList, setCompanyTypeList] = useState([]);
    const [companyType, setCompanyType] = useState({});
    const [contact, setContact] = useState('');
    const [isValid, setIsValid] = useState(false);
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState({
        line1: '',
        line2: '',
    });
    const [city, setCity] = useState('');
    const [selectedCountry, setSelectedCountry] = useState({})
    const [state, setState] = useState('')
    const [zipcode, setZipcode] = useState('');
    const [zipcodeValid, setZipcodeValid] = useState(false);
    const [USstate, setUSState] = useState({});

    const [accessLevel, setAccessLevel] = useState({
        label: 'Manufacturer Hidden Prices',
        value: 'manufacturer_hidden_prices',
    });
    const [creditLimit, setCreditLimit] = useState(350000);
    const [creditLimitValid, setCreditLimitValid] = useState(false);
    const [upliftPercent, setUpliftPercent] = useState('');
    const [percentValid, setPercentValid] = useState(false);

    const [companyTypeApiState, setCompanyTypeApiState] = useState(API_STATES.none);
    const [companyTypeApiError, setCompanyTypeApiError] = useState('');
    const [newAccountApiState, setNewAccountApiState] = useState(API_STATES.none);
    const [newAccountApiError, setNewAccountApiError] = useState('');
    const [updateAccountApiState, setUpdateAccountApiState] = useState(API_STATES.none);
    const [updateAccountApiError, setUpdateAccountApiError] = useState('');
    const [hasDomains, setHasDomains] = useState({
        alternateDomain1: false,
        alternateDomain2: false,
    });
    const [createOrder, setCreateOrder] = useState(false);
    const [createCompany, setCreateCompany] = useState(false);
    const [adminUsername, setAdminUsername] = useState(null);
    const [adminPassword, setAdminPassword] = useState(null);
    const [adminUserId, setAdminUserId] = useState(0);
    const [adminDeleted, setAdminDeleted] = useState(false);
    const [nsCompanyId, setNsCompanyId] = useState(0);
    const [showCompanyProductsConfig, setShowCompanyProductsConfig] = useState(false);
    const [newAdminAdded, setNewAdminAdded] = useState(false);
    const [purchasesAndProductsRefreshParams, setPurchasesAndProductsRefreshParams] = useState({});
    const [companyHasPurchase, setCompanyHasPurchase] = useState(false);
    const [deleteAdminManual, setDeleteAdminManual] = useState(false);
    
    const isSuperUser = store?.getState()?.account?.isSuperUser;

    const isUpdateDisabled = useMemo(() => 
        (!isCompanyDomainValid ||
        !isAlternateDomain1Valid ||
        !isAlternateDomain2Valid ||
        !creditLimitValid ||
        !zipcodeValid ||
        !percentValid ||
        !isEmail(email) ||
        !isValid)
    , [
        isCompanyDomainValid,
        isAlternateDomain1Valid,
        isAlternateDomain2Valid,
        creditLimitValid,
        zipcodeValid,
        percentValid,
        email,
        isValid,
    ]);

    const onCompanyNameChange = (e) => {
        setCompanyName(e.target.value);
    };
    const onCompanyDomainChange = (e) => {
        setCompanyDomain(e.target.value);
    };
    const onAlternateDomain1Change = (e) => {
        setAlternateDomain1(e.target.value);
    };

    useEffect(()=>{
        if (alternateDomain1) {
            let isDomain = /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/.test(alternateDomain1);
            setIsAlternateDomain1Valid(isDomain);
        } else {
            setIsAlternateDomain1Valid(true);
        }
    },[alternateDomain1])


    const onAlternateDomain2Change = (e) => {
        setAlternateDomain2(e.target.value);
    };

    useEffect(()=>{
        if (alternateDomain2) {
            let isDomain = /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/.test(alternateDomain2);
            setIsAlternateDomain2Valid(isDomain);
        } else {
            setIsAlternateDomain2Valid(true);
        }
    },[alternateDomain2])

    const onEmailChange = (value) => {
        setEmail(value);
    };
    const onCompanyTypeChange = (type) => {
        setCompanyType(type);
    };
    const onUSStateChange = (type) => {
        setUSState(type);
    };
    const onAccessLevelChange = (type) => {
        setAccessLevel(type);
    };
    const onAddressChange = (lineNumber, value) => {
        setAddress({
            ...address,
            [lineNumber]: value,
        });
    };

    const onInternalAdminCreated = useCallback(() => {
        setNewAdminAdded(true)
        refreshPurchasesAndProducts()
    }, [])

    /**
     * @param {number} refreshDelay delay in milliseconds
     */
    const refreshPurchasesAndProducts = (refreshDelay = 0) => {
        setShowCompanyProductsConfig(true)
        setPurchasesAndProductsRefreshParams({ refreshTimestamp: Date.now(), refreshDelay })
    }

    const onPurchasesUpdated = useCallback(() => {
        refreshPurchasesAndProducts(100000)
    }, []) 

    useEffect(() => {
        (async () => {
            if (zipcode !== '') {
                let isnum = /^[a-zA-Z0-9]+$/.test(zipcode);
                setZipcodeValid(isnum);
            } else {
                setZipcodeValid(true);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [zipcode]);

    useEffect(() => {
        (async () => {
            if (upliftPercent !== '') {
                let isnum = /^[+-]?\d+(\.\d+)?$/.test(upliftPercent);
                setPercentValid(isnum);
            } else {
                setPercentValid(true);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [upliftPercent]);

    useEffect(() => {
        (async () => {
            if (companyDomain !== '') {
                const isDomain = /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/.test(companyDomain);
                setIsCompanyDomainValid(isDomain);
            } else {
                setIsCompanyDomainValid(true);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyDomain]);

    useEffect(() => {
        (async () => {
            if (creditLimit !== '') {
                const isnum = /^\d+$/.test(creditLimit);
                setCreditLimitValid(isnum);
            } else {
                setCreditLimitValid(true);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [creditLimit]);

    useEffect(() => {
        (async () => {
            if (contact !== '') {
                let isnum = /^\d+$/.test(String(contact));
                let len = String(contact).length === 10;
                setIsValid(isnum && len);
            } else {
                setIsValid(true);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contact]);

    useEffect(() => {
        getCompanyTypeDetails();
    }, []);

    useEffect(() => {
        if (props.companyInfo?.nsCompanyId) {
            setNsCompanyId(props.companyInfo.nsCompanyId);
            setCompanyName(props.companyInfo.companyName);
            setCompanyDomain(props.companyInfo.companyDomain);
            const alternateDomainProp = props.companyInfo?.alternateDomains[0];
            setAlternateDomain1(alternateDomainProp || '');
            const alternateDomainProp2 = props.companyInfo?.alternateDomains[1];
            setAlternateDomain2(alternateDomainProp2 || '');
            setHasDomains({
                alternateDomain1: Boolean(alternateDomainProp),
                alternateDomain2: Boolean(alternateDomainProp2),
            });
            setCompanyType(props.companyInfo.companyType);
            setContact(props.companyInfo?.contact ?? '');
            setEmail(props.companyInfo?.email ?? '');
            setCity(props.companyInfo.address?.city ?? '');
            setState(props.companyInfo.address?.state ?? '');
            setSelectedCountry(props.companyInfo.address?.countryCode ?? '')
            setAccessLevel(props.companyInfo.accessLevel);
            setZipcode(props.companyInfo.address?.zipcode);
            setAddress({
                line1: props.companyInfo.address?.addr1,
                line2: props.companyInfo.address?.addr2,
            });
            setCreditLimit(props.companyInfo.creditLimit);
            setUpliftPercent(props.companyInfo.upliftPercentage * 100);
            setShowCompanyProductsConfig(true);
            setAdminUsername(props.companyInfo.adminUsername);
            setAdminPassword(props.companyInfo.adminPassword);
            setAdminUserId(props.companyInfo.adminUserId);
            setAdminDeleted(props.companyInfo.isAdminDeleted);
            setCompanyHasPurchase(false);
            setDeleteAdminManual(false);
        }
    }, [props.companyInfo]);

    useEffect(() => {
        if (props.companyInfo?.nsCompanyId) {
            setNsCompanyId(props.companyInfo?.nsCompanyId);
        }
    }, [props.companyInfo?.nsCompanyId]);

    const isSubmitEnabled = () => {
        return (
            companyName !== '' &&
            companyDomain !== '' &&
            isCompanyDomainValid &&
            isAlternateDomain1Valid &&
            isAlternateDomain2Valid &&
            companyType?.value &&
            newAccountApiState !== API_STATES.loading &&
            createOrder !== true &&
            address?.line1 &&
            isEmail(email) &&
            contact &&
            city &&
            state &&
            selectedCountry &&
            zipcode &&
            isValid &&
            zipcodeValid &&
            creditLimit &&
            creditLimitValid &&
            percentValid
        );
    };

    async function getCompanyTypeDetails() {
        setCompanyTypeApiState(API_STATES.loading);
        const response = await getAllCompanyTypes();
        switch (response.status) {
            case API_STATES.success:
                setCompanyTypeApiState(API_STATES.success);
                const data = response.data.map((row) => {
                    return {
                        label: row.display,
                        value: row.value,
                    };
                });
                setCompanyTypeList(data);
                break;
            case API_STATES.error:
                let error = response.error;
                handleApiError(error, setCompanyTypeApiState, setCompanyTypeApiError);
                break;
            default:
                setCompanyTypeApiError('');
                setCompanyTypeApiState(API_STATES.none);
        }
    }

    async function onCreateHandler() {
        setNewAccountApiState(API_STATES.loading);
        let alternateDomains = [];
        let createdDomain1 = false;
        let createdDomain2 = false;
        if (alternateDomain1 && isAlternateDomain1Valid) {
            alternateDomains.push(alternateDomain1);
            createdDomain1 = true;
        }
        if (alternateDomain2 && isAlternateDomain2Valid) {
            alternateDomains.push(alternateDomain2);
            createdDomain2 = true;
        }
        const response = await createNewCompany(
            companyName,
            companyType,
            companyDomain,
            alternateDomains,
            email,
            contact,
            Number(creditLimit),
            {
                street1: address.line1,
                street2: address.line2,
                city: city,
                state: state,
                countryCode: selectedCountry,
                zipcode: zipcode,
            },
            Number(upliftPercent),
            accessLevel?.value
        );
        switch (response.status) {
            case API_STATES.success:
                setHasDomains({
                    alternateDomain1: createdDomain1,
                    alternateDomain2: createdDomain2,
                });
                setNewAccountApiState(API_STATES.success);
                setCreateOrder(true);
                setCreateCompany(true);
                setNsCompanyId(response.data?.nsCompanyId);
                setTimeout(() => setNewAccountApiState(API_STATES.none), 3000);
                break;
            case API_STATES.error:
                let error = response.error;
                handleApiError(error, setNewAccountApiState, setNewAccountApiError);
                break;
            default:
                setNewAccountApiError('');
                setNewAccountApiState(API_STATES.none);
        }
    }

    async function onUpdateHandler() {
        setUpdateAccountApiState(API_STATES.loading);
        let addedDomains = [];
        let updatedDomain1 = false;
        let updatedDomain2 = false;
        if (!hasDomains?.alternateDomain1 && alternateDomain1 && isAlternateDomain1Valid) {
            addedDomains.push(alternateDomain1);
            updatedDomain1 = true;
        }
        if (!hasDomains?.alternateDomain2 && alternateDomain2 && isAlternateDomain2Valid) {
            addedDomains.push(alternateDomain2);
            updatedDomain2 = true;
        }
        const response = await updateCompany(
            props.companyInfo?.nsCompanyId ? props.companyInfo?.nsCompanyId : nsCompanyId,
            addedDomains,
            email,
            contact,
            Number(upliftPercent),
            {
                street1: address.line1,
                street2: address.line2,
                city: city,
                state: state,
                countryCode: selectedCountry,
                zipcode: zipcode,
            },
            String(accessLevel?.value)
        );
        switch (response.status) {
            case API_STATES.success:
                setHasDomains({
                    alternateDomain1: hasDomains?.alternateDomain1 || updatedDomain1,
                    alternateDomain2: hasDomains?.alternateDomain2 || updatedDomain2,
                });
                setUpdateAccountApiState(API_STATES.success);
                setTimeout(() => setUpdateAccountApiState(API_STATES.none), 3000);
                break;
            case API_STATES.error:
                let error = response.error;
                handleApiError(error, setUpdateAccountApiState, setUpdateAccountApiError);
                break;
            default:
                setUpdateAccountApiError('');
                setUpdateAccountApiState(API_STATES.none);
        }
    }

    console.log(`${selectedCountry}--${state}`)

    return (
        <>
            <h3 className={styles.screenHeading}>Step 1 of 5: Company Details</h3>
            <hr />

            <div className={styles.rowflex}>
                {/*First div section of company form*/}
                <div>
                    <TextInput
                        type="text"
                        label="Company Name:"
                        placeholder="Company Name"
                        value={companyName}
                        onInputChange={onCompanyNameChange}
                        disabled={props.companyInfo?.nsCompanyId || createOrder}
                        required={true}
                    />

                    <div className={styles.formItem}>
                        <TextInput
                            type="text"
                            name="companyDomain"
                            label="Company Domain:"
                            placeholder="Company Domain"
                            value={companyDomain}
                            onInputChange={onCompanyDomainChange}
                            disabled={props.companyInfo?.nsCompanyId || createOrder}
                            required={true}
                        />
                        {!isCompanyDomainValid && <span className={generalStyles.errorMessage}>Invalid Domain!</span>}
                    </div>

                    <div className={styles.formItem}>
                        <TextInput
                            type="text"
                            name="alternateDomain"
                            label="Alternate Domain 1:"
                            placeholder="Alternate Domain"
                            value={alternateDomain1}
                            onInputChange={onAlternateDomain1Change}
                            disabled={hasDomains?.alternateDomain1}
                        />
                        {!isAlternateDomain1Valid && (
                            <span className={generalStyles.errorMessage}>Invalid Domain!</span>
                        )}
                    </div>
                    <div className={styles.formItem}>
                        <TextInput
                            type="text"
                            name="alternateDomain2"
                            label="Alternate Domain 2:"
                            placeholder="Alternate Domain 2"
                            value={alternateDomain2}
                            onInputChange={onAlternateDomain2Change}
                            disabled={hasDomains?.alternateDomain2}
                        />
                        {!isAlternateDomain2Valid && (
                            <span className={generalStyles.errorMessage}>Invalid Domain!</span>
                        )}
                    </div>
                    {companyTypeApiState === API_STATES.loading && (
                        <label className={generalStyles.statusMessage}>Loading...</label>
                    )}
                    {companyTypeApiState === API_STATES.error && (
                        <label className={generalStyles.errorMessage}>{companyTypeApiError}</label>
                    )}
                    {companyTypeApiState === API_STATES.success && (
                        <div className={generalStyles['input-wrapper']}>
                            <label className={styles.required}>Company Type:</label>
                            <span className={styles.formField}>
                                <Select
                                    classNamePrefix={'react-select'}
                                    onChange={onCompanyTypeChange}
                                    options={companyTypeList}
                                    value={companyType}
                                    isDisabled={props.companyInfo?.nsCompanyId || createOrder}
                                />
                            </span>
                        </div>
                    )}

                    <div className={generalStyles['input-wrapper']}>
                        <label className={styles.required}>Access Level:</label>
                        <span className={styles.formField}>
                            <Select
                                classNamePrefix={'react-select'}
                                onChange={onAccessLevelChange}
                                options={AccessLevels.AccessLevels}
                                value={accessLevel}
                            />
                        </span>
                    </div>

                    <div className={styles.formItem}>
                        <TextInput
                            type="text"
                            label="Contact Number:"
                            placeholder="Contact Number"
                            value={contact}
                            onInputChange={(event) => setContact(event.target.value)}
                            required={true}
                        />
                        {!isValid && <span className={generalStyles.errorMessage}>Invalid phone Number!</span>}
                    </div>

                    <div className={styles.formItem}>
                        <TextInput
                            type="email"
                            label="Email:"
                            placeholder="Email"
                            value={email}
                            onInputChange={(event) => onEmailChange(event.target.value)}
                            required={true}
                        />
                        {email && !isEmail(email) && (
                            <span className={generalStyles.errorMessage}>Invalid email format!</span>
                        )}
                    </div>

                    <div className={styles.formItem}>
                        <TextInput
                            type="text"
                            label="Uplift %:"
                            placeholder="Uplift %"
                            value={upliftPercent}
                            onInputChange={(event) => setUpliftPercent(event.target.value)}
                        />
                        {!percentValid && <span className={generalStyles.errorMessage}>Invalid percent value!</span>}
                    </div>
                </div>

                {/*Second div section of company form*/}
                <div>
                    <TextInput
                        type="text"
                        label="Address 1:"
                        placeholder="Address 1"
                        value={address.line1}
                        onInputChange={(event) => onAddressChange('line1', event.target.value)}
                        required={true}
                    />

                    <TextInput
                        type="text"
                        label="Address 2:"
                        placeholder="Address 2"
                        value={address.line2}
                        onInputChange={(event) => onAddressChange('line2', event.target.value)}
                    />

                    <TextInput
                        type="text"
                        label="City:"
                        placeholder="City"
                        value={city}
                        onInputChange={(event) => setCity(event.target.value)}
                        required={true}
                    />

                    <div className={generalStyles['input-wrapper']}>
                        <label className={styles.required}>Country:</label>
                        <span className={styles.formField}>
                            <CountryDropdown
                                defaultOptionLabel=""
                                valueType="short"
                                classes={`${generalStyles.input} ${styles.countryStateDropdown}`}
                                value={selectedCountry}
                                onChange={(val) => setSelectedCountry(val)} />
                        </span>
                    </div>
                    <div className={generalStyles['input-wrapper']}>
                        <label className={styles.required}>State/Territory:</label>
                        <span className={styles.formField}>
                            <RegionDropdown
                                countryValueType="short"
                                defaultOptionLabel=''
                                valueType="short"
                                classes={`${generalStyles.input} ${styles.countryStateDropdown}`}
                                country={selectedCountry}
                                value={state}
                                onChange={(val) => setState(val)} />
                        </span>
                    </div>
                    <div className={styles.formItem}>
                        <TextInput
                            type="text"
                            label="Zip Code:"
                            placeholder="Zip Code"
                            value={zipcode}
                            onInputChange={(event) => setZipcode(event.target.value)}
                            required={true}
                        />
                        {!zipcodeValid && <span className={generalStyles.errorMessage}>Invalid zipcode!</span>}
                    </div>

                    <div className={styles.formItem}>
                        <TextInput
                            type="text"
                            label="Credit Limit:"
                            placeholder="Credit Limit"
                            value={creditLimit}
                            onInputChange={(event) => setCreditLimit(event.target.value)}
                            required={true}
                            //disabled={props.companyInfo?.nsCompanyId || createOrder}
                            disabled={true}
                        />
                        {!creditLimitValid && <span className={generalStyles.errorMessage}>Invalid creditLimit!</span>}
                    </div>
                    <br />
                    <button
                        className={generalStyles.submitButton}
                        onClick={onCreateHandler}
                        disabled={!isSubmitEnabled() || props.companyInfo?.nsCompanyId}
                    >
                        Create Company
                    </button>
                    <span>
                        {newAccountApiState === API_STATES.error && (
                            <label className={generalStyles.errorMessage}>{newAccountApiError}</label>
                        )}
                        {newAccountApiState === API_STATES.loading && (
                            <label className={generalStyles.statusMessage}>
                                Please wait as this might take a little while...
                            </label>
                        )}
                        {newAccountApiState === API_STATES.success && (
                            <>
                                <label className={generalStyles.successMessage}>
                                    Successfully created new account.
                                </label>
                            </>
                        )}
                    </span>
                    <button
                        className={generalStyles.submitButton}
                        onClick={onUpdateHandler}
                        disabled={isUpdateDisabled || (!props.companyInfo?.nsCompanyId && !createOrder)}
                    >
                        Update Company
                    </button>
                    <span>
                        {updateAccountApiState === API_STATES.error && (
                            <label className={generalStyles.errorMessage}>{updateAccountApiError}</label>
                        )}
                        {updateAccountApiState === API_STATES.loading && (
                            <label className={generalStyles.statusMessage}>
                                Please wait as this might take a little while...
                            </label>
                        )}
                        {updateAccountApiState === API_STATES.success && (
                            <>
                                <label className={generalStyles.successMessage}>Successfully Updated!</label>
                            </>
                        )}
                    </span>
                </div>
            </div>
            {(props.companyInfo?.nsCompanyId || createCompany) && (
                <>
                    <AdminCredentials
                        adminUsername={adminUsername}
                        adminPassword={adminPassword}
                        adminUserId={adminUserId}
                        adminCreated={onInternalAdminCreated}
                        companyName={companyName}
                        nsCompanyId={nsCompanyId}
                        adminDeleted={adminDeleted}
                        onDeleteAdminManual={() => setDeleteAdminManual(true)}
                    />
                    <h3 className={styles.screenHeading}>Step 3 of 5: Place Order</h3>
                    <hr />
                    <CompanyPurchasesReport
                        nsCompanyId={nsCompanyId}
                        refreshTimestamp={purchasesAndProductsRefreshParams.refreshTimestamp}
                        refreshDelay={purchasesAndProductsRefreshParams.refreshDelay}
                        someCompanyPurchase={() => setCompanyHasPurchase(true)}
                        noCompanyPurchase={() => setCompanyHasPurchase(false)}
                        isEditable={isSuperUser}
                    />
                    <br />
                    <PlaceOrder
                        nsCompanyId={nsCompanyId}
                        updatedPurchases={onPurchasesUpdated}
                        companyInfo={props.companyInfo}
                        resetCreateOrder={() => setCreateCompany(false)}
                        companyHasPurchase={companyHasPurchase}
                    />
                    <br />
                    {showCompanyProductsConfig && (
                        <>
                            <h3 className={styles.screenHeading}>Step 4 of 5: Configure Products</h3>
                            <hr />
                            <ConfigureProducts
                                nsCompanyId={nsCompanyId}
                                refreshTimestamp={purchasesAndProductsRefreshParams.refreshTimestamp}
                                refreshDelay={purchasesAndProductsRefreshParams.refreshDelay}
                            />
                            <h3 className={styles.screenHeading}>Step 5 of 5: Invite Users</h3>
                            <hr />
                            <AddUsers
                                nsCompanyId={nsCompanyId}
                                adminCreated={newAdminAdded}
                                deleteAdminManual={deleteAdminManual}
                            />
                        </>
                    )}
                </>
            )}
        </>
    );
}
export default CompanyDetails;
