import { logout } from '@/services/account.service';
// import store from '@/store';
import { Fragment } from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useClickOutsideHandler } from '../utils/utils';
import './DashboardHeader.scss';
import { HeaderNav } from './HeaderNav';
import store from '../../store';

import gearIcon from '@images/icons/gear-green.svg';
import personIcon from '@images/icons/person-black.svg';
import peopleIcon from '@images/icons/people-black.svg';
import wrenchIcon from '@images/icons/wrench-black.svg';
import cloudGearIcon from '@images/icons/cloud-gear-black.svg';
import keyIcon from '@images/icons/key-black.svg';
import logoutIcon from '@images/icons/logout-black.svg';
import CSR_ROLES from '@/constants/CsrRoles';
import SearchBar from '@/components/Dashboard/SearchBar';

export function DashboardHeader({ disabled = false, ...props }) {
    const user = store?.getState()?.account?.user;
    const [showSettingsDropDown, setShowSettingsDropDown] = useState(false);
    const compRef = useRef();
    const expandableMenuTypes = {
        'my-items': false,
        setting: false,
        help: false,
    };
    const [expanded, setExpanded] = useState(expandableMenuTypes);

    const history = useHistory();

    const allMenuItems = [
        {
            topHeaderItem: true,
            type: 'settings',
            iconSrc: gearIcon,
            subItems: [
                {
                    type: 'topnav-user-info',
                    title: `${user?.email}`,
                    content: user ? (
                        <strong>
                            Hey, {user.firstName} {user.lastName}
                        </strong>
                    ) : (
                        ''
                    ),
                },
                {
                    type: 'topnav-user-type',
                    title: `${user?.csrUserRoles?.join(',')}`,
                    content: 'Byzzer CSR',
                    hasHr: true,
                },
                {
                    url: '/',
                    type: 'topnav-csr-account',
                    content: 'CSR Account',
                    iconSrc: peopleIcon,
                    disabled: disabled,
                    accessibleBy: [CSR_ROLES.superUser],
                    onClick(type) {
                        onHeaderNavClick(type);
                        history.push('/modifycsr');
                    },
                },
                {
                    url: '/',
                    type: 'topnav-create-csr',
                    content: 'Create new CSR',
                    iconSrc: personIcon,
                    disabled: disabled,
                    accessibleBy: [CSR_ROLES.superUser],
                    onClick(type) {
                        onHeaderNavClick(type);
                        history.push('/createnewcsr');
                    },
                },
                {
                    url: '/',
                    type: 'topnav-maintenance',
                    content: 'Maintenance',
                    iconSrc: wrenchIcon,
                    disabled: disabled,
                    accessibleBy: [CSR_ROLES.superUser],
                    onClick(type) {
                        onHeaderNavClick(type);
                        history.push('/maintenance');
                    },
                },
                {
                    url: '/',
                    type: 'topnav-data-maintenance',
                    content: 'Data Maintenance',
                    iconSrc: cloudGearIcon,
                    disabled: disabled,
                    accessibleBy: [CSR_ROLES.superUser],
                    onClick(type) {
                        onHeaderNavClick(type);
                        history.push('/datamaintenanceschedules');
                    },
                    hasHr: true,
                },
                {
                    url: '/',
                    type: 'topnav-change-pwd',
                    content: 'Change Password',
                    iconSrc: keyIcon,
                    onClick(type) {
                        onHeaderNavClick(type);
                        history.push('/changepassword');
                    },
                },
                {
                    onClick: async (type) => {
                        await logout();
                        history.push(`/signin`);
                        onHeaderNavClick(type);
                    },
                    type: 'topnav-logout',
                    content: 'Logout',
                    iconSrc: logoutIcon,
                },
            ],
        },
    ];

    const onHeaderNavClick = (type) => {
        let duplicateExpandableTypes = JSON.parse(JSON.stringify(expandableMenuTypes));
        duplicateExpandableTypes[type] = !expanded[type];
        setExpanded(duplicateExpandableTypes);
    };

    useClickOutsideHandler(compRef, () => {
        setExpanded(expandableMenuTypes);
    });
    return (
        <>
            <div className="dashboard-header" ref={compRef}>
                <div className="dashboard-header__search-bar">
                    <SearchBar />
                </div>
                <div className="dashboard-header__content">
                    {allMenuItems.map(({ topHeaderItem, ...props }, index) => {
                        if (!topHeaderItem) {
                            return <Fragment key={index} />;
                        }

                        return (
                            <HeaderNav
                                key={`${props?.content} ${showSettingsDropDown}`}
                                {...props}
                                isHeader={true}
                                toggleParentMenu={() => setShowSettingsDropDown(false)}
                                expanded={expanded[props.type]}
                                onToggleClick={(type) => onHeaderNavClick(type)}
                            />
                        );
                    })}
                </div>
            </div>
        </>
    );
}

export default DashboardHeader;
