import axios from 'axios';
import firebase from 'firebase/app';
import 'firebase/auth';
import API_STATES from '@/constants/StateConstants';

const http = axios.create({
    baseURL: import.meta.env.REACT_APP_BASEURL,
});

/*
Watches auth token state changes to keep the request header in sync
*/

http.interceptors.request.use(async (config) => {
    if (firebase.auth().currentUser) {
        config.headers.Authorization = `Bearer ${await firebase.auth().currentUser.getIdToken()}`;
    }
    return config;
});

export async function getMaintenanceModeStatus() {
    try {
        const response = await http.get(`/maintenance`);
        return { status: API_STATES.success, data: {status: response.data?.maintenanceModeStatus, eta: response.data?.eta} };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function setMaintenanceStatus(maintenanceStatus) {
    try {
        await http.put(`/maintenance`, { maintenanceStatus });
        return { status: API_STATES.success };
    } catch (error) {
        if (error.response?.data?.message) {
            return { status: API_STATES.error, error: error.response.data.message };
        }
        return { status: API_STATES.error, error: error.toString() };
    }
}

export async function setMaintenanceETA(eta) {
    try {
        await http.put(`/maintenance/eta`, { eta });
        return { status: API_STATES.success };
    } catch (error) {
        if (error.response?.data?.message) {
            return { status: API_STATES.error, error: error.response.data.message };
        }
        return { status: API_STATES.error, error: error.toString() };
    }
}
