import React, { useEffect, useState, useRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import calendarIcon from '@images/icons/calendar.svg';
import moment from 'moment';
import styles from '../HomepageNotification.module.scss';
import generalStyles from '@/components/General/Styles.module.scss';
import Select from 'react-select';
import * as Constant from '../Constants';
import API_STATES from '@/constants/StateConstants';
import {
    getSubscriptionTypes
} from '@/services/api/product.service';
import * as MessageService from '@/services/api/subscriptionBasedNotification.service';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import ByzzerDateRange from '@/components/ByzzerDateRange';
import { format } from 'date-fns';
import { getMessageTypeValue } from '../Utils';
import { handleApiError } from '@/components/General/HandleApiError';
import { toOption } from '@/components/utils/utils';

function CategoryBasedNotificationForm({
    refresh,
    selectedNotification,
    edit = false,
    editSingleNotification = false,
    closeEdit,
}) {
    const [endDate, setEndDate] = useState(edit ? moment(new Date(selectedNotification.endDate)) : null);
    const [selectedNotificationType, setSelectedNotificationType] = useState(
        edit ? getMessageTypeValue(selectedNotification.notificationType) : []
    );
    const [notificationMessage, setNotificationMessage] = useState(edit ? selectedNotification.message : '');
    const [notificationHeader, setNotificationHeader] = useState(edit ? selectedNotification.header : '');
    const [notificationLink, setNotificationLink] = useState(edit ? selectedNotification.hyperLink : '');
    const [createApiState, setCreateApiState] = useState(API_STATES.none);
    const [createApiError, setCreateApiError] = useState('');
    const [openModal, setOpenModal] = useState(false);

    const [subscriptionTypeApiState, setSubscriptionTypeApiState] = useState(API_STATES.none);
    const [subscriptionTypeApiError, setSubscriptionTypeApiError] = useState('');
    const [subscriptionTypes, setSubscriptionTypes] = useState([]);
    const [selectedSubscriptionTypes, setSelectedSubscriptionTypes] = useState([]);

    const [userCount, setUserCount] = useState(0);


    const onDateChange = (endDateValue) => {
        if (endDateValue && endDateValue !== '') setEndDate(endDateValue);
        else setEndDate(null);
    };

    const clearSelections = () => {
        setSelectedSubscriptionTypes([]);
        setEndDate(null);
        setSelectedNotificationType([]);
        setNotificationHeader('');
        setNotificationLink('');
        setNotificationMessage('');
        setUserCount(0);
    };

    async function createRequestBody() {
        const expirationDate = endDate ? moment(endDate).endOf('day').format('YYYY/MM/DD HH:mm:ss') : null;
        const messageType =
            selectedNotificationType?.value ??
            selectedNotificationType?.[0]?.value ??
            selectedNotification?.notificationType ??
            Constant.TYPE_OF_NOTIFICATION[1].value;

        let requestBody = {
            expirationDtm: expirationDate,
            messageType: messageType,
            triggerType: Constant.CUSTOM,
            priority: Constant.PRIORITY.LOW,
            messageCreationType: Constant.MESSAGE_TYPE.SUBSCRIPTION_BASED,
            subscriptionMessage : {
                subscriptionTypes: selectedSubscriptionTypes?.map((item)=>item?.value),
                subscriptionTriggerType: "subscription",
                startDtm: null,
                endDtm: null
            },
            notification: { message: notificationMessage, header: notificationHeader, hyperLink: notificationLink }
        }

        if (edit && !editSingleNotification) {
            requestBody.notificationId = selectedNotification?.notificationId;
            requestBody.csrNotificationId = selectedNotification?.csrNotificationId;
            requestBody.recordType = "edit"

        } else if (edit && editSingleNotification) {
            requestBody.csrNotificationId = selectedNotification?.csrNotificationId
            requestBody.notificationId = selectedNotification.notificationId;
            requestBody.id = selectedNotification.id,
            requestBody.recepientId = selectedNotification.recepientId;
            requestBody.companyId = selectedNotification?.companyId;
            requestBody.recordType = "edit"
        }

        return requestBody;
    }

    const sendNotification = async () => {
        try {
            setOpenModal(false);

            setCreateApiState(API_STATES.loading);
            //creating request body based on create or edit option
            let requestBody = await createRequestBody();
            let response;

            if (edit && editSingleNotification) {
                    response = await MessageService.editSingleUserMessage(requestBody);
            } else if (edit) {
                response = await MessageService.editAllMessages(requestBody);
            } else {
                response = await MessageService.createSubscriptionBasedMessage(requestBody);
            }
            clearSelections();

            if (response.status === API_STATES.success) {
                setCreateApiState(API_STATES.success);
                refresh(true);
                if (edit) {
                    closeEdit();
                }
            } else {
                setCreateApiError(response.error);
                setCreateApiState(API_STATES.error);
            }
        } catch (e) {
            setCreateApiError(e);
            setCreateApiState(API_STATES.error);
        }
    };

    const displayPreview = async () => {
        let requestBody = await createRequestBody();
        let response = await MessageService.getUserCount(requestBody);
        if (response.status === API_STATES.success) {
            setUserCount(response.data);
            setOpenModal(true);
        }
    };
    const validateSelection = () => {
        return !(selectedSubscriptionTypes?.length !== 0 &&
            selectedNotificationType?.length !== 0 &&
            notificationHeader &&
            notificationHeader?.trim() !== '' &&
            notificationMessage &&
            notificationMessage?.trim() !== '' &&
            endDate)
    };

    const buttonLabel = useState(edit ? 'Edit' : 'Submit');

    const handleSubscriptionTypeSelect = (selectedSubscriptionType) => {
        if (selectedSubscriptionType.some((subscriptionType) => subscriptionType.value === 'All')) {
            setSelectedSubscriptionTypes(subscriptionTypes);
        } else {
            setSelectedSubscriptionTypes(selectedSubscriptionType);
        }
    };

    if (subscriptionTypes?.length === 0 && subscriptionTypeApiState === API_STATES.none) {
        loadSubscriptionTypes();
    }

    async function loadSubscriptionTypes() {
        setSubscriptionTypeApiState(API_STATES.loading);
        const response = await getSubscriptionTypes();
        if (response !== null) {
            if (response.status === API_STATES.success) {
                response.data = response.data ?? [];
                const subscriptionTypes = response.data.map((subscriptionType) => toOption(subscriptionType));
                setSubscriptionTypes(subscriptionTypes);
                setSubscriptionTypeApiState(API_STATES.success);

                if (edit) {
                    const selectedList = subscriptionTypes?.filter((item) => selectedNotification.subscriptionList?.includes(item.value));
                    setSelectedSubscriptionTypes(selectedList)
                }
            } else {
                setSubscriptionTypes([]);
                handleApiError(response.error, setSubscriptionTypeApiState, setSubscriptionTypeApiError);
            }
        }
    }

    return (
        <>
            {!edit && (
                <>
                    {' '}
                    <h1>Subscription Based Notification</h1>
                    <hr />
                </>
            )}

            {edit && (
                <>
                    {' '}
                    <h1>Edit Subscription Based Notification</h1>
                    <hr />
                    <br />
                </>
            )}
            {subscriptionTypeApiState === API_STATES.loading && (
                <p className={generalStyles.statusMessage}>Loading subscription types...</p>
            )}
            {subscriptionTypeApiState === API_STATES.error && (
                <p className={generalStyles.errorMessage}>{subscriptionTypeApiError.toString()}</p>
            )}
            {subscriptionTypes?.length > 0 && (
            <>
                <div className={styles.selectionContainer}>
                    <div className={styles.selectInputWrapper}>
                        <label className={styles.formFieldLabel}>Select Subscription Types:</label>
                            <span className={styles.formField}>
                                <Select
                                    classNamePrefix={'react-select'}
                                    options={Constant.FIRST_OPTION.concat(subscriptionTypes)}
                                    closeMenuOnSelect={false}
                                    isMulti
                                    onChange={(selectSubscriptionType)=>handleSubscriptionTypeSelect(selectSubscriptionType)}
                                    value={selectedSubscriptionTypes}
                                    styles={Constant.SELECT_STYLES}
                                    placeholder="Please select Subscription Types"
                                />
                            </span>
                    </div>
                </div>

                <div className={styles.selectionContainer}>
                    {/* Message expiration date selection */}
                    <div className={styles.selectInputWrapper}>
                        <label className={styles.formLabel}>Select Expiration Date:</label>

                        <div className={styles.datepickerContainer}>
                            <DatePicker
                                name="endDate"
                                placeholderText="End Date"
                                shouldCloseOnSelect={true}
                                className={styles.datepickerInput}
                                fixedHeight
                                closeOnScroll={true}
                                dateFormat="MMM d, y"
                                selected={endDate ? endDate.toDate() : null}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                tabIndex={1}
                                onChange={(date) => {
                                    onDateChange(date ? moment(date) : null);
                                }}
                                minDate={moment().toDate()}
                                onKeyDown={(e) => {
                                    e.preventDefault();
                                }}
                            />
                            <img src={calendarIcon} alt="calendar icon" />
                        </div>
                    </div>
                    {/* Notification type selection */}
                    <div className={styles.selectInputWrapper}>
                        <label className={styles.formLabel}>Notification Type:</label>
                        <span className={styles.formField}>
                            <Select
                                classNamePrefix={'react-select'}
                                options={Constant.TYPE_OF_NOTIFICATION}
                                onChange={(selectedValue) => setSelectedNotificationType(selectedValue)}
                                value={selectedNotificationType}
                                placeholder={'Notification Type'}
                            />
                        </span>
                    </div>

                    {/* input for message header/title */}
                    <div className={styles.selectInputWrapper}>
                        <label className={styles.formLabel}>Notification Header:</label>
                        <span className={styles.formField}>
                            <textarea
                                onChange={(e) => {
                                    setNotificationHeader(e.target.value);
                                }}
                                value={notificationHeader}
                                className={generalStyles.textareaInput}
                                placeholder={'Notification Header'}
                            />
                        </span>
                    </div>
                </div>

                <br />

                <div className={styles.selectionContainer}>
                    {/* input for message text */}
                    <div className={styles.selectInputWrapper}>
                        <label className={styles.formLabel}>Message:</label>
                        <span className={styles.formField}>
                            <textarea
                                onChange={(e) => {
                                    setNotificationMessage(e.target.value);
                                }}
                                value={notificationMessage}
                                className={generalStyles.textareaInput}
                                placeholder={'Notification Message'}
                            />
                        </span>
                    </div>
                    {/* input for adding URL for performing any action on the message */}
                    <div className={styles.selectInputWrapper}>
                        <label className={styles.formLabel}>Action:</label>
                        <span className={styles.formField}>
                            <textarea
                                onChange={(e) => {
                                    setNotificationLink(e.target.value);
                                }}
                                value={notificationLink}
                                className={generalStyles.textareaInput}
                                placeholder={'Link (Optional) - Link for redirection'}
                            />
                        </span>
                    </div>

                    {/* button to submit all form selections to generate the message and send notification to users */}
                    <div>
                        <button
                            className={`${generalStyles.submitButton}`}
                            name={'submit'}
                        onClick={displayPreview}
                        disabled={validateSelection()}
                        >
                            Submit
                        </button>
                        <div>
                            {createApiState === API_STATES.loading && (
                                <span className={generalStyles.statusMessage}>
                                    Triggering Category Based Notification...
                                </span>
                            )}
                            {createApiState === API_STATES.error && (
                                <span className={generalStyles.errorMessage}>Failed: {createApiError.toString()}</span>
                            )}
                        </div>
                    </div>
                </div>

                <Popup
                    open={openModal}
                    onClose={() => {
                        setOpenModal(false);
                    }}
                    modal
                >
                    {() => (
                        <div className={styles.warningDialog}>
                            <h2>Preview</h2>
                            <div className={styles.messageContainer}>
                                <div>
                                    {' '}
                                    <b>{notificationHeader}</b>: {notificationMessage}
                                </div>
                                <br></br>

                                {notificationLink !== undefined &&
                                    notificationLink !== null &&
                                    notificationLink.trim() !== '' && (
                                        <>
                                            <div>
                                                <b>Link</b>: {notificationLink}
                                            </div>
                                            <br></br>
                                        </>
                                    )}

                                {!edit && (
                                    <div>
                                        <b>Note</b>: This message will be sent to {userCount} accounts
                                    </div>
                                )}
                                {edit && !editSingleNotification && (
                                    <div>
                                        <b>Note</b>: This Message will be modified for all notified companies.
                                    </div>
                                )}
                                {edit && editSingleNotification && (
                                    <div>
                                        <b>Note</b>: This Message will be modified for selected company.
                                    </div>
                                )}
                            </div>

                            <div>
                                <button
                                    className={`${generalStyles.cancelOperationButton} ${styles.btnWidth}`}
                                    onClick={() => {
                                        sendNotification();
                                    }}
                                >
                                    {buttonLabel}
                                </button>

                                <button
                                    className={`${generalStyles.confirmOperationButton} ${styles.btnWidth}`}
                                    onClick={() => {
                                        setOpenModal(false);
                                    }}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    )}
                </Popup>
            </>
            )}
        </>
    );
}

export default CategoryBasedNotificationForm;
