import React, { useEffect, useState } from 'react';
import generalStyles from '@/components/General/Styles.module.scss';
import PasswordInput from '@/components/General/PasswordInput';
import TextInput from '@/components/General/TextInput';
import { validatePassword } from '@/services/PasswordValidation.service';
import * as API from '@/services/api/csrdata.service';
import API_STATES from '@/constants/StateConstants';
import CheckBoxList from '@/components/General/CheckBoxList';
import styles from '@/components/CreateNewCSR/CreateNewCSR.module.scss';
import { handleApiError } from '@/components/General/HandleApiError';
import isEmail from 'is-email';
import ByzzerLink from '../General/ByzzerLink';
import { useHistory } from 'react-router-dom';

function CreateNewCSR() {
    //states of the component
    const [name, setName] = useState({
        firstName: '',
        lastName: '',
    });   
    const history = useHistory();
    const [email, setEmail] = useState('');
    const [passwordValue, setPasswordValue] = useState({
        newPassword: '',
        confirmPassword: '',
    });
    const [passwordErrorList, setPasswordErrorList] = useState({
        newPassword: [],
        confirmPassword: [],
    });
    const [errorStatus, setErrorStatus] = useState('');
    const [createCsrUserApiStatus, setCreateCsrUserApiStatus] = useState(API_STATES.none);

    const [checkBoxList, setCheckBoxList] = useState([]);
    const [csrRoles, setCsrRoles] = useState([]);

    const [csrRolesApiStatus, setCsrRolesApiStatus] = useState(API_STATES.none);
    const [csrRolesApiError, setCsrRolesApiError] = useState('');

    //onChange Handlers for state values
    const onNameChange = (nameType, value) => {
        setCreateCsrUserApiStatus(API_STATES.none);
        setErrorStatus('');
        setName({
            ...name,
            [nameType]: value,
        });
    };

    const onEmailChange = (e) => {
        setCreateCsrUserApiStatus(API_STATES.none);
        setErrorStatus('');
        setEmail(e.target.value);
    };

    const onPasswordValueChange = (passwordName, value) => {
        setCreateCsrUserApiStatus(API_STATES.none);
        setErrorStatus('');
        setPasswordValue({
            ...passwordValue,
            [passwordName]: value,
        });
    };

    const onCheckBoxChange = (checkBoxItem, value) => {
        checkBoxList.forEach((checkListItem) => {
            if (checkListItem === checkBoxItem) {
                checkListItem.isChecked = value;
            }
        });
        setCheckBoxList([...checkBoxList]);

        let selectedRoles = [];
        checkBoxList.forEach((checkListItem) => {
            if (checkListItem.isChecked) {
                selectedRoles.push(checkListItem.label);
            }
        });
        setCsrRoles(selectedRoles);
    };

    const findPasswordErrors = () => {
        const { newPassword, confirmPassword } = passwordValue;

        let newPasswordErrorList = [];
        if (newPassword !== '') {
            newPasswordErrorList = validatePassword(newPassword, email);
        }

        let confirmPasswordErrorList = [];
        if (newPassword !== confirmPassword) {
            confirmPasswordErrorList.push('Must be the same as your new password.');
        }
        setPasswordErrorList({
            newPassword: newPasswordErrorList,
            confirmPassword: confirmPasswordErrorList,
        });
    };

    const clearForm = () => {
        setName({
            firstName: '',
            lastName: '',
        });
        setEmail('');
        setPasswordValue({
            newPassword: '',
            confirmPassword: '',
        });
        checkBoxList.forEach((listItem) => {
            listItem.isChecked = false;
        });
        setCheckBoxList([...checkBoxList]);
    };

    async function onSubmitHandler() {
        setCreateCsrUserApiStatus(API_STATES.loading);
        const response = await API.createCSR(
            name.firstName,
            name.lastName,
            email?.toLowerCase(),
            passwordValue.newPassword,
            csrRoles
        );
        switch (response.status) {
            case API_STATES.success:
                setCreateCsrUserApiStatus(API_STATES.success);
                clearForm();
                setTimeout(() => setCreateCsrUserApiStatus(API_STATES.none), 2000);
                setCsrRoles([]);
                break;
            case API_STATES.error:
                setCreateCsrUserApiStatus(API_STATES.error);
                if (response?.error?.response?.status === 409) {
                    setErrorStatus('A CSR with this email already exists.');
                } else {
                    setErrorStatus(response.error?.message || 'Unknown error creating CSR.');
                }
                break;
            default:
                setErrorStatus('');
                setCreateCsrUserApiStatus(API_STATES.none);
        }
    }

    async function getAllRoles() {
        setCsrRolesApiStatus(API_STATES.loading);
        const result = await API.getAllCsrRoles();
        switch (result.status) {
            case API_STATES.success:
                setCsrRolesApiStatus(API_STATES.success);
                const roles = result.data;
                const roleCheckList = roles.map((role) => {
                    return {
                        id: role.id,
                        label: role.role,
                        isChecked: false,
                    };
                });
                setCheckBoxList(roleCheckList);
                break;
            case API_STATES.error:
                let error = result.error;
                handleApiError(error, setCsrRolesApiStatus, setCsrRolesApiError);
                break;
            default:
                setCsrRolesApiError('');
                setCsrRolesApiStatus(API_STATES.none);
        }
    }

    const isSubmitEnabled = () => {
        return (
            name.firstName !== '' &&
            name.lastName !== '' &&
            email !== '' &&
            passwordValue.newPassword !== '' &&
            passwordErrorList.newPassword.length === 0 &&
            passwordValue.confirmPassword === passwordValue.newPassword &&
            // Don't want to disable on error, or they can't just type something new in an input and re-try
            // errorStatus === '' &&
            createCsrUserApiStatus !== API_STATES.loading &&
            csrRoles.length !== 0
        );
    };

    useEffect(() => {
        getAllRoles();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => findPasswordErrors(), [passwordValue]);

    return (
        <>
            <h1>Create New CSR</h1>
            <hr />
            <ByzzerLink label="Back" onClick={() => history.goBack()} />

            <TextInput
                type="text"
                label="First Name:"
                placeholder="First Name"
                value={name.firstName}
                onInputChange={(event) => onNameChange('firstName', event.target.value)}
                disabled={createCsrUserApiStatus === API_STATES.loading}
            />

            <TextInput
                type="text"
                label="Last Name:"
                placeholder="Last Name"
                value={name.lastName}
                onInputChange={(event) => onNameChange('lastName', event.target.value)}
                disabled={createCsrUserApiStatus === API_STATES.loading}
            />
            <div className={styles.formItem}>
                <TextInput
                    type="email"
                    label="Email:"
                    placeholder="Email"
                    value={email}
                    onInputChange={onEmailChange}
                    disabled={createCsrUserApiStatus === API_STATES.loading}
                />
                {email && !isEmail(email) && <span className={generalStyles.errorMessage}>Invalid email format</span>}
            </div>
            <PasswordInput
                label="Initial Password:"
                placeholder="Password"
                value={passwordValue.newPassword}
                onPasswordChange={(event) => onPasswordValueChange('newPassword', event.target.value)}
                errorList={passwordErrorList.newPassword}
                disabled={createCsrUserApiStatus === API_STATES.loading}
            />

            <PasswordInput
                label="Confirm Password:"
                placeholder="Confirm Password"
                value={passwordValue.confirmPassword}
                onPasswordChange={(event) => onPasswordValueChange('confirmPassword', event.target.value)}
                errorList={passwordErrorList.confirmPassword}
                disabled={createCsrUserApiStatus === API_STATES.loading}
            />
            <br />
            <div className={styles.select_roles_wrapper}>
                <div>Select Role(s):</div>
                <div>
                    <label>
                        {csrRolesApiStatus === API_STATES.loading ? (
                            <span className={generalStyles.statusMessage}>Fetching roles...</span>
                        ) : csrRolesApiStatus === API_STATES.error ? (
                            <span className={generalStyles.errorMessage}>Error: {csrRolesApiError}</span>
                        ) : (
                            csrRolesApiStatus === API_STATES.success && (
                                <>
                                    <CheckBoxList checkBoxList={checkBoxList} checkBoxOnChange={onCheckBoxChange} />
                                    <div>
                                        {csrRoles.length === 0 && (
                                            <label className={generalStyles.statusMessage}>
                                                At least one role needs to be selected to create a CSR
                                            </label>
                                        )}
                                    </div>
                                </>
                            )
                        )}
                    </label>
                </div>
            </div>

            <button className={generalStyles.submitButton} disabled={!isSubmitEnabled()} onClick={onSubmitHandler}>
                Create CSR
            </button>
            <span data-test="create-csr-status">
                {createCsrUserApiStatus === API_STATES.success && (
                    <label className={generalStyles.successMessage}>Successfully created new CSR.</label>
                )}
                {createCsrUserApiStatus === API_STATES.loading && (
                    <label className={generalStyles.statusMessage}>Processing...</label>
                )}
                {createCsrUserApiStatus === API_STATES.error && (
                    <label className={generalStyles.errorMessage}>{errorStatus}</label>
                )}
            </span>
        </>
    );
}

export default CreateNewCSR;
