import React, { useState, useEffect, useMemo } from 'react';
import styles from '../AccountSetup/SetupNewCompany.module.scss';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import calendarIcon from '@images/icons/calendar.svg';
import moment from 'moment';
import BuySubscriptionAddOns from '@/components/CompanyConfig/BuySubscriptionAddOns';
import Cart from '@/components/CompanyConfig/Cart';
import BuyBundles from '@/components/CompanyConfig/BuyBundles';
import { calculateTermInMonths } from '../utils/utils.jsx'
import generalStyles from '@/components/General/Styles.module.scss'
import { billingFrequencyOptions } from '@/constants/globalVars';
import { getPaymentTermOptions } from '@/services/api/company.service';
import API_STATES from '@/constants/StateConstants';

function AddProducts(props) {
    const nsCompanyId = props.nsCompanyId;
    const [contractStartDate, setContractStartDate] = useState(moment());
    const [addonStartDate, setAddonStartDate] = useState(moment());
    const [contractEndDate, setContractEndDate] = useState(moment().add(1, 'y').subtract(1, 'd'));
    const [cartItems, setCartItems] = useState([]);
    const [editableItem, setEditableItem] = useState({});
    const [companyHasPurchase, setCompanyHasPurchase] = useState(props.companyHasPurchase);
    const [totalBill, setTotalBill] = useState(0);
    const [selectedBillingSchedule, setSelectedBillingSchedule] = useState(billingFrequencyOptions[0])
    const [selectedPaymentTermsOption, setSelectedPaymentTermsOption] = useState({label: 'Net 30', value: 2})
    const [paymentTermOptionsApiState, setPaymentTermOptionsApiState] = useState(API_STATES.none);
    const [paymentTermOptionsApiError, setPaymentTermOptionsApiError] = useState('');
    const [paymentTermOptions, setPaymentTermOptions] = useState([])

    const getCartItems= useMemo(()=>{
        const termValue = calculateTermInMonths(addonStartDate.toDate(), contractEndDate.toDate())
        let cartItemsArray = cartItems?.map((item)=>{
            return {
                ...item,
                sectionTotal: parseFloat(item?.quantity * Number(item?.monthlyPrice) * termValue).toFixed(2)
            }
        })
        return cartItemsArray
    }, [cartItems, addonStartDate])

    useEffect(() => {
        const total = getCartItems?.reduce((accumulator, object) => {
            return accumulator + Number(object.sectionTotal);
        }, 0);
        setTotalBill(parseFloat(total).toFixed(2));
    }, [getCartItems]);

    useEffect(() => {
        if (props.companyInfo?.nsCompanyId) {
            setCartItems([]);
            if (props.companyInfo?.contractStart !== null && props.companyInfo?.contractEnd !== null) {
                setContractStartDate(moment(props.companyInfo?.contractStart));
                setContractEndDate(moment(props.companyInfo?.contractEnd));
            } else {
                setContractStartDate(moment());
                setContractEndDate(moment().add(1, 'y').subtract(1, 'd'));
            }
        }
    }, [props.companyInfo]);

    useEffect(() => {
        setCompanyHasPurchase(props.companyHasPurchase);
    }, [props.companyHasPurchase]);
    
    useEffect(()=>{
        (async()=>{
            const response  = await getPaymentTermOptions()
            if (response.status === API_STATES.success) {
                setPaymentTermOptionsApiState(API_STATES.success)
                setPaymentTermOptions(Object.entries(response.data).map(([label, id])=>({ label: label, value: id})))
                setTimeout(() => setPaymentTermOptionsApiState(API_STATES.none), 1000);

            } else {
                handleApiError(response.error, setPaymentTermOptionsApiState, setPaymentTermOptionsApiError);
                setTimeout(() => setPaymentTermOptionsApiState(API_STATES.none), 6000)
            }
        })()
    }, [])
    
    function getMonthsDifference() {
        const today=new Date(new Date().setHours(0, 0, 0, 0));
        return calculateTermInMonths(addonStartDate.toDate(), contractEndDate.toDate());
    }

    return (
        <>
            <>
                <span>
                    <b>Contract End Date: </b>
                    {contractEndDate.format('MMM DD YYYY')}
                </span>
                <br />
                <span>
                    <b>Contract Months left : </b>
                    {getMonthsDifference()}
                </span>
                <div className={styles.expiryDateTimeForm}>
                            <b>Start Date: </b>
                            <div className={styles.date}>
                                <DatePicker
                                    name="contractStartDate"
                                    placeholderText="mm/dd/yyyy"
                                    shouldCloseOnSelect={true}
                                    fixedHeight
                                    closeOnScroll={true}
                                    maxDate={new Date(2025, 11, 31, 23, 59, 59)}
                                    minDate={new Date(2020, 0, 1, 0, 0, 0)}
                                    dateFormat="MMM d, y"
                                    selected={addonStartDate.toDate()}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    tabIndex={1}
                                    onChange={(date) => {
                                        setAddonStartDate(moment(date));
                                    }}
                                />

                                <img src={calendarIcon} alt="calendar icon" />
                            </div>
                        </div>
            </>
            <div className={generalStyles['input-wrapper']}>
                <label>Billing Schedule:</label>
                <span className={generalStyles.formField}>
                    <Select
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 4,
                            colors: {
                                ...theme.colors,
                                text: 'black',
                                primary25: '#cfd1d2',
                                primary: 'hsl(120, 100%, 47%)',
                                neutral0: 'white'
                            }
                        })}
                        onChange={(option)=>setSelectedBillingSchedule(option)}
                        options={billingFrequencyOptions}
                        value={selectedBillingSchedule}
                        isDisabled={false}
                    />
                </span>
            </div>
            {paymentTermOptionsApiState === API_STATES.loading ? (
                <p className={generalStyles.statusMessage}>loading payment terms options...</p> 
            ) : ( paymentTermOptionsApiState === API_STATES.error ? (
                    <p className={generalStyles.errorMessage}>{paymentTermOptionsApiError}</p>
                ) : (
                    <div className={generalStyles['input-wrapper']}>
                        <label>Payment Terms:</label>
                        <span className={generalStyles.formField}>
                            <Select
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 4,
                                    colors: {
                                        ...theme.colors,
                                        text: 'black',
                                        primary25: '#cfd1d2',
                                        primary: 'hsl(120, 100%, 47%)',
                                        neutral0: 'white'
                                    }
                                })}
                                onChange={(option)=>setSelectedPaymentTermsOption(option)}
                                options={paymentTermOptions}
                                value={selectedPaymentTermsOption}
                                isDisabled={false}
                            />
                        </span>
                    </div>
                )
            )}
            <div className={styles.row}>
                <div>
                    <h4>Purchase Items</h4>
                    <div className={styles.purchaseItems}>
                        <BuySubscriptionAddOns
                            addCartItem={
                                (val) => {
                                    setCartItems([...cartItems, val]);
                                }
                            }
                            items={cartItems}
                            contractStart={moment(
                                `${addonStartDate.format('YYYY-MM-DD')}`,
                                'YYYY-MM-DD hh:mm a'
                            ).toDate()}
                            contractEnd={moment(
                                `${contractEndDate.format('YYYY-MM-DD')}`,
                                'YYYY-MM-DD hh:mm a'
                            ).toDate()}
                            editableItem={editableItem?.type === 'Subscription' ? editableItem : null}
                        />
                        <br />
                        <hr />
                        <BuyBundles
                            addCartItem={(val) => {
                                setCartItems([...cartItems, val]);
                                console.log(cartItems);
                            }}
                            items={cartItems}
                            contractStart={moment(
                                `${addonStartDate.format('YYYY-MM-DD')}`,
                                'YYYY-MM-DD hh:mm a'
                            ).toDate()}
                            contractEnd={moment(
                                `${contractEndDate.format('YYYY-MM-DD')}`,
                                'YYYY-MM-DD hh:mm a'
                            ).toDate()}
                            editableItem={editableItem?.type === 'Bundle' ? editableItem : null}
                        />
                        <br />
                    </div>
                </div>
                <div>
                    <div>
                        <div className={styles.billHeading}>
                            <h4>Cart</h4>
                            <h4>Total Bill: ${totalBill}</h4>
                        </div>

                        <div className={styles.purchaseItems}>
                            <Cart
                                billingSchedule = {selectedBillingSchedule.value}
                                netsuitePaymentTerm = {selectedPaymentTermsOption.value}
                                setEditableItem={(item) => setEditableItem(item)}
                                items={getCartItems}
                                nsCompanyId={nsCompanyId}
                                resetCreateOrder={props.resetCreateOrder}
                                updatedPurchases={props.updatedPurchases}
                                addonStartDate = {moment( `${addonStartDate.format('YYYY-MM-DD')}`, 'YYYY-MM-DD hh:mm a' ).toDate()}
                                contractStart={moment(
                                    `${addonStartDate.format('YYYY-MM-DD')}`,
                                    'YYYY-MM-DD hh:mm a'
                                ).toDate()}
                                contractEnd={moment(
                                    `${contractEndDate.format('YYYY-MM-DD')}`,
                                    'YYYY-MM-DD hh:mm a'
                                ).toDate()}
                                isEvergreen={false}
                                dynamicsDealNum={''}
                                removeCartItem={(sku) => {
                                    setCartItems(cartItems.filter((item) => item.sku !== sku));
                                }}
                                companyHasPurchase={companyHasPurchase}
                                setRefreshTimestamp={props.setRefreshTimestamp}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default AddProducts;
