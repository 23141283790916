import generalStyles from '@/components/General/Styles.module.scss';
import { MultiSelectColumnFilter } from '../General/MultiSelectColumnFilter';
import { FILTER_TYPES } from '../General/DataTable';
import { DateRangeColumnFilter } from '../General/DateRangeColumnFilter';

export const COLUMNS = [
    {
        Header: 'Company Id',
        accessor: 'company_id',
        id: 'company_id',
        className: `${generalStyles.tableCell} ${generalStyles.numeric}`,
        disableFilters: true,
    },
    {
        Header: 'Company Name',
        accessor: 'company_name',
        id: 'company_name',
        className: `${generalStyles.tableCell} ${generalStyles.cap}`,
        Filter: MultiSelectColumnFilter,
        filter: FILTER_TYPES.multiple,
    },
    {
        Header: 'Customer Email',
        accessor: 'email',
        id: 'email',
        className: `${generalStyles.tableCell} ${generalStyles.cap}`,
        Filter: MultiSelectColumnFilter,
        filter: FILTER_TYPES.multiple,
    },
    {
        Header: 'First Name',
        accessor: 'fname',
        id: 'fname',
        className: `${generalStyles.tableCell} ${generalStyles.cap}`,
        disableFilters: true,
    },
    {
        Header: 'Last Name',
        accessor: 'lname',
        id: 'lname',
        className: `${generalStyles.tableCell} ${generalStyles.cap}`,
        disableFilters: true,
    },
    {
        Header: 'Subscription Type',
        accessor: 'subscriptionName',
        id: 'subscriptionName',
        className: `${generalStyles.tableCell} ${generalStyles.cap}`,
        Filter: MultiSelectColumnFilter,
        filter: FILTER_TYPES.multiple,
    },
    {
        Header: 'Category Config',
        accessor: 'cat_config',
        id: 'cat_config',
        className: `${generalStyles.tableCell} ${generalStyles.cap}`,
        Filter: MultiSelectColumnFilter,
        filter: FILTER_TYPES.multiple,
        Cell: ({ value: cat_config }) => cat_config?.join(', ') ?? '',
    },
    {
        Header: 'Contract Start Date',
        accessor: 'contract_start_date',
        id: 'contract_start_date',
        className: `${generalStyles.tableCell} ${generalStyles.cap}`,
        Filter: DateRangeColumnFilter,
        filter: FILTER_TYPES.dateRange,
    }, 
    {
        Header: 'Contract End Date',
        accessor: 'contract_end_date',
        id: 'contract_end_date',
        className: `${generalStyles.tableCell} ${generalStyles.cap}`,
        Filter: DateRangeColumnFilter,
        filter: FILTER_TYPES.dateRange,
    },
    
];
