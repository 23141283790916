import CSR_ROLES from "@/constants/CsrRoles";

export const account = {
    state: {
        user: null,
        isLoggedIn: false
    },
    reducers: {
        setUser: (state, user) => ({
            user,
            isSales: user?.csrUserRoles?.includes(CSR_ROLES.sales),
            isCsr: user?.csrUserRoles?.includes(CSR_ROLES.csr),
            isSuperUser: user?.csrUserRoles?.includes(CSR_ROLES.superUser),
            isLoggedIn: !!user
        }),
        clearUser: (state) => ({
            user: null,
            isLoggedIn: false
        })
    }
};
