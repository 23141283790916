import generalStyles from '@/components/General/Styles.module.scss';
import React, { useState, useEffect } from 'react';
import API_STATES from '@/constants/StateConstants';
import * as API from '@/services/api/csrdata.service';
import TextInput from '@/components/General/TextInput';
import CheckBoxList from '@/components/General/CheckBoxList';
import styles from '@/components/ModifyCSR/ModifyCSR.module.scss';
import { handleApiError } from '@/components/General/HandleApiError';

function UpdateCsrNameAndRoles(props) {
    const email = props.email;

    const [lastSavedName, setLastSavedName] = useState({
        firstName: '',
        lastName: ''
    });
    const [newName, setNewName] = useState({
        firstName: '',
        lastName: ''
    });

    const [fetchCsrDetailsApiStatus, setFetchCsrDetailsApiStatus] = useState(API_STATES.none);
    const [fetchCsrDetailsApiError, setFetchCsrDetailsApiError] = useState('');

    const [updateCsrDetailsApiStatus, setUpdateCsrDetailsApiStatus] = useState(API_STATES.none);
    const [updateCsrDetailsApiError, setUpdateCsrDetailsApiError] = useState('');

    const [checkBoxList, setCheckBoxList] = useState([]);

    const [csrRolesApiStatus, setCsrRolesApiStatus] = useState(API_STATES.none);
    const [csrRolesApiError, setCsrRolesApiError] = useState('');

    const [newCsrRoles, setNewCsrRoles] = useState([]);
    const [oldCsrRoles, setOldCsrRoles] = useState([]);

    const onNameChange = (nameType, value) => {
        setUpdateCsrDetailsApiStatus(API_STATES.none);
        setUpdateCsrDetailsApiError('');
        setNewName({
            ...newName,
            [nameType]: value
        });
    };

    const onCheckBoxChange = (checkBoxItem, value) => {
        checkBoxList.forEach((checkListItem) => {
            if (checkListItem.label === checkBoxItem.label) {
                checkListItem.isChecked = value;
            }
        });
        setCheckBoxList([...checkBoxList]);

        let selectedRoles = [];
        checkBoxList.forEach((checkListItem) => {
            if (checkListItem.isChecked) {
                selectedRoles.push(checkListItem.label);
            }
        });
        setNewCsrRoles([...selectedRoles]);
    };

    const isSubmitEnabled = () => {
        return (
            ((updateCsrDetailsApiStatus !== API_STATES.loading &&
                (newName.firstName !== lastSavedName.firstName || newName.lastName !== lastSavedName.lastName)) ||
                (newCsrRoles.length !== 0 && JSON.stringify(oldCsrRoles) !== JSON.stringify(newCsrRoles))) &&
            (newName.firstName !== '' && newName.lastName !== '') &&
            newCsrRoles.length !== 0
        );
    };

    async function getCsrNameAndRoles() {
        setFetchCsrDetailsApiStatus(API_STATES.loading);
        const result = await API.getCsrUser(email);
        switch (result.status) {
            case API_STATES.success:
                setFetchCsrDetailsApiStatus(API_STATES.success);
                setLastSavedName({
                    firstName: result.data.firstName,
                    lastName: result.data.lastName
                });
                setNewName({
                    firstName: result.data.firstName,
                    lastName: result.data.lastName
                });
                getAllRoles(result.data.csrUserRoles);
                setOldCsrRoles(result.data.csrUserRoles);
                break;
            case API_STATES.error:
                let error = result.error;
                handleApiError(
                    error,
                    setFetchCsrDetailsApiStatus,
                    setFetchCsrDetailsApiError,
                    `Csr User ${email} not found`
                );
                break;
            default:
                setFetchCsrDetailsApiError('');
                setFetchCsrDetailsApiStatus(API_STATES.none);
        }
    }

    async function modifyCsrNameAndRoles() {
        setUpdateCsrDetailsApiStatus(API_STATES.loading);
        const result = await API.updateCsrNameAndRoles(
            email,
            newName.firstName,
            newName.lastName,
            JSON.stringify(oldCsrRoles) !== JSON.stringify(newCsrRoles) && newCsrRoles.length !== 0 ? newCsrRoles : null
        );
        switch (result.status) {
            case API_STATES.success:
                setUpdateCsrDetailsApiStatus(API_STATES.success);
                setLastSavedName({
                    firstName: newName.firstName,
                    lastName: newName.lastName
                });
                setOldCsrRoles(newCsrRoles);
                setTimeout(() => setUpdateCsrDetailsApiStatus(API_STATES.none), 2000);
                break;
            case API_STATES.error:
                let error = result.error;
                handleApiError(
                    error,
                    setUpdateCsrDetailsApiStatus,
                    setUpdateCsrDetailsApiError,
                    `Csr ${email} not found`
                );
                break;
            default:
                setUpdateCsrDetailsApiError('');
                setUpdateCsrDetailsApiStatus(API_STATES.none);
        }
    }

    async function getAllRoles(oldCsrRoles) {
        setCsrRolesApiStatus(API_STATES.loading);
        const result = await API.getAllCsrRoles();
        switch (result.status) {
            case API_STATES.success:
                setCsrRolesApiStatus(API_STATES.success);
                const roles = result.data;
                const roleCheckList = roles.map((role) => {
                    if (oldCsrRoles.includes(role.role)) {
                        return {
                            id: role.id,
                            label: role.role,
                            isChecked: true
                        };
                    } else {
                        return {
                            id: role.id,
                            label: role.role,
                            isChecked: false
                        };
                    }
                });
                setCheckBoxList(roleCheckList);
                let existingRoles = [];
                roleCheckList.forEach((checkListItem) => {
                    if (checkListItem.isChecked) {
                        existingRoles.push(checkListItem.label);
                    }
                });
                setNewCsrRoles([...existingRoles]);
                break;
            case API_STATES.error:
                let error = result.error;
                handleApiError(error, setCsrRolesApiStatus, setCsrRolesApiError, 'CSR roles not found');
                break;
            default:
                setCsrRolesApiError('');
                setCsrRolesApiStatus(API_STATES.none);
        }
    }

    useEffect(() => {
        (async () => {
            if (email !== null && email !== '') {
                getCsrNameAndRoles();
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [email]);

    return (
        <div data-test="update-csrnameroles-component">
            {fetchCsrDetailsApiStatus === API_STATES.loading ? (
                <label className={generalStyles.statusMessage}>Fetching Name and Roles</label>
            ) : fetchCsrDetailsApiStatus === API_STATES.error ? (
                <label className={generalStyles.errorMessage}>
                    Failed to fetch CSR Name and Roles: {fetchCsrDetailsApiError}
                </label>
            ) : (
                <>
                    <h3>Change Name & Role(s)</h3>
                    <TextInput
                        type="text"
                        label="First Name:"
                        placeholder="First Name"
                        value={newName.firstName}
                        onInputChange={(event) => onNameChange('firstName', event.target.value)}
                        disabled={updateCsrDetailsApiStatus === API_STATES.loading}
                    />

                    <TextInput
                        type="text"
                        label="Last Name:"
                        placeholder="Last Name"
                        value={newName.lastName}
                        onInputChange={(event) => onNameChange('lastName', event.target.value)}
                        disabled={updateCsrDetailsApiStatus === API_STATES.loading}
                    />
                    <br />

                    <div className={styles.select_roles_wrapper}>
                        <div>Select Role(s):</div>
                        <div>
                            <label>
                                {csrRolesApiStatus === API_STATES.loading ? (
                                    <span className={generalStyles.statusMessage}>Fetching roles...</span>
                                ) : csrRolesApiStatus === API_STATES.error ? (
                                    <span className={generalStyles.errorMessage}>Error: {csrRolesApiError}</span>
                                ) : (
                                    csrRolesApiStatus === API_STATES.success && (
                                        <>
                                            <CheckBoxList
                                                checkBoxList={checkBoxList}
                                                checkBoxOnChange={onCheckBoxChange}
                                            />
                                            <div>
                                                {newCsrRoles.length === 0 && (
                                                    <label className={generalStyles.statusMessage}>
                                                        At least one role needs to be selected for a CSR
                                                    </label>
                                                )}
                                            </div>
                                        </>
                                    )
                                )}
                            </label>
                        </div>
                    </div>
                    <button
                        data-test="update-csrnameroles-submit-btn"
                        className={generalStyles.submitButton}
                        disabled={!isSubmitEnabled()}
                        onClick={modifyCsrNameAndRoles}
                    >
                        Update
                    </button>

                    <label data-test="update-csrnameroles-result">
                        {updateCsrDetailsApiStatus === API_STATES.loading ? (
                            <span className={generalStyles.statusMessage}>Processing...</span>
                        ) : updateCsrDetailsApiStatus === API_STATES.error ? (
                            <span className={generalStyles.errorMessage}>
                                Failed to Update CSR Name and Roles: {updateCsrDetailsApiError}
                            </span>
                        ) : (
                            updateCsrDetailsApiStatus === API_STATES.success && (
                                <span className={generalStyles.successMessage}>CSR Details Updated!</span>
                            )
                        )}
                    </label>
                </>
            )}
        </div>
    );
}
export default UpdateCsrNameAndRoles;
