import React from 'react';
import RunCreditsEntry from './RunCreditsEntry';
import PropTypes from 'prop-types';
import styles from './RunCredits.module.scss';

function RunCreditsTable(props) {
    return (
        <table className={styles.creditTable}>
            <thead>
            <tr>
                <th>Item Title</th>
                <th>Credit Type</th>
                <th>Base Count</th>
                <th>Credits</th>
                <th>Runs Used</th>
                <th>Runs Left</th>
            </tr>
            </thead>
            <tbody>
            {props.runCredits.map((runCredit) => {
                return <RunCreditsEntry runCredit={runCredit} isEditable={props.isEditable} />;
            })}
            </tbody>
        </table>
    );
}

RunCreditsTable.propTypes = {
    runCredits: PropTypes.arrayOf(PropTypes.shape({
        baseCount: PropTypes.number.isRequired,
        creditCount: PropTypes.number.isRequired,
        usageCount: PropTypes.number.isRequired,
        creditId: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
    })).isRequired,
};

export default RunCreditsTable;
