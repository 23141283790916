import axios from 'axios';
import firebase from 'firebase/app';
import 'firebase/auth';
import API_STATES from '@/constants/StateConstants';

const http = axios.create({
    baseURL: import.meta.env.REACT_APP_BASEURL,
});

http.interceptors.request.use(async (config) => {
    if (firebase.auth().currentUser) {
        config.headers.Authorization = `Bearer ${await firebase.auth().currentUser.getIdToken()}`;
    }
    return config;
});

function getErrorMessage(error) {
    if (error.response?.data?.code) {
        return error.response.data.code;
    }
    return error.toString();
}

/**
 * /sales/ondemandalerts?initiator=...
 */
export async function getAlertsStatusByInitiator(initiator) {
    try {
        const alertsResponse = await http.get(`/sales/ondemandalerts`, { params: { initiator } });
        return { status: API_STATES.success, data: alertsResponse.data };
    } catch (error) {
        return { status: API_STATES.error, error: getErrorMessage(error) };
    }
}

/**
 * /sales/ondemandalerts/correlationId/:correlationId
 */
export async function getAlertsDetailsByCorrelationId(correlationId) {
    try {
        const alertsResponse = await http.get(`/sales/ondemandalerts/correlationId/${correlationId}`);
        return { status: API_STATES.success, data: alertsResponse.data };
    } catch (error) {
        return { status: API_STATES.error, error: getErrorMessage(error) };
    }
}

export async function submitOnDemandSalesAlerts(postDataBodyArray) {
    try {
        const submitResponse = await http.post(`/sales/ondemandalerts`, { alerts: postDataBodyArray });
        return { status: API_STATES.success, data: submitResponse.data };
    } catch (error) {
        return { status: API_STATES.error, error: getErrorMessage(error) };
    }
}

export async function resubmitOnDemandSalesAlerts(correlationId) {
    try {
        const submitResponse = await http.post(`/sales/ondemandalerts/${correlationId}`);
        return { status: API_STATES.success, data: submitResponse.data };
    } catch (error) {
        return { status: API_STATES.error, error: getErrorMessage(error) };
    }
}
