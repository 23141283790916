import React, { useMemo, useState } from 'react';
import Select from 'react-select';
import styles from './CompanyConfig.module.scss';
import generalStyles from '@/components/General/Styles.module.scss';
import API_STATES from '@/constants/StateConstants';
import TextInput from '../General/TextInput';
import { addOmniMarket } from '@/services/api/company.service';
import { handleApiError } from '../General/HandleApiError';
import ByzzerSwitch from '../General/ByzzerSwitch';

function AddOmniPriorApprovedMarketForm(props) {
    const [selectedMarket, setSelectedMarket] = useState(null);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [confirmationUrl, setConfirmationUrl] = useState('');
    const [isPartial, setIsPartial] = useState(false);

    const [approveSelectedMarketApiState, setApproveSelectedMarketApiState] = useState(API_STATES.none);
    const [approveSelectedMarketApiError, setApproveSelectedMarketApiError] = useState('');

    function reset() {
        setSelectedMarket(null);
        setSelectedCategories([]);
        setConfirmationUrl('');
        setIsPartial(false);
    }
    async function approveSelectedMarket() {
        setApproveSelectedMarketApiState(API_STATES.loading);
        const marketId = selectedMarket.value;
        const categoryKeys = selectedCategories.map((category) => category.value);
        const approveSelectedMarketResponse = await addOmniMarket(props.nsCompanyId, marketId, confirmationUrl, isPartial, isPartial ? categoryKeys : []);
        if (approveSelectedMarketResponse.status === API_STATES.success) {
            reset();
            setApproveSelectedMarketApiState(API_STATES.success);
            props.onSuccess();
        } else {
            handleApiError(
                approveSelectedMarketResponse.error,
                setApproveSelectedMarketApiState,
                setApproveSelectedMarketApiError
            );
        }
        setTimeout(() => setApproveSelectedMarketApiState(API_STATES.none), 2000);
    }

    const transformUnapprovedMarkets = (unapprovedMarkets) => {
        setSelectedMarket(null);
        return (
            unapprovedMarkets?.map((unapprovedMarket) => {
                return { label: unapprovedMarket.parentRetailer, value: unapprovedMarket.id };
            }) ?? []
        );
    };

    const transformCategories = (categories) => {
        setSelectedCategories([]);
        return (
            categories?.map((category) => {
                return { label: category.category, value: category.categoryKey };
            }) ?? []
        );
    };

    const unapprovedMarkets = useMemo(() => transformUnapprovedMarkets(props.unapprovedMarkets), [
        props.unapprovedMarkets,
    ]);
    const categories = useMemo(() => transformCategories(props.categories), [props.categories]);

    return (
        <>
            {/* Parent Retailer */}
            <div className={generalStyles['input-wrapper']}>
                <label>Parent Retailer:</label>
                {unapprovedMarkets.length === 0 ? (
                    <p className={generalStyles.statusMessage}>No unapproved omni markets available</p>
                ) : (
                    <span className={generalStyles.formField}>
                        <Select
                            classNamePrefix={'react-select'}
                            options={unapprovedMarkets}
                            onChange={(selectedMarket) => setSelectedMarket(selectedMarket)}
                            value={selectedMarket}
                        />
                    </span>
                )}
            </div>
            {/* Is Partial */}
            <div className={generalStyles['input-wrapper']}>
                <label className={generalStyles.switchLabel}>Approve all categories: </label>
                <ByzzerSwitch 
                    name="includeTestAccounts" 
                    onChange={({ target }) => setIsPartial(!target.checked)}
                    value={!isPartial}
                ></ByzzerSwitch>            
            </div>
            {/* Categories */}
            <div className={generalStyles['input-wrapper']}>
                <label>Omni Categories:</label>
                {categories.length === 0 ? (
                    <p className={generalStyles.statusMessage}>No categories available</p>
                ) : (
                    <span className={generalStyles.formField}>
                        <Select
                            classNamePrefix={'react-select'}
                            options={categories}
                            closeMenuOnSelect={false}
                            isMulti
                            onChange={(selectedCategories) => setSelectedCategories(selectedCategories)}
                            value={isPartial? selectedCategories : []}
                            placeholder={isPartial ? "Select categories" : "ALL CATEGORIES"}
                            isDisabled={!isPartial}
                        />
                    </span>
                )}
            </div>
            {
                (isPartial && !selectedCategories?.length) &&
                <div className={generalStyles.errorMessage}>
                    Please select categories
                </div>
            }
            {/* Confirmation Link */}
            <TextInput
                type="text"
                label="Confirmation Link:"
                placeholder="Confirmation Link"
                value={confirmationUrl}
                onInputChange={(event) => setConfirmationUrl(event.target.value)}
            />
            <div className={styles.columnar}>
                <button
                    className={generalStyles.submitButton}
                    onClick={approveSelectedMarket}
                    disabled={!selectedMarket || !confirmationUrl || approveSelectedMarketApiState !== API_STATES.none || (isPartial && !selectedCategories?.length)}
                    data-test="approve-selected-market-button"
                >
                    Add Market
                </button>
                {approveSelectedMarketApiState === API_STATES.loading ? (
                    <span className={generalStyles.statusMessage}>Processing...</span>
                ) : approveSelectedMarketApiState === API_STATES.error ? (
                    <span className={generalStyles.errorMessage}>Error: {approveSelectedMarketApiError}</span>
                ) : (
                    approveSelectedMarketApiState === API_STATES.success && (
                        <>
                            <span className={generalStyles.successMessage}> Market Approved </span>
                        </>
                    )
                )}
                {approveSelectedMarketApiState !== API_STATES.loading &&
                    approveSelectedMarketApiState !== API_STATES.success && (
                        <button className={generalStyles.submitButton} onClick={props.onClose}>
                            View Approved Omni Markets
                        </button>
                    )}
            </div>
        </>
    );
}
export default AddOmniPriorApprovedMarketForm;
