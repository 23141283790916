import * as Constant from './Constants';

export function customizeDate(date) {
    if (date === null || date === undefined) {
        return '';
    }
    let dateStr = date.toString().slice(0, 10);
    dateStr = dateStr.replaceAll('/','-');
    const [year, month, day] = dateStr.split('-');

    return Constant.MONTHS[month - 1] + ' ' + day + ', ' + year;
}

export function getMessageTypeValue(messageType){
    return Constant.TYPE_OF_NOTIFICATION.filter((option) => option.value === messageType)
}

export function getRunTypeValue(runType){
    return Constant.RUN_TYPE.filter((option) => option.value === runType)
}

export function getMarketNotificationTypeValue(notificationType){
    return Constant.NOTIFICATION_TYPE.filter((option) => option.value === notificationType)
}