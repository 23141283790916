import { getApprovedMarketsReport } from '@/services/api/report.service';
import { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import generalStyles from '@/components/General/Styles.module.scss';
import API_STATES from '@/constants/StateConstants';
import { handleApiError } from '@/components/General/HandleApiError';
import EmptyTable from '@/components/General/EmptyTable';
import ByzzerSwitch from '../General/ByzzerSwitch';
import Select from 'react-select';
import { toOption } from '../utils/utils';
import { getMarkets } from '@/services/api/retailer.service';
import DataTable from '../General/DataTable';
import { COLUMNS } from './ApprovedMarketsReportColumns';

function ApprovedMarketsReport() {
    const [allMarketsGetApiState, setAllMarketsGetApiState] = useState(API_STATES.none);
    const [allMarketsGetApiError, setAllMarketsGetApiError] = useState('');
    const [allMarkets, setAllMarkets] = useState([]);

    const [selectedMarkets, setSelectedMarkets] = useState([]);

    async function loadAllPriorApprovalMarkets() {
        setAllMarketsGetApiState(API_STATES.loading);
        const marketsResponse = await getMarkets();
        if (marketsResponse.status === API_STATES.success) {
            setAllMarkets(marketsResponse.data.map((market) => toOption(market.id, market.parentRetailer)));
            setAllMarketsGetApiState(API_STATES.success);
        } else {
            handleApiError(marketsResponse.error, setAllMarketsGetApiState, setAllMarketsGetApiError);
            setAllMarkets([]);
        }
    }

    const [reportApiStatus, setReportApiStatus] = useState(API_STATES.none);
    const [reportApiError, setReportApiError] = useState('');
    const [reportResult, setReportResult] = useState([]);

    const [includeTestAccounts, setIncludeTestAccounts] = useState(false);
    const [showActiveContracts, setShowActiveContracts] = useState(false);

    const handleIncludeTestAccountsChange = ({ target }) => {
        setIncludeTestAccounts(target.checked);
    };

    const handleShowActiveContracts = ({ target }) => {
        setShowActiveContracts(target.checked);
    };

    async function loadApprovedMarketsReport() {
        setReportApiStatus(API_STATES.loading);
        const result = await getApprovedMarketsReport(
            selectedMarkets.map((option) => option.value),
            showActiveContracts,
            includeTestAccounts
        );
        switch (result.status) {
            case API_STATES.success:
                setReportApiStatus(API_STATES.success);
                setReportResult(result.data);
                break;
            case API_STATES.error:
                handleApiError(result.error, setReportApiStatus, setReportApiError, 'Category License report not found');
                break;
            default:
                setReportApiStatus(API_STATES.none);
                setReportApiError('');
        }
    }

    useEffect(() => {
        loadAllPriorApprovalMarkets();
    }, []);

    return (
        <div>
            <h1>Approved Markets Report</h1>
            <hr />
            <div className={generalStyles['input-wrapper']}>
                <label> Select markets: </label>
                <span className={generalStyles.multiSelectInput}>
                    {allMarketsGetApiState === API_STATES.loading ? (
                        <span className={generalStyles.statusMessage}>Loading markets...</span>
                    ) : allMarketsGetApiState === API_STATES.error ? (
                        <span className={generalStyles.statusMessage}>{allMarketsGetApiError}</span>
                    ) : (
                        <Select
                            classNamePrefix={'react-select'}
                            isMulti={true}
                            closeMenuOnSelect={false}
                            onChange={setSelectedMarkets}
                            value={selectedMarkets}
                            options={allMarkets}
                        />
                    )}
                </span>
            </div>

            <div className={generalStyles['input-wrapper']}>
                <label className={generalStyles.switchLabel}>Include Test Accounts: </label>
                <ByzzerSwitch name="includeTestAccounts" onChange={handleIncludeTestAccountsChange}></ByzzerSwitch>
            </div>

            <div className={generalStyles['input-wrapper']}>
                <label className={generalStyles.switchLabel}>Show Only Active Contracts: </label>
                <ByzzerSwitch name="showActiveContracts" onChange={handleShowActiveContracts}></ByzzerSwitch>
            </div>
            <br />

            <button
                className={generalStyles.submitButton}
                onClick={loadApprovedMarketsReport}
                disabled={reportApiStatus === API_STATES.loading}
            >
                Generate Report
            </button>
            {reportApiStatus === API_STATES.error ? (
                <span className={generalStyles.errorMessage}>Error: {reportApiError}</span>
            ) : reportApiStatus === API_STATES.loading ? (
                <span className={generalStyles.statusMessage}>Loading...</span>
            ) : (
                reportApiStatus === API_STATES.success && (
                    <>
                        {reportResult?.length > 0 ? (
                            <DataTable
                                data={reportResult}
                                columns={COLUMNS}
                                withDownload
                                downloadFileName={'ApprovedMarketsReport'}
                            />
                        ) : (
                            <EmptyTable
                                noValueDescription="No client users matching the selected categories."
                                columns={[
                                    'Company Id',
                                    'Company Name',
                                    'Customer Email',
                                    'First Name',
                                    'Last Name',
                                    'Subscription Type',
                                    'Category Config',
                                    'Contract Start Date',
                                    'Contract End Date',
                                ]}
                            />
                        )}
                    </>
                )
            )}
        </div>
    );
}

export default ApprovedMarketsReport;
