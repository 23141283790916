import generalStyles from '@/components/General/Styles.module.scss';
import { MultiSelectColumnFilter } from '../General/MultiSelectColumnFilter';
import { FILTER_TYPES } from '../General/DataTable';

export const COLUMNS = [
    {
        Header: 'Company Name',
        accessor: 'companyName',
        className: generalStyles.tableCell,
        Filter: MultiSelectColumnFilter,
        filter: FILTER_TYPES.multiple,
    },
    {
        Header: 'Approved Market',
        accessor: 'approvedMarket',
        className: generalStyles.tableCell,
        Filter: MultiSelectColumnFilter,
        filter: FILTER_TYPES.multiple,
    },
    {
        Header: 'Department',
        accessor: 'department',
        className: generalStyles.tableCell,
        Filter: MultiSelectColumnFilter,
        filter: FILTER_TYPES.multiple,
        Cell: ({ value }) => value ?? 'ALL DEPARTMENTS',
    },
    {
        Header: 'Super Category',
        accessor: 'superCategory',
        className: generalStyles.tableCell,
        Filter: MultiSelectColumnFilter,
        filter: FILTER_TYPES.multiple,
        Cell: ({ value }) => value ?? 'ALL SUPER CATEGORIES',
    },
    {
        Header: 'Category',
        accessor: 'category',
        className: generalStyles.tableCell,
        Filter: MultiSelectColumnFilter,
        filter: FILTER_TYPES.multiple,
        Cell: ({ value }) => value ?? 'ALL CATEGORIES',
    },
    {
        Header: 'Approval Link',
        accessor: 'approvalLink',
        className: generalStyles.tableCell,
        disableFilters: true,
    },
    {
        Header: 'Contract Start Date',
        accessor: 'contractStartDate',
        className: generalStyles.tableCell,
        disableFilters: true,
    },
    {
        Header: 'Contract End Date',
        accessor: 'contractEndDate',
        className: generalStyles.tableCell,
        disableFilters: true,
    },
];
