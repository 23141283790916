import { timeZoneString } from './Constants';

function getTime(minutes) {
    const h = Math.floor(minutes / 60);
    const m = minutes - h * 60;
    const time = `${String(h % 24).padStart(2, '0')}:${String(m).padStart(2, '0')}`;
    return { label: time + ' ' + timeZoneString, value: time };
}
export function generateTimeIntervals(intervalInMinutes = 15) {
    let t = 0;
    const minutesInDay = 60 * 24;
    const timeIntervals = [];
    while (t < minutesInDay) {
        timeIntervals.push(getTime(t));
        t += intervalInMinutes;
    }
    return timeIntervals;
}
