import generalStyles from '@/components/General/Styles.module.scss';
import React, { useState } from 'react';
import API_STATES from '@/constants/StateConstants';
import { deleteCsrUser } from '@/services/api/csrdata.service';
import { handleApiError } from '@/components/General/HandleApiError';

function ConfirmDeletion(props) {
    const email = props.email;

    const [deleteCsrApiStatus, setDeleteCsrApiStatus] = useState(API_STATES.none);
    const [deleteCsrApiError, setDeleteCsrApiError] = useState('');
    const [deleteCsrApiResult, setDeleteCsrApiResult] = useState(false);

    //delete csr api call
    async function deleteCsr() {
        setDeleteCsrApiStatus(API_STATES.loading);
        const result = await deleteCsrUser(email);
        switch (result.status) {
            case API_STATES.success:
                setDeleteCsrApiStatus(API_STATES.success);
                setDeleteCsrApiResult(true);
                props.onDelete();
                break;
            case API_STATES.error:
                let error = result.error;
                handleApiError(
                    error,
                    setDeleteCsrApiStatus,
                    setDeleteCsrApiError,
                    `Unable to delete Csr: ${email} not found`
                );
                break;
            default:
                setDeleteCsrApiError('');
                setDeleteCsrApiStatus(API_STATES.none);
        }
    }
    return (
        <>
            <b className={generalStyles.errorMessage}>
                This action is not reversible, and will permanently delete the {props.email} CSR.
                <br />
                Are you sure to proceed?
            </b>
            <button
                data-test="confirm-deletion-btn"
                className={generalStyles.confirmOperationButton}
                onClick={deleteCsr}
                disabled={deleteCsrApiResult || deleteCsrApiStatus === API_STATES.loading}
            >
                Yes, Delete this CSR
            </button>
            <button
                data-test="cancel-deletion-btn"
                className={generalStyles.cancelOperationButton}
                onClick={props.onCancel}
                disabled={deleteCsrApiResult || deleteCsrApiStatus === API_STATES.loading}
            >
                No, Do not Delete this CSR
            </button>
            <label data-test="csr-deletion-status">
                {deleteCsrApiStatus === API_STATES.none ? (
                    ''
                ) : deleteCsrApiStatus === API_STATES.loading ? (
                    <span className={generalStyles.statusMessage}>Processing...</span>
                ) : deleteCsrApiStatus === API_STATES.error ? (
                    <span className={generalStyles.errorMessage}>Failed to Delete CSR: {deleteCsrApiError}</span>
                ) : (
                    deleteCsrApiStatus === API_STATES.success && (
                        <span className={generalStyles.successMessage}>CSR Deleted!</span>
                    )
                )}
            </label>
        </>
    );
}

export default ConfirmDeletion;
