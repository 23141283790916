import generalStyles from '@/components/General/Styles.module.scss';
import React, { useState, useEffect } from 'react';
import API_STATES from '@/constants/StateConstants';
import { handleApiError } from '@/components/General/HandleApiError';
import * as API from '@/services/api/product.service';
import FileInfoContainer from './FileInfoContainer';
import styles from './MarketMaps.module.scss';

function FolderList(props) {
    const folders = props.folders;
    const marketMapType = props.marketMapType;
    return (
        <ol data-test="folder-list" className={generalStyles.list}>
            {folders.map((folder) => {
                return (
                    <FolderListItem
                        key={folder.id}
                        folder={folder}
                        onUpdate={() => props.onUpdate()}
                        marketMapType={marketMapType}
                    />
                );
            })}
        </ol>
    );
}

function FolderListItem(props) {
    const folder = props.folder;
    const marketMapType = props.marketMapType;

    const [fileListApiStatus, setFileListApiStatus] = useState(API_STATES.none);
    const [fileListApiError, setFileListApiError] = useState('');

    const [fileList, setFileList] = useState({});
    const [folderSelected, setFolderSelected] = useState(false);

    async function getFilesListByFolder() {
        if (folderSelected) {
            setFolderSelected(false);
        } else {
            setFileListApiStatus(API_STATES.loading);
            const result = await API.getFilesForMarketMaps(folder.id);
            switch (result.status) {
                case API_STATES.success:
                    setFileListApiStatus(API_STATES.success);
                    setFileList(result.data);
                    setFolderSelected(true);
                    break;
                case API_STATES.error:
                    let error = result.error;
                    handleApiError(error, setFileListApiStatus, setFileListApiError);
                    break;
                default:
                    setFileListApiError('');
                    setFileListApiStatus(API_STATES.none);
            }
        }
    }

    
    return (
        <>
            <div className={styles.bundleListFlex}>
                <div>
                    <li className={generalStyles.link} onClick={getFilesListByFolder} data-test="bundle-list-item">
                        {folder.id} : {folder.name}
                    </li>
                </div>
            </div>
            <div>
                <span>
                    {fileListApiStatus === API_STATES.error ? (
                        <p className={generalStyles.errorMessage}>Error: {fileListApiError}</p>
                    ) : fileListApiStatus === API_STATES.none ? (
                        <p className={generalStyles.statusMessage}></p>
                    ) : fileListApiStatus === API_STATES.loading ? (
                        <p className={generalStyles.statusMessage}>Loading...</p>
                    ) : fileListApiStatus === API_STATES.success ? (
                        <div className={styles.bundleInfoContainer}>
                            {folderSelected && (
                                <FileInfoContainer
                                    folder={folder}
                                    fileList={fileList}
                                    marketMapType={marketMapType}
                                    onFolderUpdate={()=>props.onUpdate()}
                                    onFileUpdate={()=>getFilesListByFolder()}
                                />
                            )}
                        </div>
                    ) : null}
                </span>
            </div>
        </>
    );
}

export default FolderList;
