import React, { useContext, useState, useEffect } from 'react';
import { companyPageContext } from '@/components/NewCompanyPage/NewCompanyPage';
import MultiTenantSettings from '@/components/CompanyConfig/MultiTenantSettings';
import CompanyPurchasesReport from '@/components/CompanyPurchasesReport/CompanyPurchasesReport';
import ManageCategories from '@/components/CompanyConfig/ManageCategories';
import AccessibleMarkets from '@/components/CompanyConfig/AccessibleMarkets';
import ApprovedMarkets from '@/components/CompanyConfig/ApprovedMarkets';
import ManageOmniCategories from '@/components/CompanyConfig/ManageOmniCategories';
import OmniApprovedMarkets from '@/components/CompanyConfig/OmniPriorApprovedMarkets';
import InviteUsers from '@/components/NewCompanyPage/InviteUsers';
import ExtendTrial from '@/components/ExtendTrial/ExtendTrial';
import generalStyles from '@/components/General/Styles.module.scss';
import { updateCompanyIsOboModeAllowedStatus, updateDisableCompanyStatus } from '@/services/api/company.service';
import API_STATES from '@/constants/StateConstants';
import ByzzerSwitch from '../General/ByzzerSwitch';
import store from '../../store';
import styles from '@/components/CompanyConfig/CompanyConfig.module.scss';
import { handleApiError, noOp } from '@/components/General/HandleApiError';
import { Collapse } from 'antd';
import './Accordion.scss';
import { getNsCompanyData } from '@/services/api/company.service';
import AddProducts from '@/components/CompanyConfig/AddProducts';
import Brands from '../CompanyConfig/Brands';
import InviteMultiCompanyUsers from './InviteMultiCompanyUsers';
import copyIcon  from '@images/icons/niq-copy-dark.svg'
import greenCheckMarkIcon from '@images/icons/circle-check-green.svg'
import AttachQuotes from '../CompanyConfig/Quotes/AttachQuotes';


const { Panel } = Collapse;

export default function Company({ onChange = noOp }) {
    let { nsCompanyId, companyConfig: { companyName, companyAccessLevel, isOboModeAllowed, isDisabled, isMultiCompany, companyDomains, companyId, tenantId } } = useContext(companyPageContext);
    const [openPanels, setOpenPanels] = useState([]);
    const [confirmDisableAuth, setConfirmDisableAuth] = useState(false)
    const [confirmDisableAuthStatus, setConfirmDisableAuthStatus] = useState(API_STATES.none)
    const [confirmDisableAuthError, setConfirmDisableAuthError] = useState('')
    // const params = useParams();
    const [companyData, setCompanyData] = useState({});
    const [refreshTimestamp, setRefreshTimestamp] = useState(null);
    const [copyStatus, setCopyStatus] = useState({companyId: false, nsCompanyId: false, tenantId: false})


    useEffect(() => {
        setOboModeStatus(isOboModeAllowed)
    }, [isOboModeAllowed])

    useEffect(() => {
        setConfirmDisableAuth(isDisabled)
    }, [isDisabled])

    useEffect(() => {
        if (nsCompanyId) {
            setOpenPanels([]);
            getCompanyDetails(nsCompanyId);
        }
    }, [nsCompanyId]);

    const { isCsr, isSuperUser, isSales } = store?.getState()?.account ?? {};

    const canEditCompanyDisabledStatus = isCsr || isSuperUser || isSales

    const [oboModeStatus, setOboModeStatus] = useState(null);

    const [updateOboModeStatusApiState, setUpdateOboModeStatusApiState] = useState(API_STATES.none);
    const [updateOboModeStatusApiError, setUpdateOboModeStatusApiError] = useState('');

    const updateOboModeStatus = async () => {
        setUpdateOboModeStatusApiState(API_STATES.loading);
        setOboModeStatus(!oboModeStatus)
        const response = await updateCompanyIsOboModeAllowedStatus(nsCompanyId, !oboModeStatus);
        if (response.status === API_STATES.success) {
            setUpdateOboModeStatusApiState(API_STATES.success);
            onChange();
        } else {
            const error = response.error;
            handleApiError(error, setUpdateOboModeStatusApiState, setUpdateOboModeStatusApiError)
        }
        setTimeout(() => setUpdateOboModeStatusApiState(API_STATES.none), 3000);
    };

    async function getCompanyDetails(nsCompanyId) {
        const response = await getNsCompanyData(nsCompanyId);
        if (response.status === API_STATES.success) {
            setCompanyData(response.data);
        }
    }

    async function onDisableCompany() {
        setConfirmDisableAuthStatus(API_STATES.loading)
        setConfirmDisableAuth(!confirmDisableAuth)
        let result = await updateDisableCompanyStatus(nsCompanyId, !confirmDisableAuth)
        if (result.status === API_STATES.success) {
            setConfirmDisableAuthStatus(API_STATES.success)
            onChange()
        } else {
            let error = result.error;
            handleApiError(error, setConfirmDisableAuthStatus, setConfirmDisableAuthError);
        }
        setTimeout(() => setConfirmDisableAuthStatus(API_STATES.none), 3000);
    }

    function onCopySelectedId (item, value) {
        if(value){
            navigator.clipboard.writeText(String(value))
            setCopyStatus({[item]: true})
            setTimeout(()=>{
                setCopyStatus({[item]: false})
            }, 3000)
        }
    }
    return (
        <>
            <div className={styles.rowFlex}>
                <div>
                    <h2 className={styles.heading}>
                        {companyName} 
                        {isMultiCompany && <span className={generalStyles.statusMessage}>(multi company)</span>}
                    </h2>
                    <strong>{companyAccessLevel}</strong>
                    <br/>
                    <strong>
                        Company Id: {companyId}
                        <img title={`Copy Company Id`} className={styles.icon} src={copyStatus.companyId ? greenCheckMarkIcon: copyIcon} alt="copy-icon" onClick={()=>onCopySelectedId('companyId', companyId)}/>   
                    </strong>
                    <br/>
                    <strong>
                        Netsuite Company Id: {nsCompanyId}
                        <img title={`Copy Netsuite Company Id`} className={styles.icon} src={copyStatus.nsCompanyId ? greenCheckMarkIcon: copyIcon} alt="copy-icon" onClick={()=>onCopySelectedId('nsCompanyId', nsCompanyId)}/>   
                    </strong>
                    <br/>
                    <strong>
                        Tenant Id: {tenantId}
                        <img title={`Copy Tenant Id`} className={styles.icon} src={copyStatus.tenantId ? greenCheckMarkIcon: copyIcon} alt="copy-icon" onClick={()=>onCopySelectedId('tenantId', tenantId)}/>   
                    </strong>
                </div>

                <div className={styles.rowFlex}>
                    <div className={styles.oboModeStatus}>
                        <label className={styles.oboModeStatusLabel}>Allow Obo Mode</label>
                        <ByzzerSwitch
                            data-test="obo-mode-switch"
                            onChange={() => updateOboModeStatus()}
                            value={oboModeStatus ?? false}
                            disabled={!isSuperUser}
                        />
                        <label data-test="obo-mode-toggle-status">
                            {updateOboModeStatusApiState === API_STATES.loading ? (
                                <span className={generalStyles.statusMessage}>Processing...</span>
                            ) : updateOboModeStatusApiState === API_STATES.error ? (
                                <span className={generalStyles.errorMessage}>
                                    Failed to Update: {updateOboModeStatusApiError}
                                </span>
                            ) : (
                                updateOboModeStatusApiState === API_STATES.success && (
                                    <span className={generalStyles.successMessage}>saved!</span>
                                )
                            )}
                        </label>
                    </div>
                    {(oboModeStatus === undefined || oboModeStatus === null) && (
                        <div className={generalStyles.statusMessage}>
                            Company is missing obo mode configuration
                        </div>
                    )}
                    <div className={styles.oboModeStatus} >
                        <label className={styles.oboModeStatusLabel}>Disable Authentication</label>
                        <ByzzerSwitch
                            data-test="disable-company-switch"
                            onChange={() => onDisableCompany()}
                            value={confirmDisableAuth ?? false}
                            disabled={!canEditCompanyDisabledStatus}
                        />
                        {confirmDisableAuthStatus === API_STATES.loading ? (
                            <span className={generalStyles.statusMessage}>Processing...</span>
                        ) : confirmDisableAuthStatus === API_STATES.error ? (
                            <span className={generalStyles.errorMessage}>
                                Failed to Update: {confirmDisableAuthError}
                            </span>
                        ) : (
                            confirmDisableAuthStatus === API_STATES.success && (
                                <span className={generalStyles.successMessage}>saved!</span>
                            )
                        )}
                    </div>
                </div>

            </div>
            <br />
            <Collapse
                activeKey={openPanels}
                onChange={setOpenPanels}
                className={'alerts-list-accordion'}
                expandIconPosition={'end'}
            >
                <Panel
                    header={
                        <div className={'alerts-list-panel-heading'}>
                            <span className={'alerts-list-panel-heading-title'}>
                                {<b>Multi Company Settings</b>}
                            </span>
                        </div>
                    }
                >
                    <MultiTenantSettings
                        nsCompanyId={nsCompanyId}
                        refreshTimestamp={refreshTimestamp}
                        isEditable={isCsr || isSuperUser} />
                </Panel>
                <Panel
                    header={
                        <div className={'alerts-list-panel-heading'}>
                            <span className={'alerts-list-panel-heading-title'}>
                                {<b>Company Purchases</b>}
                            </span>
                        </div>
                    }
                >
                    <CompanyPurchasesReport
                        nsCompanyId={nsCompanyId}
                        refreshTimestamp={refreshTimestamp}
                        refreshDelay={100000}
                        isEditable={isSuperUser} />
                </Panel>
                <Panel
                    header={
                        <div className={'alerts-list-panel-heading'}>
                            <span className={'alerts-list-panel-heading-title'}>
                                {<b>Brands</b>}
                            </span>
                        </div>
                    }
                >
                    <Brands nsCompanyId={nsCompanyId} isEditable={isCsr || isSuperUser} />
                </Panel>
                <Panel
                    header={
                        <div className={'alerts-list-panel-heading'}>
                            <span className={'alerts-list-panel-heading-title'}>
                                {<b>Categories</b>}
                            </span>
                        </div>
                    }
                >
                    <ManageCategories nsCompanyId={nsCompanyId} isEditable={isCsr || isSuperUser} />
                </Panel>
                <Panel
                    header={
                        <div className={'alerts-list-panel-heading'}>
                            <span className={'alerts-list-panel-heading-title'}>
                                {<b>Accessible Markets</b>}
                            </span>
                        </div>
                    }
                >
                    <AccessibleMarkets nsCompanyId={nsCompanyId} isEditable={isCsr || isSuperUser} />
                </Panel>
                <Panel
                    header={
                        <div className={'alerts-list-panel-heading'}>
                            <span className={'alerts-list-panel-heading-title'}>
                                {<b>Approved Markets</b>}
                            </span>
                        </div>
                    }
                >
                    <ApprovedMarkets nsCompanyId={nsCompanyId} isEditable={isCsr || isSuperUser} />
                </Panel>
                <Panel
                    header={
                        <div className={'alerts-list-panel-heading'}>
                            <span className={'alerts-list-panel-heading-title'}>
                                {<b>Omni Categories</b>}
                            </span>
                        </div>
                    }
                >
                    <ManageOmniCategories nsCompanyId={nsCompanyId} isEditable={isCsr || isSuperUser} />
                </Panel>
                <Panel
                    header={
                        <div className={'alerts-list-panel-heading'}>
                            <span className={'alerts-list-panel-heading-title'}>
                                {<b>Omni Approved Markets</b>}
                            </span>
                        </div>
                    }
                >
                    <OmniApprovedMarkets nsCompanyId={nsCompanyId} isEditable={isCsr || isSuperUser} />
                </Panel>
                <Panel
                    header={
                        <div className={'alerts-list-panel-heading'}>
                            <span className={'alerts-list-panel-heading-title'}>
                                {<b>Extend Trial Expiry</b>}
                            </span>
                        </div>
                    }
                >
                    <ExtendTrial nsCompanyId={nsCompanyId} isEditable={isCsr || isSuperUser} />
                </Panel>
                <Panel
                    header={
                        <div className={'alerts-list-panel-heading'}>
                            <span className={'alerts-list-panel-heading-title'}>
                                {<b>Invite Multi-Company User (Consultant)</b>}
                            </span>
                        </div>
                    }
                >
                    <InviteMultiCompanyUsers nsCompanyId={nsCompanyId} />
                </Panel>
                <Panel
                    header={
                        <div className={'alerts-list-panel-heading'}>
                            <span className={'alerts-list-panel-heading-title'}>
                                {<b>Invite Users</b>}
                            </span>
                        </div>
                    }
                >
                    <InviteUsers nsCompanyId={nsCompanyId} />
                </Panel>
                <Panel
                    header={
                        <div className={'alerts-list-panel-heading'}>
                            <span className={'alerts-list-panel-heading-title'}>
                                {<b>Add Products</b>}
                            </span>
                        </div>
                    }
                >
                    <AddProducts
                        nsCompanyId={nsCompanyId}
                        companyInfo={companyData}
                        setRefreshTimestamp={setRefreshTimestamp}
                    />
                </Panel>
                <Panel header={
                        <div className={'alerts-list-panel-heading'}>
                            <span className={'alerts-list-panel-heading-title'}>
                                {<b>Quote</b>}
                            </span>
                        </div>
                    }>
                        <AttachQuotes companyDomain={companyDomains} isEditable={isSuperUser} nsCompanyId={nsCompanyId}/>
                </Panel>
            </Collapse>
        </>
    );
}
