import styles from '@/components/General/Styles.module.scss';
import InlineSVG from 'react-inlinesvg/esm';
import eyeOpenIcon from '@images/icons/eyeOpenIcon.svg';
import eyeClosedIcon from '@images/icons/eyeClosedIcon.svg';
import { useState } from 'react';

export default function PasswordInput(props) {
    const [revealStatus, setRevealStatus] = useState(false);
    function onToggleReveal() {
        setRevealStatus(!revealStatus);
    }
    return (
        <div className={styles['input-wrapper']}>
            {props.label && <label>{props.label}</label>}
            <div className={styles.input}>
                <input
                    type={revealStatus ? 'text' : 'password'}
                    onChange={props.onPasswordChange}
                    placeholder={props.placeholder}
                    value={props.value}
                    disabled={props.disabled}
                    required={props.required}
                />
                <InlineSVG src={revealStatus ? eyeOpenIcon : eyeClosedIcon} onClick={onToggleReveal} />
            </div>
            {props.errorList && (
                <ul className={styles.errorList}>
                    {props.errorList.map((element) => (
                        <li>{element}</li>
                    ))}
                </ul>
            )}
        </div>
    );
}
