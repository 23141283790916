import React, { useState, useCallback } from 'react';
import CategoryNotificationDetailedHistory from './CategoryNotificationDetailedHistory';

function CategoryNotificationDetailedView({ selectedNotification }) {
    const [refreshHistoryDetail, setRefreshHistoryDetail] = useState(true);

    const refreshNotificationDetailedList = useCallback(async (flag) => {
        setRefreshHistoryDetail(flag);
    }, []);

    return (
        <>
            <CategoryNotificationDetailedHistory
                refreshHistory={refreshHistoryDetail}
                refresh={(flag) => refreshNotificationDetailedList(flag)}
                selectedNotification={selectedNotification}
            />
        </>
    );
}

export default CategoryNotificationDetailedView;
