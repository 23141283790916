import React, { useEffect, useState } from 'react';
import styles from './OnDemandAlerts.module.scss';
import general from '../General/Styles.module.scss';
import { Dropzone } from '@/components/OnDemandAlerts/Dropzone';
import Select from 'react-select';
import * as R from 'ramda';
import * as SalesAPI from '../../services/api/sales.service';
import ProcessingStatus from '@/components/OnDemandAlerts/ProcessingStatus';
import isEmail from 'is-email';
import { brandExists, getCategoriesForBrand } from '@/services/api/product.service';
import store from '@/store';
import API_STATES from '@/constants/StateConstants';
import { Link } from 'react-router-dom';
import * as Constants from './Constants';
import { downloadAsCsv } from '../utils/utils';

function OnDemandAlerts(props) {
    const CSVheader = ['First Name', 'Email Address', 'Brand', 'Category', 'From (Name)', 'Reply-To Email Address'];

    const [selectedEmail, setSelectedEmail] = useState({});
    const [processingStatusMessages, setProcessingStatusMessages] = useState([]);
    const [currentProcessingMessage, setCurrentProcessingMessage] = useState('');

    /**
     * true when we're parsing a file.
     */
    const [isProcessing, setIsProcessing] = useState(false);

    // Whether or not the send email button was pressed, and if the API was successful.
    const [sentMail, setSentMail] = useState(false);

    // [
    //   [first name, email, brand, category, from name, reply-to]
    //    ...
    // ]
    const [parsedData, setParsedData] = useState([]);
    // [ error reason(s) ]
    const [errorData, setErrorData] = useState([]);

    // [
    //  {first name, email, brand, category, from name, reply-to, error-file, error-line, error-reason(s)}
    // ]
    const [errorFileContent, setErrorFileContent] = useState([]);

    /**
     * Handles the selection of the alert/email type from the selection/dropdown.
     */
    const handleSelectedEmail = (email) => {
        setSelectedEmail(email);
    };

    /**
     * downloads spreadsheet errors as a CSV file.
     */
    const downloadErrors = () => {
        const title = `FileUploadErrors`;
        const heading =
            'First Name,Email Address,Brand,Category,From (Name),Reply-To Email Address,File Name,Data Line Number,Error Reason(s)\n';
        downloadAsCsv(title, heading, errorFileContent);
    };

    /**
     * send the array of parsed data to the service.
     */
    const handleSendMailButtonClick = async () => {
        const initiator = store.getState().account.user.email;
        const postBodyData = parsedData.map((line) => {
            return {
                initiator,
                email: line[1],
                brand: String(line[2]).toUpperCase(),
                category: String(line[3]).toUpperCase(),
                alertType: selectedEmail.value,
                replyToEmail: line[5],
                fromName: line[4],
                firstName: line[0],
            };
        });

        const result = await SalesAPI.submitOnDemandSalesAlerts(postBodyData);
        // We track actual statuses of the lines of data in the status table,
        // not here.  So this is just to see if the submission worked or not.
        // If it did, indicate this in a state, so we can check that later
        // and continue to show the "send email" button, or change that to a
        // link to the status table.
        switch (result.status) {
            case API_STATES.success:
                setSentMail(true);
                setParsedData([]); // clear it so it can't get resent
                break;
            default:
                const errorStatus = result.error || 'unknown error';
                setErrorData([`error submitting mails: ${errorStatus}`]);
                setSentMail(false);
                break;
        }
    };

    // [ {type: success|error, message: string} ]
    const addSuccessProcessingStatus = (message) => {
        setSentMail(false);
        setProcessingStatusMessages((prevMessages) => [...prevMessages, { type: 'success', message: message }]);
    };
    const addErrorProcessingStatus = (message) => {
        setSentMail(false);
        setProcessingStatusMessages((prevMessages) => [...prevMessages, { type: 'error', message: message }]);
    };

    const validateLines = async (fileName, lines) => {
        let validatedLines = [];
        let errors = []; // all errors in the file
        let lineNum = 1;

        // For every line, ensure:
        for (let line of lines) {
            let hasErrors = false;
            let lineSpecificError = []; // all errors in a particular line in the file

            // we skip rest of the checks if the number of fields in a line is not 6.
            if (line?.length !== 6) {
                lineSpecificError.push(
                    `${fileName}: line ${lineNum}: line has ${line?.length || 0} field(s), expected 6.`
                );
                hasErrors = true;
                // before moving onto next line, we note down the error
                errors.push(...lineSpecificError);

                // for the purpose of display in error file, we slice if count(fields) > 6,
                // else fill with 'no data' in empty columns
                if (line.length > 6) {
                    line = line.slice(0, 6);
                } else {
                    for (let i = 0; i < 6; i++) {
                        if (!line[i]) {
                            line[i] = 'no data';
                        }
                    }
                }

                line.push(fileName);
                line.push(lineNum);
                line.push(
                    lineSpecificError[0]
                        .split(':')
                        .slice(2)
                        .join(' ')
                );
                errorFileContent.push({ ...line });
                setErrorFileContent([...errorFileContent]);

                // Don't bother with the rest of the checks since we don't know what
                // fields are actually here or in what order.
                lineNum += 1;
                lineSpecificError = [];
                continue;
            }

            // First name exists (0)
            if (!line[0]) {
                lineSpecificError.push(`${fileName}: line ${lineNum}: "First Name" missing.`);
                hasErrors = true;
            }

            // email exists and looks like an email (1)
            if (!line[1] || !isEmail(line[1])) {
                lineSpecificError.push(
                    `${fileName}: line ${lineNum}: Email Address "${
                        line[1]
                    }" is missing or doesn't look like a valid email.`
                );
                hasErrors = true;
            }

            // Brand is an actual brand (2)
            if (!line[2] || !(await findBrand(String(line[2]).toUpperCase()))) {
                lineSpecificError.push(
                    `${fileName}: line ${lineNum}: Brand "${line[2]}" is missing or can't be found.`
                );
                hasErrors = true;
            } else {
                // category belongs to the brand (3) (only check this if the brand check succeeds)
                if (!(await categoryBelongsToBrand(String(line[3]).toUpperCase(), String(line[2]).toUpperCase()))) {
                    lineSpecificError.push(
                        `${fileName}: line ${lineNum}: Category "${line[3]}" doesn't belong to brand "${line[2]}".`
                    );
                    hasErrors = true;
                }
            }

            // Category exists (category:brand matching happens above)
            if (!line[3]) {
                lineSpecificError.push(`${fileName}: line ${lineNum}: Category is missing.`);
                hasErrors = true;
            }

            // From name exists (4)
            if (!line[4]) {
                lineSpecificError.push(`${fileName}: line ${lineNum}: "From" name is missing.`);
                hasErrors = true;
            }

            // reply-to looks like an email (5)
            if (!line[5] || !isEmail(line[5])) {
                lineSpecificError.push(
                    `${fileName}: line ${lineNum}: "Reply-To Email Address" is missing or doesn't look like a valid email.`
                );
                hasErrors = true;
            }

            if (!hasErrors) {
                // eslint-disable-next-line
                setParsedData((prevState) => {
                    // isn't a duplicate of a line we already have
                    if (R.includes(line, prevState)) {
                        lineSpecificError.push(
                            `${fileName}: line ${lineNum}: Line with this data already exists.  Ignoring duplicates.`
                        );
                        hasErrors = true;
                        return prevState;
                    } else {
                        validatedLines.push(line);
                        return [...prevState, line];
                    }
                });
            }

            if (hasErrors) {
                errors.push(...lineSpecificError);

                // extract the various reason(s) for error in this line
                const allErrors = lineSpecificError.map((e) => {
                    return e.split(':').slice(2);
                });
                line.push(fileName);
                line.push(lineNum);
                line.push(allErrors.join(' '));
                errorFileContent.push({ ...line });
                setErrorFileContent([...errorFileContent]);
            }
            lineSpecificError = [];
            lineNum += 1;
        }
        return { validatedLines, errors };
    };

    /**
     * Do a brand search and see if we can find an exact match.  Return true if so, false if not.
     * @param searchBrand
     * @return if exact brand name was found
     */
    const findBrand = async (searchBrand) => {
        if (!searchBrand) {
            return false;
        }

        const svcResult = await brandExists(searchBrand);
        const ret = svcResult.status === 'success' && svcResult.data === true;
        return Boolean(ret);
    };

    /**
     * Checks to see that the provided category belongs to a given brand.
     * @type {{return: boolean}}
     */
    const categoryBelongsToBrand = async (searchCategory, brand) => {
        // Get the categories for a brand, then return true if this category is contained in it.
        if (!searchCategory || !brand) {
            return false;
        }
        const results = await getCategoriesForBrand(brand);
        const allCats = results.status === 'success' ? results.data || [] : [];
        return allCats.includes(searchCategory);
    };

    function isHeaderLine(l) {
        if (l === CSVheader) {
            return true;
        }
        if (l.length !== CSVheader.length) {
            return false;
        }
        for (const idx in CSVheader) {
            if (CSVheader[idx] !== l[idx]) {
                return false;
            }
        }
        return true;
    }

    const handleParsedFile = async (fileData, fileName) => {
        // This should be an array of "lines" shown below
        // "line": [first name, email, brand, category, from name, reply-to]
        // Add each "line" to the array of "lines" that we have.
        const bodyLines = (fileData || []).filter((line) => !isHeaderLine(line));

        // validate each line
        setCurrentProcessingMessage(`Processing ${fileName}...`);
        const { validatedLines, errors } = await validateLines(fileName, bodyLines);
        setErrorData((prevData) => {
            return [...prevData, ...errors];
        });
        addSuccessProcessingStatus(
            `${fileName} parsed. ${validatedLines.length} valid records, ${errors?.length || 0} errors.`
        );
        setCurrentProcessingMessage('');
        setIsProcessing(false);
    };

    const [emailButtonDisabled, setEmailButtonDisabled] = useState(false);
    useEffect(() => {
        const disabled = R.equals({}, selectedEmail) || parsedData?.length < 1 || isProcessing;
        setEmailButtonDisabled(disabled);
    }, [selectedEmail, isProcessing, parsedData]);

    return (
        <div id={styles['body']}>
            <div>
                <h1>On Demand Sales Alerts</h1>
                <hr />
                <div id={styles['overview']}>
                    <p>
                        Upload a list of email addresses and the relevant information for your sales prospects in order
                        to send them data tailored to the brand and category or your choosing. Please follow the
                        specific column format displayed below for the information so the information is pulled in
                        correctly. You can download a template or view the emails in the&nbsp;
                        <a
                            target={'_blank'}
                            rel={'noreferrer'}
                            href={'https://nielsenenterprise.sharepoint.com/:f:/s/Byzzer2/EviL7wQkYf9Hj60-oSIIioMBzxqkr4lD8ncceTJRk_SJ9A?e=ygHaVQ'}
                        >
                            On-Demand Sales Alerts folder
                        </a>
                        .
                    </p>
                </div>
            </div>

            <div id={styles['uploadContainer']}>
                <div id={styles['uploadControls']}>
                    <div>
                        {/* picker */}
                        <p className={styles.title}>Upload Sales Prospect List</p>
                        <p className={styles.subtitle}>Pick your email type</p>
                    </div>
                    <div id={styles['selectbox']}>
                        <Select
                            classNamePrefix={'react-select'}
                            onChange={handleSelectedEmail}
                            options={Constants.emailTypes}
                        />
                    </div>
                    <Dropzone
                        whenProcessing={setIsProcessing}
                        handleRejectFile={addErrorProcessingStatus}
                        handleParsedFile={handleParsedFile}
                        headerVals={CSVheader}
                    />
                    {sentMail ? (
                        <Link data-test="to-on-demand-list" className={general.link} to={`/ondemandalertsstatus`}>
                            Go To Alerts Status
                        </Link>
                    ) : (
                        <button
                            onClick={handleSendMailButtonClick}
                            disabled={emailButtonDisabled}
                            data-test={'send-email-button'}
                        >
                            Send Emails
                        </button>
                    )}
                    {currentProcessingMessage && (
                        <div className={general.statusMessage}>{currentProcessingMessage}</div>
                    )}
                    <ProcessingStatus messages={processingStatusMessages} />
                    {parsedData?.length > 0 && <p>{parsedData.length} emails to be sent.</p>}
                    {errorData?.length > 0 &&
                        errorData.map((err) => {
                            return (
                                <p key={err} className={styles.parseError}>
                                    {err}
                                </p>
                            );
                        })}
                    <div className={styles.columnar}>
                        <div>
                            {errorData?.length > 0 && (
                                <button
                                    data-test="clear-errors-btn"
                                    className={styles.smallButton}
                                    onClick={() => {
                                        setErrorData([]);
                                        setErrorFileContent([]);
                                    }}
                                >
                                    Clear Errors
                                </button>
                            )}
                        </div>
                        <div>
                            {errorData?.length > 0 && (
                                <button
                                    data-test="download-errors-btn"
                                    className={styles.smallButton}
                                    onClick={downloadErrors}
                                >
                                    Download Errors
                                </button>
                            )}
                        </div>
                        <div>
                            {processingStatusMessages?.length > 0 && (
                                <button
                                    data-test="clear-status-btn"
                                    className={styles.smallButton}
                                    onClick={() => setProcessingStatusMessages([])}
                                >
                                    Clear Status
                                </button>
                            )}
                        </div>
                        <div>
                            <div>
                                {parsedData?.length > 0 && (
                                    <button className={styles.smallButton} onClick={() => setParsedData([])}>
                                        Clear Emails
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div id={styles['uploadDocs']}>
                    <div id={styles['tips']}>
                        <span className={styles.title}>Upload Tips</span>
                        <ul>
                            <li>
                                The <span className={styles.item}>First Name</span> column is how the user will be
                                addressed in the email.
                            </li>
                            <li>
                                The <span className={styles.item}>Brand</span> column must match the brand name in the
                                data (and must be all caps). You can use the Profile page or run a Brand Ranking report
                                to identify a relevant brand and category combinations.
                            </li>
                            <li>
                                The <span className={styles.item}>Category</span> column must be upper case, match a
                                category in the data, and be associated with the&nbsp;
                                <span className={styles.item}>Brand</span> value.
                            </li>
                            <li>
                                The <span className={styles.item}>From</span> column is how the the email will be signed
                                and the email address is who the reply will be sent to. It is recommended these fields
                                are you or another sales associate.
                            </li>
                            <li>
                                Your uploaded file may contain a header row. If it does, the column headers must be
                                exactly, <span className={styles.item}>First Name</span>,&nbsp;
                                <span className={styles.item}>Email Address</span>, etc., as shown in the example. If
                                they differ, they will be considered "data" rows and of course will show an error in the
                                error listing since they won't be parse-able as valid data.
                            </li>
                        </ul>
                    </div>
                    <div id={styles['example']}>
                        <span className={styles.title}>Example File Upload</span>
                        <table>
                            <thead>
                                <tr>
                                    <th>First Name</th>
                                    <th>Email Address</th>
                                    <th>Brand</th>
                                    <th>Category</th>
                                    <th>From (Name)</th>
                                    <th>Reply-To Email Address</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Jane</td>
                                    <td>Jane.Doe@example.com</td>
                                    <td>BUZZ BEVERAGES (NEW AGE BEV CORP)</td>
                                    <td>SHELF STABLE BEVERAGES</td>
                                    <td>Sally Sales</td>
                                    <td>SallySales@nielsenieq.com</td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OnDemandAlerts;
