import React, { useState, useEffect } from 'react';
import generalStyles from '@/components/General/Styles.module.scss';
import styles from '@/components/BundleManager/BundleManager.module.scss';
import API_STATES from '@/constants/StateConstants';
import * as API from '@/services/api/product.service';
import BundleInfoContainer from '@/components/BundleManager/BundleInfoContainer';
import PropTypes from 'prop-types';
import { handleApiError } from '@/components/General/HandleApiError';

function BundleListItem(props) {
    const bundle = props.bundle;

    const [bundleDetailsApiStatus, setBundleDetailsApiStatus] = useState(API_STATES.none);
    const [bundleDetailsApiError, setBundleDetailsApiError] = useState('');

    const [bundleData, setBundleData] = useState({});
    const [bundleUpdated, setBundleUpdated] = useState(false);
    const [bundleSelected, setBundleSelected] = useState(false);

    async function getBundleDataBySku() {
        setBundleDetailsApiStatus(API_STATES.loading);
        const result = await API.getBundleDetails(bundle.bundleSku);
        switch (result.status) {
            case API_STATES.success:
                setBundleDetailsApiStatus(API_STATES.success);
                setBundleData(result.data);
                setBundleSelected(true);
                setBundleUpdated(false);
                break;
            case API_STATES.error:
                let error = result.error;
                handleApiError(error, setBundleDetailsApiStatus, setBundleDetailsApiError);
                break;
            default:
                setBundleDetailsApiError('');
                setBundleDetailsApiStatus(API_STATES.none);
        }
    }

    useEffect(() => {
        if (bundleUpdated) {
            getBundleDataBySku();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bundleUpdated]);

    return (
        <>
            <div className={styles.bundleListFlex}>
                <div>
                    <li className={generalStyles.link} onClick={getBundleDataBySku} data-test="bundle-list-item">
                        {bundle.bundleSku} : {bundle.bundleName}
                    </li>
                </div>
            </div>
            <div>
                <span>
                    {bundleDetailsApiStatus === API_STATES.error ? (
                        <p className={generalStyles.errorMessage}>Error: {bundleDetailsApiError}</p>
                    ) : bundleDetailsApiStatus === API_STATES.none ? (
                        <p className={generalStyles.statusMessage}></p>
                    ) : bundleDetailsApiStatus === API_STATES.loading ? (
                        <p className={generalStyles.statusMessage}>Loading...</p>
                    ) : bundleDetailsApiStatus === API_STATES.success ? (
                        <div className={styles.bundleInfoContainer}>
                            {bundleSelected && (
                                <BundleInfoContainer
                                    bundleSku={bundle.bundleSku}
                                    bundleInfo={bundleData}
                                    onBundleUpdate={() => setBundleUpdated(true)}
                                    onBundleNameUpdate={props.onBundleNameUpdate}
                                    onBundleDelete={props.onBundleDelete}
                                />
                            )}
                        </div>
                    ) : null}
                </span>
            </div>
        </>
    );
}

function BundleList(props) {
    return (
        <ol data-test="bundle-list" className={generalStyles.list}>
            {props.bundles.map((bundle) => {
                return (
                    <BundleListItem
                        key={bundle.bundleSku}
                        bundle={bundle}
                        onBundleDelete={props.onBundleDelete}
                        onBundleNameUpdate={props.onBundleNameUpdate}
                    />
                );
            })}
        </ol>
    );
}

BundleListItem.propTypes = {
    bundle: PropTypes.shape({
        bundleSku: PropTypes.string.isRequired,
        bundleName: PropTypes.string.isRequired,
    }).isRequired,
    onBundleDelete: PropTypes.func.isRequired,
    onBundleNameUpdate: PropTypes.func.isRequired,
};

BundleList.propTypes = {
    bundles: PropTypes.arrayOf(
        PropTypes.shape({
            bundleSku: PropTypes.string.isRequired,
            bundleName: PropTypes.string.isRequired,
        })
    ).isRequired,
    onBundleDelete: PropTypes.func.isRequired,
    onBundleNameUpdate: PropTypes.func.isRequired,
};

export default BundleList;
