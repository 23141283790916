import React, { useState, useEffect, useContext } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styles from '@/components/OboActionsByCompany/OboActionsByCompany.module.scss';
import calendarIcon from '@images/icons/calendar.svg';
import format from 'date-fns/format';
import { getOboActivityByCompany } from '@/services/api/report.service';
import generalStyles from '@/components/General/Styles.module.scss';
import API_STATES from '@/constants/StateConstants';
import { handleApiError } from '@/components/General/HandleApiError';
import EmptyTable from '@/components/General/EmptyTable';
import { companyPageContext } from '@/components/NewCompanyPage/NewCompanyPage';
import DataTable from '@/components/General/DataTable';
import { COLUMNS } from '@/components/OboActionsByCompany/OboActionTableColumns';
import { downloadAsCsv } from '../utils/utils';

function OboActivityTab(props) {
    const { nsCompanyId, companyConfig: { companyName, companyAccessLevel, isMultiCompany } } = useContext(companyPageContext);

    const [company, setCompany] = useState('');
    const [startDate, setStartDate] = useState(new Date(2021, 0, 1));
    const [endDate, setEndDate] = useState(new Date());
    const [startDateParam, setStartDateParam] = useState();
    const [endDateParam, setEndDateParam] = useState();

    const [oboActions, setOboActions] = useState([]);
    const [oboActionApiStatus, setOboActionApiStatus] = useState(API_STATES.none);
    const [oboActionApiError, setOboActionApiError] = useState('');

    const [downloading, setDownloading] = useState(API_STATES.none);
    const [enableDownload, setEnableDownload] = useState(false);

    function prettyDate(d) {
        if (!d) {
            return '';
        }
        const pretty = Date.parse(d);
        return format(pretty, 'MM/dd/yyyy hh:mm:ss');
    }

    const isDownloadEnabled = () => {
        return !enableDownload || startDate > endDate;
    };

    useEffect(() => {
        if (nsCompanyId !== 0) {
            setOboActionApiStatus(API_STATES.none);
            setStartDate(new Date(2021, 0, 1));
            setEndDate(new Date());
            setEnableDownload(false);
        }
    }, [nsCompanyId]);

    const isGenerateReportEnabled = () => {
        return (
            nsCompanyId === 0 ||
            nsCompanyId === undefined ||
            startDate > endDate ||
            oboActionApiStatus === API_STATES.loading
        );
    };

    async function getOboActivityDetails() {
        setOboActions([]);
        setOboActionApiStatus(API_STATES.loading);
        const result = await getOboActivityByCompany(nsCompanyId, startDateParam, endDateParam);
        switch (result.status) {
            case API_STATES.success:
                setOboActionApiStatus(API_STATES.success);
                setCompany(result?.data[0]?.company);
                const reportData = result.data.map((row) => {
                    return {
                        company: row.company,
                        user: row.user,
                        obo: row.obo,
                        obo_fname: row.obo_fname,
                        obo_lname: row.obo_lname,
                        when: prettyDate(row.when),
                        action: row.action,
                        api: row.api
                    };
                });
                setOboActions(reportData);
                result.data?.length > 0 ? setEnableDownload(true) : setEnableDownload(false);
                break;
            case API_STATES.error:
                const error = result.error;
                handleApiError(error, setOboActionApiStatus, setOboActionApiError, `No OBO actions found`);
                break;
            default:
                setOboActionApiError('');
                setOboActionApiStatus(API_STATES.none);
        }
    }

    function calculateStartDate(months) {
        let newDate = new Date(endDate);
        newDate.setMonth(newDate.getMonth() - months);
        setStartDate(newDate);
    }

    function downloadFile() {
        setDownloading(API_STATES.loading);
        const title = `OBO-Actions-${company}`;
        const heading =
            'Company, User Email, OBO Email, OBO First Name, OBO Last Name, Date and Time, Action Type, Product Activity\n';
        downloadAsCsv(title, heading, oboActions);
        setDownloading(API_STATES.success);
        setTimeout(() => setDownloading(API_STATES.none), 4000);
    }

    useEffect(() => {
        try {
            setStartDateParam(format(startDate, 'yyyy-MM-dd'));
        } catch (e) {
            const defaultStartDate = new Date(1970, 0, 1);
            setStartDate(defaultStartDate);
        }
    }, [startDate]);

    useEffect(() => {
        try {
            setEndDateParam(format(endDate, 'yyyy-MM-dd'));
        } catch (e) {
            const defaultEndDate = new Date(9999, 11, 31);
            setEndDate(defaultEndDate);
        }
    }, [endDate]);

    return (
        <>
            {nsCompanyId !== 0 ? (
                <>
                    <div>
                        <h2 style={{ color: 'black' }}>
                            {companyName} 
                            {isMultiCompany && <span className={generalStyles.statusMessage}>(multi company)</span>}
                        </h2>
                        <strong>{companyAccessLevel}</strong>
                    </div>
                    <br />
                    <div className={styles.rowflex}>
                        <div>
                            <div className={`${generalStyles.formField} ${styles.flexInner}`}>
                                <label className={styles.formItem}>From:</label>
                                <DatePicker
                                    name="startDate"
                                    placeholderText="mm/dd/yyyy"
                                    shouldCloseOnSelect={true}
                                    fixedHeight
                                    closeOnScroll={true}
                                    maxDate={new Date()}
                                    dateFormat="MM/dd/yyyy"
                                    selected={startDate}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    tabIndex={1}
                                    onChange={(date) => {
                                        setStartDate(date);
                                    }}
                                />
                                <img src={calendarIcon} alt="icon" />
                            </div>
                            <div className={`${generalStyles.formField} ${styles.flexInner}`}>
                                <label className={styles.formItem}>To:</label>
                                <DatePicker
                                    name="endDate"
                                    placeholderText="mm/dd/yyyy"
                                    shouldCloseOnSelect={true}
                                    fixedHeight
                                    closeOnScroll={true}
                                    maxDate={new Date()}
                                    dateFormat="MM/dd/yyyy"
                                    selected={endDate}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    tabIndex={1}
                                    onChange={(date) => {
                                        setEndDate(date);
                                    }}
                                />
                                <img src={calendarIcon} alt="icon" />
                            </div>
                        </div>
                        <div>
                            <p>
                                <button
                                    className={styles.timePeriodButton}
                                    onClick={(e) => calculateStartDate(1)}
                                    name={'last1month'}
                                >
                                    Last 1 month
                                </button>
                                <button
                                    className={styles.timePeriodButton}
                                    onClick={(e) => calculateStartDate(3)}
                                    name={'last3month'}
                                >
                                    Last 3 months
                                </button>
                                <button
                                    className={styles.timePeriodButton}
                                    onClick={(e) => calculateStartDate(6)}
                                    name={'last6month'}
                                >
                                    Last 6 months
                                </button>
                            </p>
                        </div>
                        <div>
                            <button
                                className={generalStyles.submitButton}
                                name={'downloadcsv'}
                                onClick={() => {
                                    getOboActivityDetails();
                                    setEnableDownload(false);
                                }}
                                disabled={isGenerateReportEnabled()}
                            >
                                Generate Report
                            </button>
                            <button
                                className={generalStyles.submitButton}
                                name={'downloadcsv'}
                                onClick={downloadFile}
                                disabled={isDownloadEnabled()}
                            >
                                Download Report
                            </button>
                            {downloading === API_STATES.loading ? (
                                <span className={generalStyles.statusMessage}>Downloading...</span>
                            ) : downloading === API_STATES.success ? (
                                <>
                                    <span className={generalStyles.statusMessage}>Downloading...</span>
                                </>
                            ) : null}
                            <br />
                            <br />
                        </div>
                    </div>
                    {oboActionApiStatus === API_STATES.error ? (
                        <p className={generalStyles.errorMessage}>Error: {oboActionApiError}</p>
                    ) : oboActionApiStatus === API_STATES.loading ? (
                        <p className={generalStyles.statusMessage}>Loading...</p>
                    ) : oboActionApiStatus === API_STATES.none ? (
                        ''
                    ) : oboActionApiStatus === API_STATES.success ? (
                        <>
                            <br />
                            {oboActions?.length > 0 ? (
                                <DataTable data={oboActions} columns={COLUMNS}/>
                            ) : (
                                <EmptyTable
                                    noValueDescription="No OBO actions found."
                                    columns={[
                                        'Company',
                                        'User Email',
                                        'OBO Email',
                                        'OBO First Name',
                                        'OBO Last Name',
                                        'Date and Time',
                                        'Action Type',
                                        'Product Activity'
                                    ]}
                                />
                            )}
                        </>
                    ) : null}
                </>
            ) : (
                <>
                    <br />
                    <span className={generalStyles.statusMessage}>Please select a company</span>
                </>
            )}
        </>
    );
}

export default OboActivityTab;
