import React from 'react';
import { useDropzone } from 'react-dropzone';
import uploadIcon from './cloud-upload.png';
import styles from './OnDemandAlerts.module.scss';
import PropTypes from 'prop-types';
import Papa from 'papaparse';
import xlsx from 'xlsx';

export function Dropzone(props) {
    /**
     * Handle file rejections detected by dropzone, by adding an error via
     * the error handler passed in via props.
     * @param props
     * @param rejectedFiles
     */
    const rejectFiles = (props, rejectedFiles) => {
        for (const rejectedFile of rejectedFiles) {
            for (const err of rejectedFile.errors) {
                switch (err?.code) {
                    case 'file-invalid-type':
                        props.handleRejectFile(
                            `${rejectedFile?.file?.name || 'unknown file'} is not a CSV or Spreadsheet type.`,
                        );
                        break;

                    default:
                        props.handleRejectFile(
                            `Error processing ${rejectedFile?.file?.name || 'unknown file name'}: ${err.message}`,
                        );
                }
            }
        }
    };

    /**
     * Process the accepted files
     * @param props
     * @param acceptedFiles
     */
    const processFiles = async (props, acceptedFiles) => {
        for (const file of acceptedFiles) {
            const type = file?.name?.split('.')?.pop();
            switch (type) {
                case 'csv':
                    processCSV(props, file);
                    break;

                case 'xls':
                case 'xlsx':
                    processXLS(props, file);
                    break;

                default:
                    break;
            }
        }
    };

    /**
     * Convert this CSV into:
     * [
     *   [element, element, ...],
     *   ...
     * ]
     * and handle that.
     *
     * @param props
     * @param file
     */
    const processCSV = async (props, file) => {
        Papa.parse(file, {
            skipEmptyLines: 'greedy',
            complete: async function(results) {
                await props.handleParsedFile(results.data, file?.name);
            },
        });
    };

    /**
     * Convert an XLS into the same thing the CSV process does, by first turning it into
     * a CSV string, then using the same process as CSV.
     * @param props
     * @param file
     * @return {JSX.Element}
     */
    const processXLS = async (props, file) => {
        const reader = new FileReader();
        reader.onload = async () => {
            const data = new Uint8Array(reader.result);
            const workbook = xlsx.read(data, { type: 'buffer' });
            const csvBlob = xlsx.utils.sheet_to_csv(workbook.Sheets[workbook.SheetNames[0]], { forceQuotes: true });
            const csvParseResult = Papa.parse(csvBlob, { header: false, skipEmptyLines: 'greedy' });
            const ret = csvParseResult?.data || [];
            await props.handleParsedFile(ret, file?.name || 'unknown-file.xls');
        };
        reader.readAsArrayBuffer(file);
    };

    const handleOnDrop = async (acceptedFiles, rejectedFiles) => {
        /*
        If a file is rejected here, add the file name and the reason to the "file errors"
        list.
         */
        props.whenProcessing(true);
        rejectFiles(props, rejectedFiles);
        await processFiles(props, acceptedFiles);
        // let the validation step unset the processing.
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop: handleOnDrop,
        accept: [
            '.xls',
            '.xlsx',
            '.csv',
            /*
            // Since we parse based on suffix, we should only allow those
            // here instead of mime types?
            'text/csv',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/vnd.ms-excel',
             */
        ],
    });

    return (
        <div
            {...getRootProps({
                className: styles.dropzone,
                'data-test': 'dropzone',
            })}
        >
            <input {...getInputProps()} />
            <p>
                <img src={uploadIcon} alt={'cloud upload jpeg'} />
            </p>
            <p>Drop CSV file or spreadsheet here or click to upload.</p>
        </div>
    );
}

Dropzone.propTypes = {
    handleRejectFile: PropTypes.func.isRequired,
    handleParsedFile: PropTypes.func.isRequired,
    headerVals: PropTypes.array.isRequired,
    whenProcessing: PropTypes.func.isRequired,
};
