import generalStyles from '@/components/General/Styles.module.scss';
import { MultiSelectColumnFilter } from '../General/MultiSelectColumnFilter';
import { FILTER_TYPES } from '../General/DataTable';
import { Link } from 'react-router-dom';
import { uniq } from 'lodash';

export const COLUMNS = [
    {
        Header: 'email Id',
        accessor: 'emailId',
        id: 'emailId',
        className: generalStyles.tableCell,
        Filter: MultiSelectColumnFilter,
        filter: FILTER_TYPES.multiple,
    },
    {
        Header: 'Company Name',
        accessor: 'companyName',
        id: 'companyName',
        className: generalStyles.tableCell,
        filter: FILTER_TYPES.multiple,
        Filter: MultiSelectColumnFilter,
        Cell: ({ row, value }) => (
            <Link className={generalStyles.link} to={`/newcompanypage/${row.original.nsCompanyId}`}>
                {value}
            </Link>
        ),
    },
    {
        Header: 'Role',
        accessor: 'role',
        id: 'role',
        className: generalStyles.tableCell,
        Filter: MultiSelectColumnFilter,
        filter: FILTER_TYPES.multiple,
    },
    {
        Header: 'Number of Alerts Allowed',
        accessor: 'numberOfAlertsAllowed',
        id: 'numberOfAlertsAllowed',
        className: `${generalStyles.tableCell} ${generalStyles.numeric}`,
        Filter: MultiSelectColumnFilter,
        filter: FILTER_TYPES.multiple,
    },
    {
        Header: 'Number of Alerts Setup',
        accessor: 'numberOfAlertsSetup',
        id: 'numberOfAlertsSetup',
        className: `${generalStyles.tableCell} ${generalStyles.numeric}`,
        Filter: MultiSelectColumnFilter,
        filter: FILTER_TYPES.multiple,
    },
    {
        Header: 'Alerts Setup',
        accessor: 'alertsSetup',
        id: 'alertsSetup',
        className: generalStyles.tableCell,
        Cell: ({value}) => {
            let formattedData = value ? Array.from(value.split(',')): []
            formattedData = uniq(formattedData).map((value)=>`${value} (${formattedData.filter((li)=>li===value).length})`).join(', ');
            return formattedData
        }
    },
    {
        Header: 'Default Brands',
        accessor: 'brands',
        id: 'brands',
        className: generalStyles.tableCell,
    },
    {
        Header: 'Default Characteristics',
        accessor: 'characteristicFilters',
        id: 'characteristicFilters',
        className: generalStyles.tableCell,
    },
    {
        Header: 'Default Category',
        accessor: 'categories',
        id: 'categories',
        className: generalStyles.tableCell,
    },
    {
        Header: 'Default Market',
        accessor: 'markets',
        id: 'markets',
        className: generalStyles.tableCell,
    },
    {
        Header: 'Default Time Period',
        accessor: 'timePeriod',
        id: 'timePeriod',
        className: generalStyles.tableCell,
        Filter: MultiSelectColumnFilter,
        filter: FILTER_TYPES.multiple,
    }
]