import React, { useState, useEffect } from 'react';
import DataTable from '@/components/General/DataTable';
import { CURRENT_QUOTE_COLUMNS, PAST_QUOTE_COLUMNS } from './AttachQuotesTableColumns';
import generalStyles from '@/components/General/Styles.module.scss';
import styles from './AttachQuotes.module.scss';
import ByzzerMultiSelectTextInput from '@/components/General/ByzzerMultiselectTextInput/ByzzerMultiSelectTextInput';
import { getQuoteIds, getAttachedQuoteUsers, deleteAttachedQuoteUsers, getAllTeamMembersByCompanyId, attachSelectedQuote, editSelectedQuote } from '@/services/api/company.service';
import API_STATES from '@/constants/StateConstants';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Select from 'react-select';
import format from 'date-fns/format';

function AttachQuotes(props) {
    const [updatedValues, setUpdatedValues] = useState();
    const [attachValues, setAttachValues] = useState();
    const [inputValue, setInputValue] = useState();
    const [quoteIdOptions, setQuoteIdOptions] = useState([]);
    const [selectedQuoteId, setSelectedQuoteId] = useState();
    const [attachedUsersList, setAttachedUsersList] = useState([]);
    const [pastQuoteUsersList, setPastQuoteUsersList] = useState([]);
    const [getExistingUsers, setGetExistingUsers] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [saveAttachedValues, setSaveAttachedValues] = useState([]);
    const [isUserDataLoading, setIsUserDataLoading] = useState(API_STATES.none)
    const [isAttachingQuote, setIsAttachingQuote] = useState(API_STATES.none);
    const [isUpdatingQuote, setIsUpdatingQuote] = useState(API_STATES.none)
    const [isDeletingQuote, setIsDeletingQuote] = useState(API_STATES.none)
    const [displayQuoteId, setDisplayQuoteId] = useState([]);

    function onAttachUsers(emailInputValue) {
        const uniqueValues = emailInputValue.filter((obj, index) => {
            return index === emailInputValue.findIndex(o => obj.label === o.label);
        });
        setAttachValues(uniqueValues)
    }
    function onEditUsers(emailInputValue) {
        const uniqueValues = emailInputValue.filter((obj, index) => {
            return index === emailInputValue.findIndex(o => obj.label === o.label);
        });
        setUpdatedValues(uniqueValues)
    }

    useEffect(() => {
        loadQuoteOptions();
        loadAttachedUserList();
        getExistingCompanyUsers();
    }, [props.nsCompanyId]);

    const onAttachQuoteId = (quoteIdValue) => {
        setSelectedQuoteId(quoteIdValue)
    }

    async function getExistingCompanyUsers() {
        try {
            // {email, authorizedUserId}
            let response = await getAllTeamMembersByCompanyId(props.nsCompanyId)
            if (response.status === API_STATES.success) {
                setGetExistingUsers(response?.data?.map((user) => ({ label: user?.email, value: user?.authorizedUserId })))
            } else {
                console.log('error in getting users', response.error)
            }
        } catch (error) {
            console.log(error)
        }
    }

    async function loadQuoteOptions() {
        try {
            let response = await getQuoteIds(props.nsCompanyId);
            if (response.status === API_STATES.success) {
                setQuoteIdOptions(response?.data.map((item) => ({ label: item.quoteId, value: item.quoteId })));
            } else {
                console.log('error in getting quote options', response.error)
            }
        } catch (error) {
            console.log(error)
        }
    }

    function formatDate(d) {
        if (!d) {
            return '';
        }
        const pretty = Date.parse(d);
        return format(pretty, 'MM/dd/yyyy hh:mm:ss');
    }

    async function loadAttachedUserList() {
        setIsUserDataLoading(API_STATES.loading)
        try {
            let response = await getAttachedQuoteUsers(props.nsCompanyId);
            if (response.status === API_STATES.success) {
                if (response.data.currentQuote) {
                    const currentUsersList = response?.data.currentQuote.users?.map((item)=>{
                        return {
                            email : item?.email,
                            status: item?.status
                        }
                    })
                    setSelectedQuoteId({ label: response.data.currentQuote.quoteID, value: response.data.currentQuote.quoteID })
                    setDisplayQuoteId({ label: response.data.currentQuote.quoteID, value: response.data.currentQuote.quoteID })
                    setAttachedUsersList(currentUsersList)
                    setUpdatedValues(response.data.currentQuote.users.map((row) =>({label: row?.email, value: row?.email })));
                } else{
                    setAttachedUsersList()
                }
                if (response.data.previousQuotes?.length) {
                    const sampleData = response.data.previousQuotes.map((item) => {
                        return {
                            quoteId: item.quoteID,
                            status: item.status,
                            date: item.acceptedDtm ? formatDate(item.acceptedDtm) : item?.expiredDtm ? formatDate(item.expiredDtm) : null,
                            acceptedUser: item?.acceptedUser
                        }
                    })
                    setPastQuoteUsersList(sampleData);
                } else{
                    setPastQuoteUsersList()
                }
                setIsUserDataLoading(API_STATES.success)
            } else {
                setIsUserDataLoading(API_STATES.error)
                console.log('error in getting attached quotes', response.error)
            }
        } catch (error) {
            setIsUserDataLoading(API_STATES.error)
            console.log(error);
        }
    }

    useEffect(() => {
        setSaveAttachedValues([])
        const attachedUsers = attachValues ?? updatedValues
        attachedUsers?.map((li_1)=>{
            const temp = getExistingUsers?.find((li)=>{return(li.label === li_1.label )})
            if(temp){
                return setSaveAttachedValues((prev) => [...prev, { email: temp?.label, authorizedUserId: temp?.value }]);
            } else{
                return setSaveAttachedValues((prev) => [...prev, { email: li_1.label }]);
            }
        })
    }, [attachValues, updatedValues])

    async function onSaveAttachedQuote() {
        try {
            setIsAttachingQuote(API_STATES.loading)
            let result = await attachSelectedQuote(saveAttachedValues, selectedQuoteId?.label, props.nsCompanyId);
            if (result.status === API_STATES.success) {
                setAttachValues()
                setSelectedQuoteId('')
                loadAttachedUserList()
                setIsAttachingQuote(API_STATES.success)
            } else {
                setIsAttachingQuote(API_STATES.error)
                console.log('error in getting attaching quote to users', result.error)
            }
        } catch (error) {
            console.log(error)
            setIsAttachingQuote(API_STATES.error)
        }
    }

    async function onUpdateAttachedQuote() {
        try {
            setIsUpdatingQuote(API_STATES.loading)
            let response = await editSelectedQuote(saveAttachedValues, selectedQuoteId?.label, props.nsCompanyId);
            if (response.status === API_STATES.success) {
                loadAttachedUserList()
                setOpenModal(false)
                setIsUpdatingQuote(API_STATES.success)
            } else {
                setIsUpdatingQuote(API_STATES.error)
                console.log('error in updating attached quotes', response.error)
            }
        } catch (e) {
            console.log(e)
            setIsUpdatingQuote(API_STATES.error)
        }
    }

    function onInputValueChange(value) {
        setInputValue(value);
    }

    function isDomainMatching() {
        const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        const emailDomain = inputValue.split('@')[1];
        if (!inputValue.match(mailformat) || !props.companyDomain?.includes(emailDomain)) {
            return false;
        } else {
            return true;
        }
    }

    function onEditQuote() {
        setOpenModal(true)
    }

    async function onDetachAllUsers() {
        try {
            setIsDeletingQuote(API_STATES.loading)
            const response = await deleteAttachedQuoteUsers(props.nsCompanyId)
            if (response.status === API_STATES.success) {
                loadAttachedUserList()
                setAttachValues([])
                setSelectedQuoteId()
                setIsDeletingQuote(API_STATES.success)
            } else {
                setIsDeletingQuote(API_STATES.error)
                console.log('error in getting deleting quotes', response.error)
            }
        } catch (error) {
            console.log(error)
            setIsDeletingQuote(API_STATES.error)
        }
    }

    return (
        <>
            {isUserDataLoading === API_STATES.loading &&
                <div className={generalStyles.statusMessage}>Loading Users...</div>
            }
            {
                isUserDataLoading === API_STATES.error &&
                <div className={generalStyles.error}>Error while loading attached users</div>
            }
            {isAttachingQuote === API_STATES.loading &&
                <div className={generalStyles.statusMessage}>Attaching Quote...</div>
            }
            {isUpdatingQuote === API_STATES.loading &&
                <div className={generalStyles.statusMessage}>Updating Quote...</div>
            }
            {isDeletingQuote === API_STATES.loading &&
                <div className={generalStyles.statusMessage}>Deleting Quote...</div>
            }
            {!attachedUsersList?.length && !(isUserDataLoading === API_STATES.loading) &&
                <div className={styles.AttachQuotesSection}>
                    <p>
                        <b>Attach Quote: </b>
                    </p>
                    <div className={styles.AttachQuotesContainer}>
                        <div className={styles.inputFieldContainer}>
                            <b>Email </b>
                            <span className={styles.AttachQuoteInputField}>
                                <ByzzerMultiSelectTextInput
                                    disabled={!props.isEditable}
                                    isValueMatching={isDomainMatching}
                                    inputValue={inputValue}
                                    setInputValue={setInputValue}
                                    onInputValueChange={onInputValueChange}
                                    onChange={onAttachUsers}
                                    value={attachValues}
                                    getOptions={getExistingUsers}
                                    errorMessage={`Please enter valid company domain (${props.companyDomain?.map((item) => " " + item)})`}
                                />
                            </span>
                        </div>
                        <div className={styles.inputFieldContainer}>
                            <b>Quote # </b>
                            <span className={styles.AttachQuoteInputField}>
                                <Select
                                    isDisabled={!props.isEditable}
                                    options={quoteIdOptions}
                                    onChange={onAttachQuoteId}
                                    value={selectedQuoteId}
                                />
                            </span>
                        </div>
                        <button className={generalStyles.submitButton} onClick={onSaveAttachedQuote} disabled={!(selectedQuoteId?.label && attachValues?.length > 0 && props.isEditable)}>
                            Save
                        </button>
                    </div>
                </div>
            }

            { attachedUsersList?.length > 0 &&
                <>
                    <div className={styles.AttachQuotesSection}>
                        <b>Active Quote ID &nbsp;<b className={styles.displayQuote}> {displayQuoteId?.label} </b></b>
                        {props.isEditable &&
                            <><span><button className={generalStyles.submitButton} onClick={onEditQuote}>Edit</button></span>
                                <span><button className={generalStyles.submitButton} onClick={onDetachAllUsers}>Delete All</button></span>
                            </>
                        }

                    </div>
                    <div className={generalStyles.AttachedQuoteUsersList}>
                        <h3 className={styles.tableHeading}>Active Quote Status</h3>
                        <DataTable data={attachedUsersList} columns={CURRENT_QUOTE_COLUMNS} />
                    </div>
                </>
            }
            { pastQuoteUsersList?.length > 0 &&
                <div className={generalStyles.AttachedQuoteUsersList}>
                    <h3 className={styles.tableHeading}>Inactive Quotes Status</h3>
                    <DataTable data={pastQuoteUsersList} columns={PAST_QUOTE_COLUMNS} />
                </div>
            }
                
            <Popup
                open={openModal}
                onClose={() => {
                    setOpenModal(false);
                }}
                modal
            >
                {() => (
                    <div className={''}>
                        <p>
                            <b>Edit Quote: </b>
                        </p>
                        <div className={styles.UpdateQuotesContainer}>
                            <div>
                                <b>Email </b>
                                <span>
                                    <ByzzerMultiSelectTextInput
                                        disabled={!props.isEditable}
                                        isValueMatching={isDomainMatching}
                                        inputValue={inputValue}
                                        setInputValue={setInputValue}
                                        onInputValueChange={onInputValueChange}
                                        onChange={onEditUsers}
                                        value={updatedValues}
                                        getOptions={getExistingUsers}
                                        errorMessage={`Please enter valid company domain (${props.companyDomain?.map((item) => " " + item)})`}
                                    />
                                </span>
                            </div>
                            <br />
                            <div>
                                <b>Quote # </b>
                                <span>
                                    <Select
                                        isDisabled={!props.isEditable}
                                        options={quoteIdOptions}
                                        onChange={onAttachQuoteId}
                                        value={selectedQuoteId}
                                    />
                                </span>
                            </div>
                            <button className={generalStyles.submitButton} onClick={onUpdateAttachedQuote} disabled={!(selectedQuoteId?.label && updatedValues?.length > 0 && props.isEditable)}>
                                {' '}
                                Update{' '}
                            </button>
                        </div>
                    </div>
                )}
            </Popup>
        </>
    );
}
export default AttachQuotes;
