import React, { useEffect, useState } from 'react';
import API_STATES from '@/constants/StateConstants';
import { addMultiYearAgreement, getMyaDetails, terminateMultiYearAgreement, updateTrueContractEndDate } from '@/services/api/company.service';
import styles from '@/components/NewCompanyPage/NewCompanyPage.module.scss';
import generalStyles from '@/components/General/Styles.module.scss';
import { handleApiError, noOp } from '@/components/General/HandleApiError';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import calendarIcon from '@images/icons/calendar.svg';
import Popup from 'reactjs-popup';
import { dateToIsoDateString, isoDateStringToDate } from '../utils/utils';

const EDIT_MODES = {
    addMYA: 'addMYA',
    terminateMYA: 'terminateMYA',
    editTrueContractEndDate: 'editTrueContractEndDate',
    none: '',
};

function MultiYearAgreement({ nsCompanyId, isEditable = true, refreshTimestamp, onChange = noOp }) {
    const [hasMultiYearAgreement, setHasMultiYearAgreement] = useState(false);
    const [hasSubscription, setHasSubscription] = useState(false);
    const [trueContractEndDate, setTrueContractEndDate] = useState(null);


    const [getMyaDetailsApiState, setGetMyaDetailsApiState] = useState(API_STATES.none);
    const [getMyaDetailsApiError, setGetMyaDetailsApiError] = useState('');

    const [trueContractEndDateInput, setTrueContractEndDateInput] = useState(null);

    const [currentEditMode, setCurrentEditMode] = useState(EDIT_MODES.none);
    const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
    const [updateMyaDetailsApiState, setUpdateMyaDetailsApiState] = useState(API_STATES.none);
    const [updateMyaDetailsApiError, setUpdateMyaDetailsApiError] = useState('');

    useEffect(() => {
        setTrueContractEndDateInput(trueContractEndDate)
    }, [trueContractEndDate])

    const handleAddMyaClick = () => {
        setTrueContractEndDateInput(trueContractEndDate ?? new Date())
        setCurrentEditMode(EDIT_MODES.addMYA)
    }

    const handleEditTrueContractEndDateClick = () => {
        setTrueContractEndDateInput(trueContractEndDate ?? new Date())
        setCurrentEditMode(EDIT_MODES.editTrueContractEndDate)
    }

    const handleTerminateMya = () => {
        setCurrentEditMode(EDIT_MODES.terminateMYA)
    }

    const handleCancel = () => {
        setCurrentEditMode(EDIT_MODES.none)
    }

    const loadMyaDetails = async (nsCompanyId) => {
        setGetMyaDetailsApiState(API_STATES.loading)
        const response = await getMyaDetails(nsCompanyId)
        if (response.status === API_STATES.success) {
            const { myaDetails } = response.data
            if (myaDetails) {
                setHasSubscription(true)
                setHasMultiYearAgreement(myaDetails.hasMultiYearAgreement)
                setTrueContractEndDate(isoDateStringToDate(myaDetails.trueContractEndDate))
            } else {
                setHasSubscription(false)
            }
            setGetMyaDetailsApiState(API_STATES.success)
        } else {
            handleApiError(response.error, setGetMyaDetailsApiState, setGetMyaDetailsApiError)
        }
    }

    const onMyaDetailsChange = (nsCompanyId) => {
        loadMyaDetails(nsCompanyId)
        onChange()
    }

    const submitAddMyaRequest = async (nsCompanyId, trueContractEndDate) => {
        trueContractEndDate = dateToIsoDateString(trueContractEndDate)
        if (!trueContractEndDate) {
            setUpdateMyaDetailsApiState(API_STATES.error)
            setUpdateMyaDetailsApiError('Invalid true contract end date')
            setTimeout(() => setUpdateMyaDetailsApiState(API_STATES.none), 5000)
            return
        }
        setUpdateMyaDetailsApiState(API_STATES.loading)
        const response = await addMultiYearAgreement(nsCompanyId, trueContractEndDate)
        if (response.status === API_STATES.success) {
            setUpdateMyaDetailsApiState(API_STATES.success)
            onMyaDetailsChange(nsCompanyId)
            setTimeout(() => {
                setCurrentEditMode(EDIT_MODES.none)
                setUpdateMyaDetailsApiState(API_STATES.none)
            }, 3000)
        } else {
            handleApiError(response.error, setUpdateMyaDetailsApiState, setUpdateMyaDetailsApiError)
            setTimeout(() => setUpdateMyaDetailsApiState(API_STATES.none), 5000)
        }
    }

    const validateTrueContractEndDate = (nsCompanyId, trueContractEndDateInput, trueContractEndDate) => {
        if(trueContractEndDateInput < trueContractEndDate) {
            setShowConfirmationPopup(true)
            return
        }
        submitUpdateTrueContractEndDateRequest(nsCompanyId, trueContractEndDateInput)
    }

    const submitUpdateTrueContractEndDateRequest = async (nsCompanyId, trueContractEndDate) => {
        setShowConfirmationPopup(false)
        trueContractEndDate = dateToIsoDateString(trueContractEndDate)
        if (!trueContractEndDate) {
            setUpdateMyaDetailsApiState(API_STATES.error)
            setUpdateMyaDetailsApiError('Invalid true contract end date')
            setTimeout(() => setUpdateMyaDetailsApiState(API_STATES.none), 5000)
            return
        }
        setUpdateMyaDetailsApiState(API_STATES.loading)
        const response = await updateTrueContractEndDate(nsCompanyId, trueContractEndDate)
        if (response.status === API_STATES.success) {
            setUpdateMyaDetailsApiState(API_STATES.success)
            onMyaDetailsChange(nsCompanyId)
            setTimeout(() => {
                setCurrentEditMode(EDIT_MODES.none)
                setUpdateMyaDetailsApiState(API_STATES.none)
            }, 3000)
        } else {
            handleApiError(response.error, setUpdateMyaDetailsApiState, setUpdateMyaDetailsApiError)
            setTimeout(() => setUpdateMyaDetailsApiState(API_STATES.none), 5000)
        }
    }

    const submitTerminateMyaRequest = async (nsCompanyId) => {
        setUpdateMyaDetailsApiState(API_STATES.loading)
        const response = await terminateMultiYearAgreement(nsCompanyId)
        if (response.status === API_STATES.success) {
            setUpdateMyaDetailsApiState(API_STATES.success)
            onMyaDetailsChange(nsCompanyId)
            setTimeout(() => {
                setCurrentEditMode(EDIT_MODES.none)
                setUpdateMyaDetailsApiState(API_STATES.none)
            }, 3000)
        } else {
            handleApiError(response.error, setUpdateMyaDetailsApiState, setUpdateMyaDetailsApiError)
            setTimeout(() => setUpdateMyaDetailsApiState(API_STATES.none), 5000)
        }
    }

    useEffect(() => {
        loadMyaDetails(nsCompanyId)
    }, [nsCompanyId, refreshTimestamp])

    if (currentEditMode === EDIT_MODES.addMYA) {
        return <div>
            <div className={generalStyles['input-wrapper']}>
                <label className={generalStyles.longLabel}>Has Multi Year Agreement:</label>
                <b className={generalStyles.formField}>
                    <span className={generalStyles.successMessage}>Yes</span>
                </b>
            </div>
            <div className={generalStyles['input-wrapper']}>
                <label className={generalStyles.longLabel}>True Contract End Date:</label>
                <span className={generalStyles.formField}>
                    <div className={styles.dateContainer}>
                        <DatePicker
                            name="trueContractEndDate"
                            placeholderText="mm/dd/yyyy"
                            shouldCloseOnSelect={true}
                            fixedHeight
                            closeOnScroll={true}
                            dateFormat="MMM d, y"
                            selected={trueContractEndDateInput}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            tabIndex={1}
                            disabled={!isEditable || updateMyaDetailsApiState === API_STATES.loading}
                            onChange={(date) => setTrueContractEndDateInput(date)}
                        />
                        <img src={calendarIcon} alt="calendar icon" />
                    </div>
                </span>
            </div>
            <div className={generalStyles.row}>
                <button
                    className={generalStyles.submitButton}
                    onClick={()=>submitAddMyaRequest(nsCompanyId, trueContractEndDateInput)}
                    disabled={
                        !isEditable ||
                        !trueContractEndDateInput ||
                        updateMyaDetailsApiState === API_STATES.loading ||
                        updateMyaDetailsApiState === API_STATES.success
                    }
                >
                    Submit
                </button>
                {
                    updateMyaDetailsApiState === API_STATES.loading ? (
                        <span className={generalStyles.statusMessage}>
                            Adding Multi Year Agreement...
                        </span>
                    ) : updateMyaDetailsApiState === API_STATES.error ? (
                        <span className={generalStyles.errorMessage}>
                            {updateMyaDetailsApiError}
                        </span>
                    ) : updateMyaDetailsApiState === API_STATES.success ? (
                        <span className={generalStyles.successMessage}>
                            Successfully added Multi Year Agreement
                        </span>
                    ) : <button
                        className={generalStyles.submitButton}
                        onClick={handleCancel}
                        disabled={updateMyaDetailsApiState === API_STATES.loading}
                    >
                        Cancel
                    </button>
                }
            </div>
        </div>
    } else if (currentEditMode === EDIT_MODES.editTrueContractEndDate) {
        return <>
            <div>
                <div className={generalStyles['input-wrapper']}>
                    <label className={generalStyles.longLabel}>Has Multi Year Agreement:</label>
                    <b className={generalStyles.formField}>
                        {hasMultiYearAgreement ?
                            <span className={generalStyles.successMessage}>Yes</span> :
                            <span className={generalStyles.errorMessage}>No</span>
                        }
                    </b>
                </div>
                <div className={generalStyles['input-wrapper']}>
                    <label className={generalStyles.longLabel}>True Contract End Date:</label>
                    <span className={generalStyles.formField}>
                        <div className={styles.dateContainer}>
                            <DatePicker
                                name="trueContractEndDate"
                                placeholderText="mm/dd/yyyy"
                                shouldCloseOnSelect={true}
                                fixedHeight
                                closeOnScroll={true}
                                dateFormat="MMM d, y"
                                selected={trueContractEndDateInput}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                tabIndex={1}
                                disabled={!isEditable || updateMyaDetailsApiState === API_STATES.loading}
                                onChange={(date) => setTrueContractEndDateInput(date)}
                            />
                            <img src={calendarIcon} alt="calendar icon" />
                        </div>
                    </span>
                </div>
                <div className={generalStyles.row}>
                    <button
                        className={generalStyles.submitButton}
                        onClick={()=>validateTrueContractEndDate(nsCompanyId, trueContractEndDateInput, trueContractEndDate)}
                        disabled={
                            !isEditable ||
                            !trueContractEndDateInput ||
                            updateMyaDetailsApiState === API_STATES.loading ||
                            updateMyaDetailsApiState === API_STATES.success
                        }
                    >
                        Save
                    </button>
                    {
                        updateMyaDetailsApiState === API_STATES.loading ?
                            <span className={generalStyles.statusMessage}>
                                Updating true contract end date...
                            </span>
                        : updateMyaDetailsApiState === API_STATES.error ?
                            <span className={generalStyles.errorMessage}>
                                {updateMyaDetailsApiError}
                            </span>
                        : updateMyaDetailsApiState === API_STATES.success ?
                            <span className={generalStyles.successMessage}>
                                Successfully updated true contract end date
                            </span>
                        : <button
                            className={generalStyles.submitButton}
                            onClick={handleCancel}
                        >
                            Cancel
                        </button>
                    }
                </div>
            </div>
            <Popup
                open={showConfirmationPopup}
                onClose={() => setShowConfirmationPopup(false)}
                modal
            >
                {() => (
                    <div>
                        You are setting the true contract end date to be earlier than the current value. Please make sure that you want to reduce the number of days of access for this company.
                        <br />
                        <div className={styles.buttonContainer}>
                            <button className={generalStyles.confirmOperationButton} onClick={() => submitUpdateTrueContractEndDateRequest(nsCompanyId, trueContractEndDateInput)}>Submit</button>
                            <button className={generalStyles.cancelOperationButton} onClick={() => setShowConfirmationPopup(false)}>Cancel</button>
                        </div>
                    </div>
                )}
            </Popup>
        </>
    } else {
        return <div>
            {
                getMyaDetailsApiState === API_STATES.loading ?
                    <span className={generalStyles.statusMessage}>
                        Loading Multi Year Agreement Details
                    </span>
                : getMyaDetailsApiState === API_STATES.error ?
                    <span className={generalStyles.errorMessage}>
                        {getMyaDetailsApiError}
                    </span>
                : !hasSubscription ?
                    <span>Company doesn't have subscription</span>
                : <div>
                    <div className={generalStyles['input-wrapper']}>
                        <label className={generalStyles.longLabel}>Has Multi Year Agreement:</label>
                        <b className={generalStyles.formField}>
                            {hasMultiYearAgreement ?
                                <span className={generalStyles.successMessage}>Yes</span> :
                                <span className={generalStyles.errorMessage}>No</span>
                            }
                        </b>
                    </div>
                    <div className={generalStyles['input-wrapper']}>
                        <label className={generalStyles.longLabel}>True Contract End Date:</label>
                        <span className={generalStyles.formField}>
                            {trueContractEndDate?.toDateString() || 'N/A'}
                        </span>
                    </div>
                    <div className={generalStyles.row}>
                        {hasMultiYearAgreement ?
                            <button
                                className={generalStyles.confirmOperationButton}
                                onClick={handleTerminateMya}
                                disabled={!isEditable || updateMyaDetailsApiState === API_STATES.loading}
                            >
                                Terminate MYA
                            </button>
                            : <button
                                className={generalStyles.submitButton}
                                onClick={handleAddMyaClick}
                                disabled={!isEditable || updateMyaDetailsApiState === API_STATES.loading}
                            >
                                Add MYA
                            </button>
                        }
                        <button
                            className={generalStyles.submitButton}
                            onClick={handleEditTrueContractEndDateClick}
                            disabled={!isEditable || updateMyaDetailsApiState === API_STATES.loading}
                        >
                            Edit True Contract End Date
                        </button>
                    </div>
                </div>
            }

            {/** terminate mya confirmation popup */}
            <Popup
                open={currentEditMode === EDIT_MODES.terminateMYA}
                onClose={handleCancel}
                modal
            >
                {() => (
                    <div>
                        You are terminating the multi year contract. This will set their access end date to the current contract end date. Do you want to proceed?
                        <br />
                        <div className={styles.buttonContainer}>
                            <button 
                                className={generalStyles.confirmOperationButton} 
                                onClick={() => submitTerminateMyaRequest(nsCompanyId, trueContractEndDateInput)}
                                disabled={
                                    !isEditable ||
                                    updateMyaDetailsApiState === API_STATES.loading ||
                                    updateMyaDetailsApiState === API_STATES.success
                                }
                            >
                                Submit
                            </button>
                            {
                                updateMyaDetailsApiState === API_STATES.loading ?
                                    <span className={generalStyles.statusMessage}>
                                        Terminating Multi Year Agreement...
                                    </span>
                                : updateMyaDetailsApiState === API_STATES.error ?
                                    <span className={generalStyles.errorMessage}>
                                        {updateMyaDetailsApiError}
                                    </span>
                                : updateMyaDetailsApiState === API_STATES.success ?
                                    <span className={generalStyles.successMessage}>
                                        Successfully terminated multi year agreement
                                    </span>
                                : <button
                                    className={generalStyles.submitButton}
                                    onClick={handleCancel}
                                >
                                    Cancel
                                </button>
                            }
                        </div>
                    </div>
                )}
            </Popup>
        </div>
    }
}

export default MultiYearAgreement;
