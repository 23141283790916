import axios from 'axios';
import firebase from 'firebase/app';
import 'firebase/auth';
import API_STATES from '@/constants/StateConstants';

const http = axios.create({
    baseURL: import.meta.env.REACT_APP_BASEURL,
});

/*
Watches auth token state changes to keep the request header in sync
*/

http.interceptors.request.use(async (config) => {
    if (firebase.auth().currentUser) {
        config.headers.Authorization = `Bearer ${await firebase.auth().currentUser.getIdToken()}`;
    }
    return config;
});

export async function getReportRuns(startDate, endDate, includeTestAccounts) {
    try {
        const res = await http.get(`/report/report_runs`, {
            params: { start_date: startDate, end_date: endDate, concat: 'true', include_test_accounts: includeTestAccounts },
        });
        return { status: API_STATES.success, data: res.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function getPurchasedItemsByCompany(nsCompanyId) {
    try {
        const res = await http.get(`/report/purchased_products/${nsCompanyId}`);
        return { status: API_STATES.success, data: res.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function getUnusedCredit(includeTestAccounts, showActiveSubscriptionsOnly, excludeDisabledCompanies) {
    try {
        const res = await http.get(`/report/unused_credits`, {
            params: { includeTestAccounts, showActiveSubscriptionsOnly, excludeDisabledCompanies }
        });
        return { status: API_STATES.success, data: res.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function getUserStatus(includeTestAccounts, showActiveSubscriptionsOnly) {
    try {
        const res = await http.get(`/report/user_status`, {
            params : {
                include_test_accounts: includeTestAccounts,
                show_active_subscriptions_only: showActiveSubscriptionsOnly
            }
        });
        return { status: API_STATES.success, data: res.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function getOboActivityByCompany(companyid, startDate, endDate) {
    try {
        const res = await http.get(`/report/obo_actions/${companyid}`, {
            params: { startDate, endDate }
        });
        return { status: API_STATES.success, data: res.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function getCategoryLicenseReport(filterType, items, subscriptionTypes, showActiveContracts, includeTestAccounts) {
    try {
        const response = await http.put(`/report/category_license`, { filterType, items, subscriptionTypes, showActiveContracts, include_test_accounts: includeTestAccounts });
        return { status: API_STATES.success, data: response.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function getReportRunsSelectionHistory(startDate, endDate, includeTestAccounts) {
    try {
        const res = await http.get(`/report/report_runs_selections`, {
            params: { start_date: startDate, end_date: endDate, concat: 'true', include_test_accounts: includeTestAccounts },
        });
        return { status: API_STATES.success, data: res.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function getApprovedMarketsReport(retailerIds, onlyActiveContracts, showTestAccount) {
    try {
        const response = await http.post(`/report/approved_markets`, { retailerIds, onlyActiveContracts, showTestAccount });
        return { status: API_STATES.success, data: response.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function getDemoAccountsReport() {
    try {
        const response = await http.post(`/report/demo_accounts`);
        return { status: API_STATES.success, data: response.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function getCompanyBrandReport() {
    try {
        const response = await http.post(`/report/company_brand`);
        return { status: API_STATES.success, data: response.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}
export async function getCategoryEventsReport() {
    try {
        const response = await http.get(`/report/category_change_events_report`);
        return { status: API_STATES.success, data: response.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function getFreeAccountsReport(){
    try {
        const response  = await http.get(`/report/free_accounts_report`);
        return {status: API_STATES.success, data: response?.data};
    } catch(error) {
        return {status: API_STATES.error, error: error};
    }
}

export async function getReportFilters(){
    try {
        const response = await http.get(`/report/report_runs_selections_filters`)
        return {status: API_STATES.success, data: response?.data};

    } catch(error){
        return { status: API_STATES.error, error: error };
    } 
 }


export async function getUserSelectionReport(startDate, endDate, filterData ){
    try {
        const response = await http.get(`report/report_runs_selections_new`,{
            params: { 
                startDate: startDate, 
                endDate: endDate, 
                ...(filterData?.includeTestAccounts && {showTestAccount: filterData?.includeTestAccounts ?? ''}),
                ...(filterData?.userEmail?.length && {userEmails: filterData?.userEmail ?? ''}),
                ...(filterData?.includeTestAccounts && {showTestAccount: filterData?.includeTestAccounts ?? ''}),
                ...(filterData?.charDimension?.length && {characteristicDimensions: filterData?.charDimension ?? ''}),
                ...(filterData?.category?.length && {categorySelections:  filterData?.category}),
                ...(filterData?.brand?.length && {brandSelections:  filterData?.brand ?? ''}),
                ...(filterData?.market?.length && {marketSelections: filterData?.market}),
                ...(filterData?.characteristics?.length && {characteristicFilters: filterData?.characteristics ?? ''}),
                ...(filterData?.facts?.length && {factsSelections: filterData?.facts ?? ''}),
                ...(filterData?.reportName?.length && {reportNames: filterData?.reportName ?? ''}),
                ...(filterData?.reportType?.length && {reportTypes: filterData?.reportType ?? ''}),
                ...(filterData?.company?.length && {companyNames: filterData?.company}),
                ...(filterData?.summedCategory && {hasSummedCategories: filterData?.summedCategory}),      
                ...(filterData?.summedBrand && {hasSummedBrands: filterData?.summedBrand}),      
                ...(filterData?.summedMarket && {hasSummedMarkets: filterData?.summedMarket}),     
                ...(filterData?.summedTimePeriod && {hasSummedTimePeriods: filterData?.summedTimePeriod})      
            },
            paramsSerializer: params => {
                return Object.entries(params)
                  .map(([key, value]) => {
                    if (Array.isArray(value) && value.length > 0) {
                      return value.map(v => `${encodeURIComponent(key)}=${encodeURIComponent(v)}`).join('&');
                    } else {
                      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
                    }
                  })
                  .join('&');
              },
            
            
        })
        return {status: API_STATES.success, data: response?.data};
    } catch(error){
        return {status: API_STATES.error, error:error}
    }
}

export async function getDomainsInvitationReport() {
    try {
        const response = await http.get(`/report/domain_mismatch_invitation`);
        return { status: API_STATES.success, data: response.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function getByzzerAccessEndDatesReport() {
    try {
        const response = await http.post(`/report/access_end_dates`);
        return { status: API_STATES.success, data: response.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function getUserLevelReportData(showActiveContracts){
    try {
        const response = await http.get(`/report/user_level_report`, {
            params: { showActiveContracts }
        });
        return { status: API_STATES.success, data: response.data };
    } catch(error) {
        return { status: API_STATES.error, error: error };
    }
}

