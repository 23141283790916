import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import './Spin.scss';

const antIcon = <LoadingOutlined style={{ fontSize: 30 }} spin />;

const Loader = (props) => {
    return (
        <div className="byzzer-component__spin-conatiner">
            <Spin indicator={antIcon} />
            <br />
            <span style={{ marginTop: '2vh', fontSize: '2vw', fontWeight: 'bold' }}>Loading...</span>
        </div>
    );
};
export default Loader;
