import React from 'react';

function ReportDashboard(props) {
    return (
        <>
            <h1>User/Company Reports</h1>
            <hr />

        </>
    );
}

export default ReportDashboard;
