import React, { useState } from 'react';
import styles from './SetupNewCompany.module.scss';
import generalStyles from '@/components/General/Styles.module.scss';
import { purchaseProducts } from '@/services/api/company.service';
import API_STATES from '@/constants/StateConstants';
import { handleApiError } from '@/components/General/HandleApiError';

function Cart(props) {
    const [purchaseOrderApiState, setPurchaseOrderApiState] = useState(API_STATES.none);
    const [purchaseOrderApiError, setPurchaseOrderApiError] = useState('');
    // const [disableFurtherPurchase, setDisableFurtherPurchase] = useState(false);
    // const [companyHasPurchase, setCompanyHasPurchase] = useState(props.companyHasPurchase);

    // Enabling further purchase for now....
    // useEffect(() => {
    //     setCompanyHasPurchase(props.companyHasPurchase);
    // }, [props.companyHasPurchase]);

    async function placeOrder() {
        setPurchaseOrderApiState(API_STATES.loading);
        if (props.items.some((item) => item.type === 'Subscription')) {
            // to match the required req object structure
            const modifiedItems = props.items.map((item) => {
                return {
                    nsCatalogItemId: item.nsCatalogItemId,
                    sku: item.sku,
                    quantity: item.quantity,
                    price: item.type === 'Report' ? Number(item.annualPrice) : null,
                    rate: item.type === 'Report' ? null : Number(item.monthlyPrice),
                    ttl: '',
                    addons: item.addons.map((addon) => {
                        return {
                            nsCatalogItemId: addon.ns_catalog_item_id,
                            sku: addon.sku,
                            quantity: addon.quantity,
                            price: item.type === 'Report' ? Number(addon.annualPrice) : null,
                            rate: item.type === 'Report' ? null : Number(addon.monthlyPrice)
                        };
                    })
                };
            });
            const newItems = modifiedItems.map((item) => {
                if (item.price === null) {
                    return {
                        nsCatalogItemId: item.nsCatalogItemId,
                        sku: item.sku,
                        quantity: item.quantity,
                        rate: item.rate,
                        ttl: '',
                        addons:item.addons.map((addon) => {
                            if (addon.price === null) {
                                return {
                                    nsCatalogItemId: addon.nsCatalogItemId,
                                    sku: addon.sku,
                                    quantity: addon.quantity,
                                    rate: addon.rate
                                };
                            } else if (addon.rate === null) {
                                return {
                                    nsCatalogItemId: addon.nsCatalogItemId,
                                    sku: addon.sku,
                                    quantity: addon.quantity,
                                    price: addon.price
                                };
                            } else {
                                return {};
                            }
                        })
                    };
                } else if (item.rate === null) {
                    return {
                        nsCatalogItemId: item.nsCatalogItemId,
                        sku: item.sku,
                        quantity: item.quantity,
                        price: item.price,
                        ttl: '',
                        addons: item.addons.map((addon) => {
                            if (addon.price === null) {
                                return {
                                    nsCatalogItemId: addon.nsCatalogItemId,
                                    sku: addon.sku,
                                    quantity: addon.quantity,
                                    rate: addon.rate
                                };
                            } else if (addon.rate === null) {
                                return {
                                    nsCatalogItemId: addon.nsCatalogItemId,
                                    sku: addon.sku,
                                    quantity: addon.quantity,
                                    price: addon.price
                                };
                            } else {
                                return {};
                            }
                        })
                    };
                } else {
                    return {};
                }
            });
            if (props.contractEnd < props.contractStart) {
                setPurchaseOrderApiState(API_STATES.error);
                setPurchaseOrderApiError('Error: Invalid contract dates range!');
                setTimeout(() => setPurchaseOrderApiState(API_STATES.none), 3000);
            } else {
                // if (companyHasPurchase) {
                //     setPurchaseOrderApiState(API_STATES.error);
                //     setPurchaseOrderApiError('Error: Company has existing purchase!');
                //     setTimeout(() => setPurchaseOrderApiState(API_STATES.none), 3000);
                // } else {
                const response = await purchaseProducts(props.nsCompanyId, newItems, {
                    createNew: true,
                    startDtm: props.contractStart,
                    endDtm: props.contractEnd,
                    billingFrequency: props.billingFrequency,
                    paymentTerms: props.netsuitePaymentTerm,
                    isEvergreen: props.isEvergreen,
                    dynamicsDealNumber: props.dynamicsDealNum,
                    isMultiYearAgreement: props.isMultiYearAgreement,
                    trueContractEndDtm: props.isMultiYearAgreement ? props.trueContractEndDate : undefined,
                    isAddon: false
                });
                switch (response.status) {
                    case API_STATES.success:
                        setPurchaseOrderApiState(API_STATES.success);
                        //setDisableFurtherPurchase(true);
                        setTimeout(() => setPurchaseOrderApiState(API_STATES.none), 3000);
                        setTimeout(() => props.updatedPurchases(), 10000);
                        break;
                    case API_STATES.error:
                        let error = response.error;
                        handleApiError(error, setPurchaseOrderApiState, setPurchaseOrderApiError);
                        break;
                    default:
                        setPurchaseOrderApiError('');
                        setPurchaseOrderApiState(API_STATES.none);
                }
                // }
            }
        } else {
            setPurchaseOrderApiState(API_STATES.error);
            setPurchaseOrderApiError('Error: Subscription needs to be selected!');
            setTimeout(() => setPurchaseOrderApiState(API_STATES.none), 3000);
        }
    }

    return (
        <>
            {props.items.length > 0 ? (
                props.items.map((item) => (
                    <>
                        <div className={styles.cart}>
                            <>
                                <h5>Parent Product:</h5>
                                <div className={styles.child}>
                                    <b>Type: </b>
                                    {item.type}
                                    <br />
                                    <b>Sku: </b>
                                    {item.sku}
                                    <br />
                                    <b>Title: </b> {item.label}{' '}
                                    <b>
                                        <br />
                                        Quantity:
                                    </b>
                                    {item.quantity}
                                    <br />
                                    {item.type !== 'Report' ? (
                                        <>
                                            <b>Monthly Price: </b>${parseFloat(item.monthlyPrice).toFixed(2)}/month
                                            <br />
                                            <b>Annual Price: </b>$
                                            {parseFloat(item.annualPrice / item.quantity).toFixed(2)}/year
                                        </>
                                    ) : (
                                        <>
                                            <b>Annual Price: </b>${parseFloat(item.annualPrice).toFixed(2)}/year
                                        </>
                                    )}
                                </div>

                                <h5>Child Addon(s):</h5>
                                {item?.addons?.length > 0 ? (
                                    item.addons?.map((c) => (
                                        <>
                                            <div className={styles.child}>
                                                <b>Sku: </b>
                                                {c.sku}
                                                <br />
                                                <b>Title: </b>
                                                {c.label}
                                                <br />
                                                <b>Quantity: </b>
                                                {c.quantity} <br />
                                                {item.type !== 'Report' ? (
                                                    <>
                                                        <b>Monthly Price: </b>${parseFloat(c.monthlyPrice).toFixed(2)}
                                                        /month
                                                        <br />
                                                        <b>Annual Price: </b>$
                                                        {parseFloat(Number(c.annualPrice / c.quantity)).toFixed(2)}
                                                        /year
                                                    </>
                                                ) : (
                                                    <>
                                                        <b>Annual Price: </b>${parseFloat(c.annualPrice).toFixed(2)}
                                                        /year
                                                    </>
                                                )}
                                                <br />
                                                <br />
                                            </div>
                                        </>
                                    ))
                                ) : (
                                    <div className={styles.child}>
                                        <span className={generalStyles.statusMessage}>No Addons selected</span>
                                    </div>
                                )}
                                
                            </>

                            <div className={styles.billHeading}>
                                <div>
                                    <button
                                        className={generalStyles.smallButton}
                                        onClick={() => {
                                            props.removeCartItem(item.sku);
                                        }}
                                        //disabled={disableFurtherPurchase}
                                    >
                                        Remove Item
                                    </button>
                                    <button
                                        className={generalStyles.smallButton}
                                        onClick={() => {
                                            props.removeCartItem(item.sku);
                                            props.setEditableItem({ ...item, edit: true });
                                        }}
                                        //disabled={disableFurtherPurchase}
                                    >
                                        Edit Item
                                    </button>
                                </div>
                                <div>
                                    <b>Item Total: ${item.sectionTotal}</b>
                                </div>
                            </div>
                        </div>
                    </>
                ))
            ) : (
                <p className={generalStyles.statusMessage}>Your cart is empty!!!</p>
            )}
            <button
                className={`${generalStyles.submitButton}`}
                // disabled={props.items.length <= 0 || disableFurtherPurchase}
                disabled={props.items.length <= 0}
                onClick={placeOrder}
            >
                Place Order
            </button>
            <span>
                {purchaseOrderApiState === API_STATES.loading && (
                    <label className={generalStyles.statusMessage}>Processing your order...</label>
                )}
                {purchaseOrderApiState === API_STATES.error && (
                    <label className={generalStyles.errorMessage}>{purchaseOrderApiError}</label>
                )}
                {purchaseOrderApiState === API_STATES.success && (
                    <label className={generalStyles.successMessage}>
                        Successfully placed order! Please proceed with next step below...
                    </label>
                )}
            </span>
        </>
    );
}
export default Cart;
