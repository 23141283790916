import React, { KeyboardEventHandler, useState } from "react";
import CreatableSelect from "react-select/creatable";
import './ByzzerMultiSelectTextInput.scss'
import classnames from "classnames";

export type ByzzerMultiSelectTextInputProps = {
    className?: string;
    onChange: (selectedOptions: readonly Option[]) => void;
    value: readonly Option[];
    onInputValueChange?: (value?: string) => void;
    inputValue?: string;
    setInputValue?: any;
    isValueMatching?: any;
    getOptions?: readonly Option[];
    errorMessage?: string
    disabled?: boolean;
    isMenuOpen?: boolean
};
const baseClassName = "byzzer-multiselect-input";
export interface Option {
    readonly label: string;
    readonly value: string;
}

export function ByzzerMultiSelectTextInput({
    className,
    onChange,
    onInputValueChange,
    value = [],
    inputValue,
    setInputValue,
    getOptions,
    isValueMatching = true,
    errorMessage,
    isMenuOpen,
    disabled = false
}: ByzzerMultiSelectTextInputProps) {
    const createOption = (label: string) => ({ label, value: label.toLowerCase().replace(/\W/g, '') });
    const [error, setError] = useState<boolean>(false);
    const handleKeyDown: KeyboardEventHandler = (event) => {
        if (!inputValue) return;
        switch (event.key) {
            case "Enter":
                if (isValueMatching?.() === false) {
                    setInputValue("");
                    setError(true);
                } else {
                    onChange([...value, createOption(inputValue)]);
                    setInputValue("");
                    setError(false);
                }
                event.preventDefault();
        }
    };

    const handleCreate = (inputValue: string) => {
        if (isValueMatching?.() === false) {
            setInputValue("");
            setError(true)
        } else {
            onChange([...value, createOption(inputValue)]);
            setInputValue("");
            setError(false);
        }
    };

    return (
        <>
            <div>
                {isMenuOpen === false ?
                    <CreatableSelect
                        menuIsOpen={isMenuOpen}
                        className={classnames(className, { [`${baseClassName}--error`]: error, })}
                        classNamePrefix={baseClassName}
                        inputValue={inputValue}
                        isClearable
                        isMulti
                        onChange={(selectedOptions) => onChange(selectedOptions)}
                        onInputChange={(newValue) => onInputValueChange?.(newValue)}
                        onKeyDown={handleKeyDown}
                        placeholder="Add email and press enter..."
                        value={value}
                        isDisabled={disabled}
                    /> :
                    <CreatableSelect
                        hideSelectedOptions={true}
                        options={getOptions}
                        className={classnames(className, { [`${baseClassName}--error`]: error, })}
                        classNamePrefix={baseClassName}
                        inputValue={inputValue}
                        onCreateOption={handleCreate}
                        isClearable
                        isMulti
                        openMenuOnFocus={false}
                        openMenuOnClick={false}
                        closeMenuOnSelect={false}
                        closeMenuOnScroll={true}
                        onChange={(selectedOptions) => onChange(selectedOptions)}
                        onInputChange={(newValue) => onInputValueChange?.(newValue)}
                        onKeyDown={handleKeyDown}
                        placeholder="Add email and press enter..."
                        value={value}
                        isDisabled={disabled}
                    />
                }
            </div>
            {error &&
                <div className={`${baseClassName}__error-message`}>{errorMessage}</div>
            }
        </>

    );
}
export default ByzzerMultiSelectTextInput;
