import React, { useEffect } from 'react';
import { useState } from 'react';
import DataTable from '../General/DataTable';
import API_STATES from '@/constants/StateConstants';
import generalStyles from '@/components/General/Styles.module.scss';
import {COLUMNS} from './Columns'
import { handleApiError } from '../General/HandleApiError';
import { getDomainsInvitationReport } from '@/services/api/report.service';

const DomainsInvitationReport = () => {
    const [reportApiStatus, setReportApiStatus] = useState(API_STATES.none);
    const [reportApiError, setReportApiError] = useState('');
    const [reportResult, setReportResult] = useState([]);

    async function loadDomainsInvitationReport() {
        setReportApiStatus(API_STATES.loading);
        const result = await getDomainsInvitationReport();
        switch (result.status) {
            case API_STATES.success:
                setReportApiStatus(API_STATES.success);
                setReportResult(result.data);
                break;
            case API_STATES.error:
                handleApiError(result.error, setReportApiStatus, setReportApiError);
                break;
            default:
                setReportApiStatus(API_STATES.none);
                setReportApiError('');
        }
    }


    useEffect(() => {
        loadDomainsInvitationReport();
    }, []);

    return (
        <>
            <h1>Domains Invitation Report</h1>
            <hr />
            <div data-test="generate-report-status">
                {reportApiStatus === API_STATES.error ? (
                    <p className={generalStyles.errorMessage}>Error: {reportApiError}</p>
                ) : reportApiStatus === API_STATES.loading ? (
                    <p className={generalStyles.statusMessage}>Loading...</p>
                ) : (
                    reportApiStatus === API_STATES.success && (
                        <DataTable columns={COLUMNS} data={reportResult} withDownload downloadFileName={'Domain_Invitation_Report'} />
                    )
                )}
            </div>
        </>
    )
}

export default DomainsInvitationReport