import './ByzzerCSRModal.scss';
import React, { useEffect, useRef } from 'react';
import { create as createModal,hexGen } from 'react-modal-promise';
import ByzzerButton  from '@/components/ByzzerButton';
import classnames from 'classnames';
import CloseIcon from '@/images/icons/closeIcon.svg';
import warningIcon from '@images/icons/warningIcon.svg';
import { isFunction } from 'lodash';

/**
 *
 * @param headerType string
 * default: error
 * acceptedValues: ["error", "normal"]
 * what if we set header Type as error ?
 * If we set the headerType as error it will display the modal exclusively for errors UI
 * what if we set header Type as normal ?
 * If we set the headerType as normal it will display the modal layout as clean
 *
 * @param show boolean
 * default: true
 * if set to true, modal will pop up
 * if set to false, modal will hide
 *
 * @param size string
 * default: small
 * values: small and large
 * small defines pop up width and height for smaller content
 * large defines popup width and height for larger content
 */
const ByzzerModal = ({
    onClick,
    heading,
    show = false,
    headerType = 'error',
    contentType = 'default',
    children,
    type = 'default',
    onClose = onClick,
    closeOnClickOutside = false,
    closeButtonText = 'close',
    size = 'small',
    extraIcon,
    className = '',
    ...props
}) => {

    const ref = useRef();

    useEffect(() => {
        if (show === true) document.body.style.position = 'absolute';
        else document.body.style.position = 'unset';
    }, [show]);

    const wrapperClassNames = classnames(className, {
        'byzzer-modal-wrapper': true,
        'byzzer-modal-wrapper--visible': show,
        [`byzzer-modal-wrapper--${type}`]: true,
    });

    const headerClassNames = classnames({
        [`byzzer-modal-header--${headerType}`]: true,
        [`byzzer-modal-header-br`]: heading ? true : false,
    });

    const modalClassNames = classnames({
        [`byzzer-modal--${size}`]: true,
    });

    const onClickOutside = (event) => {
        if (!ref.current.contains(event.target)) {
            return onClose();
        }
    };

    return (
        <div className={wrapperClassNames} {...props} onClick={closeOnClickOutside ? onClickOutside : undefined}>
            <div className={`byzzer-modal ${modalClassNames}`} ref={ref}>
                <header className={`byzzer-modal-header ${headerClassNames}`}>
                    <h1>{heading}</h1>
                    {extraIcon && <div className={'byzzer-modal-header__extraIcon'}>{extraIcon}</div>}
                    <div className={`byzzer-modal-header-close`}>
                        <img src={CloseIcon} alt={'close'} onClick={onClose} />
                    </div>
                </header>
                <main
                    className={'byzzer-modal-content'}
                >
                    {children}
                </main>
            </div>
        </div>
    );
};

export function ByzzerModalMask({ className, show, children, ...props }) {
    if (show) {
        return (
            <div className={classnames('byzzer-modal__loading-mask', className)} {...props}>
                <svg
                    className={'byzzer-modal__loading-spinner'}
                    viewBox="0 0 100 100"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <circle pathLength={1} cx="50" cy="50" r="45" />
                </svg>
                {children && <div className={'byzzer-modal__loading-text'}>{children}</div>}
            </div>
        );
    }

    return <></>;
}

export function openModal({ id = hexGen(), ...props }) {
    const promise = createModal(
        ({ isOpen, onResolve, onReject, close, instanceId, title, content, className, ...props }) => {
            return (
                <ByzzerModal className={className} heading={title} show={isOpen} onClose={onResolve} {...props}>
                    {isFunction(content) ? content({ close, cancel: onReject, id: instanceId }) : content}
                </ByzzerModal>
            );
        }
    )({
        instanceId: id,
        ...props,
    });

    promise.id = id;
    promise.resolve = () => {
        window.byzzerModals.resolve(id);
    };
    promise.reject = () => {
        window.byzzerModals.reject(id);
    };
    return promise;
}

export const confirm = createModal(
    ({ onResolve, title, content, yesLabel = 'Yes', noLabel = 'No', closeButton = false, className, ...props }) => {
        const wrapperClassNames = classnames('byzzer-modal-wrapper', 'byzzer-modal-wrapper--visible');
        return (
            <div className={wrapperClassNames}>
                <div className={`byzzer-confirm ${className}`}>
                    <header className={`byzzer-confirm__header`}>
                        <h1>{title}</h1>
                        {closeButton && (
                            <div className={`byzzer-confirm__header-close`}>
                                <img src={CloseIcon} alt={'close'} onClick={() => onResolve(false)} />
                            </div>
                        )}
                    </header>
                    <main className={'byzzer-confirm__content'}>{content}</main>
                    <footer className={'byzzer-confirm__footer'}>
                        <ByzzerButton onClick={() => onResolve(true)}>{yesLabel}</ByzzerButton>
                        <ByzzerButton onClick={() => onResolve(false)} type="negative">
                            {noLabel}
                        </ByzzerButton>
                    </footer>
                </div>
            </div>
        );
    }
);

export const alert = createModal(({ onResolve, type, title, content, okLabel = 'Ok', className, ...props }) => {
    const wrapperClassNames = classnames('byzzer-modal-wrapper', 'byzzer-modal-wrapper--visible');

    const modalClasses = classnames(className, `byzzer-alert`, {
        [`byzzer-alert--${type}`]: Boolean(type),
    });
    return (
        <div className={wrapperClassNames}>
            <div className={modalClasses}>
                {Boolean(title) && (
                    <header className={`byzzer-alert__header`}>
                        <h1>{title}</h1>
                    </header>
                )}
                <main className={'byzzer-alert__content'}>{content}</main>
                <footer className={'byzzer-alert__footer'}>
                    <ByzzerButton onClick={() => onResolve(true)}>{okLabel}</ByzzerButton>
                </footer>
            </div>
        </div>
    );
});

export function warn(message, { isTip = true, id = hexGen(), ...props } = {}) {
    const promise = createModal(
        ({ isOpen, onResolve, title, content, className, message, size = 'small', isTip = true, ...props }) => {
            return (
                <ByzzerModal show={isOpen} onClose={onResolve} size={size} headerType={'none'} contentType={'warning'}>
                    <div className={'byzzer-error-modal__container'}>
                        <img className={'byzzer-error-modal__container-icon'} src={warningIcon} alt={'warning'} />
                        <span className={'byzzer-error-modal__container-text'}>
                            {isTip && <b>Tip:&nbsp;</b>}
                            <div className="byzzer-label">{message}</div>
                        </span>
                    </div>
                </ByzzerModal>
            );
        }
    )({
        instanceId: id,
        message,
        isTip,
        ...props,
    });

    promise.id = id;

    return promise;
}

export const useModals = () => ({
    openModal,
    confirm,
    alert,
});

export default ByzzerModal;
