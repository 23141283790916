import axios from 'axios';
import firebase from 'firebase/app';
import 'firebase/auth';
import API_STATES from '@/constants/StateConstants';

const http = axios.create({
    baseURL: import.meta.env.REACT_APP_BASEURL,
});

/*
Watches auth token state changes to keep the request header in sync
*/

http.interceptors.request.use(async (config) => {
    if (firebase.auth().currentUser) {
        config.headers.Authorization = `Bearer ${await firebase.auth().currentUser.getIdToken()}`;
    }
    return config;
});

export async function getSkuList() {
    try {
        const response = await http.get(`/data_refresh_maintenance/sku`);
        return { status: API_STATES.success, data: response.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function getScheduleList() {
    try {
        const response = await http.get(`/data_refresh_maintenance/schedule`);
        return { status: API_STATES.success, data: response.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function getSchedule(scheduleId) {
    try {
        const response = await http.get(`/data_refresh_maintenance/schedule/${scheduleId}`);
        return { status: API_STATES.success, data: response.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function deleteDataMaintenanceSchedule(scheduleId) {
    try {
        const response = await http.delete(`/data_refresh_maintenance/${scheduleId}`);
        return { status: API_STATES.success, data: response.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function saveSchedule(payload) {
    try {
        const response = await http.post(`/data_refresh_maintenance`, payload);
        return { status: API_STATES.success, data: response.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function editSchedule(payload) {
    try {
        const response = await http.put(`/data_refresh_maintenance`, payload);
        return { status: API_STATES.success, data: response.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}
