import React, { useState, useEffect } from 'react'
import { COLUMNS } from './Columns'
import DataTable from '../General/DataTable';
import { getCategoryEventsReport } from '@/services/api/report.service';
import { handleApiError } from '../General/HandleApiError';
import API_STATES from '@/constants/StateConstants';
import generalStyles from '@/components/General/Styles.module.scss';

const CategoryChangeEvents = () => {
    const [reportApiStatus, setReportApiStatus] = useState(API_STATES.none);
    const [reportApiError, setReportApiError] = useState('');
    const [reportResult, setReportResult] = useState([]);

    useEffect(() => {
        loadCategoryChangeEventsReport()
    }, [])

    async function loadCategoryChangeEventsReport() {
        setReportApiStatus(API_STATES.loading);
        const result = await getCategoryEventsReport();
        switch (result.status) {
            case API_STATES.success:
                setReportApiStatus(API_STATES.success);
                setReportResult(result.data);
                break;
            case API_STATES.error:
                handleApiError(result.error, setReportApiStatus, setReportApiError);
                break;
            default:
                setReportApiStatus(API_STATES.none);
                setReportApiError('');
        }
    }

    return (
        <>
            <h1>Category Change Events</h1>
            <hr />
            {reportApiStatus === API_STATES.error ? (
                <p className={generalStyles.errorMessage}>Error: {reportApiError}</p>
            ) : reportApiStatus === API_STATES.loading ? (
                <p className={generalStyles.statusMessage}>Loading...</p>
            ) : (
                reportApiStatus === API_STATES.success && (
                    <DataTable
                        columns={COLUMNS}
                        data={reportResult}
                        withDownload
                        downloadFileName={'Category_Change_Events_Download'}
                    />

                )
            )}
        </>
    )
}

export default CategoryChangeEvents