import React, { useState } from 'react';
import isEmail from 'is-email';
import * as userDataApi from '@/services/api/userdata.service';
import * as companyApi from '@/services/api/company.service';
import API_STATES from '@/constants/StateConstants';
import generalStyles from '@/components/General/Styles.module.scss';
import { handleApiError } from '@/components/General/HandleApiError';
import styles from '@/components/NewCompanyPage/NewCompanyPage.module.scss';

export default function UserActions({ email, nsCompanyId, isConsultant }) {
    const [sendInviteApiStatus, setSendInviteApiStatus] = useState(API_STATES.none);
    const [sendInviteApiError, setSendInviteApiError] = useState('');

    const [verifyEmailApiStatus, setVerifyEmailApiStatus] = useState(API_STATES.none);
    const [verifyEmailApiError, setVerifyEmailApiError] = useState('');

    const [fetchLinkApiStatus, setFetchLinkApiStatus] = useState(API_STATES.none);
    const [fetchLinkApiError, setFetchLinkApiError] = useState('');

    const [link, setLink] = useState('');
    const [copyStatus, setCopyStatus] = useState('');

    const [verifyEmailCopyApiStatus, setVerifyEmailCopyApiStatus] = useState(API_STATES.none);
    const [verifyEmailCopyApiError, setVerifyEmailCopyApiError] = useState('');

    const [sendEmailApiStatus, setSendEmailApiStatus] = useState(API_STATES.none);
    const [sendEmailApiError, setSendEmailApiError] = useState('');

    const [invitationLinkApiStatus,setInvitationLinkApiStatus] = useState(API_STATES.none)
    const [invitationLinkApiError,setInvitationLinkApiError] = useState('');

    async function verifyEmailForVerification() {
        setSendInviteApiStatus(API_STATES.none);
        setVerifyEmailApiStatus(API_STATES.none);
        setFetchLinkApiStatus(API_STATES.none);
        setSendEmailApiStatus(API_STATES.none);
        //
        setVerifyEmailCopyApiStatus(API_STATES.loading);
        const result = await userDataApi.getFirebaseUser(email.toLowerCase());
        switch (result.status) {
            case API_STATES.success:
                setVerifyEmailCopyApiStatus(API_STATES.success);
                resendVerificationEmail();
                break;
            case API_STATES.error:
                let error = result.error;
                handleApiError(
                    error,
                    setVerifyEmailCopyApiStatus,
                    setVerifyEmailCopyApiError,
                    `The email ${email} is not a registered email in the Byzzer system.`
                );
                setTimeout(() => setVerifyEmailCopyApiStatus(API_STATES.none), 3000);
                break;
            default:
                setVerifyEmailCopyApiStatus(API_STATES.none);
                setVerifyEmailCopyApiError('');
        }
    }

    async function resendVerificationEmail() {
        setSendInviteApiStatus(API_STATES.none);
        setVerifyEmailApiStatus(API_STATES.none);
        setFetchLinkApiStatus(API_STATES.none);
        setSendInviteApiStatus(API_STATES.none);
        // setVerifyEmailCopyApiStatus(API_STATES.none);
        //
        setSendEmailApiStatus(API_STATES.loading);
        const result = await userDataApi.resendVerificationEmail(email.toLowerCase());
        switch (result.status) {
            case API_STATES.success:
                setSendEmailApiStatus(API_STATES.success);
                break;
            case API_STATES.error:
                let error = result.error;
                setSendEmailApiStatus(API_STATES.error);
                switch (error) {
                    case 401:
                        setSendEmailApiError('CSR is not logged in.');
                        break;
                    case 403:
                        setSendEmailApiError('Unauthorised to perform this action');
                        break;
                    case 404:
                        setSendEmailApiError(
                            `No email verification record was found for ${email}. This could be either because the initial email was never sent due to an error, or the email was sent and the user has already verified it.`
                        );
                        break;
                    default:
                        setSendEmailApiError('Unknown error in sending validation email');
                        break;
                }
                setTimeout(() => setSendEmailApiStatus(API_STATES.none), 3000);

                break;
            default:
                setSendEmailApiStatus(API_STATES.none);
                setSendEmailApiError('');
        }
    }

    async function resendInvitationMail() {
        setVerifyEmailCopyApiStatus(API_STATES.none);
        setVerifyEmailApiStatus(API_STATES.none);
        setFetchLinkApiStatus(API_STATES.none);
        setSendEmailApiStatus(API_STATES.none);
        //
        setSendInviteApiStatus(API_STATES.loading);
        if (email && !isEmail(email)) {
            setSendInviteApiStatus(API_STATES.error);
            setSendInviteApiError('Invalid Email Format!');
        } else {
            const result = await companyApi.resendInvitationEmail(email.toLowerCase(), nsCompanyId);
            switch (result.status) {
                case API_STATES.success:
                    setSendInviteApiStatus(API_STATES.success);
                    break;
                case API_STATES.error:
                    let error = result.error;
                    handleApiError(
                        error,
                        setSendInviteApiStatus,
                        setSendInviteApiError,
                        `Invitation Record for email ${email} not found`
                    );
                    setTimeout(() => setSendInviteApiStatus(API_STATES.none), 3000);
                    break;
                default:
                    setSendInviteApiStatus(API_STATES.none);
                    setSendInviteApiError('');
            }
        }
    }

    async function verifyEmail() {
        setVerifyEmailCopyApiStatus(API_STATES.none);
        setSendInviteApiStatus(API_STATES.none);
        setFetchLinkApiStatus(API_STATES.none);
        setSendEmailApiStatus(API_STATES.none);
        //
        setVerifyEmailApiStatus(API_STATES.loading);
        const result = await userDataApi.getFirebaseUser(email);
        switch (result.status) {
            case API_STATES.success:
                setVerifyEmailApiStatus(API_STATES.success);
                getChangePasswordLink();
                break;
            case API_STATES.error:
                let error = result.error;
                handleApiError(
                    error,
                    setVerifyEmailApiStatus,
                    setVerifyEmailApiError,
                    `The email ${email} is not a registered email in the Byzzer system.`
                );
                setTimeout(() => setVerifyEmailApiStatus(API_STATES.none), 3000);
                break;
            default:
                setVerifyEmailApiStatus(API_STATES.none);
                setVerifyEmailApiError('');
        }
    }

    async function getChangePasswordLink() {
        setVerifyEmailCopyApiStatus(API_STATES.none);
        setSendInviteApiStatus(API_STATES.none);
        setVerifyEmailApiStatus(API_STATES.none);
        setSendEmailApiStatus(API_STATES.none);
        //
        setFetchLinkApiStatus(API_STATES.loading);
        const result = await userDataApi.getResetPasswordLink(email);
        switch (result.status) {
            case API_STATES.success:
                setFetchLinkApiStatus(API_STATES.success);
                setLink(result.data);
                break;
            case API_STATES.error:
                let error = result.error;
                handleApiError(
                    error,
                    setFetchLinkApiStatus,
                    setFetchLinkApiError,
                    `${email} is not a valid byzzer customer`
                );
                setTimeout(() => setFetchLinkApiStatus(API_STATES.none), 3000);

                break;
            default:
                setFetchLinkApiStatus(API_STATES.none);
                setFetchLinkApiError('');
        }
    }

    async function getInvitationLink() {
        //
        setInvitationLinkApiStatus(API_STATES.loading);
        const result = await companyApi.getInvitationLink(email.toLowerCase(), nsCompanyId);
        console.log(result);
        switch (result.status) {
            case API_STATES.success:
                setInvitationLinkApiStatus(API_STATES.success);
                navigator.clipboard.writeText(result.data);
                setCopyStatus('Copied!');
                setTimeout(() => setCopyStatus(''), 1000);
                break;
            case API_STATES.error:
                let error = result.error;
                handleApiError(
                    error,
                    setInvitationLinkApiStatus,
                    setInvitationLinkApiError,
                    `Invitation Record for ${email} not found`
                );
                setTimeout(() => setInvitationLinkApiStatus(API_STATES.none), 3000);
                break;
            default:
                setInvitationLinkApiStatus(API_STATES.none);
                setInvitationLinkApiError('');
        }
    }
    return (
        <>
            <span className={styles.row}>
                <button className={generalStyles.submitButton} onClick={resendInvitationMail}>
                    ReSend Invite
                </button>
                <button className={generalStyles.submitButton} onClick={verifyEmail} disabled={isConsultant}>
                    Reset Password
                </button>
                <button className={generalStyles.submitButton} onClick={verifyEmailForVerification}>
                    Resend Verification Email
                </button>
                <button className={generalStyles.submitButton} onClick={getInvitationLink}>
                    Copy Invite Link
                </button>
                {/* <button className={generalStyles.submitButton} onClick={resendInvitationMail}>
                    Disable Email
                </button> */}
            </span>

            <span>
                {sendInviteApiStatus === API_STATES.error ? (
                    <span className={`${generalStyles.errorMessage} ${styles.errorMessage}`}>
                        Error: {sendInviteApiError}
                    </span>
                ) : sendInviteApiStatus === API_STATES.loading ? (
                    <span className={generalStyles.statusMessage}>Loading...</span>
                ) : sendInviteApiStatus === API_STATES.none ? (
                    <span className={generalStyles.statusMessage}></span>
                ) : sendInviteApiStatus === API_STATES.success ? (
                    <span className={generalStyles.successMessage}>Successfully sent Invitation email.</span>
                ) : null}

                {verifyEmailApiStatus === API_STATES.error || fetchLinkApiStatus === API_STATES.error ? (
                    <span className={`${generalStyles.errorMessage} ${styles.errorMessage}`}>
                        Error: {verifyEmailApiError} {fetchLinkApiError}
                    </span>
                ) : verifyEmailApiStatus === API_STATES.loading || fetchLinkApiStatus === API_STATES.loading ? (
                    <span className={generalStyles.statusMessage}>Loading...</span>
                ) : verifyEmailApiStatus === API_STATES.none || fetchLinkApiStatus === API_STATES.none ? (
                    <span className={generalStyles.statusMessage}></span>
                ) : fetchLinkApiStatus === API_STATES.success ? (
                    <span className={generalStyles.successMessage}>Success!</span>
                ) : null}

                {verifyEmailCopyApiStatus === API_STATES.error || sendEmailApiStatus === API_STATES.error ? (
                    <p className={`${generalStyles.errorMessage} ${styles.errorMessage}`}>
                        Error: {verifyEmailCopyApiError} {sendEmailApiError}
                    </p>
                ) : verifyEmailCopyApiStatus === API_STATES.loading || sendEmailApiStatus === API_STATES.loading ? (
                    <span className={generalStyles.statusMessage}>Loading...</span>
                ) : verifyEmailCopyApiStatus === API_STATES.none || sendEmailApiStatus === API_STATES.none ? (
                    <span className={generalStyles.statusMessage}></span>
                ) : sendEmailApiStatus === API_STATES.success ? (
                    <span className={generalStyles.successMessage}>Successfully sent verification email.</span>
                ) : null}
                {invitationLinkApiStatus === API_STATES.error ? (
                    <span className={`${generalStyles.errorMessage} ${styles.errorMessage}`}>
                        Error: {invitationLinkApiError}
                    </span>
                ) : invitationLinkApiStatus === API_STATES.loading ? (
                    <span className={generalStyles.statusMessage}>Fetching...</span>
                ) : invitationLinkApiStatus === API_STATES.none ? (
                    <span className={generalStyles.statusMessage}></span>
                ) : invitationLinkApiStatus === API_STATES.success ? (
                    <span className={generalStyles.statusMessage} data-test="copy-link-status">
                        {copyStatus}
                    </span>
                ) : null}
                
            </span>
            { !isConsultant && fetchLinkApiStatus === API_STATES.success && (
                <div>
                    <h5>Reset Password Link for {email}</h5>
                    <div className={styles.link_display_area} data-test="link-display-area">
                        <span id="link">{link}</span>
                        <button
                            onClick={() => {
                                navigator.clipboard.writeText(link);
                                setCopyStatus('Copied!');
                                setTimeout(() => setCopyStatus(''), 1000);
                            }}
                            className={generalStyles.smallButton}
                        >
                            Copy
                        </button>
                        <span className={generalStyles.statusMessage} data-test="copy-link-status">
                            {copyStatus}
                        </span>
                    </div>
                </div>
            )}
        </>
    );
}
