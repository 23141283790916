import { useMemo, useState, useRef } from 'react';
import { toOption, useClickOutsideHandler } from '../utils/utils';
import Select from 'react-select';
import generalStyles from '@/components/General/Styles.module.scss';
import '@/components/General/MultiSelectColumnFilter.scss';
import InlineSVG from 'react-inlinesvg';
import filterIcon from '@images/icons/filterIcon.svg';
import classNames from 'classnames';
import { isEmpty, uniq } from 'lodash';

export function MultiSelectColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = useMemo(() => {
        const values = preFilteredRows
            .map((row) => row.values[id])
            .filter((value) => !isEmpty(value))
            .flat()
        return uniq(values).sort().map((value) => toOption(value))
    }, [id, preFilteredRows]);

    const [showFilterOptions, setShowFilterOptions] = useState(false);
    const compRef = useRef();
    useClickOutsideHandler(compRef, () => {
        setShowFilterOptions(false);
    });
    function toggleSelectionForm() {
        setShowFilterOptions((status) => !status);
    }

    return (
        <div className={generalStyles.multiFilter} onClick={toggleSelectionForm} ref={compRef}>
            <InlineSVG src={filterIcon} />
            {filterValue?.length ?? 0}
            <div
                className={classNames(generalStyles.selectionWrapper, {
                    [generalStyles.active]: showFilterOptions,
                })}
                onClick={(e) => e.stopPropagation()}
            >
                <Select
                    classNamePrefix={'multi-select-column-filter'}
                    options={options}
                    closeMenuOnSelect={false}
                    isMulti={true}
                    onChange={setFilter}
                    value={filterValue}
                    placeholder={'search to filter'}
                    maxMenuHeight={250}
                />
            </div>
        </div>
    );
}
