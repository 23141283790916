import React, { useEffect, useMemo, useState } from 'react';
import API_STATES from '@/constants/StateConstants';
import { setAccessibleMarketsForCompany } from '@/services/api/company.service';
import generalStyles from '@/components/General/Styles.module.scss';
import { handleApiError, noOp } from '@/components/General/HandleApiError';
import Select from 'react-select';
import { toOption } from '../utils/utils';

export function EditAccessibleMarkets({
    nsCompanyId,
    selectedMarkets: propSelectedMarkets,
    allowedMarketCount,
    allMarkets,
    onSuccess = noOp,
    onCancel = noOp,
}) {
    const [selectedMarkets, setSelectedMarkets] = useState([]);

    const [updateMarketsApiState, setUpdateMarketsApiState] = useState(API_STATES.none);
    const [updateMarketsError, setUpdateMarketsError] = useState('');

    const allMarketOptions = useMemo(
        () =>
            allMarkets.map((market) => ({
                label: market,
                value: market,
                isDisabled: selectedMarkets.length >= allowedMarketCount,
            })),
        [allMarkets, allowedMarketCount, selectedMarkets.length]
    );

    const saveSelectedAccessibleMarkets = async (markets) => {
        setUpdateMarketsApiState(API_STATES.loading);
        const marketNames = markets.map(({value})=>value)
        const response = await setAccessibleMarketsForCompany(nsCompanyId, marketNames);
        switch (response.status) {
            case API_STATES.success:
                setUpdateMarketsApiState(API_STATES.success);
                onSuccess();
                break;
            default:
                handleApiError(response.error, setUpdateMarketsApiState, setUpdateMarketsError);
                break;
        }
        setTimeout(() => setUpdateMarketsApiState(API_STATES.none), 3000);
    };

    const isSaveDisabled = selectedMarkets.length > allowedMarketCount || updateMarketsApiState === API_STATES.loading;

    const saveSelectedMarketsStatusDisplay =
        updateMarketsApiState === API_STATES.loading ? (
            <span className={generalStyles.statusMessage}>Saving...</span>
        ) : updateMarketsApiState === API_STATES.success ? (
            <span className={generalStyles.successMessage}>Success</span>
        ) : updateMarketsApiState === API_STATES.error ? (
            <span className={generalStyles.errorMessage}>{updateMarketsError}</span>
        ) : (
            <button className={generalStyles.submitButton} onClick={() => onCancel()}>
                Cancel
            </button>
        );

    useEffect(() => {
        setSelectedMarkets(propSelectedMarkets.map(({ marketName }) => toOption(marketName)) ?? []);
    }, [propSelectedMarkets]);

    return (
        <>
            {selectedMarkets.length} of {allowedMarketCount} Selected
            <div className={generalStyles.multiSelectInput} data-test="accessible-market-list">
                <Select
                    closeMenuOnSelect={false}
                    options={allMarketOptions}
                    onChange={(markets) => setSelectedMarkets(markets)}
                    value={selectedMarkets}
                    placeholder={'Select Accessible Market'}
                    isMulti={true}
                />
                <button
                    className={generalStyles.submitButton}
                    disabled={isSaveDisabled}
                    onClick={() => saveSelectedAccessibleMarkets(selectedMarkets)}
                >
                    Save
                </button>
                {saveSelectedMarketsStatusDisplay}
            </div>
        </>
    );
}
