import React, { useContext, useState } from 'react';
import * as API from '@/services/api/company.service';
import API_STATES from '@/constants/StateConstants';
import generalStyles from '@/components/General/Styles.module.scss';

import PropTypes from 'prop-types';
import { companyPageContext } from '@/components/NewCompanyPage/NewCompanyPage';

function RunCreditsEntry(props) {
    const { nsCompanyId } = useContext(companyPageContext)
    const [count, setCount] = useState(+props.runCredit.creditCount);
    const [lastSavedCount, setLastSavedCount] = useState(+props.runCredit.creditCount);
    const [saveCreditApiError, setSaveCreditApiError] = useState('');
    const [savedCreditApiState, setSavedCreditApiState] = useState(API_STATES.none);

    function changeCredit(e) {
        const newCount = e.target.value;
        setCount(newCount);
    }

    async function saveCredit(id) {
        // The id is the PK of the ContractItemInstance table, so we don't need any other identifier like type or company.
        setSavedCreditApiState(API_STATES.loading);
        const { status } = await API.updateRunCreditCount(nsCompanyId, id, count);
        if (status === API_STATES.success) {
            setLastSavedCount(count);
            setSavedCreditApiState(API_STATES.success);
        } else {
            // revert the count
            setCount(lastSavedCount);
            setSaveCreditApiError('Failed to save');
            setSavedCreditApiState(API_STATES.error);
        }
        setTimeout(() => setSavedCreditApiState(API_STATES.none), 2000);
    }

    function displayType(uglyType) {
        if (uglyType) {
            return uglyType.replaceAll('_', ' ');
        } else {
            return '';
        }
    }

    return (
        <>
            <tr>
                <td>{props.runCredit.title}</td>
                <td className={generalStyles.cap}>{displayType(props.runCredit.type)}</td>
                <td className={generalStyles.numeric}>{props.runCredit.baseCount || 0}</td>
                <td>
                    <input className={generalStyles.numeric} type={'number'} value={count} onChange={changeCredit} disabled={!props.isEditable}/>
                </td>
                <td className={generalStyles.numeric}>{props.runCredit.usageCount || 0}</td>
                <td className={generalStyles.numeric}>
                    {props.runCredit.baseCount + Number(count || 0) - props.runCredit.usageCount}
                </td>
                {props.isEditable && <td className="borderless">
                    <button
                        className={generalStyles.submitButton}
                        disabled={count === lastSavedCount}
                        onClick={() => saveCredit(props.runCredit.creditId)}
                    >
                        Save
                    </button>
                    {savedCreditApiState === API_STATES.loading ? (
                        <span className={generalStyles.statusMessage}>Saving...</span>
                    ) : savedCreditApiState === API_STATES.error ? (
                        <span className={generalStyles.errorMessage}>{saveCreditApiError}</span>
                    ) : (
                        savedCreditApiState === API_STATES.success && (
                            <span className={generalStyles.successMessage}>Saved!</span>
                        )
                    )}
                </td>}
            </tr>
        </>
    );
}

RunCreditsEntry.propTypes = {
    runCredit: PropTypes.shape({
        baseCount: PropTypes.number.isRequired,
        creditCount: PropTypes.number.isRequired,
        usageCount: PropTypes.number.isRequired,
        creditId: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
    }).isRequired,
};

export default RunCreditsEntry;
