export const AccessLevels = [
    { label: 'Tracked Manufacturer', value: 'tracked_manufacturer' },
    { label: 'Retailer', value: 'retailer' },
    { label: 'All Else', value: 'all_else' },
    { label: 'Unknown', value: 'unknown' },
    { label: 'Banks Finance', value: 'banks_finance' },
    { label: 'Manufacturer Hidden Prices', value: 'manufacturer_hidden_prices' },
    { label: 'Unknown Hidden Prices', value: 'unknown_hidden_prices' },
    { label: 'Consulting', value: 'consulting' }
];
