import React, { useEffect, useState } from 'react';
import generalStyles from '@/components/General/Styles.module.scss';
import AsyncSelect from 'react-select/async';
import debounce from 'lodash.debounce';
import { getMatchingCompanies, getNsCompanyData } from '@/services/api/company.service';
import styles from './AccountSetup.module.scss';
import API_STATES from '@/constants/StateConstants';
import { handleApiError } from '@/components/General/HandleApiError';
import CompanyDetails from './CompanyDetails';
import { useHistory, useParams } from 'react-router-dom';

function NewAccountSetup() {
    const [companiesApiState, setCompaniesApiState] = useState(API_STATES.none);
    const [companiesApiError, setCompaniesApiError] = useState('');
    const [companyDetailsApiState, setCompanyDetailsApiState] = useState(API_STATES.none);
    const [companyDetailsApiError, setCompanyDetailsApiError] = useState('');

    const [companyExists, setCompanyExists] = useState(false);
    const [selectedCompany, setSelectedCompany] = useState({});
    const [companyData, setCompanyData] = useState({});
    const [nsCompanyId, setNsCompanyId] = useState(0);

    const history = useHistory()
    const params = useParams();

    function onAutoPopulate(nsCompanyId) {
        history.push(`/companysetup/${nsCompanyId}`)
    }

    useEffect(() => {
        if (params.nsCompanyId) {
            getCompanyDetails(params.nsCompanyId)
        }
    }, [params.nsCompanyId])

    async function getCompanyDetails(nsCompanyId) {
        setCompanyDetailsApiState(API_STATES.loading);
        const response = await getNsCompanyData(nsCompanyId);
        switch (response.status) {
            case API_STATES.success:
                setCompanyDetailsApiState(API_STATES.success);
                setCompanyData(response.data);
                setNsCompanyId(response.data?.nsCompanyId);
                setTimeout(() => setCompanyDetailsApiState(API_STATES.none), 1000);
                break;
            case API_STATES.error:
                let error = response.error;
                handleApiError(error, setCompanyDetailsApiState, setCompanyDetailsApiError, 'Company not present in netsuite');
                break;
            default:
                setCompanyDetailsApiError('');
                setCompanyDetailsApiState(API_STATES.none);
        }
    }

    const handleSelectedCompany = (company) => {
        setSelectedCompany(company);
        setCompanyExists(true);
        setCompanyDetailsApiState(API_STATES.none);
        setCompanyDetailsApiError('');
    };

    const loadCompanies = async (companySearchTerm, callback) => {
        if (companySearchTerm === '') {
            setCompaniesApiState(API_STATES.none);
            callback([]);
            return;
        }
        setCompaniesApiState(API_STATES.loading);
        const response = await getMatchingCompanies(companySearchTerm);
        if (response.status === API_STATES.success) {
            const companyData = response.data;
            callback(
                companyData.sort().map((company) => {
                    return { label: company.name, value: company.id };
                })
            );
            setCompaniesApiState(API_STATES.success);
        } else {
            setCompaniesApiError(response.error);
            setCompaniesApiState(API_STATES.error);
            callback([]);
        }
    };

    const loadCompaniesDebounced = debounce(loadCompanies, 1000);
    return (
        <>
            <h1>Company Setup</h1>
            <hr />
            <div className={styles.flexcontainer}>
                <div>
                    <div className={generalStyles['input-wrapper']}>
                        <label>Company Name:</label>
                        <span className={styles.formField}>
                            <AsyncSelect
                                cacheOptions
                                backspaceRemovesValue
                                loadOptions={loadCompaniesDebounced}
                                onChange={handleSelectedCompany}
                                escapeClearsValue={false}
                                noOptionsMessage={() =>
                                    companiesApiState === API_STATES.success
                                        ? 'No companies match the search'
                                        : 'Please enter company name to search'
                                }
                            />
                        </span>
                        {companiesApiState === API_STATES.error && (
                            <span className={generalStyles.errorMessage}>{companiesApiError}</span>
                        )}
                    </div>
                </div>
                <div>
                    <button
                        className={generalStyles.submitButton}
                        disabled={!companyExists || !selectedCompany?.value}
                        onClick={() => onAutoPopulate(selectedCompany.value)}
                    >
                        Auto Populate
                    </button>
                </div>
                <div>
                    <span>
                        {companyDetailsApiState === API_STATES.loading ? (
                            <span className={generalStyles.statusMessage}>Fetching data...</span>
                        ) : companyDetailsApiState === API_STATES.error ? (
                            <span className={generalStyles.errorMessage}>Error: {companyDetailsApiError}</span>
                        ) : (
                            companyDetailsApiState === API_STATES.success && (
                                <span className={generalStyles.successMessage}>Fetched!</span>
                            )
                        )}
                    </span>
                </div>
            </div>
            <CompanyDetails companyInfo={companyData} />
        </>
    );
}
export default NewAccountSetup;
