import generalStyles from '@/components/General/Styles.module.scss';
import { MultiSelectColumnFilter } from '../General/MultiSelectColumnFilter';
import { FILTER_TYPES } from '../General/DataTable';
import { DateRangeColumnFilter } from '../General/DateRangeColumnFilter';

export const COMMON_CATEGORY_EVENT_COLUMNS = [
    {
        Header: 'Event Type',
        accessor: 'eventType',
        id: 'eventType',
        className: `${generalStyles.tableCell}`,
        Filter: MultiSelectColumnFilter,
        filter: FILTER_TYPES.multiple
    },
    {
        Header: 'Event Name',
        accessor: 'eventName',
        id: 'eventName',
        className: `${generalStyles.tableCell}`,
        Filter: MultiSelectColumnFilter,
        filter: FILTER_TYPES.multiple,
    },
    {
        Header: 'Old Categories',
        accessor: (row)=>row?.oldCategories?.length? row?.oldCategories?.map((item)=>item.label).join(', ') : '',
        id: 'oldCategories',
        className: `${generalStyles.tableCell}`,
        Filter: MultiSelectColumnFilter,
        filter: FILTER_TYPES.multiple,
    },
    {
        Header: 'New Categories',
        accessor: 'newCategory',
        id: 'newCategory',
        className: `${generalStyles.tableCell}`,
        Filter: MultiSelectColumnFilter,
        filter: FILTER_TYPES.multiple,
    },
    {
        Header: 'Data Refresh Date',
        accessor: 'date',
        id: 'date',
        className: generalStyles.tableCell,
        Filter: DateRangeColumnFilter,
        filter: FILTER_TYPES.dateRange,
    },
    {
        Header: 'Status',
        accessor: 'status',
        id: 'status',
        className: generalStyles.tableCell,
        Filter: MultiSelectColumnFilter,
        filter: FILTER_TYPES.multiple,
    }
];
