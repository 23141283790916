import generalStyles from '@/components/General/Styles.module.scss';
import React, { useEffect, useState } from 'react';
import API_STATES from '@/constants/StateConstants';
import { getCsrUser, setResetPassword } from '@/services/api/csrdata.service';
import { handleApiError } from '@/components/General/HandleApiError';

function ResetPasswordOnNextLogin(props) {
    const email = props.email;

    const [fetchResetPasswordApiStatus, setFetchResetPasswordApiStatus] = useState(API_STATES.none);
    const [fetchResetPasswordApiError, setFetchResetPasswordApiError] = useState('');
    const [fetchResetPasswordApiResult, setFetchResetPasswordApiResult] = useState(false);

    const [updateResetPasswordApiStatus, setUpdateResetPasswordApiStatus] = useState(API_STATES.none);
    const [updateResetPasswordApiError, setUpdateResetPasswordApiError] = useState('');

    //get resetPassword value
    async function fetchChangePasswordData() {
        setFetchResetPasswordApiStatus(API_STATES.loading);
        const result = await getCsrUser(email);
        switch (result.status) {
            case API_STATES.success:
                setFetchResetPasswordApiStatus(API_STATES.success);
                setFetchResetPasswordApiResult(result.data.resetPassword);
                break;
            case API_STATES.error:
                let error = result.error;
                handleApiError(error, setFetchResetPasswordApiStatus, setFetchResetPasswordApiError);
                break;
            default:
                setFetchResetPasswordApiError('');
                setFetchResetPasswordApiStatus(API_STATES.none);
        }
    }

    //set resetPassword value
    async function updateChangePasswordData() {
        setUpdateResetPasswordApiStatus(API_STATES.loading);
        const result = await setResetPassword(email, !fetchResetPasswordApiResult);
        switch (result.status) {
            case API_STATES.success:
                setUpdateResetPasswordApiStatus(API_STATES.success);
                fetchChangePasswordData();
                break;
            case API_STATES.error:
                let error = result.error;
                setUpdateResetPasswordApiStatus(API_STATES.error);
                switch (error) {
                    case 401:
                        setUpdateResetPasswordApiError('CSR is not logged in.');
                        break;
                    case 403:
                        setUpdateResetPasswordApiError('Unauthorised to perform this action');
                        break;
                    case 404:
                        setUpdateResetPasswordApiError('Unable to set Reset Password - data not found');
                        break;
                    default:
                        setUpdateResetPasswordApiError('Unknown error in setting Reset Password status');
                        break;
                }
                break;
            default:
                setUpdateResetPasswordApiError('');
                setUpdateResetPasswordApiStatus(API_STATES.none);
        }
    }

    useEffect(() => {
        (async () => {
            if (email !== null && email !== '') {
                fetchChangePasswordData();
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [email, props.csrPasswordChanged]);

    return (
        <div data-test="reset-password-component">
            {fetchResetPasswordApiStatus === API_STATES.loading ? (
                <label className={generalStyles.statusMessage}>Fetching Reset Password Status</label>
            ) : fetchResetPasswordApiStatus === API_STATES.error ? (
                <label className={generalStyles.errorMessage}>
                    Failed to fetch Reset Password Status: {fetchResetPasswordApiError}
                </label>
            ) : (
                <>
                    <label>Reset Password on Next Login: </label>
                    <input
                        data-test="reset-password-checkbox"
                        type="checkbox"
                        checked={fetchResetPasswordApiResult}
                        onClick={() => updateChangePasswordData()}
                        disabled={props.csrPasswordChanged}
                    />
                    <label data-test="reset-password-checkbox-result">
                        {updateResetPasswordApiStatus === API_STATES.loading ? (
                            <span className={generalStyles.statusMessage}>Processing...</span>
                        ) : updateResetPasswordApiStatus === API_STATES.error ? (
                            <span className={generalStyles.errorMessage}>
                                Failed to Update: {updateResetPasswordApiError}
                            </span>
                        ) : (
                            updateResetPasswordApiStatus === API_STATES.success && (
                                <span className={generalStyles.successMessage}>saved!</span>
                            )
                        )}
                    </label>
                </>
            )}
        </div>
    );
}
export default ResetPasswordOnNextLogin;
