import firebase from 'firebase/app';
import 'firebase/auth';
import store from '@/store';
import { getCsrUser } from '@/services/api/csrdata.service';
import { putCsrAudit } from '@/services/api/csraudit.service';
import API_STATES from '@/constants/StateConstants';

export async function getUser() {
    const firebaseUser = firebase.auth()?.currentUser;

    if (firebaseUser) {
        const result = await getCsrUser(firebaseUser.email);
        if (result.status === API_STATES.success) {
            return {
                ...firebaseUser,
                ...result.data,
            };
        } else {
            // the logged in user is invalid csr hence logout
            await firebase.auth().signOut();
        }
    }
}

export async function isLoggedIn() {
    const user = await getUser();

    // probably easier ways than this, but explicit never hurts.
    return user !== null && user !== undefined;
}

export async function login({ username, password }) {
    // We need to let errors bubble up
    try {
        const firebaseResult = await firebase.auth().signInWithEmailAndPassword(username, password);
        let user = firebaseResult.user;
        if (user) {
            // specifically not waiting
            putCsrAudit('login', new Date(), { result: API_STATES.success });

            //user assigned with csrUserRoles data returned from getCsrUser()
            const result = await getCsrUser(user.email);
            if (result.status === API_STATES.success) {
                user = result.data;
                store.dispatch.account.setUser(user);
                return true;
            } else {
                putCsrAudit('login', new Date(), { result: `failed: ${username}` });
                // the logged in user is invalid csr hence logout
                await firebase.auth().signOut();
            }
        } else {
            putCsrAudit('login', new Date(), { result: `failed: ${username}` });
        }
    } catch (e) {
        putCsrAudit('login', new Date(), { result: `failed: ${username}` });
        throw e;
    }
}

export async function validateCurrentPassword(oldPass) {
    try {
        const cred = firebase.auth.EmailAuthProvider.credential(firebase.auth().currentUser.email, oldPass);
        await firebase.auth().currentUser.reauthenticateWithCredential(cred);
        return { status: API_STATES.success, data: 'Valid Current Password' };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function reauthenticateUser(newPass) {
    try {
        //Reauthenticate loggedIn csr after password change - (for successful csraudit at logout)
        const cred = firebase.auth.EmailAuthProvider.credential(firebase.auth().currentUser.email, newPass);
        await firebase.auth().currentUser.reauthenticateWithCredential(cred);

        //Reset the store value to reflect the updated csr user data - (resetPassword flag)
        let user;
        const result = await getCsrUser(firebase.auth().currentUser.email);
        if (result.status === API_STATES.success) {
            user = result.data;
        }
        store.dispatch.account.setUser(user);
        return { status: API_STATES.success, data: 'Successful Reauthentication' };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function logout() {
    // audit needs to happen first since after signout, there is no user on the req.
    try {
        await putCsrAudit('logout', new Date());
    } catch (e) {
        // If the logout audit failed..., probably not awesome, but we need to continue
        // with the process.
    }
    await firebase.auth().signOut();
    store.dispatch.account.clearUser();
}

// We are not going to have a "signup" function for CSR initially since we will be manually creating the CSR
// users in firebase.
