import { getCompanyOmniCategoryCount, getOmniCategories } from '@/services/api/product.service';
import { useEffect, useMemo, useState } from 'react';
import generalStyles from '@/components/General/Styles.module.scss';
import styles from '@/components/CompanyConfig/CompanyConfig.module.scss';
import API_STATES from '@/constants/StateConstants';
import { handleApiError } from '@/components/General/HandleApiError';
import { EditCompanyOmniCategories } from '@/components/CompanyConfig/EditCompanyOmniCategories';
import { getCompanyConfig } from '@/services/api/company.service';

function ManageOmniCategories({ nsCompanyId, className, isEditable = true, refreshTimestamp }) {
    const [companyOmniCategoriesApiState, setCompanyOmniCategoriesApiState] = useState(API_STATES.none);
    const [companyOmniCategoriesApiError, setCompanyOmniCategoriesApiError] = useState(null);
    const [companyOmniCategories, setCompanyOmniCategories] = useState([]);

    const [omniCategoriesApiState, setOmniCategoriesApiState] = useState(API_STATES.none);
    const [omniCategoriesApiError, setOmniCategoriesApiError] = useState(null);
    const [allOmniCategories, setAllOmniCategories] = useState([]);

    const [companyOmniCategoriesCountApiState, setCompanyOmniCategoriesCountApiState] = useState(API_STATES.none);
    const [companyOmniCategoriesCountApiError, setCompanyOmniCategoriesCountApiError] = useState('');
    const [companyOmniCategoryCount, setCompanyOmniCategoryCount] = useState(null);

    const [isInEditMode, setIsInEditMode] = useState(false);

    async function loadCompanyOmniCategories(nsCompanyId) {
        setCompanyOmniCategoriesApiState(API_STATES.loading);
        let response = await getCompanyConfig(nsCompanyId);
        //transforming response data/error as per requirement
        if (response.status === API_STATES.success) {
            let companyOmniCategories = response.data.omniCategories ?? [];
            companyOmniCategories = companyOmniCategories.map((category) => {
                return { label: category, value: category };
            });
            setCompanyOmniCategories(companyOmniCategories);
            setCompanyOmniCategoriesApiState(API_STATES.success);
        } else {
            const error = response.error;
            handleApiError(
                error,
                setCompanyOmniCategoriesApiState,
                setCompanyOmniCategoriesApiError,
                `No config data for company ${nsCompanyId} found.`
            );
            setCompanyOmniCategories([]);
        }
    }

    async function loadCompanyOmniCategoryCount(nsCompanyId) {
        setCompanyOmniCategoriesCountApiState(API_STATES.loading);
        let response = await getCompanyOmniCategoryCount(nsCompanyId);
        if (response.status === API_STATES.success) {
            setCompanyOmniCategoryCount(response.data);
            setCompanyOmniCategoriesCountApiState(API_STATES.success);
        } else {
            setCompanyOmniCategoryCount(null);
            handleApiError(
                response.error,
                setCompanyOmniCategoriesCountApiState,
                setCompanyOmniCategoriesCountApiError
            );
        }
    }

    async function loadOmniCategories() {
        setOmniCategoriesApiState(API_STATES.loading);
        const response = await getOmniCategories();
        if (response.status === API_STATES.success) {
            response.data = response.data ?? [];
            const categories = response.data.map((category) => {
                return { label: category, value: category };
            });
            setAllOmniCategories(categories);
            setOmniCategoriesApiState(API_STATES.success);
        } else {
            handleApiError(response.error, setOmniCategoriesApiState, setOmniCategoriesApiError);
            setAllOmniCategories([]);
        }
    }

    useEffect(() => {
        if (isEditable) {
            loadOmniCategories();
        } else {
            setAllOmniCategories([]);
        }
    }, [isEditable]);

    useEffect(() => {
        loadCompanyOmniCategoryCount(nsCompanyId);
    }, [nsCompanyId, refreshTimestamp]);

    useEffect(() => {
        if (companyOmniCategoriesCountApiState === API_STATES.success) {
            loadCompanyOmniCategories(nsCompanyId);
        } else {
            setCompanyOmniCategories([]);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyOmniCategoriesCountApiState]);

    const companyCategoriesList =
        companyOmniCategoriesApiState === API_STATES.loading ? (
            <p className={generalStyles.statusMessage}>Loading Company Omni Categories List....</p>
        ) : companyOmniCategoriesApiState === API_STATES.error ? (
            <p className={generalStyles.errorMessage}>Error: {companyOmniCategoriesApiError}</p>
        ) : !companyOmniCategories?.length ? (
            <p className={generalStyles.statusMessage}>No omni categories selected</p>
        ) : (
            <ul data-test="selected-omni-category-list" className={generalStyles.tileList}>
                {companyOmniCategories.map((category) => (
                    <li data-test="selected-omni-category-list-item" key={category.value}>
                        {category.label}
                    </li>
                ))}
            </ul>
        );

    const companyCategoriesElement =
        companyOmniCategoriesCountApiState === API_STATES.loading ? (
            <p className={generalStyles.statusMessage}>Loading Omni Categories count limit....</p>
        ) : companyOmniCategoriesCountApiState === API_STATES.error ? (
            <p className={generalStyles.errorMessage}>{companyOmniCategoriesCountApiError}</p>
        ) : (
            <>
                <span data-test="selected-omni-category-element">{companyCategoriesList}</span>
            </>
        );

    function onCategoriesEdited() {
        loadCompanyOmniCategories(nsCompanyId);
        setIsInEditMode(false);
    }

    const waitToChangeCategories = useMemo(() => {
        return (
            companyOmniCategoriesApiState !== API_STATES.success ||
            companyOmniCategoriesCountApiState !== API_STATES.success ||
            omniCategoriesApiState !== API_STATES.success ||
            !isEditable
        );
    }, [companyOmniCategoriesApiState, companyOmniCategoriesCountApiState, omniCategoriesApiState, isEditable]);

    return (
        <div className={className}>
            
            {isInEditMode ? (
                omniCategoriesApiState === API_STATES.loading ? (
                    <p className={generalStyles.statusMessage}>loading Omni categories...</p>
                ) : omniCategoriesApiState === API_STATES.error ? (
                    <p className={generalStyles.errorMessage}>Error: {omniCategoriesApiError}</p>
                ) : (
                    <EditCompanyOmniCategories
                        allOmniCategories={allOmniCategories}
                        nsCompanyId={nsCompanyId}
                        companyOmniCategories={companyOmniCategories}
                        maxAllowedCategories={companyOmniCategoryCount}
                        onSuccess={onCategoriesEdited}
                        onCancel={() => setIsInEditMode(false)}
                        disabled={waitToChangeCategories}
                    />
                )
            ) : (
                <>
                    <span data-test="selected-omni-category-element">{companyCategoriesElement}</span>
                </>
            )}
            <span className={styles.columnar}>
                {/* <h3>Omni Categories</h3><hr /> */}

                {isEditable && !isInEditMode && (
                    <button
                        className={generalStyles.submitButton}
                        disabled={waitToChangeCategories}
                        onClick={() => setIsInEditMode(true)}
                    >
                        Edit
                    </button>
                )}
                 {companyOmniCategoriesApiState === API_STATES.success && (
                    <p>
                        {companyOmniCategories.length} of {companyOmniCategoryCount ?? '(unknown)'} Selected
                    </p>
                )}
            </span>
        </div>
    );
}

export default ManageOmniCategories;
