import React, { useState } from 'react';
import generalStyles from '@/components/General/Styles.module.scss';
import ProfileSettings from '@/components/ModifyCSR/ProfileSettings';
import { useParams, Link } from 'react-router-dom';

function ModifySpecificCsr() {
    const { email } = useParams();
    const [csrDeleted, setCsrDeleted] = useState(false);
    const [csrPasswordChanged, setCsrPasswordChanged] = useState(false);

    return (
        <>
            <h1 data-test="modify-csr-screen-heading">Modify CSR - {email}</h1>
            <hr />
            <p>
                <Link className={generalStyles.link} to="/modifycsr">
                    Back
                </Link>
            </p>
            {!csrDeleted && (
                <ProfileSettings
                    csrEmail={email}
                    csrPasswordChanged={csrPasswordChanged}
                    onCsrPasswordChange={() => setCsrPasswordChanged(true)}
                    onDelete={() => setCsrDeleted(true)}
                />
            )}
            {csrDeleted && <p className={generalStyles.successMessage}>CSR Deleted! This CSR no longer exists</p>}
        </>
    );
}

export default ModifySpecificCsr;
