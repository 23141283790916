import React, { useEffect, useState } from 'react';
import API_STATES from '@/constants/StateConstants';
import {
    getCompanyBrands,
} from '@/services/api/company.service';
import styles from '@/components/CompanyConfig/CompanyConfig.module.scss';
import generalStyles from '@/components/General/Styles.module.scss';
import { handleApiError } from '@/components/General/HandleApiError';
import { EditBrands } from './EditBrands';

function Brands({ nsCompanyId, isEditable = true, refreshTimestamp }) {
    const [brands, setBrands] = useState([]);
    const [brandNotRetailingYet, setBrandNotRetailingYet] = useState(null);
    const [brandsApiState, setBrandsApiState] = useState(API_STATES.none);
    const [brandsError, setBrandsError] = useState('');
    const [isInEditMode, setIsInEditMode] = useState(false);

    const isEditDisabled = brandsApiState !== API_STATES.success;

    useEffect(() => {
        apiLoadBrands(nsCompanyId);
    }, [nsCompanyId, refreshTimestamp]);

    async function apiLoadBrands(nsCompanyId) {
        setBrandsApiState((brandsApiState) =>
            brandsApiState === API_STATES.success ? API_STATES.refreshing : API_STATES.loading
        );
        const brands = await getCompanyBrands(nsCompanyId);
        switch (brands?.status) {
            case API_STATES.success:
                setBrands(brands.data.brands);
                setBrandNotRetailingYet(brands.data.brandNotRetailingYet);
                setBrandsApiState(API_STATES.success);
                break;

            default:
                handleApiError(brands.error, setBrandsApiState, setBrandsError);
                setBrands([]);
                setBrandNotRetailingYet(null);
                break;
        }
    }

    const loadBrandsDisplay =
        brandsApiState === API_STATES.loading ? (
            <div className={generalStyles.statusMessage}>Loading brands...</div>
        ) : brandsApiState === API_STATES.error ? (
            <div className={generalStyles.errorMessage}>Error: {brandsError}</div>
        ) : brandNotRetailingYet ? (
            <div className={generalStyles.statusMessage}>Brand is not yet selling in retailers</div>
        ) : brands?.length ? (
            <ul data-test={'company-brands'} className={generalStyles.tileList}>
                {brands.map((brand) => (
                    <li key={brand}>
                        {brand}
                    </li>
                ))}
            </ul>
        ) : (
            <div className={generalStyles.statusMessage}>No brands selected</div>
        );

    async function onBrandsEdit() {
        await apiLoadBrands(nsCompanyId);
        closeEditForm();
    }
    function closeEditForm() {
        setIsInEditMode(false);
    }
    return (
        <div>
            {isInEditMode ? (
                <EditBrands 
                    nsCompanyId={nsCompanyId} 
                    selectedBrands={brands}
                    onSuccess={onBrandsEdit}
                    onCancel={closeEditForm}
                />
            ) : (
                <>
                    <span data-test="selected-brands">{loadBrandsDisplay}</span>
                    <span className={styles.columnar}>
                        {isEditable && !isInEditMode && (
                            <button
                                className={generalStyles.submitButton}
                                disabled={isEditDisabled}
                                onClick={() => setIsInEditMode(true)}
                            >
                                Edit
                            </button>
                        )}
                    </span>
                </>
            )}
        </div>
    );
}

export default Brands;
