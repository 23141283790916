import { useHistory, useParams } from 'react-router-dom';
import generalStyles from '@/components/General/Styles.module.scss';
import styles from '@/components/DataRefreshMaintenance/DataRefreshMaintenance.module.scss';
import Select from 'react-select';
import * as Constants from './Constants';
import ByzzerLink from '../General/ByzzerLink';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { generateTimeIntervals } from './Utils';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import API_STATES from '@/constants/StateConstants';
import { editSchedule, getSchedule, getSkuList } from '@/services/api/dataRefreshMaintenance.service';
import DataTable from '../General/DataTable';
import { handleApiError } from '../General/HandleApiError';

export default function Editdatamaintenanceschedule() {
    const history = useHistory();

    const { scheduleId } = useParams();

    const [scheduleApiState, setScheduleApiState] = useState(API_STATES);
    const [scheduleApiError, setScheduleApiError] = useState('');

    async function loadSchedule(scheduleId, skuList) {
        setScheduleApiState(API_STATES.loading);
        const response = await getSchedule(scheduleId);
        if (response.status === API_STATES.success) {
            const schedule = response.data;
            setSelectedEffectiveDate(moment(schedule.effectiveFrom, 'YYYY-MM-DD'));
            setSelectedFrequencyType(
                Constants.dataRefreshFrequencyTypes.find((frequencyType) => frequencyType.value === schedule.frequency)
            );
            setStartWeekday(
                Constants.isoWeekDaysOptions.find((isoWeekday) => schedule.startIsoWeekday === isoWeekday.value)
            );
            setStartTime({ label: schedule.startTime + ' ' + Constants.timeZoneString, value: schedule.startTime });
            const endWeekDayTime = moment(schedule.startTime, 'HH:mm')
                .isoWeekday(schedule.startIsoWeekday)
                .add(schedule.durationInMinutes, 'minutes');
            const endWeekday = endWeekDayTime.isoWeekday();
            setEndWeekday(Constants.isoWeekDaysOptions.find((isoWeekday) => endWeekday === isoWeekday.value));
            const endTime = endWeekDayTime.format('HH:mm');
            setEndTime({ label: endTime + ' ' + Constants.timeZoneString, value: endTime });
            setSelectedSkuList(
                skuList.filter((skuItem) => schedule.skuList.find((scheduledSku) => scheduledSku.sku === skuItem.sku))
            );
            setScheduleApiState(API_STATES.success);
        } else {
            handleApiError(response.error, setScheduleApiState, setScheduleApiError);
        }
    }

    const [selectedFrequencyType, setSelectedFrequencyType] = useState(Constants.defaultFrequency);

    const timeIntervals = useMemo(() => generateTimeIntervals(), []);
    function getMomentTime(momentTime, selectedWeekday, selectedTime) {
        momentTime = momentTime.isoWeekday(selectedWeekday.value);
        momentTime = moment(momentTime.format('YYYY-MM-DD') + ' ' + selectedTime.value, 'YYYY-MM-DD HH:mm');
        return momentTime;
    }
    const [startWeekday, setStartWeekday] = useState(Constants.defaultWeekday);
    const [startTime, setStartTime] = useState(Constants.defaultTime);
    const [startWeekdayTime, setStartWeekdayTime] = useState(getMomentTime(moment(), startWeekday, startTime));

    useEffect(() => {
        setStartWeekdayTime(getMomentTime(startWeekdayTime, startWeekday, startTime));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startWeekday, startTime]);

    const [endWeekday, setEndWeekday] = useState(Constants.defaultWeekday);
    const [endTime, setEndTime] = useState(Constants.defaultTime);
    const [endWeekdayTime, setEndWeekdayTime] = useState(getMomentTime(moment(), endWeekday, endTime));

    useEffect(() => {
        setEndWeekdayTime(getMomentTime(endWeekdayTime, endWeekday, endTime));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [endWeekday, endTime]);

    /**
     *
     * @param {moment.Moment} startWeekdayTime moment object created from start weekday and start time
     * @param {moment.Moment} endWeekdayTime moment object created from end weekday and end time
     */
    function getDuration(startWeekdayTime, endWeekdayTime) {
        if (endWeekdayTime < startWeekdayTime) {
            endWeekdayTime.add(1, 'week'); // this will modify endWeekdayTime value
        }
        return endWeekdayTime.diff(startWeekdayTime, 'minutes');
    }

    const [selectedEffectiveDate, setSelectedEffectiveDate] = useState(moment());
    const actualEffectiveFrom = useMemo(() => {
        const effectiveDate = selectedEffectiveDate.clone();
        if (effectiveDate.isoWeekday() > startWeekday.value) {
            return effectiveDate.add(1, 'week').isoWeekday(startWeekday.value);
        } else {
            return effectiveDate.isoWeekday(startWeekday.value);
        }
    }, [selectedEffectiveDate, startWeekday]);

    const [skuListApiState, setSkuListApiState] = useState(API_STATES);
    const [skuListApiError, setSkuListApiError] = useState('');
    const [skuList, setSkuList] = useState([]);
    const [selectedSkuList, setSelectedSkuList] = useState([]);

    const isSkuSelected = useCallback(
        (sku) => {
            const foundSku = selectedSkuList.find((skuItem) => skuItem.sku === sku);
            if (foundSku) {
                return true;
            } else {
                return false;
            }
        },
        [selectedSkuList]
    );

    const onCheckboxClick = useCallback(
        (skuItem, isChecked) => {
            const isSelected = isSkuSelected(skuItem.sku);
            if (isChecked && !isSelected) {
                const newSelectedSkuList = [...selectedSkuList, skuItem];
                setSelectedSkuList(newSelectedSkuList);
            } else if (!isChecked && isSelected) {
                const newSelectedSkuList = selectedSkuList.filter((selectedSku) => selectedSku.sku !== skuItem.sku);
                setSelectedSkuList(newSelectedSkuList);
            }
        },
        [isSkuSelected, selectedSkuList]
    );

    const subscriptionSkewListColumns = useMemo(
        () => [
            {
                Header: '',
                disableFilters: true,
                disableSortBy: true,
                accessor: (row) => row,
                id: 'selection',
                className: generalStyles.tableCell,
                Cell: ({ value: row }) => (
                    <input
                        type="checkbox"
                        checked={isSkuSelected(row.sku)}
                        onChange={(e) => onCheckboxClick(row, e.target.checked)}
                    />
                ),
            },
            {
                Header: 'Sku',
                accessor: 'sku',
                className: generalStyles.tableCell,
            },
            {
                Header: 'Title',
                accessor: 'title',
                className: generalStyles.tableCell,
            },
            {
                Header: 'Refresh Frequency',
                accessor: (row) => row.subscriptionFrequency ?? 'weekly',
                className: generalStyles.tableCell,
            },
        ],
        [isSkuSelected, onCheckboxClick]
    );

    const [saveScheduleApiState, setSaveScheduleApiState] = useState(API_STATES.none);
    const [saveScheduleApiError, setSaveScheduleApiError] = useState('');

    async function loadSkuList() {
        setSkuListApiState(API_STATES.loading);
        setSelectedSkuList([]);
        const response = await getSkuList();
        if (response.status === API_STATES.success) {
            const skuList = response.data;
            setSkuList(skuList);
            setSkuListApiState(API_STATES.success);
        } else {
            handleApiError(response.error, setSkuListApiState, setSkuListApiError);
            setSkuList([]);
        }
    }

    useEffect(() => {
        loadSkuList();
    }, []);

    useEffect(() => {
        if (skuListApiState === API_STATES.success) {
            loadSchedule(scheduleId, skuList);
        }
    }, [scheduleId, skuList, skuListApiState]);

    const isSubmitDisabled = useMemo(() => {
        return (
            saveScheduleApiState !== API_STATES.none ||
            // eslint-disable-next-line eqeqeq
            !selectedSkuList?.length ||
            !selectedFrequencyType ||
            !startWeekday ||
            !startTime ||
            !endWeekday ||
            !endTime ||
            !actualEffectiveFrom
        );
    }, [
        actualEffectiveFrom,
        endTime,
        endWeekday,
        saveScheduleApiState,
        selectedFrequencyType,
        selectedSkuList,
        startTime,
        startWeekday,
    ]);

    async function onSaveSchedule() {
        const payload = {
            id: scheduleId,
            frequency: selectedFrequencyType.value,
            startIsoWeekday: startWeekday.value,
            effectiveDate: actualEffectiveFrom.format('YYYY-MM-DD'),
            startTime: startTime.value,
            durationInMinutes: getDuration(startWeekdayTime, endWeekdayTime),
            skuList: selectedSkuList.map((skuItem) => skuItem.sku),
        };
        setSaveScheduleApiState(API_STATES.loading);
        const response = await editSchedule(payload);
        if (response.status === API_STATES.success) {
            setSaveScheduleApiState(API_STATES.success);
            setTimeout(() => {
                history.replace('/datamaintenanceschedules');
            }, 1000);
        } else {
            handleApiError(response.error, setSaveScheduleApiState, setSaveScheduleApiError);
            setTimeout(() => {
                setSaveScheduleApiState(API_STATES.none);
            }, 5000);
        }
    }

    return (
        <>
            <h1>Schedule Data Refresh Maintenance</h1>
            <hr />
            <ByzzerLink label="Back" onClick={() => history.goBack()} />
            <br />

            {skuListApiState === API_STATES.loading ? (
                <span className={generalStyles.statusMessage}>
                    Fetching Subscription Sku list for financial users....
                </span>
            ) : skuListApiState === API_STATES.error ? (
                <span className={generalStyles.errorMessage}>Error: {skuListApiError}</span>
            ) : scheduleApiState === API_STATES.loading ? (
                <span className={generalStyles.statusMessage}>Fetching Schedule details....</span>
            ) : scheduleApiState === API_STATES.error ? (
                <span className={generalStyles.errorMessage}>Error: {scheduleApiError}</span>
            ) : (
                scheduleApiState === API_STATES.success && (
                    <>
                        {/* frequency */}
                        <div className={generalStyles['input-wrapper']}>
                            <label className={styles.formFieldLabel}>Frequency:</label>
                            <span className={styles.formField}>
                                <Select
                                    classNamePrefix={'react-select'}
                                    options={Constants.dataRefreshFrequencyTypes}
                                    onChange={setSelectedFrequencyType}
                                    value={selectedFrequencyType}
                                />
                            </span>
                        </div>

                        {/* startTime */}
                        <div className={generalStyles['input-wrapper']}>
                            <label className={styles.formFieldLabel}>Start Time:</label>
                            <span className={styles.formItem}>
                                <span className={styles.formField}>
                                    <Select
                                        classNamePrefix={'react-select'}
                                        options={Constants.isoWeekDaysOptions}
                                        onChange={setStartWeekday}
                                        value={startWeekday}
                                    />
                                </span>
                                <span className={styles.formField}>
                                    <Select
                                        classNamePrefix={'react-select'}
                                        options={timeIntervals}
                                        onChange={setStartTime}
                                        value={startTime}
                                    />
                                </span>
                            </span>
                        </div>

                        {/* endTime */}
                        <div className={generalStyles['input-wrapper']}>
                            <label className={styles.formFieldLabel}>End Time:</label>
                            <span className={styles.formItem}>
                                <span className={styles.formField}>
                                    <Select
                                        classNamePrefix={'react-select'}
                                        options={Constants.isoWeekDaysOptions}
                                        onChange={setEndWeekday}
                                        value={endWeekday}
                                    />
                                </span>
                                <span className={styles.formField}>
                                    <Select
                                        classNamePrefix={'react-select'}
                                        options={timeIntervals}
                                        onChange={setEndTime}
                                        value={endTime}
                                    />
                                </span>
                            </span>
                        </div>

                        <div className={generalStyles['input-wrapper']}>
                            <label className={styles.formFieldLabel}>Effective from:</label>
                            <DatePicker
                                data-test="maintenance-datePicker"
                                name="effectiveFrom"
                                placeholderText="dd/MM/yyyy"
                                shouldCloseOnSelect={true}
                                fixedHeight
                                closeOnScroll={true}
                                dateFormat="MMM d, y"
                                selected={selectedEffectiveDate.toDate()}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                tabIndex={1}
                                onChange={(newDate) => setSelectedEffectiveDate(moment(newDate, 'ddd MMM DD YYYY'))}
                            />

                            {startWeekday.value !== selectedEffectiveDate.isoWeekday() && (
                                <span className={generalStyles.statusMessage}>
                                    Since effective date is a&nbsp;
                                    <span className={generalStyles.successMessage}>
                                        {selectedEffectiveDate.format('dddd')},
                                    </span>
                                    &nbsp; but the weekday of start date is&nbsp;
                                    <span className={generalStyles.successMessage}>{startWeekday.label}</span>
                                    &nbsp;the actual effective date will be&nbsp;
                                    <span className={generalStyles.successMessage}>
                                        {actualEffectiveFrom.format('MMM DD, y')}
                                    </span>
                                </span>
                            )}
                        </div>
                        <p className={styles.formFieldLabel}>Select Subscription Sku:</p>
                        {skuList.length === 0 ? (
                            <span className={generalStyles.statusMessage}>No Subscription skus found</span>
                        ) : (
                            <>
                                <DataTable
                                    data={skuList}
                                    columns={subscriptionSkewListColumns}
                                    nonStickyTableHeader={true}
                                    defaultPageSize={5}
                                />
                                <div className={generalStyles['input-wrapper']}>
                                    <label className={styles.formFieldLabel}>Selected Sku:</label>
                                    <span>
                                        {selectedSkuList.length === 0 ? (
                                            <span className={generalStyles.statusMessage}>
                                                Please select sku to apply the schedule
                                            </span>
                                        ) : (
                                            selectedSkuList
                                                .map(
                                                    (skuItem) =>
                                                        `${skuItem.sku} (${skuItem.subscriptionFrequency ?? 'weekly'})`
                                                )
                                                .join(', ')
                                        )}
                                    </span>
                                </div>
                            </>
                        )}

                        <div>
                            <button
                                className={generalStyles.submitButton}
                                onClick={onSaveSchedule}
                                disabled={isSubmitDisabled}
                            >
                                Save Schedule
                            </button>
                            {saveScheduleApiState === API_STATES.loading ? (
                                <span className={generalStyles.statusMessage}>Processing...</span>
                            ) : saveScheduleApiState === API_STATES.error ? (
                                <span className={generalStyles.errorMessage}>Error: {saveScheduleApiError}</span>
                            ) : (
                                saveScheduleApiState === API_STATES.success && (
                                    <span className={generalStyles.successMessage}> Schedule saved Successfully </span>
                                )
                            )}
                        </div>
                    </>
                )
            )}
        </>
    );
}
