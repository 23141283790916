import React, { useState, useEffect } from 'react';
import ApprovedMarkets from '@/components/CompanyConfig/ApprovedMarkets';
import AccessibleMarkets from '@/components/CompanyConfig/AccessibleMarkets';
import styles from './SetupNewCompany.module.scss';
import generalStyles from '@/components/General/Styles.module.scss';
import ManageCategories from '@/components/CompanyConfig/ManageCategories';
import OmniApprovedMarkets from '@/components/CompanyConfig/OmniPriorApprovedMarkets';
import ManageOmniCategories from '@/components/CompanyConfig/ManageOmniCategories';

function ConfigureProducts(props) {
    const nsCompanyId = props.nsCompanyId;
    const [isConfiguring, setIsConfiguring] = useState(false);
    const [refreshTimestamp, setRefreshTimestamp] = useState(null);

    useEffect(() => {
        if (props.refreshTimestamp) {
            setIsConfiguring(true);
            setTimeout(() => {
                setRefreshTimestamp(Date.now());
                setIsConfiguring(false);
            }, props.refreshDelay ?? 0);
        }
        // eslint-disable-next-line
    }, [props.refreshTimestamp]);

    return (
        <>
            {!isConfiguring ? (
                <>
                    <div className={styles.marketFlex}>
                        <div>
                            <h3>Categories</h3>
                            <br />
                            <ManageCategories nsCompanyId={nsCompanyId} refreshTimestamp={refreshTimestamp} />
                        </div>
                        <div>
                            <h3>Accessible Markets</h3>
                            <br />
                            <AccessibleMarkets nsCompanyId={nsCompanyId} refreshTimestamp={refreshTimestamp} />
                        </div>
                    </div>
                    <h3>Approved Markets</h3>
                    <br />
                    <ApprovedMarkets nsCompanyId={nsCompanyId} refreshTimestamp={refreshTimestamp} />
                    <br />
                    <br />
                    <h3>Omni Approved Markets</h3>
                    <br />
                    <OmniApprovedMarkets nsCompanyId={nsCompanyId} />
                    <h3>Omni Categories</h3>
                    <ManageOmniCategories nsCompanyId={nsCompanyId} />
                    <br />
                </>
            ) : (
                <p className={generalStyles.statusMessage}>Please wait as we configure your account in netsuite....</p>
            )}
        </>
    );
}
export default ConfigureProducts;
