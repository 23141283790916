import React, { useEffect, useMemo, useState } from 'react';
import { getOmniPriorApprovalMarkets } from '@/services/api/retailer.service';
import { getOmniApprovedMarkets, deleteOmniApprovedMarket } from '@/services/api/company.service';
import styles from './CompanyConfig.module.scss';
import generalStyles from '@/components/General/Styles.module.scss';
import API_STATES from '@/constants/StateConstants';
import DataTable from '../General/DataTable';
import { getOmniCategoriesWithKeys } from '@/services/api/product.service';
import AddOmniPriorApprovedMarketForm from './AddOmniPriorApprovedMarketForm';
import EditOmniPriorApprovedMarketForm from './EditOmniPriorApprovedMarketForm';
import { handleApiError } from '../General/HandleApiError';

function compareMarkets(market1, market2) {
    if (market1.parentRetailer < market2.parentRetailer) {
        return -1;
    }
    if (market1.parentRetailer > market2.parentRetailer) {
        return 1;
    }
    return 0;
}

function OmniApprovedMarkets({ nsCompanyId, isEditable = true, refreshTimestamp }) {
    const [omniApprovedMarketsGetApiState, setOmniApprovedMarketsGetApiState] = useState(API_STATES.none);
    const [omniApprovedMarketsGetApiError, setOmniApprovedMarketsGetApiError] = useState('');
    const [omniApprovedMarkets, setOmniApprovedMarkets] = useState([]);

    const [fetchOmniCategoriesApiState, setFetchOmniCategoriesApiState] = useState(API_STATES.none);
    const [fetchOmniCategoriesApiError, setFetchOmniCategoriesApiError] = useState('');
    const [omniCategories, setOmniCategories] = useState([]);

    const [allOmniMarketsGetApiState, setAllOmniMarketsGetApiState] = useState(API_STATES.none);
    const [allOmniMarketsGetApiError, setAllOmniMarketsGetApiError] = useState('');
    const [allOmniMarkets, setAllOmniMarkets] = useState([]);

    const [editOmniPriorApprovalMarket, setEditOmniPriorApprovalMarket] = useState(null);
    const [showAddOmniPriorApprovalMarketForm, setShowAddOmniPriorApprovalMarketForm] = useState(false);

    useEffect(() => {
        loadOmniApprovedMarkets(nsCompanyId);
    }, [nsCompanyId, refreshTimestamp]);

    useEffect(() => {
        if (isEditable) {
            loadAllOmniMarkets();
            loadOmniCategories();
        } else {
            setAllOmniMarkets([]);
            setOmniCategories([]);
        }
    }, [isEditable]);

    function getUnapprovedOmniMarkets() {
        const unapprovedOmniMarkets = allOmniMarkets
            .filter(function (market) {
                return omniApprovedMarkets.findIndex((approvedOmniMarket) => approvedOmniMarket.id === market.id) < 0;
            })
            .sort(compareMarkets);
        console.log({ unapprovedOmniMarkets });
        return unapprovedOmniMarkets;
    }

    async function loadAllOmniMarkets() {
        setAllOmniMarketsGetApiState(API_STATES.loading);
        const marketsResponse = await getOmniPriorApprovalMarkets();
        if (marketsResponse.status === API_STATES.success) {
            setAllOmniMarkets(marketsResponse.data);
            setAllOmniMarketsGetApiState(API_STATES.success);
        } else {
            handleApiError(marketsResponse.error, setAllOmniMarketsGetApiState, setAllOmniMarketsGetApiError);
            setAllOmniMarkets([]);
        }
    }

    async function loadOmniCategories() {
        setFetchOmniCategoriesApiState(API_STATES.loading);
        const categoriesResponse = await getOmniCategoriesWithKeys();
        if (categoriesResponse.status === API_STATES.success) {
            setOmniCategories(categoriesResponse.data);
            setFetchOmniCategoriesApiState(API_STATES.success);
        } else {
            setFetchOmniCategoriesApiError(categoriesResponse.error);
            setFetchOmniCategoriesApiState(API_STATES.error);
            setOmniCategories([]);
        }
    }

    async function loadOmniApprovedMarkets(nsCompanyId) {
        setOmniApprovedMarketsGetApiState(API_STATES.loading);
        let approvedMarketsResponse = await getOmniApprovedMarkets(nsCompanyId);
        if (approvedMarketsResponse.status === API_STATES.success) {
            setOmniApprovedMarkets(approvedMarketsResponse.data?.sort(compareMarkets));
            setOmniApprovedMarketsGetApiState(API_STATES.success);
        } else {
            handleApiError(
                approvedMarketsResponse.error,
                setOmniApprovedMarketsGetApiState,
                setOmniApprovedMarketsGetApiError
            );
            setOmniApprovedMarkets([]);
        }
    }

    async function initiateEdit(priorApprovalMarket) {
        setEditOmniPriorApprovalMarket(priorApprovalMarket);
    }

    async function initiateAddMarket() {
        setShowAddOmniPriorApprovalMarketForm(true);
    }

    async function onApprovedMarketsChange() {
        loadOmniApprovedMarkets(nsCompanyId);
    }

    const approvedMarketsTableColumns = useMemo(() => {
        const columns = [
            {
                Header: 'Parent Retailer',
                accessor: 'parentRetailer',
                className: generalStyles.tableCell,
            },
            {
                Header: 'Categories',
                accessor: (row) =>
                    !row.isPartial? ['ALL CATEGORIES'] : row.categories?.length ? row.categories?.map((category) => category?.category) : [],
                id: 'categories',
                className: generalStyles.tableCell,
                Cell: ({ value: categories }) => (
                    <ul className={styles.categoriesList}>
                        {categories.map((category) => (
                            <li key={category}>{category}</li>
                        ))}
                    </ul>
                ),
            },
            {
                Header: 'Confirmation URL',
                accessor: 'confirmationUrl',
                className: generalStyles.tableCell,
                Cell: ({ value: confirmationUrl }) => confirmationUrl || 'NA',
            },
        ];

        const actionsColumn = {
            Header: 'Actions',
            accessor: (row) => row,
            id: 'actions',
            disableFilters: true,
            disableSortBy: true,
            className: generalStyles.tableCell,
            Cell: ({ value: priorApprovalMarket }) => {
                const [deleteSelectedMarketApiState, setDeleteSelectedMarketApiState] = useState(API_STATES.none);
                const [deleteSelectedMarketApiError, setDeleteSelectedMarketApiError] = useState('');

                async function deleteSelectedMarket(marketId) {
                    setDeleteSelectedMarketApiState(API_STATES.loading);
                    const approveSelectedMarketResponse = await deleteOmniApprovedMarket(nsCompanyId, marketId);
                    if (approveSelectedMarketResponse.status === API_STATES.success) {
                        setDeleteSelectedMarketApiState(API_STATES.success);
                        setTimeout(() => loadOmniApprovedMarkets(nsCompanyId), 2000);
                    } else {
                        handleApiError(
                            approveSelectedMarketResponse.error,
                            setDeleteSelectedMarketApiState,
                            setDeleteSelectedMarketApiError
                        );
                        setTimeout(() => setDeleteSelectedMarketApiState(API_STATES.none), 2000);
                    }
                }

                return deleteSelectedMarketApiState === API_STATES.loading ? (
                    <label className={generalStyles.statusMessage}>Deleting...</label>
                ) : deleteSelectedMarketApiState === API_STATES.success ? (
                    <label className={generalStyles.successMessage}>Successfully deleted</label>
                ) : deleteSelectedMarketApiState === API_STATES.error ? (
                    <label className={generalStyles.errorMessage}>{deleteSelectedMarketApiError}</label>
                ) : (
                    <>
                        <button className={generalStyles.smallButton} onClick={() => initiateEdit(priorApprovalMarket)}>
                            Edit
                        </button>

                        <button
                            className={generalStyles.smallButton}
                            onClick={() => deleteSelectedMarket(priorApprovalMarket.id)}
                        >
                            Delete
                        </button>
                    </>
                );
            },
        };

        if (isEditable) {
            columns.push(actionsColumn);
        }
        return columns;
    }, [nsCompanyId, isEditable]);

    const waitToChangeApprovedMarkets =
        omniApprovedMarketsGetApiState !== API_STATES.success ||
        allOmniMarketsGetApiState !== API_STATES.success ||
        fetchOmniCategoriesApiState !== API_STATES.success;

    return (
        <>
            {omniApprovedMarketsGetApiState === API_STATES.error ? (
                <p className={generalStyles.errorMessage}>{omniApprovedMarketsGetApiError}</p>
            ) : showAddOmniPriorApprovalMarketForm ? (
                <AddOmniPriorApprovedMarketForm
                    categories={omniCategories}
                    unapprovedMarkets={getUnapprovedOmniMarkets()}
                    nsCompanyId={nsCompanyId}
                    onSuccess={onApprovedMarketsChange}
                    onClose={() => setShowAddOmniPriorApprovalMarketForm(false)}
                    disabled={waitToChangeApprovedMarkets || !isEditable}
                />
            ) : editOmniPriorApprovalMarket ? (
                <EditOmniPriorApprovedMarketForm
                    editMarket={editOmniPriorApprovalMarket}
                    categories={omniCategories}
                    nsCompanyId={nsCompanyId}
                    onSuccess={onApprovedMarketsChange}
                    onClose={() => setEditOmniPriorApprovalMarket(null)}
                    disabled={waitToChangeApprovedMarkets || !isEditable}
                />
            ) : omniApprovedMarketsGetApiState === API_STATES.loading ? (
                <p className={generalStyles.statusMessage}>Loading Approved Omni Markets.....</p>
            ) : (
                <>
                    <span className={styles.columnar}>
                        {/* <h3> Omni Prior Approved Markets</h3><hr /> */}

                        {isEditable && !showAddOmniPriorApprovalMarketForm && !editOmniPriorApprovalMarket && (
                            <button
                                className={generalStyles.submitButton}
                                disabled={waitToChangeApprovedMarkets}
                                onClick={() => initiateAddMarket()}
                            >
                                Add
                            </button>
                        )}
                    </span>
                    <br />
                    {allOmniMarketsGetApiState === API_STATES.loading ? (
                        <p className={generalStyles.statusMessage}>Loading Unapproved Omni Markets.....</p>
                    ) : (
                        allOmniMarketsGetApiState === API_STATES.error && (
                            <p className={generalStyles.errorMessage}>{allOmniMarketsGetApiError}</p>
                        )
                    )}

                    {fetchOmniCategoriesApiState === API_STATES.loading ? (
                        <p className={generalStyles.statusMessage}>Loading Omni Categories.....</p>
                    ) : (
                        fetchOmniCategoriesApiState === API_STATES.error && (
                            <p className={generalStyles.errorMessage}>{fetchOmniCategoriesApiError}</p>
                        )
                    )}

                    {omniApprovedMarkets.length === 0 ? (
                        <p className={generalStyles.statusMessage}>No approved omni markets</p>
                    ) : (
                        <DataTable
                            data={omniApprovedMarkets}
                            columns={approvedMarketsTableColumns}
                            nonStickyTableHeader={true}
                        />
                    )}
                    <br />
                </>
            )}
        </>
    );
}
export default OmniApprovedMarkets;
