import React, { useState } from 'react';
import AsyncSelect from 'react-select/async';
import debounce from 'lodash.debounce';
import API_STATES from '@/constants/StateConstants';
import { getUserAndCompanySearchResult } from '@/services/api/search.service';
import { handleApiError } from '@/components/General/HandleApiError';
import { useHistory } from 'react-router-dom';

export default function SearchBar(props) {
    const history = useHistory();

    const [companiesApiState, setCompaniesApiState] = useState(API_STATES.none);
    // eslint-disable-next-line
    const [companiesApiError, setCompaniesApiError] = useState('');
    const [selectedCompany, setSelectedCompany] = useState({});

    const handleSelectedCompany = (company) => {
        setSelectedCompany(company)
        if(company?.value) {
            navigateToCompanyPage(company.value, company.nsUserId)
        }
    };

    function navigateToCompanyPage(nsCompanyId, nsUserId) {
        setSelectedCompany({ label: 'Search', value: null })
        if(nsUserId) {
            // open users tab and expand selected user
            history.replace({ pathname: `/newcompanypage/${nsCompanyId}/users/${nsUserId}` });
        } else {
            // open company tab
            history.replace({ pathname: `/newcompanypage/${nsCompanyId}` });
        }
    }

    const loadCompanies = async (companySearchTerm, callback) => {
        if (companySearchTerm === '') {
            setCompaniesApiState(API_STATES.none);
            callback([]);
            return;
        }
        setCompaniesApiState(API_STATES.loading);
        const response = await getUserAndCompanySearchResult(companySearchTerm);
        if (response.status === API_STATES.success) {
            const companyData = response.data;
            callback(
                companyData.sort().map((res) => {
                    return {
                        label: `[${res.type}] ${res.displayName}`,
                        value: res.nsCompanyId,
                        nsUserId: res.nsUserId,
                    };
                })
            );
            setCompaniesApiState(API_STATES.success);
        } else {
            let error = response.error;
            handleApiError(error, setCompaniesApiState, setCompaniesApiError);
            callback([]);
        }
    };

    const loadCompaniesDebounced = debounce(loadCompanies, 1000);

    return (
                    <AsyncSelect
                        value={selectedCompany?.label ? selectedCompany : {}}
                        cacheOptions
                        backspaceRemovesValue
                        loadOptions={loadCompaniesDebounced}
                        onChange={handleSelectedCompany}
                        escapeClearsValue={false}
                        noOptionsMessage={() =>
                            companiesApiState === API_STATES.success &&
                            selectedCompany?.label === 'Search' && 
                            selectedCompany?.value === null
                                ? 'No companies or users match the search'
                                : 'Please enter company name or customer email address to search'
                        }
                    />
    );
}
