import React from 'react';
import ResetPasswordOnNextLogin from '@/components/ModifyCSR/ResetPasswordOnNextLogin';
import DisableCsrAuthentication from '@/components/ModifyCSR/DisableCsrAuthentication';
import DeleteCsr from '@/components/ModifyCSR/DeleteCsr';
import UpdateCsrNameAndRoles from '@/components/ModifyCSR/UpdateCsrNameAndRoles';
import ChangeCsrPassword from '@/components/ModifyCSR/ChangeCsrPassword';
import styles from '@/components/ModifyCSR/ModifyCSR.module.scss';
import OboUsage from '@/components/ModifyCSR/OboUsage';

function ProfileSettings(props) {
    const csrEmail = props.csrEmail;
    return (
        <>
            <div className={styles.settings_page}>
                <div>
                    <h3>Change Settings</h3>
                    <br />
                    <ResetPasswordOnNextLogin email={csrEmail} csrPasswordChanged={props.csrPasswordChanged} />
                    <br />
                    <DisableCsrAuthentication email={csrEmail} />
                    <br />
                    <UpdateCsrNameAndRoles email={csrEmail} />
                    <br />
                    <ChangeCsrPassword email={csrEmail} onCsrPasswordChange={props.onCsrPasswordChange} />
                    <br />
                    <DeleteCsr email={csrEmail} onDelete={props.onDelete} />
                    <br />
                    <OboUsage email={csrEmail} />
                    <br />
                </div>
            </div>
        </>
    );
}
export default ProfileSettings;
