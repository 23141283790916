import generalStyles from '@/components/General/Styles.module.scss';
import styles from '@/components/UserConfig/UserConfig.module.scss';
import API_STATES from '@/constants/StateConstants';
import { getAllTeamMembersByCompanyId } from '@/services/api/company.service';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import { handleApiError } from '../General/HandleApiError';
import { findSelectedOption, toOption } from '../utils/utils';
import { approveUser } from '@/services/api/userdata.service';
import USER_ROLES from '@/constants/UserRoles';
import ByzzerCSRModal from '../ByzzerCSRModal';

export function ApproveUser({ userDetails, nsCompanyId, teams, onUserApproved = async () => {}, isEditable }) {
    const [selectedAdmin, setSelectedAdmin] = useState(null);
    const [adminUsers, setAdminUsers] = useState([]);
    const [adminUsersApiStatus, setAdminUsersApiStatus] = useState(API_STATES.none);
    const [adminUsersApiError, setAdminUsersApiError] = useState('');

    const [selectedRole, setSelectedRole] = useState(null);
    const [selectedTeam, setSelectedTeam] = useState(null);

    const [approveUserApiState, setApproveUserApiState] = useState(API_STATES.none);
    const [approveUserApiError, setApproveUserApiError] = useState('');

    const [showApprovalForm, setShowApprovalForm] = useState(false);

    async function approveUserAsAdmin() {
        setApproveUserApiState(API_STATES.loading);
        const response = await approveUser(
            userDetails?.id,
            selectedRole.value,
            selectedTeam.value,
            selectedAdmin.value
        );
        if (response.status === API_STATES.success) {
            setApproveUserApiState(API_STATES.success);
            await onUserApproved();
        } else {
            handleApiError(response.error, setApproveUserApiState, setApproveUserApiError);
            setTimeout(() => setApproveUserApiState(API_STATES.none), 3000);
        }
    }

    async function loadAdminUsers(nsCompanyId) {
        setAdminUsersApiStatus(API_STATES.loading);
        setAdminUsers([]);
        // {role, isApproved, byzUserId, email} (only needs active admins)
        const result = await getAllTeamMembersByCompanyId(nsCompanyId);
        switch (result.status) {
            case API_STATES.success:
                const adminUsers = result.data?.filter((user) => user.role === 'admin' && user.isApproved);
                if (adminUsers?.length === 0) {
                    setAdminUsersApiStatus(API_STATES.error);
                    setAdminUsersApiError(
                        "The Company doesn't have any admins. Please complete email verification of an admin"
                    );
                } else {
                    setAdminUsersApiStatus(API_STATES.success);
                    setAdminUsers(adminUsers.map(({ byzUserId, email }) => toOption(byzUserId, email)));
                }
                break;
            case API_STATES.error:
                const error = result.error;
                handleApiError(error, setAdminUsersApiStatus, setAdminUsersApiError, 'Company User(s) not found');
                break;
            default:
                setAdminUsersApiError('');
                setAdminUsersApiStatus(API_STATES.none);
        }
    }
    function loadApprovalForm() {
        const userRole = findSelectedOption(userDetails?.role, USER_ROLES);
        setSelectedRole(userRole);
        const team = findSelectedOption(userDetails?.teamId, teams);
        setSelectedTeam(team);
        setShowApprovalForm(true);
    }
    const approvalForm = (
        <>
            <div className={generalStyles['input-wrapper']}>
                <label> Approve As: </label>
                <span className={generalStyles.formField}>
                    <Select
                        classNamePrefix={'react-select'}
                        options={adminUsers}
                        value={selectedAdmin}
                        onChange={setSelectedAdmin}
                        isDisabled={
                            adminUsersApiStatus !== API_STATES.success || approveUserApiState === API_STATES.loading
                        }
                    />
                </span>
                {adminUsersApiStatus === API_STATES.loading ? (
                    <div className={generalStyles.statusMessage}>Loading....</div>
                ) : (
                    adminUsersApiStatus === API_STATES.error && (
                        <div className={generalStyles.errorMessage}>{adminUsersApiError}</div>
                    )
                )}
            </div>
            <div className={generalStyles['input-wrapper']}>
                <label>Member Type:</label>
                <span className={generalStyles.formField}>
                    <Select
                        classNamePrefix={'react-select'}
                        options={USER_ROLES}
                        onChange={(selectedRole) => setSelectedRole(selectedRole)}
                        value={selectedRole}
                        isDisabled={!isEditable || approveUserApiState !== API_STATES.none}
                    />
                </span>
            </div>

            <div className={generalStyles['input-wrapper']}>
                <label>Team:</label>
                <span className={generalStyles.formField}>
                    <Select
                        classNamePrefix={'react-select'}
                        options={teams}
                        value={selectedTeam}
                        onChange={setSelectedTeam}
                        isDisabled={!isEditable || approveUserApiState !== API_STATES.none}
                    />
                </span>
            </div>
            <div className={styles.row}>
                <button
                    className={generalStyles.submitButton}
                    onClick={approveUserAsAdmin}
                    disabled={approveUserApiState !== API_STATES.none || !selectedAdmin}
                    data-test="approve-user-button"
                >
                    Approve
                </button>
                {approveUserApiState === API_STATES.loading ? (
                    <span className={generalStyles.statusMessage}>approving user...</span>
                ) : approveUserApiState === API_STATES.error ? (
                    <span className={generalStyles.errorMessage}>{approveUserApiError}</span>
                ) : (
                    approveUserApiState === API_STATES.success && (
                        <span className={generalStyles.successMessage}>Approved!</span>
                    )
                )}
            </div>
        </>
    );

    useEffect(() => {
        loadAdminUsers(nsCompanyId);
    }, [nsCompanyId]);

    return userDetails?.isPendingApproval ? (
        <>
            <span className={generalStyles.statusMessage}>
                User is <strong>not</strong> approved
            </span>
            {isEditable && (
                <button
                    className={generalStyles.submitButton}
                    onClick={loadApprovalForm}
                    data-test="approve-user-button"
                >
                    Approve User
                </button>
            )}
            {showApprovalForm && (
                <ByzzerCSRModal show={true} onClose={() => setShowApprovalForm(false)} headerType={'none'}>
                    {approvalForm}
                </ByzzerCSRModal>
            )}
        </>
    ) : (
        <div className={generalStyles.successMessage}>User is approved</div>
    );
}
