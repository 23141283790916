import React, { useState, useCallback } from 'react';
import SubscriptionBasedNotificationDetailedHistory from './SubscriptionBasedNotificationDetailedHistory';

function SubscriptionBasedNoficationView({ selectedNotification }) {
    const [refreshHistoryDetail, setRefreshHistoryDetail] = useState(true);

    const refreshNotificationDetailedList = useCallback(async (flag) => {
        setRefreshHistoryDetail(flag);
    }, []);

    return (
        <>
            <SubscriptionBasedNotificationDetailedHistory
                refreshHistory={refreshHistoryDetail}
                refresh={(flag) => refreshNotificationDetailedList(flag)}
                selectedNotification={selectedNotification}
            />
        </>
    );
}

export default SubscriptionBasedNoficationView;
