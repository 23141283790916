import React, { useMemo } from 'react';
import generalStyles from './Styles.module.scss';
import './DateRangeColumnFilter.scss';
import ByzzerDateRange from '@/components/ByzzerDateRange';

export const DateRangeColumnFilter = ({ column: { filterValue, setFilter, preFilteredRows, id } }) => {

    const dateRange = useMemo(() => {
        const dates = preFilteredRows.filter((row)=>Boolean(row.values[id])).map((row) => new Date(row.values[id]))
        if(!dates.length) {
            return {minDate: new Date(), maxDate: new Date()}
        }
        const range = { minDate: new Date(Math.min(...dates)), maxDate: new Date(Math.max(...dates)) }
        return range
    }, [id, preFilteredRows])

    function onChangeDateRange(startDate, endDate) {
        let fromDate = startDate === null || startDate === undefined ? null : new Date(startDate);
        let toDate = endDate === null || startDate === undefined ? null : new Date(endDate);
        if (fromDate !== filterValue?.from || toDate !== filterValue?.to) {
            setFilter({ from: fromDate, to: toDate });
        }
    }
    return (
        <div className={generalStyles.filterRow}>
            <ByzzerDateRange
                className={'date-range-column-filter'}
                onChange={onChangeDateRange}
                dateSelected={false}
                placeholder="Date Range"
                dateFormat={'MMM d, y'}
                minDate={dateRange.minDate}
                maxDate={dateRange.maxDate}
                defaultStartDate={dateRange.minDate}
                defaultEndDate={dateRange.maxDate}
                isSingleMonthCalendar={true}
            />
        </div>
    );
};
