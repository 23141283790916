import { getEmailVerifiedInfo } from '@/services/api/userdata.service';
import { useEffect, useState } from 'react';
import API_STATES from '@/constants/StateConstants';
import generalStyles from '@/components/General/Styles.module.scss';
import styles from '@/components/UserConfig/EmailVerification.module.scss';
import PropTypes from 'prop-types';
import ByzzerLink from '../General/ByzzerLink';
import { handleApiError } from '../General/HandleApiError';

function EmailVerification({ email, isEditable=true }) {
    const [emailVerificationInfoGetApiState, setEmailVerificationInfoGetApiState] = useState(API_STATES.loading);
    const [emailVerificationInfoGetApiError, setEmailVerificationInfoGetApiError] = useState('');
    const [emailVerificationInfo, setEmailVerificationInfo] = useState({});

    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
    const [copyStatus, setCopyStatus] = useState('');

    const fetchEmailVerificationInfo = async (email) => {
        setEmailVerificationInfoGetApiState(API_STATES.loading);
        const response = await getEmailVerifiedInfo(email);
        if (response.status === API_STATES.success) {
            setEmailVerificationInfo(response.data);
            setEmailVerificationInfoGetApiState(API_STATES.success);
        } else if (response.error) {
            setEmailVerificationInfo({});
            handleApiError(response.error, setEmailVerificationInfoGetApiState, setEmailVerificationInfoGetApiError, `Verification info not found for email ${email}`);
        }
    };

    const initializeEmailVerification = async () => {
        setShowConfirmationDialog(true);
    };

    const cancelEmailVerification = async () => {
        setShowConfirmationDialog(false);
    };

    const onVerifyEmail = async () => {
        window.open(emailVerificationInfo.emailVerificationLink, '_emailVerification');
        cancelEmailVerification();
    };

    const onCopyVerificationLink = async () => {
        navigator.clipboard.writeText(emailVerificationInfo.emailVerificationLink);
        setCopyStatus('Copied!');
        setTimeout(() => setCopyStatus(''), 1000);
    };

    useEffect(() => {
        fetchEmailVerificationInfo(email);
    }, [email]);

    return (
        <div className={styles.emailVerificationInfo}>
            {emailVerificationInfoGetApiState === API_STATES.loading ? (
                <label className={generalStyles.statusMessage}>Fetching email verification Info</label>
            ) : emailVerificationInfoGetApiState === API_STATES.error ? (
                <label className={generalStyles.errorMessage}>
                    Failed to fetch email verification Info: {emailVerificationInfoGetApiError}
                </label>
            ) : emailVerificationInfo.isVerified ? (
                <label className={generalStyles.successMessage}>User Email is Verified </label>
            ) : (
                <>
                    <label className={generalStyles.errorMessage}>
                        User Email is <b>not</b> Verified.
                    </label>
                    {
                        isEditable &&
                        <div>
                            You can either &nbsp;
                            <ByzzerLink label="verify email" onClick={initializeEmailVerification} />
                            &nbsp; or &nbsp;
                            <ByzzerLink label="copy verification link" onClick={onCopyVerificationLink} />
                            {copyStatus && <label className={generalStyles.successMessage}>( Copied )</label>}
                            &nbsp; and share it to customer.
                        </div>
                    }
                    {showConfirmationDialog && (
                        <>
                            <b className={generalStyles.errorMessage}>
                                This action is not reversible, and will set the {email} as verified.
                                <br />
                                Are you sure to proceed?
                            </b>
                            <button
                                data-test="confirm-email-verification-btn"
                                className={generalStyles.confirmOperationButton}
                                onClick={onVerifyEmail}
                            >
                                Yes, Verify
                            </button>
                            <button
                                data-test="cancel-email-verification-btn"
                                className={generalStyles.cancelOperationButton}
                                onClick={cancelEmailVerification}
                            >
                                Cancel
                            </button>
                        </>
                    )}
                </>
            )}
        </div>
    );
}

EmailVerification.propTypes = {
    email: PropTypes.string.isRequired,
    isEditable: PropTypes.bool,
};

export default EmailVerification;
