import React, { useState, useEffect } from 'react';
import styles from './AccountSetup.module.scss';
import generalStyles from '@/components/General/Styles.module.scss';
import { deleteInternalAdminUser, generateInternalAdminCredentials } from '@/services/api/company.service';
import API_STATES from '@/constants/StateConstants';
import { handleApiError } from '@/components/General/HandleApiError';

function AdminCredentials(props) {
    const [adminUsername, setAdminUsername] = useState('');
    const [adminPassword, setAdminPassword] = useState('');
    const [adminDeleted, setAdminDeleted] = useState(false);
    const [adminCredentialApiState, setAdminCredentialsApiState] = useState(API_STATES.none);
    const [adminCredentialApiError, setAdminCredentialsApiError] = useState('');
    const [deleteAdminApiState, setDeleteAdminApiState] = useState(API_STATES.none);
    const [deleteAdminApiError, setDeleteAdminApiError] = useState('');
    const [adminExists, setAdminExists] = useState(false);

    useEffect(() => {
        if (props.nsCompanyId) {
            setAdminUsername(props.adminUsername);
            setAdminPassword(props.adminPassword);
            setAdminDeleted(props.adminDeleted);
            setAdminExists(props.adminUsername && props.adminPassword);
        }
        // eslint-disable-next-line
    }, [props.nsCompanyId]);

    async function generateAdminData() {
        if (adminDeleted) {
            setAdminCredentialsApiError('Setup Complete: Admin Deleted!');
            setAdminCredentialsApiState(API_STATES.error);
        } else {
            setAdminCredentialsApiState(API_STATES.loading);
            const response = await generateInternalAdminCredentials(props.nsCompanyId, props.companyName);
            switch (response.status) {
                case API_STATES.success:
                    setAdminCredentialsApiState(API_STATES.success);
                    setAdminUsername(response.data.adminUsername);
                    setAdminPassword(response.data.adminPassword);
                    props.adminCreated();
                    setAdminExists(true);
                    setTimeout(() => setAdminCredentialsApiState(API_STATES.none), 3000);
                    break;
                case API_STATES.error:
                    let error = response.error;
                    handleApiError(error, setAdminCredentialsApiState, setAdminCredentialsApiError);
                    break;
                default:
                    setAdminCredentialsApiError('');
                    setAdminCredentialsApiState(API_STATES.none);
            }
        }
    }

    async function deleteAdminData() {
        setDeleteAdminApiState(API_STATES.loading);
        const response = await deleteInternalAdminUser(props.nsCompanyId);
        switch (response.status) {
            case API_STATES.success:
                setDeleteAdminApiState(API_STATES.success);
                props.adminCreated();
                props.onDeleteAdminManual();
                setAdminDeleted(true);
                setTimeout(() => setDeleteAdminApiState(API_STATES.none), 3000);
                setAdminExists(false);
                break;
            case API_STATES.error:
                let error = response.error;
                handleApiError(error, setDeleteAdminApiState, setDeleteAdminApiError);
                break;
            default:
                setDeleteAdminApiError('');
                setDeleteAdminApiState(API_STATES.none);
        }
    }

    return (
        <>
            <h3 className={styles.screenHeading}>Step 2 of 5: Admin Credentials</h3>
            <hr />
            {adminExists && !adminDeleted ? (
                <>
                    <div>
                        <h5>Test Internal-Admin Credentials</h5>
                        <div className={styles.purchaseItems}>
                            <b>Admin Email: </b>
                            <span>{adminUsername}</span>
                            <br />
                            <b>Admin Password: </b>
                            <span>{adminPassword}</span>
                        </div>
                    </div>
                    <button className={generalStyles.submitButton} onClick={deleteAdminData}>
                        Delete Admin
                    </button>
                    <span>
                        {deleteAdminApiState === API_STATES.error && (
                            <label className={generalStyles.errorMessage}>{deleteAdminApiError}</label>
                        )}
                        {deleteAdminApiState === API_STATES.loading && (
                            <label className={generalStyles.statusMessage}>Deleting Admin...</label>
                        )}
                        {deleteAdminApiState === API_STATES.success && (
                            <>
                                <label className={generalStyles.successMessage}>Successfully deleted!</label>
                            </>
                        )}
                    </span>
                </>
            ) : (
                <>
                    <button
                        className={generalStyles.submitButton}
                        onClick={generateAdminData}
                        disabled={adminCredentialApiState === API_STATES.success}
                    >
                        Create Admin
                    </button>
                    <span>
                        {adminCredentialApiState === API_STATES.error && (
                            <label className={generalStyles.errorMessage}>{adminCredentialApiError}</label>
                        )}
                        {adminCredentialApiState === API_STATES.loading && (
                            <label className={generalStyles.statusMessage}>Generating Credentials...</label>
                        )}
                        {adminCredentialApiState === API_STATES.success && (
                            <>
                                <label className={generalStyles.successMessage}>Successfully generated!</label>
                            </>
                        )}
                    </span>
                </>
            )}
        </>
    );
}
export default AdminCredentials;
