import generalStyles from '@/components/General/Styles.module.scss';

export const COLUMNS = [
    {
        Header: 'Action Time',
        accessor: 'when',
        id: 'when',
        className: generalStyles.tableCell
    },
    {
        Header: 'Company',
        accessor: 'company',
        id: 'company',
        className: `${generalStyles.tableCell} ${generalStyles.cap}`
    },
    {
        Header: 'User',
        accessor: 'user',
        id: 'user',
        className: generalStyles.tableCell
    },
    {
        Header: 'Action Type',
        accessor: 'action',
        id: 'actions',
        className: `${generalStyles.tableCell} ${generalStyles.cap}`
    },
    {
        Header: 'Product Activity',
        accessor: 'api',
        id: 'api',
        disableSortBy: true,
        className: generalStyles.tableCell
    }
];
