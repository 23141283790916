import React, { useState, useEffect, useRef, useCallback } from 'react';
import styles from './SetupNewCompany.module.scss';
import CompanyUsers from '@/components/CompanyUsersReport/CompanyUsers';
import InviteUsers from '../NewCompanyPage/InviteUsers';


function AddUsers({ nsCompanyId, adminCreated, deleteAdminManual }) {
    const usersRef = useRef(null);
    const onUserAdded = useCallback(() => {
        usersRef?.current?.getUserList();
    }, []);
    const [refreshTimestamp, setRefreshTimestamp] = useState(null)

    useEffect(() => {
        if (adminCreated === true || deleteAdminManual === true) {
            onUserAdded();
            setTimeout(() => setRefreshTimestamp(Date.now()), 5000);
        }
        // eslint-disable-next-line
    }, [adminCreated, deleteAdminManual]);

    return (
        <>
            <div className={styles.rowflex}>
                <div className={styles.inviteForm}>
                    <InviteUsers nsCompanyId={nsCompanyId} onChange={onUserAdded} refreshTimestamp={refreshTimestamp}/>
                </div>
                <div>
                    <h3>Company Users</h3>
                    <CompanyUsers nsCompanyId={nsCompanyId} ref={usersRef} />
                </div>
            </div>
        </>
    );
}
export default AddUsers;
