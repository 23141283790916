import API_STATES from '@/constants/StateConstants';
import generalStyles from '@/components/General/Styles.module.scss';
import { getCompanyBrandReport } from '@/services/api/report.service';
import { useEffect, useMemo, useState } from 'react';
import { handleApiError } from '../General/HandleApiError';
import DataTable, { FILTER_TYPES } from '../General/DataTable';
import { MultiSelectColumnFilter } from '../General/MultiSelectColumnFilter';
import { Link } from 'react-router-dom';
import { DateRangeColumnFilter } from '../General/DateRangeColumnFilter';
import { HasValueColumnFilter } from '../General/HasValueColumnFilter';

export function BrandReport() {
    const [reportApiStatus, setReportApiStatus] = useState(API_STATES.none);
    const [reportApiError, setReportApiError] = useState('');
    const [reportResult, setReportResult] = useState([]);

    async function loadReport() {
        setReportApiStatus(API_STATES.loading);
        const result = await getCompanyBrandReport();
        switch (result.status) {
            case API_STATES.success:
                setReportApiStatus(API_STATES.success);
                setReportResult(result.data);
                break;
            case API_STATES.error:
                handleApiError(result.error, setReportApiStatus, setReportApiError);
                break;
            default:
                setReportApiStatus(API_STATES.none);
                setReportApiError('');
        }
    }

    const brandReportColumns = useMemo(
        () => [
            {
                Header: 'Company Name',
                accessor: 'companyName',
                className: generalStyles.tableCell,
                Cell: ({ row, value }) => (
                    <Link className={generalStyles.link} to={`/newcompanypage/${row.original.nsCompanyId}`}>
                        {value}
                    </Link>
                ),
            },
            {
                Header: 'Subscription Type',
                accessor: 'subscription',
                className: generalStyles.tableCell,
                Filter: MultiSelectColumnFilter,
                filter: FILTER_TYPES.multiple,
            },
            {
                Header: 'Access Level',
                accessor: 'accessLevel',
                className: generalStyles.tableCell,
                Filter: MultiSelectColumnFilter,
                filter: FILTER_TYPES.multiple,
            },
            {
                Header: 'Brand',
                /**
                 *
                 * @param {Object} row
                 * @param {string[] | null} row.focusBrand
                 * @returns
                 */
                accessor: (row) => row.focusBrand?.join(', '),
                id: 'focusBrand',
                className: generalStyles.tableCell,
                Filter: HasValueColumnFilter,
                filter: FILTER_TYPES.hasValue,
            },
            {
                Header: 'Not selling in retailers',
                /**
                 *
                 * @param {Object} row
                 * @param {boolean | null} row.brandNotRetailingYet
                 * @returns 'Y'|'N'
                 */
                accessor: (row) => row.brandNotRetailingYet === true ? 'Y' : 'N',
                id: 'brandNotRetailingYet',
                className: generalStyles.tableCell,
            },
            {
                Header: 'Categories',
                /**
                 *
                 * @param {Object} row
                 * @param {string[] | null} row.categories
                 * @returns
                 */
                accessor: 'categories',
                className: generalStyles.tableCell,
                Filter: MultiSelectColumnFilter,
                filter: FILTER_TYPES.multiple,
                Cell: ({ value }) => value?.join(', ') ?? ''
            },
            {
                Header: 'Account Creation Date',
                accessor: (row) => new Date(row.createdOn).toLocaleDateString(),
                id: 'createdOn',
                className: generalStyles.tableCell,
                Filter: DateRangeColumnFilter,
                filter: FILTER_TYPES.dateRange,
            },
        ],
        []
    );

    useEffect(() => {
        loadReport();
    }, []);

    return (
        <>
            <h1>Brand Report</h1>
            <hr />
            <div data-test="generate-report-status">
                {reportApiStatus === API_STATES.error ? (
                    <p className={generalStyles.errorMessage}>Error: {reportApiError}</p>
                ) : reportApiStatus === API_STATES.loading ? (
                    <p className={generalStyles.statusMessage}>Loading...</p>
                ) : (
                    reportApiStatus === API_STATES.success && (
                        <DataTable
                            data={reportResult}
                            columns={brandReportColumns}
                            downloadFileName={'BrandReport'}
                            withDownload
                            noDataDescription='No companies found for brand report.'
                        />
                    )
                )}
            </div>
        </>
    );
}
