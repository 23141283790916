import React, { useState, useEffect, useMemo } from 'react';
import styles from './NewCompanyPage.module.scss';
import API_STATES from '@/constants/StateConstants';
import generalStyles from '@/components/General/Styles.module.scss';
import { handleApiError } from '@/components/General/HandleApiError';
import TextInput from '@/components/General/TextInput';
import Select from 'react-select';
import { getTeamsByCompanyId, inviteConsultant } from '@/services/api/company.service';
import isEmail from 'is-email';
import { toOption } from '../utils/utils';

const memberRoleOptions = [
    { value: 'admin', label: 'Admin' },
    { value: 'user', label: 'User' },
    { value: 'viewer', label: 'Viewer' }
];

const InviteMultiCompanyUsers = ({ nsCompanyId }) => {
    const [sendInviteApiStatus, setSendInviteApiStatus] = useState(API_STATES.none);
    const [sendInviteApiError, setSendInviteApiError] = useState('');
    const [email, setEmail] = useState('');
    const [selectedMemberRole, setSelectedMemberRole] = useState({});
    const [selectedTeam, setSelectedTeam] = useState({});
    const [teams, setTeams] = useState([]);
    const [teamsApiStatus, setTeamsApiStatus] = useState(API_STATES.none);
	const [teamsApiError, setTeamsApiError] = useState(API_STATES.none);

    const onEmailChange = (value) => {
        setEmail(value);
    };

    const handleSelectedMemberRole = (role) => {
        setSelectedMemberRole(role);
    };

    const handleSelectedTeam = (team) => {
        setSelectedTeam(team);
    };
    
    useEffect(() => {
		resetForm();
		if (nsCompanyId) {
			getTeamDetails(nsCompanyId);
		}
	}, [nsCompanyId]);

	async function getTeamDetails(nsCompanyId) {
        setTeamsApiStatus(API_STATES.loading);
		const result = await getTeamsByCompanyId(nsCompanyId);
		if (result.status === API_STATES.success) {
			setTeamsApiStatus(API_STATES.success);
			setTeams(result.data.map(({ id, title }) => toOption(id, title)));
		} else {
			let error = result.error;
			handleApiError(error, setTeamsApiStatus, setTeamsApiError);
        }
    }

    
    const resetForm = () => {
        setEmail('');
        setSelectedMemberRole({});
        setSelectedTeam({});
    };

    async function submitInvitation() {
        setSendInviteApiStatus(API_STATES.loading)
		const response = await inviteConsultant(
			email,
			selectedMemberRole.value,
			selectedTeam.value,
			nsCompanyId
		);
		if (response.status === API_STATES.success) {
			setSendInviteApiStatus(API_STATES.success);
			setTimeout(() => setSendInviteApiStatus(API_STATES.none), 3000);
			resetForm();
		} else {
			let error = response.error;
			handleApiError(error, setSendInviteApiStatus, setSendInviteApiError);
			setTimeout(() => setSendInviteApiStatus(API_STATES.none), 3000);
		}
	}

	const isEmailValid = useMemo(() => isEmail(email), [email])

	const isSubmitDisabled = !isEmailValid ||
		!selectedTeam.value ||
		!selectedMemberRole.value ||
		sendInviteApiStatus === API_STATES.loading ||
		sendInviteApiStatus === API_STATES.success

	return <div className={styles.inviteForm}>
		<div className={styles.formItem}>
			<TextInput
				type="email"
				label="Email:"
				placeholder="Email"
				value={email}
				onInputChange={(event) => onEmailChange(event.target.value)}
				disabled={sendInviteApiStatus === API_STATES.loading}
			/>
			{email && !isEmailValid && (
				<span className={generalStyles.errorMessage}>Invalid email format!</span>
			)}
		</div>
		<div className={generalStyles['input-wrapper']}>
			<label>Member Type:</label>
			<span className={generalStyles.formField}>
				<Select
					theme={(theme) => ({
						...theme,
						borderRadius: 4,
						colors: {
							...theme.colors,
							text: 'black',
							primary25: '#cfd1d2',
							primary: 'hsl(120, 100%, 47%)',
							neutral0: 'white'
						}
					})}
					onChange={handleSelectedMemberRole}
					options={memberRoleOptions}
					value={selectedMemberRole}
					isDisabled={sendInviteApiStatus === API_STATES.loading}
				/>
			</span>
		</div>
		<div className={generalStyles['input-wrapper']}>
			<label>Select a Team:</label>
			<span className={generalStyles.formField}>
				{teamsApiStatus === API_STATES.error ? (
					<p className={`${generalStyles.errorMessage} ${styles.errorMessage}`}>
						Error: {teamsApiError}
					</p>
				) : teamsApiStatus === API_STATES.loading ? (
					<p className={generalStyles.statusMessage}>Loading...</p>
				) : teamsApiStatus === API_STATES.none ? (
					<p></p>
				) : teamsApiStatus === API_STATES.success ? (
					<Select
						theme={(theme) => ({
							...theme,
							borderRadius: 4,
							colors: {
								...theme.colors,
								text: 'black',
								primary25: '#cfd1d2',
								primary: 'hsl(120, 100%, 47%)',
								neutral0: 'white'
							}
						})}
						onChange={handleSelectedTeam}
						options={teams}
						value={selectedTeam}
						isDisabled={sendInviteApiStatus === API_STATES.loading}
					/>
				) : null}
			</span>
		</div>

		<div className={styles.flexcontainer}>
			<button
				className={generalStyles.submitButton}
				disabled={isSubmitDisabled}
				onClick={submitInvitation}
				data-test="send-invite-btn"
			>
				Send Invite
			</button>
			{sendInviteApiStatus === API_STATES.error ? (
				<p className={`${generalStyles.errorMessage} ${styles.errorMessage}`}>
					Error: {sendInviteApiError}
				</p>
			) : sendInviteApiStatus === API_STATES.loading ? (
				<p className={generalStyles.statusMessage}>Loading...</p>
			) : sendInviteApiStatus === API_STATES.success && (
				<p className={generalStyles.successMessage}>User added to company successfully.</p>
			)}
		</div>                
	</div>
}

export default InviteMultiCompanyUsers