import generalStyles from '@/components/General/Styles.module.scss';
import React, { useEffect, useState } from 'react';
import API_STATES from '@/constants/StateConstants';
import { getFirebaseUser } from '@/services/api/userdata.service';
import { setDisableAuthValue } from '@/services/api/csrdata.service';
import { handleApiError } from '@/components/General/HandleApiError';

function DisableCsrAuthentication(props) {
    const email = props.email;

    const [fetchDisableAuthApiStatus, setFetchDisableAuthApiStatus] = useState(API_STATES.none);
    const [fetchDisableAuthApiError, setFetchDisableAuthApiError] = useState('');
    const [fetchDisableAuthApiResult, setFetchDisableAuthApiResult] = useState(false);

    const [updateDisableAuthApiStatus, setUpdateDisableAuthApiStatus] = useState(API_STATES.none);
    const [updateDisableAuthApiError, setUpdateDisableAuthApiError] = useState('');

    //get disabled auth value
    async function fetchCsrAuthenticationData() {
        setFetchDisableAuthApiStatus(API_STATES.loading);
        const result = await getFirebaseUser(email);
        switch (result.status) {
            case API_STATES.success:
                setFetchDisableAuthApiStatus(API_STATES.success);
                setFetchDisableAuthApiResult(result.data.disabled);
                break;
            case API_STATES.error:
                let error = result.error;
                handleApiError(error, setFetchDisableAuthApiStatus, setFetchDisableAuthApiError);
                break;
            default:
                setFetchDisableAuthApiError('');
                setFetchDisableAuthApiStatus(API_STATES.none);
        }
    }

    //set disable auth value
    async function updateCsrAuthenticationData() {
        setUpdateDisableAuthApiStatus(API_STATES.loading);
        const result = await setDisableAuthValue(email, !fetchDisableAuthApiResult);
        switch (result.status) {
            case API_STATES.success:
                setUpdateDisableAuthApiStatus(API_STATES.success);
                fetchCsrAuthenticationData();
                break;
            case API_STATES.error:
                let error = result.error;
                setUpdateDisableAuthApiStatus(API_STATES.error);
                switch (error) {
                    case 401:
                        setUpdateDisableAuthApiError('CSR is not logged in.');
                        break;
                    case 403:
                        setUpdateDisableAuthApiError('Unauthorised to perform this action');
                        break;
                    case 404:
                        setUpdateDisableAuthApiError('Unable to set Disable Authentication - data not found');
                        break;
                    default:
                        setUpdateDisableAuthApiError('Unknown error in setting Disable Authentication status');
                        break;
                }
                break;
            default:
                setUpdateDisableAuthApiError('');
                setUpdateDisableAuthApiStatus(API_STATES.none);
        }
    }

    useEffect(() => {
        (async () => {
            if (email !== null && email !== '') {
                fetchCsrAuthenticationData();
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [email]);

    return (
        <div data-test="disable-auth-component">
            {fetchDisableAuthApiStatus === API_STATES.loading ? (
                <label className={generalStyles.statusMessage}>Fetching Authentication Status</label>
            ) : fetchDisableAuthApiStatus === API_STATES.error ? (
                <label className={generalStyles.errorMessage}>
                    Failed to fetch Csr Authentication Status: {fetchDisableAuthApiError}
                </label>
            ) : (
                <>
                    <label>Disable Authentication: </label>
                    <input
                        data-test="disable-auth-checkbox"
                        type="checkbox"
                        checked={fetchDisableAuthApiResult}
                        onClick={() => updateCsrAuthenticationData()}
                    />
                    <label data-test="disable-auth-checkbox-result">
                        {updateDisableAuthApiStatus === API_STATES.loading ? (
                            <span className={generalStyles.statusMessage}>Processing...</span>
                        ) : updateDisableAuthApiStatus === API_STATES.error ? (
                            <span className={generalStyles.errorMessage}>
                                Failed to Update: {updateDisableAuthApiError}
                            </span>
                        ) : (
                            updateDisableAuthApiStatus === API_STATES.success && (
                                <span className={generalStyles.successMessage}>saved!</span>
                            )
                        )}
                    </label>
                </>
            )}
        </div>
    );
}
export default DisableCsrAuthentication;
