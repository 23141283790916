import React, { useState, useEffect, useMemo } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styles from './ReportRunsWithSelections.module.scss';
import calendarIcon from '@images/icons/calendar.svg';
import format from 'date-fns/format';
import AsyncSelect from 'react-select/async';
import debounce from 'lodash.debounce';
import { getReportFilters, getUserSelectionReport } from '@/services/api/report.service';
import { getMatchingBrands } from '@/services/api/product.service';
import { getMatchingCompanies } from '@/services/api/company.service';
import { toOption } from '@/components/utils/utils';
import { saveAs } from 'file-saver';
import generalStyles from '@/components/General/Styles.module.scss';
import API_STATES from '@/constants/StateConstants';
import Select, { createFilter } from 'react-select';
import ByzzerSwitch from  '@/components/General/ByzzerSwitch';

function ReportRunsWithSelections(props) {
    const [startDate, setStartDate] = useState(new Date(2021, 0, 1));
    const [endDate, setEndDate] = useState(new Date());
    const [startDateParam, setStartDateParam] = useState();
    const [endDateParam, setEndDateParam] = useState();
    const [downloading, setDownloading] = useState(API_STATES.none);
    const [initializing, setInitializing] = useState(true);

    const [brandsApiState, setBrandsApiState] = useState(API_STATES.none);
    const [brandsApiError, setBrandsApiError] = useState('');

    const [companiesApiState, setCompaniesApiState] = useState(API_STATES.none);
    const [companiesApiError, setCompaniesApiError] = useState('');

    const [filterOptions, setFilterOptions] = useState([])
    const [filterData, setFilterData] = useState({})
    const [selectedFilterData, setSelectedFilterData] = useState({
        summedCategory: false,
        summedMarket: false,
        summedBrand: false,
        summedTimePeriod: false,
        includeTestAccounts: false
    })

    const isGenerateReportEnabled = () => {
        return startDate > endDate
    };


    function calculateStartDate(weeks) {
        let newDate = new Date(endDate);
        newDate.setDate(endDate.getDate() - parseInt(7 * weeks));
        setStartDate(newDate);
    }

    async function onDownloadAsCSV(result) {
        if (endDate >= startDate) {
            setInitializing(false);
            const now = format(new Date(), 'yyyy-MM-dd.HH-mm-ss');
            const filename = `ReportRunsHistory-${now}.csv`;
            const heading = 'Date/Time, User email, Report Name, Report Type, Company Name, Category Selections, Brand Selections, Market Selections, Characteristic Dimensions, Characteristic Filters, Fact Selections, Time Period Selections\n';
            const data = heading.concat(result);
            const blob = new Blob([data], { type: 'text/csv; header=present; charset=utf-8' });
            saveAs(blob, filename);
            setDownloading(API_STATES.success);
            setTimeout(() => setDownloading(API_STATES.none), 4000);
        } else {
            setInitializing(false);
            setCompaniesApiError("Error: Unknown error")
            setDownloading(API_STATES.error)
        }
    }
    useEffect(() => {
        try {
            setStartDateParam(format(startDate, 'yyyy-MM-dd'));
        } catch (e) {
            const defaultStartDate = new Date(1970, 0, 1);
            setStartDate(defaultStartDate);
        }
    }, [startDate]);

    useEffect(() => {
        try {
            setEndDateParam(format(endDate, 'yyyy-MM-dd'));
        } catch (e) {
            const defaultEndDate = new Date(9999, 11, 31);
            setEndDate(defaultEndDate);
        }
    }, [endDate]);

    function handleSelectedFilterOption(e, getFilterType) {
        if (getFilterType === "summedBrand" || getFilterType === "summedCategory" || getFilterType === "summedMarket" || getFilterType === "summedTimePeriod" || getFilterType === "includeTestAccounts") {
            const getCheckboxValue = e.target.checked === true ? true : false
            setFilterData((prev) => ({ ...prev, [getFilterType]: getCheckboxValue }))
        } else {
            setFilterData((prev) => ({ ...prev, [getFilterType]: e }))
        }
    }

    useEffect(() => {
        loadFilterOptions()
    }, [])

    const loadBrands = async (brandSearchTerm, callback) => {
        if (brandSearchTerm === '') {
            setBrandsApiState(API_STATES.none);
            callback([]);
            return;
        }
        setBrandsApiState(API_STATES.loading);
        const response = await getMatchingBrands(brandSearchTerm);
        if (response.status === API_STATES.success) {
            const brandsData = response.data;
            callback(
                brandsData.brands.sort().map((brand) => {
                    return { label: brand, value: brand };
                })
            );
            setBrandsApiState(API_STATES.success);
        } else {
            setBrandsApiError(response.error);
            setBrandsApiState(API_STATES.error);
            callback([]);
        }
    }

    const loadBrandsDebounced = debounce(loadBrands, 1000);

    async function loadFilterOptions() {
        try {
            const result = await getReportFilters()
            if (result.status === API_STATES.success) {
                const filterOptionsObj = result.data;
                setFilterOptions(filterOptionsObj)
            }
        } catch (e) {
            console.log(e)
            setFilterOptions({})
        }
    }
    useEffect(()=>{
        Object.entries(filterData).forEach(([key, value]) => {
            if (Array.isArray(filterData?.[key])) {
                setSelectedFilterData((prev) => ({ ...prev, [key]: filterData[key]?.map((item) => item?.label) }))
            } else {
                setSelectedFilterData((prev) => ({
                    ...prev,
                    [key]: filterData[key]
                }))
            }
        });
    }, [filterData])

    console.log("newObj", selectedFilterData)

    async function onSubmitFilterOptions() {
        try{
            setDownloading(API_STATES.loading);
            const response = await getUserSelectionReport(startDateParam, endDateParam, selectedFilterData)
            if(response?.data){
                onDownloadAsCSV(response?.data)
            }else{
                setCompaniesApiError("No Data available to download report")
                setDownloading(API_STATES.error)
            }
        } catch (e){
            console.log(e)
        }
    }

    const loadCompanies = async (companySearchTerm, callback) => {
        if (companySearchTerm === '') {
            setCompaniesApiState(API_STATES.none);
            callback([]);
            return;
        }
        setCompaniesApiState(API_STATES.loading);
        const response = await getMatchingCompanies(companySearchTerm);
        if (response.status === API_STATES.success) {
            const companyData = response.data;
            callback(
                companyData.sort().map((company) => {
                    return { label: company.name, value: company.id };
                })
            );
            setCompaniesApiState(API_STATES.success);
        } else {
            setCompaniesApiState(API_STATES.error);
            callback([]);
        }
    };
    // console.log("newObj", selectedFilterData)
    // console.log("filterData-->", filterData)
    // console.log("filterOptions-->", filterOptions)

    const loadCompaniesDebounced = debounce(loadCompanies, 1000);

    return (
        <>
            <h1>Run History With User Selections</h1>
            <hr />
            <div className={styles.selectionContainer}>
                {/* <div> */}
                <div className={`${styles.formField}`}>
                    <label className={styles.formLabel}>From:</label>
                    <DatePicker
                        name="startDate"
                        placeholderText="mm/dd/yyyy"
                        shouldCloseOnSelect={true}
                        fixedHeight
                        closeOnScroll={true}
                        maxDate={new Date()}
                        dateFormat="MM/dd/yyyy"
                        selected={startDate}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        tabIndex={1}
                        onChange={(date) => {
                            setStartDate(date);
                        }}
                    />
                    <img src={calendarIcon} alt="icon" />
                </div>
                <div className={`${styles.formField} `}>
                    <label className={styles.formLabel}>To:</label>
                    <DatePicker
                        name="endDate"
                        placeholderText="mm/dd/yyyy"
                        shouldCloseOnSelect={true}
                        fixedHeight
                        closeOnScroll={true}
                        maxDate={new Date()}
                        dateFormat="MM/dd/yyyy"
                        selected={endDate}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        tabIndex={1}
                        onChange={(date) => {
                            setEndDate(date);
                        }}
                    />
                    <img src={calendarIcon} alt="icon" />
                </div>
                <button
                    className={styles.timePeriodButton}
                    onClick={(e) => calculateStartDate(1)}
                    name={'last1week'}
                >
                    Last 1 Week
                </button>
                <button
                    className={styles.timePeriodButton}
                    onClick={(e) => calculateStartDate(4)}
                    name={'last4weeks'}
                >
                    Last 4 Weeks
                </button>
                <button
                    className={styles.timePeriodButton}
                    onClick={(e) => calculateStartDate(13)}
                    name={'last13weeks'}
                >
                    Last 13 Weeks
                </button>
                <button
                    className={styles.timePeriodButton}
                    onClick={(e) => calculateStartDate(52)}
                    name={'last52weeks'}
                >
                    Last 52 Weeks
                </button>
            </div>
            <div className={styles.selectionContainer}>
                <div className={`${generalStyles['input-wrapper']} ${styles.inputContainer}`}>
                    <p className={styles.formLabel}> User Email: </p>
                    <span className={generalStyles.formField}>
                        <Select
                            id="selectBox"
                            classNamePrefix={'react-select'}
                            filterOption={createFilter({ ignoreAccents: false })}
                            isMulti={true}
                            closeMenuOnSelect={false}
                            onChange={(e) => handleSelectedFilterOption(e, 'userEmail')}
                            options={filterOptions?.userEmails?.map((item) => toOption(item)) ?? []}
                            value={filterData?.userEmail}
                            placeholder="Select from the list"
                        />
                    </span>
                </div>
                <div className={`${generalStyles['input-wrapper']} ${styles.inputContainer}`}>
                    <p className={styles.formLabel}> Report Type: </p>
                    <span className={generalStyles.formField}>
                        <Select
                            id="selectBox"
                            isMulti={true}
                            closeMenuOnSelect={false}
                            classNamePrefix={'react-select'}
                            onChange={(e) => handleSelectedFilterOption(e, 'reportType')}
                            options={filterOptions?.reportTypes?.map((item) => toOption(item)) ?? []}
                            value={filterData?.reportType}
                            placeholder="Select from the list"
                        />
                    </span>
                </div>
                <div className={`${generalStyles['input-wrapper']} ${styles.inputContainer}`}>
                    <p className={styles.formLabel}> Report Name: </p>
                    <span className={generalStyles.formField}>
                        <Select
                            id="selectBox"
                            isMulti={true}
                            filterOption={createFilter({ ignoreAccents: false })}
                            closeMenuOnSelect={false}
                            classNamePrefix={'react-select'}
                            onChange={(e) => handleSelectedFilterOption(e, 'reportName')}
                            options={filterOptions?.reportNames?.map((item) => toOption(item)) ?? []}
                            value={filterData?.reportName}
                            placeholder="Select from the list"
                        />
                    </span>
                </div>
                <div className={`${generalStyles['input-wrapper']} ${styles.inputContainer}`}>
                    <p className={styles.formLabel}> Company: </p>
                    <span className={generalStyles.formField}>
                            <AsyncSelect
                                cacheOptions
                                backspaceRemovesValue
                                isMulti={true}
                                closeMenuOnSelect={false}
                                loadOptions={loadCompaniesDebounced}
                                onChange={(e) => handleSelectedFilterOption(e, 'company')}
                                escapeClearsValue={false}
                                noOptionsMessage={() =>
                                    companiesApiState === API_STATES.success
                                        ? 'No companies match the search'
                                        : 'Please enter company name to search'
                                }
                            />
                    </span>
                </div>
                <div className={`${generalStyles['input-wrapper']} ${styles.inputContainer}`}>
                    <p className={styles.formLabel}> Category Selections: </p>
                    <span className={generalStyles.formField}>
                        <Select
                            id="selectBox"
                            isMulti={true}
                            closeMenuOnSelect={false}
                            classNamePrefix={'react-select'}
                            onChange={(e) => handleSelectedFilterOption(e, 'category')}
                            options={filterOptions?.categorySelections?.map((item) => toOption(item)) ?? []}
                            value={filterData?.category}
                            placeholder="Select from the list"
                        />
                    </span>
                </div>
                <div className={`${generalStyles['input-wrapper']} ${styles.inputContainer}`}>
                    <p className={styles.formLabel}> Brand Selections: </p>
                    <span className={generalStyles.formField}>
                        <AsyncSelect
                            cacheOptions
                            placeholder={"Select from the list"}
                            backspaceRemovesValue
                            closeMenuOnSelect={false}
                            loadOptions={loadBrandsDebounced}
                            value={filterData?.brand}
                            onChange={(e) => handleSelectedFilterOption(e, 'brand')}
                            isMulti={true}
                            escapeClearsValue={false}
                            noOptionsMessage={() =>
                                brandsApiState === API_STATES.success
                                    ? 'No brands match the search'
                                    : 'Please enter brand name to search'
                            }
                        />
                        {brandsApiState === API_STATES.error && (
                            <span className={generalStyles.errorMessage}>{brandsApiError}</span>
                        )}
                    </span>
                </div>
                <div className={`${generalStyles['input-wrapper']} ${styles.inputContainer}`}>
                    <p className={styles.formLabel}> Characteristic Filters: </p>
                    <span className={generalStyles.formField}>
                        <Select
                            id="selectBox"
                            filterOption={createFilter({ ignoreAccents: false })}
                            isMulti={true}
                            closeMenuOnSelect={false}
                            classNamePrefix={'react-select'}
                            onChange={(e) => handleSelectedFilterOption(e, 'characteristics')}
                            options={filterOptions?.characteristicFilters?.map((item) => toOption(item)) ?? []}
                            value={filterData?.characteristics}
                            placeholder="Select from the list"
                        />
                    </span>
                </div>
                <div className={`${generalStyles['input-wrapper']} ${styles.inputContainer}`}>
                    <p className={styles.formLabel}> Characteristic Dimensions: </p>
                    <span className={generalStyles.formField}>
                        <Select
                            id="selectBox"
                            filterOption={createFilter({ ignoreAccents: false })}
                            isMulti={true}
                            closeMenuOnSelect={false}
                            classNamePrefix={'react-select'}
                            onChange={(e) => handleSelectedFilterOption(e, 'charDimension')}
                            options={filterOptions?.characteristicFilters?.map((item) => toOption(item)) ?? []}
                            value={filterData?.charDimension}
                            placeholder="Select from the list"
                        />
                    </span>
                </div>
                <div className={`${generalStyles['input-wrapper']} ${styles.inputContainer}`}>
                    <p className={styles.formLabel}> Market Selections: </p>
                    <span className={generalStyles.formField}>
                        <Select
                            id="selectBox"
                            filterOption={createFilter({ ignoreAccents: false })}
                            isMulti={true}
                            closeMenuOnSelect={false}
                            classNamePrefix={'react-select'}
                            onChange={(e) => handleSelectedFilterOption(e, 'market')}
                            // options={marketOptions}
                            options={filterOptions?.marketSelections?.map((item) => toOption(item)) ?? []}
                            value={filterData?.market}
                            placeholder="Select from the list"
                        />
                    </span>
                </div>
                <div className={`${generalStyles['input-wrapper']} ${styles.inputContainer}`}>
                    <p className={styles.formLabel}> Fact Selections: </p>
                    <span className={generalStyles.formField}>
                        <Select
                            id="selectBox"
                            filterOption={createFilter({ ignoreAccents: false })}
                            isMulti={true}
                            closeMenuOnSelect={false}
                            classNamePrefix={'react-select'}
                            onChange={(e) => handleSelectedFilterOption(e, 'facts')}
                            options={filterOptions?.factsSelections?.map((item) => toOption(item)) ?? []}
                            value={filterData?.facts}
                            placeholder="Select from the list"
                        />
                    </span>
                </div>
                <div className={generalStyles['input-wrapper']}>
                    <p className={styles.formLabel}>Include Summed Brands: </p>
                    <input
                        type="checkbox"
                        checked={filterData?.summedBrand}
                        onChange={(e) => handleSelectedFilterOption(e, 'summedBrand')}
                    />
                </div>
                <div className={generalStyles['input-wrapper']}>
                    <p className={styles.formLabel}>Include Summed Categories: </p>
                    <input
                        type="checkbox"
                        checked={filterData?.summedCategory}
                        onChange={(e) => handleSelectedFilterOption(e, 'summedCategory')}
                    />
                </div>
                <div className={generalStyles['input-wrapper']}>
                    <p className={styles.formLabel}>Include Summed Markets: </p>
                    <input
                        type="checkbox"
                        checked={filterData?.summedMarket}
                        onChange={(e) => handleSelectedFilterOption(e, 'summedMarket')}
                    />
                </div>
                <div className={generalStyles['input-wrapper']}>
                    <p className={styles.formLabel}>Include Summed Time Periods: </p>
                    <input
                        type="checkbox"
                        checked={filterData?.summedTimePeriod}
                        onChange={(e) => handleSelectedFilterOption(e, 'summedTimePeriod')}
                    />
                </div>
                <div className={styles.includeTestAccounts}>
                    <b>Include Test Accounts</b>
                    <ByzzerSwitch name="includeTestAccounts" onChange={(e) => handleSelectedFilterOption(e, "includeTestAccounts")}></ByzzerSwitch>
                </div>
            </div>
            <br />
            <div className={styles.rowflex}>
                <div>
                    <button
                        className={generalStyles.submitButton}
                        name={'downloadcsv'}
                        disabled={isGenerateReportEnabled()}
                        onClick={onSubmitFilterOptions}
                    >
                        Download Report    

                    </button>
                    {downloading === API_STATES.loading ? (
                        <span className={generalStyles.statusMessage}>Downloading...</span>
                    ) : downloading === API_STATES.success ? (
                        <>
                            <span className={generalStyles.statusMessage}>
                                Your report is being downloaded to your browser...
                            </span>
                        </>
                    ) : downloading === API_STATES.error ? (
                        <>
                        {
                            <span className={generalStyles.errorMessage}>
                            {companiesApiError}
                            </span>
                        }
                        </>
                    ) : null}
                </div>
            </div>

        </>
    );
}

export default ReportRunsWithSelections;