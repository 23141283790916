import React, { useEffect, useState } from 'react';
import API_STATES from '@/constants/StateConstants';
import { getCompanyMultiTenantSettings, editCompanyMultiTenantSettings } from '@/services/api/company.service';
import styles from '@/components/CompanyConfig/CompanyConfig.module.scss';
import generalStyles from '@/components/General/Styles.module.scss';
import { handleApiError } from '@/components/General/HandleApiError';
import ByzzerSwitch from '../General/ByzzerSwitch';
import TextInput from '../General/TextInput';

function MultiTenantSettings({ nsCompanyId, isEditable = true, refreshTimestamp }) {
    const [agreementLink, setAgreementLink] = useState([]);
    const [isMultiTenant, setIsMultiTenant] = useState(null);
    const [multiTenantSettingsApiState, setMultiTenantSettingsApiState] = useState(API_STATES.none);
    const [multiTenantSettingsError, setMultiTenantSettingsError] = useState('');
    const [isInEditMode, setIsInEditMode] = useState(false);

    const isEditDisabled = multiTenantSettingsApiState !== API_STATES.success;

    useEffect(() => {
        apiLoadMultiTenantSettings(nsCompanyId);
    }, [nsCompanyId, refreshTimestamp]);

    async function apiLoadMultiTenantSettings(nsCompanyId) {
        setMultiTenantSettingsApiState((multiTenantSettingsApiState) =>
            multiTenantSettingsApiState === API_STATES.success ? API_STATES.refreshing : API_STATES.loading
        );
        const multiTenantSettings = await getCompanyMultiTenantSettings(nsCompanyId);
        switch (multiTenantSettings?.status) {
            case API_STATES.success:
                setAgreementLink(multiTenantSettings.data.multiTenantAgreement);
                setIsMultiTenant(multiTenantSettings.data.multiTenantEnabled);
                setMultiTenantSettingsApiState(API_STATES.success);
                break;

            default:
                handleApiError(multiTenantSettings.error, setMultiTenantSettingsApiState, setMultiTenantSettingsError);
                setAgreementLink('');
                setIsMultiTenant(null);
                break;
        }
    }

    const [editMultiTenantSettingsApiState, setEditMultiTenantSettingsApiState] = useState(API_STATES.none);
    const [editMultiTenantSettingsApiError, setEditMultiTenantSettingsApiError] = useState('');

    async function saveMultiTenantSettings() {
        setEditMultiTenantSettingsApiState(API_STATES.loading);
        const editMultiTenantSettingsResponse = await editCompanyMultiTenantSettings(nsCompanyId, isMultiTenant, agreementLink);
        if (editMultiTenantSettingsResponse.status === API_STATES.success) {
            setEditMultiTenantSettingsApiState(API_STATES.success);
            await apiLoadMultiTenantSettings(nsCompanyId)
            setEditMultiTenantSettingsApiState(API_STATES.none)
            closeEditForm()
        } else {
            handleApiError(editMultiTenantSettingsResponse.error, setEditMultiTenantSettingsApiState, setEditMultiTenantSettingsApiError);
            setTimeout(() => setEditMultiTenantSettingsApiState(API_STATES.none), 6000);
        }
    }

    function closeEditForm() {
        setIsInEditMode(false)
    }

    function openEditForm() {
        setIsInEditMode(true)
    }

    const multiTenantSettingsForm = <>
        {/* Parent Retailer */}
        <div className={generalStyles['input-wrapper']}>
            <label>Multi Company Login:</label>
            <ByzzerSwitch
                data-test="obo-mode-switch"
                onChange={({ target }) => setIsMultiTenant(target.checked)}
                value={isMultiTenant ?? false}
                disabled={!isEditable || !isInEditMode || editMultiTenantSettingsApiState !== API_STATES.none}
                disabledReason={
                    isEditable && !isInEditMode
                        ? 'Click the edit button to change the multi company settings'
                        : undefined // to show default error message
                }
            />
        </div>
        {/* Agreement Link */}
        <TextInput
            type="text"
            label="TPA Link:"
            placeholder="TPA Link"
            value={agreementLink}
            onInputChange={(event) => setAgreementLink(event.target.value)}
            disabled={!isEditable || !isInEditMode}
        />
        {isEditable && isInEditMode &&
            <div className={styles.columnar}>
                <button
                    className={generalStyles.submitButton}
                    onClick={saveMultiTenantSettings}
                    disabled={editMultiTenantSettingsApiState !== API_STATES.none || (isMultiTenant && !agreementLink)}
                    data-test="save-multi-tenant-settings-button"
                >
                    Save Changes
                </button>
                {editMultiTenantSettingsApiState === API_STATES.loading ? (
                    <span className={generalStyles.statusMessage}>Processing...</span>
                ) : editMultiTenantSettingsApiState === API_STATES.error ? (
                    <span className={generalStyles.errorMessage}>Error: {editMultiTenantSettingsApiError}</span>
                ) : (
                    editMultiTenantSettingsApiState === API_STATES.success && (
                        <>
                            <span className={generalStyles.successMessage}> Changes Saved </span>
                        </>
                    )
                )}
                {editMultiTenantSettingsApiState !== API_STATES.loading && editMultiTenantSettingsApiState !== API_STATES.success && (
                    <button className={generalStyles.submitButton} onClick={closeEditForm}>
                        Cancel
                    </button>
                )}
            </div>
        }
    </>

    const loadMultiTenantSettingsDisplay =
        multiTenantSettingsApiState === API_STATES.loading ? (
            <div className={generalStyles.statusMessage}>Loading MultiCompanySettings...</div>
        ) : multiTenantSettingsApiState === API_STATES.error ? (
            <div className={generalStyles.errorMessage}>Error: {multiTenantSettingsError}</div>
        ) : multiTenantSettingsForm;

    return (
        <div>
            <span data-test="multi-tenant-settings">{loadMultiTenantSettingsDisplay}</span>
            <span className={styles.columnar}>
                {isEditable && !isInEditMode && (
                    <button
                        className={generalStyles.submitButton}
                        disabled={isEditDisabled}
                        onClick={openEditForm}
                    >
                        Edit
                    </button>
                )}
            </span>
        </div>
    );
}

export default MultiTenantSettings;
