import React, { useState } from 'react';
import generalStyles from '@/components/General/Styles.module.scss';
import styles from '@/components/BundleManager/BundleManager.module.scss';
import API_STATES from '@/constants/StateConstants';
import * as API from '@/services/api/product.service';
import PropTypes from 'prop-types';
import { handleApiError } from '@/components/General/HandleApiError';

function DeleteBundle(props) {
    const [deleteBundleApiStatus, setDeleteBundleApiStatus] = useState(API_STATES.none);
    const [deleteBundleApiError, setDeleteBundleApiError] = useState('');
    const [deleteBundleSelected, setDeleteBundleSelected] = useState(false);

    async function deleteBundleItem() {
        setDeleteBundleApiStatus(API_STATES.loading);
        const result = await API.deleteBundle(props.bundleSku);
        switch (result.status) {
            case API_STATES.success:
                setDeleteBundleApiStatus(API_STATES.success);
                setDeleteBundleSelected(false);
                setTimeout(() => setDeleteBundleApiStatus(API_STATES.none), 1000);
                setTimeout(() => props.onBundleDelete(), 1500);
                break;
            case API_STATES.error:
                let error = result.error;
                handleApiError(error, setDeleteBundleApiStatus, setDeleteBundleApiError);
                break;
            default:
                setDeleteBundleApiError('');
                setDeleteBundleApiStatus(API_STATES.none);
        }
    }

    return (
        <>
            <div className={styles.column}>
                <div className={styles.row}>
                    <div>
                        <button
                            data-test="delete-bundle-btn"
                            className={generalStyles.submitButton}
                            name={'deletebundle'}
                            onClick={() => setDeleteBundleSelected(true)}
                        >
                            Delete this Bundle
                        </button>
                    </div>
                    <div>
                        <span data-test="delete-bundle-status">
                            {deleteBundleApiStatus === API_STATES.error ? (
                                <p className={generalStyles.errorMessage}>Error: {deleteBundleApiError}</p>
                            ) : deleteBundleApiStatus === API_STATES.none ? (
                                <p className={generalStyles.statusMessage}></p>
                            ) : deleteBundleApiStatus === API_STATES.loading ? (
                                <p className={generalStyles.statusMessage}>Deleting...</p>
                            ) : deleteBundleApiStatus === API_STATES.success ? (
                                <p className={generalStyles.successMessage}>Successfully deleted bundle</p>
                            ) : null}
                        </span>
                    </div>
                </div>
                <div>
                    {deleteBundleSelected && (
                        <div className={styles.warningDialogBox} data-test="delete-bundle-warning">
                            <b className={generalStyles.errorMessage}>
                                This action is not reversible, and will permanently delete the "{props.bundleSku}"
                                Bundle.
                                <br />
                                Are you sure to proceed?
                            </b>
                            <br />
                            <button
                                data-test="confirm-deletion-btn"
                                className={generalStyles.confirmOperationButton}
                                onClick={deleteBundleItem}
                                disabled={deleteBundleApiStatus === API_STATES.loading}
                            >
                                Yes, Delete this Bundle
                            </button>
                            <br />
                            <br />

                            <button
                                data-test="cancel-deletion-btn"
                                className={generalStyles.cancelOperationButton}
                                onClick={() => setDeleteBundleSelected(false)}
                                disabled={deleteBundleApiStatus === API_STATES.loading}
                            >
                                No, Do not Delete this Bundle
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

DeleteBundle.propTypes = {
    bundleSku: PropTypes.string.isRequired,
    onBundleDelete: PropTypes.func.isRequired
};

export default DeleteBundle;
