export const dataRefreshFrequencyTypes = [
    { label: 'weekly', value: 'weekly' },
    { label: 'biweekly', value: 'biweekly' },
    { label: 'monthly', value: 'monthly' },
];
export const companyAccessLevels = [
    { label: 'Weekly', value: 'weekly' },
    { label: 'Biweekly', value: 'biweekly' },
    { label: 'Monthly', value: 'monthly' },
];
export const isoWeekDaysOptions = [
    { label: 'Sunday', value: 7 },
    { label: 'Monday', value: 1 },
    { label: 'Tuesday', value: 2 },
    { label: 'Wednesday', value: 3 },
    { label: 'Thursday', value: 4 },
    { label: 'Friday', value: 5 },
    { label: 'Saturday', value: 6 },
];
export const timeZoneString = 'UTC';
export const isoWeekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
export const defaultFrequency = { label: 'weekly', value: 'weekly' };
export const defaultWeekday = { label: 'Tuesday', value: 2 };
export const defaultTime = { label: '00:00 ' + timeZoneString, value: '00:00' };
