import React, { useState } from 'react';
import styles from '@/components/AccountSetup/SetupNewCompany.module.scss';
import generalStyles from '@/components/General/Styles.module.scss';
import { purchaseProducts } from '@/services/api/company.service';
import API_STATES from '@/constants/StateConstants';
import { handleApiError } from '@/components/General/HandleApiError';

function Cart(props) {
    const [purchaseOrderApiState, setPurchaseOrderApiState] = useState(API_STATES.none);
    const [purchaseOrderApiError, setPurchaseOrderApiError] = useState('');
    const [disableFurtherPurchase, setDisableFurtherPurchase] = useState(false);
    
    async function placeOrder() {
        setPurchaseOrderApiState(API_STATES.loading);
        const newItems = props.items.map((item) => {
            return {
                nsCatalogItemId: item.nsCatalogItemId,
                sku: item.sku,
                quantity: item.quantity,
                rate: item.monthlyPrice,
                ttl: '',
            };
        });
        if (props.contractEnd < props.contractStart) {
            setPurchaseOrderApiState(API_STATES.error);
            setPurchaseOrderApiError('Error: Invalid contract dates range!');
            setTimeout(() => setPurchaseOrderApiState(API_STATES.none), 3000);
        } else {
            const today=new Date(new Date().setHours(0, 0, 0, 0));
                
            const response = await purchaseProducts(props.nsCompanyId, newItems, {
                createNew: false,
                startDtm: props?.addonStartDate,
                endDtm: props.contractEnd,
                billingFrequency: props.billingSchedule,
                paymentTerms: props.netsuitePaymentTerm,
                isEvergreen: props.isEvergreen,
                dynamicsDealNumber: props.dynamicsDealNum,
                isAddon: true,
            });
            switch (response.status) {
                case API_STATES.success:
                    setPurchaseOrderApiState(API_STATES.success);
                    setDisableFurtherPurchase(true);
                    setTimeout(props.setRefreshTimestamp(Date.now()));
                    break;
                case API_STATES.error:
                    let error = response.error;
                    handleApiError(error, setPurchaseOrderApiState, setPurchaseOrderApiError);
                    break;
                default:
                    setPurchaseOrderApiError('');
                    setPurchaseOrderApiState(API_STATES.none);
            }
        }

    }

    return (
        <>
            {props.items.length > 0 ? (
                props.items.map((item) => (
                    <>
                        <div className={styles.cart}>
                            <>
                                {item.type ? (
                                    <>
                                        <h5>Bundles:</h5>
                                        <div className={styles.child}>
                                            <b>Type: </b>
                                            {item.type}
                                            <br />
                                            <b>Sku: </b>
                                            {item.sku}
                                            <br />
                                            <b>Title: </b> {item.label}{' '}
                                            <b>
                                                <br />
                                                Quantity:
                                            </b>
                                            {item.quantity}
                                            <br />
                                            <>
                                                <b>Monthly Price: </b>${parseFloat(item.monthlyPrice).toFixed(2)}/month
                                                <br />
                                            </>
                                        </div>
                                    </>
                                ):(<>
                                <h5>Addon(s):</h5>
                                { (
                                        <>
                                            <div className={styles.child}>
                                                <b>Sku: </b>
                                                {item.sku}
                                                <br />
                                                <b>Title: </b>
                                                {item.label}
                                                <br />
                                                <b>Quantity: </b>
                                                {item.quantity} <br />
                                                {
                                                    <>
                                                        <b>Monthly Price: </b>${parseFloat(item.monthlyPrice).toFixed(2)}
                                                        /month
                                                    </>
                                                }
                                                <br />
                                                <br />
                                            </div>
                                        </>
                                    )}
                                
                                </>)}


                            </>

                            <div className={styles.billHeading}>
                                <div>
                                    <button
                                        className={generalStyles.smallButton}
                                        onClick={() => {
                                            props.removeCartItem(item.sku);
                                        }}
                                    >
                                        Remove Item
                                    </button>
                                </div>
                                <div>
                                    <b>Item Total: ${item.sectionTotal}</b>
                                </div>
                            </div>
                        </div>
                    </>
                ))
            ) : (
                <p className={generalStyles.statusMessage}>Your cart is empty!!!</p>
            )}
            <button
                className={`${generalStyles.submitButton}`}
                disabled={props.items.length <= 0 || disableFurtherPurchase}
                onClick={placeOrder}
            >
                Place Order
            </button>
            <span>
                {purchaseOrderApiState === API_STATES.loading && (
                    <label className={generalStyles.statusMessage}>Processing your order...</label>
                )}
                {purchaseOrderApiState === API_STATES.error && (
                    <label className={generalStyles.errorMessage}>{purchaseOrderApiError}</label>
                )}
                {purchaseOrderApiState === API_STATES.success && (
                    <label className={generalStyles.successMessage}>
                        Successfully placed order!
                    </label>
                )}
            </span>
        </>
    );
}
export default Cart;
