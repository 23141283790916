export const firebaseConfig = {
    apiKey: import.meta.env.REACT_APP_API_KEY,
    authDomain: import.meta.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: import.meta.env.REACT_APP_DATABASE_URL,
    projectId: import.meta.env.REACT_APP_PROJECT_ID,
    storageBucket: import.meta.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: import.meta.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: import.meta.env.REACT_APP_APP_ID,
    measurementId: import.meta.env.REACT_APP_MEASUREMENT_ID
};
export const buildInfo = {
    git: {
        sha: import.meta.env.REACT_APP_GIT_SHA,
        tag: import.meta.env.REACT_APP_GIT_TAG,
        dirty: import.meta.env.REACT_APP_GIT_DIRTY === 'true'
    },
    dtm: new Date(import.meta.env.REACT_APP_BUILD_DTM),
    version: import.meta.env.REACT_APP_VERSION,
    env: import.meta.env.REACT_APP_BYZZER_ENV
};

if (import.meta.env.REACT_APP_HIDE_BUILD_INFO !== 'true') {
    console.log({ buildInfo });
}
