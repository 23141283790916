import React from 'react'
import { approveInvitation } from '@/services/api/userdata.service';
import { useEffect, useState } from 'react';
import API_STATES from '@/constants/StateConstants';
import generalStyles from '@/components/General/Styles.module.scss';
import styles from './UserConfig.module.scss'
import { handleApiError } from '../General/HandleApiError';
import { Button } from 'antd';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

function UserInvitationApproval({ email, nsCompanyId, isInvitationPendingApproval: isInvitationPendingApprovalProp }) {
    const [openModal, setOpenModal] = useState(false)
    const [isInvitationPendingApproval, setIsInvitationPendingApproval] = useState(isInvitationPendingApprovalProp)

    const [approveInvitationApiStatus, setApproveInvitationApiStatus] = useState(API_STATES.none)
    const [approveInvitationApiError, setApproveInvitationApiError] = useState('')

    useEffect(() => {
        setIsInvitationPendingApproval(isInvitationPendingApprovalProp)
    }, [isInvitationPendingApprovalProp])

    async function submitApproveInvitation(email, nsCompanyId) {
        setOpenModal(false)
        setApproveInvitationApiStatus(API_STATES.loading)
        const result = await approveInvitation(email, nsCompanyId)
        if (result.status === API_STATES.success) {
            setApproveInvitationApiStatus(API_STATES.success)
            setIsInvitationPendingApproval(false)
        } else {
            handleApiError(result.error, setApproveInvitationApiStatus, setApproveInvitationApiError)
        }
        setTimeout(() => setApproveInvitationApiStatus(API_STATES.none), 5000)
    }

    return (
        <div data-test="approve-invitation-component">
            <>
                {!isInvitationPendingApproval ?
                    <p className={generalStyles.successMessage}>Invitation domain is approved</p>
                    : <>
                        <span className={generalStyles.statusMessage}>The invitation domain needs approval to process invitation</span>
                        <Button className={generalStyles.submitButton} onClick={() => setOpenModal(true)} disabled={approveInvitationApiStatus === API_STATES.loading}>Approve Invitation</Button>
                        {approveInvitationApiStatus === API_STATES.loading ? (
                            <span className={generalStyles.statusMessage}>approving invitation...</span>
                        ) : approveInvitationApiStatus === API_STATES.error ? (
                            <span className={generalStyles.errorMessage}>{approveInvitationApiError}</span>
                        ) : approveInvitationApiStatus === API_STATES.success && (
                            <span className={generalStyles.successMessage}>Domain Approved</span>
                        )}
                    </>
                }
            </>
            {openModal && <Popup
                open={openModal}
                onClose={() => {
                    setOpenModal(false);
                }}
                modal
            >
                {() => (
                    <div className={styles.confirmationPopupContainer}>
                        <b>Do you want to approve the user invitation with different domain ? </b>
                            <br />
                            <div className={generalStyles.row}>
                            <button className={generalStyles.submitButton} onClick={() => submitApproveInvitation(email, nsCompanyId)}>
                                Confirm
                            </button>
                            <button className={generalStyles.submitButton} onClick={()=>setOpenModal(false)}>Cancel</button>
                            </div>
                        </div>
                )}
            </Popup>}

        </div>
    )
}

export default UserInvitationApproval