import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './ByzzerDateRange.scss';
import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import { DateRangePicker } from 'react-date-range';
import { useClickOutsideHandler } from '@/components/utils/utils';
import classnames from 'classnames';
import { format } from 'date-fns';
import classNames from 'classnames';

const baseClassName = `byzzer-date-range`;
export const ByzzerDateRange = forwardRef(
    (
        {
            className,
            isSingleMonthCalendar = false,
            onChange,
            placeholder,
            clearText = 'All',
            clearDateRange,
            dateFormat = 'MM/DD/YYYY',
            minDate = null,
            maxDate = null,
            defaultStartDate,
            defaultEndDate,
            disabled,
        },
        ref
    ) => {
        const compRef = useRef();
        const [dateRange, setDateRange] = useState(null);
        const [expanded, setExpanded] = useState(false);
        const [displayValue, setDisplayValue] = useState('');
        const [ranges, setRanges] = useState([
            { startDate: new Date(), endDate: new Date(), disabled: disabled, key: 'selection' },
        ]);

        useEffect(() => {
            if (dateRange === null && defaultEndDate && defaultStartDate) {
                setDateRange({
                    endDate: defaultEndDate ? new Date(defaultEndDate) : null,
                    startDate: defaultStartDate ? new Date(defaultStartDate) : null,
                });
            }
            if (dateRange) {
                setRanges([
                    {
                        ...dateRange,
                        key: 'selection',
                    },
                ]);
                setDisplayValue(
                    `${format(new Date(dateRange.startDate), dateFormat)} - ${format(
                        new Date(dateRange.endDate),
                        dateFormat
                    )}`
                );
            } else {
                setRanges([{ startDate: new Date(), endDate: new Date(), key: 'selection' }]);
                if (defaultEndDate && defaultStartDate) {
                    setDisplayValue(
                        `${format(new Date(defaultStartDate), dateFormat)} - ${format(
                            new Date(defaultEndDate),
                            dateFormat
                        )}`
                    );
                } else setDisplayValue('');
            }
        }, [dateRange, dateFormat, defaultEndDate, defaultStartDate]);

        useEffect(() => {
            if (clearDateRange) {
                setDateRange(null);
                onChange(null, null);
            }
        }, [clearDateRange, onChange]);

        useClickOutsideHandler(compRef, () => {
            setExpanded(false);
        });

        const handleOnChange = ({ selection }) => {
            if (selection) {
                const { startDate, endDate } = selection;
                setDateRange({ startDate, endDate });
                onChange?.(startDate, endDate);
            } else {
                setDateRange(null);
                onChange?.();
            }
        };

        const handleShowAll = (e) => {
            e.stopPropagation();
            setDateRange(null);
            onChange(null, null);
        };

        useImperativeHandle(ref, () => ({
            showAll() {
                handleShowAll();
            },
        }));

        function onHeaderClick({ target }) {
            setExpanded((expanded) => !expanded);
        }

        return (
            <div className={classnames(baseClassName, className)} ref={compRef}>
                <div className="byzzer-date-range__header" onClick={onHeaderClick}>
                    {!dateRange && <div className={'byzzer-date-range__placeholder'}>{placeholder}</div>}
                    {Boolean(dateRange) && (
                        <>
                            <div className={'byzzer-date-range__value'}>{displayValue}</div>
                            <div className={'byzzer-date-range__clear'} onClick={handleShowAll}>
                                {clearText}
                            </div>
                        </>
                    )}
                </div>
                {expanded && (
                    <DateRangePicker
                        className={classNames(`${baseClassName}__date-range-picker`,{
                            [`${baseClassName}__single-month`]: isSingleMonthCalendar
                        })}
                        onChange={handleOnChange}
                        showSelectionPreview={false}
                        moveRangeOnFirstSelection={false}
                        months={isSingleMonthCalendar? 1 : 2}
                        ranges={ranges}
                        direction="horizontal"
                        showMonthAndYearPickers={true}
                        showDateDisplay={false}
                        // shownDate={false}
                        showPreview={true}
                        dragSelectionEnabled={true}
                        maxDate={maxDate}
                        minDate={minDate}
                        startDate={new Date(defaultStartDate)}
                        startDateId="startDate"
                        endDate={new Date(defaultEndDate)}
                        endDateId="endDate"
                    />
                )}
            </div>
        );
    }
);
export default ByzzerDateRange;
