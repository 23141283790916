import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import API_STATES from '@/constants/StateConstants';
import styles from '@/components/CompanyConfig/CompanyConfig.module.scss';
import { setBrandsForCompany } from '@/services/api/company.service';
import generalStyles from '@/components/General/Styles.module.scss';
import { handleApiError, noOp } from '@/components/General/HandleApiError';
import { toOption } from '../utils/utils';
import { getMatchingBrands } from '@/services/api/product.service';
import debounce from 'lodash.debounce';

export function EditBrands({
    nsCompanyId,
    selectedBrands: propSelectedBrands,
    onSuccess = noOp,
    onCancel = noOp,
}) {
    const [selectedBrands, setSelectedBrands] = useState([]);

    const [updateBrandsApiState, setUpdateBrandsApiState] = useState(API_STATES.none);
    const [updateBrandsError, setUpdateBrandsError] = useState('');

    const [brandsApiState, setBrandsApiState] = useState(API_STATES.none);
    const [brandsApiError, setBrandsApiError] = useState('');
    
    const loadBrands = async (brandSearchTerm, callback) => {
        if (brandSearchTerm === '') {
            setBrandsApiState(API_STATES.none);
            callback([]);
            return;
        }
        setBrandsApiState(API_STATES.loading);
        const response = await getMatchingBrands(brandSearchTerm);
        if (response.status === API_STATES.success) {
            const brandsData = response.data;
            callback(
                brandsData.brands.sort().map((brand) => {
                    return { label: brand, value: brand };
                })
            );
            setBrandsApiState(API_STATES.success);
        } else {
            setBrandsApiError(response.error);
            setBrandsApiState(API_STATES.error);
            callback([]);
        }
    };
    const loadBrandsDebounced = debounce(loadBrands, 1000);

    const saveSelectedBrands = async (brands) => {
        setUpdateBrandsApiState(API_STATES.loading);
        const brandNames = brands.map(({value})=>value)
        const response = await setBrandsForCompany(nsCompanyId, brandNames);
        switch (response.status) {
            case API_STATES.success:
                setUpdateBrandsApiState(API_STATES.success);
                onSuccess();
                break;
            default:
                handleApiError(response.error, setUpdateBrandsApiState, setUpdateBrandsError);
                break;
        }
        setTimeout(() => setUpdateBrandsApiState(API_STATES.none), 3000);
    };

    const isSaveDisabled = updateBrandsApiState === API_STATES.loading;

    const saveSelectedBrandsStatusDisplay =
        updateBrandsApiState === API_STATES.loading ? (
            <span className={generalStyles.statusMessage}>Saving...</span>
        ) : updateBrandsApiState === API_STATES.success ? (
            <span className={generalStyles.successMessage}>Success</span>
        ) : updateBrandsApiState === API_STATES.error ? (
            <span className={generalStyles.errorMessage}>{updateBrandsError}</span>
        ) : (
            <button className={generalStyles.submitButton} onClick={() => onCancel()}>
                Cancel
            </button>
        );

    useEffect(() => {
        setSelectedBrands(propSelectedBrands.map((brand) => toOption(brand)) ?? []);
    }, [propSelectedBrands]);

    return (
        <>
            <div className={generalStyles.multiSelectInput} data-test="brand-list">
                
                <AsyncSelect
                    cacheOptions
                    backspaceRemovesValue
                    loadOptions={loadBrandsDebounced}
                    value={selectedBrands}
                    onChange={setSelectedBrands}
                    isMulti={true}
                    escapeClearsValue={false}
                    noOptionsMessage={() =>
                        brandsApiState === API_STATES.success
                            ? 'No brands match the search'
                            : 'Please enter brand name to search'
                    }
                />
                {brandsApiState === API_STATES.error && (
                    <span className={generalStyles.errorMessage}>{brandsApiError}</span>
                )}
            </div>
            <div className={styles.columnar}>
                <button
                    className={generalStyles.submitButton}
                    disabled={isSaveDisabled}
                    onClick={() => saveSelectedBrands(selectedBrands)}
                >
                    Save
                </button>
                {saveSelectedBrandsStatusDisplay}
            </div>
        </>
    );
}
