import React, { useState } from 'react';
import generalStyles from '@/components/General/Styles.module.scss';
import TextInput from '@/components/General/TextInput';
import styles from '@/components/BundleManager/BundleManager.module.scss';
import * as API from '@/services/api/product.service';
import API_STATES from '@/constants/StateConstants';
import { DebounceInput } from 'react-debounce-input';
import PropTypes from 'prop-types';
import { handleApiError } from '@/components/General/HandleApiError';

function CreateBundle(props) {
    const bundles = props.bundles;
    const [bundleSku, setBundleSku] = useState('');
    const [bundleTitle, setBundleTitle] = useState('');
    const [newChildSku, setNewChildSku] = useState(0);

    const [childSkuApiStatus, setChildSkuApiStatus] = useState(API_STATES.none);
    const [childSkuApiError, setChildSkuApiError] = useState('');

    const [createBundleApiStatus, setCreateBundleApiStatus] = useState(API_STATES.none);
    const [createBundleApiError, setCreateBundleApiError] = useState('');

    const [childSkuData, setChildSkuData] = useState({});
    const [productSkuArray, setProductSkuArray] = useState([]);

    async function createNewBundle() {
        setCreateBundleApiStatus(API_STATES.loading);
        const result = await API.createBundle(bundleSku, bundleTitle, productSkuArray);
        switch (result.status) {
            case API_STATES.success:
                setCreateBundleApiStatus(API_STATES.success);
                setBundleSku('');
                setBundleTitle('');
                setProductSkuArray([]);
                setNewChildSku(0);
                setChildSkuApiError('');
                setChildSkuApiStatus(API_STATES.none);
                setTimeout(() => setCreateBundleApiStatus(API_STATES.none), 1000);
                setTimeout(() => props.onNewBundleCreation(), 2000);
                break;
            case API_STATES.error:
                let error = result.error;
                handleApiError(error, setCreateBundleApiStatus, setCreateBundleApiError);
                break;
            default:
                setCreateBundleApiError('');
                setCreateBundleApiStatus(API_STATES.none);
        }
    }

    async function getChildSkuData(childSku) {
        setChildSkuApiStatus(API_STATES.loading);
        const result = await API.getChildSkuDetails(childSku);
        switch (result.status) {
            case API_STATES.success:
                if (result.data) {
                    setChildSkuApiStatus(API_STATES.success);
                    setChildSkuData(result.data);
                } else {
                    setChildSkuApiStatus(API_STATES.error);
                    setChildSkuApiError('Invalid sku entry');
                }
                break;
            case API_STATES.error:
                let error = result.error;
                setChildSkuApiStatus(API_STATES.error);
                switch (error) {
                    case 401:
                        setChildSkuApiError('CSR is not logged in.');
                        break;
                    case 403:
                        setChildSkuApiError('Unauthorised to perform this action');
                        break;
                    case 404:
                        setChildSkuApiError(`Child sku ${childSku} not found`);
                        break;
                    case 422:
                        setChildSkuApiError(`A bundle or subscription SKU can't be added to the bundle`);
                        break;
                    default:
                        setChildSkuApiError('Unknown error in fetching child sku data');
                        break;
                }
                break;
            default:
                setChildSkuApiError('');
                setChildSkuApiStatus(API_STATES.none);
        }
    }

    const isSubmitEnabled = () => {
        return (
            bundleSku !== '' &&
            bundleTitle !== '' &&
            productSkuArray.length !== 0 &&
            createBundleApiStatus !== API_STATES.loading
        );
    };

    const addProductSku = () => {
        setProductSkuArray([...productSkuArray, newChildSku]);
        setChildSkuApiStatus(API_STATES.none);
        setNewChildSku(0);
        setChildSkuData({});
    };

    const checkBundleExistence = () => {
        if (bundles.some((bundle) => bundle.bundleSku === bundleSku)) {
            setCreateBundleApiStatus(API_STATES.error);
            setCreateBundleApiError('Bundle with this sku already exists');
        } else {
            createNewBundle();
        }
    };

    const removeProductSku = (sku) => {
        setProductSkuArray(productSkuArray.filter((productItem) => productItem !== sku));
    };

    const onDebounceInputChange = (value) => {
        setChildSkuApiStatus(API_STATES.none);
        setChildSkuApiError('');
        setChildSkuData({});
        setNewChildSku(value);
        if (value !== null && value !== 0 && value !== '') {
            if (productSkuArray.some((product) => product === value)) {
                setChildSkuApiStatus(API_STATES.error);
                setChildSkuApiError('Duplicate sku not allowed in a bundle');
            } else {
                getChildSkuData(value);
            }
        }
    };

    return (
        <>
            <h3>Create New Bundle</h3>
            <TextInput
                type="text"
                label="Bundle Sku"
                placeholder="Bundle Sku"
                value={bundleSku}
                onInputChange={(event) => {
                    setBundleSku(event.target.value);
                    setCreateBundleApiStatus(API_STATES.none);
                    setCreateBundleApiError('');
                }}
                disabled={createBundleApiStatus === API_STATES.loading}
            />
            <TextInput
                type="text"
                label="Bundle Title"
                placeholder="Bundle Title"
                value={bundleTitle}
                onInputChange={(event) => setBundleTitle(event.target.value)}
                disabled={createBundleApiStatus === API_STATES.loading}
            />

            <h5>Add Product Skus</h5>
            {productSkuArray.length === 0 ? (
                <p className={generalStyles.statusMessage}>No product skus selected</p>
            ) : (
                <ul data-test="product-sku-list" className={styles.tileList}>
                    {productSkuArray.map((sku) => (
                        <li data-test="product-sku-list-item" key={sku}>
                            <strong className={styles.removeButton} onClick={() => removeProductSku(sku)}>
                                X
                            </strong>
                            {sku}
                        </li>
                    ))}
                </ul>
            )}

            <table className={styles.bundleTable}>
                <thead>
                    <tr>
                        <th>Sku</th>
                        <th>Product Name</th>
                        <th>Product Type</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className={styles.tableInputContainer}>
                            <DebounceInput
                                data-test="create-bundle-debounce-input"
                                placeholder="child sku"
                                className={styles.tableInput}
                                value={newChildSku === 0 ? '' : newChildSku}
                                minLength={1}
                                debounceTimeout={1000}
                                onChange={(event) => onDebounceInputChange(event.target.value)}
                            />
                        </td>
                        <td className={generalStyles.cap}>
                            {childSkuApiStatus === API_STATES.success ? childSkuData?.title || 'Unknown Title' : ''}
                            <label data-test="get-child-sku-result">
                                {childSkuApiStatus === API_STATES.loading ? (
                                    <span className={generalStyles.statusMessage}>Fetching data...</span>
                                ) : childSkuApiStatus === API_STATES.error ? (
                                    <span className={generalStyles.errorMessage}>Error: {childSkuApiError}</span>
                                ) : (
                                    childSkuApiStatus === API_STATES.success && (
                                        <span className={generalStyles.successMessage}></span>
                                    )
                                )}
                            </label>
                        </td>
                        <td className={generalStyles.cap}>
                            {childSkuApiStatus === API_STATES.success ? childSkuData?.type || 'Unknown Type' : ''}
                        </td>
                        <td>
                            <button
                                data-test="add-childsku-btn"
                                className={generalStyles.smallButton}
                                disabled={
                                    newChildSku === 0 ||
                                    newChildSku === '' ||
                                    childSkuApiStatus === API_STATES.error ||
                                    childSkuApiStatus === API_STATES.loading
                                }
                                onClick={addProductSku}
                            >
                                Add
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <br />

            <button
                data-test="create-bundle-btn"
                className={generalStyles.submitButton}
                disabled={!isSubmitEnabled()}
                onClick={checkBundleExistence}
            >
                Create Bundle
            </button>
            <label data-test="create-bundle-result">
                {createBundleApiStatus === API_STATES.loading ? (
                    <span className={generalStyles.statusMessage}>Processing...</span>
                ) : createBundleApiStatus === API_STATES.error ? (
                    <span className={generalStyles.errorMessage}>Error: {createBundleApiError}</span>
                ) : (
                    createBundleApiStatus === API_STATES.success && (
                        <span className={generalStyles.successMessage}>Successfully created bundle</span>
                    )
                )}
            </label>
            <button className={generalStyles.submitButton} disabled={false} onClick={props.onCancelCreation}>
                Cancel
            </button>
        </>
    );
}

CreateBundle.propTypes = {
    bundles: PropTypes.arrayOf(
        PropTypes.shape({
            bundleSku: PropTypes.string.isRequired,
            bundleName: PropTypes.string.isRequired,
        })
    ).isRequired,
    onNewBundleCreation: PropTypes.func.isRequired,
    onCancelCreation: PropTypes.func.isRequired,
};

export default CreateBundle;
