import * as Constants from '@/components/OnDemandAlerts/Constants';
import generalStyles from '@/components/General/Styles.module.scss';
import ByzzerLink from '../General/ByzzerLink';
import { useHistory } from 'react-router-dom';

function alertLabelForAlertTypeValue(value) {
    return Constants.emailTypes.find((it) => it.value === value)?.label || 'Unknown Type';
}

export const COLUMNS = [
    {
        Header: 'Date',
        accessor: 'statusDate',
        className: generalStyles.tableCell,
    },
    {
        Header: 'Email Address',
        accessor: 'emailAddress',
        className: generalStyles.tableCell,
    },
    {
        Header: 'Alert Type',
        accessor: 'alertType',
        className: generalStyles.tableCell,
        Cell: ({ value }) => {
            return alertLabelForAlertTypeValue(value);
        },
    },
    {
        Header: 'Email Version',
        accessor: 'emailVersion',
        className: generalStyles.tableCell,
    },
    {
        Header: 'Status',
        accessor: 'statusValue',
        className: generalStyles.tableCell,
    },
    {
        Header: 'Action',
        accessor: 'correlationId',
        id: 'actions',
        disableFilters: true,
        disableSortBy: true,
        className: generalStyles.tableCell,
        Cell: ({ value: correlationId }) => {
            const history = useHistory();
            function viewAlertDetails() {
                history.push(`/ondemandalertdetails/${correlationId}`);
            }
            return <ByzzerLink label="View Details" onClick={viewAlertDetails} />;
        },
    },
];
