import React from 'react';
import { useEffect, useState } from 'react';
import styles from '@/components/MultiYearContractCompanySetup/MultiYearContractCompanySetup.module.scss';
import generalStyles from '@/components/General/Styles.module.scss';
import API_STATES from '@/constants/StateConstants';
import { getAllSubscriptions, getChildSkuDetails, getAllProductBundles } from '@/services/api/product.service';
import Select from 'react-select';
import { DebounceInput } from 'react-debounce-input';
import { handleApiError } from '@/components/General/HandleApiError';

const AddNewItems = ({ companyItems, getCompanyItems, openAddNewItemModal, addNewItemType, termValue }) => {
    const [subscriptionApiState, setSubscriptionApiState] = useState(API_STATES.none);
    const [subscriptionApiError, setSubscriptionApiError] = useState('');
    const [ParentSkuOptions, setParentSkuOptions] = useState([]);
    const [selectedParentSkuItem, setSelectedParentSkuItem] = useState({});
    const [childSkuApiStatus, setChildSkuApiStatus] = useState(API_STATES.none);
    const [childSkuApiError, setChildSkuApiError] = useState('');
    const [parentMonthlyPrice, setParentMonthlyPrice] = useState(0);
    const [parentYearlyPrice, setParentYearlyPrice] = useState(0);
    const [childSkuData, setChildSkuData] = useState({
        sku: '',
        monthly_price: 0,
        annual_price: 0,
        quantity: 0,
        product_name: '',
        product_type: ''
    });
    const [childSkuArray, setChildSkuArray] = useState([]);

    useEffect(() => {
        getSubscriptionList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function getSubscriptionList() {
        setSubscriptionApiState(API_STATES.loading);
        let response;
        if (addNewItemType === 'subscription') {
            response = await getAllSubscriptions();
        } else {
            response = await getAllProductBundles();
        }

        switch (response.status) {
            case API_STATES.success:
                setSubscriptionApiState(API_STATES.success);
                const data = response.data.map((row) => {
                    return {
                        product_id: row.id,
                        label: row.title,
                        value: row.sku,
                        ns_catalog_item_id: row.ns_catalog_item_id,
                        count: 1,
                        type: addNewItemType === 'subscription' ? 'Subscription' : 'Bundle',
                    };
                });
                setParentSkuOptions(data);
                break;
            case API_STATES.error:
                let error = response.error;
                handleApiError(error, setSubscriptionApiState, setSubscriptionApiError);
                break;
            default:
                setSubscriptionApiError('');
                setSubscriptionApiState(API_STATES.none);
        }
    }

    const onParentSkuChange = (type) => {
        if (validateSelections()) {
            setSubscriptionApiState(API_STATES?.error)
            setSubscriptionApiError('Adding more than one subscription is not allowed. Kindly delete previous subscription to add new one.')
        } else if (checkSkuValidity(type?.value)) {
            setSubscriptionApiState(API_STATES?.error)
            setSubscriptionApiError("Duplicate sku not allowed in selection")
        } else {
            setSubscriptionApiState(API_STATES?.none)
            setSelectedParentSkuItem(type);
        }
    };

    useEffect(() => {
        setParentYearlyPrice(Number(parseFloat(parentMonthlyPrice * termValue).toFixed(4)));
    }, [parentMonthlyPrice, termValue]);

    function checkSkuValidity(sku) {
        const checkValidity =
            companyItems.some((product) => product.sku === sku || product?.subRows?.some((li) => li.sku === sku)) ||
            childSkuArray?.some((childProduct) => childProduct?.sku === sku);

        return Boolean(checkValidity)
    }

    const onDebounceInputChange = (value) => {
        setChildSkuApiStatus(API_STATES.none);
        setChildSkuApiError('');
        if (value !== null && value !== 0 && value !== '') {
            if (checkSkuValidity(value)) {
                setChildSkuApiStatus(API_STATES.error);
                setChildSkuApiError('Duplicate sku not allowed in selection');
            } else {
                getChildSkuData(value);
            }
        }
    };

    async function getChildSkuData(childSku) {
        setChildSkuApiStatus(API_STATES.loading);
        const result = await getChildSkuDetails(childSku);
        switch (result.status) {
            case API_STATES.success:
                if (result.data) {
                    if (!result.data?.type) {
                        const newData = {
                            ...result.data,
                            type: 'unknown',
                        };
                        setChildSkuApiStatus(API_STATES.success);
                        setChildSkuData((sku) => ({
                            ...sku,
                            sku: newData?.sku,
                            product_name: newData?.title,
                            product_type: newData?.type,
                        }))
                    } else if (!result.data?.type.includes('addon')) {
                        setChildSkuApiStatus(API_STATES.error);
                        setChildSkuApiError('Not an addon');
                    } else {
                        setChildSkuApiStatus(API_STATES.success);
                        setChildSkuData((sku) => ({
                            ...sku,
                            sku: result.data?.sku,
                            product_name: result.data?.title,
                            product_type: result.data?.type,
                        }))
                    }
                } else {
                    setChildSkuApiStatus(API_STATES.error);
                    setChildSkuApiError('Invalid sku entry');
                }
                break;
            case API_STATES.error:
                let error = result.error;
                handleApiError(error, setChildSkuApiStatus, setChildSkuApiError);
                break;
            default:
                setChildSkuApiError('');
                setChildSkuApiStatus(API_STATES.none);
        }
    }

    function validateSelections() {
        if(addNewItemType === 'subscription') {
            if(companyItems.some((product) => product.product_type === "subscription")) {
                return true;
            }
        }
        return false;                                    
    }

    function onAddButtonClick() {
        const newItemObj = {
            sku: selectedParentSkuItem?.value,
            annual_price: parentYearlyPrice,
            monthly_price: parentMonthlyPrice,
            quantity: 1,
            product_type: addNewItemType,
            product_name: selectedParentSkuItem?.label,
            item_total: Number(parentYearlyPrice * 1).toFixed(2), 
            subRows: childSkuArray
        }
            getCompanyItems(newItemObj)
            openAddNewItemModal(false);   
    }

    function onAddAddonSku(skuData) {
        setChildSkuArray((prev) => ([
            ...prev,
            { ...skuData }
        ]))
        setChildSkuData({
            sku: '',
            product_name: '',
            product_type: '',
            quantity: 0,
            monthly_price: 0,
            annual_price: 0
        })
        setChildSkuApiStatus(API_STATES.none);
    }
    const removeProductSku = (sku) => {
        setChildSkuArray(childSkuArray.filter((productItem) => productItem?.sku !== sku?.sku));
    };

    return (
        <div className={styles.formContainer}>
            {subscriptionApiState === API_STATES.loading && (
                <label className={generalStyles.statusMessage}>Loading {addNewItemType === 'subscription' ? "subscriptions" : "bundles"}...</label>
            )}
            {subscriptionApiState === API_STATES.error && (
                <label className={generalStyles.errorMessage}>{subscriptionApiError}</label>
            )}
            <div className={generalStyles['input-wrapper']}>
                <strong>Select a {addNewItemType === 'subscription' ? "Subscription" : "Bundle"}: &nbsp;</strong>
                <span className={generalStyles.formField}>
                    <Select
                        classNamePrefix={'react-select'}
                        onChange={onParentSkuChange}
                        options={ParentSkuOptions}
                        value={selectedParentSkuItem}
                    />
                </span>
            </div>
            <p>Parent Sku: {selectedParentSkuItem?.value}</p>
            <br />
            <p>
                Monthly Price($):{' '}
                <input
                    className={styles.tableInputContainer}
                    value={parentMonthlyPrice}
                    onChange={(e) => {
                        const newCount = e.target.value;
                        setParentMonthlyPrice(newCount);
                    }}
                />
            </p>
            <br />
            <p>
                Annual Price($):{' '}
                <input
                    className={styles.tableInputContainer}
                    type={'number'}
                    value={parentYearlyPrice}
                    min={0}
                    disabled={true}
                />
            </p>
            <br />

            <b>{addNewItemType === 'subscription' ? "Subscription" : "Bundle"} Add-Ons</b>
            {childSkuArray.length === 0 ? (
                <p className={generalStyles.statusMessage}>No report skus selected</p>
            ) : (
                <>
                    <p>Child Sku:</p>
                    <ul className={styles.tileList}>
                        {childSkuArray?.map((sku) => (
                            <li data-test="product-sku-list-item" key={sku}>
                                <strong
                                    className={styles.removeButton}
                                    onClick={() => removeProductSku(sku)}
                                >
                                    X
                                </strong>
                                [{sku.sku}] {sku.product_name} - {sku.quantity} - $
                                {parseFloat(sku.monthly_price).toFixed(2)}/mon
                            </li>
                        ))}
                    </ul>
                </>
            )}


            <table className={styles.creditTable}>
                <thead>
                    <tr>
                        <th>Sku</th>
                        <th>Product Name</th>
                        <th>Product Type</th>
                        <th>Quantity</th>
                        <th>Monthly Price($)</th>
                        <th>Annual Price($)</th>
                        {/* <th>Billing Frequency</th> */}
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className={styles.tableInputContainer}>
                            <DebounceInput
                                placeholder="child sku"
                                className={styles.tableInput}
                                value={childSkuData?.sku}
                                minLength={1}
                                debounceTimeout={1000}
                                onChange={(event) => onDebounceInputChange(event.target.value)}
                            />
                        </td>
                        <td className={generalStyles.cap}>
                            {childSkuApiStatus === API_STATES.success
                                ? childSkuData?.product_name || 'Unknown Title'
                                : ''}
                            <label>
                                {childSkuApiStatus === API_STATES.loading ? (
                                    <span className={generalStyles.statusMessage}>Fetching data...</span>
                                ) : childSkuApiStatus === API_STATES.error ? (
                                    <span className={generalStyles.errorMessage}>
                                        Error: {childSkuApiError}
                                    </span>
                                ) : (
                                    childSkuApiStatus === API_STATES.success && (
                                        <span className={generalStyles.successMessage}></span>
                                    )
                                )}
                            </label>
                        </td>
                        <td className={generalStyles.cap}>
                            {childSkuApiStatus === API_STATES.success
                                ? childSkuData?.product_type || 'Unknown Type'
                                : ''}
                        </td>
                        <td>
                            <input
                                type={'number'}
                                className={generalStyles.numeric}
                                value={childSkuData?.quantity}
                                onChange={(e) => {
                                    const getValue = e.target.value ?? 0
                                    setChildSkuData((sku) => ({
                                        ...sku,
                                        quantity: Number(getValue),
                                    }))

                                }}
                            />
                        </td>
                            <td>
                                <input
                                    type={'number'}
                                    className={generalStyles.numeric}
                                    value={childSkuData?.monthly_price}
                                    onChange={(e) => {
                                        const getValue = e.target.value ?? 0
                                        setChildSkuData((sku) => {
                                            return {
                                                ...sku,
                                                monthly_price: Number(getValue),
                                                annual_price: Number(parseFloat(getValue * termValue).toFixed(4)),
                                                item_total: Number(parseFloat(getValue * termValue).toFixed(2)) * childSkuData?.quantity
                                            }
                                        })

                                    }}
                                />
                            </td>
                        <td>
                            <input
                                type={'number'}
                                className={generalStyles.numeric}
                                value={childSkuData?.annual_price}
                                disabled={true}
                            />
                        </td>
                        <td>
                            <button
                                className={generalStyles.smallButton}
                                disabled={
                                    childSkuData?.sku === 0 ||
                                    childSkuData?.sku === undefined ||
                                    childSkuData?.sku === '' ||
                                    childSkuApiStatus === API_STATES.error ||
                                    childSkuApiStatus === API_STATES.loading ||
                                    childSkuData?.quantity === 0 ||
                                    childSkuData?.quantity === undefined ||
                                    childSkuData?.monthly_price === 0 ||
                                    // childSkuData?.monthly_price === undefined ||
                                    childSkuData?.monthly_price?.toString().trim().length === 0
                                }
                                onClick={() => onAddAddonSku(childSkuData)}
                            >
                                Add
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <br/>
            <button className={generalStyles.submitButton} onClick={onAddButtonClick} disabled={ validateSelections() || !parentMonthlyPrice || !selectedParentSkuItem}>Add</button>
        </div>
    );
};

export default AddNewItems;
