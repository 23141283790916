import { useEffect, useMemo, useState } from 'react';
import { useTable, useSortBy, useFilters, usePagination } from 'react-table';
import upArrow from '@images/icons/upArrow.svg';
import downArrow from '@images/icons/downArrow.svg';
import InlineSVG from 'react-inlinesvg/esm';
import { ColumnFilter } from '@/components/General/ColumnFilter';
import PropTypes from 'prop-types';
import generalStyles from '@/components/General/Styles.module.scss';
import Excel from 'exceljs';
import { saveAs } from 'file-saver';
import API_STATES from '@/constants/StateConstants';
import classNames from 'classnames';
import { format } from 'date-fns';
import { isEmpty } from 'lodash';

export const FILTER_TYPES = {
    multiple: 'multiple',
    dateRange: 'date_range',
    hasValue: 'has_value',
};
function DataTable({
    data: propData,
    columns: propColumns,
    nonStickyTableHeader = false,
    defaultPageSize = 10,
    withDownload = false,
    downloadFileName,
    excludedColumnIds = ['actions'],
    noDataDescription = 'The data has no records'
}) {
    const columns = useMemo(() => propColumns, [propColumns]);
    const data = useMemo(() => propData, [propData]);
    const defaultColumn = useMemo(
        () => ({
            Filter: ColumnFilter,
        }),
        []
    );
    const filterTypes = useMemo(
        () => ({
            [FILTER_TYPES.multiple]: (rows, id, filterValue) => {
                return rows.filter((row) => {
                    // this is to support array type cell values
                    const rowValue = [].concat(row.values[id]);
                    if (rowValue !== undefined && rowValue !== null && filterValue?.length) {
                        // this needs the filter to be and array of objects of type {label:any, value: any}
                        // refer to toOptions util function for better understanding
                        // and follow this rule while implementing your filter component
                        const matchingFilter = filterValue.find((filter) => rowValue.includes(filter.value));
                        return matchingFilter !== undefined;
                    } else {
                        return true;
                    }
                });
            },
            [FILTER_TYPES.dateRange]: (rows, id, filterValue) => {
                return rows.filter((row) => {
                    const rowValue = new Date(row.values[id]);
                    if (rowValue !== undefined && rowValue !== null && filterValue.from !== null && filterValue.to !== null) {
                        // this needs the filter to be an object of type {from:Date, to: Date}
                        // follow this rule while implementing your filter component
                        return rowValue >= filterValue.from && rowValue <= filterValue.to;
                    } else {
                        return true;
                    }
                });
            },
            [FILTER_TYPES.hasValue]: (rows, id, filterValue) => {
                return rows.filter((row) => {
                    const rowValue = row.values[id];
                    // this needs the filter to be an object of type {value: 'yes' | 'no'}
                    // refer to toOptions util function for better understanding
                    // and follow this rule while implementing your filter component
                    if (filterValue?.value === 'yes') {
                        return !isEmpty(rowValue);
                    } else if (filterValue?.value === 'no') {
                        return isEmpty(rowValue);
                    } else {
                        return true;
                    }
                });
            },
        }),
        []
    );

    const tableInstance = useTable(
        {
            columns,
            data,
            defaultColumn,
            filterTypes,
        },
        useFilters,
        useSortBy,
        usePagination
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        rows,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        state,
        gotoPage,
        pageCount,
        prepareRow,
        setPageSize,
    } = tableInstance;

    const { pageIndex, pageSize } = state;

    useEffect(() => {
        setPageSize(defaultPageSize);
    }, [defaultPageSize, setPageSize]);

    const [downloadStatus, setDownloadStatus] = useState(API_STATES.none);

    const downloadExcel = async (headerRow, dataSet) => {
        const workbook = new Excel.Workbook();
        const workSheetName = 'sheet 1';

        try {
            const now = format(new Date(), '-yyyy-MM-dd.HH-mm-ss');
            const fileName = (downloadFileName || 'downloadFromCsr') + now;

            // creating one worksheet in workbook
            const worksheet = workbook.addWorksheet(workSheetName);

            // add worksheet columns
            // each columns contains header and its mapping key from data
            worksheet.columns = headerRow;

            // updated the font for first row.
            worksheet.getRow(1).font = { bold: true };

            // loop through all of the columns and set the alignment with width.
            worksheet.columns.forEach((column) => {
                column.width = column.header.length + 5;
                column.alignment = { horizontal: 'center' };
            });

            // loop through data and add each one to worksheet
            dataSet.forEach((singleData) => {
                worksheet.addRow(singleData);
            });
            // write the content using writeBuffer
            const buf = await workbook.xlsx.writeBuffer();

            // download the processed file
            saveAs(new Blob([buf]), `${fileName}.xlsx`);
        } catch (error) {
        } finally {
            // removing worksheet's instance to create new one
            workbook.removeWorksheet(workSheetName);
        }
    };

    function onExcelDownload() {
        setDownloadStatus(API_STATES.loading);
        let dataSet = [];
        const headerRow = [];
        // step 1: prepare the header for excel sheet
        // extracting the last level headers in nested heading
        // can think of it as the heading row just able the first data row of table ui
        const headerGroup = headerGroups[headerGroups.length - 1];
        if (headerGroup.headers) {
            headerGroup.headers.forEach((column) => {
                if (!excludedColumnIds.includes(column.id)) {
                    headerRow.push({ key: column.id, header: column.Header });
                }
            });
        }
        // step 2: prepare the data for excel sheet
        // FILTERED ROWS
        if (rows.length > 0) {
            rows.forEach((row) => {
                const rowData = {};
                headerRow.forEach((colHeader) => {
                    rowData[colHeader.key] = row.values[colHeader.key];
                    if (Array.isArray(rowData[colHeader.key]) && typeof rowData[colHeader.key][0] === 'string') {
                        rowData[colHeader.key] = rowData[colHeader.key].join(', ')
                    }
                });
                dataSet.push(rowData);
            });
        } else {
            dataSet.push({});
        }
        downloadExcel(headerRow, dataSet);
        setDownloadStatus(API_STATES.success);
        setTimeout(() => setDownloadStatus(API_STATES.none), 2000);
    }

    const excelDownload = (
        <>
            <button
                className={generalStyles.smallButton}
                onClick={onExcelDownload}
                disabled={rows.length <= 0 || downloadStatus !== API_STATES.none}
            >
                Download as excel
            </button>
            {downloadStatus === API_STATES.loading ? (
                <span className={generalStyles.statusMessage}>Downloading ...</span>
            ) : (
                downloadStatus === API_STATES.success && (
                    <span className={generalStyles.successMessage}>Downloaded!</span>
                )
            )}
        </>
    );

    const pageNav = (
        <>
            <span>
                Page&nbsp;
                <strong>
                    {pageIndex + 1} of {pageOptions.length}
                </strong>
                &nbsp;
            </span>
            <span>
                | Go to:
                <input
                    className={generalStyles.gotoPageInput}
                    type="number"
                    defaultValue={pageIndex + 1}
                    onChange={(e) => {
                        const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0;
                        gotoPage(pageNumber);
                    }}
                />
            </span>
            <select value={pageSize} onChange={(e) => setPageSize(Number(e.target.value))}>
                {[5, 10, 20, 50, 100, 300, 500].map((pageSize) => {
                    return (
                        <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                        </option>
                    );
                })}
            </select>
            <button className={generalStyles.smallButton} onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                {'<<'}
            </button>
            <button className={generalStyles.smallButton} onClick={() => previousPage()} disabled={!canPreviousPage}>
                Previous
            </button>
            <button className={generalStyles.smallButton} onClick={() => nextPage()} disabled={!canNextPage}>
                Next
            </button>
            <button
                className={generalStyles.smallButton}
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
            >
                {'>>'}
            </button>
        </>
    );
    return (
        <>
            <table {...getTableProps()} className={generalStyles.dataTable}>
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()} className={generalStyles.tableRow}>
                            {headerGroup.headers.map((column) => (
                                <th
                                    className={
                                        nonStickyTableHeader
                                            ? generalStyles.nonStickyTableHeader
                                            : generalStyles.tableHeader
                                    }
                                    key={column.id}
                                >
                                    <div className={generalStyles.tableHeaderContentWrapper}>
                                        <div
                                            {...column.getHeaderProps([
                                                {
                                                    className: classNames(
                                                        column.headerClassName,
                                                        generalStyles.tableHeaderContent
                                                    ),
                                                },
                                                column.getSortByToggleProps(),
                                            ])}
                                        >
                                            {column.render('Header')}
                                            <span>
                                                {column.isSorted ? (
                                                    column.isSortedDesc ? (
                                                        <InlineSVG src={upArrow} />
                                                    ) : (
                                                        <InlineSVG src={downArrow} />
                                                    )
                                                ) : (
                                                    ''
                                                )}
                                            </span>
                                        </div>
                                        <div>{column.canFilter && data.length ? column.render('Filter') : null}</div>
                                    </div>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {data.length ? page.map((row) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()} className={generalStyles.tableRow}>
                                {row.cells.map((cell) => {
                                    return (
                                        <td
                                            {...cell.getCellProps([
                                                {
                                                    className: cell.column.className,
                                                },
                                            ])}
                                        >
                                            {cell.render('Cell')}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    }) : <tr className={generalStyles.tableRow}>
                        <td colSpan={columns.length} className={generalStyles.tableCell}>
                            <b>{noDataDescription}</b>
                        </td>
                    </tr>}
                </tbody>
            </table>
            <div className={generalStyles.pageNav}>
                {data.length > 10 && pageNav}
                {withDownload && excelDownload}
            </div>
        </>
    );
}

DataTable.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    nonStickyTableHeader: PropTypes.bool,
};

export default DataTable;
