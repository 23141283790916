import { setCategories } from '@/services/api/company.service';
import { getCategories, getSuperCategories, getDepartments, getTotalStore } from '@/services/api/product.service';
import * as R from 'ramda';
import { useEffect, useState, useMemo } from 'react';
import Select from 'react-select';
import generalStyles from '@/components/General/Styles.module.scss';
import styles from '@/components/CompanyConfig/CompanyConfig.module.scss';
import API_STATES from '@/constants/StateConstants';
import { handleApiError, noOp } from '@/components/General/HandleApiError';
import * as Hierarchies from './CategoryHierarchy';

export function EditCompanyCategories({
    nsCompanyId,
    companyCategories,
    maxAllowedCategories,
    onSuccess = () => {},
    onCancel,
    disabled = false
}) {
    const [updateCategoryApiState, setUpdateCategoryApiState] = useState(API_STATES.none);
    const [updateCategoryApiError, setUpdateCategoryApiError] = useState('');
    const [productsApiState, setProductsApiState] = useState(API_STATES.none);
    const [productsApiError, setProductsApiError] = useState('');
    const [productsList, setProductsList] = useState([]);

    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedNewCategories, setSelectedNewCategories] = useState([]);
    const [selectedSuperCategories, setSelectedSuperCategories] = useState([]);
    const [selectedDepartments, setSelectedDepartments] = useState([]);
    const [selectedTotalStore, setSelectedTotalStore] = useState([]);

    const [excludedCategories, setExcludedCategories] = useState([]);
    const [selectedHierarchy, setSelectedHierarchy] = useState({});

    const [allCategories, setAllCategories] = useState([]);
    const [allSuperCategories, setAllSuperCategories] = useState([]);
    const [allDepartments, setAllDepartments] = useState([]);
    const [allTotalStores, setAllTotalStores] = useState([]);

    useEffect(() => {
        setSelectedCategories(companyCategories);
    }, [companyCategories]);

    const handleSelectedHierarchy = (hierarchy) => {
        setSelectedHierarchy(hierarchy);
    };

    const totalSelectedNewCategories = useMemo(() => {
        if (selectedTotalStore.length === 1) {
            return [...selectedTotalStore[0].value];
        }
        let totalCategories = selectedCategories.concat(selectedNewCategories);
        totalCategories = totalCategories.concat(
            ...selectedSuperCategories.map((superCategory) => superCategory.value)
        );
        totalCategories = totalCategories.concat(...selectedDepartments.map((department) => department.value));
        totalCategories = R.differenceWith(
            (cat1, cat2) => {
                return cat1.value === cat2.value;
            },
            totalCategories,
            excludedCategories
        );
        return totalCategories;
    }, [
        selectedCategories,
        selectedNewCategories,
        selectedSuperCategories,
        selectedDepartments,
        selectedTotalStore,
        excludedCategories
    ]);

    const selectedHierarchyItems = useMemo(() => {
        switch (selectedHierarchy?.value) {
            case 'category':
                return selectedNewCategories; 
            case 'supercategory':
                return selectedSuperCategories;
            case 'department':
                return selectedDepartments;
            case 'totalstore':
                return selectedTotalStore;
            default:
                break;
        }
    }, [selectedNewCategories, selectedSuperCategories, selectedDepartments, selectedTotalStore, selectedHierarchy]);

    async function loadProductList(selectedHierarchy, maxAllowedCategories, totalSelectedNewCategoryCount) {
        let productList = [];
        const remainingCredits = maxAllowedCategories - totalSelectedNewCategoryCount;
        if (selectedHierarchy.value !== null) {       
            switch (selectedHierarchy?.value) {             
                case 'category':
                    productList = allCategories?.map((product) => {
                        return {
                            ...product,
                            isDisabled: product.categoryCount > remainingCredits
                        };
                    });
                    console.log(`${productList?.length}, ${maxAllowedCategories}`)
                    setProductsList(maxAllowedCategories > productList?.length ? [{label: 'All', value: 'all'}, ...productList] : productList ?? []);
                    break;
                case 'supercategory':
                    productList = allSuperCategories?.map((product) => {
                        return {
                            ...product,
                            isDisabled: product.categoryCount > remainingCredits
                        };
                    });
                    setProductsList(maxAllowedCategories > productList?.length ? [{label: 'All', value: 'all'}, ...productList] : productList ?? []);
                    break;
                case 'department':
                    productList = allDepartments?.map((product) => {
                        return {
                            ...product,
                            isDisabled: product.categoryCount > remainingCredits
                        };
                    });
                    setProductsList(maxAllowedCategories > productList?.length ? [{label: 'All', value: 'all'}, ...productList] : productList ?? []);
                    break;
                case 'totalstore':
                    productList = allTotalStores?.map((product) => {
                        return {
                            ...product,
                            isDisabled: product.categoryCount > maxAllowedCategories
                        };
                    });
                    setProductsList(maxAllowedCategories > productList?.length ? [{label: 'All', value: 'all'}, ...productList] : productList ?? []);
                    break;
                default:
                    break;
            }
        }
    }

    useEffect(
        () => {
            loadProductList(selectedHierarchy, maxAllowedCategories, totalSelectedNewCategories.length);
        },
        // eslint-disable-next-line
        [selectedHierarchy, maxAllowedCategories, totalSelectedNewCategories.length]
    );

    useEffect(() => {}, [totalSelectedNewCategories]);

    const removeProductSku = (category) => {
        setExcludedCategories([...excludedCategories, category]);
    };

    function isUpdateCategoriesEnabled() {
        const changedCategories = R.symmetricDifference(selectedCategories, totalSelectedNewCategories);
        return (
            !disabled &&
            maxAllowedCategories >= totalSelectedNewCategories.length &&
            changedCategories.length &&
            updateCategoryApiState === API_STATES.none
        );
    }

    useEffect(() => {
        setProductsApiState(API_STATES.loading);
        Promise.all([
            getCategoriesByHierarchy(),
            getSuperCategoriesByHierarchy(),
            getDepartmentsByHierarchy(),
            getTotalStoreByHierarchy()
        ])
            .then(() => {
                setProductsApiState(API_STATES.success);
            })
            .catch((error) => {
                setProductsApiState(API_STATES.error);
                setProductsApiError(error.message);
            });
    }, []);

    async function getCategoriesByHierarchy() {
        setProductsApiState(API_STATES.loading);
        const result = await getCategories();
        switch (result.status) {
            case API_STATES.success:
                setProductsApiState(API_STATES.success);
                let res = result.data.map((it) => {
                    return {
                        label: it,
                        value: it,
                        categoryCount: Number(1)
                    };
                });
                setAllCategories(res);
                break;
            case API_STATES.error:
                let error = result.error;
                throw new Error(handleApiError(error, noOp, noOp));
            default:
                setProductsApiError('');
                setProductsApiState(API_STATES.none);
        }
    }
    async function getSuperCategoriesByHierarchy() {
        setProductsApiState(API_STATES.loading);
        const result = await getSuperCategories();
        switch (result.status) {
            case API_STATES.success:
                setProductsApiState(API_STATES.success);
                let res = result.data.map((it) => {
                    return {
                        label: `${it.categoryCount} - ${it.superCategoryName}`,
                        value: it.categories.map((category) => {
                            return {
                                label: category,
                                value: category
                            };
                        }),
                        categoryCount: Number(it.categoryCount)
                    };
                });
                setAllSuperCategories(res);
                break;
            case API_STATES.error:
                let error = result.error;
                throw new Error(handleApiError(error, noOp, noOp));
            default:
                setProductsApiError('');
                setProductsApiState(API_STATES.none);
        }
    }
    async function getDepartmentsByHierarchy() {
        setProductsApiState(API_STATES.loading);
        const result = await getDepartments();
        switch (result.status) {
            case API_STATES.success:
                setProductsApiState(API_STATES.success);
                let res = result.data.map((it) => {
                    return {
                        label: `${it.categoryCount} - ${it.departmentName}`,
                        value: it.categories.map((category) => {
                            return {
                                label: category,
                                value: category
                            };
                        }),
                        categoryCount: Number(it.categoryCount)
                    };
                });
                setAllDepartments(res);
                break;
            case API_STATES.error:
                let error = result.error;
                throw new Error(handleApiError(error, noOp, noOp));
            default:
                setProductsApiError('');
                setProductsApiState(API_STATES.none);
        }
    }
    async function getTotalStoreByHierarchy() {
        setProductsApiState(API_STATES.loading);
        const result = await getTotalStore();
        switch (result.status) {
            case API_STATES.success:
                setProductsApiState(API_STATES.success);
                let res = result.data.map((it) => {
                    return {
                        label: `${it.categoryCount} - ${it.totalStoreName}`,
                        value: it.categories.map((category) => {
                            return {
                                label: category,
                                value: category
                            };
                        }),
                        categoryCount: Number(it.categoryCount)
                    };
                });
                setAllTotalStores(res);
                break;
            case API_STATES.error:
                let error = result.error;
                throw new Error(handleApiError(error, noOp, noOp));
            default:
                setProductsApiError('');
                setProductsApiState(API_STATES.none);
        }
    }

    async function updateCategories() {
        if (!isUpdateCategoriesEnabled()) {
            return;
        }
        setUpdateCategoryApiState(API_STATES.loading);
        const categories = totalSelectedNewCategories.map((category) => category.value);
        const response = await setCategories(nsCompanyId, categories);
        if (response.status === API_STATES.success) {
            setUpdateCategoryApiState(API_STATES.success);
            setTimeout(() => {
                onSuccess();
                setUpdateCategoryApiState(API_STATES.none);
            }, 2000);
        } else {
            handleApiError(response.error, setUpdateCategoryApiState, setUpdateCategoryApiError);
            setTimeout(() => setUpdateCategoryApiState(API_STATES.none), 3000);
        }
    }

    // selectedProducts in multi select returns all selected items as array
    const onSelectionChange = (selectedProducts, { action, option, removedValue }) => {
        switch (selectedHierarchy?.value) {
            case 'category':
                setSelectedNewCategories(option?.value === 'all' ?  productsList: selectedProducts);
                if (action === 'select-option') {
                    setExcludedCategories(excludedCategories.filter((category) => category.value !== option.value));
                } else if (action === 'remove-value') {
                    setExcludedCategories([...excludedCategories, removedValue]);
                }
                break;
            case 'supercategory':
                setSelectedSuperCategories(option?.value === 'all' ?  productsList : selectedProducts);
                if (action === 'select-option') {
                    setExcludedCategories(
                        R.differenceWith(
                            (cat1, cat2) => {
                                return cat1.value === cat2.value;
                            },
                            excludedCategories,
                            option.value
                        )
                    );
                }
                break;
            case 'department':
                setSelectedDepartments(option?.value === 'all' ?  productsList : selectedProducts);
                if (action === 'select-option') {
                    setExcludedCategories(
                        R.differenceWith(
                            (cat1, cat2) => {
                                return cat1.value === cat2.value;
                            },
                            excludedCategories,
                            option.value
                        )
                    );
                }
                break;
            case 'totalstore':
                setSelectedTotalStore(option?.value === 'all' ?  productsList : selectedProducts);
                if (action === 'select-option') {
                    setExcludedCategories([]);
                }
                break;
            default:
                break;
        }
    };

    return (
        <>
            <p data-test="category-count">
                {totalSelectedNewCategories.length} of {maxAllowedCategories ?? '(unknown)'} Selected
            </p>
            <ul className={styles.tileList}>
                {totalSelectedNewCategories.map((category) => (
                    <li key={category.value}>
                        <strong className={styles.removeButton} onClick={() => removeProductSku(category)}>
                            X
                        </strong>
                        {category.label}
                    </li>
                ))}
            </ul>
            <br />
            <b>Please select hierarchy:</b>
            <div className={styles.multiSelectInput}>
                <Select
                    options={Hierarchies.hierarchies}
                    closeMenuOnSelect={true}
                    isMulti={false}
                    onChange={handleSelectedHierarchy}
                    value={selectedHierarchy}
                    placeholder="Please select hierarchy"
                    isDisabled={disabled}
                />
            </div>
            <div>
                <span>
                    {productsApiState === API_STATES.loading ? (
                        <span className={generalStyles.statusMessage}>Loading...</span>
                    ) : productsApiState === API_STATES.error ? (
                        <span className={generalStyles.errorMessage}>Error: {productsApiError}</span>
                    ) : (
                        productsApiState === API_STATES.success && (
                            <>
                                <b>Please select list:</b>
                                <div className={styles.multiSelectInput} data-test="category-list">
                                    <Select
                                        options={productsList}
                                        closeMenuOnSelect={false}
                                        isMulti={true}
                                        onChange={onSelectionChange}
                                        value={selectedHierarchyItems}
                                        placeholder="Please select product"
                                        isDisabled={disabled}
                                    />
                                </div>
                            </>
                        )
                    )}
                </span>
            </div>

            {maxAllowedCategories === undefined || maxAllowedCategories == null ? (
                <div className={generalStyles.errorMessage}>Failed to fetch categories count limit</div>
            ) : selectedCategories.length > maxAllowedCategories ? (
                <div className={generalStyles.errorMessage}>maximum categories limit exceeded</div>
            ) : (
                selectedCategories.length === maxAllowedCategories && (
                    <div className={generalStyles.statusMessage}>maximum categories limit reached</div>
                )
            )}
            <div className={styles.columnar}>
                <button
                    className={generalStyles.submitButton}
                    onClick={updateCategories}
                    disabled={!isUpdateCategoriesEnabled()}
                    data-test="save-changed-categories-button"
                >
                    Save Changes
                </button>
                {updateCategoryApiState === API_STATES.loading ? (
                    <span className={generalStyles.statusMessage}>Processing...</span>
                ) : updateCategoryApiState === API_STATES.error ? (
                    <span className={generalStyles.errorMessage}>Error: {updateCategoryApiError}</span>
                ) : (
                    updateCategoryApiState === API_STATES.success && (
                        <>
                            <span className={generalStyles.successMessage}> Changes Saved </span>
                        </>
                    )
                )}
                {updateCategoryApiState === API_STATES.none && (
                    <button className={generalStyles.submitButton} onClick={onCancel}>
                        Cancel
                    </button>
                )}
            </div>
        </>
    );
}
