import React, { useState } from 'react';
import generalStyles from '@/components/General/Styles.module.scss';
import styles from '@/components/BundleManager/BundleManager.module.scss';
import API_STATES from '@/constants/StateConstants';
import * as API from '@/services/api/product.service';
import TextInput from '@/components/General/TextInput';
import { handleApiError } from '@/components/General/HandleApiError';
import DeleteFolder from './DeleteFolder';
import FileInfoTable from './FileInfoTable';

function FileInfoContainer(props) {
    const folder = props.folder;
    const marketMapType = props.marketMapType;
    const [lastSavedFolderName, setLastSavedFolderName] = useState(props.folder.name);
    const [newTitle, setNewTitle] = useState(lastSavedFolderName);

    const [updateFolderTitleApiStatus, setUpdateFolderTitleApiStatus] = useState(API_STATES.none);
    const [updateFolderTitleApiError, setUpdateFolderTitleApiError] = useState('');

    async function renameFolderTitle() {
        setUpdateFolderTitleApiStatus(API_STATES.loading);
        const result = await API.renameMarketMapsFolder(props.folder.id, newTitle);
        switch (result.status) {
            case API_STATES.success:
                setUpdateFolderTitleApiStatus(API_STATES.success);
                setLastSavedFolderName(newTitle);
                setUpdateFolderTitleApiStatus(API_STATES.none);
                setTimeout(() => props.onFolderUpdate(), 2000);
                break;
            case API_STATES.error:
                let error = result.error;
                handleApiError(error, setUpdateFolderTitleApiStatus, setUpdateFolderTitleApiError);
                break;
            default:
                setUpdateFolderTitleApiError('');
                setUpdateFolderTitleApiStatus(API_STATES.none);
        }
    }

    const onTitleChange = (value) => {
        setUpdateFolderTitleApiStatus(API_STATES.none);
        setUpdateFolderTitleApiError('');
        setNewTitle(value);
    };

    const isSubmitEnabled = () => {
        return newTitle !== '' && updateFolderTitleApiStatus !== API_STATES.loading && newTitle !== lastSavedFolderName;
    };

    return (
        <div data-test="folder-info-container">
            <DeleteFolder folder={props.folder} onFolderDelete={props.onFolderUpdate} />

            <div className={styles.bundleInfoFlex}>
                <div data-test="folder-title-input">
                    <TextInput
                        type="text"
                        label="Title:"
                        placeholder="Title"
                        value={newTitle}
                        onInputChange={(event) => onTitleChange(event.target.value)}
                        disabled={updateFolderTitleApiStatus === API_STATES.loading}
                    />
                </div>
                <div>
                    <button
                        data-test="update-title-btn"
                        className={generalStyles.submitButton}
                        disabled={!isSubmitEnabled()}
                        onClick={renameFolderTitle}
                    >
                        Update Title
                    </button>

                    <label data-test="update-title-status">
                        {updateFolderTitleApiStatus === API_STATES.loading ? (
                            <span className={generalStyles.statusMessage}>Processing...</span>
                        ) : updateFolderTitleApiStatus === API_STATES.error ? (
                            <span className={generalStyles.errorMessage}>Error: {updateFolderTitleApiError}</span>
                        ) : (
                            updateFolderTitleApiStatus === API_STATES.success && (
                                <span className={generalStyles.successMessage}>Folder Title Updated!</span>
                            )
                        )}
                    </label>
                </div>
            </div>

            <FileInfoTable
                files={props.fileList}
                folder={folder}
                onFolderUpdate={()=>props.onFolderUpdate()}
                onFileUpdate={()=>props.onFileUpdate()}
                marketMapType={marketMapType}
            />
        </div>
    );
}

export default FileInfoContainer;
