import React, { useState, useContext, useEffect, useMemo } from 'react';
import styles from '@/components/MultiYearContractCompanySetup/MultiYearContractCompanySetup.module.scss';
import MyaCompanyItems from './MyaCompanyItems';
import DataTable from '../General/DataTable'
import { COLUMNS } from './Columns';
import generalStyles from '@/components/General/Styles.module.scss';
import { getCompanyList } from '@/services/api/company.service';
import API_STATES from '@/constants/StateConstants';
import { handleApiError } from '@/components/General/HandleApiError';

function MultiYearContractCompanySetup() {
    const [activeTab, setActiveTab] = useState(0);
    const [companyList, setCompanyList] = useState([])
    const [isRenewClicked, setIsRenewClicked] = useState(false)
    const [companyDetails, setCompanyDetails] = useState('')
    const [companiesApiState, setCompaniesApiState] = useState(API_STATES.none);
    const [companiesApiError, setCompaniesApiError] = useState('');

    const dimensions = [
        {
            displayName: 'MYA Companies ',
            key: 'MYA Companies'
        },
        {
            displayName: 'Renew MYA ',
            key: 'Renew MYA'
        },
    ]

    const renderActiveTab = () => {
        switch (activeTab) {
            case 0:
                return < MyaCompanySetupTable />
            case 1:
                return <MyaCompanyItems isRenewClicked={isRenewClicked} companyDetails={companyDetails} />;
            default:
                return <h1>Default Tab</h1>;
        }
    };

    useEffect(() => {
        if (activeTab === 0) {
            setIsRenewClicked(false)
        }
    }, [activeTab])

    useEffect(() => {
        loadCompanyList()
    }, [])

    async function loadCompanyList() {
        setCompaniesApiState(API_STATES.loading)
        const result = await getCompanyList()
        switch (result.status) {
            case API_STATES.success:
                setCompaniesApiState(API_STATES.success);
                const transformResult = result.data?.map((item) => ({
                    companyId: item?.companyId,
                    companyName: item?.companyName,
                    contractId: item?.contractId,
                    activeSubscriptionEndDate: item?.activeSubscriptionEndDate,
                    trueContractEndDate: item?.trueContractEndDate,
                    mya: item?.mya,
                    activeSubscriptionStartDate: item?.activeSubscriptionStartDate
                })).sort((a, b) => {
                    return new Date(a.activeSubscriptionEndDate).getTime() - new Date(b.activeSubscriptionEndDate).getTime()
                })
                setCompanyList(transformResult);
                break;
            case API_STATES.error:
                handleApiError(result.error, setCompaniesApiState, setCompaniesApiError, 'Companies not found');
                break;
            default:
                setCompaniesApiState(API_STATES.none);
                setCompaniesApiError('');
        }
    }

    const MyaCompanySetupTable = () => {
        const onRenewClick = (row) => {
            setCompanyDetails(row)
            setActiveTab(1);
            setIsRenewClicked(true);
        }
        const MYACompanySetupColumns = [
            ...COLUMNS,
            {
                Header: 'Action',
                accessor: (row) => row,
                id: 'actions',
                disableFilters: true,
                disableSortBy: true,
                className: generalStyles.tableCell,
                Cell: ({ value: row }) => {
                    return (
                        <div className={styles.actionContainer}>
                            <button className={generalStyles.submitButton} onClick={() => onRenewClick(row)}>Renew Contract</button>
                        </div>
                    );
                },
            },
        ]
        return (
            <>
                <h2 className={styles.heading}>Company List</h2>
                <br/>
                {companiesApiState === API_STATES.loading ? (
                    <span className={generalStyles.statusMessage}>Loading Companies...</span>
                ) : companiesApiState === API_STATES.error ? (
                    <span className={generalStyles.statusMessage}>{companiesApiError}</span>
                ) :
                    <DataTable columns={MYACompanySetupColumns} data={companyList} noDataDescription={'No Companies found for MYA renewal'} />
                }
            </>
        )
    }

    return (
        <>
            <h1>MYA Company Setup</h1>
            <hr />
            <div className={styles['tab-control']}>
                {dimensions.map((dimension, index) => {
                    return (
                        <React.Fragment key={index}>
                            <div
                                onClick={() => setActiveTab(index)}
                                className={`${styles['tab']} ${activeTab === index ? styles['tab-active'] : styles['tab-disabled']
                                    }`}
                            >
                                <div className={styles['tab-text']}>
                                    <span>{dimension.displayName} </span>
                                </div>
                            </div>
                        </React.Fragment>
                    );
                })}
            </div>
            <div className="tab-control-content">{renderActiveTab()}</div>
        </>
    )
}

export default MultiYearContractCompanySetup