import { useHistory } from 'react-router-dom';
import generalStyles from '@/components/General/Styles.module.scss';
import ByzzerLink from '../General/ByzzerLink';
import { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import API_STATES from '@/constants/StateConstants';
import { getScheduleList, deleteDataMaintenanceSchedule } from '@/services/api/dataRefreshMaintenance.service';
import DataTable from '../General/DataTable';
import { handleApiError } from '../General/HandleApiError';
import moment from 'moment';
import { isoWeekdays, timeZoneString } from './Constants';
import editIcon from '@images/icons/EditIcon.svg';
import deleteIcon from '@images/icons/deleteIcon.svg';
import InlineSVG from 'react-inlinesvg/esm';

export default function DataMaintenanceSchedules() {
    const history = useHistory();

    function openCreateScheduleForm() {
        history.push('scheduledatarefreshmaintenance');
    }

    const [scheduleListApiState, setScheduleListApiState] = useState(API_STATES);
    const [scheduleListApiError, setScheduleListApiError] = useState('');
    const [scheduleList, setScheduleList] = useState([]);

    function humanizeDuration(duration) {
        duration = moment.duration(duration, 'minutes');
        const days = duration.days();
        const hours = duration.hours();
        const minutes = duration.minutes();
        const res = [];
        if (days > 0) {
            res.push(`${days} day${days > 1 ? 's' : ''}`);
        }
        if (hours > 0) {
            res.push(`${hours} hour${hours > 1 ? 's' : ''}`);
        }
        if (minutes > 0) {
            res.push(`${minutes} minute${minutes > 1 ? 's' : ''}`);
        }
        return res.join(' ');
    }

    const scheduleListColumns = useMemo(
        () => [
            {
                Header: 'Effective From',
                accessor: 'effectiveFrom',
                disableFilters: true,
                className: generalStyles.tableCell,
                Cell: ({ value: date }) => moment(date, 'YYYY-MM-DD').format('MMM DD, y'),
            },
            {
                Header: 'Frequency',
                accessor: 'frequency',
                className: generalStyles.tableCell,
            },
            {
                Header: 'Start Weekday',
                accessor: 'startIsoWeekday',
                className: classNames(generalStyles.tableCell),
                Cell: ({ value: isoWeekdayNumber }) => isoWeekdays[isoWeekdayNumber - 1],
            },
            {
                Header: 'Start Time',
                accessor: 'startTime',
                disableFilters: true,
                className: classNames(generalStyles.tableCell),
                Cell: ({ value: time }) => time + ' ' + timeZoneString,
            },
            {
                Header: 'Duration',
                accessor: 'durationInMinutes',
                disableFilters: true,
                className: classNames(generalStyles.tableCell),
                Cell: ({ value: duration }) => humanizeDuration(duration),
            },
            {
                Header: 'Sku List',
                accessor: (row) => row.skuList.map((skuItem) => skuItem.sku),
                id: 'skuList',
                className: classNames(generalStyles.tableCell),
                Cell: ({ value: skuList }) => skuList.join(', '),
            },
            {
                Header: 'Actions',
                accessor: (row) => row,
                id: 'actions',
                disableFilters: true,
                disableSortBy: true,
                className: generalStyles.tableCell,
                Cell: ({ value: dataMaintenanceSchedule }) => {
                    const [deleteSelectedScheduleApiState, setDeleteSelectedScheduleApiState] = useState(
                        API_STATES.none
                    );
                    const [deleteSelectedScheduleApiError, setDeleteSelectedScheduleApiError] = useState('');
                    const [scheduleToBeDeleted, setScheduleToBeDeleted] = useState('');

                    function openEditScheduleForm(scheduleId) {
                        history.push(`editdatamaintenanceschedule/${scheduleId}`);
                    }

                    async function deleteSelectedSchedule(scheduleId) {
                        setDeleteSelectedScheduleApiState(API_STATES.loading);
                        const response = await deleteDataMaintenanceSchedule(scheduleId);
                        if (response.status === API_STATES.success) {
                            setDeleteSelectedScheduleApiState(API_STATES.success);
                            setTimeout(() => loadScheduleList(), 2000);
                        } else {
                            handleApiError(
                                response.error,
                                setDeleteSelectedScheduleApiState,
                                setDeleteSelectedScheduleApiError
                            );
                            setTimeout(() => setDeleteSelectedScheduleApiState(API_STATES.none), 2000);
                        }
                    }

                    useEffect(() => {
                        if (scheduleToBeDeleted && deleteSelectedScheduleApiState !== API_STATES.loading)
                            deleteSelectedSchedule(scheduleToBeDeleted);
                        // eslint-disable-next-line react-hooks/exhaustive-deps
                    }, [scheduleToBeDeleted]);

                    return deleteSelectedScheduleApiState === API_STATES.loading ? (
                        <label className={generalStyles.statusMessage}>Deleting...</label>
                    ) : deleteSelectedScheduleApiState === API_STATES.success ? (
                        <label className={generalStyles.successMessage}>Successfully deleted</label>
                    ) : deleteSelectedScheduleApiState === API_STATES.error ? (
                        <label className={generalStyles.errorMessage}>{deleteSelectedScheduleApiError}</label>
                    ) : (
                        <>
                            <InlineSVG
                                src={editIcon}
                                onClick={() => openEditScheduleForm(dataMaintenanceSchedule.id)}
                            />
                            <InlineSVG
                                src={deleteIcon}
                                onClick={() => setScheduleToBeDeleted(dataMaintenanceSchedule.id)}
                            />
                        </>
                    );
                },
            },
        ],
        [history]
    );

    async function loadScheduleList() {
        setScheduleListApiState(API_STATES.loading);
        const response = await getScheduleList();
        if (response.status === API_STATES.success) {
            const scheduleList = response.data;
            setScheduleList(scheduleList);
            setScheduleListApiState(API_STATES.success);
        } else {
            handleApiError(response.error, setScheduleListApiState, setScheduleListApiError);
            setScheduleList([]);
        }
    }

    useEffect(() => {
        loadScheduleList();
    }, []);
    return (
        <>
            <h1>Data Maintenance Schedules</h1>
            <hr />
            <ByzzerLink label="Create New Schedule" onClick={openCreateScheduleForm} />
            <br />
            <br />
            {scheduleListApiState === API_STATES.loading ? (
                <span className={generalStyles.statusMessage}>Fetching Data Maintenance Schedule list....</span>
            ) : scheduleListApiState === API_STATES.error ? (
                <span className={generalStyles.errorMessage}>Error: {scheduleListApiError}</span>
            ) : scheduleList.length === 0 ? (
                <span className={generalStyles.statusMessage}>No Data Maintenance schedules found</span>
            ) : (
                <DataTable data={scheduleList} columns={scheduleListColumns} />
            )}
        </>
    );
}
