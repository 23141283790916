import React from 'react';
import styles from '@/components/General/ByzzerSwitch.module.scss';
import classNames from 'classnames';

export function ByzzerSwitch({
    label,
    name,
    value,
    placeholder,
    onChange,
    className,
    id,
    disabled = false,
    disabledReason = 'Sorry you are not allowed to change this',
    ...props
}) {
    return (
        <label
            className={classNames(styles.byzzerSwitch, disabled ? styles.disabled : '')}
            {...props}
            disabled={disabled}
            title={disabled ? disabledReason : ''}
        >
            <input
                checked={value}
                type={'checkbox'}
                onChange={onChange}
                id={id}
                name={name}
                value={value}
                disabled={disabled}
                data-test={'checkbox'}
            />
            <div
                className={classNames(styles.byzzerSwitch, styles.byzzerSwitchToggle, disabled ? styles.disabled : '')}
            />
        </label>
    );
}

export default ByzzerSwitch;
