import React from 'react';
import styles from './Styles.module.scss';
import './HasValueColumnFilter.scss'
import Select from 'react-select';

export const HasValueColumnFilter = ({ column }) => {
    const { filterValue, setFilter } = column;

    const options = [
        { label: 'Yes', value: 'yes' },
        { label: 'No', value: 'no' }
    ]

    return (
        <div className={styles.filterRow}>
            <Select
                classNamePrefix={'has-value-column-filter'}
                options={options}
                onChange={setFilter}
                value={filterValue}
                isClearable={true}
            />
        </div>
    );
};
