import React from 'react';
import styles from '@/components/General/ByzzerLink.module.scss';
import classNames from 'classnames';

function ByzzerLink({ label, className, onClick, ...props }) {
    return (
        <div className={classNames(styles.byzzerLink, className)} href={''} onClick={onClick}>
            <span className={styles.label}>{label}</span>
            <div className={styles.border}></div>
        </div>
    );
}

export default ByzzerLink;
