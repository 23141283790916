import styles from './AlertDetails.module.scss';
import { useState, useEffect, useMemo, useCallback } from 'react';
import generalStyles from '@/components/General/Styles.module.scss';
import API_STATES from '@/constants/StateConstants';
import { useHistory, useParams } from 'react-router-dom';
import * as SalesAPI from '../../services/api/sales.service';
import ByzzerLink from '../General/ByzzerLink';
import * as Constants from '@/components/OnDemandAlerts/Constants';
import classNames from 'classnames';
import moment from 'moment';
import DataTable from '../General/DataTable';
import ByzzerAutoRefreshButton from '../General/ByzzerAutoRefreshButton';

/**
 * @typedef {Object} AlertHistory
 * @property {number} id
 * @property {string} statusDate
 * @property {string} statusValue
 * @property {string} createdDtm
 * @property {string} updatedDtm
 *
 */

/**
 * @typedef {Object} AlertDetails
 * @property {number} id
 * @property {string} correlationId
 * @property {string} jobId
 * @property {string} initiator
 * @property {string} statusDate
 * @property {string} alertType
 * @property {string} emailVersion
 * @property {string} emailAddress
 * @property {string} brand
 * @property {string} category
 * @property {string} replyToEmail
 * @property {string} fromName
 * @property {string} firstName
 * @property {string} statusValue
 * @property {string} updatedDtm
 * @property {string} updatedDtm
 * @property {AlertHistory[]} histories
 */
function AlertDetails(props) {
    // Params from route.
    const { correlationId } = useParams();
    const history = useHistory();
    const [alertDetailsApiState, setAlertDetailsApiState] = useState(API_STATES.none);
    const [alertDetailsApiError, setAlertDetailsApiError] = useState('');

    /** @type {AlertDetails} */
    const alertDetailsInitialVal = null;
    const [alertDetails, setAlertDetails] = useState(alertDetailsInitialVal);

    function alertLabelForAlertTypeValue(value) {
        return Constants.emailTypes.find((it) => it.value === value)?.label || 'Unknown Type';
    }

    const loadAlertDetails = useCallback(async () => {
        setAlertDetailsApiState(
            alertDetailsApiState === API_STATES.success ? API_STATES.refreshing : API_STATES.loading
        );
        const response = await SalesAPI.getAlertsDetailsByCorrelationId(correlationId);
        if (response.status === API_STATES.success) {
            const alertDetails = response.data;
            setAlertDetails(alertDetails);
            setAlertDetailsApiState(API_STATES.success);
        } else {
            setAlertDetailsApiError(response.error);
            setAlertDetails(null);
            setAlertDetailsApiState(API_STATES.error);
        }
    }, [alertDetailsApiState, correlationId]);

    useEffect(() => {
        loadAlertDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [correlationId]);

    const [sendAlertApiState, setSendAlertApiState] = useState(API_STATES.none);
    const [sendAlertApiError, setSendAlertApiError] = useState('');

    const isResendDisabled = useMemo(() => {
        return sendAlertApiState !== API_STATES.none && alertDetailsApiState !== API_STATES.success;
    }, [alertDetailsApiState, sendAlertApiState]);

    const isResendPossible = useMemo(() => {
        let isNotRespondingSinceLong = false;
        if (alertDetails && alertDetails.updatedDtm) {
            const updatedDtm = moment(alertDetails.updatedDtm);
            const waitingThreshold = updatedDtm.add(10, 'minutes');
            console.log(waitingThreshold.format('MMMM Do YYYY, h:mm:ss a'));
            isNotRespondingSinceLong = !alertDetails.statusValue.includes('Success') && moment() > waitingThreshold;
        }
        return (
            alertDetails &&
            (alertDetails.statusValue.includes('Failed') || isNotRespondingSinceLong) &&
            alertDetails.alertType &&
            alertDetails.brand &&
            alertDetails.category &&
            alertDetails.emailAddress &&
            alertDetails.firstName &&
            alertDetails.fromName &&
            alertDetails.replyToEmail
        );
    }, [alertDetails]);

    function getAlertStatusWithStyling(statusValue) {
        return (
            <span
                className={classNames(
                    styles.value,
                    statusValue.includes('Success')
                        ? generalStyles.successMessage
                        : statusValue.includes('Failed')
                        ? generalStyles.errorMessage
                        : generalStyles.statusMessage
                )}
            >
                {statusValue}
            </span>
        );
    }

    const sendAlert = useCallback(async () => {
        setSendAlertApiState(API_STATES.loading);

        const result = await SalesAPI.resubmitOnDemandSalesAlerts(correlationId);
        // We track actual statuses of the lines of data in the status table,
        // not here.  So this is just to see if the submission worked or not.
        // If it did, indicate this in a state, so we can check that later
        // and continue to show the "send email" button, or change that to a
        // link to the status table.
        switch (result.status) {
            case API_STATES.success:
                setSendAlertApiState(API_STATES.success);
                break;
            default:
                const errorStatus = result.error || 'unknown error';
                setSendAlertApiError(`error submitting mail: ${errorStatus}`);
                setSendAlertApiState(API_STATES.error);
                break;
        }
        setTimeout(() => setSendAlertApiState(API_STATES.none), 2000);
    }, [correlationId]);
    /**
     *
     * @param {string} dateTimeString Date time value in ISO format
     * @returns {string} The formatted version of date time
     */
    const getTimeString = (dateTimeString) => {
        const dateTime = moment(dateTimeString);
        return dateTime.format('MMMM Do YYYY, h:mm:ss a');
    };

    const alertHistoryColumns = useMemo(
        () => [
            {
                Header: 'Time',
                accessor: 'statusDate',
                className: generalStyles.tableCell,
                Cell: ({ value }) => getTimeString(value),
            },
            {
                Header: 'Status',
                accessor: 'statusValue',
                className: generalStyles.tableCell,
                Cell: ({ value }) => getAlertStatusWithStyling(value),
            },
        ],
        []
    );

    const sendAlertButton = useMemo(
        () => (
            <div>
                <button className={generalStyles.submitButton} onClick={sendAlert} disabled={isResendDisabled}>
                    Resend Alert
                </button>
                {sendAlertApiState === API_STATES.loading ? (
                    <span className={generalStyles.statusMessage}>Processing...</span>
                ) : sendAlertApiState === API_STATES.error ? (
                    <span className={generalStyles.errorMessage}>{sendAlertApiError}</span>
                ) : (
                    sendAlertApiState === API_STATES.success && (
                        <>
                            <span className={generalStyles.statusMessage}> Alert Initiated </span>
                        </>
                    )
                )}
            </div>
        ),
        [isResendDisabled, sendAlert, sendAlertApiError, sendAlertApiState]
    );

    const alertDetailsView = useMemo(
        () =>
            alertDetails && (
                <>
                    {/* refreshing status */}
                    <ByzzerAutoRefreshButton timeInterval={5000} onRefresh={loadAlertDetails} />
                    {alertDetailsApiState === API_STATES.refreshing && (
                        <span className={generalStyles.statusMessage}> Refreshing Alert Details</span>
                    )}

                    {/* alertType */}
                    <div className={styles.alertDetailField}>
                        <span className={styles.label}>Alert Type:</span>
                        <span className={styles.value}>{alertLabelForAlertTypeValue(alertDetails.alertType)}</span>
                    </div>

                    {/* brand */}
                    <div className={styles.alertDetailField}>
                        <span className={styles.label}>Brand:</span>
                        <span className={styles.value}>{alertDetails.brand ?? '<NA>'}</span>
                    </div>

                    {/* category */}
                    <div className={styles.alertDetailField}>
                        <span className={styles.label}>Category:</span>
                        <span className={styles.value}>{alertDetails.category ?? '<NA>'}</span>
                    </div>

                    {/* Email Version */}
                    <div className={styles.alertDetailField}>
                        <span className={styles.label}>Email Version:</span>
                        <span className={styles.value}>{alertDetails.emailVersion}</span>
                    </div>

                    {/* Status */}
                    <div className={styles.alertDetailField}>
                        <span className={styles.label}>Status:</span>
                        {getAlertStatusWithStyling(alertDetails.statusValue)}
                    </div>

                    {/* to Name (first name) */}
                    <div className={styles.alertDetailField}>
                        <span className={styles.label}>First Name:</span>
                        <span className={styles.value}>{alertDetails.firstName ?? '<NA>'}</span>
                    </div>

                    {/* to email */}
                    <div className={styles.alertDetailField}>
                        <span className={styles.label}>Email:</span>
                        <span className={styles.value}>{alertDetails.emailAddress ?? '<NA>'}</span>
                    </div>

                    {/* from name */}
                    <div className={styles.alertDetailField}>
                        <span className={styles.label}>From Name:</span>
                        <span className={styles.value}>{alertDetails.fromName ?? '<NA>'}</span>
                    </div>

                    {/* from email */}
                    <div className={styles.alertDetailField}>
                        <span className={styles.label}>Reply to Email:</span>
                        <span className={styles.value}>{alertDetails.replyToEmail ?? '<NA>'}</span>
                    </div>

                    {/* Correlation Id */}
                    <div className={styles.alertDetailField}>
                        <span className={styles.label}>Correlation Id:</span>
                        <span className={styles.value}>{alertDetails.correlationId}</span>
                    </div>

                    {/* Created On */}
                    <div className={styles.alertDetailField}>
                        <span className={styles.label}>Created On:</span>
                        <span className={styles.value}>{getTimeString(alertDetails.statusDate)}</span>
                    </div>

                    {/* Last Updated On */}
                    <div className={styles.alertDetailField}>
                        <span className={styles.label}>Last Updated On:</span>
                        <span className={styles.value}>{getTimeString(alertDetails.updatedDtm)}</span>
                    </div>

                    {/* Alert History */}
                    <br />
                    <div className={styles.alertDetailField}>
                        <span className={styles.label}>Alert History:</span>
                        {isResendPossible && sendAlertButton}
                    </div>
                    <DataTable data={alertDetails.histories} columns={alertHistoryColumns} />
                </>
            ),
        [alertDetails, alertDetailsApiState, alertHistoryColumns, isResendPossible, loadAlertDetails, sendAlertButton]
    );

    return (
        <>
            <h1>On Demand Sales Alert Details</h1>
            <hr />
            <ByzzerLink label="Back" onClick={() => history.goBack()} />
            <br />
            <br />
            {alertDetailsApiState === API_STATES.loading ? (
                <span className={generalStyles.statusMessage}>Fetching Alert Details...</span>
            ) : alertDetailsApiState === API_STATES.error ? (
                <span className={generalStyles.errorMessage}>Failed: {alertDetailsApiError}</span>
            ) : (
                alertDetailsView
            )}
        </>
    );
}

export default AlertDetails;
