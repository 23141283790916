import generalStyles from '@/components/General/Styles.module.scss';

const RadioButton = ({ label, value, isChecked, onChange }) => {
    return (
        <>
            <div className={generalStyles.row}>
                <div>
                    <input type="radio" checked={isChecked} value={value} onChange={onChange} />
                </div>
                <div>
                    <label>{label}</label>
                </div>
            </div>
        </>
    );
};

export default RadioButton;
