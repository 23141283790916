import axios from 'axios';
import firebase from 'firebase/app';
import 'firebase/auth';
import API_STATES from '@/constants/StateConstants';

const http = axios.create({
    baseURL: import.meta.env.REACT_APP_BASEURL,
});

function getErrorMessage(error){
    if (error.response?.data?.code) {
        return error.response.data.code;
    }
    return error.toString();
}

/*
Watches auth token state changes to keep the request header in sync
*/

http.interceptors.request.use(async (config) => {
    if (firebase.auth().currentUser) {
        config.headers.Authorization = `Bearer ${await firebase.auth().currentUser.getIdToken()}`;
    }
    return config;
});

export async function getMarkets() {
    try {
        const marketsResponse = await http.get(`/retailer/`);
        return { status: API_STATES.success, data: marketsResponse.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function getOmniPriorApprovalMarkets() {
    try {
        const marketsResponse = await http.get(`/retailer/omni`);
        return { status: API_STATES.success, data: marketsResponse.data };
    } catch (error) {
        return { status: API_STATES.error, error: getErrorMessage(error) };
    }
}