import React, { useEffect, useState, useContext, useCallback } from 'react';
import API_STATES from '@/constants/StateConstants';
import generalStyles from '@/components/General/Styles.module.scss';
import { companyPageContext } from '@/components/NewCompanyPage/NewCompanyPage';
import { handleApiError } from '@/components/General/HandleApiError';
import { Collapse } from 'antd';
import './Accordion.scss';
import store from '@/store';

import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import MultiYearAgreement from '../CompanyConfig/MultiYearAgreement';
import ByzzerAccessEndDate from '../CompanyConfig/ByzzerAccessEndDate';

const { Panel } = Collapse;

function General(props) {
    let {
        nsCompanyId,
        companyConfig: { companyName, companyAccessLevel, isMultiCompany },
    } = useContext(companyPageContext);

    const { isSuperUser } = store?.getState()?.account ?? {};

    const [openPanels, setOpenPanels] = useState([]);
    const [ refreshTimeStamp, setRefreshTimeStamp] = useState(null)

    const handleMultiYearAgreementChange = useCallback(() => {
        setRefreshTimeStamp(Date.now())
    }, [nsCompanyId])

    return (
        <>
            <div>
                <h2 style={{ color: 'black' }}>
                    {companyName}
                    {isMultiCompany && <span className={generalStyles.statusMessage}>(multi company)</span>}
                </h2>

                <strong>{companyAccessLevel}</strong>
            </div>
            <br />
            <Collapse
                activeKey={openPanels}
                onChange={setOpenPanels}
                className={'alerts-list-accordion'}
                expandIconPosition={'end'}
            >
                <Panel
                    header = {
                        <div className={'alerts-list-panel-heading'}>
                            <span className={'alerts-list-panel-heading-title'}>
                                <b>Byzzer Access End Date</b>
                            </span>
                        </div>
                    }
                >
                    <ByzzerAccessEndDate 
                        nsCompanyId={nsCompanyId}
                        isEditable={isSuperUser} 
                        refreshTimeStamp={refreshTimeStamp}
                    />
                </Panel>
                <Panel
                    header={
                        <div className={'alerts-list-panel-heading'}>
                            <span className={'alerts-list-panel-heading-title'}>
                                <b> Multi Year Agreement </b>
                            </span>
                        </div>
                    }>
                    <MultiYearAgreement
                        nsCompanyId={nsCompanyId}
                        isEditable={isSuperUser}
                        onChange={handleMultiYearAgreementChange}
                    />
                     
                </Panel>
            </Collapse>
        </>
    );
}

export default General;
