import React from 'react'; 

function ObjectConfigTable({ configBlob }) {
    function keyVals(configBlob) {
        const ret = [];
        for (let blobAttribute in configBlob) {
            ret.push({ key: blobAttribute, value: JSON.stringify(configBlob[blobAttribute]) });
        }
        return ret;
    }

    let display = keyVals(configBlob);

    return (
        <table>
            <thead>
            <tr>
                <th>Key</th>
                <th>Value</th>
            </tr>
            </thead>
            <tbody>
            {display.map((entry) => {
                return (
                    <tr key={entry.key}>
                        <td>{entry.key}</td>
                        <td>{entry.value}</td>
                    </tr>
                );
            })}
            </tbody>
        </table>
    );
}

export default ObjectConfigTable;
