import React, { useState, useEffect } from 'react';
import API_STATES from '@/constants/StateConstants';
import * as API from '@/services/api/csrdata.service';
import { COLUMNS } from '@/components/ModifyCSR/CsrTableColumns';
import generalStyles from '@/components/General/Styles.module.scss';
import { handleApiError } from '@/components/General/HandleApiError';
import DataTable from '../General/DataTable';
import { Link } from 'react-router-dom';

function ModifyCSR() {
    const [csrListApiStatus, setCsrListApiStatus] = useState(API_STATES.none);
    const [csrListApiError, setCsrListApiError] = useState('');
    const [csrList, setCsrList] = useState([]);

    async function getCsrList() {
        setCsrListApiStatus(API_STATES.loading);
        const result = await API.getAllCSRs();
        switch (result.status) {
            case API_STATES.success:
                setCsrListApiStatus(API_STATES.success);
                setCsrList(result.data);
                break;
            case API_STATES.error:
                let error = result.error;
                handleApiError(error, setCsrListApiStatus, setCsrListApiError, 'CSR List not found');
                break;
            default:
                setCsrListApiError('');
                setCsrListApiStatus(API_STATES.none);
        }
    }

    useEffect(() => {
        getCsrList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <h2>CSR Account Maintenance</h2>
            <hr />
            <div className={generalStyles.row}>
                <h3>CSR List</h3>
                <Link className={generalStyles.link} to={`/createnewcsr`}>
                    <u>
                        <strong>Create New CSR</strong>
                    </u>
                </Link>
            </div>
            <br />
            <div>
                {csrListApiStatus === API_STATES.error ? (
                    <p className={generalStyles.errorMessage}>Error: {csrListApiError}</p>
                ) : csrListApiStatus === API_STATES.none ? (
                    <p className={generalStyles.statusMessage}>Csr List</p>
                ) : csrListApiStatus === API_STATES.loading ? (
                    <p className={generalStyles.statusMessage}>Loading...</p>
                ) : csrListApiStatus === API_STATES.success ? (
                    <>{csrList?.length > 0 && <DataTable data={csrList} columns={COLUMNS} />}</>
                ) : null}
            </div>
        </>
    );
}
export default ModifyCSR;
