import React, { useState, useEffect, useMemo } from 'react';
import DataTable from '../General/DataTable';
import Popup from 'reactjs-popup';
import generalStyles from '@/components/General/Styles.module.scss';
import TextInput from '@/components/General/TextInput';
import styles from './CategoryManagement.module.scss'
import {COMMON_CATEGORY_EVENT_COLUMNS} from './CommonCategoryEventColumns';
import Select from 'react-select';
import { getCategoriesWithKeys, submitChangedCategories, getSplitUpCategories } from '@/services/api/product.service';
import API_STATES from '@/constants/StateConstants';
import calendarIcon from '@images/icons/calendar.svg';
import downloadIcon from '@images/icons/download.svg';
import deleteIcon from '@images/icons/deleteIcon.svg';
import editIcon from '@images/icons/EditIcon.svg';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import ByzzerMultiSelectTextInput from '@/components/General/ByzzerMultiselectTextInput/ByzzerMultiSelectTextInput';

function SplitupCategory(props) {
    const [openModal, setOpenModal] = useState(false)   
    const [splitUpEventName, setSplitUpEventName] = useState('')
    const [selectedCategory, setSelectedCategory] = useState([])
    const [categoriesApiState, setCategoriesApiState] = useState(API_STATES.none);
    const [categoriesApiError, setCategoriesApiError] = useState(null);
    const [currentCategories, setCurrentCategories] = useState([]);
    const [newCategory, setNewCategory] = useState('')
    const [dataRefreshDate, setDataRefreshDate] = useState('')
    const [newCategories, setNewCategories] = useState([])
    const [splitUpCategoriesList, setSplitUpCategoriesList] = useState([])

    const onSelectCategories = (selectedCategory) => {
        setSelectedCategory(selectedCategory);
    }

    useEffect(() => {
        loadCategories()
    }, [])

    const transformCategories = (list) => {
        return (
            list?.map((category) => {
                return { label: category.category, value: Number(category.categoryKey) };
            }) ?? []
        );
    };

    async function loadCategories() {
        try {
            const response = await getCategoriesWithKeys();
            if (response.status === API_STATES.success) {
                const categories = response.data;
                const categoryOption = transformCategories(categories);
                setCurrentCategories(categoryOption)
            }
        } catch (e) {
            setCurrentCategories([]);
        }
    }

    const onDateChange = (endDateValue) => {
        if (endDateValue && endDateValue !== '') {
            setDataRefreshDate(endDateValue);
        } else {
            setDataRefreshDate(null);
        }
    }

    function dateFilter(date) {
        const isSaturday = date.getDay() === 2;
        const isBeforeMaxDate = date.getTime() >= Number(new Date().getTime());
        return isSaturday && isBeforeMaxDate;
    }

    function onAddingCategories(categories) {
        setNewCategories(categories)
    }

    function onCategoryChange(value) {
        setNewCategory(value.toUpperCase())
    }

    async function onSubmit() {
        const scheduledDateStringFormat = new Date(dataRefreshDate.getTime() - dataRefreshDate.getTimezoneOffset() * 60000).toISOString();
        const oldCategory = [{category_name: selectedCategory?.label, category_key: selectedCategory?.value}]
        const newCategory = newCategories?.map((item) => {
            const getCategoryObject = typeof item?.value === 'number' ?
                { category_name: item.label, category_key: item?.value } :
                { category_name: item.label }
            return getCategoryObject

        })

        try {
            setCategoriesApiState(API_STATES.loading)
            const result = await submitChangedCategories(splitUpEventName, 'split_up', oldCategory, newCategory, scheduledDateStringFormat)
            getSplitUpCategoryList()
        } catch (e) {
            console.log(e)
            setCategoriesApiError(API_STATES.error)

        } finally {
            setCategoriesApiState(API_STATES.success)
            setOpenModal(false)
            setSelectedCategory([])
            setSplitUpEventName('')
            setNewCategories()
            setDataRefreshDate()
        }
    }

    useEffect(() => {
        getSplitUpCategoryList()
    }, [currentCategories])

    async function getSplitUpCategoryList() {
        try {
            const result = await getSplitUpCategories();
            const getCategoriesList = result.data.map((item) => {
                 return {
                    eventType: "split_up",
                    eventName: item.display_name,
                    oldCategories: item?.old_categories?.length ? item?.old_categories?.map((category)=>({label:category?.category_name, value : category?.category_key })) : '',
                    newCategory: item?.new_categories?.length? item?.new_categories?.map((newCategory)=>newCategory?.category_name).join(', ') : '',
                    date: new Date(new Date(item.scheduled_dtm).getTime() + (new Date().getTimezoneOffset() * 60000)).toLocaleDateString(),
                    status: item.status
                }
            })
            setSplitUpCategoriesList(getCategoriesList);
        } catch (error) {
            console.log(error)
        }
    }
    const getExistingCatgories = useMemo(()=>{
        return currentCategories?.filter((category)=> category?.label !== selectedCategory?.label)
    }, [selectedCategory])

    return (
        <>
            <h1>Split Up Categories</h1>
            <hr />
            <div>
                <button className={`${generalStyles.submitButton}`} onClick={() => setOpenModal(true)}>Add Split Up Event</button>
                <DataTable noDataDescription = {'No split up catgeories found'} data={splitUpCategoriesList ?? []} columns={COMMON_CATEGORY_EVENT_COLUMNS} />
            </div>
            {openModal && (
                <Popup
                    open={openModal}
                    onClose={() => {
                        setOpenModal(false);
                    }}
                    modal
                >
                    {() => (
                        <div className={styles.formContainer}>
                                <div className={generalStyles.formItem}>
                                    <TextInput
                                        label={'Event Name:'}
                                        onInputChange={(e) => {
                                            setSplitUpEventName(e.target.value);
                                        }}
                                        value={splitUpEventName}
                                        placeholder={'Event Name'}
                                    />
                                </div>

                                <div className={generalStyles['input-wrapper']}>
                                    <label className={generalStyles.label}>Old Category:</label>
                                    <span className={generalStyles.formField}>
                                        <Select
                                            className={generalStyles.formItem}
                                            options={currentCategories}
                                            closeMenuOnSelect={false}
                                            onChange={(selectedCategory) => onSelectCategories(selectedCategory)}
                                            value={selectedCategory}
                                            placeholder="Categories"
                                        />
                                    </span>
                                </div>

                                <div className={generalStyles['input-wrapper']}>
                                    <label className={generalStyles.label}>New Categories:</label>
                                    <ByzzerMultiSelectTextInput
                                        getOptions={getExistingCatgories}
                                        className={`${generalStyles.formField}`}
                                        onInputValueChange={onCategoryChange}
                                        inputValue={newCategory}
                                        setInputValue={setNewCategory}
                                        isValueMatching={() => true}
                                        value={newCategories}
                                        onChange={onAddingCategories} />
                                </div>
                                <div className={generalStyles['input-wrapper']}>
                                    <label className={generalStyles.label}>Select Date:</label>
                                    <span className={`${styles.datepickerContainer} ${generalStyles.formItem}`}>
                                        <DatePicker
                                            name="endDate"
                                            placeholderText="End Date"
                                            shouldCloseOnSelect={true}
                                            className={styles.datepickerInput}
                                            fixedHeight
                                            closeOnScroll={true}
                                            dateFormat="MMM d, y"
                                            selected={dataRefreshDate ? dataRefreshDate : null}
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            tabIndex={1}
                                            onChange={(date) => {
                                                onDateChange(date);
                                            }}
                                            minDate={moment().toDate()}
                                            filterDate={dateFilter}
                                            onKeyDown={(e) => {
                                                e.preventDefault();
                                            }}
                                        />
                                        <img src={calendarIcon} alt="calendar icon" className={styles.calenderIcon} />
                                    </span>
                                <br />
                            </div>
                            <button disabled={!(newCategories && currentCategories && splitUpEventName && dataRefreshDate)} className={`${generalStyles.submitButton}`} onClick={onSubmit}>Submit</button>
                            {
                                categoriesApiError && <p className={generalStyles.errorMessage}>Failed to submit split up categories</p>
                            }
                            {
                                categoriesApiState === 'loading' && <p className={generalStyles.statusMessage}>Submitting split up categories....</p>
                            }
                        </div>
                    )}
                </Popup>
            )}
        </>
    );
}
export default SplitupCategory;
