import React from 'react';
import generalStyles from '@/components/General/Styles.module.scss';
import TrialProductListItem from '@/components/ExtendTrial/TrialProductListItem';
import moment from 'moment';

function TrialProductsList(props) {
    function prettyDate(expDate) {
        if (expDate === null) {
            return null;
        } else {
            const date = new Date(expDate);
            const expiryDate = moment(date);
            return expiryDate;
        }
    }
    function prettyTime(expDate) {
        if (expDate === null) {
            return null;
        } else {
            const date = new Date(expDate);
            const expiryTime = moment(date).format('hh:mm a');
            return expiryTime;
        }
    }

    return (
        <ol className={generalStyles.list}>
            {props.trialProducts.map((trial) => {
                return (
                    <>
                        <b className={generalStyles.cap}>
                            {trial?.product_type} - {trial?.ns_catalog_item_title}
                        </b>
                        {trial.original_expiry !== null ? (
                            <TrialProductListItem
                                trialProduct={trial}
                                nsCompanyId={props.nsCompanyId}
                                expiryDate={prettyDate(trial?.original_expiry)}
                                expiryTime={prettyTime(trial?.original_expiry)}
                                isEditable={props.isEditable}
                            />
                        ) : (
                            <p className={generalStyles.statusMessage}>No expiry date found for this product!</p>
                        )}
                    </>
                );
            })}
        </ol>
    );
}

export default TrialProductsList;
