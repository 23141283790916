import React, { useState, useEffect } from 'react';
import API_STATES from '@/constants/StateConstants';
import * as API from '@/services/api/product.service';
import BundleList from '@/components/BundleManager/BundleList';
import CreateBundle from '@/components/BundleManager/CreateBundle';
import generalStyles from '@/components/General/Styles.module.scss';
import styles from '@/components/BundleManager/BundleManager.module.scss';
import { handleApiError } from '@/components/General/HandleApiError';

function BundleManager(props) {
    const [bundleListApiStatus, setBundleListApiStatus] = useState(API_STATES.none);
    const [bundleListApiError, setBundleListApiError] = useState('');
    const [bundleDeleted, setBundleDeleted] = useState(false);
    const [bundleList, setBundleList] = useState([]);
    const [bundleNameUpdated, setBundleNameUpdated] = useState(false);
    const [createBundleLinkSelected, setCreateBundleLinkSelected] = useState(false);
    const [newBundleCreated, setNewBundleCreated] = useState(false);

    async function getBundleList() {
        setBundleListApiStatus(API_STATES.loading);
        const result = await API.getAllBundles();
        switch (result.status) {
            case API_STATES.success:
                setBundleListApiStatus(API_STATES.success);
                setBundleList(result.data);
                setBundleDeleted(false);
                setBundleNameUpdated(false);
                setNewBundleCreated(false);
                break;
            case API_STATES.error:
                let error = result.error;
                handleApiError(error, setBundleListApiStatus, setBundleListApiError);
                break;
            default:
                setBundleListApiError('');
                setBundleListApiStatus(API_STATES.none);
        }
    }

    useEffect(() => {
        getBundleList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (bundleDeleted || bundleNameUpdated || newBundleCreated) {
            getBundleList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bundleDeleted, bundleNameUpdated, newBundleCreated]);

    return (
        <>
            <h1>Bundle Manager</h1>
            <hr />
            <div>
                {!createBundleLinkSelected && (
                    <button className={generalStyles.submitButton} onClick={() => setCreateBundleLinkSelected(true)}>
                        Create New Bundle
                    </button>
                )}
                {createBundleLinkSelected && (
                    <CreateBundle
                        bundles={bundleList}
                        onNewBundleCreation={() => setNewBundleCreated(true)}
                        onCancelCreation={() => setCreateBundleLinkSelected(false)}
                    />
                )}
            </div>
            <h3>Bundle List</h3>
            <div className={styles.bundleList}>
                {bundleListApiStatus === API_STATES.error ? (
                    <p className={generalStyles.errorMessage}>Error: {bundleListApiError}</p>
                ) : bundleListApiStatus === API_STATES.none ? (
                    <p className={generalStyles.statusMessage}>Bundle List</p>
                ) : bundleListApiStatus === API_STATES.loading ? (
                    <p className={generalStyles.statusMessage}>Loading...</p>
                ) : bundleListApiStatus === API_STATES.success ? (
                    <>
                        <BundleList
                            bundles={bundleList}
                            onBundleDelete={() => setBundleDeleted(true)}
                            onBundleNameUpdate={() => setBundleNameUpdated(true)}
                        />
                    </>
                ) : null}
            </div>
        </>
    );
}
export default BundleManager;
