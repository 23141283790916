import API_STATES from '@/constants/StateConstants';
import generalStyles from '@/components/General/Styles.module.scss';
import React, { useEffect, useState } from 'react';
import DataTable, { FILTER_TYPES } from '../General/DataTable';
import { MultiSelectColumnFilter } from '../General/MultiSelectColumnFilter';
import { handleApiError } from '../General/HandleApiError';
import { DateRangeColumnFilter } from '../General/DateRangeColumnFilter';
import { getFreeAccountsReport } from '@/services/api/report.service';

const FreeAccountsReport = () => {
    const [reportApiStatus, setReportApiStatus] = useState(API_STATES.none);
    const [reportApiError, setReportApiError] = useState('');
    const [reportResult, setReportResult] = useState([]);

    const freeAccountsReportColumns = [
        {
            Header: 'Company Name',
            id: 'companyName',
            accessor: 'companyName',
            className: generalStyles.tableCell,
            Filter: MultiSelectColumnFilter,
            filter: FILTER_TYPES.multiple,
        },
        {
            Header: 'Contract Start Date',
            id: 'contractStartDate',
            accessor: (row) => new Date(row.contractStartDate).toLocaleDateString(),
            className: generalStyles.tableCell,
            Filter: DateRangeColumnFilter,
            filter: FILTER_TYPES.dateRange,
        },
        {
            Header: 'Company Creation Date',
            id: 'companyCreationDate',
            accessor: (row) => new Date(row?.companyCreationDate).toLocaleDateString(),
            className: generalStyles.tableCell,
            Filter: DateRangeColumnFilter,
            filter: FILTER_TYPES.dateRange,
        },
        {
            Header: 'Pre-Sales Designation',
            id: 'freeAccountGroup',
            accessor: 'freeAccountGroup',
            className: generalStyles.tableCell,
            Filter: MultiSelectColumnFilter,
            filter: FILTER_TYPES.multiple
        },
        {
            Header: 'Status',
            id: 'preSalesStatus',
            accessor: (row)=> row?.preSalesStatus === null ? 'Active': 'Pre-sales',
            className: generalStyles.tableCell,
            Filter: MultiSelectColumnFilter,
            filter: FILTER_TYPES.multiple
        },
        {
            Header: 'Subscription',
            id: (row) => `[${row?.sku}] [${row?.title}]`,
            accessor: (row) => `[${row?.sku}] [${row?.title}]`,
            className: generalStyles.tableCell,
            Filter: MultiSelectColumnFilter,
            filter: FILTER_TYPES?.multiple
        }
    ]

    useEffect(() => {
        loadReportData()
    }, [])

    async function loadReportData() {
        setReportApiStatus(API_STATES.loading)
        let response = await getFreeAccountsReport();
        switch (response.status) {
            case API_STATES.success:
                setReportApiStatus(API_STATES.success);
                setReportResult(response?.data);
                break;
            case API_STATES.error:
                handleApiError(API_STATES.error, setReportApiStatus, setReportApiError)
                break;
            default:
                setReportApiStatus(API_STATES.none);
                setReportApiError('');
        }
    }

    return (
        <>
            <h1> Free to Paid Tracker</h1>
            <hr/>
            <div data-test="generate-report-status">
                {reportApiStatus === API_STATES.error ? (
                    <p className={generalStyles.errorMessage}>Error: {reportApiError}</p>
                ) : reportApiStatus === API_STATES.loading ? (
                    <p className={generalStyles.statusMessage}>Loading...</p>
                ) : (
                    reportApiStatus === API_STATES.success && (
                        <DataTable
                            data={reportResult}
                            columns={freeAccountsReportColumns}
                            downloadFileName={'Free to Paid Tracker'}
                            withDownload
                            noDataDescription='No free accounts found.'
                        />
                    )
                )}
            </div>
        </>
    )
}

export default FreeAccountsReport