import React, { useState, createContext, useEffect } from 'react';
import styles from '@/components/NewCompanyPage/NewCompanyPage.module.scss';
import Company from './Company';
import OboActivityTab from '@/components/NewCompanyPage/OBOActivityTab';
import UsersTab from '@/components/NewCompanyPage/UsersTab';
import General from './General';
import RunCredits from '@/components/ContractItemInstances/RunCredits';
import generalStyles from '@/components/General/Styles.module.scss';
import { useParams } from "react-router-dom"
import API_STATES from '@/constants/StateConstants';
import { getCompanyConfig } from '@/services/api/company.service';
import { handleApiError } from '../General/HandleApiError';

export const companyPageContext = createContext({
    nsUserId: 0,
    nsCompanyId: 0,
    companyConfig: {},
});

export default function NewCompanyPage() {
    const params = useParams()
    const [nsCompanyId, setNsCompanyId] = useState(0);
    const [nsUserId, setNsUserId] = useState(0);
    const [activeTabName, setActiveTabName] = useState('');

    const [companyConfig, setCompanyConfig] = useState({});
    const [companyConfigApiState, setCompanyConfigApiState] = useState(API_STATES.none);
    const [companyConfigApiError, setCompanyConfigApiError] = useState('');

    const loadCompanyConfig = async (nsCompanyId) => {
        setCompanyConfigApiState((prev) => 
            prev === API_STATES.success ? API_STATES.refreshing : API_STATES.loading
        );
        const response = await getCompanyConfig(nsCompanyId);
        if (response.status === API_STATES.success) {
            const company = response.data
            setCompanyConfig({
                companyName: company.displayName,
                companyAccessLevel: company.accessLevel,
                isOboModeAllowed: company.isOboModeAllowed,
                isDisabled: company.isDisabled,
                isMultiCompany: company.isMultiCompany,
                tenantId: company.tenantId,
                companyId: company.tenantCompanyId,
                companyDomains: company.companyDomains
            });
            setCompanyConfigApiState(API_STATES.success);
        } else {
            let error = response.error;
            setCompanyConfig({});
            handleApiError(error, setCompanyConfigApiState, setCompanyConfigApiError);
        }
    }

    useEffect(() => {
        setActiveTabName(params.nsUserId ? 'users' : 'company')
        setNsCompanyId(params.nsCompanyId)
        setNsUserId(params.nsUserId)
        if (params.nsCompanyId) {
            loadCompanyConfig(params.nsCompanyId)
        } else {
            setCompanyConfig({})
        }
    }, [params])

    const dimensions = [
        {
            displayName: 'Company',
            key: 'company'
        },
        {
            displayName: 'Users',
            key: 'users'
        },
        {
            displayName: 'Run Credits',
            key: 'run_credits'
        },
        {
            displayName: 'OBO Activity',
            key: 'obo_activity'
        },
        {
            displayName: 'General',
            key: 'general'
        }
    ];

    const renderActiveTab = () => {
        switch (activeTabName) {
            case 'company':
                return <Company onChange={() => loadCompanyConfig(nsCompanyId)} />;
            case 'users':
                return <UsersTab />;
            case 'run_credits':
                return <RunCredits />;
            case 'obo_activity':
                return <OboActivityTab />;
            case 'general':
                return <General />
            default:
                <div className={generalStyles.errorMessage}>Tab is not available</div>;
        }
    };
    return (
        <companyPageContext.Provider value={{ nsUserId, nsCompanyId, companyConfig }} >
            <div className={styles['tab-control']}>
                {dimensions.map((dimension) => {
                    return (
                        <React.Fragment key={dimension.key}>
                            <div
                                onClick={() => setActiveTabName(dimension.key)}
                                className={`${styles['tab']} ${
                                    activeTabName === dimension.key ? styles['tab-active'] : styles['tab-disabled']
                                }`}
                            >
                                <div className={styles['tab-text']}>
                                    <span>{dimension.displayName} </span>
                                </div>
                            </div>
                        </React.Fragment>
                    );
                })}
            </div>
            <div className="tab-control-content">
                {!nsCompanyId || companyConfigApiState === API_STATES.none ? (
                    <span className={generalStyles.statusMessage}>Please select a company</span>
                ) : companyConfigApiState === API_STATES.error ? (
                    <p className={generalStyles.errorMessage}>{companyConfigApiError}</p>
                ) : companyConfigApiState === API_STATES.loading ? (
                    <p className={generalStyles.statusMessage}>Getting company config details...</p>
                ) : renderActiveTab()}
            </div>
        </companyPageContext.Provider>
    );
}
