import React, { useState, useEffect } from 'react';
import { getUnusedCredit } from '@/services/api/report.service';
import styles from './UnusedCredit.module.scss';
import API_STATES from '@/constants/StateConstants';
import generalStyles from '@/components/General/Styles.module.scss';
import format from 'date-fns/format';
import { handleApiError } from '@/components/General/HandleApiError';
import ByzzerSwitch from '../General/ByzzerSwitch';
import DataTable from '@/components/General/DataTable';
import { COLUMNS } from '@/components/UnusedCreditReport/UnusedCreditTableColumns';
import EmptyTable from '@/components/General/EmptyTable';

function UnusedCreditReport(props) {
    const [reportApiStatus, setReportApiStatus] = useState(API_STATES.none);
    const [reportApiError, setReportApiError] = useState('');

    const [reportResult, setReportResult] = useState([]);
    const [includeTestAccounts, setIncludeTestAccounts] = useState(false);
    const [showActiveSubscriptionsOnly, setShowActiveSubscriptionsOnly] = useState(true)
    const [excludeDisabledCompanies, setExcludeDisabledCompanies] = useState(false);

    function prettyDate(d) {
        if (!d) {
            return '';
        }
        const pretty = Date.parse(d);
        return format(pretty, 'MM/dd/yyyy');
    }

    const handleIncludeTestAccountsChange = ({ target }) => {
        setIncludeTestAccounts(target.checked);
    };

    const handleShowActiveSubscriptionsChange = ({target}) => {
        setShowActiveSubscriptionsOnly(target.checked);
    } 

    async function generateReport(includeTestAccountsVal, showActiveSubscriptionsOnly, excludeDisabled) {
        setReportApiStatus(API_STATES.loading);
        const result = await getUnusedCredit(includeTestAccountsVal, showActiveSubscriptionsOnly, excludeDisabled);
        if (result.status === API_STATES.success) {
            setReportApiStatus(API_STATES.success);
            const reportData = result.data.map((row) => {
                return {
                    companyId: row.nsCompanyId,
                    companyName: row.companyName,
                    salesRepFname: row.salesRepFname,
                    salesRepLname: row.salesRepLname,
                    salesRepEmail: row.salesRepEmail,
                    reportType: row.reportType,
                    subscriptionName: row.subscriptionName,
                    hasActiveSubscription: row.hasActiveSubscription ? 'Yes' : 'No',
                    accessEndDate : prettyDate(row.accessEndDate),
                    hasMultiYearAgreement : row.hasMultiYearAgreement ? 'Yes' : 'No',
                    itemsPurchased: row?.purchases === "Unknown" ? "No active purchases" : row?.purchases,
                    alertsCount: row.alertsCount,
                    baseCategories: row.baseCategories,
                    usedCategories: row.usedCategories,
                    contractStartDate: prettyDate(row.contractStart),
                    contractEndDate: prettyDate(row.contractEnd),
                    percentContractCompleted: parseFloat(row.percentContractCompleted).toFixed(1),
                    baseRuns: row.baseRuns,
                    baseAddOns: row.baseAddOns,
                    baseCredits: row.baseCredits,
                    baseUsed: row.baseUsed,
                    baseUnused: row.baseUnused,
                    basePercentUsed: isNaN(
                        (parseFloat(row.baseUsed) /
                            (
                                parseInt(row.baseRuns) +
                                parseInt(row.baseAddOns) +
                                parseInt(row.baseCredits)
                            ).toPrecision(3)) *
                        100
                    )
                        ? (0).toFixed(1)
                        : (
                            (
                                parseFloat(row.baseUsed) /
                                (parseInt(row.baseRuns) + parseInt(row.baseAddOns) + parseInt(row.baseCredits))
                            ).toPrecision(3) * 100
                        ).toFixed(1),
                    smartRuns: row.smartRuns,
                    smartAddOns: row.smartAddOns,
                    smartCredits: row.smartCredits,
                    smartUsed: row.smartUsed,
                    smartUnused: row.smartUnused,
                    smartPercentUsed: isNaN(
                        (
                            parseFloat(row.smartUsed) /
                            (parseInt(row.smartRuns) + parseInt(row.smartAddOns) + parseInt(row.smartCredits))
                        ).toPrecision(3) * 100
                    )
                        ? (0).toFixed(1)
                        : (
                            (
                                parseFloat(row.smartUsed) /
                                (parseInt(row.smartRuns) + parseInt(row.smartAddOns) + parseInt(row.smartCredits))
                            ).toPrecision(3) * 100
                        ).toFixed(1),
                    totalRuns:
                        row.baseRuns === 'Infinity' || row.smartRuns === 'Infinity'
                            ? 'Infinity'
                            : row.baseRuns + row.smartRuns,
                    totalAddOns: 
                        row.baseAddOns === 'Infinity' || row.smartAddOns === 'Infinity'
                            ? 'Infinity'
                            : row.baseAddOns + row.smartAddOns,
                    totalCredits:
                        row.baseCredits === 'Infinity' || row.smartCredits === 'Infinity'
                            ? 'Infinity'
                            : row.baseCredits + row.smartCredits,
                    totalUsed: row.baseUsed + row.smartUsed,
                    totalUnused:
                        row.baseUnused === 'Infinity' || row.smartUnused === 'Infinity'
                            ? 'Infinity'
                            : row.baseUnused + row.smartUnused,
                    totalPercentUsed: isNaN(
                        (
                            (row.baseUsed + row.smartUsed) /
                            ((row.baseRuns === 'Infinity' || row.smartRuns === 'Infinity'
                                ? 'Infinity'
                                : row.baseRuns + row.smartRuns) +
                                (row.baseAddOns + row.smartAddOns) +
                                (row.baseCredits === 'Infinity' || row.smartCredits === 'Infinity'
                                    ? 'Infinity'
                                    : row.baseCredits + row.smartCredits))
                        ).toPrecision(3) * 100
                    )
                        ? (0).toFixed(1)
                        : (
                            (
                                (row.baseUsed + row.smartUsed) /
                                ((row.baseRuns === 'Infinity' || row.smartRuns === 'Infinity'
                                    ? 'Infinity'
                                    : row.baseRuns + row.smartRuns) +
                                    (row.baseAddOns + row.smartAddOns) +
                                    (row.baseCredits === 'Infinity' || row.smartCredits === 'Infinity'
                                        ? 'Infinity'
                                        : row.baseCredits + row.smartCredits))
                            ).toPrecision(3) * 100
                        ).toFixed(1)
                };
            });
            setReportResult(reportData);
        } else {
            let error = result.error;
            handleApiError(
                error,
                setReportApiStatus,
                setReportApiError,
                'Unused credit by company report not found'
            );
        }
    }

    const handleExcludeDisabledCompaniesChange = ({ target }) => {
        setExcludeDisabledCompanies(target.checked);
    }

    useEffect(() => {
        generateReport(includeTestAccounts, showActiveSubscriptionsOnly, excludeDisabledCompanies);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [includeTestAccounts, showActiveSubscriptionsOnly, excludeDisabledCompanies]);

    return (
        <>
            <h1>Unused Credit By Company</h1>
            <hr />
            <div className={styles.includeTestAccounts}>
                Include Test Accounts
                <ByzzerSwitch
                    name="includeTestAccounts"
                    onChange={handleIncludeTestAccountsChange}
                ></ByzzerSwitch>
            </div>
            <div className={styles.includeTestAccounts}>
                Show Active Subscriptions Only
                <ByzzerSwitch
                    name="showActiveSubscriptions"
                    onChange={handleShowActiveSubscriptionsChange}
                    value={showActiveSubscriptionsOnly}
                ></ByzzerSwitch>
            </div>
            <div className={styles.includeTestAccounts}>
                Exclude Disabled Companies
                <ByzzerSwitch
                    name="excludeDisabledCompanies"
                    onChange={handleExcludeDisabledCompaniesChange}
                ></ByzzerSwitch>
            </div>
            <div data-test="generate-report-status">
                {reportApiStatus === API_STATES.error ? (
                    <p className={generalStyles.errorMessage}>Error: {reportApiError}</p>
                ) : reportApiStatus === API_STATES.none ? (
                    <p className={generalStyles.statusMessage}>Unused credit by company report</p>
                ) : reportApiStatus === API_STATES.loading ? (
                    <p className={generalStyles.statusMessage}>Loading...</p>
                ) : reportApiStatus === API_STATES.success ? (
                    <>
                        {reportResult?.length > 0 && <DataTable data={reportResult} columns={COLUMNS} withDownload downloadFileName={"UnusedCreditReport"} />}
                    </>
                ) : <EmptyTable
                    noValueDescription="No companies found with unused credits."
                    columns={[
                        'Company ID',
                        'Company Name',
                        'Sales Rep First Name',
                        'Sales Rep Last Name',
                        'Sales Rep Email',
                        'Report Type',
                        'Subscription',
                        'Items Purchased',
                        'Total Alerts Configured',
                        'Purchased Categories',
                        'Selected Categories',
                        'Contract Start',
                        'Contract End',
                        'Percent Through Contract',
                        'Base Runs',
                        'Base Add On',
                        'Base Credits',
                        'Base Used',
                        'Base Unused',
                        'Base Percent Used',
                        'Smart Runs',
                        'Smart Add On',
                        'Smart Credits',
                        'Smart Unused',
                        'Smart Percent Used',
                        'Total Add On',
                        'Total Runs',
                        'Total Credits',
                        'Total Used',
                        'Total Unused',
                        'Total Percent Used',
                        'Access End Date',
                        'MYA'

                    ]} />}
            </div>
        </>
    );
}
export default UnusedCreditReport;
