export const TYPE_OF_NOTIFICATION = [
    { label: 'Warning', value: 'warning' },
    { label: 'Product Update', value: 'product_update' },
    { label: 'Announcement', value: 'announcement' },
    { label: 'Recommendation', value: 'recommendation' },
];

export const ALL = 'All';

export const PRIORITY = {
    LOW: 'low',
    MEDIUM: 'medium',
    HIGH: 'high',
};

export const MANUAL = 'manual';
export const CUSTOM = 'custom';


export const MESSAGE_TYPE = {
    USER_BASED: 'userBased',
    CATEGORY_BASED: 'categoryBased',
    MARKET_BASED: 'marketBased',
    SUBSCRIPTION_BASED: 'subscriptionBased'

};

export const SELECT_STYLES = {
    control: (base) => ({
        ...base,
        maxHeight: 100,
    }),
    indicator: (base) => ({
        ...base,
        maxHeight: 100,
    }),
    valueContainer: (base) => ({
        ...base,
        maxHeight: 90,
        overflow: 'auto',
    }),
    dropdownIndicator: (base) => ({
        ...base,
        paddingTop: 0,
        paddingBottom: 0,
    }),
    clearIndicator: (base) => ({
        ...base,
        paddingTop: 0,
        paddingBottom: 0,
    }),
};

export const DATE_SELECT_STYLES = {
    control: (base) => ({
        ...base,
        maxHeight: 100,
    }),
    indicator: (base) => ({
        ...base,
        maxHeight: 100,
    }),
    valueContainer: (base) => ({
        ...base,
        maxHeight: 90,
        overflow: 'auto',
        width: '260px',
    }),
    dropdownIndicator: (base) => ({
        ...base,
        paddingTop: 0,
        paddingBottom: 0,
    }),
    clearIndicator: (base) => ({
        ...base,
        paddingTop: 0,
        paddingBottom: 0,
    }),
    
};

export const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const USER_ACTIONS = {
    DISMISS: 'Dismiss',
    DONE: 'Do Now',
    DO_LATER: 'Do Later',
    ALREADY_DONE: 'Already Done'
};

export const FIRST_OPTION = [{ label: ALL, value: ALL }];

export const RUN_TYPE = [
    { label: 'Subscription Based', value: 'subscription' },
    { label: 'Report Based', value: 'reportBased' },
];

export const NOTIFICATION_TYPE = [
    { label: 'Market Definition Change', value: 'marketDefinitionChange' },
    { label: 'Premium Retailer', value: 'premium' },
    { label: 'Prior Approval Retailer', value: 'priorApproval' },
];
