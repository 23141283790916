import generalStyles from '@/components/General/Styles.module.scss';

export const COLUMNS = [
    {
        Header: 'Company',
        accessor: 'company',
        id: 'company',
        className: `${generalStyles.tableCell} ${generalStyles.cap}`
    },
    {
        Header: 'User Email',
        accessor: 'user',
        id: 'user',
        className: generalStyles.tableCell
    },
    {
        Header: 'OBO Email',
        accessor: 'obo',
        id: 'obo',
        className: generalStyles.tableCell
    },
    {
        Header: 'OBO First Name',
        accessor: 'obo_fname',
        id: 'f_name',
        className: `${generalStyles.tableCell} ${generalStyles.cap}`
    },
    {
        Header: 'OBO Last Name',
        accessor: 'obo_lname',
        id: 'l_name',
        className: `${generalStyles.tableCell} ${generalStyles.cap}`
    },
    {
        Header: 'Date and Time',
        accessor: 'when',
        id: 'when',
        className: generalStyles.tableCell
    },
    {
        Header: 'Action Type',
        accessor: 'action',
        id: 'actions',
        className: `${generalStyles.tableCell} ${generalStyles.cap}`
    },
    {
        Header: 'Product Activity',
        accessor: 'api',
        id: 'api',
        disableSortBy: true,
        className: generalStyles.tableCell
    }
];
