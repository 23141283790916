import React, { useEffect, useMemo, useState } from 'react';
import { getMarkets } from '@/services/api/retailer.service';
import { getApprovedMarkets, deleteApprovedMarket } from '@/services/api/company.service';
import styles from './CompanyConfig.module.scss';
import generalStyles from '@/components/General/Styles.module.scss';
import API_STATES from '@/constants/StateConstants';
import DataTable from '../General/DataTable';
import { getCategoriesWithKeys } from '@/services/api/product.service';
import AddPriorApprovedMarketForm from './AddPriorApprovedMarketForm';
import EditPriorApprovedMarketForm from './EditPriorApprovedMarketForm';
import { handleApiError } from '../General/HandleApiError';

function compareMarkets(market1, market2) {
    if (market1.parentRetailer < market2.parentRetailer) {
        return -1;
    }
    if (market1.parentRetailer > market2.parentRetailer) {
        return 1;
    }
    return 0;
}

function ApprovedMarkets({ nsCompanyId, isEditable = true, refreshTimestamp }) {
    const [approvedMarketsGetApiState, setApprovedMarketsGetApiState] = useState(API_STATES.none);
    const [approvedMarketsGetApiError, setApprovedMarketsGetApiError] = useState('');
    const [approvedMarkets, setApprovedMarkets] = useState([]);

    const [fetchCategoriesApiState, setFetchCategoriesApiState] = useState(API_STATES.none);
    const [fetchCategoriesApiError, setFetchCategoriesApiError] = useState('');
    const [categories, setCategories] = useState([]);

    const [allMarketsGetApiState, setAllMarketsGetApiState] = useState(API_STATES.none);
    const [allMarketsGetApiError, setAllMarketsGetApiError] = useState('');
    const [allMarkets, setAllMarkets] = useState([]);

    const [editPriorApprovalMarket, setEditPriorApprovalMarket] = useState(null);
    const [showAddPriorApprovalMarketForm, setShowAddPriorApprovalMarketForm] = useState(false);

    useEffect(() => {
        loadApprovedMarkets(nsCompanyId);
    }, [nsCompanyId, refreshTimestamp]);

    useEffect(() => {
        if (isEditable) {
            loadAllMarkets();
            loadCategories();
        } else {
            setAllMarkets([]);
            setCategories([]);
        }
    }, [isEditable]);

    function getUnapprovedMarkets() {
        const unapprovedMarkets = allMarkets
            .filter(function(market) {
                return approvedMarkets.findIndex((approvedMarket) => approvedMarket.id === market.id) < 0;
            })
            .sort(compareMarkets);
        console.log({ unapprovedMarkets });
        return unapprovedMarkets;
    }

    async function loadAllMarkets() {
        setAllMarketsGetApiState(API_STATES.loading);
        const marketsResponse = await getMarkets();
        if (marketsResponse.status === API_STATES.success) {
            setAllMarkets(marketsResponse.data);
            setAllMarketsGetApiState(API_STATES.success);
        } else {
            handleApiError(marketsResponse.error, setAllMarketsGetApiState, setAllMarketsGetApiError);
            setAllMarkets([]);
        }
    }

    async function loadCategories() {
        setFetchCategoriesApiState(API_STATES.loading);
        const categoriesResponse = await getCategoriesWithKeys();
        if (categoriesResponse.status === API_STATES.success) {
            setCategories(categoriesResponse.data);
            setFetchCategoriesApiState(API_STATES.success);
        } else {
            handleApiError(categoriesResponse.error, setFetchCategoriesApiState, setFetchCategoriesApiError);
            setCategories([]);
        }
    }

    async function loadApprovedMarkets(nsCompanyId) {
        setApprovedMarketsGetApiState(API_STATES.loading);
        let approvedMarketsResponse = await getApprovedMarkets(nsCompanyId);
        if (approvedMarketsResponse.status === API_STATES.success) {
            setApprovedMarkets(approvedMarketsResponse.data?.sort(compareMarkets));
            setApprovedMarketsGetApiState(API_STATES.success);
        } else {
            handleApiError(approvedMarketsResponse.error, setApprovedMarketsGetApiState, setApprovedMarketsGetApiError);
            setApprovedMarkets([]);
        }
    }

    async function initiateEdit(priorApprovalMarket) {
        setEditPriorApprovalMarket(priorApprovalMarket);
    }

    async function initiateAddMarket() {
        setShowAddPriorApprovalMarketForm(true);
    }

    async function onApprovedMarketsChange() {
        loadApprovedMarkets(nsCompanyId);
    }

    const approvedMarketsTableColumns = useMemo(() => {
        const columns = [
            {
                Header: 'Parent Retailer',
                accessor: 'parentRetailer',
                className: generalStyles.tableCell,
            },
            {
                Header: 'Categories',
                accessor: (row) =>
                    !row.isPartial? ['ALL CATEGORIES'] : row.categories?.length ? row.categories?.map((category) => category?.category) : [],
                id: 'categories',
                className: generalStyles.tableCell,
                Cell: ({ value: categories }) => (
                    <ul className={styles.categoriesList}>
                        {categories?.map((category) => (
                            <li key={category}>{category}</li>
                        ))}
                    </ul>
                ),
            },
            {
                Header: 'Confirmation URL',
                accessor: 'confirmationUrl',
                className: generalStyles.tableCell,
                Cell: ({ value: confirmationUrl }) => confirmationUrl || 'NA',
            },
        ];

        const actionsColumn = {
            Header: 'Actions',
            accessor: (row) => row,
            id: 'actions',
            disableFilters: true,
            disableSortBy: true,
            className: generalStyles.tableCell,
            Cell: ({ value: priorApprovalMarket }) => {
                const [deleteSelectedMarketApiState, setDeleteSelectedMarketApiState] = useState(API_STATES.none);
                const [deleteSelectedMarketApiError, setDeleteSelectedMarketApiError] = useState('');

                async function deleteSelectedMarket(marketId) {
                    setDeleteSelectedMarketApiState(API_STATES.loading);
                    const approveSelectedMarketResponse = await deleteApprovedMarket(nsCompanyId, marketId);
                    if (approveSelectedMarketResponse.status === API_STATES.success) {
                        setDeleteSelectedMarketApiState(API_STATES.success);
                        setTimeout(() => loadApprovedMarkets(nsCompanyId), 2000);
                    } else {
                        handleApiError(
                            approveSelectedMarketResponse.error,
                            setDeleteSelectedMarketApiState,
                            setDeleteSelectedMarketApiError
                        );
                        setTimeout(() => setDeleteSelectedMarketApiState(API_STATES.none), 2000);
                    }
                }

                return deleteSelectedMarketApiState === API_STATES.loading ? (
                    <label className={generalStyles.statusMessage}>Deleting...</label>
                ) : deleteSelectedMarketApiState === API_STATES.success ? (
                    <label className={generalStyles.successMessage}>Successfully deleted</label>
                ) : deleteSelectedMarketApiState === API_STATES.error ? (
                    <label className={generalStyles.errorMessage}>{deleteSelectedMarketApiError}</label>
                ) : (
                    <>
                        <button className={generalStyles.smallButton} onClick={() => initiateEdit(priorApprovalMarket)}>
                            Edit
                        </button>

                        <button
                            className={generalStyles.smallButton}
                            onClick={() => deleteSelectedMarket(priorApprovalMarket.id)}
                        >
                            Delete
                        </button>
                    </>
                );
            },
        };

        if (isEditable) {
            columns.push(actionsColumn);
        }
        return columns;
    }, [nsCompanyId, isEditable]);

    const waitToChangeApprovedMarkets =
        approvedMarketsGetApiState !== API_STATES.success ||
        allMarketsGetApiState !== API_STATES.success ||
        fetchCategoriesApiState !== API_STATES.success;

    return (
        <>
            {/* <h3>Approved Markets</h3>
            <hr /> */}

            {approvedMarketsGetApiState === API_STATES.error ? (
                <p className={generalStyles.errorMessage}>Error: {approvedMarketsGetApiError}</p>
            ) : showAddPriorApprovalMarketForm ? (
                <AddPriorApprovedMarketForm
                    categories={categories}
                    unapprovedMarkets={getUnapprovedMarkets()}
                    nsCompanyId={nsCompanyId}
                    onSuccess={onApprovedMarketsChange}
                    onClose={() => setShowAddPriorApprovalMarketForm(false)}
                    disabled={waitToChangeApprovedMarkets || !isEditable}
                />
            ) : editPriorApprovalMarket ? (
                <EditPriorApprovedMarketForm
                    editMarket={editPriorApprovalMarket}
                    categories={categories}
                    nsCompanyId={nsCompanyId}
                    onSuccess={onApprovedMarketsChange}
                    onClose={() => setEditPriorApprovalMarket(null)}
                    disabled={waitToChangeApprovedMarkets || !isEditable}
                />
            ) : approvedMarketsGetApiState === API_STATES.loading ? (
                <p className={generalStyles.statusMessage}>Loading Approved Markets.....</p>
            ) : (
                <>
                    {allMarketsGetApiState === API_STATES.loading ? (
                        <p className={generalStyles.statusMessage}>Loading Unapproved Markets.....</p>
                    ) : (
                        allMarketsGetApiState === API_STATES.error && (
                            <p className={generalStyles.errorMessage}>{allMarketsGetApiError}</p>
                        )
                    )}

                    {fetchCategoriesApiState === API_STATES.loading ? (
                        <p className={generalStyles.statusMessage}>Loading Categories.....</p>
                    ) : (
                        fetchCategoriesApiState === API_STATES.error && (
                            <p className={generalStyles.errorMessage}>{fetchCategoriesApiError}</p>
                        )
                    )}

                    {approvedMarkets.length === 0 ? (
                        <p className={generalStyles.statusMessage}>No approved markets</p>
                    ) : (
                        <DataTable
                            data={approvedMarkets}
                            columns={approvedMarketsTableColumns}
                            nonStickyTableHeader={true}
                        />
                    )}
                    <br />
                    {isEditable && !showAddPriorApprovalMarketForm && !editPriorApprovalMarket && (
                        <button
                            className={generalStyles.submitButton}
                            disabled={waitToChangeApprovedMarkets}
                            onClick={() => initiateAddMarket()}
                        >
                            Add Prior Approval Market
                        </button>
                    )}
                </>
            )}
        </>
    );
}
export default ApprovedMarkets;
