import generalStyles from '@/components/General/Styles.module.scss';
import { MultiSelectColumnFilter } from '../General/MultiSelectColumnFilter';
import { FILTER_TYPES } from '../General/DataTable';
import { DateRangeColumnFilter } from '../General/DateRangeColumnFilter';

export const COLUMNS = [
    {
        Header: 'Company ID',
        accessor: 'companyId',
        id: 'companyId',
        className: `${generalStyles.tableCell} ${generalStyles.cap}`,
        disableFilters: true,
    },
    {
        Header: 'Company Name',
        accessor: 'companyName',
        id: 'companyName',
        className: `${generalStyles.tableCell} ${generalStyles.cap}`,
        Filter: MultiSelectColumnFilter,
        filter: FILTER_TYPES.multiple,
    },
    {
        Header: 'Contract ID',
        accessor: 'contractId',
        id: 'contractId',
        className: generalStyles.tableCell,
        Filter: MultiSelectColumnFilter,
        filter: FILTER_TYPES.multiple,
    },
    {
        Header: 'Active Subscription End Date',
        accessor: 'activeSubscriptionEndDate',
        id: 'activeSubscriptionEndDate',
        className: `${generalStyles.tableCell} ${generalStyles.cap}`,
        Filter: DateRangeColumnFilter,
        filter: FILTER_TYPES.dateRange,
    },
    {
        Header: 'True Contract End Date',
        accessor: 'trueContractEndDate',
        id: 'trueContractEndDate',
        className: `${generalStyles.tableCell} ${generalStyles.cap}`,
        Filter: DateRangeColumnFilter,
        filter: FILTER_TYPES.dateRange,
    },
    {
        Header: 'MYA',
        accessor: 'mya',
        id: 'mya',
        className: generalStyles.tableCell,
        disableFilters: true,
    },
];

export const companyDetailsColumns = [
    {
        accessorKey: 'sku',
        header: 'sku',
    },
    {
        accessorKey: 'product_name',
        header: 'Product Name',
    },

    {
        accessorKey: 'product_type',
        header: 'Product Type',
    },
    {
        accessorKey: 'quantity',
        header: 'Quantity',
    },
    {
        accessorKey: 'annual_price',
        enableColumnOrdering: false,
        header: 'Annual Price',
    },
    {
        accessorKey: 'monthly_price',
        enableColumnOrdering: false,
        header: 'Monthly Price'
    },
    {
        accessorKey: 'item_total',
        enableColumnOrdering: false,
        header: 'Item Total'
    }
]