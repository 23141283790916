import React, { useState, useEffect } from 'react';
import styles from './NewCompanyPage.module.scss';
import API_STATES from '@/constants/StateConstants';
import generalStyles from '@/components/General/Styles.module.scss';
import { handleApiError, noOp } from '@/components/General/HandleApiError';
import TextInput from '@/components/General/TextInput';
import Select from 'react-select';
import { getTeamsByCompanyId, inviteUser, validateInvitationDomain } from '@/services/api/company.service';
import isEmail from 'is-email';
import Popup from 'reactjs-popup';
import RadioButton from '@/components/General/RadioButton';
import { DOMAIN_MISMATCH_T_AND_C, DOMAIN_MISMATCH_REASON_OPTIONS } from './Constants'

const memberRoleOptions = [
    { value: 'admin', label: 'Admin' },
    { value: 'user', label: 'User' },
    { value: 'viewer', label: 'Viewer' }
];

function InviteUsers({ nsCompanyId, onChange = noOp, refreshTimestamp = null }) {
    const [validateDomainApiStatus, setValidateDomainApiStatus] = useState(API_STATES.none);
    const [validateDomainApiError, setValidateDomainApiError] = useState('');
    const [sendInviteApiStatus, setSendInviteApiStatus] = useState(API_STATES.none);
    const [sendInviteApiMessage, setSendInviteApiMessage] = useState('');
    const [sendInviteApiError, setSendInviteApiError] = useState('');
    const [teamsApiStatus, setTeamsApiStatus] = useState(API_STATES.none);
    const [teamsApiError, setTeamsApiError] = useState('');

    const [name, setName] = useState({
        firstName: '',
        lastName: ''
    });
    const [email, setEmail] = useState('');
    const [showingDomainMismatchPrompt, setShowingDomainMismatchPrompt] = useState(false);
    const [showingTAndC, setShowingTAndC] = useState(false)
    const [prevEmail, setPrevEmail] = useState('');
    const [selectedMemberRole, setSelectedMemberRole] = useState({});
    const [selectedTeam, setSelectedTeam] = useState({});
    const [selectedDomainMismatchReasonCode, setSelectedDomainMismatchReasonCode] = useState(null)
    const [teams, setTeams] = useState([]);
    const [link, setLink] = useState('');
    const [copyStatus, setCopyStatus] = useState('');
    const [showLink, setShowLink] = useState(false);

    const onNameChange = (nameType, value) => {
        setName({
            ...name,
            [nameType]: value
        });
    };

    // function extractDomain (email) {
    //     const domainRegex = /@([\w-]+(?:\.[\w-]+)+)$/;
    //     const match = email.match(domainRegex);
    //     if (match) {
    //       return match[1];
    //     }
    //     return "";
    // }

    const onEmailChange = (value) => {
        setEmail(value);
        setPrevEmail(value);
    };

    const handleSelectedMemberRole = (role) => {
        setSelectedMemberRole(role);
    };

    const handleSelectedTeam = (team) => {
        setSelectedTeam(team);
    };

    const handleDomainMismatchReasonSelection = (e) => {
        const value = e.target.value
        setSelectedDomainMismatchReasonCode(value)
    }

    useEffect(() => {
        (async () => {
            if (nsCompanyId !== null && nsCompanyId !== 0) {
                getTeamDetails();
                setShowLink(false);
                setSendInviteApiStatus(API_STATES.none);
                setSendInviteApiError('');
                setName({
                    firstName: '',
                    lastName: ''
                });
                setEmail('');
                setSelectedMemberRole({});
                setSelectedTeam({});
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nsCompanyId, refreshTimestamp]);

    const isSubmitDisabled = () => {
        return !name.firstName || !name.lastName || !isEmail(email) || !selectedTeam.value || !selectedMemberRole.value || processingInvitation;
    };

    const resetForm = () => {
        setName({
            firstName: '',
            lastName: ''
        });
        setEmail('');
        setSelectedMemberRole({});
        setSelectedTeam({});
    };

    async function getTeamDetails() {
        setTeamsApiStatus(API_STATES.loading);
        const result = await getTeamsByCompanyId(nsCompanyId);
        switch (result.status) {
            case API_STATES.success:
                setTeamsApiStatus(API_STATES.success);
                setTeams(
                    result.data.map(({ id, title }) => ({
                        value: id,
                        label: title
                    }))
                );
                break;
            case API_STATES.error:
                let error = result.error;
                handleApiError(error, setTeamsApiStatus, setTeamsApiError);
                break;
            default:
                setTeamsApiStatus(API_STATES.none);
                setTeamsApiError('');
        }
    }

    async function validateAndSendInvitation(
        email,
        firstName,
        lastName,
        role,
        teamId,
        nsCompanyId
    ) {
        setValidateDomainApiStatus(API_STATES.loading)
        const response = await validateInvitationDomain(nsCompanyId, email)
        if (response.status === API_STATES.success) {
            const { isDomainMatching } = response.data
            if (isDomainMatching) {
                sendInvitation(
                    email,
                    firstName,
                    lastName,
                    role,
                    teamId,
                    nsCompanyId,
                )
            } else {
                showDomainMismatchPrompt()
            }
            setValidateDomainApiStatus(API_STATES.success)
        } else {
            handleApiError(response.error, setValidateDomainApiStatus, setValidateDomainApiError)
        }
        setTimeout(() => setValidateDomainApiStatus(API_STATES.none), 5000);
    }

    async function sendInvitation(
        email,
        firstName,
        lastName,
        role,
        teamId,
        nsCompanyId,
        selectedDomainMismatchReasonCode = null,
    ) {
        closeDomainMismatchPrompt()
        setSendInviteApiStatus(API_STATES.loading)
        const response = await inviteUser(
            email,
            firstName,
            lastName,
            role,
            teamId,
            nsCompanyId,
            selectedDomainMismatchReasonCode,
        );
        if (response.status === API_STATES.success) {
            setSendInviteApiStatus(API_STATES.success);
            setLink(response.data.link);
            if (response.data.invitationNeedsApproval) {
                const message = response.data.isDomainMatchingOtherCompany ? 'The invitation is created but this user has a domain name currently used by another Byzzer account. Please approve the invitation domain in users tab to process the invitation.'
                    : !response.data.isDomainMatchingInvitedCompany ? 'The invitation is created but this user has a domain name different than the domain name approved for the company. Please approve the invitation domain in users tab to process the invitation.'
                        : 'The invitation is created but needs approval. Please approve the invitation domain in users tab to process the invitation.'
                setSendInviteApiMessage(message)
            }
            setShowLink(true);
            onChange();
            setTimeout(() => {
                resetForm()
                setSendInviteApiStatus(API_STATES.none)
            }, 2000);
        } else {
            handleApiError(response.error, setSendInviteApiStatus, setSendInviteApiError);
            setTimeout(() => setSendInviteApiStatus(API_STATES.none), 5000);
        }
    }

    function showDomainMismatchPrompt() {
        setShowingDomainMismatchPrompt(true)
    }

    function closeDomainMismatchPrompt() {
        setSelectedDomainMismatchReasonCode(null)
        setShowingDomainMismatchPrompt(false)
    }

    function closeInvitationPendingApprovalPopup() {
        setSendInviteApiMessage('')
    }

    function showTermsAndConditions() {
        setShowingTAndC(true)
    }

    function closeTermsAndConditions() {
        setShowingTAndC(false)
        setShowingDomainMismatchPrompt(true)
    }

    const processingInvitation = sendInviteApiStatus === API_STATES.loading ||
        validateDomainApiStatus === API_STATES.loading ||
        showingDomainMismatchPrompt

    return (
        <>
            <div className={styles.inviteForm}>
                <br />
                <TextInput
                    type="text"
                    label="First Name:"
                    placeholder="First Name"
                    value={name.firstName}
                    onInputChange={(event) => onNameChange('firstName', event.target.value)}
                    disabled={processingInvitation}
                />

                <TextInput
                    type="text"
                    label="Last Name:"
                    placeholder="Last Name"
                    value={name.lastName}
                    onInputChange={(event) => onNameChange('lastName', event.target.value)}
                    disabled={processingInvitation}
                />

                <div className={styles.formItem}>
                    <TextInput
                        type="email"
                        label="Email:"
                        placeholder="Email"
                        value={email}
                        onInputChange={(event) => onEmailChange(event.target.value)}
                        disabled={processingInvitation}
                    />
                    {email && !isEmail(email) && (
                        <span className={generalStyles.errorMessage}>Invalid email format!</span>
                    )}
                    {validateDomainApiStatus === API_STATES.error ?
                        <span className={generalStyles.errorMessage}>{validateDomainApiError}</span> :
                        validateDomainApiStatus === API_STATES.success ?
                            <span className={generalStyles.successMessage}>Valid Email Domain</span> :
                            validateDomainApiStatus === API_STATES.loading &&
                            <span className={generalStyles.statusMessage}>validating email domain...</span>
                    }
                </div>

                <div className={generalStyles['input-wrapper']}>
                    <label>Member Type:</label>
                    <span className={generalStyles.formField}>
                        <Select
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 4,
                                colors: {
                                    ...theme.colors,
                                    text: 'black',
                                    primary25: '#cfd1d2',
                                    primary: 'hsl(120, 100%, 47%)',
                                    neutral0: 'white'
                                }
                            })}
                            onChange={handleSelectedMemberRole}
                            options={memberRoleOptions}
                            value={selectedMemberRole}
                            isDisabled={processingInvitation}
                        />
                    </span>
                </div>

                <div className={generalStyles['input-wrapper']}>
                    <label>Select a Team:</label>
                    <span className={generalStyles.formField}>
                        {teamsApiStatus === API_STATES.error ? (
                            <p className={`${generalStyles.errorMessage} ${styles.errorMessage}`}>
                                Error: {teamsApiError}
                            </p>
                        ) : teamsApiStatus === API_STATES.loading ? (
                            <p className={generalStyles.statusMessage}>Loading...</p>
                        ) : teamsApiStatus === API_STATES.none ? (
                            <p></p>
                        ) : teamsApiStatus === API_STATES.success ? (
                            <Select
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 4,
                                    colors: {
                                        ...theme.colors,
                                        text: 'black',
                                        primary25: '#cfd1d2',
                                        primary: 'hsl(120, 100%, 47%)',
                                        neutral0: 'white'
                                    }
                                })}
                                onChange={handleSelectedTeam}
                                options={teams}
                                value={selectedTeam}
                                            isDisabled={processingInvitation}
                            />
                        ) : null}
                    </span>
                </div>

                <div>
                    <button
                        className={generalStyles.submitButton}
                        disabled={isSubmitDisabled()}
                        onClick={() => validateAndSendInvitation(
                            email,
                            name.firstName,
                            name.lastName,
                            selectedMemberRole.value,
                            selectedTeam.value,
                            nsCompanyId,
                        )}
                        data-test="send-invite-btn"
                    >
                        Send Invite
                    </button>
                    {sendInviteApiStatus === API_STATES.error ? (
                        <span className={`${generalStyles.errorMessage} ${styles.errorMessage}`}>
                            Error: {sendInviteApiError}
                        </span>
                    ) : processingInvitation ? (
                        <span className={generalStyles.statusMessage}>processing invitation...</span>
                    ) : sendInviteApiStatus === API_STATES.success && (
                        <span className={generalStyles.successMessage}>Invitation sent successfully.</span>
                    ) }
                </div>
            </div>
            {showLink && link && (
                <div>
                    <h5>Invitation Link for {prevEmail}</h5>
                    <div className={styles.link_display_area}>
                        <p id="link">{link}</p>
                        <button
                            onClick={() => {
                                navigator.clipboard.writeText(link);
                                setCopyStatus('Copied!');
                                setTimeout(() => setCopyStatus(''), 1000);
                            }}
                            className={generalStyles.smallButton}
                        >
                            Copy Link
                        </button>
                        <span className={generalStyles.statusMessage}>{copyStatus}</span>
                    </div>
                </div>
            )}
            {
                <Popup
                    open={Boolean(sendInviteApiMessage)}
                    onClose={closeInvitationPendingApprovalPopup}
                    modal
                >
                    {() => (
                        <div className={styles.infoPopupContainer}>
                            <span className={generalStyles.errorMessage}>{sendInviteApiMessage}</span>
                            <br />
                            <button
                                className={generalStyles.submitButton}
                                onClick={closeInvitationPendingApprovalPopup}
                            >
                                Ok
                            </button>
                        </div>
                    )}

                </Popup>
            }
            {
                <Popup
                    open={showingDomainMismatchPrompt}
                    onClose={closeDomainMismatchPrompt}
                    modal
                >
                    {() => (
                        <div className={styles.domainMismatchWarningContainer}>
                            <b> That email address doesn't match your company's domain. </b>
                            <br />
                            <br />
                            <p> That's not a problem - you can still invite them. Just let us know more about them:</p>
                            <br />
                            {DOMAIN_MISMATCH_REASON_OPTIONS?.map((option) => {
                                return (
                                    <div className={`modal-options-item`} key={option.value}>
                                        <RadioButton
                                            label={option.label}
                                            value={option.value}
                                            isChecked={selectedDomainMismatchReasonCode === option.value}
                                            onChange={handleDomainMismatchReasonSelection}
                                        />
                                    </div>
                                );
                            })}
                            <br />
                            Please review the <b onClick={showTermsAndConditions}><u> Terms & Conditions </u></b> to make sure this user can be added to your account per the NielsenIQ data-sharing guidelines.
                            <br />
                            <button
                                className={generalStyles.submitButton}
                                onClick={() => sendInvitation(
                                    email,
                                    name.firstName, 
                                    name.lastName,
                                    selectedMemberRole.value,
                                    selectedTeam.value,
                                    nsCompanyId,
                                    selectedDomainMismatchReasonCode,
                                )}
                            >
                                Send Invite
                            </button>
                        </div>
                    )}

                </Popup>
            }
            {
                <Popup
                    open={showingTAndC}
                    onClose={() => {
                        setShowingTAndC(false);
                    }}
                    modal
                >
                    {() => (
                        <div className={styles.termsAndConditionsContainer}>
                            <div className={styles.termsAndConditionsHeader}>
                                <h3 className="terms-and-condition__heading">Terms and Conditions</h3>
                                <button className={generalStyles.smallButton} onClick={closeTermsAndConditions}>X</button>
                            </div >
                            <br />
                            <div className={styles.termsAndConditionsContent}>
                                {DOMAIN_MISMATCH_T_AND_C.map((item) => {

                                    return (
                                        <div className="terms-and-condition-wrapper">
                                            <div className="terms-and-condition-wrapper__heading"><b>{item.heading}</b></div>
                                            <div className="terms-and-condition-wrapper__content">{item.content}</div>
                                            <br />
                                        </div>

                                    );
                                })}
                            </div>
                        </div>
                    )}
                </Popup>
            }

        </>
    );
}
export default InviteUsers;
