import React, { useCallback, useEffect, useMemo, useState } from 'react';
import * as API from '@/services/api/sales.service';
import API_STATES from '@/constants/StateConstants';
import generalStyles from '@/components/General/Styles.module.scss';
import { COLUMNS } from '@/components/OnDemandAlerts/AlertsStatusTableColumns';
import store from '../../store';
import styles from './AlertsStatus.module.scss';
import EmptyTable from '@/components/General/EmptyTable';
import classNames from 'classnames';
import DataTable from '../General/DataTable';
import ByzzerAutoRefreshButton from '../General/ByzzerAutoRefreshButton';
import { prettyDate } from '../utils/utils';

function AlertsStatus() {
    // "email" is email of the current logged in user here.
    // TODO: this might be a security issue; we might need to ensure that the
    // TODO: user that uses the API is the current user also.  (Even if superuser?)
    const email = store?.getState()?.account?.user?.email;

    // State
    const [alertsStatus, setAlertsStatus] = useState([]);
    const [fetchAlertsApiState, setFetchAlertsApiState] = useState(API_STATES.none);
    const [retrievalErrorReason, setRetrievalErrorReason] = useState('');

    const [isWindowBackToFocus, setIsWindowBackToFocus] = useState(false);

    const [isInAutoRefreshMode, setIsInAutoRefreshMode] = useState(false);

    function onWindowFocus() {
        setIsWindowBackToFocus(true);
    }

    //////////////////////////////////////////////////////////////////////////
    const handleAPIError = useCallback(
        (error) => {
            switch (error?.response?.status) {
                case 401:
                    setRetrievalErrorReason('CSR is not logged in.');
                    break;
                case 403:
                    setRetrievalErrorReason('Unauthorised to perform this action');
                    break;
                case 404:
                    setRetrievalErrorReason(
                        `Customer with email address ${email} does not exist in the authentication database.`
                    );
                    break;
                default:
                    setRetrievalErrorReason('Unknown error occurred');
                    break;
            }
        },
        [email]
    );

    const isFetchingAlerts = useMemo(
        () => fetchAlertsApiState === API_STATES.loading || fetchAlertsApiState === API_STATES.refreshing,
        [fetchAlertsApiState]
    );
    const fetchAlertStatus = useCallback(async () => {
        if (isFetchingAlerts) {
            return; //this is to prevent multiple simultaneous refresh
        }

        // using API_STATES.refreshing state keeps the table visible when refreshing data
        setFetchAlertsApiState(fetchAlertsApiState === API_STATES.success ? API_STATES.refreshing : API_STATES.loading);
        const apiData = await API.getAlertsStatusByInitiator(email);
        switch (apiData.status) {
            case API_STATES.success:
                apiData.data.forEach((row) => {
                    row.statusDate = prettyDate(row.statusDate);
                });
                setAlertsStatus(apiData.data);
                setFetchAlertsApiState(API_STATES.success);
                break;
            case API_STATES.error:
                let error = apiData.error;
                handleAPIError(error);
                setFetchAlertsApiState(API_STATES.error);
                break;
            default:
                setRetrievalErrorReason('Unknown error retrieving data.');
                setFetchAlertsApiState(API_STATES.error);
                break;
        }
    }, [email, fetchAlertsApiState, handleAPIError, isFetchingAlerts]);

    useEffect(() => {
        // setShouldFetchAlertStatus(true);
        fetchAlertStatus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [email]);

    useEffect(() => {
        if (isWindowBackToFocus) {
            if (!isInAutoRefreshMode) {
                fetchAlertStatus();
            }
            setIsWindowBackToFocus(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isWindowBackToFocus]);

    useEffect(() => {
        //this lets the data be automatically refreshed when window comes back to focus
        window.addEventListener('focus', onWindowFocus);
        return () => {
            window.removeEventListener('focus', onWindowFocus);
        };
    }, []);

    ////////////////////////////////////////////////
    return (
        <>
            <h2>On Demand Sales Alerts Status</h2>
            <hr />
            {fetchAlertsApiState === API_STATES.loading ? (
                <p className={generalStyles.statusMessage}>Retrieving data...</p>
            ) : fetchAlertsApiState === API_STATES.error ? (
                <p className={generalStyles.errorMessage}>Error: {retrievalErrorReason}</p>
            ) : (
                <>
                    <div className={styles.header}>
                        <h3 className={styles.label}>Alerts Status</h3>
                        <ByzzerAutoRefreshButton
                            onRefresh={fetchAlertStatus}
                            onStart={() => setIsInAutoRefreshMode(true)}
                            onStop={() => setIsInAutoRefreshMode(false)}
                        />
                        {isFetchingAlerts && (
                            <label className={classNames(generalStyles.statusMessage, styles.label)}>
                                Fetching alerts...
                            </label>
                        )}
                    </div>
                    {alertsStatus.length > 0 ? (
                        <DataTable data={alertsStatus} columns={COLUMNS} />
                    ) : (
                        <div className={styles.statusTable}>
                            <EmptyTable
                                noValueDescription="No alerts statuses found."
                                columns={['Date', 'Email Address', 'Alert Type', 'Email Version', 'Status']}
                            />
                        </div>
                    )}
                </>
            )}
        </>
    );
}

export default AlertsStatus;
