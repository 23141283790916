import './SignIn.scss';
import React, { useEffect, useState } from 'react';
import { login } from '@/services/account.service';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Loader from '@/components/Spin';
import TextInput from '@/components/General/TextInput';
import PasswordInput from '@/components/General/PasswordInput';
import generalStyles from '@/components/General/Styles.module.scss';

function SignIn({ user, heading, props }) {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [loginError, setLoginError] = useState(false);

    const [credentials, setCredentials] = useState({
        username: '',
        password: '',
    });

    useEffect(() => {
        if (user) {
            navigateAuthenticatedUser();
        }
        // eslint-disable-next-line
    }, [user]);
    useEffect(() => {
        if (loginError) {
            setLoginError(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [credentials]);

    function updateUsername(e) {
        credentials.username = e.target.value;
        setCredentials({ ...credentials });
    }

    function updatePassword(e) {
        credentials.password = e.target.value;
        setCredentials({ ...credentials });
    }

    function signinButtonDisabled() {
        const credentialsAreBothFilledOut = credentials.username.length > 0 && credentials.password.length > 0;
        return !credentialsAreBothFilledOut;
    }

    async function signIn() {
        try {
            setLoading(true);
            setLoginError(false);
            const loginResult = await login(credentials);
            if (loginResult) {
                setLoading(false);
                await navigateAuthenticatedUser();
            } else {
                setLoginError(true);
            }
        } catch (error) {
            setLoginError(true);
        } finally {            
            setLoading(false);
        }
    }

    async function navigateAuthenticatedUser() {
        // There is only one place to go for CSR's after login.
        setLoading(true);

        history.replace({
            pathname: '/newcompanypage',
        });
    }

    return (
        <>
            <h1>Sign In</h1>
            <hr />
            {loading && <Loader />}
            <form
                onSubmit={(event) => {
                    signIn();
                    event.preventDefault();
                }}
            >
                <TextInput
                    required
                    type="email"
                    label="User Name:"
                    placeholder="Email"
                    value={credentials.username}
                    onInputChange={updateUsername}
                />
                <PasswordInput
                    label="Password:"
                    placeholder="Password"
                    value={credentials.password}
                    onPasswordChange={updatePassword}
                    required
                />
                <input
                    type="submit"
                    className={generalStyles.submitButton}
                    disabled={signinButtonDisabled()}
                    value="Sign In"
                />
            </form>
            {loginError && <label className={generalStyles.errorMessage}>Error logging in.</label>}
        </>
    );
}

const mapState = (state, props) => ({
    user: state.account.user,
    props,
});
export default connect(mapState)(SignIn);
