import React from 'react';
import PropTypes from 'prop-types';
import general from '../General/Styles.module.scss';

ProcessingStatus.propTypes = {
    messages: PropTypes.array,
};

function ProcessingStatus(props) {
    return (
        <ul>
            {props.messages?.map((msg) => {
                switch (msg.type) {
                    case 'success':
                        return (
                            <li className={general.successMessage} key={msg.message}>
                                {msg.message}
                            </li>
                        );
                    case 'error':
                        return (
                            <li className={general.errorMessage} key={msg.message}>
                                {msg.message}
                            </li>
                        );
                    default:
                        return <li key={msg}>{msg}</li>;
                }
            })}
        </ul>
    );
}

export default ProcessingStatus;
