import axios from 'axios';
import firebase from 'firebase/app';
import 'firebase/auth';
import API_STATES from '@/constants/StateConstants';

const http = axios.create({
    baseURL: import.meta.env.REACT_APP_BASEURL,
});

/*
Watches auth token state changes to keep the request header in sync
*/

http.interceptors.request.use(async (config) => {
    if (firebase.auth().currentUser) {
        config.headers.Authorization = `Bearer ${await firebase.auth().currentUser.getIdToken()}`;
    }
    return config;
});

export async function putCsrAudit(action, when, metadata) {
    try {
        await http.post(`/csraudit`, { action, when, metadata });
        return { status: API_STATES.success };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}