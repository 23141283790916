import InlineSVG from 'react-inlinesvg/esm';
import startIcon from '@images/icons/startIcon.svg';
import stopIcon from '@images/icons/stopIcon.svg';
import { useEffect, useState } from 'react';
import generalStyles from '@/components/General/Styles.module.scss';

function ByzzerAutoRefreshButton({ timeInterval = 3000, onRefresh, onStart = () => {}, onStop = () => {} }) {
    const [isInAutoRefreshMode, setIsInAutoRefreshMode] = useState(false);
    const [shouldRefresh, setShouldRefresh] = useState(false);
    const [isRefreshing, setIsRefreshing] = useState(false);

    const fetchAlertStatus = async () => {
        setIsRefreshing(true);
        if (onRefresh) await onRefresh();
        setIsRefreshing(false);
    };

    function toggleAutoRefresh(isInAutoRefreshMode) {
        if (!isInAutoRefreshMode) {
            onStart();
            setIsInAutoRefreshMode(true);
            setShouldRefresh(true);
        } else {
            setIsInAutoRefreshMode(false);
            onStop();
        }
    }

    useEffect(() => {
        if (shouldRefresh) {
            if (!isRefreshing) {
                fetchAlertStatus();
            }
            setShouldRefresh(false);
            if (isInAutoRefreshMode) {
                setTimeout(() => setShouldRefresh(true), timeInterval);
            }
        }
        // eslint-disable-next-line
    }, [shouldRefresh, isRefreshing, isInAutoRefreshMode]);
    return (
        <button className={generalStyles.submitButton} onClick={() => toggleAutoRefresh(isInAutoRefreshMode)}>
            {isInAutoRefreshMode ? (
                <span className={generalStyles.iconText}>
                    <InlineSVG src={stopIcon} />
                    Stop Auto Refresh
                </span>
            ) : (
                <span className={generalStyles.iconText}>
                    <InlineSVG src={startIcon} />
                    Start Auto Refresh
                </span>
            )}
        </button>
    );
}

export default ByzzerAutoRefreshButton;
