import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { getAllTeamMembersByCompanyId } from '@/services/api/company.service';
import API_STATES from '@/constants/StateConstants';
import generalStyles from '@/components/General/Styles.module.scss';
import { handleApiError } from '@/components/General/HandleApiError';
import { useHistory } from 'react-router-dom';

function CompanyUsersList(props) {
    const history = useHistory();

    function handleUserClick(user) {
        history.push({ pathname: `/newcompanypage/${props.nsCompanyId}/users/${user.nsUserId}` });
    }
    return (
        <ol className={generalStyles.list}>
            {props.dtos.map((dto) => {
                return (
                    <li key={dto.byzUserId}>                             
                        <span className={generalStyles.link} onClick={()=>handleUserClick(dto)}>
                            {dto.email} {dto.isConsultant && '(consultant)'}
                        </span>
                        &nbsp;-&nbsp;
                        {dto.role ? (
                            <span className={`${generalStyles.cap} ${generalStyles.successMessage}`}>
                                {dto.role}
                            </span>
                        ) : (
                            <span className={generalStyles.errorMessage}>No Role</span>
                        )}
                        &nbsp;-&nbsp;
                        <span className={generalStyles.statusMessage}>{dto.isPendingInvitation ? 'Pending Invitation' : dto.isApproved ? 'Active' : 'Pending Approval'}</span>
                    </li>
                );
            })}
        </ol>
    );
}

function CompanyUsers(props, ref) {
    const nsCompanyId = props.nsCompanyId;
    const [userList, setUserList] = useState([]);
    const [userListApiStatus, setUserListApiStatus] = useState(API_STATES.none);
    const [userListApiError, setUserListApiError] = useState('');

    async function getUserList() {
        setUserListApiStatus(API_STATES.loading);
        // {byzUserId, email, role, isPendingInvitation, isApproved, isConsultant}
        const result = await getAllTeamMembersByCompanyId(nsCompanyId);
        switch (result.status) {
            case API_STATES.success:
                if (result.data.length === 0) {
                    setUserListApiStatus(API_STATES.error);
                    setUserListApiError('No user found in this company');
                } else {
                    setUserListApiStatus(API_STATES.success);
                    setUserList(result.data);
                    console.log('user list..', result.data);
                }
                break;
            case API_STATES.error:
                const error = result.error;
                handleApiError(error, setUserListApiStatus, setUserListApiError, 'Company User(s) not found');
                break;
            default:
                setUserListApiError('');
                setUserListApiStatus(API_STATES.none);
        }
    }

    useImperativeHandle(ref, () => ({ getUserList }));

    useEffect(() => {
        (async () => {
            if (nsCompanyId !== null && nsCompanyId !== 0) {
                getUserList();
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nsCompanyId]);

    useEffect(() => {
        console.log('value of user added', props.userAdded);
        if (props.userAdded) {
            setUserListApiStatus(API_STATES.loading);
            console.log('reload user added...', props.userAdded);
            getUserList();
            console.log('complete reload user added', props.userAdded);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.userAdded]);

    return (
        <>
            {userListApiStatus === API_STATES.error ? (
                <p className={generalStyles.errorMessage}>{userListApiError}</p>
            ) : userListApiStatus === API_STATES.none ? (
                ''
            ) : userListApiStatus === API_STATES.loading ? (
                <p className={generalStyles.statusMessage}>Loading company users...</p>
            ) : userListApiStatus === API_STATES.success ? (
                <CompanyUsersList nsCompanyId={nsCompanyId} dtos={userList} />
            ) : null}
        </>
    );
}

export default forwardRef(CompanyUsers);
