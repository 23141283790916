import React, { useEffect, useState } from 'react';
import API_STATES from '@/constants/StateConstants';
import { getAllMarkets } from '@/services/api/reference.service';
import {
    getCompanyAccessibleMarketsCount,
    getCompanyAccessibleMarkets,
} from '@/services/api/company.service';
import styles from './AccessibleMarkets.module.scss';
import generalStyles from '@/components/General/Styles.module.scss';
import { handleApiError } from '@/components/General/HandleApiError';
import { EditAccessibleMarkets } from './EditAccessibleMarkets';

function AccessibleMarkets({ nsCompanyId, isEditable = true, refreshTimestamp }) {
    // {id: number, marketName: string}[]
    const [accessibleMarkets, setAccessibleMarkets] = useState([]);
    const [accessibleMarketsApiState, setAccessibleMarketsApiState] = useState(API_STATES.none);
    const [accessibleMarketsError, setAccessibleMarketsError] = useState('');

    const [allMarkets, setAllMarkets] = useState([]); // string[]
    const [allMarketsApiState, setAllMarketsApiState] = useState(API_STATES.none);
    const [allMarketsApiError, setAllMarketsApiError] = useState();
    const [isInEditMode, setIsInEditMode] = useState(false);

    const [marketCount, setMarketCount] = useState(0);
    const [marketCountApiState, setMarketCountApiState] = useState(API_STATES.none);
    const [marketCountError, setMarketCountError] = useState('');

    const isEditDisabled =
        allMarketsApiState !== API_STATES.success ||
        accessibleMarketsApiState !== API_STATES.success ||
        marketCountApiState !== API_STATES.success;

    useEffect(() => {
        apiLoadMarketCount(nsCompanyId);
        apiLoadAccessibleMarkets(nsCompanyId);
    }, [nsCompanyId, refreshTimestamp]);

    useEffect(() => {
        if (isEditable) {
            apiLoadAllMarkets();
        } else {
            setAllMarkets([]);
        }
    }, [isEditable]);

    async function apiLoadAllMarkets() {
        setAllMarketsApiState(API_STATES.loading);
        const response = await getAllMarkets();
        switch (response?.status) {
            case API_STATES.success:
                const markets = response.data?.map(({ marketName }) => marketName);
                setAllMarkets(markets);
                setAllMarketsApiState(API_STATES.success);
                break;

            default:
                // error case and every other non-success case is the same.
                handleApiError(response.error, setAllMarketsApiState, setAllMarketsApiError);
                setAllMarkets([]);
                break;
        }
    }

    async function apiLoadAccessibleMarkets(nsCompanyId) {
        setAccessibleMarketsApiState((accessibleMarketsApiState) =>
            accessibleMarketsApiState === API_STATES.success ? API_STATES.refreshing : API_STATES.loading
        );
        const markets = await getCompanyAccessibleMarkets(nsCompanyId);
        switch (markets?.status) {
            case API_STATES.success:
                setAccessibleMarkets(markets.data);
                setAccessibleMarketsApiState(API_STATES.success);
                break;

            default:
                handleApiError(markets.error, setAccessibleMarketsApiState, setAccessibleMarketsError);
                setAccessibleMarkets([]);
                break;
        }
    }

    async function apiLoadMarketCount(nsCompanyId) {
        setMarketCountApiState(API_STATES.loading);
        const response = await getCompanyAccessibleMarketsCount(nsCompanyId);
        switch (response?.status) {
            case API_STATES.success:
                setMarketCount(response.data?.count ?? 0);
                setMarketCountApiState(API_STATES.success);
                break;

            default:
                setMarketCount(0);
                handleApiError(response.error, setMarketCountApiState, setMarketCountError);
                break;
        }
    }

    const allMarketsStatusDisplay =
        allMarketsApiState === API_STATES.loading ? (
            <div className={generalStyles.statusMessage}>Loading all accessible markets...</div>
        ) : (
            allMarketsApiState === API_STATES.error && (
                <div className={generalStyles.errorMessage}>Error: {allMarketsApiError}</div>
            )
        );

    const marketsCountDisplay =
        marketCountApiState === API_STATES.success ? (
            <p>
                {accessibleMarkets.length} of {marketCount ?? '(unknown)'} Selected
            </p>
        ) : marketCountApiState === API_STATES.loading ? (
            <div className={generalStyles.statusMessage}>Loading allowed markets count</div>
        ) : marketCountApiState === API_STATES.error ? (
            <div className={generalStyles.errorMessage}>{marketCountError}</div>
        ) : (
            ''
        );

    const loadAccessibleMarketsDisplay =
        accessibleMarketsApiState === API_STATES.loading ? (
            <div className={generalStyles.statusMessage}>Loading accessible markets...</div>
        ) : accessibleMarketsApiState === API_STATES.error ? (
            <div className={generalStyles.errorMessage}>Error: {accessibleMarketsError}</div>
        ) : accessibleMarkets?.length ? (
            <ul data-test={'accessible-markets'} className={generalStyles.tileList}>
                {accessibleMarkets.map((market) => (
                    <li className={styles.accessibleMarketItem} key={market.id}>
                        {market.marketName}
                    </li>
                ))}
            </ul>
        ) : (
            <div className={generalStyles.statusMessage}>No markets selected</div>
        );

    async function onMarketsEdit() {
        await apiLoadAccessibleMarkets(nsCompanyId);
        closeEditForm();
    }
    function closeEditForm() {
        setIsInEditMode(false);
    }
    return (
        <div>
            {isInEditMode ? (
                <EditAccessibleMarkets 
                    nsCompanyId={nsCompanyId} 
                    selectedMarkets={accessibleMarkets} 
                    allowedMarketCount={marketCount}
                    allMarkets={allMarkets}
                    onSuccess={onMarketsEdit}
                    onCancel={closeEditForm}
                />
            ) : (
                <>
                    {marketsCountDisplay}
                    <span data-test="selected-markets">{loadAccessibleMarketsDisplay}</span>
                    <span className={styles.columnar}>
                        {isEditable && !isInEditMode && (
                            <button
                                className={generalStyles.submitButton}
                                disabled={isEditDisabled}
                                onClick={() => setIsInEditMode(true)}
                            >
                                Edit
                            </button>
                        )}
                        {allMarketsStatusDisplay}
                    </span>
                </>
            )}
        </div>
    );
}

export default AccessibleMarkets;
