import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styles from './ReportRunsReport.module.scss';
import calendarIcon from '@images/icons/calendar.svg';
import format from 'date-fns/format';
import { saveAs } from 'file-saver';
import { getReportRuns } from '@/services/api/report.service';
import generalStyles from '@/components/General/Styles.module.scss';
import ByzzerSwitch from '../General/ByzzerSwitch';

function ReportRunsReport(props) {
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [startDateParam, setStartDateParam] = useState();
    const [endDateParam, setEndDateParam] = useState();
    const [error, setError] = useState(false);
    const [errorStatus, setErrorStatus] = useState('');
    const [downloading, setDownloading] = useState(false);
    const [initializing, setInitializing] = useState(true);
    const [includeTestAccounts, setIncludeTestAccounts] = useState(false);
    
    function calculateStartDate(weeks) {
        let newDate = new Date(endDate);
        newDate.setDate(endDate.getDate() - parseInt(7 * weeks));
        setStartDate(newDate);
    }

    async function downloadFile() {

        if (endDate >= startDate) {
            setInitializing(false);
            setError(false);
            setDownloading(true);

            const now = format(new Date(), 'yyyy-MM-dd.HH-mm-ss');
            const filename = `ReportRunsHistory-${now}.csv`;
            const heading = 'Date/Time, User email, Name of Report, Type of Report, Company Name\n';
            const result = await getReportRuns(startDateParam, endDateParam, includeTestAccounts);
            const data = heading.concat(result.data);

            const blob = new Blob([data], { type: 'text/csv; header=present; charset=utf-8' });
            saveAs(blob, filename);
            setDownloading(false);
        } else {
            setInitializing(false);
            setError(true);
            setErrorStatus('Error: Invalid Date Input - No Data Available');
        }
    }

    const handleIncludeTestAccountsChange = ({ target }) => {
        setIncludeTestAccounts((prev) => {
            if (target.type === 'checkbox') {
                return target.checked;
            }
        });
    };

    useEffect(() => {
        try {
            setStartDateParam(format(startDate, 'yyyy-MM-dd'));
        } catch (e) {
            const defaultStartDate = new Date(1970, 0, 1);
            setStartDate(defaultStartDate);
        }
    }, [startDate]);

    useEffect(() => {
        try {
            setEndDateParam(format(endDate, 'yyyy-MM-dd'));
        } catch (e) {
            const defaultEndDate = new Date(9999, 11, 31);
            setEndDate(defaultEndDate);
        }
    }, [endDate]);

    return (
        <>
            <h1>Report Runs History</h1>
            <hr />
            {initializing ? (
                <>
                    <div className={styles.flexOuter}>
                        <div className={styles.flexInner}>
                            <span>From: </span>
                            <DatePicker
                                name="startDate"
                                placeholderText="mm/dd/yyyy"
                                shouldCloseOnSelect={true}
                                fixedHeight
                                closeOnScroll={true}
                                maxDate={new Date()}
                                dateFormat="MM/dd/yyyy"
                                selected={startDate}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                tabIndex={1}
                                onChange={(date) => {
                                    setStartDate(date);
                                }}
                            />
                            <img src={calendarIcon} alt="icon" />
                        </div>

                        <div className={styles.flexInner}>
                            <span>To:</span>
                            <DatePicker
                                name="endDate"
                                placeholderText="mm/dd/yyyy"
                                shouldCloseOnSelect={true}
                                fixedHeight
                                closeOnScroll={true}
                                maxDate={new Date()}
                                dateFormat="MM/dd/yyyy"
                                selected={endDate}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                tabIndex={1}
                                onChange={(date) => {
                                    setEndDate(date);
                                }}
                            />
                            <img src={calendarIcon} alt="icon" />
                        </div>
                    </div>

                    <p>
                        <button
                            className={styles.timePeriodButton}
                            onClick={(e) => calculateStartDate(1)}
                            name={'last1week'}
                        >
                            Last 1 Week
                        </button>
                        <button
                            className={styles.timePeriodButton}
                            onClick={(e) => calculateStartDate(4)}
                            name={'last4weeks'}
                        >
                            Last 4 Weeks
                        </button>
                        <button
                            className={styles.timePeriodButton}
                            onClick={(e) => calculateStartDate(13)}
                            name={'last13weeks'}
                        >
                            Last 13 Weeks
                        </button>
                        <button
                            className={styles.timePeriodButton}
                            onClick={(e) => calculateStartDate(52)}
                            name={'last52weeks'}
                        >
                            Last 52 Weeks
                        </button>
                    </p>
                    <div className={styles.includeTestAccounts}>
                        Include Test Accounts
                        <ByzzerSwitch
                            name="includeTestAccounts"
                            onChange={handleIncludeTestAccountsChange}
                        ></ByzzerSwitch>
                    </div>
                    <div className={styles.downloadButton}>
                        <button className={generalStyles.submitButton} name={'downloadcsv'} onClick={downloadFile}>
                            Download CSV
                        </button>
                    </div>
                </>
            ) : error ? (
                <p className={generalStyles.errorMessage}>{errorStatus}</p>
            ) : downloading ? (
                <p className={generalStyles.statusMessage}>Downloading your report...</p>
            ) : (
                <p className={generalStyles.statusMessage}>Your report is being downloaded to your browser</p>
            )}
        </>
    );
}
export default ReportRunsReport;
