import React, { useState } from 'react';
import generalStyles from '@/components/General/Styles.module.scss';
import styles from '@/components/BundleManager/BundleManager.module.scss';
import API_STATES from '@/constants/StateConstants';
import * as API from '@/services/api/product.service';
import { handleApiError } from '@/components/General/HandleApiError';

function DeleteFolder(props) {
    const [deleteFolderApiStatus, setDeleteFolderApiStatus] = useState(API_STATES.none);
    const [deleteFolderApiError, setDeleteFolderApiError] = useState('');
    const [deleteFolderSelected, setDeleteFolderSelected] = useState(false);
    const folderId = props.folder.id;
    const folderName = props.folder.name;
    async function deleteFolderItem() {
        setDeleteFolderApiStatus(API_STATES.loading);
        const result = await API.deleteMarketMapsFolder(folderId);
        switch (result.status) {
            case API_STATES.success:
                setDeleteFolderApiStatus(API_STATES.success);
                setDeleteFolderSelected(false);
                setTimeout(() => setDeleteFolderApiStatus(API_STATES.none), 1000);
                setTimeout(() => props.onFolderDelete(), 1500);
                break;
            case API_STATES.error:
                let error = result.error;
                handleApiError(error, setDeleteFolderApiStatus, setDeleteFolderApiError);
                break;
            default:
                setDeleteFolderApiError('');
                setDeleteFolderApiStatus(API_STATES.none);
        }
    }

    return (
        <>
            <div className={styles.column}>
                <div className={styles.row}>
                    <div>
                        <button
                            data-test="delete-folder-btn"
                            className={generalStyles.submitButton}
                            name={'deletefolder'}
                            onClick={() => setDeleteFolderSelected(true)}
                        >
                            Delete this Folder
                        </button>
                    </div>
                    <div>
                        <span data-test="delete-folder-status">
                            {deleteFolderApiStatus === API_STATES.error ? (
                                <p className={generalStyles.errorMessage}>Error: {deleteFolderApiError}</p>
                            ) : deleteFolderApiStatus === API_STATES.none ? (
                                <p className={generalStyles.statusMessage}></p>
                            ) : deleteFolderApiStatus === API_STATES.loading ? (
                                <p className={generalStyles.statusMessage}>Deleting...</p>
                            ) : deleteFolderApiStatus === API_STATES.success ? (
                                <p className={generalStyles.successMessage}>Successfully deleted Folder</p>
                            ) : null}
                        </span>
                    </div>
                </div>
                <div>
                    {deleteFolderSelected && (
                        <div className={styles.warningDialogBox} data-test="delete-folder-warning">
                            <b className={generalStyles.errorMessage}>
                                This action is not reversible, and will permanently delete the "{folderName}" folder.
                                <br />
                                Are you sure to proceed?
                            </b>
                            <br />
                            <button
                                data-test="confirm-deletion-btn"
                                className={generalStyles.confirmOperationButton}
                                onClick={deleteFolderItem}
                                disabled={deleteFolderApiStatus === API_STATES.loading}
                            >
                                Yes, Delete this Folder
                            </button>
                            <br />
                            <br />

                            <button
                                data-test="cancel-deletion-btn"
                                className={generalStyles.cancelOperationButton}
                                onClick={() => setDeleteFolderSelected(false)}
                                disabled={deleteFolderApiStatus === API_STATES.loading}
                            >
                                No, Do not Delete this Folder
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default DeleteFolder;
