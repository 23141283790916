import 'react-datepicker/dist/react-datepicker.css';
import generalStyles from '@/components/General/Styles.module.scss';
import styles from '@/components/Maintenance/Maintenance.module.scss';
import { getMaintenanceModeStatus, setMaintenanceETA, setMaintenanceStatus } from '@/services/api/maintenance.service';
import { useCallback, useEffect, useRef, useState } from 'react';
import API_STATES from '@/constants/StateConstants';
import DatePicker from 'react-datepicker';
import calendarIcon from '@images/icons/calendar.svg';
import clockIcon from '@images/icons/clock.svg';
import TimeKeeper from 'react-timekeeper';
import moment from 'moment';
import classNames from 'classnames';
import { ByzzerSwitch } from '@/components/General/ByzzerSwitch';
import deleteIcon from '@images/icons/deleteIcon.svg';
import InlineSVG from 'react-inlinesvg/esm';

export default function Maintenance(props) {
    const [fetchMaintenanceStatusApiState, setFetchMaintenanceStatusApiState] = useState(API_STATES.none);
    const [fetchMaintenanceStatusApiError, setFetchMaintenanceStatusApiError] = useState('');
    const [maintenanceModeStatus, setMaintenanceModeStatus] = useState(null);

    const [updateMaintenanceStatusApiState, setUpdateMaintenanceStatusApiState] = useState(API_STATES.none);
    const [updateMaintenanceStatusApiError, setUpdateMaintenanceStatusApiError] = useState('');

    const [eta, setEta] = useState(null);
    const [time, setTime] = useState(moment()); // buffer to store new eta

    const [showTimePicker, setShowTimePicker] = useState(false);

    const [updateEtaApiState, setUpdateEtaApiState] = useState(API_STATES.none);
    const [updateEtaApiError, setUpdateEtaApiError] = useState('');

    const [removeEtaApiState, setRemoveEtaApiState] = useState(API_STATES.none);
    const [removeEtaApiError, setRemoveEtaApiError] = useState('');

    function updateEtaDate(time, newDate) {
        const dateTimeString = newDate + time.format(' hh:mm a');
        setTime(moment(dateTimeString, 'ddd MMM DD YYYY hh:mm a'));
    }
    function updateEtaTime(time, newTime) {
        const dateTimeString = time.format('DD/MM/YYYY ') + newTime.formatted12;
        setTime(moment(dateTimeString, 'DD/MM/YYYY hh:mm a'));
    }

    const timePickerRef = useRef(null); // needed to detect outer click
    const handleClickOutsideTimePicker = useCallback(
        (event) => {
            if (timePickerRef.current && !timePickerRef.current.contains(event.target)) {
                setShowTimePicker(false);
            }
        },
        [timePickerRef]
    );
    const loadMaintenanceStatus = async () => {
        setFetchMaintenanceStatusApiState(API_STATES.loading);
        const response = await getMaintenanceModeStatus();
        if (response.status === API_STATES.success) {
            setFetchMaintenanceStatusApiState(API_STATES.success);
            setMaintenanceModeStatus(response.data.status);

            if (response.data.eta) {
                const eta = moment(response.data.eta);
                setEta(eta);
                if (eta > moment()) {
                    // pre-fill eta only if it is greater than current time given by moment()
                    setTime(moment(eta));
                }
            } else {
                setEta(null);
            }
        } else {
            setFetchMaintenanceStatusApiState(API_STATES.error);
            setFetchMaintenanceStatusApiError(response.error.toString());
        }
    };
    const updateMaintenanceMode = async () => {
        setUpdateMaintenanceStatusApiState(API_STATES.loading);
        const response = await setMaintenanceStatus(!maintenanceModeStatus);
        if (response.status === API_STATES.success) {
            setUpdateMaintenanceStatusApiState(API_STATES.success);
            await loadMaintenanceStatus();
        } else {
            setUpdateMaintenanceStatusApiError(response.error);
            setUpdateMaintenanceStatusApiState(API_STATES.error);
        }
        setTimeout(() => setUpdateMaintenanceStatusApiState(API_STATES.none), 2000);
    };

    const updateEta = async (time) => {
        setUpdateEtaApiState(API_STATES.loading);
        const response = await setMaintenanceETA(time.toDate());
        if (response.status === API_STATES.success) {
            setUpdateEtaApiState(API_STATES.success);
            await loadMaintenanceStatus();
        } else {
            setUpdateEtaApiError(response.error);
            setUpdateEtaApiState(API_STATES.error);
        }
        setTimeout(() => setUpdateEtaApiState(API_STATES.none), 2000);
    };

    const removeEta = async () => {
        setRemoveEtaApiState(API_STATES.loading);
        const response = await setMaintenanceETA(null);
        if (response.status === API_STATES.success) {
            setRemoveEtaApiState(API_STATES.success);
            await loadMaintenanceStatus();
        } else {
            setRemoveEtaApiError(response.error);
            setRemoveEtaApiState(API_STATES.error);
        }
        setTimeout(() => setRemoveEtaApiState(API_STATES.none), 2000);
    };

    useEffect(() => {
        // Bind the event listener for click on outside time picker
        if (showTimePicker) {
            document.addEventListener('mousedown', handleClickOutsideTimePicker);
        } else {
            document.removeEventListener('mousedown', handleClickOutsideTimePicker);
        }
    }, [handleClickOutsideTimePicker, showTimePicker]);
    useEffect(() => {
        loadMaintenanceStatus();
    }, []);

    return (
        <>
            <h1>Maintenance Mode</h1>
            <hr />
            <div className={styles.maintenanceModeStatus}>
                {maintenanceModeStatus !== null ? (
                    <>
                        <label className={styles.maintenanceModeStatusLabel}>Maintenance Mode</label>
                        <ByzzerSwitch
                            data-test="maintenance-switch"
                            onChange={() => updateMaintenanceMode()}
                            value={maintenanceModeStatus}
                        />
                        <label data-test="maintenance-toggle-status">
                            {updateMaintenanceStatusApiState === API_STATES.loading ? (
                                <span className={generalStyles.statusMessage}>Processing...</span>
                            ) : updateMaintenanceStatusApiState === API_STATES.error ? (
                                <span className={generalStyles.errorMessage}>
                                    Failed to Update: {updateMaintenanceStatusApiError}
                                </span>
                            ) : (
                                updateMaintenanceStatusApiState === API_STATES.success && (
                                    <span className={generalStyles.successMessage}>saved!</span>
                                )
                            )}
                        </label>
                    </>
                ) : fetchMaintenanceStatusApiState === API_STATES.loading ? (
                    <label className={generalStyles.statusMessage}>Fetching maintenance status</label>
                ) : (
                    fetchMaintenanceStatusApiState === API_STATES.error && (
                        <label className={generalStyles.errorMessage}>
                            Failed to fetch maintenance status: {fetchMaintenanceStatusApiError}
                        </label>
                    )
                )}
            </div>
            {maintenanceModeStatus ? (
                <div data-test="maintenance-eta" className={styles.maintenanceModeInfo}>
                    The system is planned to be back online&nbsp;
                    {eta ? (
                        <>
                            by
                            <span data-test="maintenance-eta-datetime" className={generalStyles.statusMessage}>
                                {eta.format('LLL')}
                            </span>
                            .
                        </>
                    ) : (
                        'shortly.'
                    )}
                    <br />
                    <span className={classNames(generalStyles.statusMessage, styles.instruction)}>
                        Please uncheck Maintenance mode to make the system live.
                    </span>
                </div>
            ) : (
                <div data-test="maintenance-warning" className={generalStyles.errorMessage}>
                    Checking maintenance mode will TAKE DOWN THE ENTIRE SITE and prevent any customers from using it!
                    <br />
                    Be absolutely sure you want to do this.
                </div>
            )}
            <div className={styles.etaForm}>
                <span>ETA: </span>
                <div className={styles.date}>
                    <DatePicker
                        data-test="maintenance-datePicker"
                        name="endDate"
                        placeholderText="dd/MM/yyyy"
                        shouldCloseOnSelect={true}
                        fixedHeight
                        closeOnScroll={true}
                        minDate={new Date()}
                        dateFormat="MMM d, y"
                        selected={time.toDate()}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        tabIndex={1}
                        onChange={(newDate) => {
                            updateEtaDate(time, newDate);
                        }}
                    />
                    <img src={calendarIcon} alt="calendar icon" />
                </div>
                <div className={styles.time}>
                    <div className={styles.timePicker} ref={timePickerRef}>
                        <div className={styles.timeDisplay} onClick={() => setShowTimePicker(!showTimePicker)}>
                            <input value={time.format('hh:mm a')} />
                            <img src={clockIcon} alt="clock icon" />
                        </div>
                        {showTimePicker && (
                            <div className={styles.timePickerElementWrapper}>
                                <TimeKeeper
                                    data-test="maintenance-timePicker"
                                    time={time.format('hh:mm a')}
                                    onChange={(newTime) => updateEtaTime(time, newTime)}
                                    onDoneClick={() => setShowTimePicker(false)}
                                    switchToMinuteOnHourSelect
                                    closeOnMinuteSelect
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className={styles.updateEtaSubmission}>
                <button
                    className={generalStyles.submitButton}
                    name={'updateETA'}
                    onClick={() => updateEta(time)}
                    disabled={!time || eta?.isSame(time) || time < moment() || updateEtaApiState !== API_STATES.none}
                >
                    {eta? 'Update ETA' : 'Set ETA'}
                </button>
                <label data-test="maintenance-update-eta-status">
                    {updateEtaApiState === API_STATES.loading ? (
                        <span className={generalStyles.statusMessage}>Processing...</span>
                    ) : updateEtaApiState === API_STATES.error ? (
                        <span className={generalStyles.errorMessage}>Failed to Update: {updateEtaApiError}</span>
                    ) : (
                        updateEtaApiState === API_STATES.success && (
                            <span className={generalStyles.successMessage}>saved!</span>
                        )
                    )}
                </label>
                {eta && (
                    <>
                        <button
                            className={classNames(generalStyles.submitButton, styles.removeButton)}
                            name={'removeETA'}
                            onClick={() => removeEta(time)}
                        >  
                            <InlineSVG src={deleteIcon} />Remove ETA
                        </button>
                        <label data-test="maintenance-remove-eta-status">
                            {removeEtaApiState === API_STATES.loading ? (
                                <span className={generalStyles.statusMessage}>Processing...</span>
                            ) : removeEtaApiState === API_STATES.error ? (
                                <span className={generalStyles.errorMessage}>
                                    Failed to Remove: {removeEtaApiError}
                                </span>
                            ) : (
                                removeEtaApiState === API_STATES.success && (
                                    <span className={generalStyles.successMessage}>removed!</span>
                                )
                            )}
                        </label>
                    </>
                )}
            </div>
        </>
    );
}
