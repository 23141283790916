import React, { useEffect, useState } from 'react';
import { getUserStatus } from '@/services/api/report.service';
import styles from './UserStatusReport.module.scss';
import API_STATES from '@/constants/StateConstants';
import generalStyles from '@/components/General/Styles.module.scss';
import { handleApiError } from '@/components/General/HandleApiError';
import ByzzerSwitch from '../General/ByzzerSwitch';
import DataTable from '@/components/General/DataTable';
import { COLUMNS } from '@/components/UserStatusReport/UserStatusReportColumns';
import EmptyTable from '@/components/General/EmptyTable';

function UserStatusReport(props) {
    const [reportApiStatus, setReportApiStatus] = useState(API_STATES.none);
    const [reportApiError, setReportApiError] = useState('');

    const [reportResult, setReportResult] = useState([]);
    const [includeTestAccounts, setIncludeTestAccounts] = useState(false);
    const [showActiveSubscriptionsOnly, setShowActiveSubscriptionsOnly] = useState(true)

    async function generateReport(includeTestAccountsVal, showActiveSubscriptionsOnly) {
        setReportApiStatus(API_STATES.loading);
        const result = await getUserStatus(includeTestAccountsVal, showActiveSubscriptionsOnly);
        switch (result.status) {
            case API_STATES.success:
                setReportApiStatus(API_STATES.success);
                setReportResult(result.data);
                break;
            case API_STATES.error:
                const error = result.error;
                handleApiError(error, setReportApiStatus, setReportApiError, 'User status by company report not found');
                break;
            default:
                setReportApiStatus(API_STATES.none);
                setReportApiError('');
        }
    }

    const handleIncludeTestAccountsChange = ({ target }) => {
        setIncludeTestAccounts(target.checked);
        generateReport(target.checked, showActiveSubscriptionsOnly);
    };
    const handleActiveSubscriptionsChange = ({target}) => {
        setShowActiveSubscriptionsOnly(target.checked)
        generateReport(includeTestAccounts, target.checked);
    }

    useEffect(() => {
        (async () => {
            generateReport(includeTestAccounts, showActiveSubscriptionsOnly);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <h1>User Status By Company</h1>
            <hr />
            <div className={styles.includeTestAccounts}>
                Include Test Accounts
                <ByzzerSwitch
                    name="includeTestAccounts"
                    onChange={handleIncludeTestAccountsChange}
                ></ByzzerSwitch>
            </div>
            <div className={styles.includeTestAccounts}>
                Show Active Subscriptions Only
                <ByzzerSwitch
                    name="showActiveSubscriptionsOnly"
                    value={showActiveSubscriptionsOnly}
                    onChange={handleActiveSubscriptionsChange}
                ></ByzzerSwitch>
            </div>
            <div data-test="generate-report-status">
                {reportApiStatus === API_STATES.error ? (
                    <p className={generalStyles.errorMessage}>Error: {reportApiError}</p>
                ) : reportApiStatus === API_STATES.none ? (
                    <p className={generalStyles.statusMessage}>User Status by company report</p>
                ) : reportApiStatus === API_STATES.loading ? (
                    <p className={generalStyles.statusMessage}>Loading...</p>
                ) : reportApiStatus === API_STATES.success ? (
                    <>
                    <DataTable data={reportResult} withDownload columns={COLUMNS} downloadFileName={"UserStatusReport"}/>
                    </>
                ) : <EmptyTable
                    noValueDescription="No users found"
                    columns={[
                        'Company Name',
                        'Pending Users',
                        'Active Users',
                        'Total Users'
                    ]} />}
            </div>
        </>
    );
}

export default UserStatusReport;
