import React, { useState, useEffect } from 'react';
import DataTable from '../General/DataTable';
import Popup from 'reactjs-popup';
import generalStyles from '@/components/General/Styles.module.scss';
import TextInput from '@/components/General/TextInput';
import styles from './CategoryManagement.module.scss'
import {COMMON_CATEGORY_EVENT_COLUMNS} from './CommonCategoryEventColumns';
import Select from 'react-select';
import { submitChangedCategories, getCategoriesWithKeys, getConsolidatedCategories } from '@/services/api/product.service';
import API_STATES from '@/constants/StateConstants';
import calendarIcon from '@images/icons/calendar.svg';
import downloadIcon from '@images/icons/download.svg';
import deleteIcon from '@images/icons/deleteIcon.svg';
import editIcon from '@images/icons/EditIcon.svg';
import moment from 'moment';
import DatePicker from 'react-datepicker';

function CategoryChange(props) {
    const [openModal, setOpenModal] = useState(false)
    const [consolidateEventName, setConsolidateEventName] = useState('')
    const [selectedCategories, setSelectedCategories] = useState([])
    const [categoriesApiState, setCategoriesApiState] = useState(API_STATES.none);
    const [categoriesApiError, setCategoriesApiError] = useState(null);
    const [currentCategories, setCurrentCategories] = useState([]);
    const [newCategory, setNewCategory] = useState([])
    const [dataRefreshDate, setDataRefreshDate] = useState('')
    const [consolidatedCategoriesList, setConsolidatedCategoriesList] = useState([])

    const onSelectCategories = (selectedCategory) => {
        if (selectedCategory.some((company) => company.value === 'All')) {
            setSelectedCategories(currentCategories);
        } else {
            setSelectedCategories(selectedCategory);
        }
    }

    useEffect(() => {
        loadCategories()
    }, [])

    const transformCategories = (list) => {
        return (
            list?.map((category) => {
                return { label: category.category, value: Number(category.categoryKey) };
            }) ?? []
        );
    };

    async function loadCategories() {
        try {
            const response = await getCategoriesWithKeys();
            if (response.status === API_STATES.success) {
                const categories = response.data;
                const categoryOption = transformCategories(categories);
                setCurrentCategories(categoryOption)
            }
        } catch (e) {
            console.log(e)
            setCurrentCategories([]);
        }
    }

    const onDateChange = (endDateValue) => {
        if (endDateValue && endDateValue !== '') {
            setDataRefreshDate(endDateValue);
        } else {
            setDataRefreshDate(null);
        }
    };

    function dateFilter(date) {
        const isSaturday = date.getDay() === 2;
        const isBeforeMaxDate = date.getTime() >= Number(new Date().getTime());
        return isSaturday && isBeforeMaxDate;
    }

    async function onSubmit() {
        const scheduledDateStringFormat = new Date(dataRefreshDate.getTime() - dataRefreshDate.getTimezoneOffset() * 60000).toISOString()
        const oldCategories = selectedCategories?.map((item) =>({category_name: item?.label, category_key: item?.value}))
        const newCategoryName = newCategory?.map((item) =>({category_name: item?.label}))
        try {
            setCategoriesApiState(API_STATES.loading)
            const result = await submitChangedCategories(consolidateEventName, 'consolidate', oldCategories, newCategoryName, scheduledDateStringFormat)
            getConsolidatedCategoryList()
            setOpenModal(false)
        } catch (e) {
            console.log(e)
            setCategoriesApiError(API_STATES.error)
        } finally {
            setSelectedCategories([])
            setConsolidateEventName('')
            setNewCategory([])
            setDataRefreshDate()
            setCategoriesApiState(API_STATES.success)
        }
    }

    useEffect(() => {
        getConsolidatedCategoryList()
    }, [currentCategories])

    async function getConsolidatedCategoryList() {
        try {
            const result = await getConsolidatedCategories();
            const getCategoriesList = result.data.map((item) => {
                return {
                    eventType: "consolidate",
                    eventName: item.display_name,
                    oldCategories: item?.old_categories?.length ? item?.old_categories?.map((category)=>({label:category?.category_name, value: category?.category_key })) : '',
                    newCategory: item.new_categories?.length ? item?.new_categories?.map((newCategory)=>(newCategory?.category_name)) : '',
                    date: new Date(new Date(item.scheduled_dtm).getTime() + (new Date().getTimezoneOffset() * 60000)).toLocaleDateString(),
                    status: item.status
                }
            })
            setConsolidatedCategoriesList(getCategoriesList);
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            <h1>Consolidate Categories</h1>
            <hr />
            <div>
                <button className={`${generalStyles.submitButton}`} onClick={() => setOpenModal(true)}>Add Consolidate Event</button>
                <DataTable noDataDescription = {'No consolidated catgeories found'} data={consolidatedCategoriesList?? []} columns={COMMON_CATEGORY_EVENT_COLUMNS} />
            </div>
            {openModal && (
                <Popup
                    open={openModal}
                    onClose={() => {
                        setOpenModal(false);
                    }}
                    modal
                >
                    {() => (
                        <div className={styles.formContainer}>
                                <div className={generalStyles.formItem}>
                                    <TextInput
                                        label={'Event Name:'}
                                        onInputChange={(e) => {
                                            setConsolidateEventName(e.target.value);
                                        }}
                                        value={consolidateEventName}
                                        placeholder={'Event Name'}
                                    />
                                </div>

                                <div className={generalStyles['input-wrapper']}>
                                    <label className={generalStyles.label}>Current Categories:</label>
                                    <span className={generalStyles.formField}>
                                        <Select
                                            className={generalStyles.formField}
                                            options={currentCategories}
                                            closeMenuOnSelect={false}
                                            isMulti
                                            onChange={(selectedCategory) => onSelectCategories(selectedCategory)}
                                            value={selectedCategories}
                                            placeholder="Categories"
                                        />
                                    </span>
                                </div>

                                <div className={`${generalStyles.formItem}`}>
                                    <TextInput
                                        label={'New Category'}
                                        onInputChange={(e) => {
                                            setNewCategory([{ label: (e.target.value).toUpperCase(), value: (e.target.value).toLowerCase() }]);
                                        }}
                                        value={newCategory?.map((item) => item?.label)}
                                        placeholder={'New Category'}
                                    />
                                </div>

                                <div className={generalStyles['input-wrapper']}>
                                    <label className={generalStyles.label}>Select Date:</label>
                                    <span className={`${styles.datepickerContainer} ${generalStyles.formItem}`}>
                                        <DatePicker
                                            name="endDate"
                                            placeholderText="End Date"
                                            shouldCloseOnSelect={true}
                                            className={styles.datepickerInput}
                                            fixedHeight
                                            closeOnScroll={true}
                                            dateFormat="MMM d, y"
                                            selected={dataRefreshDate ? dataRefreshDate : null}
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            tabIndex={1}
                                            onChange={(date) => {
                                                onDateChange(date);
                                            }}
                                            minDate={moment().toDate()}
                                            filterDate={dateFilter}
                                            onKeyDown={(e) => {
                                                e.preventDefault();
                                            }}
                                        />
                                        <img src={calendarIcon} alt="calendar icon" className={styles.calenderIcon} />
                                    </span>
                                <br />
                            </div>
                            <button disabled={!(newCategory && currentCategories && consolidateEventName && dataRefreshDate)} className={`${generalStyles.submitButton}`} onClick={onSubmit}>Submit</button>
                            {
                                categoriesApiError && <p className={generalStyles.errorMessage}>Failed to submit consolidated category</p>
                            }
                            {
                                categoriesApiState === 'loading' && <p className={generalStyles.statusMessage}>Submitting consolidated category....</p>
                            }
                        </div>
                    )}
                </Popup>
            )}
        </>
    );
}
export default CategoryChange;
