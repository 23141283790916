import React, { useState } from 'react';
import generalStyles from '@/components/General/Styles.module.scss';
import styles from '@/components/BundleManager/BundleManager.module.scss';
import API_STATES from '@/constants/StateConstants';
import * as API from '@/services/api/product.service';
import TextInput from '@/components/General/TextInput';
import BundleInfoTable from '@/components/BundleManager/BundleInfoTable';
import DeleteBundle from '@/components/BundleManager/DeleteBundle';
import PropTypes from 'prop-types';
import { handleApiError } from '@/components/General/HandleApiError';

function BundleInfoContainer(props) {
    const [lastSavedTitle, setLastSavedTitle] = useState(props.bundleInfo.title);
    const [newTitle, setNewTitle] = useState(lastSavedTitle);

    const [updateBundleTitleApiStatus, setUpdateBundleTitleApiStatus] = useState(API_STATES.none);
    const [updateBundleTitleApiError, setUpdateBundleTitleApiError] = useState('');

    async function modifyBundleTitle() {
        setUpdateBundleTitleApiStatus(API_STATES.loading);
        const result = await API.updateBundleTitle(props.bundleSku, newTitle);
        switch (result.status) {
            case API_STATES.success:
                setUpdateBundleTitleApiStatus(API_STATES.success);
                setLastSavedTitle(newTitle);
                setTimeout(() => setUpdateBundleTitleApiStatus(API_STATES.none), 1000);
                setTimeout(() => props.onBundleNameUpdate(), 2000);
                break;
            case API_STATES.error:
                let error = result.error;
                handleApiError(error, setUpdateBundleTitleApiStatus, setUpdateBundleTitleApiError);
                break;
            default:
                setUpdateBundleTitleApiError('');
                setUpdateBundleTitleApiStatus(API_STATES.none);
        }
    }

    const onTitleChange = (value) => {
        setUpdateBundleTitleApiStatus(API_STATES.none);
        setUpdateBundleTitleApiError('');
        setNewTitle(value);
    };

    const isSubmitEnabled = () => {
        return newTitle !== '' && updateBundleTitleApiStatus !== API_STATES.loading && newTitle !== lastSavedTitle;
    };

    return (
        <div data-test="bundle-info-container">
            <DeleteBundle bundleSku={props.bundleSku} onBundleDelete={props.onBundleDelete} />

            <div className={styles.bundleInfoFlex}>
                <div data-test="bundle-title-input">
                    <TextInput
                        type="text"
                        label="Title:"
                        placeholder="Title"
                        value={newTitle}
                        onInputChange={(event) => onTitleChange(event.target.value)}
                        disabled={updateBundleTitleApiStatus === API_STATES.loading}
                    />
                </div>
                <div>
                    <button
                        data-test="update-title-btn"
                        className={generalStyles.submitButton}
                        disabled={!isSubmitEnabled()}
                        onClick={modifyBundleTitle}
                    >
                        Update Title
                    </button>

                    <label data-test="update-title-status">
                        {updateBundleTitleApiStatus === API_STATES.loading ? (
                            <span className={generalStyles.statusMessage}>Processing...</span>
                        ) : updateBundleTitleApiStatus === API_STATES.error ? (
                            <span className={generalStyles.errorMessage}>Error: {updateBundleTitleApiError}</span>
                        ) : (
                            updateBundleTitleApiStatus === API_STATES.success && (
                                <span className={generalStyles.successMessage}>Bundle Title Updated!</span>
                            )
                        )}
                    </label>
                </div>
            </div>

            <BundleInfoTable
                bundleSku={props.bundleSku}
                childProducts={props.bundleInfo.productSkus}
                onBundleUpdate={props.onBundleUpdate}
            />
        </div>
    );
}

BundleInfoContainer.propTypes = {
    bundleSku: PropTypes.string.isRequired,
    bundleInfo: PropTypes.shape({
        productSkus: PropTypes.arrayOf(
            PropTypes.shape({
                childSku: PropTypes.string.isRequired,
                type: PropTypes.string.isRequired,
                name: PropTypes.string.isRequired
            })
        ).isRequired,
        title: PropTypes.string.isRequired
    }).isRequired,
    onBundleUpdate: PropTypes.func.isRequired,
    onBundleDelete: PropTypes.func.isRequired,
    onBundleNameUpdate: PropTypes.func.isRequired
};

export default BundleInfoContainer;
