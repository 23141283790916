import React from 'react';
import generalStyles from '@/components/General/Styles.module.scss';
import { Link } from 'react-router-dom';

function CompanySearchTableInstance({ dto }) {
    return (
        <>
            <tr>
                <td className={generalStyles.cap}>{dto.companyname}</td>
                <td className={generalStyles.cap}>
                    <Link className={generalStyles.link} to={`/newcompanypage/${dto.companyid}`}>
                        Configuration
                    </Link>
                </td>
                <td>
                    <Link className={generalStyles.link} to={`/obobycompany/${dto.companyid}`}>
                        View OBO Activity
                    </Link>
                </td>
            </tr>
        </>
    );
}

function CompanySearchTable(props) {
    return (
        <table>
            <thead>
                <tr>
                    <th>Company Name</th>
                    <th>Company Configuration</th>
                    <th>OBO Actions</th>
                </tr>
            </thead>
            <tbody>
                {props.dtos.map((dto, index) => {
                    return <CompanySearchTableInstance key={index} dto={dto} />;
                })}
            </tbody>
        </table>
    );
}

export default CompanySearchTable;
