import { FILTER_TYPES } from '../General/DataTable';
import { MultiSelectColumnFilter } from '../General/MultiSelectColumnFilter';
import { DateRangeColumnFilter } from '../General/DateRangeColumnFilter';
import generalStyles from '@/components/General/Styles.module.scss';

export const COLUMNS = [
    {
        Header: 'Event Name',
        accessor: 'event_name',
        className: generalStyles.tableCell,
        Filter: MultiSelectColumnFilter,
        filter: FILTER_TYPES.multiple,
    }, 
    {
        Header: 'Event Date',
        accessor: (row)=>new Date(new Date(row.event_date).getTime() + (new Date().getTimezoneOffset() * 60000)).toLocaleDateString(),
        className: generalStyles.tableCell,
        Filter: DateRangeColumnFilter,
        filter: FILTER_TYPES.dateRange
    }, 
    {
        Header: 'Company Name',
        accessor: 'company_name',
        className: generalStyles.tableCell,
        Filter: MultiSelectColumnFilter,
        filter: FILTER_TYPES.multiple
    },
    {
        Header: 'Company ID',
        accessor: ((row)=>row?.company_id ? String(row?.company_id) : row?.company_id ),
        id: 'company_id',
        className:`${generalStyles.tableCell} ${generalStyles.numeric}`,
        Filter: MultiSelectColumnFilter,
        filter: FILTER_TYPES.multiple
    },
    {
        Header: 'Previously licensed categories',
        accessor: 'previously_licensed_categories',
        className: generalStyles.tableCell,
        Filter: MultiSelectColumnFilter,
        filter: FILTER_TYPES.multiple,
        Cell: ({ value }) => value?.join(', ') ?? ''
    },
    {
        Header: 'Newly licensed category',
        accessor: 'newly_licensed_categories',
        className: generalStyles.tableCell,
        Filter: MultiSelectColumnFilter,
        filter: FILTER_TYPES.multiple,
        Cell: ({ value }) => value?.join(', ') ?? ''
    },
    {
        Header: 'Number of categories added',
        accessor: (row)=> String(row.categories_added_count),
        id: 'categories_added_count',
        className: generalStyles.tableCell,
        Filter: MultiSelectColumnFilter,
        filter: FILTER_TYPES.multiple,
    },
    {
        Header: 'PMA Impacted',
        accessor: ((row) => row.pma_impacted !== null ? (row.pma_impacted === false ? 'N':'Y') : null),
        className: generalStyles.tableCell,
        Filter: MultiSelectColumnFilter,
        filter: FILTER_TYPES.multiple,
    },
    {
        Header: 'PMA Marktets Impacted',
        accessor:'pma_markets_impacted',
        className: generalStyles.tableCell,
        Filter: MultiSelectColumnFilter,
        filter: FILTER_TYPES.multiple
    },
    {
        Header :'Scheduled Runs impacted',
        accessor: ((row) => row.scheduled_runs_impacted !== null ? (row.scheduled_runs_impacted === false ? 'N':'Y') : null),
        className: generalStyles.tableCell,
        Filter: MultiSelectColumnFilter,
        filter: FILTER_TYPES.multiple,

    },
    {
        Header :'Alerts impacted',
        id: 'alerts_impacted',
        accessor: ((row) => row.alerts_impacted !== null ? (row.alerts_impacted === false ? 'N':'Y') : null),
        className: generalStyles.tableCell,
        Filter: MultiSelectColumnFilter,
        filter: FILTER_TYPES.multiple,
    }
]