import generalStyles from '@/components/General/Styles.module.scss';
import React, { useState, useEffect } from 'react';
import API_STATES from '@/constants/StateConstants';
import { changeCsrPassword } from '@/services/api/csrdata.service';
import { validateCurrentPassword, reauthenticateUser } from '@/services/account.service';
import PasswordInput from '@/components/General/PasswordInput';
import { validatePassword } from '@/services/PasswordValidation.service';
import levenshtein from 'js-levenshtein';
import store from '@/store';
import { handleApiError } from '@/components/General/HandleApiError';

function ChangeCsrPassword(props) {
    const email = props.email;
    const loggedInCsrEmail = store?.getState()?.account?.user?.email;
    const isLoggedInCsr = loggedInCsrEmail === email;

    const [passwordValue, setPasswordValue] = useState({
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
    });

    const onPasswordValueChange = async (passwordName, value) => {
        setChangeCsrPasswordApiState(API_STATES.none);
        setPasswordValue({
            ...passwordValue,
            [passwordName]: value,
        });
        setPasswordErrorList({
            ...passwordErrorList,
            [passwordName]: [],
        });
    };

    const [passwordErrorList, setPasswordErrorList] = useState({
        currentPassword: [],
        newPassword: [],
        confirmPassword: [],
    });

    const [changeCsrPasswordApiState, setChangeCsrPasswordApiState] = useState(API_STATES.none);
    const [changeCsrPasswordApiError, setChangeCsrPasswordApiError] = useState('');

    const isSubmitEnabled = () => {
        return (
            passwordErrorList.currentPassword.length === 0 &&
            passwordValue.newPassword !== '' &&
            passwordErrorList.newPassword.length === 0 &&
            passwordValue.confirmPassword === passwordValue.newPassword &&
            changeCsrPasswordApiState !== API_STATES.loading
        );
    };

    const findPasswordErrors = () => {
        const { currentPassword, newPassword, confirmPassword } = passwordValue;
        let newPasswordErrorList = [];
        if (newPassword !== '') {
            newPasswordErrorList = validatePassword(newPassword, email);
            const similarity = levenshtein(newPassword, currentPassword);
            if (isLoggedInCsr && (newPassword === currentPassword || similarity < 3)) {
                newPasswordErrorList.push('Must not be the similar to your current password.');
            }
        }
        let confirmPasswordErrorList = [];
        if (newPassword !== confirmPassword) {
            confirmPasswordErrorList.push('Must be the same as your new password.');
        }
        setPasswordErrorList({
            ...passwordErrorList,
            newPassword: newPasswordErrorList,
            confirmPassword: confirmPasswordErrorList,
        });
    };

    async function checkCurrentPassword() {
        let currentPasswordErrorList = [];
        setChangeCsrPasswordApiState(API_STATES.loading);
        const result = await validateCurrentPassword(passwordValue.currentPassword);
        switch (result.status) {
            case API_STATES.success:
                changePassword();
                break;
            case API_STATES.error:
                let errorStatus = result.error.code;
                switch (errorStatus) {
                    case 'auth/wrong-password':
                        currentPasswordErrorList.push('Your current password is incorrect');
                        break;
                    default:
                        currentPasswordErrorList.push(
                            'This is an unusual case, and can usually be fixed by logging out, then logging back in.'
                        );
                        break;
                }
                setPasswordErrorList({
                    ...passwordErrorList,
                    currentPassword: currentPasswordErrorList,
                });
                setPasswordValue({
                    ...passwordValue,
                    currentPassword: '',
                });
                setChangeCsrPasswordApiState(API_STATES.error);
                break;
            default:
                setChangeCsrPasswordApiState(API_STATES.none);
        }
    }

    async function changePassword() {
        setChangeCsrPasswordApiState(API_STATES.loading);
        const result = await changeCsrPassword(email, passwordValue.currentPassword, passwordValue.newPassword);
        switch (result.status) {
            case API_STATES.success:
                if (isLoggedInCsr) {
                    const reauthResult = await reauthenticateUser(passwordValue.newPassword);
                    switch (reauthResult.status) {
                        case API_STATES.success:
                            setChangeCsrPasswordApiState(API_STATES.success);
                            setPasswordValue({
                                currentPassword: '',
                                newPassword: '',
                                confirmPassword: '',
                            });
                            break;
                        case API_STATES.error:
                            setChangeCsrPasswordApiState(API_STATES.error);
                            setChangeCsrPasswordApiError(
                                'This is an unusual case, and can usually be fixed by logging out, then logging back in.'
                            );
                            break;
                        default:
                            setChangeCsrPasswordApiState(API_STATES.none);
                    }
                } else {
                    setChangeCsrPasswordApiState(API_STATES.success);
                    setPasswordValue({
                        currentPassword: '',
                        newPassword: '',
                        confirmPassword: '',
                    });
                    props.onCsrPasswordChange();
                }
                break;
            case API_STATES.error:
                let error = result.error;
                handleApiError(
                    error,
                    setChangeCsrPasswordApiState,
                    setChangeCsrPasswordApiError,
                    `Csr ${email} not found`
                );
                break;
            default:
                setChangeCsrPasswordApiError('');
                setChangeCsrPasswordApiState(API_STATES.none);
        }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => findPasswordErrors(), [passwordValue]);

    return (
        <div data-test="change-csr-password-component">
            <>
                <h3>Change Password</h3>
                <br />
                {isLoggedInCsr && (
                    <PasswordInput
                        label="Current Password:"
                        placeholder="Current Password"
                        value={passwordValue.currentPassword}
                        onPasswordChange={(event) => onPasswordValueChange('currentPassword', event.target.value)}
                        errorList={passwordErrorList.currentPassword}
                        disabled={changeCsrPasswordApiState === API_STATES.loading}
                    />
                )}
                <PasswordInput
                    label="New Password:"
                    placeholder="New Password"
                    value={passwordValue.newPassword}
                    onPasswordChange={(event) => onPasswordValueChange('newPassword', event.target.value)}
                    errorList={passwordErrorList.newPassword}
                    disabled={changeCsrPasswordApiState === API_STATES.loading}
                />
                <PasswordInput
                    label="Confirm Password:"
                    placeholder="Confirm Password"
                    value={passwordValue.confirmPassword}
                    onPasswordChange={(event) => onPasswordValueChange('confirmPassword', event.target.value)}
                    errorList={passwordErrorList.confirmPassword}
                    disabled={changeCsrPasswordApiState === API_STATES.loading}
                />
                <br />
                <button
                    data-test="change-csr-password-submit-btn"
                    className={generalStyles.submitButton}
                    disabled={!isSubmitEnabled()}
                    onClick={isLoggedInCsr ? checkCurrentPassword : changePassword}
                >
                    Change Password
                </button>
                <label data-test="change-csr-pwd-result">
                    {changeCsrPasswordApiState === API_STATES.loading ? (
                        <span className={generalStyles.statusMessage}>Processing...</span>
                    ) : changeCsrPasswordApiState === API_STATES.error ? (
                        <span className={generalStyles.errorMessage}>
                            Failed to Change CSR Password: {changeCsrPasswordApiError}
                        </span>
                    ) : (
                        changeCsrPasswordApiState === API_STATES.success && (
                            <span className={generalStyles.successMessage}>CSR Password Updated!</span>
                        )
                    )}
                </label>
            </>
        </div>
    );
}
export default ChangeCsrPassword;
