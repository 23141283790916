import React, { useState } from 'react';
import * as API from '@/services/api/product.service';
import generalStyles from '@/components/General/Styles.module.scss';
import API_STATES from '@/constants/StateConstants';
import { handleApiError } from '@/components/General/HandleApiError';
import TextInput from '@/components/General/TextInput';
import { Button } from 'antd';

function FileInfoTableInstance(props) {
    const fileId = props.fileId;
    const folder = props.folder;

    const [lastSavedFileName, setLastSavedFileName] = useState(props.fileName);
    const [newFileName, setNewFileName] = useState(lastSavedFileName);

    const [deleteFileApiStatus, setDeleteFileApiStatus] = useState(API_STATES.none);
    const [deleteFileApiError, setDeleteFileApiError] = useState('');

    const [updateFileTitleApiStatus, setUpdateFileTitleApiStatus] = useState(API_STATES.none);
    const [updateFileTitleApiError, setUpdateFileTitleApiError] = useState('');

    async function deleteFile() {
        setDeleteFileApiStatus(API_STATES.loading);
        const result = await API.deleteMarketMapsFile(fileId, folder.name);
        switch (result.status) {
            case API_STATES.success:
                setDeleteFileApiStatus(API_STATES.success);
                setTimeout(() => props.onFileUpdate(), 2000);
                break;
            case API_STATES.error:
                let error = result.error;
                handleApiError(error, setDeleteFileApiStatus, setDeleteFileApiError);
                break;
            default:
                setDeleteFileApiError('');
                setDeleteFileApiStatus(API_STATES.none);
        }
    }
    const onTitleChange = (value) => {
        setUpdateFileTitleApiStatus(API_STATES.none);
        setUpdateFileTitleApiError('');
        setNewFileName(value);
    };
    const isRenameEnabled = () => {
        return (
            newFileName !== '' && updateFileTitleApiStatus !== API_STATES.loading && newFileName !== lastSavedFileName
        );
    };
    async function renameFileTitle() {
        setUpdateFileTitleApiStatus(API_STATES.loading);
        const result = await API.renameMarketMapsFile(fileId, newFileName);
        switch (result.status) {
            case API_STATES.success:
                setUpdateFileTitleApiStatus(API_STATES.success);
                setLastSavedFileName(newFileName);
                setTimeout(() => props.onFileUpdate(), 2000);
                break;
            case API_STATES.error:
                let error = result.error;
                handleApiError(error, setDeleteFileApiStatus, setDeleteFileApiError);
                break;
            default:
                setUpdateFileTitleApiError('');
                setUpdateFileTitleApiStatus(API_STATES.none);
        }
    }

    return (
        <>
            <tr>
                <td className={generalStyles.numeric}>{fileId}</td>
                <td className={generalStyles.TextInput}>
                    <TextInput
                        type="text"
                        label="FileName :"
                        placeholder="Title"
                        value={newFileName}
                        onInputChange={(event) => onTitleChange(event.target.value)}
                        disabled={updateFileTitleApiStatus === API_STATES.loading}
                    />
                </td>
                <td>
                    <Button
                        className={generalStyles.smallButton}
                        disabled={deleteFileApiStatus === API_STATES.loading}
                        onClick={() => {
                            deleteFile();
                        }}
                    >
                        Delete
                    </Button>
                    <label data-test="delete-product-status">
                        {deleteFileApiStatus === API_STATES.loading ? (
                            <span className={generalStyles.statusMessage}>Processing...</span>
                        ) : deleteFileApiStatus === API_STATES.error ? (
                            <span className={generalStyles.errorMessage}>Error: {deleteFileApiError}</span>
                        ) : (
                            deleteFileApiStatus === API_STATES.success && (
                                <span className={generalStyles.successMessage}>Successfully deleted!</span>
                            )
                        )}
                    </label>
                    <Button
                        className={generalStyles.smallButton}
                        disabled={!isRenameEnabled()}
                        onClick={() => {
                            renameFileTitle();
                        }}
                    >
                        Rename
                    </Button>
                    <label data-test="rename-product-status">
                        {updateFileTitleApiStatus === API_STATES.loading ? (
                            <span className={generalStyles.statusMessage}>Processing...</span>
                        ) : updateFileTitleApiStatus === API_STATES.error ? (
                            <span className={generalStyles.errorMessage}>Error: {updateFileTitleApiError}</span>
                        ) : (
                            updateFileTitleApiStatus === API_STATES.success && (
                                <span className={generalStyles.successMessage}>Successfully Renamed!</span>
                            )
                        )}
                    </label>
                </td>
            </tr>
        </>
    );
}

export default FileInfoTableInstance;
