import axios from 'axios';
import firebase from 'firebase/app';
import 'firebase/auth';
import API_STATES from '@/constants/StateConstants';

const http = axios.create({
    baseURL: import.meta.env.REACT_APP_BASEURL,
});

/*
Watches auth token state changes to keep the request header in sync
*/

http.interceptors.request.use(async (config) => {
    if (firebase.auth().currentUser) {
        config.headers.Authorization = `Bearer ${await firebase.auth().currentUser.getIdToken()}`;
    }
    return config;
});

export async function getCsrUser(email) {
    try {
        const res = await http.get(`/csr/${email}`);
        return { status: API_STATES.success, data: res.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function getAllCSRs() {
    try {
        const response = await http.get(`/csr`);
        return { status: API_STATES.success, data: response.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function setResetPassword(email, resetValue) {
    try {
        const res = await http.put(`/csr/${email}`, { resetValue });
        return { status: API_STATES.success, data: res.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function setDisableAuthValue(email, disabled) {
    try {
        const res = await http.put(`/csr/${email}`, { disabled });
        return { status: API_STATES.success, data: res.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function deleteCsrUser(email) {
    try {
        const res = await http.delete(`/csr/${email}`);
        return { status: API_STATES.success, data: res.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function updateCsrNameAndRoles(email, firstname, lastname, csrRoles) {
    try {
        const res = await http.put(`/csr/${email}`, { firstname, lastname, csrRoles });
        return { status: API_STATES.success, data: res.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function changeMyOwnPassword(oldPass, newPass) {
    try {
        const res = await http.put(`/csr`, { oldPass, newPass });
        return { status: API_STATES.success, data: res.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function changeCsrPassword(email, oldPass, newPass) {
    try {
        const res = await http.put(`/csr/${email}`, { oldPass, newPass });
        return { status: API_STATES.success, data: res.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function createCSR(firstName, lastName, email, password, roles) {
    try {
        const res = await http.post(`/csr`, { firstName, lastName, email, password, roles });
        return { status: API_STATES.success, data: res.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function getAllCsrRoles() {
    try {
        const res = await http.get(`/csr/roles`);
        return { status: API_STATES.success, data: res.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function getOboActivity(email) {
    try {
        const result = await http.get(`/csr/${email}/obo`);
        return { status: API_STATES.success, data: result.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}