import React, { useState } from 'react';
import * as API from '@/services/api/userdata.service';
import styles from './GetResetPasswordLink.module.scss';
import API_STATES from '@/constants/StateConstants';
import SearchBar from '@/components/General/SearchBar';
import generalStyles from '@/components/General/Styles.module.scss';
import { handleApiError } from '@/components/General/HandleApiError';

function GetResetPasswordLink() {
    const [emailInput, setEmailInput] = useState('');
    const [customerEmail, setCustomerEmail] = useState('');

    const [verifyEmailApiStatus, setVerifyEmailApiStatus] = useState(API_STATES.none);
    const [verifyEmailApiError, setVerifyEmailApiError] = useState('');

    const [fetchLinkApiStatus, setFetchLinkApiStatus] = useState(API_STATES.none);
    const [fetchLinkApiError, setFetchLinkApiError] = useState('');

    const [link, setLink] = useState('');
    const [copyStatus, setCopyStatus] = useState('');

    async function verifyEmail() {
        setVerifyEmailApiStatus(API_STATES.loading);
        const result = await API.getFirebaseUser(emailInput.toLowerCase());
        switch (result.status) {
            case API_STATES.success:
                setVerifyEmailApiStatus(API_STATES.success);
                getChangePasswordLink(emailInput.toLowerCase());
                setCustomerEmail(emailInput.toLowerCase());
                break;
            case API_STATES.error:
                let error = result.error;
                handleApiError(
                    error,
                    setVerifyEmailApiStatus,
                    setVerifyEmailApiError,
                    `The email ${emailInput} is not a registered email in the Byzzer system.`
                );
                break;
            default:
                setVerifyEmailApiStatus(API_STATES.none);
                setVerifyEmailApiError('');
        }
    }

    async function getChangePasswordLink(email) {
        setFetchLinkApiStatus(API_STATES.loading);
        const result = await API.getResetPasswordLink(email);
        switch (result.status) {
            case API_STATES.success:
                setFetchLinkApiStatus(API_STATES.success);
                setLink(result.data);
                break;
            case API_STATES.error:
                let error = result.error;
                handleApiError(
                    error,
                    setFetchLinkApiStatus,
                    setFetchLinkApiError,
                    `${emailInput} is not a valid byzzer customer`
                );
                break;
            default:
                setFetchLinkApiStatus(API_STATES.none);
                setFetchLinkApiError('');
        }
    }

    const updateEmailInput = (e) => {
        setEmailInput(e.target.value);
    };

    function onSubmitHandler() {
        if (emailInput !== null && emailInput !== '') {
            setVerifyEmailApiError('');
            setVerifyEmailApiStatus(API_STATES.none);
            setFetchLinkApiStatus(API_STATES.none);
            setFetchLinkApiError('');
            setLink('');
            verifyEmail();
        }
    }

    return (
        <>
            <h1>Generate Reset Password Link</h1>
            <hr />
            <div className={styles.flexcontainer}>
                <div>
                    <SearchBar
                        value={emailInput}
                        onChange={updateEmailInput}
                        placeholder="Byzzer Customer Email"
                        disabled={
                            verifyEmailApiStatus === API_STATES.loading || fetchLinkApiStatus === API_STATES.loading
                        }
                        withButton={true}
                        buttonText="Get Link"
                        onSubmit={() => onSubmitHandler()}
                    />
                </div>
                <div data-test="get-reset-pwd-link-status">
                    {verifyEmailApiStatus === API_STATES.error || fetchLinkApiStatus === API_STATES.error ? (
                        <p className={`${generalStyles.errorMessage} ${styles.errorMessage}`}>
                            Error: {verifyEmailApiError} {fetchLinkApiError}
                        </p>
                    ) : verifyEmailApiStatus === API_STATES.loading || fetchLinkApiStatus === API_STATES.loading ? (
                        <p className={generalStyles.statusMessage}>Loading...</p>
                    ) : verifyEmailApiStatus === API_STATES.none || fetchLinkApiStatus === API_STATES.none ? (
                        <p className={generalStyles.statusMessage}>Please enter a customer email</p>
                    ) : fetchLinkApiStatus === API_STATES.success ? (
                        <p className={generalStyles.successMessage}>Success!</p>
                    ) : null}
                </div>
            </div>
            {fetchLinkApiStatus === API_STATES.success && (
                <div>
                    <h5>Reset Password Link for {customerEmail}</h5>
                    <div className={styles.link_display_area} data-test="link-display-area">
                        <p id="link">{link}</p>
                        <button
                            onClick={() => {
                                navigator.clipboard.writeText(link);
                                setCopyStatus('Copied!');
                                setTimeout(() => setCopyStatus(''), 1000);
                            }}
                            className={generalStyles.smallButton}
                        >
                            Copy
                        </button>
                        <span className={generalStyles.statusMessage} data-test="copy-link-status">{copyStatus}</span>
                    </div>
                </div>
            )}
        </>
    );
}
export default GetResetPasswordLink;
