import React, { useEffect, useState , useRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import calendarIcon from '@images/icons/calendar.svg';
import moment from 'moment';
import styles from '../HomepageNotification.module.scss';
import generalStyles from '@/components/General/Styles.module.scss';
import Select from 'react-select';
import * as Constant from '../Constants';
import API_STATES from '@/constants/StateConstants';
import {
    getCategories,
    getDepartments,
    getSuperCategories,

} from '@/services/api/product.service';
import * as MessageService from '@/services/api/categoryBasedNotification.service';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import ByzzerDateRange from '@/components/ByzzerDateRange';
import { format } from 'date-fns';
import { getMessageTypeValue, getRunTypeValue } from '../Utils';
import { handleApiError } from '@/components/General/HandleApiError';
import {toOption } from '@/components/utils/utils';


const FILTER_TYPES = [
    { label: 'Categories', value: 'Category' },
    { label: 'Super Categories', value: 'Super Category' },
    { label: 'Departments', value: 'Department' },
];

function CategoryBasedNotificationForm({
    refresh,
    selectedNotification,
    edit = false,
    editSingleNotification = false,
    closeEdit,
}) {
    const [endDate, setEndDate] = useState(edit ? moment(new Date(selectedNotification.endDate)) : null);
    const [selectedNotificationType, setSelectedNotificationType] = useState(
        edit ? getMessageTypeValue(selectedNotification.notificationType) : []
    );
    const [notificationMessage, setNotificationMessage] = useState(edit ? selectedNotification.message : '');
    const [notificationHeader, setNotificationHeader] = useState(edit ? selectedNotification.header : '');
    const [notificationLink, setNotificationLink] = useState(edit ? selectedNotification.hyperLink : '');
    const [createApiState, setCreateApiState] = useState(API_STATES.none);
    const [createApiError, setCreateApiError] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [triggerType, setTriggerType] = useState(edit ? getRunTypeValue(selectedNotification.runType) : []);
    const [clearDateRange, setClearDateRange] = useState(false);
    const dateRangeRef = useRef();

    const [categoriesApiState, setCategoriesApiState] = useState(API_STATES.none);
    const [categoriesApiError, setCategoriesApiError] = useState(null);
    const [selectedItems, setSelectedItems] = useState([]);
    const [allCategories, setAllCategories] = useState(null);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedFilterType, setSelectedFilterType] = useState(FILTER_TYPES[0]);
    const [totalSuperCategories, setTotalSuperCategories] = useState([]);
    const [totalDepartmentCategories, setTotalDepartmentCategories] = useState([]);
    const [rowDataCategories, setRowDataCategories] = useState([]);

    const timeRangeRef = useRef();
    const [defaultStartDate, setDefaultStartDate] = useState(null);
    const [defaultEndDate, setDefaultEndDate] = useState(null);
    const [userCount, setUserCount] = useState(0);

    useEffect(()=>{
        if(edit){
            setRowDataCategories(selectedNotification?.categoryList)
            setSelectedCategories(selectedNotification?.categoryList)
        }

    }, [selectedNotification])

    const onDateChange = (endDateValue) => {
        if (endDateValue && endDateValue !== '') setEndDate(endDateValue);
        else setEndDate(null);
    };

    const clearSelections = () => {
        setSelectedCategories([]);
        setSelectedItems([])
        setSelectedFilterType(FILTER_TYPES[0])
        setTriggerType([]);
        setClearDateRange(true);
        setEndDate(null);
        setSelectedNotificationType([]);
        setNotificationHeader('');
        setNotificationLink('');
        setNotificationMessage('');
        setClearDateRange(false);
        timeRangeRef.current = null;
        setDefaultEndDate(null);
        setDefaultStartDate(null);
        setUserCount(0);
    };

    async function createRequestBody() {
        const expirationDate = endDate ? moment(endDate).endOf('day').format('YYYY/MM/DD HH:mm:ss') : null;
        const runFrom = timeRangeRef.current?.runFrom
            ? moment(new Date(timeRangeRef.current.runFrom)).startOf('day').format('YYYY/MM/DD HH:mm:ss')
            : null;
        const runTo = timeRangeRef.current?.runTo
            ? moment(new Date(timeRangeRef.current.runTo)).endOf('day').format('YYYY/MM/DD HH:mm:ss')
            : null;

        const messageType =
            selectedNotificationType?.value ??
            selectedNotificationType?.[0]?.value ??
            selectedNotification?.notificationType ??
            Constant.TYPE_OF_NOTIFICATION[1].value;

        const categoryTriggerType =
            triggerType?.value ??
            triggerType?.[0]?.value ??
            triggerType?.notificationType ??
            Constant.RUN_TYPE[1].value;

        let requestBody = {
            categoryMessage: {
                categories: selectedCategories,
                categoryTriggerType: categoryTriggerType,
                startDtm: runFrom,
                endDtm: runTo,
            },
            expirationDtm: expirationDate,
            notification: { message: notificationMessage, header: notificationHeader, hyperLink: notificationLink },
            messageType: messageType,
            triggerType: Constant.CUSTOM,
            priority: Constant.PRIORITY.LOW,
            messageCreationType: Constant.MESSAGE_TYPE.CATEGORY_BASED,
        };

        if (edit) {
            requestBody.notificationId = selectedNotification.notificationId;
            requestBody.csrNotificationId = selectedNotification.csrNotificationId;
            requestBody.recordType = 'edit';
        }
        if (edit && editSingleNotification) {
            requestBody.id = selectedNotification.id;
            requestBody.recepientId = selectedNotification?.recepientId ?? null;
            requestBody.companyId = selectedNotification?.companyId ?? null;
            requestBody.notificationId = selectedNotification.notificationId;
            
            //TODO fix this - edit_userbased_message API accepts
            //'requestBody.message' instead of 'requestBody.notification'
            if(requestBody.categoryMessage?.categoryTriggerType === "reportBased") {
                requestBody.message = { message: notificationMessage, header: notificationHeader, hyperLink: notificationLink };
            }
        }

        return requestBody;
    }

    const sendNotification = async () => {
        try {
            setOpenModal(false);

            setCreateApiState(API_STATES.loading);
            //creating request body based on create or edit option
            let requestBody = await createRequestBody();
            let response;

            if (edit && editSingleNotification) {
                if(requestBody.categoryMessage?.categoryTriggerType === "reportBased") {
                    response = await MessageService.editSingleUserMessage(requestBody);
                } else {
                    response = await MessageService.editCompanyBasedMessage(requestBody);
                }
            } else if (edit) {
                response = await MessageService.editAllMessages(requestBody);
            } else {
                response = await MessageService.createCategoryBasedMessage(requestBody);
            }
            clearSelections();

            if (response.status === API_STATES.success) {
                setCreateApiState(API_STATES.success);
                refresh(true);
                if (edit) {
                    closeEdit();
                }
            } else {
                setCreateApiError(response.error);
                setCreateApiState(API_STATES.error);
            }
        } catch (e) {
            setCreateApiError(e);
            setCreateApiState(API_STATES.error);
        }
    };

    const displayPreview = async () => {
        let requestBody = await createRequestBody();
        let response = await MessageService.getUserCount(requestBody);
        if (response.status === API_STATES.success) {
            setUserCount(response.data);
            setOpenModal(true);
        }
    };
    const validateSelection = () => {
        if (triggerType?.value === 'reportBased' || triggerType[0]?.value === 'reportBased') {
            return !(
                selectedCategories?.length !== 0 &&
                triggerType?.length !== 0 &&
                timeRangeRef.current !== null &&
                timeRangeRef.current !== undefined &&
                timeRangeRef.current?.runFrom !==null &&
                timeRangeRef.current?.runFrom !==undefined &&
                timeRangeRef.current?.runTo !==null &&
                timeRangeRef.current?.runTo !==undefined &&
                selectedNotificationType?.length !== 0 &&
                notificationHeader &&
                notificationHeader?.trim() !== '' &&
                notificationMessage &&
                notificationMessage?.trim() !== '' &&
                endDate
            );
        } else {
            return !(
                selectedCategories?.length !== 0 &&
                triggerType?.length !== 0 &&
                selectedNotificationType?.length !== 0 &&
                notificationHeader &&
                notificationHeader?.trim() !== '' &&
                notificationMessage &&
                notificationMessage?.trim() !== '' &&
                endDate
            );
        }
    };

    function onChangeDateRange(startDate, endDate) {
        let fromDate = startDate === null || startDate === undefined ? null : format(new Date(startDate), 'yyyy/MM/dd');
        let toDate = endDate === null || startDate === undefined ? null : format(new Date(endDate), 'yyyy/MM/dd');
        if (fromDate !== timeRangeRef.current?.runFrom || toDate !== timeRangeRef.current?.runTo) {
            timeRangeRef.current = { runFrom: fromDate, runTo: toDate };
            setDefaultStartDate(startDate === null || startDate === undefined ? null : new Date(startDate));
            setDefaultEndDate(endDate === null || startDate === undefined ? null : new Date(endDate));
        }
    }

    const buttonLabel = useState(edit ? 'Edit' : 'Submit');

    const handleSelectedFilterType = (filterType) => {
        setSelectedItems([]);
        setSelectedCategories([]);
        setSelectedFilterType(filterType);
    }

    const handleItemSelect = (selectedItem) => {
        setSelectedItems(selectedItem);
        let getCategories;
        if (selectedFilterType.value === 'Super Category') {
            getCategories = selectedItem .flatMap((item) => totalSuperCategories
                        .filter((category) => category.superCategoryName === item.value)
                        .map((item) => item.categories)
                )
                .flat();
        } else if (selectedFilterType.value === 'Department') {
            getCategories = selectedItem
                .flatMap((item) => totalDepartmentCategories
                        .filter((category) => category.departmentName === item.value)
                        .map((item) => item.categories)
                )
                .flat();
        } else {
            getCategories = selectedItem.map((item) => item.value);
        }

        if (!edit) {
            setSelectedCategories(getCategories);
        } else {
            const newCategories = [...rowDataCategories, ...getCategories];
            setSelectedCategories([...new Set(newCategories)]);
            setRowDataCategories([...new Set(newCategories)]);
        }
    };
          
    useEffect(() => {
        loadAllCategories(selectedFilterType);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFilterType]);

    async function loadAllCategories(selectedFilterType) {
        setCategoriesApiState(API_STATES.loading);
        let response = null;
        if (selectedFilterType.value === 'Category') {
            response = await getCategories();
        } else if (selectedFilterType.value === 'Super Category') {
            response = await getSuperCategories();
        } else if (selectedFilterType.value === 'Department') {
            response = await getDepartments();
        } else {
            return;
        }
        if (response !== null) {
            if (response.status === API_STATES.success) {
                let selectItems;
                if (selectedFilterType.value === 'Super Category') {
                    setTotalSuperCategories(response.data)
                    selectItems = response.data.map((item) => toOption(item.superCategoryName));
                    setAllCategories(selectItems);
                    
                } else if (selectedFilterType.value === 'Department') {
                    setTotalDepartmentCategories(response.data)
                    selectItems = response.data.map((item) => toOption(item.departmentName));
                    setAllCategories(selectItems);
                } else {
                    selectItems = response.data.map((category) => toOption(category));
                    setAllCategories(selectItems);
                }
                setCategoriesApiState(API_STATES.success);
                if(edit){ 
                    onChangeDateRange(selectedNotification.fromDate, selectedNotification.toDate);
                }
            } else {
                setAllCategories([]);
                handleApiError(response.error, setCategoriesApiState, setCategoriesApiError);
            }
        }
    }

    function removeCategory(category) {       
        const newCategories = rowDataCategories?.filter((li)=> li !== category)
        setRowDataCategories(newCategories);
        setSelectedCategories(newCategories);
    }

    return (
        <>
            {!edit && (
                <>
                    {' '}
                    <h1>Category Based Notification</h1>
                    <hr />
                </>
            )}

            {edit && (
                <>
                    {' '}
                    <h1>Edit Category Based Notification</h1>
                    <hr />
                    <br />
                    <b data-test="category-count"> Selected Categories </b>
                    <ul className={styles.tileList}>
                        {rowDataCategories?.map((category) => (
                            <li>
                                <strong className={styles.removeButton} onClick={() => removeCategory(category)}>
                                    X
                                </strong>
                                {category}
                            </li>
                        ))}
                    </ul>
                    <br />

                </>
            )}

            {allCategories?.length > 0 && (
                <>                    
                    <div className={styles.selectionContainer}>
                        <div className={generalStyles['input-wrapper']}>
                            <label> Select Hierarchy: </label>
                            <span className={generalStyles.formField}>
                                <Select
                                    id="selectBox"
                                    classNamePrefix={'react-select'}
                                    onChange={handleSelectedFilterType}
                                    options={FILTER_TYPES}
                                    value={selectedFilterType}
                                />
                            </span>
                        </div>

                        <div className={generalStyles['input-wrapper']}>
                        <label>{selectedFilterType?.label}:</label>
                        <div className={styles.multiSelectInput}>
                            <Select
                                classNamePrefix={'react-select'}
                                options={allCategories}
                                closeMenuOnSelect={false}
                                isMulti={true}
                                onChange={handleItemSelect}
                                value={selectedItems}
                                placeholder="Select from the list"
                            />
                            {categoriesApiState === API_STATES.loading ? (
                                <span className={generalStyles.statusMessage}>Loading Categories...</span>
                            ) : categoriesApiState === API_STATES.error && (
                                <span className={generalStyles.statusMessage}>{categoriesApiError}</span>
                            )}
                        </div>
                        </div>

                        {/* Run Type select */}
                        <div className={styles.selectInputWrapper}>
                            <label className={styles.formFieldLabel}>Select Type:</label>
                            <span className={styles.formField}>
                                <Select
                                    classNamePrefix={'react-select'}
                                    options={Constant.RUN_TYPE}
                                    closeMenuOnSelect={false}
                                    onChange={(selectedType) => setTriggerType(selectedType)}
                                    value={triggerType}
                                    placeholder="Type"
                                    styles={Constant.SELECT_STYLES}
                                    isDisabled={editSingleNotification}
                                />
                            </span>
                        </div>

                        {/* date range selection */}
                        <div className={styles.selectInputWrapper}>
                            <label className={styles.formLabel}>Select Time Range:</label>                                                                                                                                                                           
                            <div className={styles.datepickerContainer}>
                                {(triggerType?.value === 'reportBased' || triggerType[0]?.value === 'reportBased') 
                                    && (!editSingleNotification) && (
                                    <ByzzerDateRange
                                        ref={dateRangeRef}
                                        onChange={onChangeDateRange}
                                        dateSelected={false}
                                        placeholder="Time Range"
                                        clearDateRange={clearDateRange}
                                        dateFormat={'MMM d, y'}
                                        minDate={new Date(2021, 0, 1)}
                                        maxDate={new Date()}
                                        disabled={
                                            triggerType?.value === 'reportBased' ||
                                            triggerType[0]?.value === 'reportBased'
                                                ? false
                                                : true
                                        }
                                        defaultStartDate={defaultStartDate}
                                        defaultEndDate={defaultEndDate}
                                    />
                                )}

                                {((triggerType?.value !== 'reportBased' && triggerType[0]?.value !== 'reportBased') 
                                    || editSingleNotification) && (
                                    <div className={styles.disabledDatePicker}>
                                        <Select
                                            classNamePrefix={'react-select'}
                                            closeMenuOnSelect={false}
                                            placeholder="Time Range"
                                            styles={Constant.DATE_SELECT_STYLES}
                                            isDisabled={true}
                                        />
                                    </div>
                                )}

                                <img src={calendarIcon} alt="calendar icon" />
                            </div>
                        </div>
                    </div>

                    <br />

                    <div className={styles.selectionContainer}>
                        {/* Message expiration date selection */}
                        <div className={styles.selectInputWrapper}>
                            <label className={styles.formLabel}>Select Expiration Date:</label>

                            <div className={styles.datepickerContainer}>
                                <DatePicker
                                    name="endDate"
                                    placeholderText="End Date"
                                    shouldCloseOnSelect={true}
                                    className={styles.datepickerInput}
                                    fixedHeight
                                    closeOnScroll={true}
                                    dateFormat="MMM d, y"
                                    selected={endDate ? endDate.toDate() : null}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    tabIndex={1}
                                    onChange={(date) => {
                                        onDateChange(date ? moment(date) : null);
                                    }}
                                    minDate={moment().toDate()}
                                    onKeyDown={(e) => {
                                        e.preventDefault();
                                    }}
                                />
                                <img src={calendarIcon} alt="calendar icon" />
                            </div>
                        </div>
                        {/* Notification type selection */}
                        <div className={styles.selectInputWrapper}>
                            <label className={styles.formLabel}>Notification Type:</label>
                            <span className={styles.formField}>
                                <Select
                                    classNamePrefix={'react-select'}
                                    options={Constant.TYPE_OF_NOTIFICATION}
                                    onChange={(selectedValue) => setSelectedNotificationType(selectedValue)}
                                    value={selectedNotificationType}
                                    placeholder={'Notification Type'}
                                />
                            </span>
                        </div>

                        {/* input for message header/title */}
                        <div className={styles.selectInputWrapper}>
                            <label className={styles.formLabel}>Notification Header:</label>
                            <span className={styles.formField}>
                                <textarea
                                    onChange={(e) => {
                                        setNotificationHeader(e.target.value);
                                    }}
                                    value={notificationHeader}
                                    className={generalStyles.textareaInput}
                                    placeholder={'Notification Header'}
                                />
                            </span>
                        </div>
                    </div>

                    <br />

                    <div className={styles.selectionContainer}>
                        {/* input for message text */}
                        <div className={styles.selectInputWrapper}>
                            <label className={styles.formLabel}>Message:</label>
                            <span className={styles.formField}>
                                <textarea
                                    onChange={(e) => {
                                        setNotificationMessage(e.target.value);
                                    }}
                                    value={notificationMessage}
                                    className={generalStyles.textareaInput}
                                    placeholder={'Notification Message'}
                                />
                            </span>
                        </div>
                        {/* input for adding URL for performing any action on the message */}
                        <div className={styles.selectInputWrapper}>
                            <label className={styles.formLabel}>Action:</label>
                            <span className={styles.formField}>
                                <textarea
                                    onChange={(e) => {
                                        setNotificationLink(e.target.value);
                                    }}
                                    value={notificationLink}
                                    className={generalStyles.textareaInput}
                                    placeholder={'Link (Optional) - Link for redirection'}
                                />
                            </span>
                        </div>

                        {/* button to submit all form selections to generate the message and send notification to users */}
                        <div>
                            <button
                                className={`${generalStyles.submitButton}`}
                                name={'submit'}
                                onClick={displayPreview}
                                disabled={validateSelection()}
                            >
                                Submit
                            </button>
                            <div>
                                {createApiState === API_STATES.loading && (
                                    <span className={generalStyles.statusMessage}>
                                        Triggering Category Based Notification...
                                    </span>
                                )}
                                {createApiState === API_STATES.error && (
                                    <span className={generalStyles.errorMessage}>Failed: {createApiError}</span>
                                )}
                            </div>
                        </div>
                    </div>

                    <Popup
                        open={openModal}
                        onClose={() => {
                            setOpenModal(false);
                        }}
                        modal
                    >
                        {() => (
                            <div className={styles.warningDialog}>
                                <h2>Preview</h2>
                                <div className={styles.messageContainer}>
                                    <div>
                                        {' '}
                                        <b>{notificationHeader}</b>: {notificationMessage}
                                    </div>
                                    <br></br>

                                    {notificationLink !== undefined &&
                                        notificationLink !== null &&
                                        notificationLink.trim() !== '' && (
                                            <>
                                                <div>
                                                    <b>Link</b>: {notificationLink}
                                                </div>
                                                <br></br>
                                            </>
                                        )}

                                    {!edit && (
                                        <div>
                                            <b>Note</b>: This message will be sent to {userCount} accounts
                                        </div>
                                    )}  
                                    {edit && !editSingleNotification && (
                                        <div>
                                            <b>Note</b>: This Message will be modified for all notified companies.
                                        </div>
                                    )}
                                    {edit && editSingleNotification && (
                                        <div>
                                            <b>Note</b>: This Message will be modified for selected company.
                                        </div>
                                    )}
                                </div>

                                <div>
                                    <button
                                        className={`${generalStyles.cancelOperationButton} ${styles.btnWidth}`}
                                        onClick={() => {
                                            sendNotification();
                                        }}
                                    >
                                        {buttonLabel}
                                    </button>

                                    <button
                                        className={`${generalStyles.confirmOperationButton} ${styles.btnWidth}`}
                                        onClick={() => {
                                            setOpenModal(false);
                                        }}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        )}
                    </Popup>
                </>
            )}
        </>
    );
}

export default CategoryBasedNotificationForm;
