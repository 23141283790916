import { getCategories, getCompanyCategoryCount } from '@/services/api/product.service';
import { useEffect, useMemo, useState } from 'react';
import generalStyles from '@/components/General/Styles.module.scss';
import styles from '@/components/CompanyConfig/CompanyConfig.module.scss';
import API_STATES from '@/constants/StateConstants';
import { handleApiError } from '@/components/General/HandleApiError';
import { EditCompanyCategories } from '@/components/CompanyConfig/EditCompanyCategories';
import { getCompanyConfig } from '@/services/api/company.service';

function ManageCategories({ nsCompanyId, className, isEditable = true, refreshTimestamp }) {
    const [companyCategoriesApiState, setCompanyCategoriesApiState] = useState(API_STATES.none);
    const [companyCategoriesApiError, setCompanyCategoriesApiError] = useState(null);
    const [companyCategories, setCompanyCategories] = useState([]);

    const [companyCategoriesCountApiState, setCompanyCategoriesCountApiState] = useState(API_STATES.none);
    const [companyCategoriesCountApiError, setCompanyCategoriesCountApiError] = useState('');
    const [companyCategoryCount, setCompanyCategoryCount] = useState(null);

    const [categoriesApiState, setCategoriesApiState] = useState(API_STATES.none);
    const [categoriesApiError, setCategoriesApiError] = useState(null);
    const [allCategories, setAllCategories] = useState([]);

    const [isInEditMode, setIsInEditMode] = useState(false);

    async function loadCompanyCategories(nsCompanyId) {
        setCompanyCategoriesApiState(API_STATES.loading);
        let response = await getCompanyConfig(nsCompanyId);
        //transforming response data/error as per requirement
        if (response.status === API_STATES.success) {
            let companyCategories = response.data.categories ?? [];
            companyCategories = companyCategories.map((category) => {
                return { label: category, value: category };
            });
            setCompanyCategories(companyCategories);
            setCompanyCategoriesApiState(API_STATES.success);
        } else {
            const error = response.error;
            handleApiError(
                error,
                setCompanyCategoriesApiState,
                setCompanyCategoriesApiError,
                `No config data for company ${nsCompanyId} found.`
            );
            setCompanyCategories([]);
        }
    }

    async function loadCompanyCategoryCount(nsCompanyId) {
        setCompanyCategoriesCountApiState(API_STATES.loading);
        let response = await getCompanyCategoryCount(nsCompanyId);
        if (response.status === API_STATES.success) {
            setCompanyCategoryCount(response.data);
            setCompanyCategoriesCountApiState(API_STATES.success);
        } else {
            setCompanyCategoryCount(null);
            handleApiError(response.error, setCompanyCategoriesCountApiState, setCompanyCategoriesCountApiError);
        }
    }

    async function loadCategories() {
        setCategoriesApiState(API_STATES.loading);
        const response = await getCategories();
        if (response.status === API_STATES.success) {
            response.data = response.data ?? [];
            const categories = response.data.map((category) => {
                return { label: category, value: category };
            });
            setAllCategories(categories);
            setCategoriesApiState(API_STATES.success);
        } else {
            handleApiError(response.error, setCategoriesApiState, setCategoriesApiError);
            setAllCategories([]);
        }
    }

    useEffect(() => {
        if (isEditable) {
            loadCategories();
        } else {
            setAllCategories([]);
        }
    }, [isEditable]);

    useEffect(() => {
        loadCompanyCategories(nsCompanyId);
        loadCompanyCategoryCount(nsCompanyId);
    }, [nsCompanyId, refreshTimestamp]);

    const companyCategoriesCountElement =
        companyCategoriesCountApiState === API_STATES.loading ? (
            <p className={generalStyles.statusMessage}>Loading Number of Categories....</p>
        ) : companyCategoriesCountApiState === API_STATES.error ? (
            <p className={generalStyles.errorMessage}>{companyCategoriesCountApiError}</p>
        ) : (
            companyCategoriesApiState === API_STATES.success && (
                <p>
                    {companyCategories.length} of {companyCategoryCount ?? '(unknown)'} Selected
                </p>
            )
        );

    const companyCategoriesElement =
        companyCategoriesApiState === API_STATES.loading ? (
            <p className={generalStyles.statusMessage}>Loading Company Categories List....</p>
        ) : companyCategoriesApiState === API_STATES.error ? (
            <p className={generalStyles.errorMessage}>Error: {companyCategoriesApiError}</p>
        ) : !companyCategories?.length ? (
            <p className={generalStyles.statusMessage}>No categories selected</p>
        ) : (
            <ul data-test="selected-category-list" className={generalStyles.tileList}>
                {companyCategories.map((category) => (
                    <li data-test="selected-category-list-item" key={category.value}>
                        {category.label}
                    </li>
                ))}
            </ul>
        );

    function onCategoriesEdited() {
        loadCompanyCategories(nsCompanyId);
        setIsInEditMode(false);
    }

    const waitToChangeCategories = useMemo(() => {
        return (
            companyCategoriesApiState !== API_STATES.success ||
            companyCategoriesCountApiState !== API_STATES.success ||
            categoriesApiState !== API_STATES.success ||
            !isEditable
        );
    }, [categoriesApiState, companyCategoriesApiState, companyCategoriesCountApiState, isEditable]);

    return (
        <div className={className}>
            {/* <h3>Categories</h3>
            <hr /> */}

            {isInEditMode ? (
                <>
                    <EditCompanyCategories
                        allCategories={allCategories}
                        nsCompanyId={nsCompanyId}
                        companyCategories={companyCategories}
                        maxAllowedCategories={companyCategoryCount}
                        onSuccess={onCategoriesEdited}
                        onCancel={() => setIsInEditMode(false)}
                        disabled={waitToChangeCategories}
                    />
                </>
            ) : (
                <>
                    {categoriesApiState === API_STATES.loading ? (
                        <p className={generalStyles.statusMessage}>loading categories...</p>
                    ) : (
                        categoriesApiState === API_STATES.error && (
                            <p className={generalStyles.errorMessage}>Error: {categoriesApiError}</p>
                        )
                    )}
                    <span data-test="selected-category-element">{companyCategoriesElement}</span>
                    <span className={styles.columnar}>
                        {isEditable && !isInEditMode && (
                            <button
                                className={generalStyles.submitButton}
                                disabled={waitToChangeCategories}
                                onClick={() => setIsInEditMode(true)}
                            >
                                Edit categories
                            </button>
                        )}
                        <span data-test="category-count">{companyCategoriesCountElement}</span>
                    </span>
                </>
            )}
        </div>
    );
}

export default ManageCategories;
