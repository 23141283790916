import generalStyles from '@/components/General/Styles.module.scss';
import { MultiSelectColumnFilter } from '../../General/MultiSelectColumnFilter';
import { DateRangeColumnFilter} from '../../General/DateRangeColumnFilter';
import { FILTER_TYPES } from '../../General/DataTable';

export const CURRENT_QUOTE_COLUMNS = [
    {
        Header: 'Email',
        accessor: 'email',
        id: 'email',
        className: `${generalStyles.tableCell}`,
        Filter: MultiSelectColumnFilter,
        filter: FILTER_TYPES.multiple
    },
    {
        Header: 'Quote Status',
        accessor: 'status',
        id: 'status',
        className: `${generalStyles.tableCell} ${generalStyles.cap}`,
        Filter: MultiSelectColumnFilter,
        filter: FILTER_TYPES.multiple
    },
];

export const PAST_QUOTE_COLUMNS = [
    {
        Header: 'Quote Id',
        accessor: 'quoteId',
        id: 'quoteId',
        className: `${generalStyles.tableCell}`,
        Filter: MultiSelectColumnFilter,
        filter: FILTER_TYPES.multiple
    },
    {
        Header: 'Accepted User',
        accessor: 'acceptedUser',
        id: 'acceptedUser',
        className: `${generalStyles.tableCell}`,
        Filter: MultiSelectColumnFilter,
        filter: FILTER_TYPES.multiple
    },
    {
        Header: 'Quote Status',
        accessor: 'status',
        id: 'status',
        className: `${generalStyles.tableCell} ${generalStyles.cap}`,
        Filter: MultiSelectColumnFilter,
        filter: FILTER_TYPES.multiple
    },
    {
        Header: 'Accepted / Expired Date',
        accessor: 'date',
        id: 'date',
        className: `${generalStyles.tableCell} ${generalStyles.cap}`,
        Filter: DateRangeColumnFilter,
        filter: FILTER_TYPES.dateRange,
    }, 
]