import React, { useEffect, useState, useContext } from 'react';
import RunCreditsTable from './RunCreditsTable';
import API_STATES from '@/constants/StateConstants';
import generalStyles from '@/components/General/Styles.module.scss';
import { companyPageContext } from '@/components/NewCompanyPage/NewCompanyPage';
import { getRunCredits } from '@//services/api/company.service';
import store from '../../store';

function RunCredits() {
    const { nsCompanyId, companyConfig: { companyName, companyAccessLevel, isMultiCompany } } = useContext(companyPageContext);

    const {isCsr, isSuperUser} = store?.getState()?.account ?? {};
    // State
    const [runCredits, setRunCredits] = useState([]);
    const [apiCallsOk, setApiCallsOk] = useState(false);
    const [initializing, setInitializing] = useState(false);
    const [retrievalErrorReason, setRetrievalErrorReason] = useState('');

    //////////////////////////////////////////////////////////////////////////
    function handleAPIError(error) {
        switch (error?.response?.status) {
            case 401:
                setRetrievalErrorReason('CSR is not logged in.');
                break;
            case 403:
                setRetrievalErrorReason('Unauthorised to perform this action');
                break;
            case 404:
                setRetrievalErrorReason('Run credits not found');
                break;
            default:
                setRetrievalErrorReason('Unknown error occurred');
                break;
        }
    }

    //
    useEffect(() => {
        (async () => {
            setInitializing(true);
            const dbRunCredits = await getRunCredits(nsCompanyId);
            switch (dbRunCredits.status) {
                case API_STATES.success:
                    setRunCredits(dbRunCredits.data);
                    setApiCallsOk(true);
                    setInitializing(false);
                    break;
                case API_STATES.error:
                    let error = dbRunCredits.error;
                    handleAPIError(error);
                    setApiCallsOk(false);
                    setInitializing(false);
                    break;
                default:
                    setRetrievalErrorReason('Unknown error retrieving run credits.');
                    setApiCallsOk(false);
                    setInitializing(false);
            }

            setInitializing(false);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nsCompanyId]);

    ////////////////////////////////////////////////
    return (
        <>
            {nsCompanyId !== 0 ? (
                <>
                    {initializing ? (
                        <p className={generalStyles.statusMessage}>Getting runcredits...</p>
                    ) : apiCallsOk ? (
                        <>
                            <div>
                                <h2 style={{color: 'black'}}>
                                    {companyName}
                                    {isMultiCompany && <span className={generalStyles.statusMessage}>(multi company)</span>}
                                </h2>

                                <strong>{companyAccessLevel}</strong>
                            </div>
                            <br />
                            <RunCreditsTable runCredits={runCredits} isEditable={isCsr || isSuperUser}/>
                        </>
                    ) : (
                        <p className={generalStyles.errorMessage}>Error: {retrievalErrorReason}</p>
                    )}
                </>
            ) : (
                <>
                    <br />
                    <span className={generalStyles.statusMessage}>Please select a company</span>
                </>
            )}
        </>
    );
}

export default RunCredits;
