import generalStyles from '@/components/General/Styles.module.scss';
import styles from '@/components/ModifyCSR/ModifyCSR.module.scss';
import React, { useState } from 'react';
import ConfirmDeletion from '@/components/ModifyCSR/ConfirmDeletion';

function DeleteCsr(props) {
    const email = props.email;
    const [deleteCsrAcct, setDeleteCsrAcct] = useState('false');

    return (
        <div data-test="delete-csr-component">
            <>
                <h3>Delete CSR</h3>
                <br />
                <div className={styles.delete_page}>
                    <div>
                        <button
                            data-test="delete-csr-btn"
                            className={generalStyles.submitButton}
                            disabled={deleteCsrAcct === 'true'}
                            onClick={() => setDeleteCsrAcct('true')}
                        >
                            Delete CSR
                        </button>
                    </div>
                    {deleteCsrAcct === 'true' ? (
                        <ConfirmDeletion
                            email={email}
                            onDelete={props.onDelete}
                            onCancel={() => setDeleteCsrAcct('cancel')}
                        />
                    ) : deleteCsrAcct === 'cancel' ? (
                        <label className={generalStyles.statusMessage}>CSR delete operation terminated</label>
                    ) : null}
                </div>
            </>
        </div>
    );
}
export default DeleteCsr;
