import React, { useEffect, useState } from 'react';
import { getOboActivity } from '@/services/api/csrdata.service';
import API_STATES from '@/constants/StateConstants';
import generalStyles from '@/components/General/Styles.module.scss';
import { handleApiError } from '@/components/General/HandleApiError';
import EmptyTable from '@/components/General/EmptyTable';
import format from 'date-fns/format';
import DataTable from '@/components/General/DataTable';
import { COLUMNS } from '@/components/ModifyCSR/OboTableColumns';

function OboUsage(props) {
    const email = props.email;
    const [oboActions, setOboActions] = useState([]);
    const [oboActivityApiStatus, setOboActivityApiStatus] = useState(API_STATES.none);
    const [oboActivityApiError, setOboActivityApiError] = useState('');

    function prettyDate(d) {
        if (!d) {
            return '';
        }
        const pretty = Date.parse(d);
        return format(pretty, 'yyyy-MM-dd HH:mm:ss');
    }

    async function getOboActionDetails() {
        setOboActivityApiStatus(API_STATES.loading);
        const result = await getOboActivity(email);
        switch (result.status) {
            case API_STATES.success:
                result.data.forEach((row) => {
                    row.when = prettyDate(row.when);
                });
                setOboActivityApiStatus(API_STATES.success);
                setOboActions(result.data);
                break;
            case API_STATES.error:
                const error = result.error;
                handleApiError(error, setOboActivityApiStatus, setOboActivityApiError, `No OBO actions found`);
                break;
            default:
                setOboActivityApiError('');
                setOboActivityApiStatus(API_STATES.none);
        }
    }

    useEffect(() => {
        (async () => {
            if (email !== null && email !== '') {
                getOboActionDetails();
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [email]);

    return (
        <>
            <h3>View OBO Activity</h3>
            <br />
            {oboActivityApiStatus === API_STATES.error ? (
                <p className={generalStyles.errorMessage}>Error: {oboActivityApiError}</p>
            ) : oboActivityApiStatus === API_STATES.loading ? (
                <p className={generalStyles.statusMessage}>Loading OBO Actions...</p>
            ) : oboActivityApiStatus === API_STATES.none ? (
                ''
            ) : oboActivityApiStatus === API_STATES.success ? (
                <>
                    {oboActions.length > 0 ? (
                        <DataTable data={oboActions} columns={COLUMNS}/>
                    ) : (
                        <EmptyTable
                            noValueDescription="No OBO actions found."
                            columns={['Action Time', 'Company', 'User', 'Action Type', 'Product Activity']}
                        />
                    )}
                </>
            ) : null}
        </>
    );
}

export default OboUsage;
