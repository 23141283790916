import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import calendarIcon from '@images/icons/calendar.svg';
import moment from 'moment';
import styles from '../HomepageNotification.module.scss';
import generalStyles from '@/components/General/Styles.module.scss';
import Select from 'react-select';
import * as Constant from '../Constants';
import API_STATES from '@/constants/StateConstants';
import * as CompanyApi from '@/services/api/company.service';
import * as MessageService from '@/services/api/message.service';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { getMessageTypeValue } from '../Utils';

function UserBasedNotificationForm({
    refresh,
    selectedNotification,
    edit = false,
    editSingleNotification = false,
    closeEdit,
}) {
    const [companyList, setCompanyList] = useState([]);
    const [userList, setUserList] = useState([]);
    const firstOption = [{ label: Constant.ALL, value: Constant.ALL }];
    const [selectedCompany, setSelectedCompany] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [endDate, setEndDate] = useState(edit ? moment(new Date(selectedNotification.endDate)) : null);
    const [selectedNotificationType, setSelectedNotificationType] = useState(
        edit ? getMessageTypeValue(selectedNotification.notificationType) : []
    );
    const [notificationMessage, setNotificationMessage] = useState(edit ? selectedNotification.message : '');
    const [notificationHeader, setNotificationHeader] = useState(edit ? selectedNotification.header : '');
    const [notificationLink, setNotificationLink] = useState(edit ? selectedNotification.hyperLink : '');
    const [companyApiState, setCompanyApiState] = useState(API_STATES.none);
    const [userApiState, setUserApiState] = useState(API_STATES.none);
    const [companyApiError, setCompanyApiError] = useState('');
    const [userApiError, setUserApiError] = useState('');
    const [isCompanyListChanged, setIsCompanyListChanged] = useState(false);
    const [selectedCompanyIds, setSelectedCompanyIds] = useState([]);
    const [createApiState, setCreateApiState] = useState(API_STATES.none);
    const [createApiError, setCreateApiError] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [skippedCompanies, setSkippedCompanies] = useState([]);
    const [skippedUsers, setSkippedUsers] = useState([]);

    const transformCompanies = (list) => {
        return (
            list?.map((company) => {
                return { label: company.name, value: company.id };
            }) ?? []
        );
    };

    const fetchCompanyList = async () => {
        try {
            setCompanyApiState(companyApiState === API_STATES.success ? API_STATES.refreshing : API_STATES.loading);

            let response = await CompanyApi.getAllCompanies();
            if (response.status === API_STATES.success) {
                const companies = response.data;
                setCompanyList(transformCompanies(companies));
                setCompanyApiState(API_STATES.success);
            } else {
                setCompanyApiError(response.error);
                setCompanyList([]);
                setCompanyApiState(API_STATES.error);
            }
            setUserList([]);
            setSelectedUsers([]);
            setSkippedUsers([]);
        } catch (e) {
            setCompanyApiError(e);
            setCompanyList([]);
            setCompanyApiState(API_STATES.error);
        }
    };

    if (companyList.length === 0 && companyApiState === API_STATES.none) {
        fetchCompanyList();
    }

    const selectCompany = (selectedValues) => {
        let companyIds;
        if (selectedValues.some((company) => company.value === 'All')) {
            companyIds = companyList.map((obj) => obj.value);
            setSelectedCompany(companyList);
        } else {
            companyIds = selectedValues.map((obj) => obj.value);
            setSelectedCompany(selectedValues);
            if (selectedValues?.length === 0) {
                setSkippedCompanies([]);
            }
        }
        setSelectedCompanyIds(companyIds);
        setIsCompanyListChanged(true);
        setSelectedUsers([]);
        setSkippedUsers([]);
        setUserApiState(API_STATES.none);
    };

    const selectUsers = (selectedValues) => {
        if (selectedValues.some((user) => user.value === 'All')) {
            setSelectedUsers(userList);
        } else {
            setSelectedUsers(selectedValues);
            if (selectedValues?.length === 0) {
                setSkippedUsers([]);
            }
        }
    };

    const onDateChange = (endDateValue) => {
        if (endDateValue && endDateValue !== '') setEndDate(endDateValue);
        else setEndDate(null);
    };

    const handleSelectedNotificationType = (selectedValue) => {
        setSelectedNotificationType(selectedValue);
    };

    const handleMessageChange = (e) => {
        setNotificationMessage(e.target.value);
    };

    const handleHeaderChange = (e) => {
        setNotificationHeader(e.target.value);
    };

    const handleLinkChange = (e) => {
        setNotificationLink(e.target.value);
    };

    const transformUsers = (list) => {
        return (
            list?.map((user) => {
                return {
                    label: user.first_name + ' ' + user.last_name + ' (' + user.email + ')',
                    value: { id: user.id, email: user.email },
                };
            }) ?? []
        );
    };

    const userInputChange = (inputValue, actionMeta) => {
        if (actionMeta?.action === 'input-change' && userList?.length !== 0 && inputValue.indexOf('|') !== -1) {
            const users = inputValue.split('|');
            let res = userList.filter((item) => users.includes(item.label));
            let userNames = res.map(({ label }) => label);
            let skippedValues = users.filter((item) => !userNames.includes(item));

            if (res?.length !== 0) {
                let list = selectedUsers;
                for (let value of res) {
                    if (!selectedUsers?.includes(value)) {
                        list.push(value);
                    }
                }
                if (list?.length !== 0) {
                    selectUsers(list);
                }
                let skippedUsersList = skippedUsers;
                for (let value of skippedValues) {
                    if (!skippedUsers?.includes(value)) {
                        skippedUsersList.push(value);
                    }
                }
                if (skippedUsersList?.length !== 0) {
                    setSkippedUsers(skippedUsersList);
                }
            }
        }
    };

    const userSelectClicked = async () => {
        try {
            if (selectedCompany && selectedCompany.length > 0 && isCompanyListChanged) {
                setUserList([]);
                setIsCompanyListChanged(false);
                setUserApiState(API_STATES.loading);

                let response = await CompanyApi.getAllUsers(selectedCompanyIds);
                if (response.status === API_STATES.success) {
                    const users = response.data;
                    setUserList(transformUsers(users));
                    setUserApiState(API_STATES.success);
                } else {
                    setUserApiError(response.error);
                    setUserList([]);
                    setUserApiState(API_STATES.error);
                }
            }
        } catch (e) {
            setUserApiError(e);
            setUserList([]);
            setUserApiState(API_STATES.error);
        }
    };

    const clearSelections = () => {
        setSelectedCompany([]);
        setSelectedUsers([]);
        setEndDate(null);
        setSelectedNotificationType([]);
        setNotificationHeader('');
        setNotificationLink('');
        setNotificationMessage('');
        setSkippedCompanies([]);
        setSkippedUsers([]);
    };

    async function createRequestBody() {
        const expirationDate = endDate ? moment(endDate).endOf('day').format('YYYY/MM/DD HH:mm:ss') : null;
        const messageType =
            selectedNotificationType?.value ??
            selectedNotificationType?.[0]?.value ??
            selectedNotification?.notificationType ??
            Constant.TYPE_OF_NOTIFICATION[1].value;

        if (edit && !editSingleNotification) {
            return {
                notificationId: selectedNotification.notificationId,
                expirationDtm: expirationDate,
                message: { message: notificationMessage, header: notificationHeader, hyperLink: notificationLink },
                messageType: messageType,
                triggerType: Constant.MANUAL,
                priority: Constant.PRIORITY.LOW,
                messageCreationType: Constant.MESSAGE_TYPE.USER_BASED,
            };
        } else if (edit && editSingleNotification) {
            return {
                id: selectedNotification.id,
                recepientId: selectedNotification.recepientId,
                notificationId: selectedNotification.notificationId,
                expirationDtm: expirationDate,
                message: { message: notificationMessage, header: notificationHeader, hyperLink: notificationLink },
                messageType: messageType,
                triggerType: Constant.MANUAL,
                priority: Constant.PRIORITY.LOW,
                messageCreationType: Constant.MESSAGE_TYPE.USER_BASED,
            };
        } else {
            return {
                selectedUserIds: selectedUsers.map((obj) => obj.value.id),
                selectedCompanyIds: selectedCompanyIds,
                expirationDtm: expirationDate,
                message: { message: notificationMessage, header: notificationHeader, hyperLink: notificationLink },
                messageType: selectedNotificationType.value,
                triggerType: Constant.MANUAL,
                priority: Constant.PRIORITY.LOW,
                messageCreationType: Constant.MESSAGE_TYPE.USER_BASED,
            };
        }
    }

    const sendNotification = async () => {
        try {
            setOpenModal(false);

            clearSelections();
            setCreateApiState(API_STATES.loading);
            let requestBody = await createRequestBody();
            let response;
            if (edit && !editSingleNotification) {
                response = await MessageService.editAllMessages(requestBody);
            } else if (edit && editSingleNotification) {
                response = await MessageService.editSingleUserMessage(requestBody);
            } else {
                response = await MessageService.createConsumerMessage(requestBody);
            }
            if (response.status === API_STATES.success) {
                setCreateApiState(API_STATES.success);
                refresh(true);
                if (edit) {
                    closeEdit();
                }
            } else {
                setCreateApiError(response.error);
                setCreateApiState(API_STATES.error);
            }
        } catch (e) {
            setCreateApiError(e);
            setCreateApiState(API_STATES.error);
        }
    };

    const displayPreview = () => {
        setOpenModal(true);
    };

    const validateSelection = () => {
        if (edit) {
            return !(
                selectedNotificationType?.length !== 0 &&
                notificationHeader &&
                notificationHeader?.trim() !== '' &&
                notificationMessage &&
                notificationMessage?.trim() !== '' &&
                endDate
            );
        } else {
            return !(
                selectedCompany?.length !== 0 &&
                selectedUsers?.length !== 0 &&
                selectedNotificationType?.length !== 0 &&
                notificationHeader &&
                notificationHeader.trim() !== '' &&
                notificationMessage &&
                notificationMessage.trim() !== '' &&
                endDate
            );
        }
    };

    const companyInputChange = (inputValue, actionMeta) => {
        if (actionMeta?.action === 'input-change' && companyList?.length !== 0 && inputValue.indexOf('|') !== -1) {
            const companies = inputValue.split('|');
            let res = companyList.filter((item) => companies.includes(item.label));
            let companyNames = res.map(({ label }) => label);
            let skippedValues = companies.filter((item) => !companyNames.includes(item));

            if (res?.length !== 0) {
                let list = selectedCompany;
                for (let value of res) {
                    if (!selectedCompany?.includes(value)) {
                        list.push(value);
                    }
                }
                if (list?.length !== 0) {
                    selectCompany(list);
                }
                let skippedCompaniesList = skippedCompanies;
                for (let value of skippedValues) {
                    if (!skippedCompanies?.includes(value)) {
                        skippedCompaniesList.push(value);
                    }
                }
                if (skippedCompaniesList?.length !== 0) {
                    setSkippedCompanies(skippedCompaniesList);
                }
            }
        }
    };

    const buttonLabel = useState(edit ? 'Edit' : 'Submit');
    return (
        <>
            {!edit && (
                <>
                    {' '}
                    <h1>User Based Notification</h1>
                    <hr />
                    {/* Company list fetch status */}
                    {companyApiState === API_STATES.loading && (
                        <span className={generalStyles.statusMessage}>Fetching Companies...</span>
                    )}
                    {companyApiState === API_STATES.error && (
                        <span className={generalStyles.errorMessage}>Failed: {companyApiError}</span>
                    )}
                    {/* User list fetch status */}
                    {userApiState === API_STATES.loading && (
                        <span className={generalStyles.statusMessage}>Fetching Users...</span>
                    )}
                    {userApiState === API_STATES.error && (
                        <span className={generalStyles.errorMessage}>Failed: {userApiError}</span>
                    )}
                    <br />
                </>
            )}

            {edit && (
                <>
                    {' '}
                    <h1>Edit User Based Notification</h1>
                    <hr />
                    <br />
                </>
            )}

            {(companyList.length > 0 || edit) && (
                <>
                    <div className={styles.selectionContainer}>
                        {/* Company select */}
                        <div className={styles.selectInputWrapper}>
                            <label className={styles.formFieldLabel}>Select Companies:</label>
                            <span className={styles.formField}>
                                <Select
                                    classNamePrefix={'reactSelect'}
                                    options={firstOption.concat(companyList)}
                                    closeMenuOnSelect={false}
                                    isMulti
                                    onChange={(selectedCompanyValue) => selectCompany(selectedCompanyValue)}
                                    value={selectedCompany}
                                    placeholder="Companies"
                                    styles={Constant.SELECT_STYLES}
                                    isDisabled={edit}
                                    onInputChange={companyInputChange}
                                />
                            </span>
                        </div>

                        {/* User select */}
                        <div className={styles.selectInputWrapper}>
                            <label className={styles.formFieldLabel}>Select Users:</label>
                            <span className={styles.formField}>
                                <Select
                                    classNamePrefix={'react-select'}
                                    options={userList.length > 0 ? firstOption.concat(userList) : []}
                                    closeMenuOnSelect={false}
                                    isMulti
                                    onChange={(selectedUserValue) => selectUsers(selectedUserValue)}
                                    value={selectedUsers}
                                    placeholder="Users"
                                    onFocus={userSelectClicked}
                                    styles={Constant.SELECT_STYLES}
                                    onInputChange={userInputChange}
                                    isDisabled={edit || selectedCompany?.length === 0}
                                />
                            </span>
                        </div>

                        {/* Message expiration date selection */}
                        <div className={styles.selectInputWrapper}>
                            <label className={styles.formLabel}>Select Expiration Date:</label>

                            <div className={styles.datepickerContainer}>
                                <DatePicker
                                    name="endDate"
                                    placeholderText="End Date"
                                    shouldCloseOnSelect={true}
                                    className={styles.datepickerInput}
                                    fixedHeight
                                    closeOnScroll={true}
                                    dateFormat="MMM d, y"
                                    selected={endDate ? endDate.toDate() : null}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    tabIndex={1}
                                    onChange={(date) => {
                                        onDateChange(date ? moment(date) : null);
                                    }}
                                    minDate={moment().toDate()}
                                    onKeyDown={(e) => {
                                        e.preventDefault();
                                    }}
                                />

                                <img src={calendarIcon} alt="calendar icon" />
                            </div>
                        </div>
                    </div>

                    <br />

                    <div className={styles.selectionContainer}>
                        {/* Notification type selection */}
                        <div className={styles.selectInputWrapper}>
                            <label className={styles.formLabel}>Notification Type:</label>
                            <span className={styles.formField}>
                                <Select
                                    classNamePrefix={'react-select'}
                                    options={Constant.TYPE_OF_NOTIFICATION}
                                    onChange={handleSelectedNotificationType}
                                    value={selectedNotificationType}
                                    placeholder={'Notification Type'}
                                />
                            </span>
                        </div>

                        {/* input for message header/title */}
                        <div className={styles.selectInputWrapper}>
                            <label className={styles.formLabel}>Notification Header:</label>
                            <span className={styles.formField}>
                                <textarea
                                    onChange={(e) => handleHeaderChange(e)}
                                    value={notificationHeader}
                                    className={generalStyles.textareaInput}
                                    placeholder={'Notification Header'}
                                />
                            </span>
                        </div>

                        {/* input for message text */}
                        <div className={styles.selectInputWrapper}>
                            <label className={styles.formLabel}>Message:</label>
                            <span className={styles.formField}>
                                <textarea
                                    onChange={(e) => handleMessageChange(e)}
                                    value={notificationMessage}
                                    className={generalStyles.textareaInput}
                                    placeholder={'Notification Message'}
                                />
                            </span>
                        </div>
                    </div>

                    <br />

                    <div className={styles.selectionContainer}>
                        {/* input for adding URL for performing any action on the message */}
                        <div className={styles.selectInputWrapper}>
                            <label className={styles.formLabel}>Action:</label>
                            <span className={styles.formField}>
                                <textarea
                                    onChange={(e) => handleLinkChange(e)}
                                    value={notificationLink}
                                    className={generalStyles.textareaInput}
                                    placeholder={'Link (Optional) - Link for redirection'}
                                />
                            </span>
                        </div>

                        {/* button to submit all form selections to generate the message and send notification to selected users */}
                        <div>
                            <button
                                className={`${generalStyles.submitButton}`}
                                name={'submit'}
                                onClick={displayPreview}
                                disabled={validateSelection()}
                            >
                                Submit
                            </button>
                            <div>
                                {createApiState === API_STATES.loading && (
                                    <span className={generalStyles.statusMessage}>
                                        Triggering User Based Notification...
                                    </span>
                                )}
                                {createApiState === API_STATES.error && (
                                    <span className={generalStyles.errorMessage}>Failed: {createApiError}</span>
                                )}
                            </div>
                        </div>
                    </div>

                    <Popup
                        open={openModal}
                        onClose={() => {
                            setOpenModal(false);
                        }}
                        modal
                    >
                        {() => (
                            <div className={styles.warningDialog}>
                                <h2>Preview</h2>
                                <div className={styles.messageContainer}>
                                    <div>
                                        {' '}
                                        <b>{notificationHeader}</b>: {notificationMessage}
                                    </div>
                                    <br></br>

                                    {notificationLink !== undefined &&
                                        notificationLink !== null &&
                                        notificationLink.trim() !== '' && (
                                            <>
                                                <div>
                                                    <b>Link</b>: {notificationLink}
                                                </div>
                                                <br></br>
                                            </>
                                        )}
                                    {!edit && (
                                        <div>
                                            <b>Note</b>: This message will be sent to {selectedUsers.length} accounts
                                        </div>
                                    )}

                                    {edit && !editSingleNotification && (
                                        <div>
                                            <b>Note</b>: This Message will be modified for all notified users.
                                        </div>
                                    )}
                                    {edit && editSingleNotification && (
                                        <div>
                                            <b>Note</b>: This Message will be modified for selected single user.
                                        </div>
                                    )}
                                </div>

                                {(skippedCompanies?.length !== 0 || skippedUsers?.length !== 0) && (
                                    <>
                                        {' '}
                                        <div>
                                            <b>Warning</b>:
                                            {skippedCompanies?.length !== 0 && (
                                                <span>
                                                    Companies <b>{skippedCompanies.join(', ')} </b>
                                                </span>
                                            )}
                                            {skippedCompanies?.length !== 0 && skippedUsers?.length !== 0 && (
                                                <span>and </span>
                                            )}
                                            {skippedUsers?.length !== 0 && (
                                                <span>
                                                    Users <b>{skippedUsers.join(', ')} </b>
                                                </span>
                                            )}
                                            will not be notified.
                                        </div>
                                        <br />
                                    </>
                                )}

                                <div>
                                    <button
                                        className={`${generalStyles.cancelOperationButton} ${styles.btnWidth}`}
                                        onClick={() => {
                                            sendNotification();
                                        }}
                                    >
                                        {buttonLabel}
                                    </button>

                                    <button
                                        className={`${generalStyles.confirmOperationButton} ${styles.btnWidth}`}
                                        onClick={() => {
                                            setOpenModal(false);
                                        }}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        )}
                    </Popup>
                </>
            )}
        </>
    );
}

export default UserBasedNotificationForm;
