import React, { useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import styles from '@/components/MultiYearContractCompanySetup/MultiYearContractCompanySetup.module.scss';
import { companyDetailsColumns } from './Columns';
import { useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import generalStyles from '@/components/General/Styles.module.scss';
import calendarIcon from '@images/icons/calendar.svg';
import moment from 'moment';
import Select from 'react-select';
import { getItemsData, purchaseProducts, getPaymentTermOptions } from '@/services/api/company.service';
import { getChildSkuDetails } from '@/services/api/product.service';
import TextInput from '@/components/General/TextInput';
import Popup from 'reactjs-popup';
import { Box, IconButton } from '@mui/material';
import { calculateTermInMonths } from '../utils/utils.jsx'
import 'reactjs-popup/dist/index.css';
import { DebounceInput } from 'react-debounce-input';
import API_STATES from '@/constants/StateConstants';
import editIcon from '@images/icons/EditIcon.svg';
import deleteIcon from '@images/icons/deleteIcon.svg';
import plusIcon from '@images/icons/plus-icon.svg'
import { handleApiError } from '@/components/General/HandleApiError';
import AddNewItems from './AddNewItems';
import { billingFrequencyOptions } from '@/constants/globalVars'

const MyaAccountSetup = ({ isRenewClicked, companyDetails }) => {

    const [companyItems, setCompanyItems] = useState([
        {
            sku: '',
            product_name: '',
            product_type: '',
            quantity: 0,
            monthly_price: 0,
            annual_price: 0,
        }
    ])

    const [contractStartDate, setContractStartDate] = useState(moment(companyDetails?.activeSubscriptionEndDate).add(1, 'd'));
    const [contractEndDate, setContractEndDate] = useState(
        moment(contractStartDate)
            .add(1, 'y')
            .subtract(1, 'd')
    );
    const [trueContractEndDate, setTrueContractEndDate] = useState(
        moment()
            .add(1, 'y')
            .subtract(1, 'd')
    );
    const [upliftPercent, setUpliftPercent] = useState('');
    const [dynamicsDealNum, setDynamicsDealNum] = useState('');
    const [selectedBillingFrequencyOption, setSelectedBillingFrequencyOption] = useState({});
    const [isMultiYearAgreement, setMultiYearAgreement] = useState(false);
    const [percentValid, setPercentValid] = useState(false);
    const [openModal, setOpenModal] = useState(false)
    const [skuData, setSkuData] = useState({})
    const [termValue, setTermValue] = useState(0)
    const [companyItemsApiStatus, setCompanyItemsApiStatus] = useState(API_STATES.none)
    const [companyItemsApiError, setCompanyItemsApiError] = useState('')
    const [purchaseOrderApiState, setPurchaseOrderApiState] = useState(API_STATES.none)
    const [purchaseOrderApiError, setPurchaseOrderApiError] = useState('')
    const [rowIndex, setRowIndex] = useState('')
    const [openAddNewItemModal, setOpenAddNewItemModal] = useState()
    const [addNewItemType, setAddNewItemType] = useState()
    const [addonsData, setAddonsData] = useState()
    const [childSkuApiStatus, setChildSkuApiStatus] = useState(API_STATES.none);
    const [childSkuApiError, setChildSkuApiError] = useState('');
    const [enableEdit, setEnableEdit] = useState(false)
    const [paymentTermOptionsApiState, setPaymentTermOptionsApiState] = useState(API_STATES.none);
    const [paymentTermOptionsApiError, setPaymentTermOptionsApiError] = useState('');
    const [selectedPaymentTermsOption, setSelectedPaymentTermsOption] = useState({label: 'Net 30', value: 2})
    const [paymentTermOptions, setPaymentTermOptions] = useState([])

    async function loadCompanyItemsData(termValue) {
        setCompanyItemsApiStatus(API_STATES.loading);
        const response = await getItemsData(companyDetails?.contractId);
        switch (response?.status) {
            case API_STATES.success:
                setCompanyItemsApiStatus(API_STATES.success);
                if (response?.data && termValue) {
                    let result = response?.data?.map((item) => {
                        const annualPrice = Number(item?.annual_price / item?.quantity).toFixed(4)
                        if (item?.addons) {
                            let { addons: subRows, ...rest } = item;
                            subRows = subRows?.map((li) => {
                                const subrowsAnnualPrice = Number(li?.annual_price / li?.quantity).toFixed(4)
                                const calsubRowsMonthlyPrice =
                                    item?.product_type !== 'report'
                                        ? {
                                            monthly_price: termValue
                                                ? calculatePrice(subrowsAnnualPrice / termValue)
                                                : '',
                                            disableMonthlyPrice: false,
                                            ...li,
                                        }
                                        : { disableMonthlyPrice: true, ...li };
                                return { ...calsubRowsMonthlyPrice, annual_price: subrowsAnnualPrice, item_total: Number(subrowsAnnualPrice * li?.quantity)?.toFixed(2)};
                            });
                            const calMonthlyPrice =
                                item?.product_type !== 'report'
                                    ? {
                                        subRows,
                                        monthly_price: termValue
                                            ? calculatePrice(annualPrice / termValue)
                                            : '',
                                        disableMonthlyPrice: false,
                                        ...rest,
                                    }
                                    : { subRows, disableMonthlyPrice: true, ...rest };
                            return { ...calMonthlyPrice, annual_price: annualPrice, item_total: Number(annualPrice * item?.quantity)?.toFixed(2) };
                        } else {
                            const monthlyPrice = termValue ? calculatePrice(annualPrice / termValue) : ''
                            const calResult = item?.product_type !== 'report' ?
                                { ...item, monthly_price: monthlyPrice, annual_price: annualPrice, item_total: Number(annualPrice * item?.quantity)?.toFixed(2) } :
                                { ...item, annual_price: annualPrice, item_total: Number(annualPrice * item?.quantity)?.toFixed(2) }
                            return calResult
                        }
                    });
                    setCompanyItems(result);
                }
                break;
            case API_STATES.error:
                let error = response.error;
                handleApiError(error, setCompanyItemsApiError, setCompanyItemsApiStatus);
                break;
            default:
                setCompanyItemsApiStatus(API_STATES.none);
                setCompanyItemsApiError('');
        }
    }

    useEffect(() => {
        loadCompanyItemsData(termValue)
        setTrueContractEndDate(moment(companyDetails?.trueContractEndDate))
    }, [companyDetails, termValue])

    useEffect(() => {
        (async () => {
            if (upliftPercent !== '') {
                let isnum = /^[+-]?\d+(\.\d+)?$/.test(upliftPercent);
                setPercentValid(isnum);
            } else {
                setPercentValid(true);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [upliftPercent]);

    function calculatePrice(value) {
        return value ? (value * (1 + Number(upliftPercent) / 100)).toFixed(4) : ''
    }

    useEffect(() => {
        const contractTerm = calculateTermInMonths(new Date(contractStartDate.format("MM/DD/YYYY")), new Date(contractEndDate.format("MM/DD/YYYY")))
        setTermValue(contractTerm);
    }, [contractStartDate, contractEndDate]);

    function increasePrices(termValue) {
        const list = companyItems?.map((item) => {
            let monthlyPrice = item?.monthly_price
            let annualPrice = calculatePrice(Number(parseFloat(item?.annual_price).toFixed(4)))
            if (item?.subRows) {
                const result = item.subRows?.map((li) => {
                    const addonMonthlyPrice = li?.monthly_price
                    const addonAnnualPrice = calculatePrice(Number(parseFloat(li?.annual_price).toFixed(4)))
                    return {
                        ...li,
                        monthly_price: calculatePrice(addonMonthlyPrice),
                        annual_price: addonAnnualPrice,
                        item_total: (addonAnnualPrice * li?.quantity)?.toFixed(2)
                    }
                })
                return {
                    ...item,
                    subRows: result,
                    monthly_price: calculatePrice(monthlyPrice),
                    annual_price: annualPrice,
                    item_total: (annualPrice * item?.quantity)?.toFixed(2)
                }
            } else {
                return {
                    ...item,
                    monthly_price: calculatePrice(monthlyPrice),
                    annual_price: annualPrice,
                    item_total: (annualPrice * item?.quantity)?.toFixed(2)
                }
            }
        })
        setCompanyItems(list)
    }

    //edit functionality starts here

    function onPriceChange(target, row) {
        const inputValue = target.value
        if (target.name === "monthlyPrice") {
            setSkuData((sku) => ({
                ...sku,
                "monthly_price": inputValue,
                "annual_price": Number(parseFloat(inputValue * termValue).toFixed(4))
            }))
        } else {
            setSkuData((sku) => ({
                ...sku,
                "annual_price": inputValue
            }))
        }

    }

    function onQuantityChange(value, row) {
        setSkuData((sku) => ({
            ...sku,
            quantity: value,
        }))
    }

    function calculateTotalSum(data) {
        let totalSum = 0;
        data?.forEach(item => {
            let temp = Number((item?.annual_price * item?.quantity)?.toFixed(2))
            totalSum += temp;
            if (item?.subRows) {
                //adding this recursion logic to calculate include annual_price and quantity of addons
                totalSum += calculateTotalSum(item?.subRows);
            }
        });
        return totalSum;

    }

    function onEdit(row) {
        setOpenModal(!openModal)
        setRowIndex(row)
        setSkuData(row)
        setEnableEdit(false)

    }

    function onAdd(data, row) {
        if (addonsData) {
            const result = data?.map((item) => {
                if (item?.sku === addonsData?.sku) {
                    const oldData = [...item?.subRows, {
                        sku: skuData?.sku,
                        product_name: skuData?.product_name,
                        product_type: skuData?.product_type,
                        monthly_price: skuData?.monthly_price,
                        annual_price: skuData?.annual_price,
                        quantity: skuData?.quantity,
                        item_total: Number(skuData?.annual_price * skuData?.quantity)?.toFixed(2),
                    }]
                    item["subRows"] = oldData;
                    return item
                }
                return item
            })
            setAddonsData()
            setOpenModal(false);
            return result;
        } else {
            return data?.map((item) => {
                if (item?.sku === skuData?.sku) {
                    setOpenModal(false)
                    const annualPrice = skuData?.disableMonthlyPrice ? (skuData?.annual_price) : (skuData?.monthly_price * termValue)
                    return {
                        ...item,
                        quantity: skuData?.quantity,
                        annual_price: parseFloat(annualPrice).toFixed(4),
                        monthly_price: skuData?.disableMonthlyPrice ? '' : parseFloat(skuData?.monthly_price),
                        item_total: (parseFloat(annualPrice).toFixed(4) * skuData?.quantity).toFixed(2)
                    }
                } else if (item?.subRows) {
                    return {
                        ...item,
                        subRows: onAdd(item?.subRows),
                    }
                }
                setOpenModal(false);
                return item;
            })
        }
    }

    async function onPlaceOrder() {
        setPurchaseOrderApiState(API_STATES.loading)
        const newItems = companyItems?.map((item) => ({
            nsCatalogItemId: item.nsCatalogItemId,
            sku: item.sku,
            quantity: Number(item.quantity),
            ...(item?.monthly_price ? { rate: item?.monthly_price } : { price: item?.annual_price }),
            ttl: item?.product_name,
            addons: item?.subRows?.length ? item?.subRows?.map((addon) => ({
                nsCatalogItemId: addon.nsCatalogItemId,
                sku: addon.sku,
                quantity: Number(addon.quantity),
                ...(addon?.monthly_price ? { rate: addon?.monthly_price } : { price: addon?.annual_price })
            })) : []
        }))
        const result = await purchaseProducts(companyDetails?.companyId, newItems, {
            createNew: true,
            startDtm: contractStartDate,
            endDtm: contractEndDate,
            billingFrequency: selectedBillingFrequencyOption?.value,
            paymentTerms: selectedPaymentTermsOption.value,
            dynamicsDealNumber: dynamicsDealNum,
            isEverGreen: false,
            isMultiYearAgreement: isMultiYearAgreement,
            trueContractEndDtm: trueContractEndDate,
            isAddon: false
        })
        switch (result.status) {
            case API_STATES.success:
                setPurchaseOrderApiState(API_STATES.success);
                setPurchaseOrderApiState(API_STATES.success);
                break;
            case API_STATES.error:
                let error = result.error;
                handleApiError(error, setPurchaseOrderApiState, setPurchaseOrderApiError);
                break;
            default:
                setPurchaseOrderApiError('');
                setPurchaseOrderApiState(API_STATES.none);
        }
    }

    useEffect(()=>{
        (async()=>{
            const response  = await getPaymentTermOptions()
            if (response.status === API_STATES.success) {
                setPaymentTermOptionsApiState(API_STATES.success)
                setPaymentTermOptions(Object.entries(response.data).map(([label, id])=>({ label: label, value: id})))
                setTimeout(() => setPaymentTermOptionsApiState(API_STATES.none), 1000);
            } else {
                handleApiError(response.error, setPaymentTermOptionsApiState, setPaymentTermOptionsApiError);
                setTimeout(() => setPaymentTermOptionsApiState(API_STATES.none), 6000)
            }
        })()
    }, [])

    function filterDate(date) {
        return Boolean(
            new Date(date) >=
            new Date(moment(contractStartDate).subtract(2, 'days')).setFullYear(
                new Date(contractStartDate).getFullYear() + 1
            )
        );
    }

    function onDelteItem(row) {
        setCompanyItems(companyItems?.filter((item) => {
            item["subRows"] = item?.subRows.filter((li) => li?.sku !== row?.sku)
            return item?.sku !== row?.sku
        }))
    }

    function onAddAddons(addonsData) {
        setSkuData({})
        setOpenModal(true)
        setAddonsData(addonsData)
        setEnableEdit(true)
    }

    function onDebounceInputChange(value, rowIndex) {
        setChildSkuApiStatus(API_STATES.none);
        setChildSkuApiError('');
        if (value !== null && value !== 0 && value !== '') {
            if (companyItems.some((product) => (product.sku === value || product?.subRows?.some((li) => li.sku === value)))) {
                setChildSkuApiStatus(API_STATES.error);
                setChildSkuApiError('Duplicate sku not allowed in selection');
            } else {
                getChildSkuData(value);
            }
        }
    }

    async function getChildSkuData(childSku) {
        setChildSkuApiStatus(API_STATES.loading);
        const result = await getChildSkuDetails(childSku);
        switch (result.status) {
            case API_STATES.success:
                if (result.data) {
                    if (!result.data?.type) {
                        const newData = {
                            ...result.data,
                            type: 'unknown'
                        }
                        setChildSkuApiStatus(API_STATES.success);
                        setSkuData((sku) => ({
                            sku: newData?.sku,
                            product_name: newData?.title,
                            product_type: newData?.type,
                        }))
                    } else if (!result.data?.type.includes('addon')) {
                        setChildSkuApiStatus(API_STATES.error);
                        setChildSkuApiError('Not an addon');
                    } else {
                        setChildSkuApiStatus(API_STATES.success);
                        setSkuData((sku) => ({
                            sku: result.data?.sku,
                            product_name: result.data?.title,
                            product_type: result.data?.type,
                        }))
                    }
                } else {
                    setChildSkuApiStatus(API_STATES.error);
                    setChildSkuApiError('Invalid sku entry');
                }
                break;
            case API_STATES.error:
                let error = result.error;
                handleApiError(error, setChildSkuApiStatus, setChildSkuApiError);
                break;
            default:
                setChildSkuApiError('');
                setChildSkuApiStatus(API_STATES.none);
        }
    }

    function onAddNewItem(itemType) {
        setOpenAddNewItemModal(true);
        setAddNewItemType(itemType)
    }

    function checkValidity() {
        return Boolean(!companyItems?.length ||
            companyItems?.filter((item) => item?.product_type === 'subscription')?.length > 1 ||
            !(companyItems?.some((item) => item?.product_type === 'subscription'))
        )
    }

    return (
        <>
            {isRenewClicked ?
                <>
                    <h2 className={styles.heading}>{companyDetails?.companyName} ({companyDetails?.companyId})</h2>
                    <br />
                    <div className={styles.expiryDateTimeForm}>
                        <span><b>Contract Start Date: </b></span>
                        <div className={styles.date}>
                            <DatePicker
                                name="contractStartDate"
                                placeholderText="mm/dd/yyyy"
                                shouldCloseOnSelect={true}
                                fixedHeight
                                closeOnScroll={true}
                                minDate={new Date(2020, 0, 1, 0, 0, 0)}
                                dateFormat="MMM d, y"
                                selected={contractStartDate.toDate()}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                tabIndex={1}
                                onChange={(date) => {
                                    setContractStartDate(moment(date));
                                    setContractEndDate(moment(date).add(1, 'y').subtract(1, 'd'))
                                }}
                            />
                            <img src={calendarIcon} alt="calendar icon" />
                        </div>

                        <span><b>Contract End Date: </b></span>
                        <div className={styles.date}>
                            <DatePicker
                                name="contractEndDate"
                                placeholderText="mm/dd/yyyy"
                                shouldCloseOnSelect={true}
                                fixedHeight
                                closeOnScroll={true}
                                maxDate={new Date(2030, 11, 31, 23, 59, 59)}
                                minDate={new Date()}
                                dateFormat="MMM d, y"
                                selected={contractEndDate.toDate()}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                tabIndex={1}
                                filterDate={(date) => filterDate(date)}
                                onChange={(date) => {
                                    setContractEndDate(moment(date));
                                }}
                            />
                            <img src={calendarIcon} alt="calendar icon" />
                        </div>

                        <span><b>True Contract End Date: </b></span>
                        <div className={styles.date}>
                            <DatePicker
                                name="trueContractEndDate"
                                placeholderText="mm/dd/yyyy"
                                shouldCloseOnSelect={true}
                                fixedHeight
                                closeOnScroll={true}
                                minDate={new Date()}
                                dateFormat="MMM d, y"
                                selected={trueContractEndDate.toDate() ?? moment(companyDetails?.trueContractEndDate)}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                tabIndex={1}
                                filterDate={(date) => Boolean(new Date(date) >= new Date(contractEndDate))}
                                onChange={(date) => {
                                    setTrueContractEndDate(moment(date));
                                }}
                            />
                            <img src={calendarIcon} alt="calendar icon" />
                        </div>
                    </div>
                    <div className={styles.expiryDateTimeForm}>
                        <div className={styles.formItem}>
                            <TextInput
                                type="text"
                                label="Uplift %:"
                                placeholder="Uplift %"
                                value={upliftPercent}
                                onInputChange={(event) => setUpliftPercent(event.target.value)}
                            />
                            {!percentValid && <span className={generalStyles.errorMessage}>Invalid percent value!</span>}
                        </div>
                        <button className={generalStyles.submitButton} onClick={() => increasePrices(termValue)} disabled={!upliftPercent}>Increase Prices</button>
                    </div>

                    <div className={generalStyles.formItem}>
                        <TextInput
                            type="text"
                            label="Dynamics Deal Number:"
                            value={dynamicsDealNum}
                            onInputChange={(event) => setDynamicsDealNum(event.target.value)}
                            required={false}
                        />
                    </div>

                    <div className={generalStyles['input-wrapper']}>
                        <label>Billing Frequency:</label>
                        <span className={generalStyles.formField}>
                            <Select
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 4,
                                    colors: {
                                        ...theme.colors,
                                        text: 'black',
                                        primary25: '#cfd1d2',
                                        primary: 'hsl(120, 100%, 47%)',
                                        neutral0: 'white'
                                    }
                                })}
                                onChange={(option)=>setSelectedBillingFrequencyOption(option)}
                                options={billingFrequencyOptions}
                                value={selectedBillingFrequencyOption}
                                isDisabled={false}
                            />
                        </span>
                    </div>
                    {paymentTermOptionsApiState === API_STATES.loading ? (
                        <p className={generalStyles.statusMessage}>loading payment terms options...</p> 
                    ) : ( paymentTermOptionsApiState === API_STATES.error ? (
                        <p className={generalStyles.errorMessage}>{paymentTermOptionsApiError}</p>
                        ) : (
                            <div className={generalStyles['input-wrapper']}>
                                <label>Payment Terms:</label>
                                <span className={generalStyles.formField}>
                                    <Select
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 4,
                                            colors: {
                                                ...theme.colors,
                                                text: 'black',
                                                primary25: '#cfd1d2',
                                                primary: 'hsl(120, 100%, 47%)',
                                                neutral0: 'white'
                                            }
                                        })}
                                        onChange={(option)=>setSelectedPaymentTermsOption(option)}
                                        options={paymentTermOptions}
                                        value={selectedPaymentTermsOption}
                                        isDisabled={false}
                                    />
                                </span>
                            </div>
                        )
                    )}
                    <div className={generalStyles['input-wrapper']}>
                        <label>Multi Year Agreement: </label>
                        <input
                            type="checkbox"
                            checked={isMultiYearAgreement}
                            onChange={(e) => setMultiYearAgreement(e.target.checked)}
                        />
                    </div>
                    <button className={`${generalStyles.submitButton} ${styles.addItemsButton}`} onClick={() => onAddNewItem('subscription')}> Add Subscription</button>
                    <button className={`${generalStyles.submitButton} ${styles.addItemsButton}`} onClick={() => onAddNewItem('bundle')}>Add Bundle</button>
                    <br />
                    {companyItemsApiStatus === API_STATES.loading ? (
                        <span className={generalStyles.statusMessage}>Loading Company Items...</span>
                    ) : companyItemsApiStatus === API_STATES.error ? (
                        <span className={generalStyles.statusMessage}>{companyItemsApiError}</span>
                    ) :
                        <div>
                            <MaterialReactTable
                                columns={companyDetailsColumns}
                                data={companyItems ?? []}
                                enableExpanding
                                enableBottomToolbar={false}
                                initialState={{ expanded: true }}
                                enableSorting
                                enableColumnFilters={true}
                                enableColumnActions={false}
                                enableTopToolbar={false}
                                enablePagination={false}
                                enableRowActions
                                    renderRowActions={({ row }) => {
                                        return [
                                            <div className={styles.iconContainer}>
                                                <span onClick={() => onEdit(row?.original)} className={styles.expandActionContainer}> <img src={editIcon} alt="edit" className={styles.btnWidth} /></span>
                                                {row?.original?.product_type !== "report" && row?.original?.hasOwnProperty("subRows") &&
                                                    <span onClick={() => onAddAddons(row?.original)} name={"add addons"}>
                                                        <img src={plusIcon} alt="add" className={styles.btnWidth} />
                                                    </span>
                                                }
                                                <span onClick={() => onDelteItem(row?.original)} ><img src={deleteIcon} alt="delete" className={styles.btnWidth} /></span>
                                            </div>
                                        ]
                                    }
                                    }
                                    positionActionsColumn="last"

                                />
                        </div>
                    }
                    <div className={styles.actionContainer}>
                    {checkValidity() && <p className={generalStyles.errorMessage}>No Subscription Added</p>}
                        <b className={styles.billHeading}>Total Bill: {'$' + new Intl.NumberFormat('en-US').format(calculateTotalSum(companyItems)?.toFixed(2))}</b>
                        <div className={styles.tableButtonContainer}>
                            <button className={`${generalStyles.submitButton} ${styles.rightButton}`} onClick={() => onPlaceOrder()} disabled={checkValidity()}> Place Order </button>
                            <span>
                                {purchaseOrderApiState === API_STATES.loading && (
                                    <label className={generalStyles.statusMessage}>Processing your order...</label>
                                )}
                                {purchaseOrderApiState === API_STATES.error && (
                                    <label className={generalStyles.errorMessage}>{purchaseOrderApiError}</label>
                                )}
                                {purchaseOrderApiState === API_STATES.success && (
                                    <label className={generalStyles.successMessage}>
                                        Successfully placed order!
                                    </label>
                                )}
                            </span>
                        </div>
                    </div>
                    <br />
                </> :
                <div className={generalStyles.statusMessage}>Please select company to renew MYA</div>
            }

            <Popup
                open={openModal}
                onClose={() => {
                    setOpenModal(false);
                }}
                modal
            >
                {() => (
                    <>
                        <div className={styles.editContainer}>
                            <div className={styles.editContainerFields}>
                                <div><span><b>Sku: </b></span>
                                    <DebounceInput
                                        placeholder="Sku"
                                        className={styles.tableInput}
                                        value={skuData?.sku}
                                        minLength={1}
                                        debounceTimeout={1000}
                                        disabled={!enableEdit}
                                        onChange={(e) => onDebounceInputChange(e.target.value, rowIndex)}
                                    />
                                    <label>
                                        {childSkuApiStatus === API_STATES.loading ? (
                                            <span className={generalStyles.statusMessage}>Fetching data...</span>
                                        ) : childSkuApiStatus === API_STATES.error ? (
                                            <span className={generalStyles.errorMessage}>
                                                Error: {childSkuApiError}
                                            </span>
                                        ) : (
                                            childSkuApiStatus === API_STATES.success && (
                                                <span className={generalStyles.successMessage}></span>
                                            )
                                        )}
                                    </label>
                                </div>
                                <div className={styles.textField}><b>Product Name: </b><span >{skuData?.product_name}</span></div>
                                <div className={styles.textField}><b>Product Type: </b><span className={styles.textField}>{skuData?.product_type}</span></div>
                                <div><span><b>Quantity: </b></span><input type='text' name='qty' onChange={(e) => onQuantityChange(e.target.value.replace(/\D/g, ''), rowIndex)} value={skuData?.quantity} /></div>
                                <div><span><b>Montly Price($): </b></span><input type='number' name='monthlyPrice' disabled={skuData?.disableMonthlyPrice} onChange={(e) => onPriceChange(e.target, rowIndex)} value={skuData?.monthly_price} /></div>
                                <div><span><b>Annual Price($): </b></span><input type='number' name='annualPrice' disabled={!skuData?.disableMonthlyPrice} onChange={(e) => onPriceChange(e.target, rowIndex)} value={skuData?.annual_price} /></div>
                            </div>
                            <br />
                            <br />
                            <button className={generalStyles.submitButton} disabled={(!skuData?.disableMonthlyPrice && !skuData?.monthly_price) || !skuData?.quantity || (skuData?.disableMonthlyPrice && !skuData?.annual_price)} onClick={() => setCompanyItems(onAdd(companyItems, rowIndex))}>{enableEdit ? "Add Addons" : "Edit"}</button>
                        </div>
                    </>
                )}
            </Popup>
            <Popup
                open={openAddNewItemModal}
                onClose={() => {
                    setOpenAddNewItemModal(false);
                }}
                modal
            >
                <AddNewItems companyItems={companyItems} termValue={termValue} addNewItemType={addNewItemType} openAddNewItemModal={(state) => setOpenAddNewItemModal(state)} getCompanyItems={(companyItems) => setCompanyItems((prev) => ([...prev, companyItems]))} />
            </Popup>
        </>
    );
};

export default MyaAccountSetup;
