import React, { useState, useEffect } from 'react';
import styles from './MarketMaps.module.scss';
import * as API from '@/services/api/product.service';
import generalStyles from '@/components/General/Styles.module.scss';
import API_STATES from '@/constants/StateConstants';
import { handleApiError } from '@/components/General/HandleApiError';
import FileInfoTableInstance from './FileInfoTableInstance';
import { Button } from 'antd';

function FileInfoTable(props) {
    const [fileApiStatus, setFileApiStatus] = useState(API_STATES.none);
    const [fileApiError, setFileApiError] = useState('');

    const [addFileApiStatus, setAddFileApiStatus] = useState(API_STATES.none);
    const [addFileApiError, setAddFileApiError] = useState('');

    const [newFile, setNewFile] = useState({});
    const [selectedFile, setSelectedFile] = useState();

    const files = props.files;
    const folder = props.folder;
    const marketMapType = props.marketMapType;

    async function addFileData() {
        setAddFileApiStatus(API_STATES.loading);
        const result = await API.addMarketMapsFile(
            folder.name,
            marketMapType,
            selectedFile.name,
            selectedFile,
            folder.id,
            selectedFile.type
        );
        switch (result.status) {
            case API_STATES.success:
                setAddFileApiStatus(API_STATES.success);
                setNewFile({});
                setSelectedFile();
                setFileApiStatus(API_STATES.none);
                setAddFileApiStatus(API_STATES.none);
                setTimeout(() => props.onFileUpdate(), 2000);
                break;
            case API_STATES.error:
                let error = result.error;
                handleApiError(error, setAddFileApiStatus, setAddFileApiError);
                break;
            default:
                setAddFileApiError('');
                setAddFileApiStatus(API_STATES.none);
        }
    }

    // async function getChildSkuData() {
    //     setFileApiStatus(API_STATES.loading);
    //     const result = await API.getFilesForMarketMaps(folderId);
    //     switch (result.status) {
    //         case API_STATES.success:
    //             if (result.data) {
    //                 setFileApiStatus(API_STATES.success);
    //                 setFileData(result.data);
    //             } else {
    //                 setFileApiStatus(API_STATES.error);
    //                 setFileApiError('Invalid sku entry');
    //             }
    //             break;
    //         case API_STATES.error:
    //             let error = result.error;
    //             handleApiError(error, setFileApiStatus, setAddFileApiError);
    //             break;
    //         default:
    //             setFileApiError('');
    //             setFileApiStatus(API_STATES.none);
    //     }
    // }

    // const onInputChange = (value) => {
    //     setFileApiStatus(API_STATES.none);
    //     setFileApiError('');
    //     setFileData({});
    //     setNewFile(value);
    // };
    const handleFileChange = (e) => {
        const [file] = e.target?.files;
        setSelectedFile(file);
    };
    return (
        <>
            <table className={styles.bundleTable}>
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>File Name</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {files.map((file) => {
                        return (
                            <FileInfoTableInstance
                                fileId={file.id}
                                fileName={file.name}
                                key={file.id}
                                folder={folder}
                                onFileUpdate={()=>props.onFileUpdate()}
                            />
                        );
                    })}
                </tbody>
            </table>
            <div className={generalStyles['input-wrapper'] + ' ' + styles.dialogInputWrapper}>
                <input id="file-input" type="file" onChange={handleFileChange} />

                {selectedFile ? <span className={generalStyles.successMessage}>File added!</span> : null}

                <div>
                    <Button
                        disabled={selectedFile ? false : true}
                        onClick={() => {
                            addFileData();
                        }}
                    >
                        {' '}
                        Upload File{' '}
                    </Button>
                </div>
                <label data-test="add-file-status">
                    {addFileApiStatus === API_STATES.loading ? (
                        <span className={generalStyles.statusMessage}>Uploading...</span>
                    ) : addFileApiStatus === API_STATES.error ? (
                        <span className={generalStyles.errorMessage}>Error: {addFileApiError}</span>
                    ) : (
                        addFileApiStatus === API_STATES.success && (
                            <span className={generalStyles.successMessage}>Successfully Uploaded!</span>
                        )
                    )}
                </label>
            </div>
        </>
    );
}

export default FileInfoTable;
