import React, { useState, useEffect } from 'react';
import styles from '@/components/AccountSetup/SetupNewCompany.module.scss';
import 'react-datepicker/dist/react-datepicker.css';
import API_STATES from '@/constants/StateConstants';
import { getAllProductBundles } from '@/services/api/product.service';
import Select from 'react-select';
import generalStyles from '@/components/General/Styles.module.scss';
import { handleApiError } from '@/components/General/HandleApiError';
import { calculateTermInMonths } from '../utils/utils.jsx'

function BuyBundles(props) {
    const [subscriptionApiState, setSubscriptionApiState] = useState(API_STATES.none);
    const [subscriptionApiError, setSubscriptionApiError] = useState('');
    const [subscription, setSubscription] = useState({});
    const [subscriptionList, setSubscriptionList] = useState([]);
    const [parentMonthlyPrice, setParentMonthlyPrice] = useState(0);
    const [parentYearlyPrice, setParentYearlyPrice] = useState(0);

    const [addCartItemError, setAddCartItemError] = useState('');

    const [termValue, setTermValue] = useState(0);

    useEffect(() => {
        const contractStart=props.contractStart;
        const today=new Date(new Date().setHours(0, 0, 0, 0));
        const contractTerm = calculateTermInMonths(
            today > contractStart ? today : contractStart, new Date(props.contractEnd));
        setTermValue(contractTerm);
    }, [props.contractStart, props.contractEnd]);

    useEffect(() => {
        setParentYearlyPrice(Number(parseFloat(parentMonthlyPrice * termValue).toFixed(2)));
    }, [parentMonthlyPrice, termValue]);

    useEffect(() => {
        if (props.editableItem?.edit === true) {
            setSubscription({
                label: props.editableItem.label,
                value: props.editableItem.sku,
                ns_catalog_item_id: props.editableItem.nsCatalogItemId,
                count: 1,
                type: 'Bundle'
            });
            setParentMonthlyPrice(props.editableItem.monthlyPrice);
        }
    }, [props.editableItem]);

    const onAddSubscription = () => {
        if (props.items.some((item) => item.sku === subscription.value)) {
            setAddCartItemError('Error: Bundle already present in cart');
            setTimeout(() => setAddCartItemError(''), 3000);
        } else {
            const date = new Date();
            const currentYear = date.getFullYear();
            const currentMonth = date.getMonth() + 1;

            const fullMonths = monthDiff(date, props.contractEnd);

            const daysInCurrentMonth = getDaysInMonth(currentYear, currentMonth);

            const daysForProata = (daysInCurrentMonth - date.getDate() + 1) + (props.contractEnd.getDate());

            const totalCost = (parentMonthlyPrice * fullMonths + (daysForProata * parentMonthlyPrice/30));

            props.addCartItem({
                nsCatalogItemId: subscription.ns_catalog_item_id,
                sku: subscription.value,
                quantity: subscription.count,
                label: subscription.label,
                type: subscription.type,
                monthlyPrice: parentMonthlyPrice,
                sectionTotal: Number(parseFloat(totalCost).toFixed(2)),
                annualPrice: parentYearlyPrice
            });
            setSubscription({});
            setParentMonthlyPrice(0);
            setParentYearlyPrice(0);
        }
    };


    const onSubscriptionChange = (type) => {
        setSubscription(type);
        setParentMonthlyPrice(0);
        setParentYearlyPrice(0);
    };

    const isSubmitEnabled = () => {
        return subscription.value && parentMonthlyPrice !== 0 && parentMonthlyPrice?.toString().trim().length !== 0;
    };

    async function getSubscriptionList() {
        setSubscriptionApiState(API_STATES.loading);
        const response = await getAllProductBundles();
        switch (response.status) {
            case API_STATES.success:
                setSubscriptionApiState(API_STATES.success);
                const data = response.data.map((row) => {
                    return {
                        product_id: row.id,
                        label: row.title,
                        value: row.sku,
                        ns_catalog_item_id: row.ns_catalog_item_id,
                        type: 'Bundle',
                        count: 1
                    };
                });
                setSubscriptionList(data);
                break;
            case API_STATES.error:
                let error = response.error;
                handleApiError(error, setSubscriptionApiState, setSubscriptionApiError);
                break;
            default:
                setSubscriptionApiError('');
                setSubscriptionApiState(API_STATES.none);
        }
    }

    useEffect(() => {
        getSubscriptionList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function getDaysInMonth(year, month) {
        return new Date(year, month, 0).getDate();
    }

    function monthDiff(dateFrom, dateTo) {
        //skip the current month and last month of contract, those will be calculated on pro-rata basis
        return dateTo.getMonth() - 1 - dateFrom.getMonth()+ 
          (12 * (dateTo.getFullYear() - dateFrom.getFullYear()))
    }

    return (
        <>
            <div className={styles.billHeading}>
                <h4>Bundles</h4>
            </div>
            {subscriptionApiState === API_STATES.loading && (
                <label className={generalStyles.statusMessage}>Loading bundles...</label>
            )}
            {subscriptionApiState === API_STATES.error && (
                <label className={generalStyles.errorMessage}>{subscriptionApiError}</label>
            )}
            {subscriptionApiState === API_STATES.success && (
                <>
                    <div className={generalStyles['input-wrapper']}>
                        <label>Select Bundle(s):</label>
                        <span className={styles.formField}>
                            <Select
                                classNamePrefix={'react-select'}
                                onChange={onSubscriptionChange}
                                options={subscriptionList}
                                value={subscription}
                            />
                        </span>
                    </div>
                    <div>
                        <p>Sku: {subscription.value}</p>
                        <br />
                        <p>
                            Bundle Monthly Price($):{' '}
                            <input
                                className={styles.tableInputContainer}
                                value={parentMonthlyPrice}
                                onChange={(e) => {
                                    const newCount = e.target.value;
                                    setParentMonthlyPrice(newCount);
                                }}
                            />
                        </p>
                        <br />
                        {/*<p>
                            Bundle Annual Price($):{' '}
                            <input className={styles.tableInputContainer} value={parentYearlyPrice} disabled={true} />
                        </p>*/}
                    </div>
                    <button
                        className={generalStyles.submitButton}
                        disabled={!isSubmitEnabled()}
                        onClick={onAddSubscription}
                    >
                        Add to Cart
                    </button>
                    <span className={generalStyles.errorMessage}>{addCartItemError}</span>
                </>
            )}
        </>
    );
}
export default BuyBundles;
