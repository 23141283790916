import generalStyles from '@/components/General/Styles.module.scss';
import { Link } from 'react-router-dom';

export const COLUMNS = [
    {
        Header: 'First Name',
        accessor: 'firstName',
        id: 'firstname',
        className: `${generalStyles.tableCell} ${generalStyles.cap}`
    },
    {
        Header: 'Last Name',
        accessor: 'lastName',
        id: 'lastname',
        className: `${generalStyles.tableCell} ${generalStyles.cap}`
    },
    {
        Header: 'CSR Email',
        accessor: 'email',
        id: 'email',
        className: generalStyles.tableCell
    },
    {
        Header: 'CSR Roles',
        accessor: 'csrUserRoles',
        disableSortBy: true,
        id: 'csruserroles',
        className: generalStyles.tableCell,
        Cell: ({ value }) => {
            return value.join(',');
        }
    },
    {
        Header: 'Action',
        accessor: 'email',
        id: 'actions',
        disableFilters: true,
        disableSortBy: true,
        className: generalStyles.tableCell,
        Cell: ({ value }) => {
            return (
                <Link className={generalStyles.link} to={`/modifycsr/${value}`}>
                    <u>View/Modify</u>
                </Link>
            );
        }
    }
];
