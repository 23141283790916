import InlineSVG from 'react-inlinesvg/esm';
import searchBlackIcon from '@images/icons/searchBlackIcon.svg';
import styles from '@/components/General/Styles.module.scss';
import classNames from 'classnames';

function SearchBar(props) {
    return (
        <form
            className={classNames(styles.searchbar, props.disabled && styles.disabled)}
            onSubmit={(event) => {
                if (props.withButton && !props.disabled) {
                    props.onSubmit(event);
                }
                event.preventDefault();
            }}
        >
            <div className={styles.searchInputWrapper}>
                <InlineSVG src={searchBlackIcon} />
                <input
                    type="text"
                    value={props.value}
                    onChange={props.onChange}
                    placeholder={props.placeholder}
                    disabled={props.disabled}
                />
            </div>
            {props.withButton && (
                <input
                    data-test="search-bar-btn"
                    type="submit"
                    className={styles.submitButton}
                    disabled={props.disabled}
                    value={props.buttonText || 'Submit'}
                />
            )}
        </form>
    );
}
export default SearchBar;
