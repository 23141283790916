import React, { useState, useCallback } from 'react';
import UserBasedNotificationDetailedHistory from './UserBasedNotificationDetailedHistory';

function UserBasedNotificationDetailedView({ selectedNotification }) {
    const [refreshHistoryDetail, setRefreshHistoryDetail] = useState(true);

    const refreshNotificationDetailedList = useCallback(async (flag) => {
        setRefreshHistoryDetail(flag);
    }, []);

    return (
        <>
            <UserBasedNotificationDetailedHistory
                refreshHistory={refreshHistoryDetail}
                refresh={(flag) => refreshNotificationDetailedList(flag)}
                selectedNotification={selectedNotification}
            />
        </>
    );
}

export default UserBasedNotificationDetailedView;
