import generalStyles from '@/components/General/Styles.module.scss';
import { MultiSelectColumnFilter } from '../General/MultiSelectColumnFilter';
import { FILTER_TYPES } from '../General/DataTable';
import { Link } from 'react-router-dom';

export const COLUMNS = [
    {
        Header: 'Company Name',
        accessor: 'companyName',
        id: 'companyName',
        className: `${generalStyles.tableCell} ${generalStyles.cap}`,
        Filter: MultiSelectColumnFilter,
        filter: FILTER_TYPES.multiple,
        Cell: ({ row, value }) => (
            <Link className={`${generalStyles.link} ${generalStyles.tooltip}`} to={`/newcompanypage/${row.original.nsCompanyId}`}>
                {value}
                <span className={generalStyles.tooltiptext}>Company Configuration</span>
            </Link>
        ),
    },
    {
        Header: 'Active Users',
        accessor: 'activeUsers',
        id: 'activeUsers',
        className: `${generalStyles.tableCell} ${generalStyles.cap}`,
        disableFilters: true
    },
    {
        Header: 'Has Active Subscription',
        accessor: (row)=> row.hasActiveSubscription ? "Yes" : "No",
        id: 'hasActiveSubscription',
        className: `${generalStyles.tableCell}`,
    },
    {
        Header: 'Pending Users',
        accessor: 'pendingUsers',
        id: 'pendingUsers',
        className: `${generalStyles.tableCell} ${generalStyles.cap}`,
        disableFilters: true
    },
    {
        Header: 'Total Users',
        accessor: 'totalUsers',
        id: 'totalUsers',
        className: `${generalStyles.tableCell} ${generalStyles.cap}`,
        disableFilters: true
    },

];
