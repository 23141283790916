import React, { useEffect, useState } from 'react';
import { getPurchasedItemsByCompany } from '@/services/api/report.service';
import API_STATES from '@/constants/StateConstants';
import generalStyles from '@/components/General/Styles.module.scss';
import { handleApiError } from '@/components/General/HandleApiError';
import styles from '@/components/CompanyConfig/CompanyConfig.module.scss';
import { isEmpty } from 'lodash';
import Popup from 'reactjs-popup';
import { closeCompanyContract, getPreSaleStatus, moveContractToActive } from '@/services/api/company.service';
import DataTable from '../General/DataTable';
import { COLUMNS } from './CompanyPurchaseTableColumns';

function CompanyPurchasesReport({
    nsCompanyId,
    isEditable = false,
    someCompanyPurchase,
    noCompanyPurchase,
    refreshTimestamp,
    refreshDelay = 0,
}) {
    const [purchasedItems, setPurchasedItems] = useState();
    const [purchasedItemsApiStatus, setPurchasedItemsApiStatus] = useState(API_STATES.none);
    const [purchasedItemsApiError, setPurchasedItemsApiError] = useState('');

    const [contractIdToClose, setContractIdToClose] = useState(null);
    const [contractCloseApiState, setContractCloseApiState] = useState(API_STATES.none);
    const [contractCloseApiError, setContractCloseApiError] = useState('');
    const [contractActivateApiState, setContractActivateApiState] = useState(API_STATES.none);
    const [contractActivateApiError, setContractActivateApiError] = useState('');
    const [isInActive, setIsInActive] = useState(true);
    const [freePackageContractId, setFreePackageContractId] = useState(null);

    async function getPurchasedItems() {
        setPurchasedItemsApiStatus(API_STATES.loading);
        const result = await getPurchasedItemsByCompany(nsCompanyId);
        switch (result.status) {
            case API_STATES.success:
                if (result.data?.active?.length > 0 || result.data?.inactive?.length > 0) {
                    setPurchasedItems({
                        active: processPurchasedItems(result.data.active),
                        inactive: processPurchasedItems(result.data.inactive),
                    });
                    setPurchasedItemsApiStatus(API_STATES.success);
                    someCompanyPurchase?.();
                    break;
                } else {
                    noCompanyPurchase?.();
                    setPurchasedItemsApiError('No items purchased by company yet!');
                    setPurchasedItemsApiStatus(API_STATES.error);
                    break;
                }
            case API_STATES.error:
                const error = result.error;
                handleApiError(
                    error,
                    setPurchasedItemsApiStatus,
                    setPurchasedItemsApiError,
                    `No purchased items found`
                );
                break;
            default:
                setPurchasedItemsApiError('');
                setPurchasedItemsApiStatus(API_STATES.none);
        }
    }

    async function closeContract(contractId) {
        setContractCloseApiState(API_STATES.loading);
        const result = await closeCompanyContract(nsCompanyId, contractId);
        switch (result.status) {
            case API_STATES.success:
                setContractCloseApiState(API_STATES.success);
                setTimeout(() => {
                    setContractIdToClose(null);
                    setContractCloseApiState(API_STATES.none);
                    // reloading purchases after 1 minute
                    setPurchasedItemsApiStatus(API_STATES.loading);
                    setTimeout(() => getPurchasedItems(), 60000);
                }, 2000);
                break;
            case API_STATES.error:
                handleApiError(result.error, setContractCloseApiState, setContractCloseApiError, `Contract not found`);
                setTimeout(() => setContractCloseApiState(API_STATES.none), 3000);
                break;
            default:
                setContractCloseApiState(API_STATES.none);
        }
    }

    async function moveToActive() {
        setContractActivateApiState(API_STATES.loading);
        const result = await moveContractToActive(freePackageContractId);
        switch (result.status) {
            case API_STATES.success:
                setContractActivateApiState(API_STATES.success);
                setIsInActive(true);
                setTimeout(() => {
                    setContractActivateApiState(API_STATES.none);
                }, 2000);
                break;
            case API_STATES.error:
                handleApiError(result.error, setContractActivateApiState, setContractCloseApiError);
                setTimeout(() => setContractActivateApiState(API_STATES.none), 3000);
                break;
            default:
                setContractActivateApiState(API_STATES.none);
        }
    }

    function processPurchasedItems(purchasedItems) {
        if (isEmpty(purchasedItems)) {
            return [];
        }
        const processedItems = [];
        purchasedItems.forEach(({ contractId, isContractActive, ...itemDetails }) => {
            const processedItem = processedItems.find((it) => it.contractId === contractId);
            if (!processedItem) {
                processedItems.push({
                    contractId,
                    isContractActive,
                    items: [itemDetails],
                });
            } else {
                processedItem.items.push(itemDetails);
            }
        });
        return processedItems;
    }

    function initiateCloseContract(contractId) {
        setContractIdToClose(contractId);
    }

    function cancelCloseContract() {
        setContractIdToClose(null);
    }

    useEffect(() => {
        if (nsCompanyId !== null && nsCompanyId !== 0) {
            getPurchasedItems();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nsCompanyId]);

    useEffect(() => {
        if (nsCompanyId !== null && nsCompanyId !== 0) {
            setPurchasedItemsApiStatus(API_STATES.loading);
            setTimeout(() => getPurchasedItems(), refreshDelay ?? 0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshTimestamp]);

    useEffect(() => {
        purchasedItems?.active?.map((contract) => {
            contract.items.map(async (item) => {
                if (item.sku === '5') {
                    const status = await getPreSaleStatus(contract.contractId);
                    if (status.data?.preSales === true) {
                        setFreePackageContractId(contract.contractId);
                        setIsInActive(false);
                    }
                }
            });
        });
    }, [purchasedItems]);

    return (
        <>
            {purchasedItemsApiStatus === API_STATES.error ? (
                <p className={generalStyles.errorMessage}>Error: {purchasedItemsApiError}</p>
            ) : purchasedItemsApiStatus === API_STATES.loading ? (
                <p className={generalStyles.statusMessage}>Loading purchased items...</p>
            ) : (
                purchasedItemsApiStatus === API_STATES.success && (
                    <>
                        <h4 className={styles.heading}>Active Purchases</h4>
                         {freePackageContractId !== null ? (
                            <>
                                {isInActive ? (
                                    <label className={generalStyles.successMessage}>
                                        Company is in active free subscription state
                                    </label>
                                ) : (
                                    <label className={generalStyles.errorMessage}>
                                        Company is in pre-sales free subscription state
                                    </label>
                                )}
                                {!isInActive ? (
                                    <button
                                        className={generalStyles.submitButton}
                                        onClick={() => moveToActive()}
                                        disabled={isInActive}
                                    >
                                        Move to active
                                    </button>
                                ) : null}
                                {contractActivateApiState === API_STATES.loading ? (
                                    <span className={generalStyles.statusMessage}>Activating contract</span>
                                ) : contractActivateApiState === API_STATES.success ? (
                                    <span className={generalStyles.successMessage}>Activated contract</span>
                                ) : (
                                    contractActivateApiState === API_STATES.error && (
                                        <span className={generalStyles.errorMessage}>{contractActivateApiError}</span>
                                    )
                                )}
                                <br />
                            </>
                        ) : null}
                        {!isEmpty(purchasedItems?.active) ? (
                            purchasedItems?.active?.map((contract) => (
                                <>
                                    <b>Contract: {contract.contractId}</b>
                                    {isEditable && (
                                        <button
                                            className={generalStyles.submitButton}
                                            disabled={
                                                !contract.isContractActive ||
                                                contractCloseApiState !== API_STATES.none ||
                                                contractIdToClose
                                            }
                                            onClick={() => initiateCloseContract(contract.contractId)}
                                        >
                                            Close contract
                                        </button>
                                    )}
                                    <DataTable columns={COLUMNS} data={contract.items}/>
                                    <br/>
                                </>
                            ))
                        ) : (
                            <p className={generalStyles.statusMessage}>Company has no active purchases</p>
                        )}

                        <h4 className={styles.heading}>Inactive Purchases</h4>
                        {!isEmpty(purchasedItems?.inactive) ? (
                            purchasedItems?.inactive?.map((contract) => (
                                <>
                                    <b>Contract: {contract.contractId}</b>
                                    <DataTable columns={COLUMNS} data={contract.items}/>
                                </>
                            ))
                        ) : (
                            <p className={generalStyles.statusMessage}>Company has no inactive purchases</p>
                        )}
                    </>
                )
            )}

            <Popup open={Boolean(contractIdToClose)} onClose={cancelCloseContract} modal>
                {() => (
                    <div className={styles.warningDialog}>
                        Once a contract is closed it cannot be undone, Do you want to proceed closing the contract -{' '}
                        {contractIdToClose}
                        <div className={generalStyles.row}>
                            <button
                                className={`${generalStyles.cancelOperationButton} ${styles.btnWidth}`}
                                onClick={cancelCloseContract}
                                disabled={contractCloseApiState === API_STATES.loading}
                            >
                                cancel
                            </button>

                            <button
                                className={`${generalStyles.confirmOperationButton} ${styles.btnWidth}`}
                                onClick={() => closeContract(contractIdToClose)}
                                disabled={contractCloseApiState !== API_STATES.none}
                            >
                                Confirm
                            </button>
                        </div>
                        {contractCloseApiState === API_STATES.loading ? (
                            <span className={generalStyles.statusMessage}>Closing contract</span>
                        ) : contractCloseApiState === API_STATES.success ? (
                            <span className={generalStyles.successMessage}>Closed contract</span>
                        ) : (
                            contractCloseApiState === API_STATES.error && (
                                <span className={generalStyles.errorMessage}>{contractCloseApiError}</span>
                            )
                        )}
                    </div>
                )}
            </Popup>
        </>
    );
}

export default CompanyPurchasesReport;
